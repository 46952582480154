import { defineMessages } from "react-intl";

export default defineMessages({
  caloriesFrom: {
    id: "nutrition.caloriesFrom",
    defaultMessage: "Calories coming from {value}",
  },
  caloriesInfo: {
    id: "nutrition.caloriesInfo",
    defaultMessage:
      "Define the maximum and minimum percentage of daily calorie requirements that can come from staple food items. You may wish to do this to ensure a more balanced meal.",
  },
  maximumAmount: {
    id: "nutrition.maximumAmount",
    defaultMessage: "Please provide maximum amount for element.",
  },
  maximum: {
    id: "nutrition.maximum",
    defaultMessage: "Maximum {value}",
  },
  firstRowDescription: {
    id: "nutrition.firstRowDescription",
    defaultMessage:
      "You will now define how nutritious your menu will be by setting nutritient targets for the meals. PLUS suggests pre-defined nutrient targets according to your programme objectives and country context.",
  },
  secondRowDescription: {
    id: "nutrition.secondRowDescription",
    defaultMessage: "You can also decide to define custom requirements in the advanced settings.",
  },
  title: {
    id: "nutrition.title",
    defaultMessage: "Nutritional requirements",
  },
  info: {
    id: "nutrition.info",
    defaultMessage: "Select one of the options below or manually adjust the nutrient targets of your school meal menus",
  },
  adjustRequirement: {
    id: "nutrition.adjustRequirement",
    defaultMessage: "Adjust requirements",
  },
  recommended: {
    id: "nutrition.recommended",
    defaultMessage: "Country minimum requirements",
  },
  basic: {
    id: "nutrition.basic",
    defaultMessage: "Basic recommendation",
  },
  anemia: {
    id: "nutrition.anemia",
    defaultMessage: "Recommended if anemia in Children is > 40%",
  },
  objective: {
    id: "nutrition.objective",
    defaultMessage: "Recommended for Nutrition Objective",
  },
  custom: {
    id: "nutrition.custom",
    defaultMessage: "Custom adjustments",
  },
  micronutrientTitle: {
    id: "nutrition.micronutrientTitle",
    defaultMessage: "Micronutrients",
  },
  micronutrientInfo: {
    id: "nutrition.micronutrientInfo",
    defaultMessage:
      "Define the micronutrient content of the meals. This is a percentage of the daily Dietary Reference Intake for the selected School Children.",
  },
  micronutrientCheckbox: {
    id: "nutrition.micronutrientCheckbox",
    defaultMessage: "Set all micronutrients to the same percentage",
  },
  macronutrientTitle: {
    id: "nutrition.macronutrientTitle",
    defaultMessage: "Macronutrients",
  },
  macronutrientInfo: {
    id: "nutrition.macronutrientInfo",
    defaultMessage:
      "Define the macronutrient content of the meals. This is a percentage of the daily Dietary Reference Intake for the selected School Children.",
  },
  macronutrientCheckbox: {
    id: "nutrition.macronutrientCheckbox",
    defaultMessage: "Set all macronutrients to the same percentage",
  },
  advancedSetting: {
    id: "advancedSetting.title",
    defaultMessage: "Advanced settings",
  },
  warningTitle: {
    id: "nutrition.warningTitle",
    defaultMessage:
      "With the list of available food, the following nutritional requirements cannot be met for your selected nutritional requirements:",
  },
  warningInfo: {
    id: "nutrition.warningInfo",
    defaultMessage: "You can either:",
  },
  warningFirstPoint: {
    id: "nutrition.warningFirstPoint",
    defaultMessage: "Include more food items",
  },
  warningSecondPoint: {
    id: "nutrition.warningSecondPoint",
    defaultMessage: 'Go to "Custom requirements" and adjust the nutritional requirements',
  },
  warningThirdPoint: {
    id: "nutrition.warningThirdPoint",
    defaultMessage:
      "Continue: PLUS will calculate a menu as nutritious as possible with the current settings, but it will not meet your nutritional requirements.",
  },
  warningCritic: {
    id: "nutrition.warningCritic",
    defaultMessage:
      "The requested level is higher than the available amount of {value} in the basket. It is unlikely to be reached by the optimization",
  },
  warningInfeasible: {
    id: "nutrition.warningInfeasible",
    defaultMessage: "The requested level is high compared to the available amount of {value} in the basket",
  },
});
