import * as R from "ramda";
import { FIELDS } from "./constants";

// We need to transform our local data before sending it to BE
export const getReworkedFilters = (filters) => {
  const queryParamsKeys = ["page", "page_size", "ordering", "target_group", "name"];
  let params = {},
    data = {};

  Object.keys(filters).forEach((key) => {
    const currentItem = filters[key];

    // When comparing menus we end up having "ordering" param as an object instead of a string
    // Always add "-is_starred" as first comma separated param in order to show starred menus first
    if (key === "ordering") {
      const orderingValue = R.is(Object, currentItem) ? currentItem.value : currentItem;

      // Since we need to have "-updated_timestamp" flag, we check the value before providing or not the "-" in front of the param
      const ordering = orderingValue === "updated_timestamp" ? "-updated_timestamp" : orderingValue;
      params[key] = `-is_starred,${ordering}`;
      return;
    }

    // Handle queryParams
    if (queryParamsKeys.includes(key)) {
      params[key] = currentItem;
      return;
    }

    // Handle data and change its shape accordingly
    switch (key) {
      case "date":
        data[FIELDS.DATE_FROM] = currentItem[FIELDS.DATE_FROM]?.format("YYYY-MM-DD");
        data[FIELDS.DATE_TO] = currentItem[FIELDS.DATE_TO]?.format("YYYY-MM-DD");
        break;
      case "target":
        data[key] = currentItem.map((el) => el.value);
        break;
      default:
        data[key] = currentItem;
    }
  });

  return { params, data };
};
