import React, { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { loadFoodPricesRequest } from "containers/Admin/actions";

import { selectConstants } from "containers/App/selectors";
import { allPriceApprovalFields } from "./constants";

import FiltersTemplate from "containers/Admin/Countries/Filters";

const Filters = () => {
  const intl = useIntl();
  const constants = useSelector(selectConstants);

  const filterFields = useMemo(() => {
    if (R.isEmpty(constants)) return [];
    const { food_source_choices } = constants as { food_source_choices: {label: string, value: string}[]};
    return allPriceApprovalFields(intl, [{ label: "All", value: "" }, ...food_source_choices]);
  }, [constants, intl]);

  // We need to wait for constants since sources options are fetched from BE.
  if (R.isEmpty(constants)) {
    return null;
  }

  return <FiltersTemplate fields={filterFields} actionFunction={loadFoodPricesRequest} />;
};

export default Filters;
