import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@foodbasket/LOAD_STARTED");
export const loadEnded = createAction("@@foodbasket/LOAD_ENDED");

export const loadFoodBasket = createAction("@@foodbasket/LOAD_FOOD_BASKET");

export const loadMajorGroupsSuccess = createAction("@@foodbasket/LOAD_MAJOR_GROUPS_SUCCESS");

export const searchFoodItemsRequest = createAction("@@foodbasket/SEARCH_FOOD_ITEMS_REQUEST");
export const loadFoodItemsStarted = createAction("@@foodbasket/LOAD_FOOD_ITEMS_STARTED");
export const loadFoodItemsSuccess = createAction("@@foodbasket/LOAD_FOOD_ITEMS_SUCCESS");
export const loadFoodItemsEnded = createAction("@@foodbasket/LOAD_FOOD_ITEMS_ENDED");

export const loadSummaryPricesRequest = createAction("@@foodbasket/LOAD_SUMMARY_PRICES_REQUEST");
export const loadSummaryPricesStarted = createAction("@@foodbasket/LOAD_SUMMARY_PRICES_STARTED");
export const loadSummaryPricesSuccess = createAction("@@foodbasket/LOAD_SUMMARY_PRICES_SUCCESS");
export const loadSummaryPricesEnded = createAction("@@foodbasket/LOAD_SUMMARY_PRICES_ENDED");

export const resetSummaryPrices = createAction("@@foodbasket/RESET_SUMMARY_PRICES");

export const createOrUpdateFoodPriceRequest = createAction("@@foodbasket/CREATE_OR_UPDATE_FOOD_PRICE_REQUEST");
export const createOrUpdateFoodPriceStarted = createAction("@@foodbasket/CREATE_OR_UPDATE_FOOD_PRICE_STARTED");
export const createOrUpdateFoodPriceEnded = createAction("@@foodbasket/CREATE_OR_UPDATE_FOOD_PRICE_ENDED");

export const deleteFoodPriceRequest = createAction("@@foodbasket/DELETE_FOOD_PRICE_REQUEST");
export const deleteFoodPriceStarted = createAction("@@foodbasket/DELETE_FOOD_PRICE_STARTED");
export const deleteFoodPriceEnded = createAction("@@foodbasket/DELETE_FOOD_PRICE_ENDED");

export const loadNutrientsSuccess = createAction("@@foodbasket/LOAD_NUTRIENTS_SUCCESS");

export const loadFilteredFCTFoodItemsRequest = createAction("@@foodbasket/LOAD_FILTERED_FCT_FOOD_ITEMS_REQUEST");
export const loadFilteredFCTFoodItemsSuccess = createAction("@@foodbasket/LOAD_FILTERED_FCT_FOOD_ITEMS_SUCCESS");

export const loadFCTFoodItemsRequest = createAction("@@foodbasket/LOAD_FCT_FOOD_ITEMS_REQUEST");
export const loadFCTFoodItemsStarted = createAction("@@foodbasket/LOAD_FCT_FOOD_ITEMS_STARTED");
export const loadFCTFoodItemsSuccess = createAction("@@foodbasket/LOAD_FCT_FOOD_ITEMS_SUCCESS");
export const loadFCTFoodItemsEnded = createAction("@@foodbasket/LOAD_FCT_FOOD_ITEMS_ENDED");

export const resetFoodBasket = createAction("@@foodbasket/RESET_FOOD_BASKET");
