import React from "react";
import styled, { css } from "styled-components";
import { Button } from "@wfp/ui";

const MyButton = ({
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  widthAuto,
  noPadding,
  withUnderline,
  minwWidth,
  maxwWidth,
  fontSize,
  fontWeight,
  height,
  marginInlineStart,
  marginInlineEnd,
  ...props
}) => <Button {...props} />;

export const UIButton = styled(MyButton)`
  width: ${({ widthAuto }) => (widthAuto ? "auto" : "100%")};
  height: ${({ height }) => height || "none"};
  min-width: ${({ minwWidth }) => minwWidth || "none"};
  max-width: ${({ maxwWidth }) => (maxwWidth ? "135px" : "auto")};
  margin-top: ${({ marginTop }) => marginTop || 0};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight || "14px"};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  ${({ marginInlineStart }) =>
    marginInlineStart &&
    css`
      margin-inline-start: ${marginInlineStart};
    `};
  ${({ marginInlineEnd }) =>
    marginInlineEnd &&
    css`
      margin-inline-end: ${marginInlineEnd};
    `};
  padding: ${({ noPadding }) => noPadding && 0};
  text-decoration: ${({ withUnderline }) => withUnderline && "underline"};
`;
