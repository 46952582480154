import { defineMessages } from "react-intl";

export default defineMessages({
  continue: {
    id: "button.continue",
    defaultMessage: "Continue",
  },
  compare: {
    id: "button.compare",
    defaultMessage: "Compare",
  },
  clearCompare: {
    id: "button.clearCompare",
    defaultMessage: "Clear compare list",
  },
  duplicate: {
    id: "button.duplicate",
    defaultMessage: "Duplicate",
  },
  delete: {
    id: "button.delete",
    defaultMessage: "Delete",
  },
  remove: {
    id: "button.remove",
    defaultMessage: "Remove",
  },
  edit: {
    id: "button.edit",
    defaultMessage: "Edit",
  },
  rename: {
    id: "button.rename",
    defaultMessage: "Rename",
  },
  share: {
    id: "button.share",
    defaultMessage: "Share",
  },
  sharing: {
    id: "button.sharing",
    defaultMessage: "Sharing...",
  },
  validate: {
    id: "button.validate",
    defaultMessage: "Validate",
  },
  next: {
    id: "button.next",
    defaultMessage: "Next",
  },
  loading: {
    id: "button.loading",
    defaultMessage: "Loading...",
  },
  downloadPdf: {
    id: "button.downloadPdf",
    defaultMessage: "Download PDF",
  },
  exportExcel: {
    id: "button.exportExcel",
    defaultMessage: "Export Excel",
  },
  generatingPdf: {
    id: "button.generatingPdf",
    defaultMessage: "Generating PDF...",
  },
  download: {
    id: "button.download",
    defaultMessage: "Download",
  },
  generatingFile: {
    id: "button.generatingFile",
    defaultMessage: "Generating file...",
  },
  includeAdditional: {
    id: "button.includeAdditional",
    defaultMessage: "Include additional costs",
  },
  close: {
    id: "button.close",
    defaultMessage: "Close",
  },
  excludeAdditional: {
    id: "button.excludeAdditional",
    defaultMessage: "Exclude additional costs",
  },
  save: {
    id: "button.save",
    defaultMessage: "Save",
  },
  submit: {
    id: "button.submit",
    defaultMessage: "Submit",
  },
  apply: {
    id: "button.apply",
    defaultMessage: "Apply",
  },
  back: {
    id: "button.back",
    defaultMessage: "Back",
  },
  cancel: {
    id: "button.cancel",
    defaultMessage: "Cancel",
  },
  ok: {
    id: "button.ok",
    defaultMessage: "Ok",
  },
  convert: {
    id: "button.convert",
    defaultMessage: "Convert in {currency}",
  },
  changeSettings: {
    id: "button.changeSettings",
    defaultMessage: "Change settings",
  },
  reviewSourcing: {
    id: "button.reviewSourcingConditions",
    defaultMessage: "Review sourcing conditions",
  },
  reviewNutrition: {
    id: "button.reviewNutrition",
    defaultMessage: "Review nutrition requirements",
  },
  resultsWithoutSourcing: {
    id: "button.resultsWithoutSourcing",
    defaultMessage: "See Menu results WITHOUT my sourcing conditions",
  },
  fixValues: {
    id: "button.fixValues",
    defaultMessage: "Fix values",
  },
  addEditPrices: {
    id: "button.addEditPrices",
    defaultMessage: "Food prices",
  },
  trainingMaterials: {
    id: "button.trainingMaterials",
    defaultMessage: "Training materials",
  },
  filter: {
    id: "button.filter",
    defaultMessage: "Filter",
  },
  clear: {
    id: "button.clear",
    defaultMessage: "Clear",
  },
  search: {
    id: "button.search",
    defaultMessage: "Search",
  },
  addPersonalFoodItem: {
    id: "button.addPersonalFoodItem",
    defaultMessage: "Add a personal food item",
  },
  bulkAddPersonalFoodItems: {
    id: "button.bulkAddPersonalFoodItems",
    defaultMessage: "Bulk upload personal food items",
  },
  addAdditionalPrice: {
    id: "button.addAdditionalPrice",
    defaultMessage: "Add additional price",
  },
  addPersonalPrice: {
    id: "button.addPersonalPrice",
    defaultMessage: "Add a personal price",
  },
  viewMore: {
    id: "button.viewMore",
    defaultMessage: "View more",
  },
  saveAndAdd: {
    id: "button.saveAndAdd",
    defaultMessage: "Save and add another",
  },
  approve: {
    id: "button.approve",
    defaultMessage: "Approve",
  },
  reject: {
    id: "button.reject",
    defaultMessage: "Reject",
  },
  approveAll: {
    id: "button.approveAll",
    defaultMessage: "Approve all",
  },
  reloadApp: {
    id: "button.reloadApp",
    defaultMessage: "Reload app",
  },
  addNewFoodItem: {
    id: "button.addNewFoodItem",
    defaultMessage: "Add a new food item",
  },
  add: {
    id: "button.add",
    defaultMessage: "Add",
  },
  // TODO: Add to locales files? Maybe not. It was defined as button.viewFoodBasket but was inside GeneralInfo/GeneralForm/FoodBasket/messages.
  viewFoodBasket: {
    id: "button.viewFoodBasket",
    defaultMessage: "View food basket",
  },
  createRecipe: {
    id: "button.createRecipe",
    defaultMessage: "Create recipe",
  },
});
