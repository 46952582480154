import { useEffect, useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { selectProfile, selectPriceSources, selectRegions, selectFoodSources } from "containers/App/selectors";
import { selectFoodBasketItems } from "containers/GeneralInfo/GeneralForm/selectors";
import { selectRestriction } from "containers/OptimizedMenu/Rules/FoodRules/selectors";
import { selectInitValues } from "containers/OptimizedMenu/selectors";
import { FIELDS, INITIAL_VALUES } from "./constants";
import { getPriceSourcesCheckboxes, getDefaultMenuName } from "./utils";

import {
  selectRegions as selectMenuRegions,
  selectMenuPriceSources,
  selectMenuPreliminaryInformation,
  selectMenuName,
  selectMenuSourcingChannels,
  selectMenuBasket,
} from "containers/Menu/selectors";

// Hook to get both sync and async initial values
export const useInitialValues = (menuId) => {
  const profile = useSelector(selectProfile);
  const priceSources = useSelector(selectPriceSources);
  const regions = useSelector(selectRegions);
  const procurementSources = useSelector(selectFoodSources);
  const initValues = useSelector(selectInitValues);
  const restriction = useSelector(selectRestriction);
  const basket = useSelector(selectFoodBasketItems);

  const menuRegions = useSelector(selectMenuRegions);
  const menuPriceSources = useSelector(selectMenuPriceSources);
  const menuPreliminaryInformation = useSelector(selectMenuPreliminaryInformation);
  const menuCustomName = useSelector(selectMenuName);
  const menuSourcingChannels = useSelector(selectMenuSourcingChannels);
  const menuBasket = useSelector(selectMenuBasket);

  return useMemo(() => {
    if (
      R.isEmpty(priceSources) ||
      R.isEmpty(profile) ||
      R.isEmpty(regions) ||
      R.isEmpty(procurementSources) ||
      R.isEmpty(basket)
    ) {
      return INITIAL_VALUES;
    }

    if (menuId && menuPreliminaryInformation) {
      const newRegions = (menuRegions || []).map(({ id, region, ...rest }) => ({ value: id, label: region, ...rest }));
      const newSourcingChannels = procurementSources.filter((source) =>
        (menuSourcingChannels || []).includes(source.value)
      );

      return {
        ...INITIAL_VALUES,
        [FIELDS.PRELIMINARY_INFORMATION]: {
          ...INITIAL_VALUES[FIELDS.PRELIMINARY_INFORMATION],
          [FIELDS.BASKET]: menuBasket,
          [FIELDS.COUNTRY]: R.pathOr(null, ["country"], profile),
          [FIELDS.REGIONS]: newRegions,
          [FIELDS.SOURCING_CHANNELS]: newSourcingChannels,
          [FIELDS.TARGET_GROUP]: menuPreliminaryInformation[FIELDS.TARGET_GROUP],
          [FIELDS.SEASONALITY]:
            menuPreliminaryInformation[FIELDS.START_MONTH] === 1 && menuPreliminaryInformation[FIELDS.END_MONTH] === 12
              ? "y"
              : "m",
          [FIELDS.PRICE_SOURCE_VALIDITY]: menuPreliminaryInformation[FIELDS.PRICE_SOURCE_VALIDITY],
          [FIELDS.START_MONTH]: menuPreliminaryInformation[FIELDS.START_MONTH],
          [FIELDS.END_MONTH]: menuPreliminaryInformation[FIELDS.END_MONTH],
          [FIELDS.DAYS_IN_WEEK]: menuPreliminaryInformation[FIELDS.DAYS_IN_WEEK],
          [FIELDS.WEEK_COUNT]: menuPreliminaryInformation[FIELDS.WEEK_COUNT],
        },
        [FIELDS.MENU_PRICING_LIST]: R.omit(["id"], menuPriceSources),
        ...initValues,
        [FIELDS.RESTRICTION]: restriction,
        [FIELDS.MENU_NAME]: menuCustomName,
      };
    } else {
      return {
        ...INITIAL_VALUES,
        [FIELDS.PRELIMINARY_INFORMATION]: {
          ...INITIAL_VALUES[FIELDS.PRELIMINARY_INFORMATION],
          [FIELDS.COUNTRY]: R.pathOr(null, ["country"], profile),
        },
        [FIELDS.MENU_PRICING_LIST]: getPriceSourcesCheckboxes(priceSources),
        ...initValues,
        [FIELDS.RESTRICTION]: restriction,
        [FIELDS.MENU_NAME]: getDefaultMenuName(profile.country_name),
      };
    }
  }, [
    basket,
    initValues,
    menuBasket,
    menuCustomName,
    menuId,
    menuPreliminaryInformation,
    menuPriceSources,
    menuRegions,
    menuSourcingChannels,
    priceSources,
    procurementSources,
    profile,
    regions,
    restriction,
  ]);
};

// If we are editing a menu we need to initialize form with the values coming from backend.
export const useAsyncForm = (menuId) => {
  const profile = useSelector(selectProfile);
  const priceSources = useSelector(selectPriceSources);
  const procurementSources = useSelector(selectFoodSources);
  const initValues = useSelector(selectInitValues);
  const restriction = useSelector(selectRestriction);
  const basket = useSelector(selectFoodBasketItems);

  const menuRegions = useSelector(selectMenuRegions);
  const menuPriceSources = useSelector(selectMenuPriceSources);
  const menuPreliminaryInformation = useSelector(selectMenuPreliminaryInformation);
  const menuCustomName = useSelector(selectMenuName);
  const menuSourcingChannels = useSelector(selectMenuSourcingChannels);
  const menuBasket = useSelector(selectMenuBasket);

  const formValues = useInitialValues(menuId);
  const useFormApi = useForm({ defaultValues: formValues });

  // "profile" change
  useEffect(() => {
    const values = useFormApi.getValues();
    const menuName = menuId ? menuCustomName : getDefaultMenuName(profile.country_name);

    useFormApi.reset({
      ...values,
      [FIELDS.PRELIMINARY_INFORMATION]: {
        ...values[FIELDS.PRELIMINARY_INFORMATION],
        [FIELDS.COUNTRY]: R.pathOr(null, ["country"], profile),
      },
      [FIELDS.MENU_NAME]: menuName,
      [FIELDS.DRAFT]: {
        ...values[FIELDS.DRAFT],
        [FIELDS.MENU_NAME]: menuName,
      },
    });
  }, [menuCustomName, menuId, profile, useFormApi]);

  // "priceSources" change: wait for price sources to be fetched.
  useEffect(() => {
    if (!menuId) {
      if (!R.isEmpty(priceSources)) {
        useFormApi.reset({
          ...useFormApi.getValues(),
          [FIELDS.MENU_PRICING_LIST]: getPriceSourcesCheckboxes(priceSources),
        });
      }
    } else {
      if (!R.isEmpty(menuPriceSources)) {
        useFormApi.reset({
          ...useFormApi.getValues(),
          [FIELDS.MENU_PRICING_LIST]: R.omit(["id"], menuPriceSources),
        });
      }
    }
  }, [menuId, menuPriceSources, priceSources, useFormApi]);

  // "regions" change: wait for regions to be fetched.
  useEffect(() => {
    if (menuId) {
      if (!R.isNil(menuRegions)) {
        const values = useFormApi.getValues();
        const newRegions = menuRegions.map(({ id, region, ...rest }) => ({ value: id, label: region, ...rest }));

        useFormApi.reset({
          ...values,
          [FIELDS.PRELIMINARY_INFORMATION]: {
            ...values[FIELDS.PRELIMINARY_INFORMATION],
            [FIELDS.REGIONS]: newRegions,
          },
        });
      }
    }
  }, [menuId, menuRegions, useFormApi]);

  // "procurement sources" change: wait for price sources to be fetched.
  useEffect(() => {
    if (!menuId) {
      if (!R.isEmpty(procurementSources)) {
        const values = useFormApi.getValues();

        useFormApi.reset({
          ...values,
          [FIELDS.PRELIMINARY_INFORMATION]: {
            ...values[FIELDS.PRELIMINARY_INFORMATION],
            [FIELDS.SOURCING_CHANNELS]: [],
          },
        });
      }
    } else {
      if (!R.isEmpty(procurementSources) && !R.isNil(menuSourcingChannels)) {
        const values = useFormApi.getValues();
        const newSourcingChannels = procurementSources.filter((source) => menuSourcingChannels.includes(source.value));

        useFormApi.reset({
          ...values,
          [FIELDS.PRELIMINARY_INFORMATION]: {
            ...values[FIELDS.PRELIMINARY_INFORMATION],
            [FIELDS.SOURCING_CHANNELS]: newSourcingChannels,
          },
        });
      }
    }
  }, [menuId, menuSourcingChannels, procurementSources, useFormApi]);

  // "initValues" change: wait for init values to be ready.
  useEffect(() => {
    if (!R.isEmpty(initValues)) {
      useFormApi.reset({ ...useFormApi.getValues(), ...initValues });
    }
  }, [initValues, useFormApi]);

  // "restriction" change: wait for it to be fetched.
  useEffect(() => {
    if (!R.isNil(restriction)) {
      // "item_id" | "major_group_id" | "minor_group_id"
      const kindRestriction = Object.keys(restriction).filter((el) => el !== "name")[0];
      let restrictionContent = restriction[kindRestriction];

      if (kindRestriction.includes("item")) {
        // "item_id": has both matched and unmatched.
        const matching_with = restrictionContent.matching_with.map((el) => ({ label: el, value: el }));
        const unmatched_with = restrictionContent.unmatched_with.map((el) => ({ label: el, value: el }));
        restrictionContent = { ...restrictionContent, matching_with, unmatched_with };
      } else {
        // "major_group_id" | "minor_group_id": has unmatched only.
        const unmatched_with = restrictionContent.unmatched_with.map((el) => ({ label: el, value: el }));
        restrictionContent = { ...restrictionContent, unmatched_with };
      }

      // "major_group_id" | "minor_group_id": handle max_product_repetition being null.
      if (!kindRestriction.includes("item")) {
        const max_product_repetition = R.isNil(restrictionContent["food_group_restriction"].max_product_repetition)
          ? ""
          : restrictionContent["food_group_restriction"].max_product_repetition;
        restrictionContent = {
          ...restrictionContent,
          food_group_restriction: { ...restrictionContent["food_group_restriction"], max_product_repetition },
        };
      }

      useFormApi.reset({ ...useFormApi.getValues(), [FIELDS.RESTRICTION]: { ...restrictionContent } });
    }
  }, [restriction, useFormApi]);

  // "basket" change
  useEffect(() => {
    // We have to save newly added food items before overriding local basket so we prevent losing them.
    const values = useFormApi.getValues();
    const prevBasket = values[FIELDS.BASKET];
    const arrayNewlyAddedFoodItems = Object.entries(prevBasket).filter(
      ([, item]) =>
        item.user_provided_item ||
        (Object.values(item.prices).filter((price) => price.added_by_user) &&
          Object.keys(basket).includes(item.value.toString()))
    );

    if (menuId) {
      // Use basket coming from menu details instead of relying on food-basket-v2
      let tempBasket = menuBasket;

      if (!R.isEmpty(basket) && !R.isEmpty(prevBasket)) {
        // use the food-basket-v2 data instead of the one from the menu details, and also add the menuBasket's
        // custom menu items. However, only do this when the menuBasket has already been in the form's values
        // (i.e. when prevBasket actually has the data from menuStructure, so that the menu's custom items
        // can be correctly copied)
        tempBasket = { ...basket, ...Object.fromEntries(arrayNewlyAddedFoodItems) };
      }

      // Use basket coming from menu details instead of relying on food-basket-v2
      useFormApi.reset({
        ...values,
        [FIELDS.BASKET]: tempBasket,
      });
    } else {
      useFormApi.reset({
        ...values,
        [FIELDS.BASKET]: { ...basket, ...Object.fromEntries(arrayNewlyAddedFoodItems) },
      });
    }
  }, [basket, menuBasket, menuId, useFormApi]);

  // Handling of "target_group", "seasonality", "price_source_validity", "start_month", "end_month", "days_in_week" and "week_count"
  useEffect(() => {
    if (menuId) {
      if (!R.isNil(menuPreliminaryInformation)) {
        const values = useFormApi.getValues();

        useFormApi.reset({
          ...values,
          [FIELDS.PRELIMINARY_INFORMATION]: {
            ...values[FIELDS.PRELIMINARY_INFORMATION],
            [FIELDS.TARGET_GROUP]: menuPreliminaryInformation[FIELDS.TARGET_GROUP],
            [FIELDS.SEASONALITY]:
              menuPreliminaryInformation[FIELDS.START_MONTH] === 1 &&
              menuPreliminaryInformation[FIELDS.END_MONTH] === 12
                ? "y"
                : "m",
            [FIELDS.PRICE_SOURCE_VALIDITY]: menuPreliminaryInformation[FIELDS.PRICE_SOURCE_VALIDITY],
            [FIELDS.START_MONTH]: menuPreliminaryInformation[FIELDS.START_MONTH],
            [FIELDS.END_MONTH]: menuPreliminaryInformation[FIELDS.END_MONTH],
            [FIELDS.DAYS_IN_WEEK]: menuPreliminaryInformation[FIELDS.DAYS_IN_WEEK],
            [FIELDS.WEEK_COUNT]: menuPreliminaryInformation[FIELDS.WEEK_COUNT],
          },
        });
      }
    }
  }, [menuId, menuPreliminaryInformation, useFormApi]);

  return useMemo(() => ({ useFormApi }), [useFormApi]);
};
