import { colors } from "@wfp/ui";

import { Text } from "components";

const Sentence = ({ value, textAlign }) => (
  <Text
    wrap
    bold
    fontSize="18px"
    marginTop="20px"
    marginBottom="10px"
    textAlign={textAlign}
    defaultColor={colors["text-02"].hex}
    value={value}
  />
);

export default Sentence;
