import React, { useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useResponsiveHook } from "hooks";
import { Module, Button, TextArea } from "components";
import { ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import buttonMessages from "components/Button/messages";
import { updateMenu } from "containers/Menu/actions";
import { selectMenuLoading } from "containers/Menu/selectors";
import { selectPermissions } from "containers/App/selectors";
import { PERMISSIONS } from "containers/Admin/constants";
import { checkPermission } from "components/Unlock/utils";

function Notes({ menuId, notes }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();
  const loading = useSelector(selectMenuLoading);
  const userPermissions = useSelector(selectPermissions);

  const [notesValue, setNotesValue] = useState(notes);

  const hasChangePermission = useMemo(
    () => checkPermission(userPermissions, PERMISSIONS.CHANGE_COMMUNITY_MENU),
    [userPermissions],
  );

  const onChange = useCallback((e) => {
    setNotesValue(e.target.value);
  }, []);

  const onSave = useCallback(() => {
    dispatch(updateMenu({ id: menuId, body: { notes: notesValue } }));
  }, [dispatch, menuId, notesValue]);

  const disableSave = useMemo(() => notes === notesValue, [notes, notesValue]);

  const save = useMemo(
    () => (
      <ColStyled padding="0">
        <Button
          widthAuto
          disabled={disableSave || loading}
          children={intl.formatMessage(buttonMessages.save)}
          onClick={onSave}
        />
      </ColStyled>
    ),
    [disableSave, loading, onSave, intl],
  );

  return (
    <ColStyled xs={12}>
      <Module
        noLine
        padding={isTabletOrMobile && "0"}
        alignBottom="xs"
        marginHeader="0 0 10px"
        margin="0 0 10px"
        title={intl.formatMessage(menuMessages.notes)}
        elementsOnRight={hasChangePermission && save}
      >
        <TextArea disabled={!hasChangePermission} labelText="" rows={8} value={notesValue} onChange={onChange} />
      </Module>
    </ColStyled>
  );
}

export default Notes;
