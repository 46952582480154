import styled, { css } from "styled-components";
import { colors } from "@wfp/ui";

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  padding: 0.7rem 1.2rem;
  background: #edf0f3;

  &:hover {
    background: rgba(0, 0, 0, 0.1);

    > p {
      color: ${colors["brand-01"].hex};
    }

    svg {
      fill: ${colors["brand-01"].hex};
    }
  }
`;

export const FirstLevelContainerText = styled(ContainerText) <{ noSelection: boolean, selected: boolean }>`
  border-right: ${({ noSelection }) => (noSelection ? 0 : `1px solid ${colors["brand-01-30"].hex}`)};

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid ${colors["brand-01-30"].hex};
      border-right: 0;
      background: #fff;
    `};
`;

export const SecondLevelContainerText = styled(ContainerText)`
  background: #fff;

  &:first-child {
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-right-radius: 0.5rem;
  }
`;

export const FirstLevelWrapper = styled.div`
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: hidden;
`;

export const SecondLevelWrapper = styled.div<{ maxHeight: string }>`
  height: 100%;
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  overflow-y: scroll;
  border: 1px solid ${colors["brand-01-30"].hex};
  border-left: 0;

  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface IconStyledProps {
  marginInlineEnd: string,
  disabled?: boolean,
  // Children is used by styled componenets but attribute does not exist
  // in other parts of the type for some reason. I am adding this so type check could pass.
  children: any,
}

export const IconStyled = styled.div<IconStyledProps>`
  width: 30px;
  margin-inline-end: ${({ marginInlineEnd }) => (marginInlineEnd ? `${marginInlineEnd}px` : "10px")};
  svg > g > g {
    fill: ${({ disabled }) => (disabled ? "rgb(90, 104, 114)" : "rgb(0, 125, 188)")};
  }
`;
