import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import { RowStyled, ColStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import { FIELDS } from "containers/FoodBasket/constants";
import { validation } from "./validation";

const Footer = ({ close, save }) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const personalItem = useWatch({ control, name: FIELDS.PERSONAL_ITEM });

  const validate = useMemo(() => validation(personalItem), [personalItem]);

  return (
    <RowStyled end="xs" flex={1} padding="0.8rem 0">
      <ColStyled>
        <Button
          widthAuto
          marginRight="10px"
          kind="secondary"
          onClick={close}
          children={intl.formatMessage(buttonMessages.cancel)}
        />
        <Button widthAuto disabled={validate} onClick={save} children={intl.formatMessage(buttonMessages.save)} />
      </ColStyled>
    </RowStyled>
  );
};

export default Footer;
