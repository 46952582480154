import React from "react";
import { Switch, Route } from "react-router-dom";
import routesPath from "containers/App/Routes/paths";
import Users from "./Users";
import Countries from "./Countries";
import { withAuth } from "hocs";

const Admin = () => {
  return (
    <Switch>
      <Route path={routesPath.adminUsers} component={Users} />
      <Route path={routesPath.adminCountries} component={Countries} />
    </Switch>
  );
};

export default withAuth(Admin);
