import {IntlShape} from 'react-intl';

import {FoodRestrictionType} from './types';

import messages from "./messages";

export const FIELDS = {
  MACRONUTRIENTS: "macronutrients",
  MICRONUTRIENTS: "micronutrients",
  macronutrient: {
    CHECKBOX: "all_macronutrient_restricted",
    PERCENTAGE: "macronutrient_restriction",
  },
  micronutrient: {
    CHECKBOX: "all_micronutrient_restricted",
    PERCENTAGE: "micronutrient_restriction",
  },
  MENU_GENERAL_PARAMETER: "menu_general_parameter",
  NUTRITION_LEVEL: "nutrition_level",
  RECOMMENDED: "recommended_nutritional_requirements",
};

export const NUTRITION_LEVELS = {
  RECOMMENDED: "recommended",
  BASIC: "basic",
  OBJECTIVE: "objective",
  ANEMIA: "anemia",
  CUSTOM: "custom",
};

export const options = (intl: IntlShape, recommendedNutrients = true) => {
  const optionsArray = [
    { value: NUTRITION_LEVELS.RECOMMENDED, label: intl.formatMessage(messages.recommended) },
    { value: NUTRITION_LEVELS.BASIC, label: intl.formatMessage(messages.basic) },
    { value: NUTRITION_LEVELS.OBJECTIVE, label: intl.formatMessage(messages.objective) },
    { value: NUTRITION_LEVELS.ANEMIA, label: intl.formatMessage(messages.anemia) },
    { value: NUTRITION_LEVELS.CUSTOM, label: intl.formatMessage(messages.custom) },
  ];

  return recommendedNutrients ? optionsArray : optionsArray.slice(1);
};

export const nutrientsSet = {
  [NUTRITION_LEVELS.BASIC]: {
    macronutrients: 30,
    micronutrients: 30,
  },
  [NUTRITION_LEVELS.OBJECTIVE]: {
    macronutrients: 30,
    micronutrients: 50,
  },
  [NUTRITION_LEVELS.ANEMIA]: {
    macronutrients: 30,
    micronutrients: 70,
  },
};
// there is a correspondant constant in the backend for this.
// it is in planner/constants/FoodRestrictionType
export const FOOD_RESTRICTION_TYPES = {
  INCLUDE: 'include' as FoodRestrictionType,
  EXCLUDE: 'exclude' as FoodRestrictionType,
  DEFAULT: 'default' as FoodRestrictionType,
};
