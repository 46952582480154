import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { RowStyled, ColStyled } from "components/utils";
import { Link, Tag } from "@wfp/ui";
import { useHtmlHook } from "hooks";
import { defaultFontStyle } from "utils/utils";
import { selectProfile } from "../selectors";
import routesPath from "containers/App/Routes/paths";

export default function LogoAndCountry({ userIsInactive }) {
  const dispatch = useDispatch();
  const { isRTL } = useHtmlHook();
  const profile = useSelector(selectProfile);

  const clickOnLogo = useCallback(() => dispatch(push(routesPath.menus)), [dispatch]);

  return (
    <RowStyled middle="xs">
      <Link onClick={clickOnLogo} style={defaultFontStyle}>
        {" SMP PLUS"}
      </Link>
      <ColStyled margin={isRTL ? "0 10px 0 0" : "0 0 0 10px"}>
        {!userIsInactive && <Tag type="info" children={profile.country_name} style={defaultFontStyle} />}
      </ColStyled>
    </RowStyled>
  );
}
