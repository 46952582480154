import { defineMessages } from "react-intl";

export default defineMessages({
  name: {
    id: "menu.titleName",
    defaultMessage: "Menu name",
  },
  placeholderName: {
    id: "menu.placeholderName",
    defaultMessage: "Write menu name",
  },
  yearly: {
    id: "menu.yearly",
    defaultMessage: "Yearly",
  },
  on: {
    id: "menu.on",
    defaultMessage: "{name} on {date}",
  },
  regional: {
    id: "menu.regional",
    defaultMessage: "Regional",
  },
  wholeCountry: {
    id: "menu.wholeCountry",
    defaultMessage: "Whole country",
  },
  allRegions: {
    id: "menu.allRegions",
    defaultMessage: "All regions",
  },
  regions: {
    id: "menu.regions",
    defaultMessage: "regions: {regions}",
  },
  daysAndWeeks: {
    id: "menu.daysAndWeeks",
    defaultMessage: "{days_in_week} days x {week_count} weeks",
  },
  today: {
    id: "menu.today",
    defaultMessage: "Today",
  },
  sourcing: {
    id: "menu.sourcing",
    defaultMessage: "Sourcing channels",
  },
  sourcingInfo: {
    id: "menu.sourcingInfo",
    defaultMessage: "Sources from where the food items will be supplied",
  },
  sourcingAll: {
    id: "menu.sourcingAll",
    defaultMessage: "-- All --",
  },
  averageSourcing: {
    id: "menu.averageSourcing",
    defaultMessage: "Average sourcing channels",
  },
  costPerMeal: {
    id: "menu.costPerMeal",
    defaultMessage: "Cost per meal",
  },
  averageCostPerMeal: {
    id: "menu.averageCostPerMeal",
    defaultMessage: "Average cost per meal",
  },
  foodItems: {
    id: "menu.foodItems",
    defaultMessage: "Food items",
  },
  foodGroups: {
    id: "menu.foodGroups",
    defaultMessage: "Food groups",
  },
  foodItemsLower: {
    id: "menu.foodItemsLower",
    defaultMessage: "food items",
  },
  foodGroupsLower: {
    id: "menu.foodGroupsLower",
    defaultMessage: "food groups",
  },
  composition: {
    id: "menu.composition",
    defaultMessage: "Menu composition",
  },
  wholeMenu: {
    id: "menu.wholeMenu",
    defaultMessage: "Whole menu",
  },
  week: {
    id: "menu.week",
    defaultMessage: "Week {week}",
  },
  shortWeek: {
    id: "menu.shortWeek",
    defaultMessage: "W {week}",
  },
  day: {
    id: "menu.day",
    defaultMessage: "Day {day}",
  },
  genericSuccess: {
    id: "success.genericSuccess",
    defaultMessage: "Success",
  },
  menuDeleted: {
    id: "success.menuDeleted",
    defaultMessage: "Menu successfully deleted",
  },
  menuUpdated: {
    id: "success.menuUpdated",
    defaultMessage: "Menu successfully updated",
  },
  menuCreated: {
    id: "success.menuCreated",
    defaultMessage: "Menu successfully created",
  },
  menuShared: {
    id: "success.menuShared",
    defaultMessage: "Menu successfully shared",
  },
  notes: {
    id: "menu.notes",
    defaultMessage: "Notes",
  },
  recipe: {
    id: "menu.recipe",
    defaultMessage: "Recipe {recipe}",
  },
  draft: {
    id: "menu.draft",
    defaultMessage: "Draft",
  },
});
