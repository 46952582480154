import React, { useCallback, useMemo } from "react";
import * as R from "ramda";
import Text from "components/Text";
import Select from "react-select";
import { ReadOnly } from "./styles";
import { colors } from "@wfp/ui";

export default function CustomSelect({
  options,
  filteredOptions,
  onChangeCustom,
  input,
  className,
  readOnly,
  labelText,
  keyToGet,
  placeholder,
  fontSizeLabel,
  fontColorLabel,
  menuPlacement,
  isMulti,
  capitalize,
  height,
  onChangeCustomOnly,
  objectInsideArray,
}) {
  const selectedValue = useMemo(
    () =>
      options
        ? options.find((el) => {
            return !objectInsideArray ? R.equals(el.value, input.value) : R.equals(el, input.value);
          })
        : "",
    [input.value, objectInsideArray, options],
  );

  // If we only want to trigger the onChangeCustom function being passed in, we also pass in onChangeCustomOnly set to true. This way, we prevent running input.onChange and the onChangeCustom function, which in some case run once again the onChange with a different behavior, leading to multiple and unnecessary component changes.
  const handleChange = useCallback(
    (element) => {
      if (onChangeCustomOnly) {
        onChangeCustom(element);
      } else {
        input.onChange(element[keyToGet || "value"]);
        if (onChangeCustom) onChangeCustom(element);
      }
    },
    [input, keyToGet, onChangeCustom, onChangeCustomOnly],
  );

  const capitalizeValues = useMemo(() => (capitalize ? { textTransform: "capitalize" } : {}), [capitalize]);
  const fixedHeight = useMemo(() => (height ? { height, minHeight: height } : {}), [height]);

  const styles = useMemo(() => {
    return {
      menu: (styles) => ({ ...styles, zIndex: 9999, ...capitalizeValues, ...fixedHeight }),
      menuList: (styles) => ({ ...styles, fixedHeight }),
      // Selected option
      singleValue: (styles, { data }) => ({
        ...styles,
        ...capitalizeValues,
        color: data?.value?.is_priority ? colors["support-02"].hex : colors["text-01"].hex,
      }),
      // All available options
      option: (styles, { data }) => ({
        ...styles,
        color: data?.value?.is_priority ? colors["support-02"].hex : colors["text-01"].hex,
      }),
    };
  }, [capitalizeValues, fixedHeight]);

  return (
    <>
      {labelText && (
        <Text bold marginBottom="5px" fontSize={fontSizeLabel} defaultColor={fontColorLabel} value={labelText} />
      )}
      {readOnly ? (
        <ReadOnly>{selectedValue && selectedValue.label}</ReadOnly>
      ) : (
        <Select
          isMulti={isMulti}
          className={`wfp--react-select-container ${className || ""}`}
          classNamePrefix="wfp--react-select"
          styles={styles}
          menuPlacement={menuPlacement || "auto"}
          options={filteredOptions || options || []}
          value={input.value ? selectedValue : ""}
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}
    </>
  );
}
