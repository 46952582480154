import { FormattedMessage } from "react-intl";

import messages from "./messages";

const WeeklyHelper = () => {
  return (
    <div style={{ fontSize: "12px", padding: "15px" }}>
      <b>{<FormattedMessage {...messages.dailyFirstRow} />}</b>
      <ul className="listStyle">
        <li className="dotStyle">{<FormattedMessage {...messages.weeklyFirstRow} />}</li>
        <li className="dotStyle">{<FormattedMessage {...messages.weeklySecondRow} />}</li>
        <li className="dotStyle">{<FormattedMessage {...messages.weeklyThirdRow} />}</li>
      </ul>
    </div>
  );
};

export default WeeklyHelper;
