import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import { RowStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import { createOrUpdateFoodPriceRequest } from "containers/FoodBasket/actions";
import { FIELDS } from "containers/FoodBasket/constants";
import { useInitialValues } from "containers/FoodBasket/useInitialValues";
import { getSummaryPricesFilters } from "containers/FoodBasket/utils";
import { reworkData } from "containers/FoodBasket/utils";
import { ButtonsContainer } from "./styles";
import { validation } from "./validation";

const Footer = ({ close }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control, getValues, reset } = useFormContext();
  const formValues = useWatch({ control });
  const initialValues = useInitialValues();

  const validate = useMemo(() => validation(formValues[FIELDS.PERSONAL_ITEM]), [formValues]);

  const resetFields = useCallback(() => {
    const initialPersonalItem = initialValues[FIELDS.PERSONAL_ITEM];
    reset({ ...formValues, [FIELDS.PERSONAL_ITEM]: initialPersonalItem });
  }, [formValues, initialValues, reset]);

  // When clicking "Save and add another", persist everything but food item and price fields
  const partialResetFields = useCallback(() => {
    const initialPersonalItem = initialValues[FIELDS.PERSONAL_ITEM];
    reset({
      ...formValues,
      [FIELDS.PERSONAL_ITEM]: {
        ...formValues[FIELDS.PERSONAL_ITEM],
        [FIELDS.ITEM]: initialPersonalItem[FIELDS.ITEM],
        [FIELDS.PRICE]: initialPersonalItem[FIELDS.PRICE],
      },
    });
  }, [formValues, initialValues, reset]);

  const resetAndClose = useCallback(() => {
    resetFields();
    close();
  }, [close, resetFields]);

  const save = useCallback(() => {
    const { id, ...data } = reworkData(formValues[FIELDS.PERSONAL_ITEM]);
    const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
    dispatch(createOrUpdateFoodPriceRequest({ id, data, filters, cb: resetAndClose }));
  }, [dispatch, formValues, getValues, resetAndClose]);

  const saveAndAddAnother = useCallback(() => {
    const { id, ...data } = reworkData(formValues[FIELDS.PERSONAL_ITEM]);
    const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
    dispatch(createOrUpdateFoodPriceRequest({ id, data, filters, cb: partialResetFields }));
  }, [dispatch, formValues, getValues, partialResetFields]);

  return (
    <RowStyled end="xs" flex={1} padding="0.8rem 0">
      <ButtonsContainer>
        <Button
          widthAuto
          marginRight="10px"
          kind="secondary"
          onClick={close}
          children={intl.formatMessage(buttonMessages.cancel)}
        />
        <Button
          widthAuto
          marginRight="10px"
          disabled={validate}
          onClick={save}
          children={intl.formatMessage(buttonMessages.save)}
        />
        <Button
          widthAuto
          disabled={validate}
          onClick={saveAndAddAnother}
          children={intl.formatMessage(buttonMessages.saveAndAdd)}
        />
      </ButtonsContainer>
    </RowStyled>
  );
};

export default Footer;
