import { useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import { FormProvider, useWatch } from "react-hook-form";
import { colors, Loading } from "@wfp/ui";
import { withAuth, withLanguage } from "hocs";

import { Layout } from "components";
import { selectPermissions, selectLocalCurrency, selectEnabledFeatures } from "containers/App/selectors";
import { resetMenu } from "containers/Menu/actions";
import { FEATURES, USD } from "utils/constants";
import { loadResults, resetResults } from "./actions";
import { FIELDS } from "./constants";
import DayComposition from "./DayComposition";
import FoodInfoAndCosts from "./FoodInfoAndCosts";
import Header from "./Header";
import MenuComposition from "./MenuComposition";
import Notes from "./Notes";
import NutritionalValues from "./NutritionalValues";
import { selectLoading, selectMenuScores } from "./selectors";
import SourcingChannels from "./SourcingChannels";
import { ResultsContainer, StatsContainer } from "./styles";
import SuboptimalWarning from "./SuboptimalWarning";
import { useAsyncForm } from "./useInitialValues";

const Results = () => {
  const dispatch = useDispatch();
  const { id: menuId } = useParams();

  const loadingResults = useSelector(selectLoading);
  const userPermissions = useSelector(selectPermissions);
  const localCurrency = useSelector(selectLocalCurrency);
  const enabledFeatures = useSelector(selectEnabledFeatures);
  const menuScores = useSelector(selectMenuScores);

  const [dayToShow, setDayToShow] = useState();
  const [currency, setCurrency] = useState(localCurrency ? { label: localCurrency, value: localCurrency } : USD);

  const { useFormApi } = useAsyncForm();

  useEffect(() => {
    // If we don't have yet user permissions, do not fetch. Permissions check will be done saga side since we can't know in advance the menu type.
    if (!R.isEmpty(userPermissions)) {
      dispatch(loadResults({ id: menuId }));
    }

    return () => {
      dispatch(resetResults());
      dispatch(resetMenu());
    };
  }, [dispatch, menuId, userPermissions]);

  // Since we want the localCurrency to be the default currency, we
  // need this useEffect to update the "currency" value when "localCurrency" is finally loaded
  useEffect(() => {
    if (localCurrency) {
      setCurrency({ label: localCurrency, value: localCurrency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCurrency]);

  const formMenuComposition = useWatch({ control: useFormApi.control, name: FIELDS.MENU_COMPOSITION });
  const formMenuIncludedRecipes = useWatch({ control: useFormApi.control, name: FIELDS.INCLUDED_RECIPES });
  const allValues = useMemo(() => {
    const ingredients = Object.keys(formMenuComposition).map((el) => formMenuComposition[el]);
    const recipe_ingredients = Object.values(formMenuIncludedRecipes).map((recipes) =>
      recipes.map((recipe) => Object.values(recipe.ingredients))
    );
    return R.flatten([recipe_ingredients, ingredients]);
  }, [formMenuComposition, formMenuIncludedRecipes]);

  const enabledMenuScoreFeature = useMemo(() => enabledFeatures.includes(FEATURES.MENU_SCORE), [enabledFeatures]);

  return (
    <FormProvider {...useFormApi}>
      <Layout backgroundColor={colors["ui-02"].hex}>
        <Loading active={loadingResults} />
        <Header menuId={menuId} />
        <SuboptimalWarning />
        <ResultsContainer>
          <StatsContainer>
            <FoodInfoAndCosts
              menuComposition={allValues}
              currency={currency}
              setCurrency={setCurrency}
              menuScores={menuScores}
              enabledMenuScoreFeature={enabledMenuScoreFeature}
            />
            <NutritionalValues values={allValues} />
            <SourcingChannels menuComposition={allValues} />
          </StatsContainer>

          <div style={{ display: dayToShow ? "none" : "block" }}>
            {!R.isEmpty(formMenuComposition) && (
              <MenuComposition
                menuComposition={formMenuComposition}
                setDayToShow={setDayToShow}
                currency={currency}
                menuScores={menuScores}
                enabledMenuScoreFeature={enabledMenuScoreFeature}
              />
            )}
          </div>

          <div style={{ display: dayToShow ? "block" : "none" }}>
            {dayToShow ? <DayComposition dayToShow={dayToShow} setDayToShow={setDayToShow} /> : null}
          </div>

          <Notes menuId={menuId} />
        </ResultsContainer>
      </Layout>
    </FormProvider>
  );
};

export default compose(withAuth, withLanguage)(Results);
