import { useCallback } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button, InlineLoading } from "components";
import { RowStyled, ColStyled } from "components/utils";
import messages from "components/Button/messages";
import { SearchHookForm } from "components/Form";
import { searchFoodItemsRequest } from "containers/FoodBasket/actions";
import { FIELDS } from "containers/FoodBasket/constants";
import OrderByAndFilter from "./OrderByAndFilter";
import { selectWeightChoices } from "containers/App/selectors";
import { useCurrencies } from "hooks";

const Header = ({ visibleFilters, setVisibleFilters, closeFiltersAndFetchFoodItems, closeFilters }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control } = useFormContext();
  const formFilters = useWatch({ control, name: FIELDS.FILTERS });

  const fetchFoodItems = useCallback(() => {
    dispatch(searchFoodItemsRequest({ body: formFilters }));
  }, [dispatch, formFilters]);

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        fetchFoodItems();
      }
    },
    [fetchFoodItems],
  );

  const units = useSelector(selectWeightChoices);
  const currencies = useCurrencies();

  if (R.isEmpty(units) || R.isEmpty(currencies)) return <InlineLoading />;

  return (
    <RowStyled middle="xs" between="xs">
      <ColStyled xs={4}>
        <RowStyled>
          <SearchHookForm control={control} name={`${FIELDS.FILTERS}.${FIELDS.SEARCH}`} onKeyUp={handleEnterPress} />
          <Button widthAuto marginLeft="8px" onClick={fetchFoodItems} children={intl.formatMessage(messages.search)} />
        </RowStyled>
      </ColStyled>
      <ColStyled xs>
        <OrderByAndFilter
          visibleFilters={visibleFilters}
          setVisibleFilters={setVisibleFilters}
          closeFiltersAndFetchFoodItems={closeFiltersAndFetchFoodItems}
          closeFilters={closeFilters}
        />
      </ColStyled>
    </RowStyled>
  );
};

export default Header;
