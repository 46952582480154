import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Blockquote } from "@wfp/ui";

import { Text } from "components";
import messages from "containers/Menu/nutMessages";
import { selectMenuOptimizationType } from "containers/Menu/selectors";
import { SUBOPTIMAL } from "containers/MyMenus/labels";

const SuboptimalWarning = () => {
  const type = useSelector(selectMenuOptimizationType);

  return (
    R.equals(type, SUBOPTIMAL) && (
      <Blockquote margin="20px 0 0" kind="warning">
        <Text fontSize="18px" wrap value={<FormattedMessage {...messages.warningNutrients} />} />
      </Blockquote>
    )
  );
};

export default SuboptimalWarning;
