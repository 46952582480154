import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectFoodBasket = () => (state) => R.pathOr(initialState, ["foodBasket"], state);

export default () => createSelector(selectFoodBasket(), (foodBasketState) => foodBasketState);

export const selectLoading = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(false, ["loading"], foodBasketState),
);

export const selectFoodItems = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr([], ["items"], foodBasketState),
);

export const selectFCTFoodItems = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr([], ["fctItems"], foodBasketState),
);

export const selectLoadingSummaryPrices = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(false, ["loadingSummaryPrices"], foodBasketState),
);

export const selectLoadingFoodItems = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(false, ["loadingFoodItems"], foodBasketState),
);

export const selectLoadingFCTFoodItems = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(false, ["loadingFCTFoodItems"], foodBasketState),
);

export const selectLoadingFoodPrice = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(false, ["loadingFoodPrice"], foodBasketState),
);

export const selectSummary = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr(null, ["summary"], foodBasketState),
);

export const selectNutrients = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr([], ["nutrients"], foodBasketState),
);

export const selectMajorGroups = createSelector(selectFoodBasket(), (foodBasketState) =>
  R.pathOr([], ["majorGroups"], foodBasketState),
);
