import { call, select, all, put, takeLatest } from "redux-saga/effects";
import * as R from "ramda";
import { setAlert, loadAllNutritionalValuesStarted, loadAllNutritionalValuesSuccess } from "containers/App/actions";
import {
  getFoodItemsMenu,
  getNutrionalValuesMenu,
  postFoodItemsCommunityMenu,
  getFoodItemsCommunityMenu,
  getNutrionalValuesCommunityMenu,
  postCommunityFoodPrices,
  getSingleNutrionalValuesCommunityMenu,
  getListGroupsAndItems,
  getAllNutritionalValuesCommunityMenu,
  patchMenuMealNames,
  postSelectedFoodItem,
  patchSelectedFoodItem,
  deleteSelectedFoodItem,
  getAvailableRecipes,
  getMenuRecipes,
  postMenuRecipe,
  deleteMenuRecipe,
  createRecipeFromSelectedItems,
  getMenuScores,
} from "api/api";
import { loadMenuSaga, loadCommunityMenuSaga } from "containers/Menu/sagas";
import { selectMealNames } from "containers/Menu/selectors";
import { loadPartialPlusMenuSaga, loadPartialCommunityPlusMenuSaga } from "containers/OptimizedMenu/sagas";
import {
  loadStarted,
  loadSuccess,
  loadEnded,
  loadResults,
  loadCommunityResults,
  loadSingleNutritionalValuesStarted,
  loadSingleNutritionalValuesEnded,
  loadSingleNutritionalValues,
  loadSingleNutritionalValuesSuccess,
  loadCommunityGroupsAndItemsSuccess,
  saveAllCommunityResults,
  saveResultsRequest,
} from "./actions";
import { loadCurrencySaga, loadConstantsSaga } from "containers/App/sagas";
import { selectCurrency, selectPermissions, selectNutritionalValues } from "containers/App/selectors";
import messages from "containers/Menu/messages";
import { FIELDS, ADDITIONAL_FIELDS } from "containers/ResultsCommunity/Header/constants";
import { configLocalForage, readFromLocalForage, writeToLocalForage } from "utils/utils";
import { MANUAL } from "containers/MyMenus/labels";
import { checkPermission } from "components/Unlock/utils";
import { PERMISSIONS } from "containers/Admin/constants";
import { push } from "connected-react-router";
import { validate as uuidValidate } from "uuid";

export function* loadAllCommunityNutritionalValues() {
  try {
    return yield call(getAllNutritionalValuesCommunityMenu);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadPartialDiversity(id) {
  try {
    return yield call(loadPartialPlusMenuSaga, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadCommunityPartialDiversity(id) {
  try {
    return yield call(loadPartialCommunityPlusMenuSaga, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadFoodItems(id) {
  try {
    return yield call(getFoodItemsMenu, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadCommunityFoodItems(id) {
  try {
    return yield call(getFoodItemsCommunityMenu, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadNutritionalValues(ids) {
  try {
    return yield call(getNutrionalValuesMenu, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadCommunityNutritionalValues(ids) {
  try {
    return yield call(getNutrionalValuesCommunityMenu, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}


export function* loadAvailableRecipes(id) {
  try {
    return yield call(getAvailableRecipes, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadMenuRecipes(id) {
  try {
    return yield call(getMenuRecipes, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadMenuScores(id) {
  try {
    return yield call(getMenuScores, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}


export function* loadCommunityGroupsAndItemsSaga(id) {
  try {
    const results = yield call(getListGroupsAndItems, id);
    yield put(
      loadCommunityGroupsAndItemsSuccess({
        majorGroups: results.major_groups.map((item) => ({
          value: item.major_id,
          label: item.major_label,
        })),
      })
    );
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* saveFoodItemsCommunityMenu(id, body) {
  try {
    return yield call(postFoodItemsCommunityMenu, id, { selected_food_items: body });
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* postSelectedFoodItemSaga(id, foodItem) {
  try {
    return yield call(postSelectedFoodItem, id, foodItem);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* patchSelectedFoodItemSaga(id, foodItemId, foodItem) {
  try {
    return yield call(patchSelectedFoodItem, id, foodItemId, foodItem);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* deleteSelectedFoodItemSaga(id, foodItemId) {
  try {
    return yield call(deleteSelectedFoodItem, id, foodItemId);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadAllCommunityNutritionalValuesSaga(country) {
  try {
    configLocalForage();

    // If nutrients exist, we we don't need to fetch them once again
    const key = `nutritionalValues-${country}`;
    const nutrients = yield call(readFromLocalForage, key);
    if (nutrients) {
      yield put(loadAllNutritionalValuesSuccess({ nutritionalValues: nutrients }));
      return;
    }

    yield put(loadAllNutritionalValuesStarted());
    const nutritionalValues = yield call(loadAllCommunityNutritionalValues);
    if (nutritionalValues) {
      yield call(writeToLocalForage, { key, value: nutritionalValues });
      yield put(loadAllNutritionalValuesSuccess({ nutritionalValues }));
    }
  } catch (e) {
    yield put(loadAllNutritionalValuesSuccess({}));
    yield put(setAlert(e));
  }
}

export function* updateCommunityFoodPricesSaga(id, body) {
  try {
    // Filter foods by performing validation before sending data. If one or more food items fail it, we don't send those to the BE to prevent 400 errors.
    const foods = Object.keys(body)
      .filter((label) => {
        const item = body[label];

        if (item[FIELDS.PRICE] === 0 || !item[FIELDS.PRICE]) {
          return false;
        }

        const price = String(item[FIELDS.PRICE]).split(".");
        if (price[1] && price[1].length > 2) {
          return false;
        }

        if (!R.isEmpty(item[ADDITIONAL_FIELDS.ADDITIONAL_COSTS])) {
          item[ADDITIONAL_FIELDS.ADDITIONAL_COSTS].forEach((el) => {
            if (!el[ADDITIONAL_FIELDS.UNIT] || !el[ADDITIONAL_FIELDS.VALUE] || !el[ADDITIONAL_FIELDS.TYPE]) {
              return false;
            }
          });
        }

        return true;
      })
      .reduce((acc, label) => ({ ...acc, [label]: body[label] }), {});

    const payload = Object.keys(foods).map((item) => ({
      additional_costs: foods[item].additional_costs,
      item: foods[item].food_item,
      price: parseFloat(foods[item].price.toFixed(2)),
      price_currency: foods[item].price_currency,
      quantity: foods[item].quantity,
      source: foods[item].source,
    }));

    yield call(postCommunityFoodPrices, id, payload);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadResultsSaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    const currency = yield select(selectCurrency);

    const { preliminary_information, type } = yield call(loadMenuSaga, { payload: { id } });

    // If user doesn't have permission to view this type of menu, do not load additional information
    const userPermissions = yield select(selectPermissions);
    const permissionToBeChecked = type === MANUAL ? PERMISSIONS.VIEW_MANUAL_MENU : PERMISSIONS.VIEW_OPTIMIZED_MENU;
    if (!checkPermission(userPermissions, permissionToBeChecked)) {
      yield put(push("/not-found"));
      return;
    }

    const [items, nutrients, partialMenu, availableRecipes, menuRecipes, menuScores] = yield all([
      loadFoodItems(id),
      loadNutritionalValues(id),
      loadPartialDiversity(id),
      loadAvailableRecipes(id),
      loadMenuRecipes(id),
      loadMenuScores(id),
      !currency && loadCurrencySaga(preliminary_information.country),
    ]);
    yield put(
      loadSuccess({
        nutrients,
        selectedFoodItems: items.selected_food_items,
        nutritionalRequirements: items.menu_nutritional_requirements,
        nutrientRestrictions: partialMenu.nutrient_restrictions,
        availableRecipes,
        menuRecipes,
        menuScores,
      })
    );
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCommunityResultsSaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    const currency = yield select(selectCurrency);

    const { preliminary_information } = yield call(loadCommunityMenuSaga, { id });
    const [items, partialMenu] = yield all([
      loadCommunityFoodItems(id),
      loadCommunityPartialDiversity(id),
      !currency && loadCurrencySaga(preliminary_information.country),
      loadConstantsSaga(),
      loadCommunityGroupsAndItemsSaga(id),
    ]);
    yield put(
      loadSuccess({
        selectedFoodItems: items.selected_food_items,
        nutritionalRequirements: items.menu_nutritional_requirements,
        nutrientRestrictions: partialMenu.nutrient_restrictions,
      })
    );
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* createFromExistingRecipeSaga(id, recipe) {
  try {
    return yield call(postMenuRecipe, id, recipe);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* createNewRecipeSaga(id, recipe) {
  try {
    // First, add items to selected-food endpoint...
    let promisesIngredients = [];
    for (const ingredient of recipe.ingredients) {
      promisesIngredients.push(postSelectedFoodItemSaga(id, { ...ingredient, day: recipe.day }));
    }
    const results = yield all(promisesIngredients);

    // ...then, use BE response for creating the new recipe. This response will include the "id" property, mandatory in order to have this working.
    const reworkedRecipe = R.omit(["ingredients"], { ...recipe, selected_food_items: R.flatten(results) });
    return yield call(createRecipeFromSelectedItems, id, reworkedRecipe);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* deleteRecipeSaga(id, recipeId) {
  try {
    return yield call(deleteMenuRecipe, id, recipeId);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* updateRecipeIngredientsSaga(id, ingredients) {
  try {
    let promises = [];

    for (const ingredient of ingredients) {
      promises.push(patchSelectedFoodItemSaga(id, ingredient.id, ingredient));
    }

    return yield all(promises);
  } catch (e) {
    yield put(setAlert(e));
  }
}

// Handle food items creation/update/removal.
export const getFoodItemsUpdatesPromises = (id, menuUpdates) => {
  let promises = [];

  for (const key in menuUpdates) {
    const updates = menuUpdates[key];

    if (key === "created") {
      updates.forEach((foodItem) => {
        promises.push(postSelectedFoodItemSaga(id, foodItem));
      });
    }

    if (key === "updated") {
      updates.forEach((foodItem) => {
        promises.push(patchSelectedFoodItemSaga(id, foodItem._id, foodItem));
      });
    }

    if (key === "deleted") {
      updates.forEach((foodItemId) => {
        promises.push(deleteSelectedFoodItemSaga(id, foodItemId));
      });
    }
  }

  return promises;
};

// Handle recipes creation/update/removal.
export const getRecipesUpdatesPromises = (id, recipesUpdates) => {
  let promises = [];

  for (const key in recipesUpdates) {
    const updates = recipesUpdates[key];

    if (key === "created") {
      updates.forEach((recipe) => {
        promises.push(
          uuidValidate(recipe.recipe_id) ? createNewRecipeSaga(id, recipe) : createFromExistingRecipeSaga(id, recipe)
        );
      });
    }

    if (key === "updated") {
      updates.forEach((recipe) => {
        promises.push(updateRecipeIngredientsSaga(id, Object.values(recipe.ingredients)));
      });
    }

    if (key === "deleted") {
      updates.forEach((recipeId) => {
        promises.push(deleteRecipeSaga(id, recipeId));
      });
    }
  }

  return promises;
};

export function* saveResultsSaga({
  payload: {
    id,
    body: { menuUpdates, mealNames, recipesUpdates },
    cb,
  },
}) {
  try {
    yield put(loadStarted());

    yield call(patchMenuMealNames, id, mealNames);

    yield all(getRecipesUpdatesPromises(id, recipesUpdates));
    yield all(getFoodItemsUpdatesPromises(id, menuUpdates));

    if (cb) cb();

    // Reload fresh data after the updates
    yield call(loadResultsSaga, { payload: { id } });

    yield put(
      setAlert({
        type: "success",
        text: messages.menuUpdated,
        title: messages.genericSuccess,
      })
    );
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadSingleNutritionalValuesSaga({ payload: { menuId, foods } }) {
  try {
    yield put(loadSingleNutritionalValuesStarted());

    const nutritionalValues = yield select(selectNutritionalValues);

    let missingNutritionalValuesFoods = [];
    Object.keys(foods).forEach((label) => {
      const foodItemId = foods[label].food_item;
      if (nutritionalValues[foodItemId] === undefined) {
        missingNutritionalValuesFoods.push(call(getSingleNutrionalValuesCommunityMenu, menuId, foodItemId));
      }
    });
    const responses = yield all(missingNutritionalValuesFoods);
    const objResponses = responses.reduce((acc, el) => ({ ...acc, ...el }), {});
    yield put(loadSingleNutritionalValuesSuccess({ nutrients: objResponses }));

    yield put(loadSingleNutritionalValuesEnded());
  } catch (e) {
    yield put(loadSingleNutritionalValuesEnded());
    yield put(setAlert(e));
  }
}

export function* saveAllCommunityResultsSaga({ payload: { id, body } }) {
  try {
    yield put(loadStarted());

    const [{ selected_food_items }] = yield all([
      saveFoodItemsCommunityMenu(id, body.selected_food_items),
      updateCommunityFoodPricesSaga(id, body.foods),
    ]);
    yield put(loadSuccess({ selectedFoodItems: selected_food_items }));

    const mealNames = yield select(selectMealNames);
    yield call(patchMenuMealNames, id, mealNames);

    // Reload fresh data after the updates
    yield call(loadCommunityResultsSaga, { payload: { id } });

    yield put(loadEnded());
    yield put(
      setAlert({
        type: "success",
        text: messages.menuUpdated,
        title: messages.genericSuccess,
      })
    );
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* resultsSaga() {
  yield takeLatest(loadResults, loadResultsSaga);
  yield takeLatest(loadCommunityResults, loadCommunityResultsSaga);
  yield takeLatest(saveResultsRequest, saveResultsSaga);
  yield takeLatest(loadSingleNutritionalValues, loadSingleNutritionalValuesSaga);
  yield takeLatest(saveAllCommunityResults, saveAllCommunityResultsSaga);
}
