import React, { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import { RowStyled, ColStyled } from "components/utils";
import { useField, useForm } from "react-final-form";
import { injectIntl } from "react-intl";
import { TextInput } from "@wfp/ui";
import Validate from "./Validate";
import Delete from "./Delete";
import messages from "../messages";

function Email({ values, input, index, intl }) {
  const [errorMessage, setErrorMessage] = useState(false);
  const validKey = input.name.replace("value", "valid");
  const {
    input: { value: isValidInput },
  } = useField(validKey);
  const form = useForm();

  const onChange = useCallback(
    (e) => {
      form.batch(() => {
        form.change(input.name, e.target.value);
        form.change(validKey, undefined);
        setErrorMessage(false);
      });
    },
    [form, input.name, validKey],
  );

  const showInvalidError = useMemo(() => {
    if (!R.isEmpty(isValidInput) && !isValidInput) {
      return { message: intl.formatMessage(errorMessage || messages.shareEmailDoesNotExist) };
    }
  }, [errorMessage, intl, isValidInput]);

  return (
    <RowStyled bottom="xs" margin="20px 0 0">
      <ColStyled xs>
        <TextInput
          id={input.name}
          labelText={!index ? intl.formatMessage(messages.shareEmail) : ""}
          placeholder={intl.formatMessage(messages.sharePlaceholderEmail)}
          onChange={onChange}
          //show the validate error only if valid is equal to false
          invalid={showInvalidError}
        />
      </ColStyled>
      <Validate
        values={values}
        setErrorMessage={setErrorMessage}
        isValidInput={isValidInput}
        email={input.value}
        validKey={validKey}
      />
      <Delete field={input.name} index={index} />
    </RowStyled>
  );
}

export default injectIntl(Email);
