import styled from "styled-components";
import { colors } from "@wfp/ui";

export const WeekNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -15px;
  padding: 5px 0;
  background-color: ${colors["brand-01-20"].hex};
`;
