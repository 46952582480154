import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext } from "react-hook-form";

import { Text } from "components";
import { RangeHookForm } from "components/Form";
import { mapSliders, FIELDS } from "./constants";
import messages from "../../messages";

const CaloriesPercentage = ({ nutrient }) => {
  const { control } = useFormContext();

  // Covered area
  const trackStyle = useMemo(
    () =>
      Array(2).fill({
        backgroundColor: colors["brand-01"].hex,
        height: 4,
        borderRadius: 0,
      }),
    [],
  );

  const handleStyle = useMemo(
    () =>
      Array(2).fill({
        height: 20,
        width: 20,
        marginTop: -7,
        border: 0,
        backgroundColor: colors["brand-01"].hex,
      }),
    [],
  );

  const dotStyle = useMemo(
    () => ({
      marginLeft: -3,
    }),
    [],
  );

  return (
    <>
      {mapSliders[nutrient] && (
        <>
          <Text
            bold
            value={
              <FormattedMessage {...messages.caloriesFrom} values={{ value: mapSliders[nutrient].split("_")[0] }} />
            }
          />
          <Text
            wrap
            fontSize="12px"
            marginBottom="10px"
            defaultColor={colors["text-02"].hex}
            value={<FormattedMessage {...messages.caloriesInfo} />}
          />
          <RangeHookForm
            control={control}
            name={`${FIELDS.ADVANCED_REQUIREMENTS}.${mapSliders[nutrient]}`}
            allowCross
            step={1}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            dotStyle={dotStyle}
            showRangeValues
          />
        </>
      )}
    </>
  );
};

export default CaloriesPercentage;
