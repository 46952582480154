import { FIELDS } from "./utils";

export default function validation(values, foodBasket) {
  let errors = {
    [FIELDS.PRELIMINARY_INFO]: {},
  };
  if (values[FIELDS.PRELIMINARY_INFO] && !values[FIELDS.PRELIMINARY_INFO][FIELDS.DAYS_IN_WEEK]) {
    errors[FIELDS.PRELIMINARY_INFO][FIELDS.DAYS_IN_WEEK] = " ";
  }
  if (foodBasket && !foodBasket.items_count) {
    errors[FIELDS.PRELIMINARY_INFO] = " ";
  }
  return errors;
}
