import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

type ActionPayload = {
  id: string;
  params: object;
};

type ActionPayloadWithBody = {
  id: string;
  params: object;
  body: object;
};

export const loadStarted = createAction("@@diversity/LOAD_STARTED");
export const loadEnded = createAction("@@diversity/LOAD_ENDED");

export const loadDiversityRequest = createAction(
  "@@diversity/LOAD_DIVERSITY_REQUEST"
);

export const loadItemsSuccess = createAction("@@diversity/LOAD_ITEMS_SUCCESS");
export const loadGroupsAndItemsSuccess = createAction(
  "@@diversity/LOAD_GROUPS_AND_ITEMS_SUCCESS"
);
export const loadMajorGroupsSuccess = createAction(
  "@@diversity/LOAD_MAJOR_GROUPS_SUCCESS"
);
export const loadCompositionsSuccess = createAction(
  "@@diversity/LOAD_COMPOSITIONS_SUCCESS"
);

export const loadItemRestrictionRequest = createAction(
  "@@diversity/LOAD_ITEM_RESTRICTION_REQUEST"
);
export const loadGroupRestrictionRequest = createAction(
  "@@diversity/LOAD_GROUP_RESTRICTION_REQUEST"
);
export const loadRestrictionSuccess = createAction(
  "@@diversity/LOAD_RESTRICTION_SUCCESS"
);

export const saveItemRestrictionRequest: ActionCreatorWithPayload<ActionPayloadWithBody> =
  createAction("@@diversity/SAVE_ITEM_RESTRICTION_REQUEST");
export const saveGroupRestrictionRequest: ActionCreatorWithPayload<ActionPayloadWithBody> =
  createAction("@@diversity/SAVE_GROUP_RESTRICTION_REQUEST");

export const deleteItemRestriction: ActionCreatorWithPayload<ActionPayload> = createAction(
  "@@diversity/DELETE_ITEM_RESTRICTION_REQUEST"
);
export const deleteGroupRestriction: ActionCreatorWithPayload<ActionPayload> = createAction(
  "@@diversity/DELETE_GROUP_RESTRICTION_REQUEST"
);

export const resetMenuStructure = createAction(
  "@@diversity/RESET_MENU_STRUCTURE"
);
