import React from "react";
import styled from "styled-components";
import { colors } from "@wfp/ui";
import { Col } from "react-flexbox-grid";
import { mediaTablet } from "components/utils";

const StyledCol = ({ isSelected, ...props }) => <Col {...props} />;

export const CardStyled = styled(StyledCol)`
  padding: 23px 10px;
  border-radius: 4px;
  max-width: 281px;
  border: ${({ isSelected }) =>
    isSelected ? `2px solid ${colors["brand-01"].hex}` : `2px solid ${colors["ui-04"].hex}`};

  &:not(:last-child) {
    margin-inline-end: 30px;
  }

  ${mediaTablet} {
    &:not(:last-child) {
      margin-right: 0;
    }

    &:first-child {
      margin-inline-end: 30px;
    }
  }
`;
