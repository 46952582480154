import { useMemo, useCallback, Fragment } from "react";
import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ModuleHeader, ModuleBody } from "@wfp/ui";

import { NewModule } from "components";
import {
  loadItemRestrictionRequest,
  loadGroupRestrictionRequest,
  deleteGroupRestriction,
  deleteItemRestriction,
} from "../actions";
import GroupRule from "./GroupRule";
import ItemRule from "./ItemRule";
import messages from "../messages";
import { selectMajorGroups } from "../selectors";
import { filterItems } from "./utils";

const Summaries = ({ compositions: { menu }, menuId }) => {
  const dispatch = useDispatch();
  const majorGroups = useSelector(selectMajorGroups);

  const editRule = useCallback(
    (params, name) => {
      if (Object.keys(params).includes("item_id")) {
        dispatch(loadItemRestrictionRequest({ id: menuId, params, name }));
      } else {
        dispatch(loadGroupRestrictionRequest({ id: menuId, params }));
      }
    },
    [dispatch, menuId]
  );

  const deleteRule = useCallback(
    (restrictionType, id) => {
      if (restrictionType.includes("group")) {
        dispatch(
          deleteGroupRestriction({
            id: menuId,
            params: { [restrictionType]: id },
          })
        );
      }
      if (restrictionType.includes("item")) {
        dispatch(deleteItemRestriction({ id: menuId, params: { item_id: id } }));
      }
    },
    [dispatch, menuId]
  );

  const refactorData = useMemo(() => {
    if (R.isEmpty(menu)) return {};
    const foodGrouprestrictions = R.pathOr([], ["food_group_restrictions"], menu);
    const foodItemRestrictions = R.pathOr([], ["food_item_restrictions"], menu);
    const itemsGroupedByMajor = R.groupBy((item) => item.item_major_group, foodItemRestrictions.filter(filterItems));
    const groupsGroupedByMajor = R.groupBy((group) => group.major_group, foodGrouprestrictions);
    const refactoredGroups = Object.keys(groupsGroupedByMajor).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...groupsGroupedByMajor[key].find((el) => !el.minor_group),
          minors: groupsGroupedByMajor[key].filter((el) => el.minor_group !== null),
          major_en: groupsGroupedByMajor[key][0].major_en,
        },
      }),
      {}
    );
    const refactoredItems = Object.keys(itemsGroupedByMajor).reduce((acc, key) => {
      return {
        ...acc,
        [key]: {
          items: itemsGroupedByMajor[key],
          major_en: R.pathOr("", ["major_en"], refactoredGroups[key]) ? "" : itemsGroupedByMajor[key][0].major_en,
        },
      };
    }, {});
    return R.mergeDeepWith(R.concat, refactoredItems, refactoredGroups);
  }, [menu]);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <FormattedMessage {...messages.addedRules} />
      </ModuleHeader>
    ),
    []
  );

  const body = useMemo(
    () => (
      <ModuleBody>
        {Object.entries(refactorData).map(([groupId, group], index) => (
          <Fragment key={`${groupId}-${index}`}>
            {/* Restriction about food items. */}
            {(group.items || []).map((item, index) => (
              <ItemRule
                majorLabel={item.major_en}
                key={`${item.food_item_name}-${index}`}
                editRule={editRule}
                deleteRule={deleteRule}
                {...item}
              />
            ))}

            {/* Restriction about major group. */}
            {group.major_group && (
              <GroupRule
                {...group}
                label={majorGroups[groupId]}
                majorLabel={majorGroups[groupId]}
                majorGroups={majorGroups}
                editRule={editRule}
                deleteRule={deleteRule}
                groupId={groupId}
                kind="major"
              />
            )}

            {/* Restriction about minor group. */}
            {(group.minors || []).map((minorGroup) => (
              <GroupRule
                key={minorGroup.minor_group}
                editRule={editRule}
                deleteRule={deleteRule}
                majorGroups={majorGroups}
                label={minorGroup.minor_label}
                majorLabel={minorGroup.major_en}
                groupId={minorGroup.minor_group}
                kind="minor"
                {...minorGroup}
              />
            ))}
          </Fragment>
        ))}
      </ModuleBody>
    ),
    [deleteRule, editRule, majorGroups, refactorData]
  );

  return (
    !R.isEmpty(refactorData) && (
      <NewModule
        padding="30px 8px 10px"
        contentBackground="white"
        withBorder
        header={header}
        headerPadding="10px 8px"
        withHeaderBorder
        body={body}
      />
    )
  );
};

export default Summaries;
