import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "components";
import { updateSingleUser } from "../actions";
import { selectUserGroups, selectLoading } from "../selectors";
import { selectRoles } from "containers/App/selectors";
import { reworkUserGroups } from "./utils";

const Roles = ({ user }) => {
  const dispatch = useDispatch();
  const userGroups = useSelector(selectUserGroups);
  const loading = useSelector(selectLoading);
  const loggedInUserRoles = useSelector(selectRoles);

  const role = useMemo(() => user.plusadmin_roles[0], [user.plusadmin_roles]);
  const roleOptions = useMemo(() => reworkUserGroups(userGroups, loggedInUserRoles), [userGroups, loggedInUserRoles]);
  const defaultValue = useMemo(() => ({ id: role.id, label: role.label, value: role.name }), [role]);

  const onChange = useCallback(
    (el) => {
      const role = { id: el.id };
      dispatch(updateSingleUser({ user, body: { plusadmin_roles: [role] } }));
    },
    [dispatch, user],
  );

  if (loading) return null;

  // Added key because sometimes the <Select> shows older value after updating a role from the user details page and getting back to the users list.
  return (
    <Select
      key={role.id}
      id="role"
      options={roleOptions}
      defaultValue={defaultValue}
      customOnChange={(el) => onChange(el)}
    />
  );
};

export default Roles;
