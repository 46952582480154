import { forwardRef } from "react";
import styled, { css } from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import TextareaAutosize from "react-textarea-autosize";
import { colors } from "@wfp/ui";

export const CustomDraggable = ({ className, ...rest }) => {
  return <Draggable {...rest} className={className} />;
};

const CustomDraggableInnerDiv = styled.div`
  margin: 0;
  border-bottom: 1px solid ${colors["ui-04"].hex};

  ${({ padding }) =>
    padding &&
    css`
      ${{ padding }}
    `};
`;

export const DraggableInnerDiv = forwardRef(({ children, padding, provided, style, onClick }, ref) => {
  return (
    <CustomDraggableInnerDiv
      ref={ref}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="row spaceBetween"
      padding={padding}
      style={style}
      onClick={onClick}
    >
      {children}
    </CustomDraggableInnerDiv>
  );
});

export const TextareaAutosizeStyled = styled(TextareaAutosize)`
  display: block;
  outline: none;
  padding: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid #8ca4b5;
  pointer-events: "auto";
  resize: none;
  font-family: "Open Sans", sans-serif;

  :focus {
    outline: none;
    box-shadow: 0px 2px 8px 0 #c2dbec;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DayAndRecipeNameContainer = styled.div`
  background-color: ${colors["ui-03"].hex};
  border-bottom: 1px solid ${colors["ui-04"].hex};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: auto;
`;

export const DayNameContainer = styled(DayAndRecipeNameContainer)`
  padding: 10px;
  // Prevents layout switch when toggling between "Day X" and edit mode for a day
  height: 51px;
`;
export const RecipeNameContainer = styled(DayAndRecipeNameContainer)`
  padding: 5px 10px;
`;

const CustomFoodItemContainer = styled.div`
  margin: 0;
  padding: 5px 10px;
  border-bottom: 1px solid ${colors["ui-04"].hex};
`;

export const FoodItemContainer = ({ children }) => (
  <CustomFoodItemContainer className="row spaceBetween">{children}</CustomFoodItemContainer>
);
