import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Text } from "components";
import { ColStyled } from "components/utils";
import {
  LogosContainer,
  WFPLogo,
  PartnerLogo,
  AfricanUnionLogo,
  SodexoLogo,
  InnovationAcceleratorLogo,
  NoradLogo,
  FaoLogo,
} from "./styles";
import messages from "./messages";
import { StyledFooter } from "./styles";
import { selectLanguage } from "../selectors";

const Footer = () => {
  const intl = useIntl();
  const language = useSelector(selectLanguage);

  const logoLink = useMemo(() => {
    const lang = language.split("-")[0];

    // UI kit doesn't have logos for these languages. Fallback to "en" logo
    const missingAssetsLanguages = ["pt", "ru"];
    if (missingAssetsLanguages.includes(lang)) {
      return "https://cdn.wfp.org/guides/ui/assets/v0.0.1/logos/standard/en/wfp-logo-standard-black-en.png";
    } else {
      return `https://cdn.wfp.org/guides/ui/assets/v0.0.1/logos/standard/${lang}/wfp-logo-standard-black-${lang}.png`;
    }
  }, [language]);

  return (
    <StyledFooter
      external
      metaContent={`${new Date().getFullYear()} © World Food Programme`}
      logoExtended={
        <LogosContainer>
          <WFPLogo logo={logoLink} />
          <ColStyled>
            <Text superBold fontSize="13px" marginBottom="10px" value={intl.formatMessage(messages.inPartnership)} />
            <PartnerLogo />
          </ColStyled>
          <AfricanUnionLogo />
          <SodexoLogo />
          <InnovationAcceleratorLogo />
          <NoradLogo />
          <FaoLogo />
        </LogosContainer>
      }
    />
  );
};

export default Footer;
