import { useEffect, useState, useCallback } from "react";
import { Text } from "components";
import { ModalStyled } from "components/UIKitStyled";
import { useIntl } from "react-intl";
import messages from "./messages";

const WarningOrientation = () => {
  const intl = useIntl();

  const isPortraitOrTooSmall = useCallback(() => window.innerHeight > window.innerWidth || window.innerWidth < 920, []);

  const [showModal, setModal] = useState(isPortraitOrTooSmall);

  useEffect(() => {
    const onResize = (_) => setModal(isPortraitOrTooSmall());
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [isPortraitOrTooSmall]);

  return (
    <ModalStyled noFooter hideClose open={showModal} modalHeading={intl.formatMessage(messages.title)}>
      <Text fontSize="16px" value={intl.formatMessage(messages.firstLabel)} />
      {window.innerHeight > window.innerWidth ? (
        <Text wrap fontSize="16px" value={intl.formatMessage(messages.secondLabel)} />
      ) : (
        <Text wrap fontSize="16px" value={intl.formatMessage(messages.useAnotherDevice)} />
      )}
    </ModalStyled>
  );
};

export default WarningOrientation;
