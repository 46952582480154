import { useCallback, useMemo, useEffect } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { AsyncSelectHookForm } from "components/Form";
import { ColStyled } from "components/utils";
import { loadFilteredFCTFoodItemsRequest, loadFCTFoodItemsRequest } from "containers/FoodBasket/actions";
import foodBasketMessages from "containers/FoodBasket/messages";
import { selectFCTFoodItems, selectLoadingFCTFoodItems } from "containers/FoodBasket/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import { selectFoodBasketItems } from "containers/GeneralInfo/GeneralForm/selectors";
import { ChooseFromGlobalBasketContainer } from "./styles";

const ChooseFromGlobalBasket = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { control, getValues } = useFormContext();
  const foodItem = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.ITEM}` });

  const basketFoodItems = useSelector(selectFoodBasketItems);
  const fctFoodItems = useSelector(selectFCTFoodItems);
  const loadingFCTFoodItems = useSelector(selectLoadingFCTFoodItems);

  useEffect(() => {
    dispatch(loadFCTFoodItemsRequest());
  }, [dispatch]);

  const defaultOptions = useMemo(
    () => {
      const values = getValues();
      const formBasketIds = Object.keys(values[FIELDS.BASKET]);

      const fullBasket = R.sortWith([R.ascend(R.prop("label"))])([...Object.values(basketFoodItems), ...fctFoodItems]);
      const options = fullBasket.filter((item) => !formBasketIds.includes(item.value.toString()));
      return options;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fctFoodItems, basketFoodItems, foodItem.value, getValues],
  );

  const filterFn = useCallback(
    (items) => {
      const values = getValues();
      const formBasketIds = Object.keys(values[FIELDS.BASKET]);
      return items.filter((item) => !formBasketIds.includes(item.value.toString()));
    },
    [getValues],
  );

  // Provide a filter function so we can remove already inserted food items from the ones coming from BE.
  const loadFCTFoodItemsOptions = useCallback(
    (text, callback) => {
      dispatch(loadFilteredFCTFoodItemsRequest({ text, callback, filterFn }));
    },
    [dispatch, filterFn],
  );

  const styles = useMemo(
    () => ({
      // Selected option
      singleValue: (styles, { data }) => ({
        ...styles,
        color: data.from_fct ? colors["categorical-corporate-08"].hex : colors["text-01"].hex,
      }),
      // All available options
      option: (styles, { data }) => ({
        ...styles,
        color: data.from_fct ? colors["categorical-corporate-08"].hex : colors["text-01"].hex,
      }),
    }),
    [],
  );

  return (
    <ColStyled xs={4}>
      <ChooseFromGlobalBasketContainer>
        <AsyncSelectHookForm
          control={control}
          name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.ITEM}`}
          isLoading={loadingFCTFoodItems}
          defaultOptions={defaultOptions}
          label={intl.formatMessage(foodBasketMessages.foodItem)}
          loadOptions={loadFCTFoodItemsOptions}
          styles={styles}
        />
      </ChooseFromGlobalBasketContainer>
    </ColStyled>
  );
};

export default ChooseFromGlobalBasket;
