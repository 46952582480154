import { Controller } from "react-hook-form";
import { TextInput } from "@wfp/ui";

/**
 * TextInput wrapper that handles react-hook-form's handleSubmit() validation
 *
 * TODO: In the future, replace all the existing TextInputHookForm
 * components that need a submit validation, with this component
 */
const ValidatedTextInputController = ({ control, name, labelText = "", rules = {}, ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState: { error } }) => (
      <TextInput
        id={name}
        labelText={labelText}
        invalid={error}
        invalidText={error && error.message}
        {...field}
        {...rest}
      />
    )}
    rules={rules}
  />
);

export default ValidatedTextInputController;
