import { colors } from "@wfp/ui";

export const targetOptions = [
  { value: 0, label: "0%" },
  { value: 10, label: "10%" },
  { value: 20, label: "20%" },
  { value: 30, label: "30%" },
  { value: 40, label: "40%" },
  { value: 50, label: "50%" },
  { value: 60, label: "60%" },
  { value: 70, label: "70%" },
  { value: 80, label: "80%" },
  { value: 90, label: "90%" },
  { value: 100, label: "100%" },
];

export const targetColor = colors["brand-01-80"].hex;

export const HEIGHT_CHART = 304;
export const TABLET_HEIGHT_CHART = 247;
export const MODAL_SIZE = 160;
export const H_PADDING = 4;
export const SELECTED_BAR_WIDTH = 20;
export const BAR_WIDTH = 6;
export const SPACE_GROUP = 60;
