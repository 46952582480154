import { format } from "date-fns";

export const FIELDS = {
  MONTH: "month",
  YEAR: "year",
  FOOD_ITEM: "item",
  SOURCE: "source",
  PRICE: "price",
  WEIGHT: "quantity",
  CURRENCY: "price_currency",
  STAPLE: "is_staple",
  ORIGIN: "origin",
  SHARE: "share",
  QUANTITY: "quantity",
};

export const ADDITIONAL_FIELDS = {
  ADDITIONAL_COSTS: "additional_costs",
  TYPE: "type",
  UNIT: "unit",
  PERCENTAGE: "percentage",
  VALUE: "value",
  PRICE_CURRENCY: "price_currency",
  PRICE: "price",
};

export const initialValues = {
  [FIELDS.MONTH]: Number(format(new Date(), "M")),
  [FIELDS.YEAR]: Number(format(new Date(), "YYY")),
  [FIELDS.WEIGHT]: "1 kg",
  [FIELDS.CURRENCY]: "USD",
  [ADDITIONAL_FIELDS.ADDITIONAL_COSTS]: [],
};
