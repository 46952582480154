import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { View, Text } from "@react-pdf/renderer";

import messages from "containers/Menu/messages";
import { seasonality, daysAndWeeks, targeted } from "containers/Menu/utils";
import generalStyles from "containers/PDF/styles";
import compareStyles from "../styles";

const BasicSettings = ({
  name,
  isLast,
  regions,
  start_month,
  end_month,
  days_in_week,
  week_count,
  target_group_details,
}) => {
  const intl = useIntl();
  const { box } = compareStyles;
  const { row, fontSize12, fontSize10 } = generalStyles;
  const regionsNames = regions?.length ? regions.join(", ") : intl.formatMessage(messages.wholeCountry);
  const text = [fontSize10, { marginTop: 3 }];

  return (
    <View style={[box, { backgroundColor: colors["ui-02"].hex, height: 109 }, isLast ? { border: 0 } : {}]}>
      <Text style={[fontSize12, { marginBottom: 5 }]}>{name}</Text>
      <Text style={text}>{regionsNames}</Text>
      <View style={row}>
        <Text style={text}>{seasonality({ start_month, end_month }, true, intl)}</Text>
        <Text style={text}>{" |"}</Text>
        <Text style={text}>{daysAndWeeks({ days_in_week, week_count }, intl)}</Text>
      </View>
      <Text style={text}>{targeted({ target_group_details })}</Text>
    </View>
  );
};

export default BasicSettings;
