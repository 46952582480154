import { forwardRef } from "react";
import styled from "styled-components";
import { colors } from "@wfp/ui";

export const MenuCompositionContainer = styled.div`
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
  width: 100%;
  margin-top: 30px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 60px;
`;

export const FoodGroups = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  background-color: ${colors["ui-03"].hex};
  border-top: 1px solid ${colors["ui-04"].hex};
  border-bottom: 1px solid ${colors["ui-04"].hex};
  border-right: ${({ hasBorderRight }) => (hasBorderRight ? `1px solid ${colors["ui-04"].hex}` : 0)};
  padding: 5px 10px;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

export const FoodGroupsInnerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomDroppableInnerDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  border-top: 1px solid ${colors["ui-04"].hex};
  border-right: 1px solid ${colors["ui-04"].hex};
  max-width: ${({ maxWidth }) => maxWidth}px;
  cursor: pointer;
  position: relative;
  min-height: 230px;
  overflow: hidden;
  margin: 0;

  &:last-child {
    border-right: 0;
  }
`;

export const DroppableInnerDiv = forwardRef(({ children, maxWidth, style, ...props }, ref) => {
  return (
    <CustomDroppableInnerDiv ref={ref} maxWidth={maxWidth} {...props}>
      {children}
    </CustomDroppableInnerDiv>
  );
});
