import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { Text } from "components";
import { RowStyled } from "components/utils";
import { BreadcrumbHome, Breadcrumb, BreadcrumbItem } from "@wfp/ui";
import { compose } from "redux";
import messages from "./messages";

function Breadcrumbs({ history: { location }, intl }) {
  const LABELS = useMemo(
    () => ({
      create: intl.formatMessage(messages.newMenu),
      edit: intl.formatMessage(messages.editMenu),
      duplicate: intl.formatMessage(messages.duplicateMenu),
      manual: intl.formatMessage(messages.manualMenu),
      optimized: intl.formatMessage(messages.optimisedMenu),
      community: intl.formatMessage(messages.communityMenu),
    }),
    [intl],
  );
  const paths = location.pathname.split("/");
  paths.shift();

  return (
    <RowStyled margin="0 0 0 10px">
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="/">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        {paths.map(
          (breadcrumb, index) =>
            LABELS[breadcrumb] && (
              <BreadcrumbItem key={`breadcrumb-${index}`}>
                <Text value={LABELS[breadcrumb]} bold defaultColor={colors["interactive-01"]} />
              </BreadcrumbItem>
            ),
        )}
      </Breadcrumb>
    </RowStyled>
  );
}

export default compose(injectIntl)(Breadcrumbs);
