import { useMemo, useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconDelete, iconAddGlyph } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import { SelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS, KEYS } from "../constants";
import messages from "../messages";
import MinOrMax from "./MinOrMax";

const SelectFoodGroups = ({ name, addEmptyGroup, removeFoodGroup, foodGroupOptions, foodGroupsFields }) => {
  const intl = useIntl();
  const { control, register } = useFormContext();

  const sourceId = useWatch({ control, name: `${name}.${FIELDS.ID}` });

  const addNewButton = useCallback(
    (label) => <Button kind="ghost" widthAuto icon={iconAddGlyph} children={label} onClick={addEmptyGroup} />,
    [addEmptyGroup],
  );

  const filteredOptions = useMemo(() => {
    if (!foodGroupsFields) return foodGroupOptions;
    return foodGroupOptions.filter((el) => !foodGroupsFields.find(({ group }) => group === el.label));
  }, [foodGroupOptions, foodGroupsFields]);

  return (
    <>
      {foodGroupsFields.map((foodGroup, index) => (
        <RowStyled key={foodGroup.id} middle="xs" margin="20px 0">
          <ColStyled xs={4} padding="0">
            {sourceId && (
              <input
                {...register(`${name}.${KEYS.FOOD_GROUPS}[${index}].${KEYS.SOURCING_CONSTRAINT}`)}
                defaultValue={sourceId}
                style={{ display: "none" }}
              />
            )}
            <SelectHookForm
              control={control}
              name={`${name}.${KEYS.FOOD_GROUPS}[${index}].${KEYS.GROUP}`}
              label={intl.formatMessage(messages.selectFoodGroup)}
              options={foodGroupOptions}
            />
          </ColStyled>

          <ColStyled xs={3} padding="0" margin="0 0 0 30px" className="min-max-percentage">
            <MinOrMax
              label={intl.formatMessage(messages.selectGroupLimit)}
              keyLimit={`${name}.${KEYS.FOOD_GROUPS}[${index}].${KEYS.LIMIT}`}
              keyPercentage={`${name}.${KEYS.FOOD_GROUPS}[${index}].${KEYS.VALUE}`}
              xsMinMax={3}
              xsField={6}
              xsPerc={2}
            />
          </ColStyled>

          <ColStyled xs={1} margin="20px 0 0">
            <Icon
              className="pointer"
              description="Remove"
              icon={iconDelete}
              fill={colors["brand-01"].hex}
              width="20"
              height="20"
              onClick={() => removeFoodGroup(index)}
            />
          </ColStyled>

          {foodGroupsFields.length === index + 1 && !!filteredOptions.length && (
            <ColStyled xs={3} margin="20px 0 0">
              {addNewButton(intl.formatMessage(messages.addAnotherFoodGroup))}
            </ColStyled>
          )}
        </RowStyled>
      ))}
      {R.isEmpty(foodGroupsFields) && addNewButton(intl.formatMessage(messages.addFoodGroup))}
    </>
  );
};

export default SelectFoodGroups;
