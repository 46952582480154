import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { iconAddGlyph } from "@wfp/icons";
import { useFormContext, useFieldArray } from "react-hook-form";

import { Button, Text } from "components";
import { ColStyled } from "components/utils";
import { selectAvailableSources } from "containers/OptimizedMenu/selectors";
import { FIELDS, KEYS } from "./constants";
import messages from "./messages";
import Source from "./Source";

const SourcingRules = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const { fields: sources, append, remove } = useFieldArray({ control, name: FIELDS.SOURCING });

  const availableSources = useSelector(selectAvailableSources);

  const addSource = useCallback(() => {
    append({ [KEYS.FOOD_GROUPS]: [] });
  }, [append]);

  const removeSource = useCallback(
    (index) => {
      remove(index);
    },
    [remove],
  );

  return (
    <ColStyled padding="10px 0">
      <Text marginBottom="10px" fontSize="16px" value={intl.formatMessage(messages.description)} wrap />
      {sources.map((source, index) => (
        <Source key={source.id} index={index} options={availableSources} remove={() => removeSource(index)} />
      ))}

      <Button
        widthAuto
        disabled={availableSources.length === sources.length}
        marginBottom="20px"
        marginTop="30px"
        kind="secondary"
        children={intl.formatMessage(sources.length ? messages.addAnotherRequirement : messages.addRequirement)}
        icon={iconAddGlyph}
        onClick={addSource}
      />
    </ColStyled>
  );
};

export default SourcingRules;
