import React, { memo } from "react";

import { Text } from "components";

import { GroupRowInnerDiv } from "./styles";
import { iconCheckmark } from "@wfp/icons";
import { colors, Icon } from "@wfp/ui";

const GroupRow = ({ group }) => {
  return (
    <div style={{ height: "100%" }}>
      <GroupRowInnerDiv padding="5px 10px">
        <Text value={group.name} marginRight="5px" />
        <Icon icon={iconCheckmark} fill={colors["categorical-corporate-03"].hex} width="16px" height="16px" />
        <Text value={group.inclusionLabel} marginLeft="5px" />
      </GroupRowInnerDiv>
    </div>
  );
};

export default memo(GroupRow);
