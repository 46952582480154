export const FIELDS = {
  IS_ALL_SOURCES: "all_sources_restricted",
  ALL_SOURCES_RANGE: "sourcing_range",
  SOURCING: "sourcing_constraints",
  ID: "id",
};

export const KEYS = {
  SELECTED: "isSelected",
  GROUP: "group",
  SOURCE: "source",
  PERCENTAGE: "percentage",
  VALUE: "value",
  LIMIT: "limit",
  RANGE: "range",
  FOOD_GROUPS: "group_sourcing_constraints",
  GROUPS: "groups",
  SOURCING_CONSTRAINT: "sourcing_constraint",
};

export const MIN = "min";
export const MAX = "max";
