import React from "react";
import { useIntl } from "react-intl";
import { Button } from "components";
import buttonMessages from "components/Button/messages";

const CopyButton = ({ onClick, disabled }) => {
  const intl = useIntl();

  return (
    <Button
      widthAuto
      kind="secondary"
      onClick={onClick}
      disabled={disabled}
      children={intl.formatMessage(buttonMessages.duplicate)}
    />
  );
};

export default CopyButton;
