import { useState, useEffect } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loading } from "@wfp/ui";

import { Layout, SecondHeader } from "components";
import { withAuth } from "hocs";
import { saveComparisonList } from "containers/MyMenus/actions";
import { selectComparisonList } from "containers/MyMenus/selectors";
import { selectLocalCurrency, selectRateFromUSD } from "containers/App/selectors";
import PDF from "containers/PDF";
import { COMPARE } from "containers/PDF/utils";
import TutorialLink from "containers/Tutorials/TutorialLink";
import { TUTORIAL_IDS } from "containers/Tutorials/utils";
import Menus from "./Menus";
import BasicSettings from "./BasicSettings";
import CostPerMeal from "./CostPerMeal";
import MenuDiversity from "./MenuDiversity";
import NutritionalValues from "./NutritionalValues";
import SourcingChannels from "./SourcingChannels";
import { loadCompareMenus, clearCompare } from "./actions";
import messages from "./messages";
import { selectLoading, selectNutritions, selectDiversity, selectSourcing, selectInfo } from "./selectors";

const Compare = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { ids } = useParams();

  const comparisonList = useSelector(selectComparisonList);
  const loading = useSelector(selectLoading);
  const nutritions = useSelector(selectNutritions);
  const diversity = useSelector(selectDiversity);
  const sourcing = useSelector(selectSourcing);
  const infoMenus = useSelector(selectInfo);
  const conversionRate = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);

  const [withAdditionalCost, setAdditionalCost] = useState(true);

  useEffect(() => {
    dispatch(loadCompareMenus({ ids }));
    return () => dispatch(clearCompare());
  }, [dispatch, ids]);

  useEffect(() => {
    if (infoMenus) dispatch(saveComparisonList(infoMenus));
  }, [dispatch, infoMenus]);

  return (
    <Layout>
      <SecondHeader
        title={intl.formatMessage(messages.header)}
        info={intl.formatMessage(messages.subHeader, {
          link: <TutorialLink kind={TUTORIAL_IDS.COMPARE} />,
        })}
        button={<PDF page={COMPARE} />}
      />
      <Loading active={loading} />
      {!R.isEmpty(infoMenus) && (
        <>
          <Menus menus={infoMenus} comparisonList={comparisonList} />
          <BasicSettings menus={infoMenus} />
          <CostPerMeal
            menus={infoMenus}
            withAdditionalCost={withAdditionalCost}
            setAdditionalCost={setAdditionalCost}
            conversionRate={conversionRate}
            localCurrency={localCurrency}
          />
          <NutritionalValues menus={nutritions} />
          <MenuDiversity menus={diversity} />
          <SourcingChannels menus={sourcing} withAdditionalCost={withAdditionalCost} />
        </>
      )}
    </Layout>
  );
};

export default compose(withAuth)(Compare);
