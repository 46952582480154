import styled from "styled-components";
import { ModuleHeader, ModuleBody } from "@wfp/ui";

export const HeaderWrapper = styled(ModuleHeader)`
  .wfp--form-item {
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: row;
  }
  .wfp--input-group-inside {
    margin-top: 0px;
  }
`;

export const ModuleBodyWrapper = styled(ModuleBody)`
  .wfp--number__controls > .wfp--number__control-btn {
    opacity: 1;
  }
`;
