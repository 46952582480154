import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Layout, SecondHeader } from "components";
import { colors, Tabs, Tab } from "@wfp/ui";
import Nutrients from "./Nutrients";
import Sources from "./Sources";
import CommunityNutrientsLevel from "./NutrientsLevel/Community";
import PriorityItems from "./PriorityItems";
import VamFoodPrices from "./VamFoodPrices";
import PriceApproval from "./PriceApproval";
import Recipes from "./Recipes";
import routesPath from "containers/App/Routes/paths";
import {
  updateFormState,
  resetSources,
  resetNutrients,
  resetNutrientsLevel,
  resetPriorityItems,
  resetFoodPrices,
} from "../actions";
import messages from "./messages";
import { intlTabs } from "./utils";
import { TabsContainer } from "../styles";
import FoodDatabase from "./FoodDatabase";

const Countries = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [secondaryTabIndex, setSecondaryTabIndex] = useState(0);

  const tabs = useMemo(() => intlTabs(intl), [intl]);

  const setInitTabAndRedirect = useCallback(() => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);
    let newLocationPathname = "";

    // We are changing mainTab, so we set its index accordingly and reset secondaryTab to 0
    if (index > -1) {
      setMainTabIndex(index);
      setSecondaryTabIndex(0);

      // Since not all mainTabs have secondaryTabs, we need to check before changing route
      const hasSecondaryTabs = tabs[index].secondaryTabs.length > 0;
      if (hasSecondaryTabs) {
        newLocationPathname = tabs[index].secondaryTabs[0].href;
      } else {
        newLocationPathname = tabs[index].href;
      }
    } else {
      // If we landed on "/countries/sources/manual" without clicking the "Countries" link in Header or we are changing secondary tab
      const mainIndex = tabs.findIndex((tab) => location.pathname.includes(tab.href));
      if (mainIndex > -1) {
        const secondaryTabIndex = tabs[mainIndex].secondaryTabs.findIndex((tab) => tab.href === location.pathname);

        if (secondaryTabIndex === -1) {
          // Currently, the :id param only works on main tabs so if there is no seoncdaryTab index,
          // we first check for optional :id param in the pathname
          const idParam = location.pathname.replace(`${tabs[mainIndex].href}/`, "").replace(/\//g, "");

          // If valid (positive integer) :id param exists and if the main tab accepts the :id param,
          // update the mainTabIndex and the path accordingly and reset the secondaryTabIndex
          if (idParam && (/^\d+$/.test(idParam) || idParam === "create") && tabs[mainIndex].acceptsIdParam) {
            setMainTabIndex(mainIndex);
            setSecondaryTabIndex(0);
            newLocationPathname = `${tabs[mainIndex].href}/${idParam}`;
          } else {
            // Else, if we can't find a secondary tab and there is also no valid :id param, just fallback to default page
            setMainTabIndex(0);
            setSecondaryTabIndex(0);
            newLocationPathname = tabs[0].href;
          }
        } else {
          setMainTabIndex(mainIndex);
          setSecondaryTabIndex(secondaryTabIndex);
          newLocationPathname = tabs[mainIndex].secondaryTabs[secondaryTabIndex].href;
        }
      } else {
        // Fallback to default page if no occurrence is found
        setMainTabIndex(0);
        setSecondaryTabIndex(0);
        newLocationPathname = tabs[0].href;
      }
    }
    if (newLocationPathname !== location.pathname) {
      history.replace(newLocationPathname);
    }
  }, [history, location.pathname, tabs]);

  useEffect(() => {
    return () => {
      dispatch(updateFormState({ formState: {} }));
      dispatch(resetSources());
      dispatch(resetNutrients());
      dispatch(resetNutrientsLevel());
      dispatch(resetPriorityItems());
      dispatch(resetFoodPrices());
    };
  }, [dispatch]);

  useEffect(() => {
    setInitTabAndRedirect();
  }, [setInitTabAndRedirect]);

  const onMainTabChange = useCallback(
    (index) => {
      const selectedTab = tabs[index];
      history.push(selectedTab.href);
      setMainTabIndex(index);
      setSecondaryTabIndex(0);
    },
    [history, tabs]
  );

  const onSecondaryTabChange = useCallback(
    (index) => {
      const selectedMainTab = tabs[mainTabIndex];
      const selectedSecondaryTab = selectedMainTab.secondaryTabs[index];
      history.push(selectedSecondaryTab.href);
      setSecondaryTabIndex(index);
    },
    [history, mainTabIndex, tabs]
  );

  const tabsStructure = useMemo(() => {
    const hasSecondaryTabs = tabs[mainTabIndex].secondaryTabs.length > 0;

    return (
      <TabsContainer>
        <Tabs selected={mainTabIndex} onSelectionChange={onMainTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        {hasSecondaryTabs ? (
          <Tabs selected={secondaryTabIndex} onSelectionChange={onSecondaryTabChange}>
            {tabs[mainTabIndex].secondaryTabs.map((tab) => (
              <Tab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
        ) : null}
      </TabsContainer>
    );
  }, [tabs, mainTabIndex, onMainTabChange, secondaryTabIndex, onSecondaryTabChange]);

  return (
    <Layout backgroundColor={colors["ui-02"].hex}>
      <SecondHeader
        title={intl.formatMessage(messages.countriesHeader)}
        info={intl.formatMessage(messages.countriesSubHeader)}
        padding="0 30px"
        afterText={tabsStructure}
      />

      <Switch>
        <Route path={routesPath.adminCountriesNutrients} component={Nutrients} />
        <Route path={routesPath.adminCountriesSources} component={Sources} />
        <Route path={routesPath.adminCountriesCommunityNutrientsLevel} component={CommunityNutrientsLevel} />
        <Route path={routesPath.adminCountriesPriorityItems} component={PriorityItems} />
        <Route path={routesPath.adminCountriesVam} component={VamFoodPrices} />
        <Route path={routesPath.adminCountriesPriceApproval} component={PriceApproval} />
        <Route path={routesPath.adminCountriesFoodItems} component={FoodDatabase} />
        <Route path={routesPath.adminCountriesRecipes} component={Recipes} />
      </Switch>
    </Layout>
  );
};

export default Countries;
