import { connectRouter } from "connected-react-router";
import { history } from "utils/history";

import pdf from "containers/PDF/reducer";
import app from "containers/App/reducer";
import myMenus from "containers/MyMenus/reducer";
import compareMenus from "containers/Compare/reducer";
import results from "containers/Results/reducer";
import menu from "containers/Menu/reducer";
import foodBasket from "containers/FoodBasket/reducer";
import optimizedMenu from "containers/OptimizedMenu/reducer";
import generalForm from "containers/GeneralInfo/GeneralForm/reducer";
import menuStructure from "containers/OptimizedMenu/Rules/FoodRules/reducer";
import selectCountry from "containers/SelectCountry/reducer";
import tutorials from "containers/Tutorials/reducer";
import communityMenu from "containers/GeneralInfoCommunity/GeneralForm/reducer";
import admin from "containers/Admin/reducer";

export default {
  router: connectRouter(history),
  app,
  pdf,
  compareMenus,
  myMenus,
  results,
  menu,
  tutorials,
  selectCountry,
  generalForm,
  foodBasket,
  optimizedMenu,
  menuStructure,
  communityMenu,
  admin,
};
