import { useState, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Tag } from "@wfp/ui";

import { Text, Module } from "components";
import { Star, StarFilled } from "components/Icons";
import { loadCompareMenus, changeFavourite } from "../actions";
import { saveComparisonList } from "containers/MyMenus/actions";
import Modals from "containers/OptionsAndModals/Modals";
import Options from "containers/OptionsAndModals/Options";
import routesPath from "containers/App/Routes/paths";
import createMenuMessages from "containers/CreateNewMenu/messages";
import { MANUAL, COMMUNITY } from "containers/MyMenus/labels";
import messages from "../messages";
import { ContainerTextAndStar } from "./styles";

const Menus = ({ menus, comparisonList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { ids } = useParams();

  const [menuAndAction, setMenuAndAction] = useState();

  const clickOnStar = useCallback(
    (id) => {
      dispatch(changeFavourite({ id, ids }));
    },
    [dispatch, ids],
  );

  const removeFromComparisonList = useCallback(
    (id) => {
      const filteredList = comparisonList.filter((el) => el.id !== id);
      dispatch(saveComparisonList(filteredList));
      const ids = filteredList.map((el) => el.id).join(",");
      history.push(filteredList.length === 1 ? routesPath.menus : routesPath.compare.replace(":ids", ids));
    },
    [comparisonList, dispatch, history],
  );

  const withTag = useMemo(() => {
    if (!menus) return;
    return !!menus.filter((el) => [MANUAL, COMMUNITY].includes(R.pathOr("", ["optimization", "type"], el))).length;
  }, [menus]);

  const callbackOnDelete = useCallback(
    (id) => {
      removeFromComparisonList(id);
    },
    [removeFromComparisonList],
  );

  const callbackOnRename = useCallback(() => {
    dispatch(loadCompareMenus({ ids }));
  }, [dispatch, ids]);

  const renderTag = useCallback(
    (optimization) => {
      const optimizationType = R.pathOr("", ["type"], optimization);

      switch (optimizationType) {
        case MANUAL:
          return <Tag type="info" children={intl.formatMessage(createMenuMessages.titleManual)} />;
        case COMMUNITY:
          return <Tag type="info" children={intl.formatMessage(createMenuMessages.titleCommunity)} />;
        default:
          return null;
      }
    },
    [intl],
  );

  return (
    <>
      {menus && (
        <>
          <Module useSticky title={intl.formatMessage(messages.menusToCompare)}>
            <Module.ContentWithBorder menus={menus} withPaddingTop={!withTag ? "15px 20px" : ""}>
              {({ name, id, is_starred, optimization }) => (
                <>
                  <ContainerTextAndStar>
                    <div onClick={() => clickOnStar(id)}>
                      {is_starred ? <StarFilled size="18" /> : <Star size="18" />}
                    </div>
                    <Text bold value={name} />
                    <Options
                      original={{ id, name, optimization }}
                      callbackOnDelete={callbackOnDelete}
                      setMenuAndAction={setMenuAndAction}
                      noClone
                      withRemoveFromCompare
                      classNameIcon={"rotate90"}
                      onRemove={removeFromComparisonList}
                    />
                  </ContainerTextAndStar>
                  {renderTag(optimization)}
                </>
              )}
            </Module.ContentWithBorder>
          </Module>
          <Modals
            menuAndAction={menuAndAction}
            setMenuAndAction={setMenuAndAction}
            callbackOnRename={callbackOnRename}
          />
        </>
      )}
    </>
  );
};

export default Menus;
