import { useMemo } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { CipLegend } from "components/Charts";
import { RowStyled, ColStyled, newColorBySource } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import messages from "containers/Results/messages";
import { getCosts, getAverageSources } from "containers/Results/utils";
import { InfoBlock, SourcingBlock } from "./styles";

const Stats = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const dayComposition = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_COMPOSITION}` });

  const itemsNumber = useMemo(() => dayComposition.length, [dayComposition]);

  const foodGroupsNumber = useMemo(
    () => Object.keys(R.groupBy(({ major_group }) => major_group, dayComposition)).length,
    [dayComposition],
  );

  const totalMealWeight = useMemo(
    () => dayComposition.reduce((acc, item) => acc + item.quantity, 0).toFixed(1),
    [dayComposition],
  );

  const { cost } = useMemo(() => getCosts(dayComposition), [dayComposition]);
  const renderCost = useMemo(() => `$ ${cost.toFixed(4)}`, [cost]);
  const averageSources = useMemo(() => getAverageSources(cost, dayComposition), [cost, dayComposition]);

  return (
    <RowStyled>
      <ColStyled xs={6} padding="0">
        <RowStyled flex={1}>
          <InfoBlock>
            <Text fontSize="24px" defaultColor={colors["text-02"].hex} bold value={foodGroupsNumber} />
            <Text defaultColor={colors["text-02"].hex} value={intl.formatMessage(messages.foodGroups)} />
          </InfoBlock>
          <InfoBlock>
            <Text fontSize="24px" defaultColor={colors["text-02"].hex} bold value={`${totalMealWeight} g`} />
            <Text defaultColor={colors["text-02"].hex} value={intl.formatMessage(messages.totalMealWeight)} />
          </InfoBlock>
        </RowStyled>
        <RowStyled flex={1}>
          <InfoBlock>
            <Text fontSize="24px" defaultColor={colors["text-02"].hex} bold value={itemsNumber} />
            <Text defaultColor={colors["text-02"].hex} value={intl.formatMessage(messages.foodItems)} />
          </InfoBlock>
          <InfoBlock>
            <Text fontSize="24px" defaultColor={colors["text-02"].hex} bold value={renderCost} />
            <Text defaultColor={colors["text-02"].hex} value={intl.formatMessage(messages.averageCostPerMeal)} />
          </InfoBlock>
        </RowStyled>
      </ColStyled>
      <ColStyled xs={6} padding="0">
        <SourcingBlock>
          <Text
            fontSize="16px"
            defaultColor={colors["text-02"].hex}
            bold
            marginBottom="10px"
            value={intl.formatMessage(messages.sourcingChannels)}
          />
          {averageSources.map(({ source, label, value }) => (
            <RowStyled key={source} middle="xs">
              <CipLegend
                fillColor={newColorBySource[source]}
                textColor={colors["text-02"].hex}
                value={`${label} - ${value}%`}
              />
            </RowStyled>
          ))}
        </SourcingBlock>
      </ColStyled>
    </RowStyled>
  );
};

export default Stats;
