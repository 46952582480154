import { View } from "@react-pdf/renderer";

import AverageCost from "./AverageCost";
import FoodNumbers from "./FoodNumbers";
import MainCostsDrivers from "./MainCostsDrivers";
import styles from "./styles";

const AverageAndDriversCosts = ({ costs, costsDrivers, numbers }) => {
  const { line, firstSection, section } = styles;

  return (
    <View style={[section, { border: 0, marginRight: 20 }]}>
      <View style={firstSection}>
        <AverageCost costs={costs} />
        <View style={line} />
        <MainCostsDrivers costsDrivers={costsDrivers} />
      </View>
      <FoodNumbers numbers={numbers} />
    </View>
  );
};

export default AverageAndDriversCosts;
