import React, { useCallback } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { RowStyled, ColStyled } from "components/utils";
import { Link, Tag, colors } from "@wfp/ui";
import { Text } from "components";
import { saveAccount } from "containers/App/actions";
import { saveComparisonList } from "containers/MyMenus/actions";
import messages from "./messages";
import { updateHtmlElement } from "utils/utils";

function Language({ mainNavigationRef, language, description, beta }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onClick = useCallback(() => {
    axios.defaults.headers.common["Accept-Language"] = language;
    updateHtmlElement(language);
    const body = { profile: { language } };
    dispatch(saveComparisonList());
    dispatch(saveAccount({ body }));
    mainNavigationRef.current.onChangeSub("close");
  }, [dispatch, language, mainNavigationRef]);

  return (
    <ColStyled onClick={onClick}>
      <RowStyled middle="xs" height="36px">
        <Link inline children={intl.formatMessage(messages[language])} />
        {description && (
          <Text
            marginLeft="5px"
            defaultColor={colors["brand-01"].hex}
            value={intl.formatMessage(messages[description])}
          />
        )}
        {beta && (
          <ColStyled margin="0 0 0 5px">
            <Tag type="custom" children={intl.formatMessage(messages.betaVersion)} />
          </ColStyled>
        )}
      </RowStyled>
    </ColStyled>
  );
}

export default Language;
