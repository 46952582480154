import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadSuccess,
  resetResults,
  loadSingleNutritionalValuesStarted,
  loadSingleNutritionalValuesEnded,
  loadSingleNutritionalValuesSuccess,
  loadCommunityGroupsAndItemsSuccess,
} from "./actions";

export const initialState = {
  informations: [],
  selectedFoodItems: [],
  nutrients: [],
  nutritionalRequirements: [],
  nutrientRestrictions: [],
  majorGroups: [],
  menuScores: {},
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadSuccess.type]: (state, action) => ({ ...state, ...action.payload }),
  [loadSingleNutritionalValuesStarted.type]: (state) => {
    state.loadingNutritionalValues = true;
  },
  [loadSingleNutritionalValuesEnded.type]: (state) => {
    state.loadingNutritionalValues = false;
  },
  [loadSingleNutritionalValuesSuccess.type]: (state, action) => {
    state.nutrients = { ...state.nutrients, ...action.payload.nutrients };
  },
  [loadCommunityGroupsAndItemsSuccess.type]: (state, action) => {
    state.majorGroups = action.payload.majorGroups;
  },

  [resetResults]: () => initialState,
});
