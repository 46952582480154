import styled from "styled-components";
import { colors } from "@wfp/ui";

export const ToggleContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors["field-border-01"].hex};
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ToggleUnit = styled.div`
  margin: 2px;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? `${colors["ui-01"].hex}` : `${colors["field-border-01"].hex}`)};
`;
