import { useIntl } from "react-intl";
import { Blockquote } from "@wfp/ui";
import { Text } from "components";
import countriesMessages from "../messages";

const SubsetWarning = () => {
  const intl = useIntl();

  return (
    <Blockquote kind="warning">
      <Text fontSize="14px" bold value={intl.formatMessage(countriesMessages.sourcesSubTitle)} />
    </Blockquote>
  );
};

export default SubsetWarning;
