import { useCallback } from "react";

import { ACTIONS } from "../constants";
import Clone from "./Clone";
import Share from "./Share";
import Rename from "./Rename";
import Download from "./Download";

const Modals = ({ onClose, callbackOnRename, menuAndAction, setMenuAndAction }) => {
  const closeModal = useCallback(() => {
    if (!menuAndAction) return;
    setMenuAndAction(false);
    if (onClose) onClose();
  }, [menuAndAction, onClose, setMenuAndAction]);

  return (
    <>
      {menuAndAction && menuAndAction.action === ACTIONS.CLONE && (
        <Clone menuAndAction={menuAndAction} closeModal={closeModal} />
      )}
      {menuAndAction && menuAndAction.action === ACTIONS.RENAME && (
        <Rename menuAndAction={menuAndAction} closeModal={closeModal} callback={callbackOnRename} />
      )}
      {menuAndAction && menuAndAction.action === ACTIONS.SHARE && (
        <Share menuAndAction={menuAndAction} closeModal={closeModal} />
      )}
      {menuAndAction && menuAndAction.action === ACTIONS.DOWNLOAD && (
        <Download menuAndAction={menuAndAction} closeModal={closeModal} />
      )}
    </>
  );
};

export default Modals;
