import * as R from "ramda";

import { FIELDS } from "containers/FoodBasket/constants";

// Returns "true" if button will be disabled
export const validation = (item) => {
  // Left section, "Food item" can't be empty
  if (R.isEmpty(item[FIELDS.ITEM])) {
    return true;
  }

  // Section above "Add additional price" button. Skipping "Currency" and "Unit" since they can't be emptied
  if (!item[FIELDS.PRICE]) {
    return true;
  }

  // Additional costs checks
  if (!R.isEmpty(item[FIELDS.ADDITIONAL_COSTS])) {
    const additionalCosts = item[FIELDS.ADDITIONAL_COSTS];
    const failedValidation = additionalCosts.some((cost) => {
      return !cost[FIELDS.PRICE] || R.isEmpty(cost[FIELDS.TYPE]) || R.isEmpty(cost[FIELDS.UNIT]);
    });

    if (failedValidation) return true;
  }

  // Section below "Add additional price" button. Skipping "When was the price collected?" section since fields within can't be emptied
  if (R.isEmpty(item[FIELDS.PROCUREMENT_SOURCES]) || R.isEmpty(item[FIELDS.ORIGIN])) {
    return true;
  }

  return false;
};
