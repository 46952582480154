import { FormattedMessage } from "react-intl";

import messages from "./messages";

const DailyHelper = () => {
  return (
    <div style={{ fontSize: "12px", padding: "10px" }}>
      <b>{<FormattedMessage {...messages.dailyFirstRow} />}</b>
      <ul className="listStyle">
        <li className="dotStyle">{<FormattedMessage {...messages.dailySecondRow} />}</li>
        <li className="dotStyle">{<FormattedMessage {...messages.dailyThirdRow} />}</li>
        <li className="dotStyle">{<FormattedMessage {...messages.dailyFourthRow} />}</li>
        <li className="dotStyle">{<FormattedMessage {...messages.dailyFifthRow} />}</li>
      </ul>
    </div>
  );
};

export default DailyHelper;
