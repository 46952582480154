import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { Text } from "components";
import { iconClose } from "@wfp/icons";
import messages from "components/Button/messages";
import { useHtmlHook } from "hooks";
import { ContainerIcon } from "./styles";

function Remove({ onRemove, menu }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const clickOnRemove = useCallback(() => {
    onRemove(menu.id);
  }, [menu.id, onRemove]);

  return (
    <ContainerIcon onClick={clickOnRemove} hover={colors["brand-01"].hex} isRTL={isRTL}>
      <Icon icon={iconClose} kind="inverse" fill={colors["text-01"].hex} width="18px" height="18px" />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.remove)} />
    </ContainerIcon>
  );
}

export default Remove;
