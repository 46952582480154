// TODO replace those constants with actual type of this data.
export const FIELDS = {
  RESTRICTION: "restriction",
  ITEM_ID: "item_id",
  ITEM_RESTRICTION: "food_item_restriction",
  INCLUDE: "include",
  EXCLUDE_FROM_GROUP: "exclude_from_group_restriction",
  ITEM_NAME: "food_item_name",
  MAJOR_LABEL: "major_en",
  AMOUNT_RANGE: "amount_range",
  MIN_MAX_AMOUNT_RANGE: "min_max_amount_range",
  DEFAULT_AMOUNT_RANGE: "default_amount_range",
  MATCH_WITH: "matching_with",
  UNMATCH_WITH: "unmatched_with",
  MAJOR_GROUP_ID: "major_group_id",
  GROUP_RESTRICTION: "food_group_restriction",
  DAILY_REPETITION: "daily_repetition",
  WEEKLY_REPETITION: "weekly_repetition",
  MAXIMUM_REPETITION: "max_product_repetition",
  ENABLED_DAILY_MAX: "enabled_daily_max",
  ENABLED_WEEKLY_MAX: "enabled_weekly_max",
  ENABLED_REP_MAX: "enabled_rep_max",
  MAX_PER_WEEK: "max_valid_repetition",
  VALIDATIONS: "validations",
  DAILY_REQUIRED: "daily_max_valid_range",
  WEEKLY_REQUIRED: "weekly_max_valid_range",
  MAXIMUM_REQUIRED: "repetition_max_required",
  NUMBER_ITEMS_IN_GROUP: "number_items_in_group",
  EXCLUDE: "exclude_from_menu",
  RESTRICTION_TYPE: "restriction_type",
  REPETITION: "repetition",
  INCLUDED_DAYS: "included_days",
  EXCLUDED_DAYS: "excluded_days",
  EXCLUDED_GROUP: "excluded_group",
};

export const FOOD_RESTRICTION_PATH = `${FIELDS.RESTRICTION}.${FIELDS.ITEM_RESTRICTION}`;
export const GROUP_RESTRICTION_PATH = `${FIELDS.RESTRICTION}.${FIELDS.GROUP_RESTRICTION}`;
export const GROUP_VALIDATION_PATH = `${FIELDS.RESTRICTION}.${FIELDS.VALIDATIONS}`;
