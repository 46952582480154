import { createReducer } from "@reduxjs/toolkit";

import { saveInResultsPdf } from "./actions";

export const initialState = {
  results: {},
};

export default createReducer(initialState, {
  [saveInResultsPdf.type]: (state, action) => {
    state.results = { ...state.results, ...action.payload };
  },
});
