import { DateRangePickerInput } from "@wfp/ui";
import { DateRangePicker } from "react-dates";
import "containers/App/react_dates_overrides.scss";
import { Controller } from "react-hook-form";

const DateRangeHookForm = ({
  control,
  name,
  labelText,
  startDate,
  startDateId,
  endDate,
  endDateId,
  onDatesChange,
  firstDayOfWeek = 1,
  displayFormat = "DD/MM/YYYY",
  isOutsideRange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <DateRangePickerInput
          datePicker={DateRangePicker}
          labelText={labelText}
          startDate={startDate}
          startDateId={startDateId}
          endDate={endDate}
          endDateId={endDateId}
          onDatesChange={({ startDate, endDate }) => onDatesChange({ startDate, endDate, onChangeField: onChange })}
          firstDayOfWeek={firstDayOfWeek}
          displayFormat={displayFormat}
          isOutsideRange={isOutsideRange}
        />
      )}
    />
  );
};

export default DateRangeHookForm;
