import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  getListGroupsAndItems,
  getMenuComposition,
  getMealComposition,
  getItemRestriction,
  getGroupRestriction,
  postItemRestriction,
  postGroupRestriction,
  delItemRestriction,
  delGroupRestriction,
} from "api/api";
import { setAlert } from "containers/App/actions";
import {
  loadStarted,
  loadEnded,
  loadDiversityRequest,
  loadItemsSuccess,
  loadGroupsAndItemsSuccess,
  loadMajorGroupsSuccess,
  loadCompositionsSuccess,
  loadItemRestrictionRequest,
  loadGroupRestrictionRequest,
  loadRestrictionSuccess,
  saveItemRestrictionRequest,
  saveGroupRestrictionRequest,
  deleteItemRestriction,
  deleteGroupRestriction,
} from "./actions";

export function* loadGroupsAndItems(id) {
  try {
    const results = yield call(getListGroupsAndItems, id);
    yield put(loadGroupsAndItemsSuccess({ groupsAndItems: results }));
    yield put(
      loadMajorGroupsSuccess({
        majorGroups: results.major_groups.reduce((acc, group) => ({ ...acc, [group.major_id]: group.major_label }), {}),
      })
    );
    const items = results.major_groups.flatMap((majorGroup) =>
      majorGroup.minor_groups.flatMap((minorGroup) => minorGroup.items)
    );
    const foodItems = [...new Set(items.map((item) => ({ value: item.name, label: item.name })))];
    yield put(loadItemsSuccess({ foodItems: foodItems }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadMenuComposition(id) {
  try {
    return yield call(getMenuComposition, id);
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadMealComposition(id) {
  try {
    return yield call(getMealComposition, id);
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCompositions(id) {
  try {
    const [menu, meals] = yield all([loadMenuComposition(id), loadMealComposition(id)]);
    yield put(loadCompositionsSuccess({ composition: { menu, meals } }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadDiversitySaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    yield all([loadGroupsAndItems(id), loadCompositions(id)]);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadItemRestrictionSaga({ payload: { id, params, name, cb } }) {
  try {
    yield put(loadStarted());
    const nameAndKind = Object.entries(params)[0];
    const results = yield call(getItemRestriction, id, params);
    yield put(
      loadRestrictionSuccess({
        restriction: {
          name,
          [nameAndKind[0]]: { ...results, id: params[nameAndKind[0]] },
        },
      })
    );
    if (cb) cb(false);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadGroupRestrictionSaga({ payload: { id, params, cb } }) {
  try {
    yield put(loadStarted());
    const nameAndKind = Object.entries(params)[0];
    const results = yield call(getGroupRestriction, id, params);
    const {
      food_group_restriction: { major_label, minor_label },
    } = results;
    yield put(
      loadRestrictionSuccess({
        restriction: {
          name: minor_label || major_label,
          [nameAndKind[0]]: { ...results, id: params[nameAndKind[0]] },
        },
      })
    );
    if (cb) cb(false);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* saveItemRestrictionSaga({ payload: { id, params, body } }) {
  try {
    yield put(loadStarted());
    yield call(postItemRestriction, id, params, body);
    yield call(loadDiversitySaga, { payload: { id } });
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* saveGroupRestrictionSaga({ payload: { id, params, body } }) {
  try {
    yield put(loadStarted());
    yield call(postGroupRestriction, id, params, body);
    yield call(loadDiversitySaga, { payload: { id } });
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* deleteItemRestrictionSaga({ payload: { id, params } }) {
  try {
    yield put(loadStarted());
    yield call(delItemRestriction, id, params);
    yield call(loadDiversitySaga, { payload: { id } });
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* deleteGroupRestrictionSaga({ payload: { id, params } }) {
  try {
    yield put(loadStarted());
    yield call(delGroupRestriction, id, params);
    yield call(loadDiversitySaga, { payload: { id } });
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* diversitySaga() {
  yield takeLatest(loadDiversityRequest, loadDiversitySaga);
  yield takeLatest(loadItemRestrictionRequest, loadItemRestrictionSaga);
  yield takeLatest(loadGroupRestrictionRequest, loadGroupRestrictionSaga);
  yield takeLatest(saveItemRestrictionRequest, saveItemRestrictionSaga);
  yield takeLatest(saveGroupRestrictionRequest, saveGroupRestrictionSaga);
  yield takeLatest(deleteItemRestriction, deleteItemRestrictionSaga);
  yield takeLatest(deleteGroupRestriction, deleteGroupRestrictionSaga);
}
