import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectSidebar = () => (state) => R.pathOr(initialState, ["tutorials"], state);

export default () => createSelector(selectSidebar(), (tutorialState) => tutorialState);

export const selectLoading = createSelector(selectSidebar(), (tutorialState) =>
  R.pathOr(false, ["loading"], tutorialState),
);

export const selectTutorials = createSelector(selectSidebar(), (tutorialState) =>
  R.pathOr({}, ["data"], tutorialState),
);

export const selectTutorial = createSelector(selectSidebar(), (tutorialState) =>
  R.pathOr(null, ["tutorial"], tutorialState),
);
