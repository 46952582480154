import styled from "styled-components";
import { colors } from "@wfp/ui";
import Select from "react-select";

export const SelectStyled = styled(Select)`
  .wfp--react-select__multi-value {
    background: ${colors["ui-02"].hex};
  }
  .wfp--react-select__multi-value__label {
    padding: 6px;
  }
  .wfp--react-select__multi-value__remove {
    :after {
      color: ${colors["text-02"].hex};
      content: "✕";
      padding: 3px;
      font-size: 12px;
    }
    svg {
      display: none;
    }
  }
`;
