import React, { useCallback } from "react";
import * as R from "ramda";
import Text from "components/Text";
import { NumberInput } from "@wfp/ui";
import { colors } from "@wfp/ui";

export default function FieldNumber({
  input,
  meta,
  labelText,
  allowEmpty,
  maxValue,
  minValue,
  className,
  invalid,
  invalidText,
  addActionOnChange,
  placeholder,
  fontSizeLabel,
  fontColorLabel,
  labelInfo,
  step,
}) {
  const emptyValue = allowEmpty ? 0 : null;

  const onChange = useCallback(
    (event) => {
      let value = typeof event !== "number" ? event.target.value : event;
      const valueReturned = value ? Number(value) : emptyValue;
      if (addActionOnChange) addActionOnChange(valueReturned);
      return input.onChange(valueReturned);
    },
    [addActionOnChange, emptyValue, input],
  );

  return (
    <>
      {labelText && (
        <Text marginBottom="5px" bold fontSize={fontSizeLabel} defaultColor={fontColorLabel} value={labelText} />
      )}
      {labelInfo && (
        <Text
          marginBottom="5px"
          marginTop="-5px"
          bold
          fontSize="12px"
          defaultColor={colors["ui-05"].hex}
          value={labelInfo}
        />
      )}
      <NumberInput
        className={`${className || ""} error-field`}
        id={`${input.name}-number`}
        labelText={""}
        hideLabel={true}
        onChange={onChange}
        value={input.value}
        max={maxValue}
        min={minValue}
        step={step || 1}
        allowEmpty={allowEmpty}
        placeholder={placeholder}
        invalid={invalid || !!R.pathOr(false, ["error"], meta)}
        invalidText={invalidText || R.pathOr("", ["error"], meta)}
      />
    </>
  );
}
