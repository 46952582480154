import { useCallback, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { NewModule } from "components";
import { useFormContext } from "react-hook-form";

import { searchMyMenus } from "../actions";
import Header from "./Header";
import SectionTitles from "./SectionTitles";
import Filters from "./Filters";
import Footer from "./Footer";

const SearchAndFilter = () => {
  const dispatch = useDispatch();
  const { getValues, reset } = useFormContext();
  const [visibleFilters, setVisibleFilters] = useState(false);

  const closeFilters = useCallback(() => {
    reset();
    setVisibleFilters(false);
  }, [reset]);

  // Click on "Apply": close filters section, take form values and fetch menus
  const closeFiltersAndFetchMenus = useCallback(() => {
    setVisibleFilters(false);
    const queryParams = { page: 1, page_size: 10, ...getValues() };
    dispatch(searchMyMenus({ body: queryParams }));
  }, [dispatch, getValues]);

  const header = useMemo(
    () => (
      <Header
        visibleFilters={visibleFilters}
        setVisibleFilters={setVisibleFilters}
        closeFiltersAndFetchMenus={closeFiltersAndFetchMenus}
        closeFilters={closeFilters}
      />
    ),
    [visibleFilters, closeFiltersAndFetchMenus, closeFilters],
  );

  const body = useMemo(
    () =>
      visibleFilters ? (
        <>
          <SectionTitles />
          <Filters />
        </>
      ) : null,
    [visibleFilters],
  );

  const footer = useMemo(
    () =>
      visibleFilters ? (
        <Footer closeFiltersAndFetchMenus={closeFiltersAndFetchMenus} closeFilters={closeFilters} />
      ) : null,
    [visibleFilters, closeFiltersAndFetchMenus, closeFilters],
  );

  return (
    <NewModule
      innerPadding="10px 15px"
      hasFooterLine
      header={header}
      body={visibleFilters ? body : null}
      footer={footer}
    />
  );
};

export default SearchAndFilter;
