import React, { useMemo, useCallback } from "react";
import * as R from "ramda";
import { injectIntl } from "react-intl";
import { ColStyled, loaderWrapperStyle } from "components/utils";
import { Button } from "components";
import { useForm } from "react-final-form";
import buttonMessage from "components/Button/messages";
import { useDispatch, useSelector } from "react-redux";
import { Icon, colors } from "@wfp/ui";
import { validEmail } from "containers/App/actions";
import { iconCheckmarkOutline, iconCloseOutline } from "@wfp/icons";
import { selectLoadingSmall, selectUsername } from "containers/App/selectors";
import messages from "../messages";
import { Oval } from "react-loader-spinner";

function Validate({ values, setErrorMessage, isValidInput, validKey, email, intl }) {
  const form = useForm();
  const dispatch = useDispatch();
  const showValidate = useMemo(() => R.isEmpty(isValidInput), [isValidInput]);
  const showError = useMemo(() => !R.isEmpty(isValidInput) && !isValidInput, [isValidInput]);
  const showSuccess = useMemo(() => !!isValidInput, [isValidInput]);
  const loading = useSelector(selectLoadingSmall);
  const username = useSelector(selectUsername);
  const isYourEmail = useMemo(() => username === email, [email, username]);

  const changeValidation = useCallback((value) => form.change(validKey, value), [form, validKey]);

  const validateEmail = useCallback(() => {
    if (Object.values(values).filter((el) => el.value === email).length > 1) {
      setErrorMessage(messages.emailAlreadyEntered);
      return changeValidation(false);
    }
    if (isYourEmail) {
      setErrorMessage(messages.shareMenuWithYourself);
      return changeValidation(false);
    }
    dispatch(validEmail({ email, cb: changeValidation }));
  }, [changeValidation, dispatch, email, isYourEmail, setErrorMessage, values]);

  return (
    <ColStyled xs={2}>
      {showValidate && loading !== email && (
        <Button
          disabled={R.isEmpty(email)}
          kind="secondary"
          children={intl.formatMessage(buttonMessage.validate)}
          onClick={validateEmail}
        />
      )}
      {loading === email && (
        <ColStyled padding="3px" className="center-xs">
          <Oval color={colors["brand-01"].hex} height={30} width={30} wrapperStyle={loaderWrapperStyle} />
        </ColStyled>
      )}
      {showError && (
        <ColStyled padding="8px" className="center-xs">
          <Icon icon={iconCloseOutline} fill={colors["support-01"].hex} width="28px" height="28px" />
        </ColStyled>
      )}
      {showSuccess && (
        <ColStyled padding="4px" className="center-xs">
          <Icon icon={iconCheckmarkOutline} fill={colors["support-02"].hex} width="28px" height="28px" />
        </ColStyled>
      )}
    </ColStyled>
  );
}

export default injectIntl(Validate);
