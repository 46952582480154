import { defineMessages } from "react-intl";

export default defineMessages({
  nutritionTitle: {
    id: "generalInfo.nutritionTitle",
    defaultMessage: "Nutritional requirements",
  },
  nutritionButton: {
    id: "generalInfo.nutritionButton",
    defaultMessage: "Adjust nutritional requirements",
  },
});
