import { defineMessages } from "react-intl";

export default defineMessages({
  chooseLanguage: {
    id: "header.chooseLanguage",
    defaultMessage: "Choose language",
  },
  en: {
    id: "language.en",
    defaultMessage: "English",
  },
  es: {
    id: "language.es",
    defaultMessage: "Spanish",
  },
  "es-PR": {
    id: "language.es-PR",
    defaultMessage: "Spanish",
  },
  fr: {
    id: "language.fr",
    defaultMessage: "French",
  },
  pt: {
    id: "language.pt",
    defaultMessage: "Portuguese",
  },
  ru: {
    id: "language.ru",
    defaultMessage: "Russian",
  },
  ar: {
    id: "language.ar",
    defaultMessage: "Arabic",
  },
  khm: {
    id: "language.khm",
    defaultMessage: "Cambodian",
  },
  betaVersion: {
    id: "language.betaVersion",
    defaultMessage: "beta version",
  },
  general: {
    id: "language.general",
    defaultMessage: "(General)",
  },
  dominicanRepublic: {
    id: "language.dominicanRepublic",
    defaultMessage: "(Dominican Republic)",
  },
});
