import routesPaths from "containers/App/Routes/paths";
import messages from "containers/App/messages";
import { host } from "api/api";

export const getPages = (isSuperUser) => {
  const pages = [
    {
      page: "Global",
      display_name: messages.djangoAdmin,
      path: `${host}/admin/`,
      targetBlank: true,
    },
    {
      page: "Users",
      display_name: messages.users,
      path: routesPaths.adminUsers,
    },
    {
      page: "Countries",
      display_name: messages.countries,
      path: routesPaths.adminCountriesNutrients,
    },
  ];

  return isSuperUser ? pages : pages.slice(1);
};
