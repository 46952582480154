import { useMemo } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconClose } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Line } from "components";
import buttonMessages from "components/Button/messages";
import { ValidatedSelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import AdvancedSettings from "./AdvancedSettings";
import { KEYS, FIELDS } from "../constants";
import messages from "../messages";
import MinOrMax from "./MinOrMax";
import { SourceContainer } from "./styles";
import errorMessages from "utils/messages";

const Source = ({ index, options, remove }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const sources = useWatch({ control, name: FIELDS.SOURCING });

  // Set option as "disabled" if already added to the rules list.
  const filteredOptions = useMemo(() => {
    if (R.isEmpty(sources)) return options;

    return options.map((option) => {
      const found = sources.find(({ source }) => source === option.value);
      return found ? { ...option, isDisabled: true } : option;
    });
  }, [options, sources]);

  return (
    <SourceContainer>
      <ColStyled margin="10px 10px 0" className="end-xs">
        <Icon
          className="pointer"
          description={intl.formatMessage(buttonMessages.remove)}
          icon={iconClose}
          width="16"
          height="16"
          fill={colors["ui-05"].hex}
          onClick={remove}
        />
      </ColStyled>
      <RowStyled>
        <ColStyled xs={4} margin="0 0 30px" padding="0">
          <ValidatedSelectHookForm
            control={control}
            name={`${FIELDS.SOURCING}[${index}].${KEYS.SOURCE}`}
            options={filteredOptions}
            placeholder={intl.formatMessage(messages.selectSourcing)}
            rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
          />
        </ColStyled>
      </RowStyled>

      <RowStyled>
        <ColStyled xs={6} margin="0 0 30px" padding="0">
          <MinOrMax
            keyLimit={`${FIELDS.SOURCING}[${index}].${KEYS.LIMIT}`}
            keyPercentage={`${FIELDS.SOURCING}[${index}].${KEYS.VALUE}`}
            label={intl.formatMessage(messages.setLimit)}
            xsMinMax={2}
            xsField={3}
            xsPerc={1}
          />
        </ColStyled>
      </RowStyled>

      <AdvancedSettings name={`${FIELDS.SOURCING}[${index}]`} />
      <Line margin="20px 0 0" />
    </SourceContainer>
  );
};

export default Source;
