import * as R from "ramda";
import { View } from "@react-pdf/renderer";

import generalStyles from "containers/PDF/styles";
import AverageAndDrivers from "./AverageAndDrivers";
import Header from "./Header";
import NutritionalValues from "./NutritionalValues";
import SourcingChannels from "./SourcingChannels";

const PageOne = ({ imageUrlNutrients, imageUrlSources, preliminaryInfo, pdf }) => {
  const { row } = generalStyles;
  const { costs, costsDrivers, numbers, nutrients, reqNotMet } = R.pathOr({}, ["results"], pdf);

  return (
    <>
      <Header preliminaryInfo={preliminaryInfo} />
      <View style={row}>
        <AverageAndDrivers {...{ costs, costsDrivers, numbers }} />
        <SourcingChannels imageUrl={imageUrlSources} />
      </View>
      <NutritionalValues imageUrl={imageUrlNutrients} nutrients={nutrients} reqNotMet={reqNotMet} />
    </>
  );
};

export default PageOne;
