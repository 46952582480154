import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { colors, Icon } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { getIcon } from "components/Icons";
import { FOOD_RESTRICTION_TYPES } from "containers/OptimizedMenu/Rules/constants";
import { joinStrings } from "utils/utils";
import messages from "../messages";
import { RuleContainer, RuleNameContainer } from "./styles";
import { IconStyled } from "../styles";

const GroupRule = ({
  groupId,
  majorGroups,
  daily_repetition,
  weekly_repetition,
  max_product_repetition,
  minor_group,
  unmatched_with,
  amount_range,
  restriction_type,
  enabled_daily_max,
  enabled_weekly_max,
  editRule,
  deleteRule,
  label,
  majorLabel,
  kind,
}) => {
  const intl = useIntl();

  const buildRepetition = useCallback(
    (first, second, flag, key) =>
      flag
        ? intl.formatMessage(messages.rangePortionsPer, { first, second, key })
        : first
        ? intl.formatMessage(messages.atLeastPortionsPer, { first, key })
        : "",
    [intl]
  );

  const dailyRepetition = useMemo(() => {
    if (!daily_repetition) return "";
    return buildRepetition(
      daily_repetition[0],
      daily_repetition[1],
      enabled_daily_max,
      intl.formatMessage(messages.day)
    );
  }, [buildRepetition, daily_repetition, enabled_daily_max, intl]);

  const weeklyRepetition = useMemo(() => {
    if (!weekly_repetition) return "";
    return buildRepetition(
      weekly_repetition[0],
      weekly_repetition[1],
      enabled_weekly_max,
      intl.formatMessage(messages.week)
    );
  }, [buildRepetition, enabled_weekly_max, intl, weekly_repetition]);

  const productRepetition = useMemo(() => {
    if (!max_product_repetition) return "";
    return intl.formatMessage(messages.maxRepetition, {
      maxRepetition: max_product_repetition,
    });
  }, [intl, max_product_repetition]);

  const portionSize = useMemo(() => {
    if (!amount_range || R.equals(amount_range, [null, null])) return "";
    const minPortion = amount_range[0];
    const maxPortion = amount_range[1];
    if (minPortion && maxPortion)
      return intl.formatMessage(messages.rangePortionSize, {
        minPortion,
        maxPortion,
      });
    if (minPortion && !maxPortion)
      return intl.formatMessage(messages.minPortionSize, { minPortion });
    if (!minPortion && maxPortion)
      return intl.formatMessage(messages.maxPortionSize, { maxPortion });
  }, [amount_range, intl]);

  const unmatchedWith = useMemo(() => {
    if (minor_group || !unmatched_with || R.isEmpty(unmatched_with)) return "";
    const getLabel = unmatched_with.map((groupId) => majorGroups[groupId]);
    return intl.formatMessage(messages.unmatchedWith, {
      items: getLabel.join(", "),
    });
  }, [intl, majorGroups, minor_group, unmatched_with]);

  const sentence = useMemo(() => {
    const constraints = [
      dailyRepetition,
      weeklyRepetition,
      productRepetition,
      unmatchedWith,
      portionSize,
    ].filter(Boolean);
    return joinStrings(constraints, constraints.pop(), intl);
  }, [
    dailyRepetition,
    intl,
    portionSize,
    productRepetition,
    unmatchedWith,
    weeklyRepetition,
  ]);

  const key = useMemo(
    () => (kind === "minor" ? "minor_group_id" : "major_group_id"),
    [kind]
  );


  let isIncluded = restriction_type === FOOD_RESTRICTION_TYPES.INCLUDE;
  let isExcluded = restriction_type === FOOD_RESTRICTION_TYPES.EXCLUDE;
  let isDefault = restriction_type === FOOD_RESTRICTION_TYPES.DEFAULT;

  return (
    <RuleContainer middle="xs" padding="16px 8px">
      <ColStyled xs={4}>
        <RuleNameContainer>
          {majorLabel && (
            <IconStyled
              disabled={kind === "major" && isExcluded}
              marginInlineEnd="0"
            >
              {getIcon(majorLabel) && getIcon(majorLabel)()}
            </IconStyled>
          )}
          <div>
            {kind === "minor" && majorLabel && (
              <Text bold fontSize="16px" value={majorLabel} />
            )}

            {isIncluded && 
              <Text
                bold
                wrap
                fontSize={kind === "minor" ? "14px" : "16px"}
                value={label}
              />
            }
            {isExcluded &&
              
              <Text
                bold
                wrap
                fontSize={kind === "minor" ? "14px" : "16px"}
                value={label}
                decoration="line-through"
                defaultColor={colors["text-02"].hex}
              />
            }
            {isDefault &&
              <Text
                bold
                wrap
                fontSize={kind === "minor" ? "14px" : "16px"}
                value={`${label} (Custom Default Value)`}
              />
            }
          </div>
        </RuleNameContainer>
      </ColStyled>
      <ColStyled xs={6}>
        {(isIncluded || isDefault )&& (
          <Text value={!R.isEmpty(sentence) ? `${sentence};` : ""} wrap />
        )}
      </ColStyled>
      <ColStyled xs={2} className="end-xs">
        <RowStyled end="xs">
          <div onClick={() => editRule({ [key]: groupId })}>
            <Icon
              className="pointer"
              icon={iconEdit}
              fill={colors["brand-01"].hex}
            />
          </div>
          <div onClick={() => deleteRule(key, groupId)}>
            <Icon
              className="pointer"
              icon={iconDelete}
              fill={colors["categorical-corporate-07"].hex}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </RowStyled>
      </ColStyled>
    </RuleContainer>
  );
};

export default GroupRule;
