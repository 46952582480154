import styled from "styled-components";

export const Wrapper = styled.div`
  margin: -16px;
  > div {
    padding: 5px 20px;
    cursor: pointer;
    &:hover {
      background-color: var(--selected-color);
    }
  }
`;
