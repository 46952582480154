import { View, Text } from "@react-pdf/renderer";

import generalStyles from "containers/PDF/styles";
import styles from "./styles";

const FoodNumbers = ({ numbers = [] }) => {
  const { fontSize12 } = generalStyles;
  const { title, littleBox } = styles;

  return (
    <>
      {numbers.map(({ label, value }, index) => (
        <View key={`${label}-${index}`} style={[littleBox, { paddingTop: 20, marginRight: index ? 0 : 14 }]}>
          <Text style={[title, { fontWeight: "semibold" }]}>{value}</Text>
          <Text style={[fontSize12, { marginTop: 3, fontWeight: "normal" }]}>{label}</Text>
        </View>
      ))}
    </>
  );
};

export default FoodNumbers;
