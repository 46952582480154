import React from "react";
import { colors, Module } from "@wfp/ui";
import styled, { css } from "styled-components";

const StyledModule = ({
  padding,
  headerPadding,
  innerPadding,
  contentPadding,
  contentBackground,
  footerPadding,
  footerBackground,
  withBorder,
  withHeaderBorder,
  withFooterBorder,
  ...props
}) => <Module {...props} />;

export const MyModule = styled(StyledModule)`
  padding: ${({ padding }) => padding || "20px 0"};

  .wfp--module__inner {
    box-shadow: none;
    background: white;
    border-radius: 0.4rem;
    padding: ${({ innerPadding }) => innerPadding || "0"};

    ${({ withBorder }) =>
      withBorder &&
      css`
        border: 1px solid ${colors["ui-04"].hex};
      `};
  }

  .wfp--module__header {
    border-bottom: ${({ withHeaderBorder }) => (withHeaderBorder ? `1px solid ${colors["ui-04"].hex}` : "none")};
    padding: ${({ headerPadding }) => headerPadding || "2.2rem 2.7rem 0"};
  }

  .wfp--module__content {
    padding: ${({ contentPadding }) => contentPadding || "2.2rem"};
    background: ${({ contentBackground }) => `${contentBackground} !important` || "auto"};
  }

  .wfp--module__footer {
    border-top: ${({ withFooterBorder }) =>
      withFooterBorder ? `1px solid ${colors["ui-04"].hex} !important` : "none"};
    padding: ${({ footerPadding }) => footerPadding || "0.8rem 2.7rem"};
    background: ${({ footerBackground }) => `${footerBackground} !important` || "auto"};
  }
`;
