import { useCallback, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { CheckboxHookForm, SliderHookForm } from "components/Form";
import messages from "../messages";

import { FIELDS } from "containers/OptimizedMenu/Rules/constants";

const SetSamePercentage = ({ type, disable }) => {
  const intl = useIntl();
  const { control, setValue, getValues } = useFormContext();

  const checkbox = useWatch({ control, name: `${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].CHECKBOX}` });

  const changeNutrients = useCallback(
    (value, field) => {
      const nutrients = getValues()[`${type}s`];

      setValue(
        `${type}s`,
        nutrients.map((el) => ({ ...el, percentage: value })),
      );
      field.onChange(value);
    },
    [getValues, setValue, type],
  );

  useEffect(() => {
    if (!checkbox || disable) {
      setValue(`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].PERCENTAGE}`, 0);
    }
  }, [checkbox, disable, setValue, type]);

  return (
    <Row className={disable ? "disable" : ""} between="xs">
      <Col xs={5}>
        <CheckboxHookForm
          key={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].CHECKBOX}`}
          control={control}
          name={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].CHECKBOX}`}
          id={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].CHECKBOX}`}
          labelText={intl.formatMessage(messages[`${type}Checkbox`])}
          noMarginBottom
        />
      </Col>
      <Col xs={6}>
        <SliderHookForm
          control={control}
          name={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].PERCENTAGE}`}
          id={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS[type].PERCENTAGE}`}
          disabled={disable}
          customOnChange={changeNutrients}
          className={!checkbox ? "disable" : ""}
        />
      </Col>
    </Row>
  );
};

export default SetSamePercentage;
