import React from "react";
import { FormattedMessage } from "react-intl";
import { NewAccordion } from "components";
import NumberOfGroupsAndItems from "./NumberOfGroupsAndItems";
import MaxWeight from "./MaxWeight";
import messages from "./messages";

export default function AdvancedSettings() {
  return (
    <>
      <NewAccordion
        customPadding="10px 16px 0px"
        fields={[
          {
            title: <FormattedMessage {...messages.advancedSetting} />,
            titleFontSize: "16px",
            titleCustomPadding: "20px 8px 5px",
            content: (
              <>
                <NumberOfGroupsAndItems />
                <MaxWeight />
              </>
            ),
          },
        ]}
      />
    </>
  );
}
