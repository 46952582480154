import React, { useCallback, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Table } from "components";
import { ColStyled } from "components/utils";
import Filters from "../Filters";
import { colors } from "@wfp/ui";
import { useResponsiveHook } from "hooks";
import { loadUsers, loadUserGroups, resetTableParams } from "containers/Admin/actions";
import {
  selectUsers,
  selectCount,
  selectTableParams,
  selectLoading as selectLoadingUsers,
} from "containers/Admin/selectors";
import { loadCountries } from "containers/SelectCountry/actions";
import { selectCountries, selectLoading as selectLoadingCountries } from "containers/SelectCountry/selectors";
import { reworkOrdering } from "utils/utils";
import { getColumns, columnsClickable } from "../utils";

const AllUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isTabletOrMobile } = useResponsiveHook();
  const users = useSelector(selectUsers);
  const loadingUsers = useSelector(selectLoadingUsers);
  const usersCount = useSelector(selectCount);
  const countries = useSelector(selectCountries);
  const loadingCountries = useSelector(selectLoadingCountries);
  const { page: pageNum, page_size: pageSize } = useSelector(selectTableParams);

  const columns = useMemo(() => getColumns(countries, isTabletOrMobile), [countries, isTabletOrMobile]);
  const isLoading = useMemo(() => loadingUsers || loadingCountries, [loadingUsers, loadingCountries]);

  useEffect(() => {
    dispatch(loadCountries());
    dispatch(loadUserGroups());

    return () => {
      dispatch(resetTableParams());
    };
  }, [dispatch]);

  const loadData = useCallback(
    (page, pageSize, ordering) => {
      let params = { page, page_size: pageSize };

      if (ordering && !R.isEmpty(ordering)) {
        params = { ...params, ordering: reworkOrdering(ordering) };
      }

      dispatch(loadUsers({ params }));
    },
    [dispatch],
  );

  const onClickRow = useCallback(
    (row, cell) => {
      if (!row || !row.original) return;
      if (columnsClickable.includes(cell.column.id)) {
        history.push(`/plusadmin/users/all/${row.original.id}`);
      }
    },
    [history],
  );

  const tableProps = {
    manual: true,
    pointer: true,
    overflowId: "role",
    columns,
    data: users,
    pageNum,
    pageSize,
    totalItems: usersCount,
    loadData,
    onClickRow,
    loading: isLoading,
    pageSizeOptions: [10, 30, 40],
    initialState: { pageSize: 10 },
    border: `1px solid ${colors["ui-04"].hex}`,
    margin: isTabletOrMobile ? 0 : "30px 0 0",
  };

  return (
    <ColStyled xs style={{ padding: "20px 8px" }}>
      <Filters />
      <Table {...tableProps} />
    </ColStyled>
  );
};

export default AllUsers;
