import styled from "styled-components";
import { colors } from "@wfp/ui";

export const ReactTableWrapper = styled.div`
  background: white;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width || "100%"};
  border: ${({ border }) => border || "none"};
`;

export const Header = styled.div`
  border-bottom: ${({ noBorderBottom }) => (noBorderBottom ? "none !important" : `1px solid ${colors["ui-04"].hex}`)};
  background: ${({ noHeaderBackground }) => (noHeaderBackground ? "none !important" : colors["ui-02"].hex)};
`;

export const TrBody = styled.div`
  min-height: ${({ minHeight }) => minHeight};
  border-bottom: ${({ noBorderBottom }) =>
    noBorderBottom ? "none !important" : "1px solid rgba(0, 0, 0, 0.1) !important"};
`;

export const NoResults = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
