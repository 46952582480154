import React, { memo } from "react";

import { useIntl } from "react-intl";

import { colors, Icon } from "@wfp/ui";
import { iconCheckmark, iconErrorOutline } from "@wfp/icons";

import messages from "containers/OptimizedMenu/Rules/messages";

import { Text } from "components";

import { DayNameContainer, DayContentInnerDiv } from "./styles";

const DayBox = ({ week, weekDay, dayItems }) => {
  const intl = useIntl();

  return (
    <div style={{ height: "100%" }}>
      <DayNameContainer>
        <Text fontSize="16px" bold value={intl.formatMessage(messages.weekAndDay, { week, day: weekDay })} />
      </DayNameContainer>

      {(dayItems || []).map((item, index) => (
        <DayContentInnerDiv key={`day-row-${week}-${weekDay}-${index}`} padding="5px 10px">
          <Text value={item === "" ? item : item.name} marginRight="5px" />
          {item !== "" && (
            <Icon
              icon={item.type === "include" ? iconCheckmark : iconErrorOutline}
              fill={item.type === "include" ? colors["categorical-corporate-03"].hex : colors["support-01"].hex}
              width="16px"
              height="16px"
            />
          )}
        </DayContentInnerDiv>
      ))}
    </div>
  );
};

export default memo(DayBox);
