import React from "react";
import styled, { css } from "styled-components";
import { Text } from "components";
import { colors } from "@wfp/ui";
import { RowStyled, ColStyled } from "components/utils";

export const Header = styled(Text)`
  background-color: ${colors["ui-01"].hex};
  padding-top: 2px;
  border-bottom: 1px solid ${colors["ui-04"].hex};
  height: 30px;
`;

const ColWithProps = ({ maxWidth, customFlex, noPermissionCursor, ...props }) => <ColStyled {...props} />;
const RowWithProps = ({ maxWidth, day, ...props }) => <RowStyled {...props} />;

export const ContainerDay = styled(ColWithProps)`
  border-top: 1px solid ${colors["ui-04"].hex};
  border-right: 1px solid ${colors["ui-04"].hex};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "none")};
  ${({ customFlex }) =>
    customFlex &&
    css`
      flex: ${customFlex};
    `}
  padding: 0 0 10px;
  cursor: ${({ noPermissionCursor }) => (noPermissionCursor ? "not-allowed" : "pointer")};
  position: relative;
  min-height: 230px;
  overflow: hidden;
  &:last-child {
    border-right: 0;
  }
`;

export const PlaceholderStyled = styled(RowWithProps)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  overflow: visible;
  width: 100%;
  min-height: 229px;
  height: ${({ height }) => height + 10}px;
  z-index: 2;
  border: 2px solid ${colors["brand-01"].hex};
`;
