import React from "react";
import styled from "styled-components";
import { Grid, Col } from "react-flexbox-grid";
import { colors } from "@wfp/ui";
import { mediaTablet } from "components/utils";

const MyCol = ({ paddingTop, withTag, ...props }) => <Col {...props} />;

export const ColWithBorder = styled(MyCol)`
  border-right: 1px solid ${colors["ui-04"].hex};
  padding: ${(props) => props.paddingTop || "0 20px"};
  min-height: 50px;
  margin: 20px 0;
  position: relative;
  min-width: 1px;
  align-self: stretch;
  :last-child {
    border-right: none;
  }
  ${mediaTablet} {
    padding: 0;
  }
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;
