import styled from "styled-components";
import { colors } from "@wfp/ui";

export const Grid = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-bottom: 1px dashed ${colors["ui-04"].hex};
`;

export const LabelWrapper = styled.div`
  position: absolute;
  margin-right: 12px;
  color: ${colors["ui-05"].hex};
  ${({ bottom }) => (bottom ? "bottom" : "top")}: 0;
  right: 100%;
  height: 0;
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: ${({ isTabletOrMobile, inModal }) => (isTabletOrMobile || inModal ? "calc(100% / 5)" : "10%")};
  position: relative;
  border-top: ${({ selectedTarget }) =>
    selectedTarget ? `2px solid ${selectedTarget}` : `1px dashed ${colors["ui-04"].hex}`};
`;
