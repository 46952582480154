import React, { createContext, useCallback, useState, useMemo, useContext } from "react";
import { injectIntl } from "react-intl";
import { Text } from "components";
import { useDispatch } from "react-redux";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import TutorialLink from "containers/Tutorials/TutorialLink";
import { TUTORIAL_IDS } from "containers/Tutorials/utils";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import { FormWizardHorizontal } from "./styles";
import messages from "./messages";

export const FormWizardContext = createContext({});
export const useFormWizardContext = () => useContext(FormWizardContext);
function MenuWizard({
  updateFormState,
  hasValidationErrors,
  children,
  labels,
  initialValues,
  onSubmit,
  actionsOnNext,
  disableSubmit,
  passPage,
  showLoadingText,
  intl,
}) {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const formattedLabels = useMemo(() => labels.map((label) => label.toLowerCase().split(" ")[0]), [labels]);

  const updateForm = useCallback(
    (payload) => {
      // https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
      setTimeout(() => dispatch(updateFormState({ formState: payload })), 0);
    },
    [dispatch, updateFormState],
  );

  const isLastPage = useMemo(() => page === React.Children.count(children) - 1, [children, page]);

  const activePage = useMemo(() => React.Children.toArray(children)[page], [children, page]);

  const validate = (values) => (activePage.props.validate ? activePage.props.validate(values) : {});

  const next = useCallback(() => {
    setPage(Math.min(page + 1, children.length - 1));
    passPage(Math.min(page + 1, children.length - 1));
    window.scrollTo(0, 0);
  }, [children.length, page, passPage]);

  const customSubmit = (values) => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      /*Use actionsOnNext to trigger something clicking on next button*/
      if (actionsOnNext && actionsOnNext[labels[page]]) {
        return actionsOnNext[labels[page]](next);
      }
      return next();
    }
  };

  const contextValue = useMemo(() => ({ setPage }), []);
  return (
    <FormWizardContext.Provider value={contextValue}>
      <Form initialValues={initialValues} validate={validate} onSubmit={customSubmit} mutators={arrayMutators}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy onChange={updateForm} subscription={{ values: true, hasValidationErrors: true }} />
            <FormWizardHorizontal
              formHeader={
                <Text
                  bold
                  fontSize="16px"
                  value={intl.formatMessage(messages.pagination, {
                    current: page + 1,
                    total: labels.length,
                    name: intl.formatMessage(messages[formattedLabels[page]]),
                    link: <TutorialLink kind={TUTORIAL_IDS[formattedLabels[page].toUpperCase()]} />,
                  })}
                />
              }
              formControls={
                <Footer
                  hasValidationErrors={hasValidationErrors || disableSubmit}
                  isLastPage={isLastPage}
                  page={page}
                  labels={labels}
                  handleSubmit={handleSubmit}
                  setPage={setPage}
                  showLoadingText={showLoadingText}
                />
              }
              sidebar={<NavigationBar page={page} labels={formattedLabels} />}
            >
              {activePage}
            </FormWizardHorizontal>
          </form>
        )}
      </Form>
    </FormWizardContext.Provider>
  );
}

MenuWizard.Page = ({ children }) => children;

export default injectIntl(MenuWizard);
