import React from "react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled } from "components/utils";
import { FIELDS } from "./constants";
import { validation } from "./validation";

type ApproveAllProps = {
  approveAllFoodPrices: () => void,
  totalCount: number,
};

const ApproveAll = ({ approveAllFoodPrices, totalCount}: ApproveAllProps) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const foodPrices = useWatch({ control, name: FIELDS.FOOD_PRICES });

  const disableApproveAllButton = useMemo(
    () => foodPrices.map((foodPrice: Record<string, any>) => validation(foodPrice)).some(Boolean),
    [foodPrices]
  );

  return (
    <RowStyled end="xs" flex={1} padding="0.8rem 0">
      <Button
        widthAuto
        disabled={disableApproveAllButton}
        onClick={approveAllFoodPrices}
        children={<>
        {intl.formatMessage(messages.approveAll)} ({totalCount})
        </>}
      />
    </RowStyled>
  );
};

export default ApproveAll;
