import IncludedCount from "./IncludedCount";
import Scheduler from "./Scheduler";

const Repetition = ({ disable, daysInWeek, weekCount }) => {
  return (
    <>
      <IncludedCount disable={disable} daysInWeek={daysInWeek} weekCount={weekCount} />
      <Scheduler disable={disable} daysInWeek={daysInWeek} weekCount={weekCount} />
    </>
  );
};

export default Repetition;
