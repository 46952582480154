import { colors } from "@wfp/ui";
import styled from "styled-components";

export const SourcingChannelsContainer = styled.div`
  grid-area: channels;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
`;

export const GraphForPDF = styled.div`
  background: white;
  width: 360px;
  height: 400px;
  z-index: -1000;
`;
