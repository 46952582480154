import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { ColStyled } from "components/utils";
import PriceFields from "containers/FoodBasket/Shared/PriceFields";
import messages from "containers/FoodBasket/messages";
import { AddToFoodBasketContainer } from "./styles";

const AddToFoodBasket = () => {
  const intl = useIntl();

  return (
    <ColStyled xs={8}>
      <AddToFoodBasketContainer>
        <Text
          bold
          fontSize="16px"
          marginLeft="0.5rem"
          marginBottom="1.5rem"
          defaultColor={colors["text-02"].hex}
          value={intl.formatMessage(messages.addToPersonalFoodBasket)}
        />

        <PriceFields showProposedCheckbox />
      </AddToFoodBasketContainer>
    </ColStyled>
  );
};

export default AddToFoodBasket;
