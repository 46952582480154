import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { RowStyled } from "components/utils";
import messages from "../messages";

const ReceivedFrom = ({ sharedBy }) => {
  const intl = useIntl();

  return (
    <RowStyled>
      <Text
        bold
        fontSize="13px"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.receivedFrom)}
      />
      <Text fontSize="13px" marginLeft="5px" value={sharedBy} />
    </RowStyled>
  );
};

export default ReceivedFrom;
