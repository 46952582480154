import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { colors, Loading } from "@wfp/ui";
import { Line, Button, Table } from "components";
import { ColStyled, RowStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import Filters from "./Filters";
import {
  loadVamMappings,
  updateFormState,
  updateVamMappings,
  initAdminVamFoodPricesManage,
} from "containers/Admin/actions";
import {
  selectLoadingCountriesSlice,
  selectVamMappings,
  selectTableParams,
  selectVamMappingsCount,
  selectLoadingFoodItems,
  selectAdminVamFoodPricesLoading,
} from "containers/Admin/selectors";
import { useResponsiveHook } from "hooks";
import { useInitialValues } from "./useInitialValues";
import { reworkOrdering } from "utils/utils";
import { getColumns } from "../utils";
import { validation } from "./utils";

const VamFoodPrices = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();
  const vamMappings = useSelector(selectVamMappings);
  const loading = useSelector(selectLoadingCountriesSlice);
  const vamMappingsCount = useSelector(selectVamMappingsCount);
  const { page: pageNum, page_size: pageSize } = useSelector(selectTableParams);
  const loadingFoodItems = useSelector(selectLoadingFoodItems);
  const isAdminVamFoodPricesLoading = useSelector(
    selectAdminVamFoodPricesLoading
  );
  const initialValues = useInitialValues();

  const [initialize, setInitialize] = useState();

  const columns = useMemo(
    () => getColumns(intl, isTabletOrMobile),
    [intl, isTabletOrMobile]
  );

  useEffect(() => {
    dispatch(initAdminVamFoodPricesManage());
  }, [dispatch]);


  useEffect(() => {
    if (!isAdminVamFoodPricesLoading) {
      setInitialize(initialValues);
    }
  }, [initialValues, isAdminVamFoodPricesLoading]);

  const updateForm = useCallback(
    (state) => {
      dispatch(updateFormState({ formState: state }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    dispatch(updateVamMappings());
  }, [dispatch]);

  const loadData = useCallback(
    (page, pageSize, ordering) => {
      let params = { page, page_size: pageSize };

      if (ordering && !R.isEmpty(ordering)) {
        params = { ...params, ordering: reworkOrdering(ordering) };
      }

      dispatch(loadVamMappings({ params }));
    },
    [dispatch]
  );

  const loadingData = useMemo(
    () => loading || loadingFoodItems || isAdminVamFoodPricesLoading,
    [isAdminVamFoodPricesLoading, loading, loadingFoodItems]
  );

  const tableProps = {
    manual: true,
    pointer: false,
    overflowId: "item",
    columns,
    data: vamMappings,
    pageNum,
    pageSize,
    totalItems: vamMappingsCount,
    loadData,
    loading: loadingData,
    pageSizeOptions: [10, 30, 40],
    initialState: { pageSize: 10 },
    border: `1px solid ${colors["ui-04"].hex}`,
    margin: isTabletOrMobile ? 0 : "30px 0 0",
  };

  return (
    <ColStyled xs padding="20px 8px">
      {!R.isEmpty(initialize) && (
        <Form
          initialValues={initialize}
          mutators={arrayMutators}
          onSubmit={onSubmit}
          render={({ values }) => {
            const disableSubmit = validation(initialValues, values);

            return (
              <>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={updateForm}
                />
                <Loading active={loadingData} />

                <Filters />
                <Table {...tableProps} />

                <Line margin="15px -30px" />
                <RowStyled end="xs" margin="0">
                  <Button
                    type="submit"
                    onClick={onSubmit}
                    disabled={disableSubmit}
                    className="col-xs-2"
                  >
                    <FormattedMessage {...buttonMessages.submit} />
                  </Button>
                </RowStyled>
              </>
            );
          }}
        />
      )}
    </ColStyled>
  );
};

export default VamFoodPrices;
