import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconCopy } from "@wfp/icons";
import { Text } from "components";
import messages from "components/Button/messages";
import { useHtmlHook } from "hooks";
import { ACTIONS } from "../constants";
import { ContainerIcon } from "./styles";

function Clone({ setMenuAndAction, menu }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const clickOnClone = useCallback(() => {
    setMenuAndAction({
      id: menu.id,
      action: ACTIONS.CLONE,
      name: menu.name,
      regions: menu.preliminary_information.regions,
      type: menu.type,
    });
  }, [menu.id, menu.name, menu.type, menu.preliminary_information.regions, setMenuAndAction]);

  return (
    <ContainerIcon onClick={clickOnClone} hover={colors["brand-01"].hex} isRTL={isRTL}>
      <Icon
        hover={colors["brand-01"].hex}
        icon={iconCopy}
        kind="inverse"
        width="18px"
        height="18px"
        fill={colors["text-01"].hex}
      />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.duplicate)} />
    </ContainerIcon>
  );
}

export default Clone;
