import styled from "styled-components";
import { RowStyled } from "components/utils";

export const ContainerWeek = styled(RowStyled)`
  position: relative;
  margin: 0 -15px;
  &:last-child {
    margin: 0 -15px -10px;
  }
`;
