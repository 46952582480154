import { useEffect, useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { selectRegions, selectWeightChoices } from "containers/App/selectors";
import { FIELDS, INITIAL_VALUES } from "./constants";
import { selectNutrients, selectFoodItems } from "./selectors";

// Hook to get both sync and async initial values
export const useInitialValues = () => {
  const nutrients = useSelector(selectNutrients);
  const foodItems = useSelector(selectFoodItems);
  const regions = useSelector(selectRegions);
  const units = useSelector(selectWeightChoices);

  return useMemo(() => {
    if (R.isEmpty(nutrients) || R.isEmpty(foodItems) || R.isEmpty(regions) || R.isEmpty(units)) {
      return INITIAL_VALUES;
    }

    const defaultRegion = regions.find((region) => region.region === "country");

    return {
      ...INITIAL_VALUES,
      [FIELDS.FILTERS]: {
        ...INITIAL_VALUES[FIELDS.FILTERS],
        [FIELDS.NUTRIENTS]: nutrients,
        [FIELDS.REGIONS]: [],
      },
      [FIELDS.PERSONAL_ITEM]: {
        ...INITIAL_VALUES[FIELDS.PERSONAL_ITEM],
        [FIELDS.REGION]: defaultRegion?.value,
      },
    };
  }, [foodItems, nutrients, regions, units]);
};

// Define form default values and reset its slices when specific data changes
export const useAsyncForm = () => {
  const nutrients = useSelector(selectNutrients);
  const foodItems = useSelector(selectFoodItems);
  const regions = useSelector(selectRegions);

  const formValues = useInitialValues();
  const useFormApi = useForm({ defaultValues: formValues });

  // "nutrients" change
  useEffect(() => {
    const formValues = useFormApi.getValues();

    useFormApi.reset({
      ...formValues,
      [FIELDS.FILTERS]: { ...formValues[FIELDS.FILTERS], [FIELDS.NUTRIENTS]: nutrients },
    });
  }, [nutrients, useFormApi]);

  // "regions" change
  useEffect(() => {
    const formValues = useFormApi.getValues();
    const defaultRegion = regions.find((region) => region.region === "country");

    useFormApi.reset({
      [FIELDS.FILTERS]: {
        ...formValues[FIELDS.FILTERS],
        [FIELDS.REGIONS]: [],
      },
      [FIELDS.PERSONAL_ITEM]: { ...formValues[FIELDS.PERSONAL_ITEM], [FIELDS.REGION]: defaultRegion?.value },
    });
  }, [regions, useFormApi]);

  // "foodItems" change
  useEffect(() => {
    useFormApi.reset({ ...useFormApi.getValues() });
  }, [foodItems, useFormApi]);

  return useMemo(() => ({ useFormApi }), [useFormApi]);
};
