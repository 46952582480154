import React, { useMemo, useState, memo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Text } from "components";
import { Icon, ModuleBody, Tabs, Tab } from "@wfp/ui";
import { iconHelp } from "@wfp/icons";
import { selectTutorials } from "containers/Tutorials/selectors";
import { Container, Content, HelpButton, HelpModule, HelpModuleHeader } from "./styles";
import { intlTabs } from "./utils";
import messages from "./messages";
import VideosPage from "./VideosPage";
import FaqsPage from "./FaqsPage";

const GeneralTutorial = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const tutorials = useSelector(selectTutorials);

  const tabs = useMemo(() => intlTabs(intl), [intl]);

  return (
    <>
      {!open ? (
        <HelpButton onClick={() => setOpen((prev) => !prev)}>
          <Icon icon={iconHelp} height="20px" width="20px" />
        </HelpButton>
      ) : (
        <Container
          data-test-id="help-section"
          style={{ display: "flex", justifyContent: "flex-end" }}
          onClick={() => setOpen(false)}
        >
          <HelpButton onClick={() => setOpen((prev) => !prev)} position="relative">
            <Icon icon={iconHelp} height="20px" width="20px" />
          </HelpButton>
          <Content withBorder padding="0" width="90vw" onClick={(e) => e.stopPropagation()}>
            <HelpModule fullHeight>
              <HelpModuleHeader>
                <Text fontSize="24px" bold value={intl.formatMessage(messages.helpLabel)} />
                <Tabs selected={tabIndex} onSelectionChange={(index) => setTabIndex(index)}>
                  {tabs.map((tab, index) => (
                    <Tab key={index} label={tab} />
                  ))}
                </Tabs>
              </HelpModuleHeader>
              <ModuleBody>
                {tabIndex === 0 && <VideosPage tutorials={tutorials} />}
                {tabIndex === 1 && <FaqsPage tutorials={tutorials} />}
              </ModuleBody>
            </HelpModule>
          </Content>
        </Container>
      )}
    </>
  );
};

export default memo(GeneralTutorial);
