import { useMemo } from "react";
import { Tag } from "@wfp/ui";

import { getIcon } from "components/Icons";
import { ColStyled } from "components/utils";
import { TitleContainer, TitleAndCount, Group } from "./styles";

const Title = ({ value, itemsCount }) => {
  const titleAndCount = useMemo(() => {
    return (
      <>
        <Group>{value}</Group>
        <Tag type="info">{itemsCount}</Tag>
      </>
    );
  }, [itemsCount, value]);

  return (
    <TitleContainer between="xs" middle="xs">
      <ColStyled xs={10} padding="0">
        <TitleAndCount fontSize="16px" value={titleAndCount} />
      </ColStyled>
      <ColStyled padding="0 0 0 8px">
        <div>{getIcon(value) && getIcon(value)()}</div>
      </ColStyled>
    </TitleContainer>
  );
};

export default Title;
