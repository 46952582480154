import React, { useState, useMemo } from "react";
import { injectIntl } from "react-intl";
import { Text, Button } from "components";
import { ColStyled } from "components/utils";
import ModalFoodSources from "./ModalFoodSources";
import messages from "./messages";

function FoodSources({ intl }) {
  const [statusModal, setStatusModal] = useState();

  const ModalFoodSourcesRenderer = useMemo(
    () => (
      <ModalFoodSources
        open={statusModal}
        setStatusModal={setStatusModal}
        label={intl.formatMessage(messages.foodSourcesButton)}
      />
    ),
    [intl, statusModal],
  );

  return (
    <ColStyled margin="0 0 40px">
      <Text bold fontSize="20px" value={intl.formatMessage(messages.foodSourcesTitle)} />
      <Button
        widthAuto
        kind="secondary"
        marginTop="5px"
        children={intl.formatMessage(messages.foodSourcesButton)}
        onClick={() => setStatusModal(true)}
      />
      {statusModal && ModalFoodSourcesRenderer}
    </ColStyled>
  );
}

export default injectIntl(FoodSources);
