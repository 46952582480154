import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { NewAccordion } from "components";
import { selectPriceSources } from "containers/App/selectors";
import messages from "./messages";
import PriceDataOrigins from "./PriceDataOrigins";
import Validity from "./Validity";

const PriceDataOriginsSection = () => {
  const intl = useIntl();
  const priceSources = useSelector(selectPriceSources);

  return (
    <NewAccordion
      withBorderBottom={false}
      fields={[
        {
          title: intl.formatMessage(messages.priceTitle),
          content: (
            <>
              <PriceDataOrigins priceSources={priceSources} />
              <Validity />
            </>
          ),
        },
      ]}
    />
  );
};

export default PriceDataOriginsSection;
