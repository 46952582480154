import { useMemo } from "react";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { RowStyled, ColStyled, newColorByCategory } from "components/utils";
import { Grid } from "containers/FoodBasket/styles";
import { useResponsiveHook } from "hooks";
import { legend } from "./constants";
import { Box } from "./styles";

const Legend = () => {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();

  const itemsPerRow = useMemo(() => (isTabletOrMobile ? 2 : 8), [isTabletOrMobile]);

  return (
    <RowStyled margin="1.5rem 0 0 0">
      <ColStyled xs padding="0">
        <Grid itemsPerRow={itemsPerRow}>
          {legend(intl).map(({ nutrient, value }) => {
            const backgroundColor = newColorByCategory[nutrient]["backgroundColor"];

            return (
              <Box key={nutrient} backgroundColor={backgroundColor}>
                <Text regularWrap defaultColor={colors["ui-01"].hex} value={value.toUpperCase()} />
              </Box>
            );
          })}
        </Grid>
      </ColStyled>
    </RowStyled>
  );
};

export default Legend;
