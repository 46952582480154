import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useForm, useField, Field } from "react-final-form";
import { Icon, colors } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { Autocomplete } from "components";
import { RowStyled, ColStyled, ReactSelectWithDataTestId } from "components/utils";
import { MultiSelect } from "components/InputFields";
import { FIELDS } from "../constants";
import { loadFoodItems } from "containers/App/actions";
import { selectFoodItems, selectRegions } from "containers/App/selectors";
import countriesMessages from "../messages";
import buttonMessages from "components/Button/messages";

const PriorityItem = ({ name, index, values }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const form = useForm();
  const foodItems = useSelector(selectFoodItems);
  const regions = useSelector(selectRegions);
  const { input: foodItemInput } = useField(`${name}`);
  const { input: regionsInput } = useField(`${name}.${FIELDS.REGIONS}`);

  const loadPriorityItems = useCallback(
    (value) => {
      dispatch(loadFoodItems({ params: { display_name: value } }));
    },
    [dispatch],
  );

  const customOnChange = useCallback(
    (element) => {
      foodItemInput.onChange({ ...element, regions: regionsInput.value });
    },
    [foodItemInput, regionsInput.value],
  );

  const onRemove = useCallback(() => {
    form.change(
      FIELDS.PRIORITY_ITEMS,
      values.filter((_, i) => i !== index),
    );
  }, [form, index, values]);

  return (
    <RowStyled margin="0 0 20px">
      <ColStyled xs md={5}>
        <Field
          id={`${name}.${FIELDS.VALUE}`}
          name={`${name}.${FIELDS.VALUE}`}
          options={foodItems}
          labelText={intl.formatMessage(countriesMessages.foodItem)}
          placeholder={intl.formatMessage(countriesMessages.typeToSearch)}
          fontSizeLabel="16px"
          components={ReactSelectWithDataTestId}
          loadData={loadPriorityItems}
          component={Autocomplete}
          customOnChange={customOnChange}
        />
      </ColStyled>
      <ColStyled xs md={5}>
        <Field
          id={`${name}.${FIELDS.REGIONS}`}
          name={`${name}.${FIELDS.REGIONS}`}
          placeholder={intl.formatMessage(countriesMessages.regionsPlaceholder)}
          options={regions}
          labelText={intl.formatMessage(countriesMessages.regions)}
          fontSizeLabel="16px"
          components={ReactSelectWithDataTestId}
          component={MultiSelect}
          arrayOfObjects
        />
      </ColStyled>
      <ColStyled flex={0.1} className="end-xs">
        <Icon
          className="pointer paddingTopSmall"
          description={intl.formatMessage(buttonMessages.remove)}
          icon={iconDelete}
          width="23"
          height="23"
          fill={colors["brand-01"].hex}
          onClick={onRemove}
        />
      </ColStyled>
    </RowStyled>
  );
};

export default PriorityItem;
