import React from "react";
import { newColorByCategory } from "components/utils";

export default function ExcessValue({ bar }) {
  return (
    <>
      {bar.percentage > 150 && (
        <svg
          fill={newColorByCategory[bar.category]?.["backgroundColor"]}
          viewBox="5 0 20 9"
          style={{ marginBottom: "1px" }}
        >
          <path d="M15 0 L5 9 L25 9 Z" />
        </svg>
      )}
    </>
  );
}
