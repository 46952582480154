import { FormattedMessage } from "react-intl";

import { Button, Text } from "components";
import buttonMessages from "components/Button/messages";
import { ModalStyled } from "components/UIKitStyled";
import utilsMessages from "utils/messages";
import messages from "../messages";
import routesPath from "containers/App/Routes/paths";

const ErrorBoundary =
  ({ history }) =>
  ({ resetError }) => {
    const reload = () => {
      history.push(routesPath.menus);
      resetError();
    };

    return (
      <ModalStyled
        hideClose
        open
        modalHeading={<FormattedMessage {...utilsMessages.generic} />}
        modalFooter={() => (
          <Button widthAuto children={<FormattedMessage {...buttonMessages.reloadApp} />} onClick={reload} />
        )}
        maxWidth="65%"
      >
        <Text value={<FormattedMessage {...messages.uncaughtError} />} />
      </ModalStyled>
    );
  };

export default ErrorBoundary;
