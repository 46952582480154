import React, { memo } from "react";
import { RowStyled, ColStyled } from "components/utils";
import { Field } from "react-final-form";
import { useResponsiveHook } from "hooks";
import { Slider } from "components/InputFields";
import { capitalize } from "utils/utils";
import { FIELDS } from "../../utils";

const Nutrient = memo(({ nutrient }) => {
  const { isTabletOrMobile } = useResponsiveHook();

  return (
    <div className="wfp--module__inner nutritionModule formItem">
      <RowStyled between="xs">{capitalize(nutrient.nutrient)}</RowStyled>
      <RowStyled bottom="xs">
        <ColStyled xs={10} lg={9} margin="20px 0 0">
          <Field
            name={`${FIELDS.NUTRITIONAL_REQUIREMENTS}.${nutrient.nutrient}.percentage`}
            minLabel="0%"
            maxLabel="100%"
            minValue={0}
            maxValue={100}
            customXs={isTabletOrMobile ? 9 : 10}
            customLg={7}
            component={Slider}
          />
        </ColStyled>
      </RowStyled>
    </div>
  );
});

export default Nutrient;
