import { defineMessage } from "react-intl";

export default {
  general: defineMessage({
    id: "step.generalInfo",
    defaultMessage: "General Info",
  }),
  structure: defineMessage({
    id: "step.structure",
    defaultMessage: "Structure",
  }),
  nutrition: defineMessage({
    id: "step.nutrition",
    defaultMessage: "Nutrition",
  }),
  sourcing: defineMessage({
    id: "step.sourcing",
    defaultMessage: "Sourcing",
  }),
  summary: defineMessage({
    id: "step.summary",
    defaultMessage: "Summary",
  }),
  pagination: defineMessage({
    id: "step.pagination",
    defaultMessage: "Step {current} of {total} - {name} {link}",
  }),
};
