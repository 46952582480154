import React from "react";
import { useIntl } from "react-intl";

import {
  LogosContainer,
  WFPLogo,
  PartnerLogo,
  AfricanUnionLogo,
  SodexoLogo,
  InnovationAcceleratorLogo,
  NoradLogo,
  FaoLogo,
} from "containers/App/Footer/styles";
import messages from "containers/App/Footer/messages";
import { ColStyled } from "components/utils";
import { Text } from "components";
import { CustomStyledFooter } from "./styles";

const Footer = () => {
  const intl = useIntl();

  return (
    <CustomStyledFooter
      external
      logoExtended={
        <LogosContainer>
          <WFPLogo
            logo={"https://cdn.wfp.org/guides/ui/assets/v0.0.1/logos/standard/en/wfp-logo-standard-white-en.png"}
          />
          <ColStyled>
            <Text
              superBold
              defaultColor={"white"}
              fontSize="14px"
              marginBottom="10px"
              value={intl.formatMessage(messages.inPartnership)}
            />
            <PartnerLogo />
          </ColStyled>
          <AfricanUnionLogo />
          <SodexoLogo />
          <InnovationAcceleratorLogo />
          <NoradLogo />
          <FaoLogo />
        </LogosContainer>
      }
    />
  );
};

export default Footer;
