import { useCallback } from "react";
import { useController } from "react-hook-form";
import { ValidatedRadioButton } from "./styles";

import { iconWarningGlyph } from "@wfp/icons";
import { colors, Icon } from "@wfp/ui";

/**
 * RadioButton wrapper that handles react-hook-form's handleSubmit() validation
 *
 * TODO: In the future, replace all the existing RadioButtonHookForm
 * components that need a submit validation, with this component
 */
const ValidatedRadioButtonHookForm = ({
  control,
  id,
  name,
  value,
  customOnChange,
  labelText,
  checked,
  rules = {},
  showErrorMessage = true,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  const handleChange = useCallback(
    (el) => {
      if (customOnChange) {
        customOnChange(el, field);
      } else {
        field.onChange(el.target.value);
      }
    },
    [customOnChange, field]
  );

  return (
    // Invalid icon and label implemented from the wfp/ui's Input component,
    // together with the required wrapper classes for the appropriate styling
    <div
      className={`error-field ${error ? "wfp--form-item--invalid" : ""}`}
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <ValidatedRadioButton
        {...rest}
        className={`${error ? "wfp--radio-button-invalid" : ""}`}
        name={name}
        value={value}
        id={id}
        checked={checked ? checked : field.value === value}
        onChange={handleChange}
        labelText={labelText}
      />
      {showErrorMessage && error && (
        <div className={`wfp--form-requirement`} id={`${name}-error-msg`}>
          {<Icon icon={iconWarningGlyph} fill={colors["support-01"].hex} />} <span>{error && error.message}</span>
        </div>
      )}
    </div>
  );
};

export default ValidatedRadioButtonHookForm;
