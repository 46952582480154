import * as R from "ramda";

import { formatDate } from "utils/utils";
import Options from "containers/OptionsAndModals/Options";
import menuMessages from "containers/Menu/messages";
import myMenusMessages from "containers/MyMenus/messages";
import NameAndSettings from "./NameAndSettings";
import StarAndMenuIcon from "./StarAndMenuIcon";
import Compare from "./Compare";
import Cell from "./Cell";

const calculatePixelCol = (isTabletOrMobile, size) => {
  const PADDING = isTabletOrMobile ? 0 : 62;
  return ((window.innerWidth - PADDING) / 8) * size;
};

export const columnsClickable = [
  "name",
  "target",
  "groups",
  "items",
  "average_cost_per_meal",
  "type",
  "updated_timestamp",
];

export default (setMenuAndAction, comparisonList, callbackOnDelete, isTabletOrMobile, intl) => [
  {
    accessor: "icons",
    width: calculatePixelCol(isTabletOrMobile, 0.75),
    Cell: ({ cell: { row } }) => <StarAndMenuIcon data={row.original} comparisonMode={!R.isEmpty(comparisonList)} />,
  },
  {
    accessor: "name",
    width: calculatePixelCol(isTabletOrMobile, 2.8),
    Cell: ({ cell: { row } }) => <NameAndSettings data={row.original} />,
  },
  {
    accessor: "groups",
    width: calculatePixelCol(isTabletOrMobile, 0.6),
    Cell: ({ cell: { row } }) => (
      <Cell
        data={row.original}
        value={row.original.nb_selected_food_groups}
        label={intl.formatMessage(menuMessages.foodGroups)}
      />
    ),
  },
  {
    accessor: "items",
    width: calculatePixelCol(isTabletOrMobile, 0.6),
    Cell: ({ cell: { row } }) => (
      <Cell
        data={row.original}
        value={row.original.nb_selected_food_items}
        label={intl.formatMessage(menuMessages.foodItems)}
      />
    ),
  },
  {
    accessor: "average_cost_per_meal",
    width: calculatePixelCol(isTabletOrMobile, 0.75),
    Cell: ({ cell: { row, value } }) => (
      <Cell data={row.original} value={`$ ${value || 0}`} label={intl.formatMessage(myMenusMessages.costColumn)} />
    ),
  },
  {
    accessor: "updated_timestamp",
    width: calculatePixelCol(isTabletOrMobile, 0.75),
    Cell: ({ cell: { row, value } }) => (
      <Cell
        data={row.original}
        value={formatDate(value, intl)}
        label={intl.formatMessage(myMenusMessages.updatedColumn)}
      />
    ),
  },
  {
    accessor: "actions1",
    width: calculatePixelCol(isTabletOrMobile, 0.8),
    Cell: ({ cell: { row } }) => <Compare data={row} comparisonList={comparisonList} />,
  },
  {
    accessor: "actions2",
    width: calculatePixelCol(isTabletOrMobile, 0.4),
    style: {
      position: "relative",
      justifyContent: "center",
    },
    Cell: ({ cell: { row } }) => (
      <Options
        {...row}
        placement={row.pageSize === row.index + 1 && "top"}
        setMenuAndAction={setMenuAndAction}
        callbackOnDelete={callbackOnDelete}
        comparisonMode={!R.isEmpty(comparisonList)}
      />
    ),
  },
];
