import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const pageRoot = document.getElementById("root");

const withPortal = (WrappedComponent) => (props) => {
  const documentEl = useRef(document.createElement("div"));

  useEffect(() => {
    const element = documentEl.current;
    if (element && pageRoot) {
      pageRoot.appendChild(element);
    }

    return () => element.remove();
  }, []);

  return createPortal(<WrappedComponent {...props} />, documentEl.current);
};

export default withPortal;
