import { useState, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { colors, Icon, Tag } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { SecondHeader } from "components";
import { RowStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import messages from "containers/Menu/messages";
import { selectMenuTranslatedOptimizationType } from "containers/Menu/selectors";
import RenameMenu from "./RenameMenu";

const Header = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const menuName = useWatch({ control, name: FIELDS.MENU_NAME });

  const optimizationType = useSelector(selectMenuTranslatedOptimizationType);

  const [editMode, setEditMode] = useState(false);

  const onClickEdit = useCallback(() => setEditMode(true), [setEditMode]);

  const tag = useMemo(() => optimizationType || intl.formatMessage(messages.draft), [intl, optimizationType]);

  return (
    <SecondHeader>
      {editMode ? (
        <RenameMenu setEditMode={setEditMode} />
      ) : (
        <RowStyled middle="xs">
          <h2>{menuName}</h2>
          <Tag type="warning" style={{ marginLeft: "0.5rem" }}>
            <p>{tag}</p>
          </Tag>
          <Icon
            className="marginLeftSmall pointer"
            onClick={onClickEdit}
            icon={iconEdit}
            description="edit"
            fill={colors["brand-01"].hex}
            width="20"
            height="20"
          />
        </RowStyled>
      )}
    </SecondHeader>
  );
};

export default Header;
