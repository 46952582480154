import messages from "../../messages";
import { Text } from "components";

export const getColumns = (intl) => {
  return [
    {
      Header: "#",
      accessor: "id",
      flex: "0 1 auto",
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: intl.formatMessage(messages.recipeName),
      accessor: "name",
      flex: "2 1 auto",
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: intl.formatMessage(messages.authorName),
      accessor: "author_full_name",
      flex: "2 1 auto",
      Cell: ({ row }) => (
        <Text fontSize="16px" value={row.original.author.first_name + " " + row.original.author.last_name} />
      ),
    },
  ];
};
