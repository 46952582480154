import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { MultiSelectHookForm } from "components/Form";
import { selectRegions } from "containers/App/selectors";
import { FIELDS } from "containers/MyMenus/constants";
import { GridArea } from "./styles";

const Region = () => {
  const { control } = useFormContext();
  const regions = useSelector(selectRegions);

  const onRegionChange = useCallback(({ value, meta, field }) => {
    if (meta.action === "clear") {
      return field.onChange([]);
    }

    // If user selected "Whole Country", set it as selected option.
    // Note: when removing a selected option we won't have meta.option, that's why we need to use optional chaining operator.
    const userSelectedWholeCountry = meta?.option?.region === "country";
    if (userSelectedWholeCountry) {
      field.onChange([meta.option]);
      return;
    }

    // If user didn't select "Whole Country":
    // - if "Whole Country" is the selected option, remove it and set the incoming one;
    // - if "Whole Country" is NOT the selected option, put incoming one along with selected ones.
    const wholeCountrySelected = Boolean(value.find((option) => option.region === "country"));

    if (wholeCountrySelected) {
      field.onChange([meta.option]);
    } else {
      field.onChange(value);
    }
  }, []);

  return (
    <GridArea area="1 / 3 / 2 / 4">
      <MultiSelectHookForm control={control} name={FIELDS.REGION} options={regions} customOnChange={onRegionChange} />
    </GridArea>
  );
};

export default Region;
