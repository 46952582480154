import * as R from "ramda";
import { MemoryRouter } from "react-router";
import { Provider } from "react-redux";
import { Form } from "react-final-form";
import appStore from "AppStore";
import messages from "../locales";
import { IntlProvider } from "react-intl";
import { FormProvider, useForm } from "react-hook-form";
import ReactDom from "react-dom";
import MenuWizard from "components/MenuWizard";
import arrayMutators from "final-form-arrays";
import { Context as ResponsiveContext } from "react-responsive";
import { PERMISSIONS } from "containers/Admin/constants";
import { WIDTH } from "./constants";

export const allUserPermissions = Object.values(PERMISSIONS);

// TODO: To be removed in the future once we get rid of react-final-form and friends.
export const initializeFormTest = (component, initialValues) => (
  <MemoryRouter>
    <Provider store={appStore}>
      <Form
        mutators={arrayMutators}
        onSubmit={() => {}}
        initialValues={initialValues || {}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <MenuWizard.Page>
              <MemoryRouter>
                <Provider store={appStore}>{component}</Provider>
              </MemoryRouter>
            </MenuWizard.Page>
          </form>
        )}
      />
    </Provider>
  </MemoryRouter>
);

export const createNodeMock = () => {
  const doc = document.implementation.createHTMLDocument();
  return { parentElement: doc.body };
};

// https://github.com/Andarist/react-textarea-autosize#how-to-test-it-with-jest-and-react-test-renderer-if-you-need-ref
export const createTextareaNodeMock = () => document.createElement("textarea");

export const initializeResponsiveContext = (component) => (
  <ResponsiveContext.Provider value={{ width: WIDTH.TABLET }}>{component}</ResponsiveContext.Provider>
);

// Useful when we need to make assertions against a react-intl message having placeholders for values. It substitutes placeholder(s) with value(s).
// e.g. formatted = { id: 'diversity.invalidDailyRepetition', defaultMessage: 'Invalid daily repetition of {group}', values: { group: 'Oils' } }
export const getText = (formatted) => {
  const values = R.pathOr({}, ["values"], formatted);
  let text = formatted.defaultMessage;
  Object.keys(values).forEach((key) => (text = text.replace(key, values[key])));
  return text.replace(/[{}]/g, "");
};

export const mockPortal = () => (ReactDom.createPortal = jest.fn((element) => element));

export const isInTest = process.env.NODE_ENV === "test";

// TODO: To be removed in the future since we replaced this with mockRedux in order to have better "single responsability principle" mock.
export const mockProvider = (component, mockStore = appStore, initialEntries = ["/"]) => (
  <MemoryRouter initialEntries={initialEntries}>
    <IntlProvider locale="en" defaultLocale="en" messages={messages.en}>
      <Provider store={mockStore}>{component}</Provider>
    </IntlProvider>
  </MemoryRouter>
);

export const mockIntl = (component) => {
  return (
    <IntlProvider locale="en" defaultLocale="en" messages={messages.en}>
      {component}
    </IntlProvider>
  );
};

export const mockRedux = (component, { store = appStore, initialEntries = ["/"], valueProvider = {} } = {}) => {
  return (
    <MemoryRouter initialEntries={initialEntries}>
      <IntlProvider locale="en" defaultLocale="en" messages={messages.en}>
        <Provider store={store} value={valueProvider}>
          {component}
        </Provider>
      </IntlProvider>
    </MemoryRouter>
  );
};

export const mockRHF = (component, { defaultValues = {}, ...rhfMethods } = {}) => {
  const RHFWrapper = () => {
    const methods = useForm({ defaultValues });
    return (
      <IntlProvider locale="en" defaultLocale="en" messages={messages.en}>
        <FormProvider {...methods} {...rhfMethods}>
          {component}
        </FormProvider>
      </IntlProvider>
    );
  };

  return <RHFWrapper />;
};

export const jsonParseResult = (result) => {
  return JSON.parse(result) === "undefined" ? undefined : JSON.parse(result);
};
