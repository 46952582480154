import React from "react";
import { Svg } from "./styles";

export const StarFilled = ({ size }) => (
  <Svg width={size || "18px"} height={size || "18px"} viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-172.000000, -303.000000)" fill="#F7B825" fillRule="nonzero">
        <g transform="translate(172.000000, 303.000000)">
          <polygon points="9 0 6.21807065 5.59785714 0 6.49035714 4.5 10.8496429 3.4361413 17 9 14.0978571 14.5638587 17 13.5 10.8496429 18 6.49642857 11.7819293 5.59785714" />
        </g>
      </g>
    </g>
  </Svg>
);
