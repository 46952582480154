import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import { SearchHookForm } from "components/Form";
import { searchMyMenus } from "containers/MyMenus/actions";
import { selectFilters } from "containers/MyMenus/selectors";
import { RowStyled, ColStyled } from "components/utils";
import OrderByAndFilter from "./OrderByAndFilter";
import { FIELDS } from "containers/MyMenus/constants";
import messages from "components/Button/messages";

const Header = ({ visibleFilters, setVisibleFilters, closeFiltersAndFetchMenus, closeFilters }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const params = useSelector(selectFilters);
  const { control, getValues } = useFormContext();

  const fetchMenus = useCallback(() => {
    dispatch(searchMyMenus({ body: { ...params, ...getValues() } }));
  }, [dispatch, getValues, params]);

  const handleEnterPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        fetchMenus();
      }
    },
    [fetchMenus],
  );

  return (
    <RowStyled middle="xs" between="xs">
      <ColStyled xs={4}>
        <SearchHookForm control={control} name={FIELDS.NAME} onKeyUp={handleEnterPress} />
      </ColStyled>
      <Button widthAuto onClick={fetchMenus} children={intl.formatMessage(messages.search)} />
      <ColStyled xs>
        <OrderByAndFilter
          visibleFilters={visibleFilters}
          setVisibleFilters={setVisibleFilters}
          closeFiltersAndFetchMenus={closeFiltersAndFetchMenus}
          closeFilters={closeFilters}
        />
      </ColStyled>
    </RowStyled>
  );
};

export default Header;
