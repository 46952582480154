import React from "react";
import { Svg } from "./styles";

export const Star = ({ size, onClick }) => (
  <Svg
    onClick={onClick}
    width={size || "17px"}
    height={size || "17px"}
    viewBox="0 0 18 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-52.000000, -62.000000)" fill="#8C9BA5" fillRule="nonzero">
        <g transform="translate(52.000000, 62.000000)">
          <path d="M9,2.74428571 L10.6875,6.13214286 L10.96875,6.73928571 L11.580163,6.83035714 L15.3464674,7.37071429 L12.6684783,9.98142857 L12.2099185,10.4246429 L12.3199728,11.0317857 L12.9619565,14.7535714 L9.59307065,12.9989286 L9,12.75 L8.43138587,13.0475 L5.0625,14.7778571 L5.67391304,11.0560714 L5.78396739,10.4489286 L5.33152174,9.98142857 L2.62907609,7.34035714 L6.39538043,6.8 L7.00679348,6.70892857 L7.28804348,6.10178571 L9,2.74428571 Z M9,0 L6.21807065,5.59785714 L0,6.49035714 L4.5,10.8496429 L3.4361413,17 L9,14.0978571 L14.5638587,17 L13.5,10.8496429 L18,6.49642857 L11.7819293,5.59785714 L9,0 Z" />
        </g>
      </g>
    </g>
  </Svg>
);
