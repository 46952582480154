import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext } from "react-hook-form";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS, FOOD_RESTRICTION_PATH } from "../../constants";
import messages from "containers/OptimizedMenu/Rules/messages";
import { SmallButton } from "./styles";
import { addDay, removeDay } from "./utils";

const Day = ({ dayNumber, week, weekDay, includedDays, excludedDays }) => {
  const { setValue } = useFormContext();

  const isIncluded = useMemo(() => includedDays.includes(dayNumber), [dayNumber, includedDays]);
  const isExcluded = useMemo(() => excludedDays.includes(dayNumber), [dayNumber, excludedDays]);

  const onInclude = useCallback(() => {
    if (isIncluded) {
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.INCLUDED_DAYS}`, removeDay(includedDays, dayNumber));
      return;
    }

    setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.INCLUDED_DAYS}`, addDay(includedDays, dayNumber));
  }, [dayNumber, includedDays, isIncluded, setValue]);

  const onExclude = useCallback(() => {
    if (isExcluded) {
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDED_DAYS}`, removeDay(excludedDays, dayNumber));
      return;
    }

    setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDED_DAYS}`, addDay(excludedDays, dayNumber));
  }, [dayNumber, excludedDays, isExcluded, setValue]);

  const onNoRule = useCallback(() => {
    if (isExcluded) {
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDED_DAYS}`, removeDay(excludedDays, dayNumber));
      return;
    } else if (isIncluded) {
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.INCLUDED_DAYS}`, removeDay(includedDays, dayNumber));
      return;
    }
  }, [dayNumber, includedDays, excludedDays, isIncluded, isExcluded, setValue]);

  return (
    <ColStyled xs>
      <Text bold value={<FormattedMessage {...messages.weekAndDay} values={{ week, day: weekDay }} />} />
      <RowStyled middle="xs" center="xs" margin="10px 0">
        <ColStyled maxWidth="135px" xs>
          <SmallButton
            onClick={onInclude}
            color={colors["categorical-corporate-03"].hex}
            disabled={isExcluded}
            selected={isIncluded}
          >
            <FormattedMessage {...messages.include} />
          </SmallButton>
        </ColStyled>
        <ColStyled maxWidth="135px" xs>
          <SmallButton
            onClick={onNoRule}
            color={colors["ui-05"].hex}
            disabled={isIncluded || isExcluded}
            selected={!(isIncluded || isExcluded)}
          >
            <FormattedMessage {...messages.noRule} />
          </SmallButton>
        </ColStyled>
        <ColStyled maxWidth="135px" xs>
          <SmallButton onClick={onExclude} color={colors["support-01"].hex} disabled={isIncluded} selected={isExcluded}>
            <FormattedMessage {...messages.exclude} />
          </SmallButton>
        </ColStyled>
      </RowStyled>
    </ColStyled>
  );
};

export default Day;
