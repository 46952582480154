import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "@wfp/ui";

import { Title, Sentence } from "./components";
import messages from "./messages";

function InfeasibleWithoutSourcing({ setPage, closeModal }) {
  const intl = useIntl();

  const goToMenuStructure = useCallback(() => {
    setPage(1);
    closeModal();
  }, [closeModal, setPage]);

  return (
    <>
      <Title value={intl.formatMessage(messages.infeasibleTitle)} />
      <Sentence value={intl.formatMessage(messages.menuStructureNotMet)} />
      <Sentence
        value={intl.formatMessage(messages.goBackAndLink, {
          link: <Link onClick={goToMenuStructure} children={intl.formatMessage(messages.menuStructure)} />,
        })}
      />
    </>
  );
}

export default InfeasibleWithoutSourcing;
