import { call, select, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { loadGeneralAppInfoEnded, loadGeneralAppInfoStarted, loadGlobalFeaturesSuccess, setAlert } from "containers/App/actions";
import {
  getAccount,
  getAllPriceSources,
  getRegions,
  getConstants,
  postAccount,
  getCurrency,
  postToRetrieveToken,
  postValidEmail,
  getCommunityFoodSources,
  getFoodItems,
  getFeatures,
} from "api/api";
import {
  loadStarted,
  loadEnded,
  loadSmallStarted,
  loadSmallEnded,
  loadAccountSuccess,
  loadEssentialInfo,
  loadGeneralAppInfo,
  loadRegionsSuccess,
  loadPriceSourcesSuccess,
  loadConstantsSuccess,
  loadCurrencySuccess,
  loadAccount,
  saveAccount,
  loginRedirect,
  validEmail,
  loadFoodSourcesSuccess,
  loadFoodItems,
  loadFoodItemsSuccess,
  loadRegions,
  loadingRegionsStarted,
  loadingRegionsEnded,
} from "./actions";
import { loadTutorialsSaga } from "containers/Tutorials/sagas";
import { selectCountryCode, selectSelectedCountry } from "./selectors";
import { loadAllCommunityNutritionalValuesSaga } from "containers/Results/sagas";
import { loadTutorials } from "containers/Tutorials/actions";
import { selectFormValues } from "containers/Admin/selectors";
import routesPath from "containers/App/Routes/paths";

export function* loadAccountSaga() {
  try {
    const user = yield call(getAccount);

    // Store language preference inside localStorage
    const languagePreference = localStorage.getItem("language");
    if (!languagePreference) {
      localStorage.setItem("language", user.profile.language);
    }

    yield put(loadAccountSuccess({ user }));
  } catch (e) {
    yield put(loadEnded());
  }
}

export function* loadGlobalFeaturesSaga() {
  try {
    const { results } = yield call(getFeatures);
    // For now we just need the name of the global features. Change in the future if needed
    const reworkedResults = results.map(item => item.name)
    yield put(loadGlobalFeaturesSuccess({ globalFeatures: reworkedResults }));
  } catch (e) {
    yield put(loadGeneralAppInfoEnded());
    yield put(setAlert(e));
  }
}

export function* loadPricesSourcesSaga() {
  try {
    const prices = yield call(getAllPriceSources);
    const priceSources = prices.map(({ value, origins, label }) => ({ checkboxes: origins, value, label }));
    yield put(loadPriceSourcesSuccess({ priceSources }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadRegionsSaga({ inline = false } = {}) {
  try {
    inline ? yield put(loadingRegionsStarted()) : yield put(loadStarted());
    const country = yield select(selectCountryCode);
    const { results } = yield call(getRegions, country);
    const refactorRegions = results.map(({ id, ...rest }) => ({ value: id, ...rest }));
    yield put(loadRegionsSuccess({ regions: refactorRegions }));
    inline ? yield put(loadingRegionsEnded()) : yield put(loadEnded());
  } catch (e) {
    inline ? yield put(loadingRegionsEnded()) : yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadConstantsSaga() {
  try {
    const constants = yield call(getConstants);
    yield put(loadConstantsSuccess({ constants }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCurrencySaga(country) {
  try {
    const currency = yield call(getCurrency, country);
    yield put(loadCurrencySuccess({ currency }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCommunityFoodSourcesSaga(country) {
  try {
    const { results } = yield call(getCommunityFoodSources, country);
    yield put(loadFoodSourcesSuccess({ foodSources: results }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* saveAccountSaga({ payload: { body, path, fetchNutritionalValues = false } }) {
  try {
    yield put(loadStarted());
    const data = yield call(postAccount, body);

    // Update local language preference
    if (body?.profile?.language) {
      localStorage.setItem("language", body?.profile?.language);
    }

    if (path) yield put(push(path));
    yield put(loadAccountSuccess({ user: data }));
    yield put(loadTutorials());
    yield put(loadEnded());

    if (fetchNutritionalValues) {
      const country = body.profile.country;
      yield call(loadAllCommunityNutritionalValuesSaga, country);
    }
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadEssentialInfoSaga() {
  try {
    yield put(loadStarted());
    yield call(loadAccountSaga);
    yield call(loadTutorialsSaga);
    yield put(loadEnded());

    // We start fetching all the nutritional values without blocking user with loading spinners
    const { country } = yield select(selectSelectedCountry);
    yield call(loadAllCommunityNutritionalValuesSaga, country);
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadGeneralAppInfoSaga() {
  try {
    yield put(loadGeneralAppInfoStarted());
    yield call(loadGlobalFeaturesSaga);
    yield put(loadGeneralAppInfoEnded());
  } catch (e) {
    yield put(loadGeneralAppInfoEnded());
    yield put(setAlert(e));
  }
}

export function* loginRedirectSaga({ payload: { code } }) {
  try {
    yield put(loadStarted());
    const params = new URLSearchParams({
      grant_type: "authorization_code",
      code,
      client_id: process.env.CIAM_CLIENT_ID,
      redirect_uri: process.env.CIAM_CALLBACK_URL,
      code_verifier: localStorage.getItem("code_verifier"),
    });
    const results = yield call(postToRetrieveToken, { params });
    if (results) {
      localStorage.removeItem("code_verifier");
      yield put(push(routesPath.menus));
    }
    yield put(loadEnded());
  } catch (e) {
    localStorage.removeItem("token");
    yield put(loadEnded());
  }
}

export function* validEmailSaga({ payload: { email, cb } }) {
  try {
    yield put(loadSmallStarted({ email }));
    const { valid } = yield call(postValidEmail, { username: email });
    yield put(loadSmallEnded());
    if (cb) cb(valid);
  } catch (e) {
    yield put(loadSmallEnded());
  }
}

export function* loadFoodItemsSaga({ payload: { params, initialPriorityItems } }) {
  try {
    // When loading the priority items page for the first time, we need to get the initial values set for the priority items and store them otherwise we could get initial blank food items
    let { priority_items } = yield select(selectFormValues);
    if (initialPriorityItems) {
      priority_items = initialPriorityItems.map((item) => ({ value: item.item_id, label: item.item_display_name }));
    }

    // Since we could have previous set priority items, we need to persist these inside our options otherwise could be cleaned up by accident
    // Eg. Item "A", we add a new one and we search for item "B": new options do not include "A", therefore previous item is cleared up. We prevent this bug by persisting those items.
    const previousItems = priority_items
      ? priority_items.filter((item) => item.value).map((item) => ({ id: item.value, display_name: item.label }))
      : [];

    const { results } = yield call(getFoodItems, params ? { ...params, page_size: 50 } : { page_size: 50 });
    yield put(loadFoodItemsSuccess({ foodItems: [...previousItems, ...results] }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* appSaga() {
  yield takeLatest(loadAccount, loadAccountSaga);
  yield takeLatest(saveAccount, saveAccountSaga);
  yield takeLatest(loginRedirect, loginRedirectSaga);
  yield takeLatest(loadEssentialInfo, loadEssentialInfoSaga);
  yield takeLatest(validEmail, validEmailSaga);
  yield takeLatest(loadFoodItems, loadFoodItemsSaga);
  yield takeLatest(loadRegions, loadRegionsSaga);
  yield takeLatest(loadGeneralAppInfo, loadGeneralAppInfoSaga);
}
