import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ColStyled } from "components/utils";
import { saveAccount } from "containers/App/actions";
import routesPath from "containers/App/Routes/paths";
import { saveComparisonList, clearFilters } from "containers/MyMenus/actions";
import { Link } from "@wfp/ui";

const reloadCurrentPage = [
  routesPath.foodPrices,
  routesPath.menus,
  routesPath.createManual,
  routesPath.createOptimized,
  routesPath.createCommunity,
];

export default function Country({ mainNavigationRef, country, country_name, history }) {
  const dispatch = useDispatch();
  const isSpecificPage = reloadCurrentPage.includes(history.location.pathname);

  const onClickCountry = useCallback(() => {
    const body = { profile: { country } };
    mainNavigationRef.current.onChangeSub("close");
    dispatch(saveAccount({ body, path: !isSpecificPage ? routesPath.menus : null, fetchNutritionalValues: true }));
    dispatch(clearFilters());
    dispatch(saveComparisonList([]));
  }, [country, dispatch, isSpecificPage, mainNavigationRef]);

  return (
    <ColStyled margin="15px 0 0">
      <Link children={country_name} onClick={onClickCountry} inline />
    </ColStyled>
  );
}
