import "react-accessible-accordion/dist/fancy-example.css";
import styled from "styled-components";
import { Accordion, AccordionItemButton } from "react-accessible-accordion";
import { colors, Icon } from "@wfp/ui";

export const IconStyled = styled(Icon)`
  transform: ${({ expanded }) => (expanded === "true" ? "rotate(-180deg)" : "rotate(0deg)")};
  margin: 0 10px;
  padding-top: ${({ expanded }) => (expanded === "true" ? "0" : "5px")};
  transition: transform 0.35s ease-in;
`;

export const AccordionStyled = styled(Accordion)`
  border: none;
  width: 100%;

  & + & {
    margin-top: 20px !important;
  }

  .accordion__item {
    background: ${colors["ui-01"].hex};
    border-radius: 0.4rem;

    & + & {
      border: none;
    }
  }

  .accordion__panel {
    padding: ${({ padding = "10px 20px" }) => padding};
  }
`;

const MyAccordionItemButton = ({ withBorderBottom, ...props }) => <AccordionItemButton {...props} />;

export const AccordionItemButtonStyled = styled(MyAccordionItemButton)`
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent !important;
  font-weight: 600;
  font-size: ${({ fontSize = "20px" }) => fontSize};
  padding: ${({ padding = "10px 20px" }) => padding};
  cursor: pointer;
  border-bottom: ${({ withBorderBottom }) => (withBorderBottom ? `2px solid ${colors["ui-03"].hex}` : 0)};

  svg {
    color: #0a6eb4;
  }
`;
