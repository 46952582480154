import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectCountry = () => (state) => R.pathOr(initialState, ["selectCountry"], state);

export default () => createSelector(selectCountry(), (selectCountryState) => selectCountryState);

export const selectLoading = createSelector(selectCountry(), (selectCountryState) =>
  R.pathOr(false, ["loading"], selectCountryState),
);

export const selectCountries = createSelector(selectCountry(), (selectCountryState) =>
  R.pathOr(null, ["countries"], selectCountryState),
);
