import { defineMessage } from "react-intl";

export default {
  header: defineMessage({
    id: "compareMenus.header",
    defaultMessage: "Compare menus",
  }),
  subHeader: defineMessage({
    id: "compareMenus.subHeader",
    defaultMessage:
      "Here you can compare the cost, nutrition value, sourcing channels and structure of your menus. {link}",
  }),
  basicSettings: defineMessage({
    id: "compareMenus.basicSettings",
    defaultMessage: "Basic settings",
  }),
  menuStructure: defineMessage({
    id: "compareMenus.menuStructure",
    defaultMessage: "Menu structure",
  }),
  menusToCompare: defineMessage({
    id: "compareMenus.menusToCompare",
    defaultMessage: "Menus to compare",
  }),
};
