export const ManualMenu = () => (
  <svg width="55" height="55" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 40.5C52.4558 40.5 58.5 34.4558 58.5 27C58.5 19.5442 52.4558 13.5 45 13.5C37.5442 13.5 31.5 19.5442 31.5 27C31.5 34.4558 37.5442 40.5 45 40.5ZM45 45C35.0589 45 27 36.9411 27 27C27 17.0589 35.0589 9 45 9C54.9411 9 63 17.0589 63 27C63 36.9411 54.9411 45 45 45Z"
      fill="#8C9BA5"
    />
    <path
      d="M22.5 81H18V69.8367C18 58.6529 27.0662 49.5867 38.25 49.5867C38.2824 49.5867 42.8149 49.6084 51.8473 49.6518C62.993 49.7054 72 58.7558 72 69.9016V80.9994H67.5V69.9016C67.5 61.2326 60.4945 54.1934 51.8257 54.1517L38.3257 54.0869C29.5515 54.0867 22.5 61.1382 22.5 69.8367V81Z"
      fill="#8C9BA5"
    />
  </svg>
);
