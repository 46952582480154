import { ThreeDots } from "react-loader-spinner";
import { colors, Icon } from "@wfp/ui";
import { iconUser, iconWfpHumFoodSecurityClusterPos } from "@wfp/icons";

import { Text } from "components";
import { RowStyled, ColStyled, loaderWrapperStyle } from "components/utils";
import { Box } from "./styles";

const BoxNumber = ({ loading, value, text, personalItemsCount, customItemsCount, customSourcesNumber }) => (
  <Box middle="xs" padding="10px 5px">
    <ColStyled xs={12}>
      {loading ? (
        <ThreeDots color={colors["brand-01"].hex} height={30} width={30} wrapperStyle={loaderWrapperStyle} />
      ) : (
        <RowStyled middle="xs" center="xs">
          <Text fontSize="21.5px" bold value={value} />
          {personalItemsCount > 0 && (
            <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
              <Icon icon={iconUser} fill={colors["support-03"].hex} />
              <Text defaultColor={colors["support-03"].hex} value={personalItemsCount} />
            </RowStyled>
          )}
          {customItemsCount > 0 && (
            <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
              <Icon
                icon={iconWfpHumFoodSecurityClusterPos}
                height="20"
                width="20"
                fill={colors["categorical-corporate-08"].hex}
              />
              <Text defaultColor={colors["categorical-corporate-08"].hex} value={customItemsCount} />
            </RowStyled>
          )}
          {customSourcesNumber > 0 && (
            <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
              <Icon
                icon={iconWfpHumFoodSecurityClusterPos} // TODO: check if we need to change this icon since we are using it for custom menu items
                height="20"
                width="20"
                fill={colors["categorical-corporate-06"].hex}
              />
              <Text defaultColor={colors["categorical-corporate-06"].hex} value={customSourcesNumber} />
            </RowStyled>
          )}
        </RowStyled>
      )}
      <Text wrap value={text} />
    </ColStyled>
  </Box>
);

export default BoxNumber;
