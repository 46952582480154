import React, { useState, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { iconAddGlyph } from "@wfp/icons";
import { colors, Icon } from "@wfp/ui";
import { isMobile } from "react-device-detect";
import { useForm } from "react-final-form";
import { RowStyled } from "components/utils";
import { Text } from "components";
import { selectFormFoods } from "containers/Results/selectors";
import { getPrices } from "containers/Results/utils";
import { FoodItemContainer } from "./styles";

export default function FoodItem({ food, day, values }) {
  const form = useForm();
  const reduxFormFoods = useSelector(selectFormFoods);

  const [hover, setHover] = useState(false);

  const activeHover = useCallback(() => setHover(true), []);
  const disactiveHover = useCallback(() => setHover(false), []);

  const addItem = useCallback(() => {
    const dayKey = `day_${day}`;
    const quantity = 0;

    // Prevent user from inserting same food item multiple times
    if (values[dayKey].find((item) => item.food_item === food.food_item)) {
      return;
    }

    const newItem = {
      ...food,
      quantity,
      day,
      ...getPrices({ ...food, quantity }),
    };
    form.change(dayKey, [...values[dayKey], newItem]);

    // If the new food item is not found inside form.values.foods, we try to add it since it's new (we need to check all the days beacuse the content is different)
    if (Object.keys(reduxFormFoods).find((label) => label === food.display_name) === undefined) {
      form.change("foods", {
        ...values.foods,
        [food.display_name]: R.omit(["source"], {
          ...food,
          price_currency: "USD",
          quantity: "100 g",
          additional_costs: [],
        }),
      });
    }
  }, [form, values, day, food, reduxFormFoods]);

  const callOnClick = useCallback(() => {
    // On mobile with no overlay? We activate it
    if (isMobile && !hover) {
      activeHover();
      return;
    }

    // On mobile with overlay? We can go ahead and add the food item, and we also remove the hover
    if (isMobile && hover) {
      addItem();
      disactiveHover();
      return;
    }

    addItem();
  }, [addItem, activeHover, disactiveHover, hover]);

  return (
    <FoodItemContainer onMouseEnter={activeHover} onMouseLeave={disactiveHover} onClick={callOnClick} hover={hover}>
      <RowStyled middle="xs" between="xs" padding="5px" height="100%">
        <Text
          value={food.display_name}
          defaultColor={food.is_priority ? colors["support-02"].hex : colors["text-01"].hex}
        />
        {hover && <Icon width="16" height="16" fill={colors["brand-01"].hex} icon={iconAddGlyph} />}
      </RowStyled>
    </FoodItemContainer>
  );
}
