import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  title: {
    fontFamily: "OpenSans",
    fontWeight: "bold",
    fontSize: 16,
  },
  line: {
    borderBottom: 1,
    borderColor: colors["ui-04"].hex,
    margin: "15 0 10",
  },
  littleBox: {
    width: "47%",
    border: 1,
    borderRadius: 1,
    borderColor: colors["ui-04"].hex,
    fontSize: 20,
    textAlign: "center",
    height: 82,
    marginTop: 15,
    alignContent: "flex-end",
  },
  firstSection: {
    width: "100%",
    border: 1,
    borderColor: colors["ui-04"].hex,
    height: 262,
    padding: "10 15",
  },
  section: {
    flex: 1,
    overflow: "hidden",
    fontSize: 30,
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    marginRight: 20,
  },
});
