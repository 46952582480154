import { select, call, all, put, takeLatest } from "redux-saga/effects";

import { setAlert } from "containers/App/actions";
import {
  postFavouriteMenu,
  getCompareNutritions,
  getCompareInformations,
  getCompareDiversities,
  getCompareSourcing,
} from "api/api";
import { selectCurrency } from "containers/App/selectors";
import { loadCurrencySaga } from "containers/App/sagas";
import { loadStarted, loadSuccess, loadEnded, loadCompareMenus, changeFavourite } from "./actions";

export function* loadInformationSaga(ids) {
  try {
    return yield call(getCompareInformations, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadDiversitiesSaga(ids) {
  try {
    return yield call(getCompareDiversities, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadNutritionsSaga(ids) {
  try {
    return yield call(getCompareNutritions, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadSourcingSaga(ids) {
  try {
    return yield call(getCompareSourcing, ids);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* saveFavoriteOnMenuSaga(id) {
  try {
    return yield call(postFavouriteMenu, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadCompareMenusSaga({ payload: { ids } }) {
  try {
    yield put(loadStarted());
    const currency = yield select(selectCurrency);
    const [info, diversity, nutritions, sourcing] = yield all([
      call(loadInformationSaga, ids),
      call(loadDiversitiesSaga, ids),
      call(loadNutritionsSaga, ids),
      call(loadSourcingSaga, ids),
    ]);
    if (!currency) yield* loadCurrencySaga(info[0].preliminary_information.country);
    yield put(loadSuccess({ info, diversity, nutritions, sourcing }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
  }
}

export function* changeFavouriteSaga({ payload: { id, ids } }) {
  try {
    yield put(loadStarted());
    yield* saveFavoriteOnMenuSaga(id);
    const info = yield* loadInformationSaga(ids);
    yield put(loadSuccess({ info }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* compareSaga() {
  yield takeLatest(loadCompareMenus, loadCompareMenusSaga);
  yield takeLatest(changeFavourite, changeFavouriteSaga);
}
