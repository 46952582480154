import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Layout, SecondHeader } from "components";
import { colors, Tabs, Tab } from "@wfp/ui";
import AllUsers from "../AllUsers";
import messages from "../messages";
import { intlTabs } from "../utils";
import { TabsContainer } from "containers/Admin/styles";

const Users = () => {
  const location = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useMemo(() => intlTabs(intl), [intl]);

  const setInitTabAndRedirect = useCallback(() => {
    const index = tabs.findIndex((tab) => tab.href === location.pathname);

    if (index < 0) {
      setTabIndex(0);
      history.replace(tabs[0].href);
    } else {
      setTabIndex(index);
    }
  }, [history, location.pathname, tabs]);

  useEffect(() => {
    setInitTabAndRedirect();
  }, [setInitTabAndRedirect]);

  const onTabChange = useCallback(
    (index) => {
      const selectedTab = tabs[index];
      history.push(selectedTab.href);
      setTabIndex(index);
    },
    [history, tabs],
  );

  return (
    <Layout backgroundColor={colors["ui-02"].hex}>
      <SecondHeader
        title={intl.formatMessage(messages.usersHeader)}
        info={intl.formatMessage(messages.usersSubHeader)}
        afterText={
          <TabsContainer>
            <Tabs selected={tabIndex} onSelectionChange={onTabChange}>
              {tabs.map((tab) => (
                <Tab key={tab.label} label={tab.label} />
              ))}
            </Tabs>
          </TabsContainer>
        }
      />

      <Switch>
        <Route path={"/plusadmin/users/all"} component={AllUsers} />
      </Switch>
    </Layout>
  );
};

export default Users;
