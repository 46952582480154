import styled from "styled-components";

export const ContainerTextAndStar = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  > div {
    max-width: calc(10% - 10px);
    margin: 2px 5px;
  }
  p {
    max-width: 80%;
    margin: 0 5px;
  }
`;
