import { colors } from "@wfp/ui";
import styled from "styled-components";

export const FoodInfoContainer = styled.div`
  grid-area: foodinfo;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
`;

export const CostsContainer = styled.div`
  grid-area: costs;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
`;
