import { defineMessages } from "react-intl";

export default defineMessages({
  priceTitle: {
    id: "generalInfo.priceTitle",
    defaultMessage: "Price sources",
  },
  priceInfo: {
    id: "generalInfo.priceInfo",
    defaultMessage:
      "Choose a combination of price sources for an accurate calculation. PLUS uses an average of all the prices",
  },
  optionAll: {
    id: "priceSources.optionAll",
    defaultMessage: "All",
  },
  optionOne: {
    id: "priceSources.optionOne",
    defaultMessage: "Last year",
  },
  option: {
    id: "priceSources.option",
    defaultMessage: "Last {number}year",
  },
  priceValidity: {
    id: "generalInfo.priceValidity",
    defaultMessage: "Price source validity",
  },
  priceValidityInfo: {
    id: "generalInfo.priceValidityInfo",
    defaultMessage: "Select the time frame from when we can use prices",
  },
});
