import { FormattedMessage } from "react-intl";
import { partialCopyMenu, copyMenu } from "containers/Menu/actions";
import messages from "./messages";
import { MANUAL, COMMUNITY, OPTIMIZED } from "containers/MyMenus/labels";

export const optionsClone = [
  { id: "clone1", value: 1, name: "radio", labelText: <FormattedMessage {...messages.optionOne} /> },
  { id: "clone2", value: 2, name: "radio", labelText: <FormattedMessage {...messages.optionTwo} /> },
  { id: "clone3", value: 3, name: "radio", labelText: <FormattedMessage {...messages.optionThree} /> },
  { id: "clone4", value: 4, name: "radio", labelText: <FormattedMessage {...messages.optionFour} /> },
  { id: "clone5", value: 5, name: "radio", labelText: <FormattedMessage {...messages.optionFive} /> },
  { id: "clone6", value: 6, name: "radio", labelText: <FormattedMessage {...messages.optionSix} /> },
  { id: "clone7", value: 7, name: "radio", labelText: <FormattedMessage {...messages.optionSeven} /> },
  { id: "clone8", value: 8, name: "radio", labelText: <FormattedMessage {...messages.optionEight} /> },
  { id: "clone9", value: 9, name: "radio", labelText: <FormattedMessage {...messages.optionNine} /> },
  { id: "clone10", value: 10, name: "radio", labelText: <FormattedMessage {...messages.optionTen} /> },
];

export const downloadOptions = [
  { id: "excel", value: "excel", name: "radio", labelText: "Excel" },
  { id: "pdf", value: "pdf", name: "radio", labelText: "PDF" },
];

// Given the menu type, it returns the correct function to be called when creating a copy of the current menu
export const getDuplicateFunctionToCall = (menuType) => {
  let functionToCall;

  switch (menuType) {
    case OPTIMIZED:
      functionToCall = copyMenu;
      break;
    case MANUAL:
    case COMMUNITY:
      functionToCall = partialCopyMenu;
      break;
    default:
      functionToCall = copyMenu;
  }

  return functionToCall;
};
