export const FIELDS = {
  INGREDIENT_ID: "ingredient_id",
  NAME: "name",
  DESCRIPTION: "description",
  AUTHOR: "author",
  INGREDIENTS: "ingredients",
  FOOD_ITEM: "food_item",
  QUANTITY: "quantity",
};

export const ITEMS_INITIAL_VALUES = {
  [FIELDS.INGREDIENT_ID]: undefined,
  [FIELDS.FOOD_ITEM]: "",
  [FIELDS.QUANTITY]: "",
};
