import { useCallback } from "react";
import { useController } from "react-hook-form";

import { SliderStyled } from "./styles";

const SliderHookForm = ({
  control,
  name,
  id,
  min = 0,
  max = 100,
  step = 1,
  color,
  minLabel = "%",
  maxLabel = "%",
  labelText,
  disabled,
  fullWidth = true,
  customOnChange,
  ...rest
}) => {
  const { field } = useController({ name, control });

  const handleChange = useCallback(
    (value) => {
      if (customOnChange) {
        customOnChange(value, field);
      } else {
        field.onChange(value);
      }
    },
    [customOnChange, field]
  );

  return (
    <SliderStyled
      id={id}
      name={name}
      min={min}
      max={max}
      step={step}
      color={color}
      minLabel={minLabel}
      maxLabel={maxLabel}
      labelText={labelText}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={handleChange}
      value={isNaN(field.value) || !field.value ? 0 : parseFloat(field.value)}
      {...rest}
    />
  );
};

export default SliderHookForm;
