import messages from "./messages";

export const getPeriodOptions = (intl) => [
  {
    value: "y",
    label: intl.formatMessage(messages.yearAround),
  },
  {
    value: "m",
    label: intl.formatMessage(messages.months),
  },
];
