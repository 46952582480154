import { Tooltip } from "@wfp/ui";
import styled from "styled-components";

import { Text } from "components";
import { RowStyled } from "components/utils";

const StyledTooltip = ({ isTabletOrMobile, ...props }) => {
  return <Tooltip {...props} />;
};

export const CustomTooltip = styled(StyledTooltip)`
  max-width: none;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? "50%" : "40%")};
  cursor: auto !important;
  background: #778289;
  z-index: 3;

  .wfp--tooltip__arrow:before {
    background: #778289;
  }
`;

export const TitleContainer = styled(RowStyled)`
  background: #edf0f3;
  padding: 0.75rem;
`;

export const FoodItemContainer = styled(RowStyled)`
  background: #fff;
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid #edf0f3;
  cursor: pointer;
`;

export const FoodItemLabel = styled.span`
  font-size: 16px;
  color: ${({ color }) => color};
`;

export const TitleAndCount = styled(Text)`
  display: flex;
  flex-wrap: nowrap;
`;

export const Group = styled.span`
  margin-right: 5px;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
