import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";

const Footer = ({ page, setPage, passPage, labels, isLastPage, disabledButton, showLoadingText }) => {
  const showPrevious = page > 0;
  const prefixDataTestId = useMemo(() => labels[page].localeKey, [labels, page]);

  const onBack = useCallback(() => {
    setPage(Math.max(page - 1, 0));
    passPage(Math.max(page - 1, 0));
    window.scrollTo(0, 0);
  }, [page, passPage, setPage]);

  return (
    <RowStyled className="wfp--form-controls">
      <ColStyled xs className="start-xs">
        {showPrevious && (
          <Button maxwWidth data-test-id={`${prefixDataTestId}-previous`} kind="secondary" onClick={onBack}>
            <FormattedMessage {...messages.back} />
          </Button>
        )}
      </ColStyled>
      <ColStyled xs className="end-xs">
        <Button
          maxwWidth
          type="submit"
          kind="primary"
          data-test-id={`${prefixDataTestId}-${!isLastPage ? "next" : "submit"}`}
          disabled={disabledButton}
        >
          {showLoadingText ? (
            <FormattedMessage {...messages.loading} />
          ) : !isLastPage ? (
            <FormattedMessage {...messages.next} />
          ) : (
            <FormattedMessage {...messages.submit} />
          )}
        </Button>
      </ColStyled>
    </RowStyled>
  );
};

export default Footer;
