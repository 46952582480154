import { useIntl, FormattedMessage } from "react-intl";
import CookieConsent from "react-cookie-consent";
import { colors } from "@wfp/ui";

import messages from "../messages";

const CookieConsentBanner = ({ onAccept, onDecline }) => {
  const intl = useIntl();

  return (
    <CookieConsent
      enableDeclineButton
      buttonText={intl.formatMessage(messages.acceptCookie)}
      declineButtonText={intl.formatMessage(messages.declineCookie)}
      ariaAcceptLabel={intl.formatMessage(messages.ariaAcceptCookie)}
      ariaDeclineLabel={intl.formatMessage(messages.ariaDeclineCookie)}
      expires={365}
      style={{ background: colors["brand-01"].hex, paddingLeft: "1rem", paddingRight: "1rem" }}
      contentStyle={{ margin: 0 }}
      disableButtonStyles
      buttonClasses="wfp--btn wfp--btn--primary"
      declineButtonClasses="wfp--btn wfp--btn--danger--primary"
      buttonWrapperClasses="CookieConsent--button-wrapper"
      onAccept={onAccept}
      onDecline={onDecline}
    >
      <FormattedMessage {...messages.cookieMessage} />
    </CookieConsent>
  );
};

export default CookieConsentBanner;
