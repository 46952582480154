import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import menuMessages from "containers/Menu/messages";
import { FIELDS } from "containers/Results/constants";
import { ColStyled } from "components/utils";

import RecipePreview from "./RecipePreview";
import { DayNameContainer, CustomDraggable, DraggableInnerDiv } from "./styles";

const DayBox = ({ day, listKey, setDayToShow }) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const foodItemsList = useWatch({ control, name: `${FIELDS.MENU_COMPOSITION}.${listKey}` });
  const formMealName = useWatch({ control, name: `${FIELDS.MEAL_NAMES}.${day}` });
  const dayRecipes = useWatch({ control, name: `${FIELDS.INCLUDED_RECIPES}.${listKey}` });

  const $containerDay = useRef();

  const clickOnDay = useCallback(() => setDayToShow(day), [day, setDayToShow]);

  const getItemStyle = useCallback(
    (isDragging, draggableStyle) => ({
      userSelect: "none",
      ...draggableStyle,
    }),
    []
  );

  return (
    <div style={{ height: "100%" }}>
      <DayNameContainer>
        <>
          <Text fontSize="16px" bold value={formMealName ?? intl.formatMessage(menuMessages.day, { day })} />
          <Icon
            onClick={clickOnDay}
            icon={iconEdit}
            description="edit"
            fill={colors["brand-01"].hex}
            width="18"
            height="18"
            className="pointer"
          />
        </>
      </DayNameContainer>

      <div ref={$containerDay} style={{ height: "100%" }}>
        {(foodItemsList || []).map(({ display_name, quantity, is_priority }, index) => (
          <CustomDraggable
            key={`row-day${day}-${display_name}-${index}`}
            draggableId={`row-day${day}-${display_name}-${index}`}
            index={index}
            className="col-xs"
            style={{ padding: `${!index ? "5px" : "0"} 10px 0` }}
          >
            {(provided, snapshot) => (
              <DraggableInnerDiv
                key={`row-${display_name}`}
                provided={provided}
                ref={provided.innerRef}
                padding="5px 10px"
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <ColStyled xs={8} className="start-xs" padding="0">
                  <Text
                    value={display_name.split(";")[0]}
                    defaultColor={is_priority ? colors["support-02"].hex : colors["text-01"].hex}
                  />
                </ColStyled>
                <ColStyled xs={4} className="end-xs">
                  <Text defaultColor={colors["text-02"].hex} value={`${quantity.toFixed(1)} g`} />
                </ColStyled>
                {provided.placeholder}
              </DraggableInnerDiv>
            )}
          </CustomDraggable>
        ))}
        {(dayRecipes || []).map((recipe) => (
          <RecipePreview key={recipe.recipe_id} recipe={recipe} />
        ))}
      </div>
    </div>
  );
};

export default DayBox;
