import { defineMessage } from "react-intl";

export default {
  welcome: defineMessage({
    id: "profile.welcome",
    defaultMessage: "Welcome {name}!",
  }),
  logout: defineMessage({
    id: "profile.logout",
    defaultMessage: "Logout",
  }),
  previousCountries: defineMessage({
    id: "profile.previousCountries",
    defaultMessage: "Other countries for which you have recently used PLUS:",
  }),
  usingCountry: defineMessage({
    id: "profile.usingCountry",
    defaultMessage: "You are using PLUS for {country}",
  }),
};
