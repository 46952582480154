import React from "react";
import { CheckboxStyled } from "./styles";

export default function Checkbox({
  labelText,
  fontBold,
  noMarginBottom,
  fontSize,
  checked,
  onChange,
  className,
  defaultChecked,
  id,
}) {
  return (
    <CheckboxStyled
      fontBold={fontBold}
      fontSize={fontSize}
      defaultChecked={defaultChecked}
      noMarginBottom={noMarginBottom}
      id={id}
      onChange={onChange}
      className={className}
      checked={checked}
      labelText={labelText}
    />
  );
}
