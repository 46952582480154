import { colors } from "@wfp/ui";
import styled from "styled-components";

export const LegendWrapper = styled.div`
  padding: 1.4rem 15px;
`;

export const LegendContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ columns }) => columns};
  grid-auto-rows: 40px;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.4rem;
  padding: 0.5rem;
`;
