import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { Module, Button, Text } from "components";
import buttonMessages from "components/Button/messages";
import menuMessages from "containers/Menu/messages";
import { ColStyled } from "components/utils";

const CostPerMeal = ({ menus, setAdditionalCost, withAdditionalCost, conversionRate, localCurrency }) => {
  const intl = useIntl();

  const [defaultCurrency, setCurrency] = useState("USD");

  const onAdditionalCosts = useCallback(() => {
    setAdditionalCost(!withAdditionalCost);
  }, [setAdditionalCost, withAdditionalCost]);

  const onChangeCurrency = useCallback(() => {
    setCurrency(defaultCurrency === "USD" ? localCurrency : "USD");
  }, [defaultCurrency, localCurrency]);

  const renderCostByCurrency = useCallback(
    (cost, cost_without_additionals) => {
      const totalCost = withAdditionalCost ? cost : cost_without_additionals;
      return (defaultCurrency === "USD" ? totalCost : totalCost * conversionRate).toFixed(3);
    },
    [defaultCurrency, conversionRate, withAdditionalCost],
  );

  return (
    <>
      {menus && (
        <Module
          title={intl.formatMessage(menuMessages.costPerMeal)}
          elementsOnRight={
            <ColStyled xs className="end-xs" margin="0 0 5px">
              <Button
                kind="ghost"
                widthAuto
                small
                onClick={onAdditionalCosts}
                children={
                  withAdditionalCost
                    ? intl.formatMessage(buttonMessages.excludeAdditional)
                    : intl.formatMessage(buttonMessages.includeAdditional)
                }
              />
              <Button
                small
                kind="ghost"
                widthAuto
                onClick={onChangeCurrency}
                children={intl.formatMessage(buttonMessages.convert, {
                  currency: defaultCurrency === "USD" ? localCurrency : "USD",
                })}
              />
            </ColStyled>
          }
        >
          <Module.ContentWithBorder menus={menus}>
            {({ cost, cost_without_additionals }) => (
              <Text
                fontSize="28px"
                value={`${defaultCurrency} ${renderCostByCurrency(cost, cost_without_additionals)}`}
              />
            )}
          </Module.ContentWithBorder>
        </Module>
      )}
    </>
  );
};

export default CostPerMeal;
