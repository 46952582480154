import { useEffect, useMemo, useState, useCallback } from "react";
import * as R from "ramda";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "@wfp/ui";

import { Button, Text, Select } from "components";
import { ModalWithoutFooter } from "components/UIKitStyled";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { selectMealCount } from "containers/Menu/selectors";
import { selectLocalCurrency, selectRateFromUSD } from "containers/App/selectors";
import { saveInResultsPdf } from "containers/PDF/actions";
import resultsMessages from "containers/Results/messages";
import { getPercentageByTotal, getCosts } from "containers/Results/utils";
import { useBoolean } from "hooks";
import { USD } from "utils/constants";
import { options as mainCostsOptions } from "./constants";
import MainCostsList from "./MainCostsList";
import { FoodInfoContainer, CostsContainer } from "./styles";

const FoodInfoAndCosts = ({ menuComposition, currency, setCurrency, menuScores, enabledMenuScoreFeature }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const mealCount = useSelector(selectMealCount);
  const localCurrency = useSelector(selectLocalCurrency);
  const conversionRate = useSelector(selectRateFromUSD);

  const itemsNumber = useMemo(
    () => Object.keys(R.groupBy(({ display_name }) => display_name, menuComposition)).length,
    [menuComposition]
  );

  const foodGroupsNumber = useMemo(
    () => Object.keys(R.groupBy(({ major_group }) => major_group, menuComposition)).length,
    [menuComposition]
  );

  const costs = useMemo(() => getCosts(menuComposition), [menuComposition]);

  const options = useMemo(() => [USD, { label: localCurrency, value: localCurrency }], [localCurrency]);
  const useCurrency = useMemo(() => (R.equals(USD, currency) ? 1 : conversionRate), [conversionRate, currency]);

  const renderByKey = useCallback(
    (key, conversionRate, noCurrencyLabel = false) => {
      if (R.isNil(conversionRate) || mealCount === 0) return `${currency.value === USD.value ? "$" : currency.value} 0`;
      return `${noCurrencyLabel ? "$" : currency.value === USD.value ? "$" : currency.value} ${(
        (costs[key] / mealCount) *
        conversionRate
      ).toFixed(4)}`;
    },
    [currency.value, mealCount, costs]
  );

  const renderCost = useMemo(() => renderByKey("cost", useCurrency), [renderByKey, useCurrency]);
  const renderWithoutAdditional = useMemo(() => renderByKey("costWithoutAdd", useCurrency), [renderByKey, useCurrency]);
  const averageMenuScore = useMemo(() => {
    if (R.isNil(menuScores) || R.isEmpty(menuScores)) return "0";
    return (Object.values(menuScores).reduce((acc, cur) => acc + cur, 0) / mealCount).toFixed(4);
  }, [menuScores, mealCount]);

  // Save this information in redux to display it in the PDF.
  useEffect(() => {
    dispatch(
      saveInResultsPdf({
        numbers: [
          { label: <FormattedMessage {...menuMessages.foodGroups} />, value: foodGroupsNumber },
          { label: <FormattedMessage {...menuMessages.foodItems} />, value: itemsNumber },
        ],
      })
    );
  }, [dispatch, foodGroupsNumber, itemsNumber]);

  // Save this information in redux to display it in the PDF.
  useEffect(() => {
    dispatch(
      saveInResultsPdf({
        costs: [
          {
            total: renderByKey("cost", 1, true),
            withoutAdditional: renderByKey("costWithoutAdd", 1, true),
            currency: USD.label,
          },
          {
            total: renderByKey("cost", conversionRate, true),
            withoutAdditional: renderByKey("costWithoutAdd", conversionRate, true),
            currency: localCurrency,
          },
        ],
      })
    );
  }, [conversionRate, currency, dispatch, localCurrency, renderByKey]);

  const [showModal, { toggle: toggleShowModal }] = useBoolean(false);
  const onChangeStatusModal = useCallback(() => toggleShowModal(), [toggleShowModal]);

  const [mainCostType, setMainCostType] = useState(mainCostsOptions(intl)[0]);

  const mainCosts = useMemo(() => {
    if (!costs || !menuComposition) return [[]];
    const keysToCycle = R.groupBy((item) => item[mainCostType.value], menuComposition);
    const mainCostObject = Object.keys(keysToCycle).reduce(
      (acc, item) => ({
        ...acc,
        [item]: getPercentageByTotal(keysToCycle[item], "price", costs.cost),
      }),
      {}
    );

    const orderValues = R.sortWith(
      [R.descend(R.prop("value"))],
      Object.keys(mainCostObject).map((key) => ({ label: key, value: mainCostObject[key] }))
    );
    return orderValues.map((el) => ({ ...el, value: `${el.value.toFixed(2)}%` }));
  }, [mainCostType.value, costs, menuComposition]);

  return (
    <>
      <CostsContainer>
        <RowStyled center="xs" middle="xs" padding="10px" height="100%">
          <ColStyled xs padding="0">
            <Text
              data-test-id="menu-cost"
              fontSize="26px"
              defaultColor={colors["support-02"].hex}
              bold
              value={renderCost}
            />
            <RowStyled center="xs" middle="xs" margin="10px 0 10px">
              <ColStyled xs={6}>
                <Text
                  fontSize="14px"
                  defaultColor={colors["text-02"].hex}
                  bold
                  regularWrap
                  transform="uppercase"
                  value={intl.formatMessage(resultsMessages.averageCostPerMeal)}
                />
              </ColStyled>
              <ColStyled xs={6}>
                <Select
                  wfpStyle
                  defaultValue={currency}
                  key={currency.value}
                  options={options}
                  onChange={setCurrency}
                />
              </ColStyled>
            </RowStyled>
            <Text
              data-test-id="menu-cost-without-additional"
              regularWrap
              fontSize="14px"
              defaultColor={colors["text-02"].hex}
              marginBottom="10px"
              value={intl.formatMessage(resultsMessages.excludingAdditional, {
                price: renderWithoutAdditional,
              })}
            />
            {enabledMenuScoreFeature && (
              <Text
                data-test-id="menu-average-score"
                regularWrap
                fontSize="14px"
                bold
                defaultColor={colors["text-02"].hex}
                marginBottom="10px"
                value={intl.formatMessage(resultsMessages.menuAverageScore, {
                  score: averageMenuScore,
                })}
              />
            )}

            <MainCostsList mainCosts={mainCosts} mainCostType={mainCostType} setMainCostType={setMainCostType} />

            <Button widthAuto kind="ghost" marginTop="10px" marginBottom="5px" onClick={onChangeStatusModal}>
              <FormattedMessage {...resultsMessages.viewFullPrice} />
            </Button>
          </ColStyled>
        </RowStyled>
      </CostsContainer>

      <FoodInfoContainer>
        <RowStyled center="xs" middle="xs" padding="20px" height="100%">
          <ColStyled xs={6}>
            <Text
              data-test-id="food-groups-number"
              fontSize="32px"
              defaultColor={colors["text-02"].hex}
              value={foodGroupsNumber}
            />
            <Text
              fontSize="14px"
              defaultColor={colors["text-02"].hex}
              bold
              regularWrap
              transform="uppercase"
              value={<FormattedMessage {...menuMessages.foodGroups} />}
            />
          </ColStyled>
          <ColStyled xs={6}>
            <Text
              data-test-id="food-items-number"
              fontSize="32px"
              defaultColor={colors["text-02"].hex}
              value={itemsNumber}
            />
            <Text
              fontSize="14px"
              defaultColor={colors["text-02"].hex}
              bold
              regularWrap
              transform="uppercase"
              value={<FormattedMessage {...menuMessages.foodItems} />}
            />
          </ColStyled>
        </RowStyled>
      </FoodInfoContainer>

      <ModalWithoutFooter open={showModal} onRequestClose={onChangeStatusModal}>
        <ColStyled margin="20px 0 0 0">
          <MainCostsList inModal mainCosts={mainCosts} mainCostType={mainCostType} setMainCostType={setMainCostType} />
        </ColStyled>
      </ModalWithoutFooter>
    </>
  );
};

export default FoodInfoAndCosts;
