import { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, Button } from "components";
import { SliderHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { useInitialValues } from "containers/GeneralInfo/useInitialValues";
import { mappingUnit } from "utils/constants";
import { fixDecimals } from "utils/utils";
import messages from "../messages";
import ModalNutrient from "./ModalNutrient";

const Nutrient = ({ nutrient, index, name, disable }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const percentage = useWatch({ control, name: `${name}[${index}].percentage` });

  const [statusModal, setStatusModal] = useState();
  const [warning, setWarning] = useState();

  const initialValues = useInitialValues();

  const sliderColor = useMemo(() => {
    const { criticAbove, infeasibleAbove } = nutrient;

    if (infeasibleAbove <= percentage) {
      setWarning(intl.formatMessage(messages.warningCritic, { value: nutrient.label }));
      return colors["support-01"].hex;
    } else if (criticAbove <= percentage && percentage <= infeasibleAbove) {
      setWarning(intl.formatMessage(messages.warningInfeasible, { value: nutrient.label }));
      return colors["support-03"].hex;
    }

    setWarning("");
    return null;
  }, [intl, nutrient, percentage]);

  const absoluteValue = useMemo(
    () => fixDecimals((nutrient.lowerBound * percentage) / 100),
    [nutrient.lowerBound, percentage],
  );

  return (
    <>
      <div
        className="wfp--module__inner nutritionModule formItem"
        style={{ boxShadow: "0px 1px 4px rgb(0 0 0 / 5%), 0px 25px 30px -15px rgb(0 0 0 / 10%)" }} // value copied from .wfp--module__inner's default box-shadow value
      >
        <RowStyled end="xs" className={`${disable ? "disable" : ""}`}>
          <Button
            widthAuto
            kind="secondary"
            disabled={disable}
            children={intl.formatMessage(messages.advancedSetting)}
            onClick={() => setStatusModal(true)}
          />
        </RowStyled>
        <RowStyled bottom="xs">
          <ColStyled xs={10} lg={11} margin="20px 0 0">
            <SliderHookForm
              control={control}
              name={`${name}[${index}].percentage`}
              id={`${name}[${index}].percentage`}
              disabled={disable}
              labelText={nutrient.label}
              color={sliderColor}
            />
          </ColStyled>
          <ColStyled xs={2} lg={1}>
            <Text
              bold
              fontSize="16px"
              textAlign="center"
              className={`${disable ? "disable" : ""}`}
              value={`${mappingUnit[nutrient.unit]} ${absoluteValue}`}
            />
          </ColStyled>
        </RowStyled>
        {statusModal && (
          <ModalNutrient
            {...nutrient}
            initialValues={initialValues}
            open={statusModal}
            setStatusModal={setStatusModal}
          />
        )}
        {warning && <Text defaultColor={sliderColor} value={warning} />}
      </div>
    </>
  );
};

export default Nutrient;
