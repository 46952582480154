import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "myMenus.header",
    defaultMessage: "My Menus",
  },
  subHeader: {
    id: "myMenus.subHeader",
    defaultMessage:
      "A list of the menus you have created: from here you can view, edit, compare and clone them. {link}",
  },
  targetColumn: {
    id: "myMenus.targetColumn",
    defaultMessage: "Target",
  },
  costColumn: {
    id: "myMenus.costColumn",
    defaultMessage: "Cost per meal",
  },
  typeColumn: {
    id: "myMenus.typeColumn",
    defaultMessage: "Type",
  },
  updatedColumn: {
    id: "myMenus.updatedColumn",
    defaultMessage: "Last updated",
  },
  nameColumn: {
    id: "myMenus.nameColumn",
    defaultMessage: "Name",
  },
  modeTitle: {
    id: "myMenus.modeTitle",
    defaultMessage: "Mode",
  },
  statusTitle: {
    id: "myMenus.statusTitle",
    defaultMessage: "Status",
  },
  regionTitle: {
    id: "myMenus.regionTitle",
    defaultMessage: "Region",
  },
  targetTitle: {
    id: "myMenus.targetTitle",
    defaultMessage: "Target",
  },
  dateRangeTitle: {
    id: "myMenus.dateRangeTitle",
    defaultMessage: "Date range",
  },
  dateRangeLabel: {
    id: "myMenus.dateRangeLabel",
    defaultMessage: "Select date range for menu creation",
  },
  compareWarningTitle: {
    id: "myMenus.compareWarningTitle",
    defaultMessage: "Nutrition needs differ by age and gender.",
  },
  compareWarningSubtitle: {
    id: "myMenus.compareWarningSubtitle",
    defaultMessage: "For an accurate comparison, we filtered menus created for {target}.",
  },
  comparePlaceholder: {
    id: "myMenus.comparePlaceholder",
    defaultMessage: "Add another menu to compare. You can add up to 3 more menus.",
  },
  noMenusFirst: {
    id: "myMenus.noMenusFirst",
    defaultMessage: 'Create your first menu by clicking on "Create a new menu"',
  },
  noMenusSecond: {
    id: "myMenus.noMenusSecond",
    defaultMessage: 'If you don\'t know how to use PLUS, click on "Need help?"',
  },
  genericError: {
    id: "error.genericError",
    defaultMessage: "Error",
  },
  onlyOneMenuToCompare: {
    id: "myMenus.onlyOneMenuToCompare",
    defaultMessage: "There is no other menu to compare with this one",
  },
  receivedFrom: {
    id: "myMenus.receivedFrom",
    defaultMessage: "RECEIVED FROM:",
  },
  sentTo: {
    id: "myMenus.sentTo",
    defaultMessage: "SENT TO:",
  },
  sentMenuTo: {
    id: "myMenus.sentMenu",
    defaultMessage: "You sent this menu to:",
  },
});
