import React from "react";
import { colors } from "@wfp/ui";

export const Supplements = ({ size, disable }) => (
  <svg
    width={size || "22px"}
    height={size || "22px"}
    viewBox="0 0 14 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-171.000000, -529.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(171.000000, 528.000000)">
          <g transform="translate(0.000000, 0.543328)">
            <path d="M1.78843422,0.654588519 L1.78843422,5.22128008 L3.31066474,5.22128008 L3.31066474,5.24365388 C3.33929395,5.23188437 3.36945175,5.22434895 3.40020772,5.22128008 C3.41015148,5.22080903 3.42011161,5.22080903 3.43005537,5.22128008 L10.9218173,5.22128008 L10.9591269,5.22128008 L10.9740507,5.22128008 L12.4440479,5.22128008 L12.4440479,0.654588519 L1.78843422,0.654588519 Z M3.31066474,5.8555428 L3.31066474,7.25092077 C3.31066474,8.54843425 0.281127532,8.50609356 0.281127532,10.5192392 L0.266203704,21.9508848 L13.9662784,21.9508848 L13.9513546,10.5192392 C13.9513546,8.50609356 10.9218173,8.54843425 10.9218173,7.25092077 L10.9218173,5.87046663 L3.43005537,5.87046663 C3.3896843,5.8731294 3.3491788,5.86806621 3.31066474,5.8555428 Z M8.58623817,8.94477532 C8.59369386,8.94451645 8.60115628,8.94451645 8.60861197,8.94477532 C8.7363137,8.93134334 8.85981027,8.9949751 8.92299347,9.10676075 C8.98617667,9.21854641 8.97699761,9.35716873 8.89962663,9.45964741 L5.97455622,13.5114717 L8.89216471,13.5114717 C9.01451779,13.5105044 9.12687296,13.578885 9.18222532,13.6880057 C9.23757768,13.7971264 9.22638572,13.9281772 9.15333172,14.0263318 L5.85516559,18.5855734 C5.7500742,18.7298119 5.54795235,18.761547 5.4037138,18.6564556 C5.25947525,18.5513642 5.2277402,18.3492424 5.33283159,18.2050038 L8.26537585,14.1531963 L5.34030544,14.1531963 C5.22057366,14.1521447 5.11128761,14.084822 5.05649103,13.9783601 C5.00169446,13.8718981 5.01041511,13.7438367 5.07913844,13.6457861 L8.37730457,9.0865517 C8.4247759,9.01285121 8.50021398,8.96166108 8.58623817,8.94477532 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
