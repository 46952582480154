import { colors } from "@wfp/ui";
import { useIntl } from "react-intl";

import { Text } from "components";
import messages from "../messages";
import Nutrient from "./Nutrient";
import SetSamePercentage from "./SetSamePercentage";

const Nutrients = ({ nutrients = [], type, disable }) => {
  const intl = useIntl();

  return (
    <>
      <Text bold fontSize="18px" value={intl.formatMessage(messages[`${type}Title`])} />
      <Text
        fontSize="12px"
        defaultColor={colors["text-02"].hex}
        marginBottom="10px"
        value={intl.formatMessage(messages[`${type}Info`])}
      />
      <SetSamePercentage disable={disable} type={type} />
      <div className="paddingTopSmall">
        {nutrients.map((nutrient, index) => (
          <Nutrient key={nutrient.id} disable={disable} name={`${type}s`} index={index} nutrient={nutrient} />
        ))}
      </div>
    </>
  );
};

export default Nutrients;
