import { createReducer } from "@reduxjs/toolkit";
import { differenceInMonths } from "date-fns";

import {
  loadStarted,
  loadEnded,
  loadSmallStarted,
  loadSmallEnded,
  loadAccountSuccess,
  setSelectedCountry,
  loadPriceSourcesSuccess,
  loadConstantsSuccess,
  loadRegionsSuccess,
  loadCurrencySuccess,
  setAlert,
  clearAlert,
  loadAvailableNutrientsSuccess,
  loadFoodSourcesSuccess,
  loadAllNutritionalValuesStarted,
  loadAllNutritionalValuesSuccess,
  loadFoodItemsSuccess,
  loadingRegionsStarted,
  loadingRegionsEnded,
  loadGlobalFeaturesSuccess,
  loadGeneralAppInfoStarted,
  loadGeneralAppInfoEnded,
} from "./actions";
import { LANGUAGES } from "./Header/Languages/utils";

export const initialState = {
  loading: false,
  loadingSmall: false,
  loadingRegions: false,
  account: null,
  error: false,
  selectedCountry: null,
  languages: LANGUAGES,
  globalFeatures: null,
  // We default this to true to wait for the app to load the general info first
  loadingGeneralAppInfo: true,
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadSmallStarted.type]: (state, action) => {
    state.loadingSmall = action.payload.email;
  },
  [loadSmallEnded.type]: (state) => {
    state.loadingSmall = false;
  },
  [loadAccountSuccess.type]: (state, action) => {
    const { user } = action.payload;
    const showContinueWith = differenceInMonths(new Date(), new Date(user.last_login)) < 6 && user.profile;
    state.account = user;
    if (
      (showContinueWith?.country !== state.selectedCountry?.country) ||
      !state.selectedCountry
    ) {
      state.selectedCountry = showContinueWith;
      state.currency = null;
    }
  },
  [setSelectedCountry.type]: (state, action) => {
    state.selectedCountry = action.payload;
  },
  [loadConstantsSuccess.type]: (state, action) => {
    state.constants = action.payload.constants;
  },
  [loadCurrencySuccess.type]: (state, action) => {
    state.currency = action.payload.currency;
  },
  [loadRegionsSuccess.type]: (state, action) => {
    state.regions = action.payload.regions;
  },
  [loadPriceSourcesSuccess.type]: (state, action) => {
    state.priceSources = action.payload.priceSources;
  },
  [setAlert.type]: (state, action) => {
    state.error = action.payload || true;
  },
  [clearAlert.type]: (state) => {
    state.error = false;
  },
  [loadAvailableNutrientsSuccess.type]: (state, action) => {
    state.availableNutrients = action.payload.nutrients;
  },
  [loadFoodSourcesSuccess.type]: (state, action) => {
    state.constants = { ...state.constants, food_source_choices: action.payload.foodSources };
  },
  [loadAllNutritionalValuesStarted.type]: (state) => {
    state.loadingAllNutritionalValues = true;
  },
  [loadAllNutritionalValuesSuccess.type]: (state, action) => {
    state.loadingAllNutritionalValues = false;
    state.nutritionalValues = action.payload.nutritionalValues;
  },
  [loadFoodItemsSuccess.type]: (state, action) => {
    state.foodItems = action.payload.foodItems.map((el) => ({ value: el.id, label: el.display_name }));
  },
  [loadingRegionsStarted.type]: (state) => {
    state.loadingRegions = true;
  },
  [loadingRegionsEnded.type]: (state) => {
    state.loadingRegions = false;
  },
  [loadGlobalFeaturesSuccess.type]: (state, action) => {
    state.globalFeatures = action.payload.globalFeatures;
  },
  [loadGeneralAppInfoStarted.type]: (state) => {
    state.loadingGeneralAppInfo = true;
  },
  [loadGeneralAppInfoEnded.type]: (state) => {
    state.loadingGeneralAppInfo = false;
  },
});
