import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import routesPath from "containers/App/Routes/paths";
import { Optimized } from "containers/CreateNewMenu/Icons";
import { OPTIMAL, SUBOPTIMAL, RUNNING_SUBOPTIMAL } from "containers/MyMenus/labels";
import { useHtmlHook } from "hooks";
import { Title, Sentence } from "./components";
import messages from "./messages";
import { AnimatedIcon } from "./styles";

const Running = ({ menuId, internalStatus, withoutSourcing }) => {
  const history = useHistory();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const isOptimal = useMemo(() => internalStatus === OPTIMAL, [internalStatus]);
  const isSubOptimal = useMemo(() => internalStatus === SUBOPTIMAL, [internalStatus]);
  const isRunningSubOptimal = useMemo(() => internalStatus === RUNNING_SUBOPTIMAL, [internalStatus]);

  useEffect(() => {
    if ((isOptimal || isSubOptimal) && !withoutSourcing) history.push(routesPath.results.replace(":id", menuId));
  }, [history, isOptimal, isSubOptimal, menuId, withoutSourcing]);

  return (
    <>
      <AnimatedIcon isRTL={isRTL}>
        <Optimized size="215" />
      </AnimatedIcon>
      <Title value={intl.formatMessage(messages.defaultTitle)} />
      <Sentence
        textAlign="center"
        value={intl.formatMessage(isRunningSubOptimal ? messages.subTitleSuboptimal : messages.subTitleDefault)}
      />
      <Sentence
        textAlign="center"
        value={intl.formatMessage(isRunningSubOptimal ? messages.sentenceSuboptimal : messages.sentenceDefault)}
      />
    </>
  );
};

export default Running;
