import { useMemo, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { colors } from "@wfp/ui";
import useSize from "@react-hook/size";

import { Text } from "components";
import { NutritionalGraph } from "components/ChartsNivo";
import { RowStyled } from "components/utils";
import messages from "containers/Menu/nutMessages";
import { selectMenuOptimizationType } from "containers/Menu/selectors";
import { MODIFIED, OPTIMAL, OPTIMIZED } from "containers/MyMenus/labels";
import { saveInResultsPdf } from "containers/PDF/actions";
import { useGraphValues, useNutrientValues } from "hooks";
import { NutritionalValuesContainer, GraphForPDF } from "./styles";

const NutritionalValues = ({ values, specificDay }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const type = useSelector(selectMenuOptimizationType);

  const $section = useRef();
  const [sectionWidth] = useSize($section);

  const isOptimizedOrModified = useMemo(() => [OPTIMAL, MODIFIED, OPTIMIZED].includes(type), [type]);

  const graphValues = useGraphValues({ items: values, singleDay: false });
  const nutrientValues = useNutrientValues({ items: values, singleDay: false });

  const requirementsNotMet = useMemo(
    () => graphValues.every(({ percentage, restriction }) => percentage >= restriction),
    [graphValues],
  );

  useEffect(() => {
    if (!specificDay) {
      dispatch(saveInResultsPdf({ nutrients: graphValues, reqNotMet: !requirementsNotMet && isOptimizedOrModified }));
    }
  }, [dispatch, graphValues, isOptimizedOrModified, requirementsNotMet, specificDay]);

  return (
    <>
      <NutritionalValuesContainer ref={$section}>
        <RowStyled center="xs" middle="xs" padding="20px 0 0">
          <Text
            bold
            regularWrap
            defaultColor={colors["text-02"].hex}
            value={intl.formatMessage(messages.averageValues)}
          />
        </RowStyled>
        <NutritionalGraph
          graphValues={graphValues}
          nutrientValues={nutrientValues}
          targetByRestriction={isOptimizedOrModified}
          width={sectionWidth}
        />
      </NutritionalValuesContainer>
      {!specificDay && (
        <GraphForPDF className="visuallyHidden">
          <NutritionalGraph
            id="nutritional-values"
            graphValues={graphValues}
            nutrientValues={nutrientValues}
            targetByRestriction={isOptimizedOrModified}
            width={sectionWidth}
          />
        </GraphForPDF>
      )}
    </>
  );
};

export default NutritionalValues;
