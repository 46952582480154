import { createReducer } from "@reduxjs/toolkit";

import { loadStarted, loadEnded, loadSuccess, clearCompare } from "./actions";

export const initialState = {
  loading: false,
  info: [],
  diversity: [],
  nutritions: [],
  sourcing: [],
  error: "",
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadSuccess.type]: (state, action) => ({ ...state, ...action.payload }),
  [clearCompare.type]: () => initialState,
});
