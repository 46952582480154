import { defineMessages } from "react-intl";

export default defineMessages({
  defaultTitle: {
    id: "summary.defaultTitle",
    defaultMessage: "Your menu is being prepared",
  },
  subTitleDefault: {
    id: "summary.subTitleDefault",
    defaultMessage: "This calculation can take up to 1 minute.",
  },
  sentenceDefault: {
    id: "summary.sentenceDefault",
    defaultMessage: "Wait until the optimization finishes or change settings and run it again.",
  },
  subTitleSuboptimal: {
    id: "summary.subTitleSuboptimal",
    defaultMessage: "A solution meeting your requirements is not feasible.",
  },
  sentenceSuboptimal: {
    id: "summary.sentenceSuboptimal",
    defaultMessage: "We're searching for the best suboptimal solution possible",
  },
  infeasibleTitle: {
    id: "summary.infeasibleTitle",
    defaultMessage: "Oops, looks like your MENU STRUCTURE settings are not possible to accomplish.",
  },
  menuStructureNotMet: {
    id: "summary.menuStructureNotMet",
    defaultMessage:
      "You may have set some contradicting conditions (i.e: Apples 5 days a week & Maximum 4 Fruits a week)",
  },
  goBackAndLink: {
    id: "summary.goBackAndLink",
    defaultMessage: "Go back to the {link} page, review your requirements and try again",
  },
  sourcingNotMet: {
    id: "summary.sourcingNotMet",
    defaultMessage: "Oops! Seems your SOURCING conditions are impossible to meet, considering your other settings.",
  },
  optimalResultsLink: {
    id: "summary.optimalResultsLink",
    defaultMessage: "Alternatively, take a look at the {link} you will obtain if you remove your sourcing conditions.",
  },
  sourcingNutritionNotMet: {
    id: "summary.sourcingNutritionNotMet",
    defaultMessage:
      "Oops! Seems your SOURCING conditions are impossible to meet, and your NUTRITIONAL requirements are TOO HIGH, considering your other conditions.",
  },
  youCanGoBackAndLink: {
    id: "summary.youCanGoBackAndLink",
    defaultMessage: "You can also go back to the {link} page and lower your requirements",
  },
  subOptimalInfo: {
    id: "summary.subOptimalInfo",
    defaultMessage:
      "Nevertheless, PLUS calculated a menu, as nutritious as possible, that you may obtain the {link} if you remove your sourcing conditions. This menu is not meeting all your nutritional requirements but gets as close as possible to that.",
  },
  forSourcing: {
    id: "summary.forSourcing",
    defaultMessage: "For SOURCING, ",
  },
  sourcingSet: {
    id: "summary.sourcingSet",
    defaultMessage: "{sentence}You may have set:",
  },
  setHighMin: {
    id: "summary.setHighMin",
    defaultMessage: "Very high min requirements for {value}",
  },
  setLowMax: {
    id: "summary.setLowMax",
    defaultMessage: "Very low max requirements for {value}",
  },
  setFoodGroups: {
    id: "summary.setFoodGroups",
    defaultMessage: "Very restricting sourcing requirements for {value}",
  },
  menuStructure: {
    id: "compareMenus.menuStructure",
    defaultMessage: "Menu structure",
  },
  results: {
    id: "summary.results",
    defaultMessage: "PLUS Calculated a Menu",
  },
  sourcing: {
    id: "step.sourcing",
    defaultMessage: "Sourcing",
  },
});
