import styled from "styled-components";
import { colors, StepNavigationItem as NavigationItem, FormWizard } from "@wfp/ui";

const MyFormWizard = ({ backgroundColor, padding, ...props }) => <FormWizard {...props} />;

export const FormWizardHorizontal = styled(MyFormWizard)`
  flex-direction: column;
  background-color: var(--background-color);

  .wfp--form-wizard__sidebar {
    background-color: ${colors["ui-02"].hex};
    width: auto;
    margin: 0;
    padding: 20px 0 40px;
  }

  .wfp--step-navigation__nav {
    flex-direction: row;
    width: 670px;
    margin: 0 auto;
  }

  .wfp--form-wizard__content {
    flex: 1;
    border-radius: 0.4rem;
    box-sizing: border-box;

    .wfp--module__inner {
      background-color: #fff;
      border-radius: 0.4rem;

      .wfp--module__content {
        background-color: ${({ backgroundColor }) => backgroundColor};
        padding: ${({ padding }) => padding}; // Fix padding when landing on "Step 2".
      }

      .wfp--module__footer {
        border-top: 0;
        background-color: ${colors["ui-02"].hex};
        padding: 0.8rem 0;

        // Remove left padding of Back button given by class from react-flexbox-grid.
        .start-xs {
          padding-inline-start: 0;
        }

        // Remove right padding of Next/Submit button given by class from react-flexbox-grid.
        .end-xs {
          padding-inline-end: 0;
        }
      }
    }
  }
`;

export const StepNavigationItem = styled(NavigationItem)`
  flex-direction: column;
  flex: 1;
  cursor: default;
  text-align: center;

  &.wfp--step-navigation__nav-item--locked .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["ui-05"].hex};
  }

  &.wfp--step-navigation__nav-item--selected .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["text-01"].hex};
  }

  &.wfp--step-navigation__nav-item--complete .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["text-01"].hex};
    text-decoration: none;
  }

  .wfp--step-navigation__nav-item__indicator {
    margin: 0 0 15px;

    span {
      font-weight: bold;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    &::after {
      display: none;
    }
  }
`;
