import styled from "styled-components";
import { colors } from "@wfp/ui";

const lightTheme = (theme) => theme === "light";

// Starting styling taken from https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/full-width-table
export const Styles = styled.div`
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  table {
    width: 100%;
    border-spacing: 0;
    margin: 1.25rem 0;
    border-collapse: separate;

    thead {
      background-color: ${({ theme }) => (lightTheme(theme) ? "#EDF0F3" : "#515d66")};
    }

    th {
      border-top: ${({ theme }) => (lightTheme(theme) ? `1px solid ${colors["ui-04"].hex}` : "1px solid #7c8993")};
      font-weight: bold;
      font-size: 14px;
      color: ${({ theme }) => (lightTheme(theme) ? colors["text-02"].hex : colors["inverse-01"].hex)};
    }

    th:first-child,
    td:first-child {
      border-left: ${({ theme }) => (lightTheme(theme) ? `1px solid ${colors["ui-04"].hex}` : "1px solid #7c8993")};
    }

    th:last-child,
    td:last-child {
      border-right: ${({ theme }) => (lightTheme(theme) ? `1px solid ${colors["ui-04"].hex}` : "1px solid #7c8993")};
    }

    tr:first-child th:first-child {
      border-top-left-radius: 0.4rem;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 0.4rem;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 0.4rem;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 0.4rem;
    }

    th,
    td {
      border-bottom: ${({ theme }) => (lightTheme(theme) ? `1px solid ${colors["ui-04"].hex}` : "1px solid #7c8993")};
      margin: 0;
      padding: 0.5rem;
      vertical-align: middle;
      width: 1%;
    }

    th > div,
    td > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const NoResults = styled.td.attrs(({ colSpan }) => ({
  colSpan,
}))`
  color: ${({ theme }) => (lightTheme(theme) ? colors["text-02"].hex : colors["inverse-01"].hex)};
`;
