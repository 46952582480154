import { useIntl } from "react-intl";
import * as R from "ramda";
import { View, Text } from "@react-pdf/renderer";
import SpacedInRow from "containers/PDF/SpacedInRow";
import generalStyles from "containers/PDF/styles";
import messages from "containers/Menu/messages";
import Recipe from "./Recipe";
import styles from "./styles";

const Day = ({ week, day, index, daysInWeek, foodItemsList = [], recipesList = [] }) => {
  const intl = useIntl();
  const { dayContainer, dayHeader, baseTag, dayTag } = styles;
  const { fontSize9, row } = generalStyles;

  const orderedList = R.sortWith([R.ascend(R.prop("ordering_value")), R.ascend(R.prop("display_name"))])(foodItemsList);

  return (
    <View
      style={[
        {
          width: `${100 / daysInWeek}%`,
          borderLeft: !index ? 0 : 1,
        },
        dayContainer,
      ]}
    >
      <View style={[row, dayHeader]}>
        {!index && (
          <Text style={[baseTag, dayTag]}>
            {daysInWeek > 5
              ? intl.formatMessage(messages.shortWeek, { week: week + 1 })
              : intl.formatMessage(messages.week, { week: week + 1 })}
          </Text>
        )}
        <Text style={{ width: !index ? "55%" : "100%" }}>{intl.formatMessage(messages.day, { day })}</Text>
      </View>
      {orderedList.map(({ display_name, quantity }) => (
        <SpacedInRow
          key={`row-${display_name}`}
          label={display_name.split(";")[0]}
          value={`${quantity.toFixed()} g`}
          fontSizeStyle={fontSize9}
          heightRow={15}
        />
      ))}
      {recipesList.map((recipe) => (
        <Recipe key={recipe.recipe_id} recipe={recipe} />
      ))}
    </View>
  );
};

export default Day;
