import { colors, Icon } from "@wfp/ui";
import { iconWarning } from "@wfp/icons";

import { Optimized } from "containers/CreateNewMenu/Icons";
import { ContainerIcons } from "../styles";

const IconWarning = () => (
  <ContainerIcons>
    <Optimized size="215" />
    <Icon description="infeasible" icon={iconWarning} height="170" width="170" fill={colors["support-03"].hex} />
  </ContainerIcons>
);

export default IconWarning;
