import { useIntl } from "react-intl";
import { View, Text } from "@react-pdf/renderer";

import generalStyles from "containers/PDF/styles";
import messages from "../messages";
import styles from "./styles";

const Pagination = () => {
  const intl = useIntl();
  const { pagination } = styles;
  const { fontSize10 } = generalStyles;

  const date = intl.formatDate(new Date(), { day: "numeric", month: "long", year: "numeric" });
  return (
    <View fixed style={[pagination, fontSize10]}>
      <Text>{intl.formatMessage(messages.generatedOn, { date })}</Text>
      <Text
        fixed
        render={({ pageNumber, totalPages }) =>
          intl.formatMessage(messages.pagination, { page: pageNumber, total: totalPages })
        }
      />
    </View>
  );
};

export default Pagination;
