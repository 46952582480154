import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Col } from "react-flexbox-grid";

import { Module, Text } from "components";
import { RowStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { seasonality, daysAndWeeks, targeted } from "containers/Menu/utils";
import messages from "../messages";

const BasicSettings = ({ menus }) => {
  const intl = useIntl();

  const filterInfo = useMemo(() => {
    if (!menus) return;
    return menus.map((el) => ({ ...el.preliminary_information, id: el.id }));
  }, [menus]);

  const getRegions = useCallback(
    (regions) => {
      if (!regions) return;
      return regions.length ? regions.join(", ") : intl.formatMessage(menuMessages.wholeCountry);
    },
    [intl],
  );

  return (
    <>
      {filterInfo && (
        <Module title={intl.formatMessage(messages.basicSettings)}>
          <Module.ContentWithBorder menus={filterInfo}>
            {({ days_in_week, week_count, start_month, end_month, target_group_details, regions }) => (
              <Col>
                <Text value={getRegions(regions)} />
                <RowStyled center="xs">
                  <Text value={seasonality({ start_month, end_month }, true, intl)} />
                  <Text value=" | " />
                  <Text value={daysAndWeeks({ days_in_week, week_count }, intl)} />
                </RowStyled>
                <Text value={targeted({ target_group_details })} />
              </Col>
            )}
          </Module.ContentWithBorder>
        </Module>
      )}
    </>
  );
};

export default BasicSettings;
