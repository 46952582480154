import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectAllMenu = () => (state) => R.pathOr(initialState, ["menu"], state);
const selectMenuData = () => (state) => R.pathOr(null, ["menu", "data"], state);

export default () => createSelector(selectMenuData(), (menuState) => menuState);

export const selectMenuLoading = createSelector(selectAllMenu(), (menuState) =>
  R.pathOr(false, ["loading"], menuState),
);

export const selectMenuId = createSelector(selectMenuData(), (menuState) => R.pathOr(null, ["id"], menuState));

export const selectMenuName = createSelector(selectMenuData(), (menuState) => R.pathOr("", ["name"], menuState));

export const selectMenuPreliminaryInformation = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["preliminary_information"], menuState),
);

export const selectMenuNotes = createSelector(selectMenuData(), (menuState) => R.pathOr("", ["notes"], menuState));

export const selectMealCount = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(0, ["preliminary_information", "meal_count"], menuState),
);

export const selectDaysInWeek = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(1, ["preliminary_information", "days_in_week"], menuState),
);

export const selectWeekCount = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(1, ["preliminary_information", "week_count"], menuState),
);

export const selectTargetGroup = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["preliminary_information", "target_group_details"], menuState),
);

export const selectRegions = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["preliminary_information", "regions"], menuState),
);

export const selectMenuSourcingChannels = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["preliminary_information", "sourcing_channels"], menuState),
);

export const selectMenuOptimizationType = createSelector(selectMenuData(), (menuState) =>
  R.pathOr("", ["optimization", "type"], menuState),
);

export const selectMenuTranslatedOptimizationType = createSelector(selectMenuData(), (menuState) =>
  R.pathOr("", ["optimization", "type_translated"], menuState),
);

export const selectMenuType = createSelector(selectMenuData(), (menuState) => R.pathOr(null, ["type"], menuState));

export const selectWithoutSourcing = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["optimization", "without_sourcing"], menuState),
);

export const selectMenuPriceSources = createSelector(selectMenuData(), (menuState) =>
  R.pathOr({}, ["menu_pricing_list"], menuState),
);

export const selectMealNames = createSelector(selectMenuData(), (menuState) =>
  R.pathOr(null, ["meal_names"], menuState),
);

export const selectDuplication = createSelector(selectAllMenu(), (menuState) =>
  R.pathOr(null, ["duplication"], menuState),
);

export const selectMenuParents = createSelector(selectMenuData(), (menuState) => R.pathOr([], ["parents"], menuState));

export const selectMenuChildren = createSelector(selectMenuData(), (menuState) =>
  R.pathOr({}, ["children"], menuState),
);

export const selectMenuBasket = createSelector(selectMenuData(), (menuState) =>
  R.pathOr({}, ["payload", "basket", "food_items"], menuState),
);
