import React, { useCallback, useMemo } from "react";
import { Icon, colors } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { RowStyled, ColStyled } from "components/utils";
import { Text } from "components";
import { FieldNumber } from "components/InputFields";
import { Field, useField } from "react-final-form";
import { getPrices } from "containers/Results/utils";
import { useResponsiveHook } from "hooks";

export default function ItemRow({ name, index, removeItem, values, dayKey }) {
  const { isTabletOrMobile } = useResponsiveHook();
  const {
    input: { value: item, onChange: onChangeItem },
  } = useField(name);

  const onChangeQuantity = useCallback(() => {
    onChangeItem({ ...item, ...getPrices(item) });
  }, [item, onChangeItem]);

  const onClick = useCallback(() => removeItem(index), [index, removeItem]);

  const itemColor = useMemo(
    () => (values?.[dayKey]?.[index]?.is_priority === true ? colors["support-02"].hex : colors["text-01"].hex),
    [dayKey, index, values],
  );

  return (
    <RowStyled middle="xs" margin="5px 0 0">
      <ColStyled xs={isTabletOrMobile ? 5 : 6} padding="0">
        <Field name={`${name}.display_name`}>
          {({ input }) => <Text value={input.value} defaultColor={itemColor} />}
        </Field>
      </ColStyled>
      <ColStyled xs={3}>
        <Field name={`${name}.quantity`} addActionOnChange={onChangeQuantity} allowEmpty component={FieldNumber} />
      </ColStyled>
      <ColStyled xs={1} margin="0 0 0 auto">
        <Icon className="iconPrimary pointer" description="Remove" icon={iconDelete} onClick={onClick} />
      </ColStyled>
    </RowStyled>
  );
}
