import { defineMessages } from "react-intl";

export default defineMessages({
  location: {
    id: "generalInfo.locationTitle",
    defaultMessage: "Location",
  },
  locationInfo: {
    id: "generalInfo.locationInfo",
    defaultMessage: "Regions where this menu will be served",
  },
  locationAll: {
    id: "generalInfo.locationAll",
    defaultMessage: "-- All --",
  },
  placeholderCountry: {
    id: "generalInfo.placeholderCountry",
    defaultMessage: "Whole country (default)",
  },
  checkboxCountry: {
    id: "generalInfo.checkboxCountry",
    defaultMessage: "Also, use prices that are common for the whole country",
  },
  duration: {
    id: "generalInfo.durationTitle",
    defaultMessage: "Menu duration",
  },
  durationInfo: {
    id: "generalInfo.durationInfoDay",
    defaultMessage: "No. of days in a school week",
  },
  durationInfoWeek: {
    id: "generalInfo.durationInfoWeek",
    defaultMessage: "Number of weeks",
  },
  seasonality: {
    id: "generalInfo.seasonalityTitle",
    defaultMessage: "Seasonality",
  },
  seasonalityInfo: {
    id: "generalInfo.seasonalityInfo",
    defaultMessage: "Months during which this menu will be served",
  },
  targeted: {
    id: "generalInfo.targetedTitle",
    defaultMessage: "Targeted group",
  },
  targetedInfo: {
    id: "generalInfo.targetedInfo",
    defaultMessage: "Define the age group and gender of your targeted group",
  },
  daysAndWeek: {
    id: "generalInfo.daysAndWeek",
    defaultMessage: "{days} days (1 week)",
  },
  daysAndWeeks: {
    id: "generalInfo.daysAndWeeks",
    defaultMessage: "{days} days ({weeks} weeks)",
  },
  yearAround: {
    id: "generalInfo.yearAround",
    defaultMessage: "Year around",
  },
  months: {
    id: "generalInfo.months",
    defaultMessage: "Months",
  },
  foodBasketModalHeaderTitle: {
    id: "generalInfo.foodBasketModalHeaderTitle",
    defaultMessage: "Food basket - ",
  },
  foodBasketModalHeaderGroups: {
    id: "generalInfo.foodBasketModalHeaderGroups",
    defaultMessage: "Food groups: ",
  },
  foodBasketModalHeaderItems: {
    id: "generalInfo.foodBasketModalHeaderItems",
    defaultMessage: "Food items: ",
  },
  addPriceSource: {
    id: "generalInfo.addPriceSource",
    defaultMessage: "Add price source",
  },
  columnAveragePrice: {
    id: "generalInfo.columnAveragePrice",
    defaultMessage: "Average price",
  },
  columnYourAveragePrice: {
    id: "generalInfo.columnYourAveragePrice",
    defaultMessage: "Your average price for this menu",
  },
  foodBasketTitle: {
    id: "generalInfo.foodBasketTitle",
    defaultMessage: "Food basket",
  },
  foodBasketInfo: {
    id: "generalInfo.foodBasketInfo",
    defaultMessage:
      "The food basket comprises the food items for the chosen regions, sourcing channels and seasons with the official price. Contact your Country admin if you have a new food price or don't find your required food item. Meanwhile, you can add you personal prices or food items to the basket.",
  },
  addToThisMenu: {
    id: "generalInfo.addToThisMenu",
    defaultMessage: "Add a food item to this specific menu",
  },
});
