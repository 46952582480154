import React, { useEffect } from "react";
import { push } from "connected-react-router";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, colors } from "@wfp/ui";
import { Text } from "components";
import { selectFirstName, selectIsInactive } from "./selectors";
import messages from "./messages";
import routesPath from "containers/App/Routes/paths";

function InactiveUser({ intl }) {
  const dispatch = useDispatch();
  const firstName = useSelector(selectFirstName);
  const isInactive = useSelector(selectIsInactive);

  useEffect(() => {
    if (!isInactive) dispatch(push(routesPath.menus));
  }, [dispatch, isInactive]);

  return (
    <Wrapper pageWidth="lg" spacing="md">
      <Text
        wrap
        textAlign="center"
        fontSize="20px"
        marginBottom="20px"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.pending, { name: firstName })}
      />
      <Text
        wrap
        fontSize="20px"
        textAlign="center"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.ableToLog)}
      />
      <Text
        wrap
        fontSize="20px"
        textAlign="center"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.moreInfo)}
      />
    </Wrapper>
  );
}

export default injectIntl(InactiveUser);
