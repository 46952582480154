import { colors } from "@wfp/ui";

export const Manual = ({ highlight }) => (
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45 40.5C52.4559 40.5 58.5 34.4558 58.5 27C58.5 19.5442 52.4559 13.5 45 13.5C37.5442 13.5 31.5 19.5442 31.5 27C31.5 34.4558 37.5442 40.5 45 40.5ZM45 45C35.0589 45 27 36.9411 27 27C27 17.0589 35.0589 9 45 9C54.9412 9 63 17.0589 63 27C63 36.9411 54.9412 45 45 45Z"
      fill={highlight ? colors["brand-01"].hex : colors["ui-05"].hex}
    />
    <path
      d="M22.5 80.9992H18V69.836C18 58.6522 27.0662 49.5859 38.25 49.5859C38.2824 49.586 42.8149 49.6077 51.8473 49.6511C62.993 49.7046 72 58.7551 72 69.9008V80.9987H67.5V69.9008C67.5 61.2319 60.4945 54.1927 51.8257 54.151L38.3257 54.0861C29.5515 54.0859 22.5 61.1375 22.5 69.836V80.9992Z"
      fill={highlight ? colors["brand-01"].hex : colors["ui-05"].hex}
    />
  </svg>
);
