import React, { useCallback } from "react";
import { ColStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import { useForm } from "react-final-form";
import { injectIntl } from "react-intl";
import { Icon, colors } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";

function Delete({ field, index, intl }) {
  const form = useForm();

  const onDelete = useCallback(() => form.change(field, undefined), [field, form]);

  return (
    <ColStyled xs={1} padding="5px" className="end-xs">
      {!!index && (
        <Icon
          className="pointer"
          onClick={onDelete}
          icon={iconDelete}
          description={intl.formatMessage(buttonMessages.delete)}
          width="25px"
          height="25px"
          fill={colors["brand-01"].hex}
        />
      )}
    </ColStyled>
  );
}

export default injectIntl(Delete);
