import styled from "styled-components";

import { defaultColumnNumber } from "./constants";

// https://www.youtube.com/watch?v=KrPz_wmBsAE
// Edited Codepen: https://codepen.io/gguidotti/pen/qBVeoYm
export const ItemsContainer = styled.div`
  columns: ${defaultColumnNumber};
  gap: 1em;
  padding: 15px 15px 40px;

  & > * {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
`;

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 0.4rem;
`;
