import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

export const ContainerText = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const ContainerActions = styled.div`
  display: inline-flex;
  align-items: center;

  * + * {
    margin-inline-start: 20px;
  }
`;

export const Title = styled(TextareaAutosize)`
  display: block;
  font-size: 1.75rem;
  outline: none;
  padding: 5px;
  margin-right: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid #8ca4b5;
  pointer-events: "auto";
  resize: none;
  font-family: "Open Sans", sans-serif;

  :focus {
    outline: none;
    box-shadow: 0px 2px 8px 0 #c2dbec;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
