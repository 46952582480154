import styled, { css } from "styled-components";
import { Row } from "react-flexbox-grid";
import { colors } from "@wfp/ui";

export const Label = styled.div`
  margin: ${(props) => props.margin};
  font-size: 14px;
  ${(props) =>
    props.right &&
    css`
      margin-right: 5px !important;
    `}
  ${(props) =>
    props.left &&
    css`
      margin-right: 0 !important;
      margin-left: 5px;
    `}
  color: ${colors["text-02"].hex};
`;

export const RowWithoutMargin = styled(Row)`
  margin: 0;
`;
