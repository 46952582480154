import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@communityform/LOAD_STARTED");
export const loadEnded = createAction("@@communityform/LOAD_ENDED");
export const loadBasketStarted = createAction("@@communityform/LOAD_BASKET_STARTED");
export const loadBasketEnded = createAction("@@communityform/LOAD_BASKET_ENDED");
export const loadFoodBasket = createAction("@@communityform/LOAD_FOOD_BASKET");
export const loadCommunityGeneralInfo = createAction("@@communityform/LOAD_GENERAL_INFO");

export const loadFoodBasketSuccess = createAction("@@communityform/LOAD_FOOD_BASKET_SUCCESS");
export const loadTargetBeneficiariesSuccess = createAction("@@communityform/LOAD_TARGET_BENEFICIARIES_SUCCESS");
export const updateFormState = createAction("@@communityform/UPDATE_FORM_STATE");
export const resetResults = createAction("@@communityform/RESET_RESULTS");
export const loadNutritionalReq = createAction("@@communityform/LOAD_NUTRITIONAL_REQ");
export const saveNutritionalReq = createAction("@@communityform/SAVE_NUTRITIONAL_REQ");
