import { defineMessage } from "react-intl";

export default {
  tag: defineMessage({
    id: "warningLowItems.tag",
    defaultMessage: "Low number of items",
  }),
  title: defineMessage({
    id: "warningLowItems.title",
    defaultMessage: "The food basket has only {numGroups} food groups and {numItems} food items.",
  }),
  info: defineMessage({
    id: "warningLowItems.info",
    defaultMessage:
      "For your menu to reach its nutritional targets, we recommended a larger and more diverse food basket. You can do this by changing your settings or adding price information on the {link} page.",
  }),
};
