import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { setAlert } from "containers/App/actions";
import * as R from "ramda";
import {
  getFoodBasket,
  getTargetBeneficiaries,
  getAvailableNutrientsCommunityMenu,
  getCommunityPartialDiversity,
} from "api/api";
import {
  loadStarted,
  loadEnded,
  loadBasketStarted,
  loadBasketEnded,
  loadFoodBasket,
  loadFoodBasketSuccess,
  saveNutritionalReq,
  loadNutritionalReq,
  loadTargetBeneficiariesSuccess,
  loadCommunityGeneralInfo,
} from "./actions";
import { loadBeneficiariesNutrition } from "containers/OptimizedMenu/Rules/sagas";
import { loadCommunityMenuSaga } from "containers/Menu/sagas";
import { selectCountryCode } from "containers/App/selectors";
import { loadRegionsSaga, loadPricesSourcesSaga, loadCommunityFoodSourcesSaga } from "containers/App/sagas";
import { loadAvailableNutrientsSuccess } from "containers/App/actions";
import { transformForBackend } from "containers/Menu/utils";

export function* loadNutritionalReqSaga({ payload: { id } }) {
  try {
    const { nutritional_requirements } = yield call(loadBeneficiariesNutrition, id);
    yield put(
      saveNutritionalReq({ nutritionalRequirements: R.sortBy((key) => key.nutrient_order, nutritional_requirements) })
    );
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadFoodBasketSaga({ payload: { body } }) {
  try {
    yield put(loadBasketStarted());
    const reworkedBody = transformForBackend(body);
    const foodBasket = yield call(getFoodBasket, reworkedBody);
    yield put(loadFoodBasketSuccess({ foodBasket }));
    yield put(loadBasketEnded());
  } catch (e) {
    yield put(loadBasketEnded());
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadTargetBeneficiariesSaga() {
  try {
    const target = yield call(getTargetBeneficiaries);
    const refactoredTarget = target.map(({ id, name }) => ({ value: id, label: name }));
    yield put(loadTargetBeneficiariesSuccess({ targetBeneficiaries: refactoredTarget }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadAvailableNutrientsSaga() {
  try {
    let nutrients = yield call(getAvailableNutrientsCommunityMenu);

    nutrients = nutrients.map((nutrient) => ({
      nutrient: nutrient.value,
      percentage: Number(nutrient.percentage),
    }));
    nutrients = nutrients.reduce(
      (acc, item) => ({
        ...acc,
        [item.nutrient]: { ...item },
      }),
      {}
    );

    yield put(loadAvailableNutrientsSuccess({ nutrients }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadPartialDiversitySaga(id) {
  try {
    let { nutrient_restrictions: nutrients } = yield call(getCommunityPartialDiversity, id);

    nutrients = nutrients.map((nutrient) => ({
      nutrient: nutrient.nutrient,
      percentage: Number(nutrient.percentage),
    }));
    nutrients = nutrients.reduce(
      (acc, item) => ({
        ...acc,
        [item.nutrient]: { ...item },
      }),
      {}
    );

    yield put(loadAvailableNutrientsSuccess({ nutrients }));
    return nutrients;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCommunityGeneralInfoSaga({ payload: { id } }) {
  try {
    const country = yield select(selectCountryCode);
    yield put(loadStarted());
    yield all([
      !!id && loadCommunityMenuSaga({ id }),
      loadRegionsSaga(),
      loadPricesSourcesSaga(),
      loadTargetBeneficiariesSaga(),
      loadCommunityFoodSourcesSaga(country),
      id ? loadPartialDiversitySaga(id) : loadAvailableNutrientsSaga(),
    ]);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* appSaga() {
  yield takeLatest(loadCommunityGeneralInfo, loadCommunityGeneralInfoSaga);
  yield takeLatest(loadFoodBasket, loadFoodBasketSaga);
  yield takeLatest(loadNutritionalReq, loadNutritionalReqSaga);
}
