import { useMemo, useCallback } from "react";
import { Bar as NivoBar } from "@nivo/bar";

import { newColorByCategory } from "components/utils";
import Bar from "./Bar";
import Tooltip from "./Tooltip";

const NutritionalGraph = ({
  id,
  graphValues,
  nutrientValues,
  hasTooltip = true,
  isInteractive = true,
  targetByRestriction,
  width,
}) => {
  const keys = useMemo(() => ["percentage"], []);

  const margin = useMemo(
    () => ({
      top: 50,
      right: 45,
      bottom: 50,
      left: 45,
    }),
    []
  );

  const axisLeft = useMemo(
    () => ({
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }),
    []
  );

  const barComponent = useCallback(
    (props) => {
      return (
        <Bar
          {...props}
          targetByRestriction={targetByRestriction}
          isInteractive={isInteractive}
        />
      );
    },
    [isInteractive, targetByRestriction]
  );

  const colors = useCallback((bar) => {
    return newColorByCategory[bar.data.category]?.["backgroundColor"];
  }, []);

  const tooltip = useCallback(
    (bar) => {
      return hasTooltip ? (
        <Tooltip bar={bar} nutrientValues={nutrientValues} />
      ) : null;
    },
    [hasTooltip, nutrientValues]
  );

  return (
    <div id={id}>
      <NivoBar
        width={width}
        height={400}
        data={graphValues}
        keys={keys} // Read this property and show corresponding data.
        indexBy="label"
        margin={margin}
        padding={0.15}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={axisLeft}
        minValue={0}
        maxValue={100}
        ariaLabel="Nutritional values"
        barComponent={barComponent}
        colors={colors}
        tooltip={tooltip}
      />
    </div>
  );
};

export default NutritionalGraph;
