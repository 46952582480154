import * as R from "ramda";
import { FormattedMessage } from "react-intl";

import { validateMinAndMax, minGreaterMax } from "utils/errors";
import { FIELDS } from "../constants";
import messages from "../../messages";

const {
  RESTRICTION_TYPE,
  RESTRICTION,
  DAILY_REPETITION,
  WEEKLY_REPETITION,
  VALIDATIONS,
  DAILY_REQUIRED,
  WEEKLY_REQUIRED,
  MAXIMUM_REPETITION,
  MAXIMUM_REQUIRED,
  AMOUNT_RANGE,
  NUMBER_ITEMS_IN_GROUP,
} = FIELDS;

// Major group and minor group key
const TYPE = "food_group_restriction";

// TODO fix the return value of this function.
// It seems like we are just doing a boolen check at the end,
// Maybe we can just return a bool?
export const validation = (name, values, daysInWeek, weekCount) => {
  if (!values[TYPE]) return "";
  const restrictionType= values[TYPE][RESTRICTION_TYPE];
  const dailyRepetition = values[TYPE][DAILY_REPETITION];
  const weeklyRepetition = values[TYPE][WEEKLY_REPETITION];
  const validations = values[VALIDATIONS];
  const restrictionId = values[TYPE]["id"];
  const maxRepetition = values[TYPE][MAXIMUM_REPETITION];
  const amountRange = values[TYPE][AMOUNT_RANGE];
  const dailyMax = validations ? validations[NUMBER_ITEMS_IN_GROUP] : undefined;
  const minMaxRepetition = validations ? validations[MAXIMUM_REQUIRED] : undefined;

  if (dailyRepetition && validateMinAndMax(dailyRepetition)) {
    return { [RESTRICTION]: { [DAILY_REPETITION]: [minGreaterMax] } };
  }

  if (
    dailyRepetition &&
    validations &&
    validations[DAILY_REQUIRED] &&
    (dailyRepetition[0] > validations[DAILY_REQUIRED][1] || dailyRepetition[1] > validations[DAILY_REQUIRED][1])
  ) {
    return {
      title: <FormattedMessage {...messages.invalidDailyRepetition} values={{ group: name }} />,
      info: (
        <FormattedMessage
          {...messages.infoRepetitionValidation}
          values={{ value: validations[DAILY_REQUIRED].join(", ") }}
        />
      ),
      fixValues: true,
    };
  }

  if (
    dailyRepetition &&
    validations &&
    validations[DAILY_REQUIRED] &&
    dailyRepetition[0] < validations[DAILY_REQUIRED][0]
  ) {
    return {
      title: <FormattedMessage {...messages.invalidDailyRepetition} values={{ group: name }} />,
      info: (
        <FormattedMessage
          {...messages.infoRepetitionValidation}
          values={{ value: validations[DAILY_REQUIRED].join(", ") }}
        />
      ),
    };
  }

  if (
    weeklyRepetition &&
    validations &&
    validations[WEEKLY_REQUIRED] &&
    (weeklyRepetition[0] > validations[WEEKLY_REQUIRED][1] || weeklyRepetition[1] > validations[WEEKLY_REQUIRED][1])
  ) {
    return {
      title: <FormattedMessage {...messages.invalidWeeklyRepetition} values={{ group: name }} />,
      info: (
        <FormattedMessage
          {...messages.infoRepetitionValidation}
          values={{ value: validations[WEEKLY_REQUIRED].join(", ") }}
        />
      ),
      fixValues: true,
    };
  }

  if (
    !R.isNil(maxRepetition) &&
    maxRepetition !== "" &&
    !R.isNil(minMaxRepetition) &&
    maxRepetition < minMaxRepetition
  ) {
    return {
      title: <FormattedMessage {...messages.maxRepetitionValidation} values={{ group: name, value: maxRepetition }} />,
      info: <FormattedMessage {...messages.infoRepetitionValidation} values={{ value: minMaxRepetition }} />,
    };
  }

  if (dailyRepetition && !R.isNil(maxRepetition) && maxRepetition !== "" && !R.isNil(minMaxRepetition)) {
    // Max repetition's min and max match the min and max defined for the input component in GroupRestriction/index.js
    const calcMaxRepetition = Math.ceil(((daysInWeek * weekCount) / (dailyMax || 1)) * dailyRepetition[0]);
    const maxRepetitionLowerLimit = Math.max(minMaxRepetition, calcMaxRepetition, 1);
    const maxRepetitionUpperLimit = Math.max(daysInWeek * weekCount, Math.max(minMaxRepetition, calcMaxRepetition));

    if (
      validateMinAndMax([maxRepetitionLowerLimit, maxRepetition]) ||
      validateMinAndMax([maxRepetition, maxRepetitionUpperLimit])
    ) {
      return minGreaterMax;
    }
  }

  if (amountRange && validateMinAndMax(amountRange)) {
    return minGreaterMax;
  }

  if (!restrictionId && restrictionType === "no-rule") {
    return [true];
  }

  return "";
};
