import * as R from "ramda";

// Returns "true" if button will be disabled
export const validation = (initialItem, draftItem) => {
  // Removing "id" property from draftItem before checking for equality.
  if (R.equals(initialItem, R.dissoc("id", draftItem))) {
    return true;
  }

  return false;
};
