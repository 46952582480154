import { format, subYears } from "date-fns";

import messages from "./messages";

export const FIELDS = {
  FILTERS: "filters",
  SEARCH: "search",
  ORDERING: "ordering",
  UNIT: "quantity",
  CURRENCY: "price_currency",
  REGION: "region",
  REGIONS: "regions",
  MONTH: "month",
  YEAR: "year",
  DATE_FROM: "date_from",
  DATE_TO: "date_to",
  CREATED_AFTER: "created_after",
  DATA_SOURCE: "data_source",
  PRICE_DATA_ORIGINS: "data_origin",
  PROCUREMENT_SOURCES: "source",
  NUTRIENTS: "nutrients",
  PERSONAL_ITEM: "personal_item",
  ITEM: "item",
  PRICE: "price",
  ORIGIN: "origin",
  PRICE_STATUS: "price_status",
  PROPOSED: "proposed",
  ADDITIONAL_COSTS: "additional_costs",
  TYPE: "type",
};

export const INITIAL_VALUES = {
  [FIELDS.FILTERS]: {
    [FIELDS.SEARCH]: "",
    [FIELDS.UNIT]: "1 kg",
    [FIELDS.CURRENCY]: "USD",
    [FIELDS.ORDERING]: "updated_timestamp",
    [FIELDS.REGIONS]: [],
    [FIELDS.DATA_SOURCE]: [],
    [FIELDS.PRICE_DATA_ORIGINS]: [],
    [FIELDS.PROCUREMENT_SOURCES]: [],
    [FIELDS.DATE_FROM]: null,
    [FIELDS.DATE_TO]: null,
  },
  [FIELDS.PERSONAL_ITEM]: {
    [FIELDS.ITEM]: [],
    [FIELDS.CURRENCY]: "USD",
    [FIELDS.UNIT]: "1 kg",
    [FIELDS.PRICE]: "",
    [FIELDS.PROCUREMENT_SOURCES]: [],
    [FIELDS.ORIGIN]: [],
    [FIELDS.PROPOSED]: false,
    [FIELDS.ADDITIONAL_COSTS]: [],
    [FIELDS.MONTH]: Number(format(new Date(), "M")),
    [FIELDS.YEAR]: Number(format(new Date(), "YYY")),
  },
};

export const ADDITIONAL_COSTS_INITIAL_VALUES = {
  [FIELDS.TYPE]: [],
  [FIELDS.PRICE]: "",
  [FIELDS.UNIT]: [],
};

export const PROPOSED = "proposed";
export const OFFICIAL = "official";

export const getYearsOptions = (intl) => [
  { value: null, label: intl.formatMessage(messages.optionAll) },
  { value: format(subYears(new Date(), 1), "YYY-MM-dd"), label: intl.formatMessage(messages.optionOne) },
  {
    value: format(subYears(new Date(), 3), "YYY-MM-dd"),
    label: intl.formatMessage(messages.option, { number: "3 " }),
  },
  {
    value: format(subYears(new Date(), 5), "YYY-MM-dd"),
    label: intl.formatMessage(messages.option, { number: "5 " }),
  },
];

export const getDataSourcesOptions = (intl) => [
  {
    value: "personal",
    label: intl.formatMessage(messages.personal),
  },
  {
    value: "official",
    label: intl.formatMessage(messages.official),
  },
];

// January to December
export const years = new Array(11).fill().map((_, index) => {
  const year = format(subYears(new Date(), index), "YYY");
  return { value: Number(year), label: year };
});
