import { useMemo, useState, useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { colors } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { ResponsiveContainer, ScatterChart, CartesianGrid, Tooltip, XAxis, YAxis, Scatter } from "recharts";

import { Checkbox } from "components";
import { SelectHookForm } from "components/Form";
import { RowStyled, ColStyled, newColorBySource } from "components/utils";
import { selectWeightChoices } from "containers/App/selectors";
import { FIELDS } from "containers/FoodBasket/constants";
import messages from "containers/FoodBasket/messages";
import { selectSummary } from "containers/FoodBasket/selectors";
import { useCurrencies, useResponsiveHook } from "hooks";
import { useMonths } from "utils/utils";
import CustomTooltip from "./CustomTooltip";
import { TickX, TickY } from "./ChartLabels";
import { Block } from "./styles";

const AveragePriceChart = () => {
  const intl = useIntl();
  const months = useMonths(intl);
  const units = useSelector(selectWeightChoices);
  const currencies = useCurrencies();

  const { isTabletOrMobile } = useResponsiveHook();

  const [includeCosts, setIncludeCosts] = useState(true);

  const { control } = useFormContext();
  const formUnit = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.UNIT}` });
  const formCurrency = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.CURRENCY}` });

  const summary = useSelector(selectSummary);
  const averagePrices = R.pathOr([], ["average_prices"], summary);

  const unit = useMemo(() => units.find((unit) => unit.value === formUnit), [formUnit, units]);

  const data = useMemo(
    () =>
      R.clone(averagePrices).reduce(
        (acc, item) => ({
          ...acc,
          [item.source]: item.prices.reduce((list, el) => {
            Object.keys(el).map(
              (key) => (el[key] = key === "month" ? el[key] : Number((el[key] * unit.conversion_rate).toFixed(3))),
            );
            return [...list, el];
          }, []),
        }),
        {},
      ),
    [averagePrices, unit.conversion_rate],
  );

  const dataKey = useMemo(() => {
    const prefix = formCurrency === "USD" ? "" : "local_";
    const suffix = includeCosts ? "_with_additional" : "";
    return `${prefix}average_price${suffix}`;
  }, [formCurrency, includeCosts]);

  const max = useMemo(
    () => R.flatten(Object.values(data)).reduce((acc, item) => Math.max(item[dataKey], acc), 0),
    [dataKey, data],
  );

  const ticksX = useMemo(() => months.map(({ value }) => value), [months]);

  const ticksY = useMemo(
    () =>
      new Array(5)
        .fill()
        .map((_, index) => {
          const digits = max < 1 ? 3 : max < 100 ? 2 : 0;
          return ((max / 5) * (index + 1)).toFixed(digits);
        })
        .filter((el) => el !== "0.000" && el !== "0.00"),
    [max],
  );

  const changeAdditionalCosts = useCallback(() => setIncludeCosts((includeCosts) => !includeCosts), []);

  return (
    <Block>
      <RowStyled middle="xs" margin="0 0 2rem">
        <ColStyled xs={isTabletOrMobile ? 5 : 3}>
          <RowStyled>
            <ColStyled xs>
              <SelectHookForm control={control} name={`${FIELDS.FILTERS}.${FIELDS.UNIT}`} options={units} />
            </ColStyled>
            <ColStyled xs>
              <SelectHookForm control={control} name={`${FIELDS.FILTERS}.${FIELDS.CURRENCY}`} options={currencies} />
            </ColStyled>
          </RowStyled>
        </ColStyled>
        <Checkbox
          noMarginBottom
          id="checkbox-additional-costs"
          defaultChecked={includeCosts}
          labelText={intl.formatMessage(messages.includeAdditional)}
          onChange={changeAdditionalCosts}
        />
      </RowStyled>
      <ResponsiveContainer height={200} width="100%">
        <ScatterChart>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            content={<CustomTooltip dataKey={dataKey} unitLabel={unit.label} currencyLabel={formCurrency} />}
          />
          <XAxis
            type="number"
            dataKey="month"
            domain={[R.head(months).value, R.last(months).value]}
            tickLine={false}
            tick={(props) => <TickX {...props} months={months} />}
            ticks={ticksX}
            stroke={colors["ui-04"].hex}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            type="number"
            dataKey={dataKey}
            domain={[0, "dataMax"]}
            axisLine={false}
            tickLine={false}
            tick={TickY}
            ticks={[0, ...ticksY]}
            allowDecimals
          />
          {Object.keys(data).map((source) => (
            <Scatter key={source} data={data[source]} fill={newColorBySource[source]} />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </Block>
  );
};

export default AveragePriceChart;
