import { defineMessage } from "react-intl";

export default {
  compareMenus: defineMessage({
    id: "pdf.compareMenus",
    defaultMessage: "menu_comparison.pdf",
  }),
  headerCompare: defineMessage({
    id: "pdf.headerCompare",
    defaultMessage: "SMP PLUS - Compare menus",
  }),
  menuStructure: defineMessage({
    id: "pdf.menuStructure",
    defaultMessage: "{groups} food groups / {items} food items",
  }),
  pagination: defineMessage({
    id: "pdf.pagination",
    defaultMessage: "Page {page} of {total}",
  }),
  generatedOn: defineMessage({
    id: "pdf.generatedOn",
    defaultMessage: "Generated on {date}",
  }),
};
