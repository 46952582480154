import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadPlusMenuSuccess,
  loadAvailableSourcesSuccess,
  loadFoodBasketSourcesSuccess,
  setInitValues,
  resetOptimizedMenu,
} from "./actions";

export const initialState = {
  loading: false,
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadPlusMenuSuccess.type]: (state, action) => {
    state.plusMenu = action.payload.data;
  },
  [loadAvailableSourcesSuccess.type]: (state, action) => {
    state.availableSources = action.payload.sources;
  },
  [loadFoodBasketSourcesSuccess.type]: (state, action) => {
    state.foodBasketSources = action.payload.foodBasket;
  },
  [setInitValues.type]: (state, action) => {
    state.initValues = action.payload.values;
  },
  [resetOptimizedMenu.type]: () => initialState,
});
