import React from "react";
import { useIntl } from "react-intl";
import { Icon, Tooltip, colors } from "@wfp/ui";
import { iconInfoSolid } from "@wfp/icons";
import { mappingUnit } from "utils/constants";
import { renameVitamins } from "containers/Menu/utils";
import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import messages from "./messages";
import { useHtmlHook } from "hooks";

function NutritionalRequirements({ nutritionalRequirements }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  return (
    <Tooltip
      placement={isRTL ? "left-start" : "right-start"}
      trigger="click"
      content={
        <div style={{ width: "280px" }}>
          <Text fontSize="13px" marginBottom="5px" value={intl.formatMessage(messages.nutritionTitle)} bold />
          {(nutritionalRequirements || []).map(({ nutrient_display, nutrient, value_range, unit }) => (
            <RowStyled padding="0" key={nutrient_display}>
              <ColStyled xs={6} padding="0">
                <RowStyled padding="0">
                  <Text bold fontSize="12px" value={`${renameVitamins(nutrient_display)}`} />
                  <Text
                    marginLeft="2px"
                    fontSize="12px"
                    value={`(${nutrient === "iron" ? `${intl.formatMessage(messages.absorbed)} ` : ""}${
                      mappingUnit[unit]
                    }):`}
                  />
                </RowStyled>
              </ColStyled>
              <ColStyled xs padding="0">
                <RowStyled padding="0">
                  <Text fontSize="12px" value={`${intl.formatMessage(messages.min)} ${value_range[0]}`} />
                </RowStyled>
              </ColStyled>
              <ColStyled xs padding="0">
                <RowStyled padding="0">
                  <Text
                    fontSize="12px"
                    value={value_range[1] ? `${intl.formatMessage(messages.max)} ${value_range[1]}` : ""}
                  />
                </RowStyled>
              </ColStyled>
            </RowStyled>
          ))}
        </div>
      }
    >
      <div>
        <Icon icon={iconInfoSolid} description="info" fill={colors["brand-01"].hex} />
      </div>
    </Tooltip>
  );
}

export default NutritionalRequirements;
