import React, { useMemo, useRef, useState, useCallback, useEffect } from "react";
import * as R from "ramda";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withPortal } from "hocs";
import { Accordion, Text } from "components";
import { RowStyled } from "components/utils";
import { Icon, colors } from "@wfp/ui";
import { iconClose } from "@wfp/icons";
import ReactPlayer from "react-player";
import { setTutorial } from "./actions";
import { useOnClickOutside } from "utils/utils";
import { Container, ContainerVideo, Content, Header } from "./styles";
import { mapTutorials } from "./utils";
import Documents from "./Documents";

function Tutorial({ tutorial }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const $accordionRef = useRef({ current: {} });
  const [videoReady, setVideoReady] = useState();

  const resetTutorial = useCallback(() => {
    if (tutorial) dispatch(setTutorial({ kind: null }));
  }, [dispatch, tutorial]);

  const $content = useRef();
  useOnClickOutside($content, resetTutorial);

  useEffect(() => {
    const mainPath = history.location.pathname.split("/")[1];
    if (!R.pathOr([], [tutorial.page], mapTutorials).includes(mainPath)) {
      resetTutorial();
    }
  }, [history.location.pathname, resetTutorial, tutorial.page]);

  const formatFaq = useMemo(() => {
    return ((tutorial && tutorial.faq) || []).map((el) => ({
      title: <Text fontSize="16px" bold wrap value={el.question} />,
      content: <Text wrap marginLeft="10px" marginBottom="10px" marginTop="-5px" value={el.answer} />,
    }));
  }, [tutorial]);

  const width = ($content && $content.current && $content.current.getBoundingClientRect().width) || 500;

  const onChange = useCallback(() => $accordionRef.current.scrollIntoView(), []);

  return (
    <Container>
      <Content ref={$content}>
        <Header between="xs">
          <Text flex={1} wrap fontSize="25px" bold value={tutorial.title} />
          <Icon fill={colors["ui-05"].hex} onClick={resetTutorial} icon={iconClose} width="16px" height="16px" />
        </Header>
        <Text textAlign="justify" wrap value={tutorial.description} />
        <ContainerVideo
          active={String(!videoReady)}
          height="250px"
          margin="15px 0 0"
          between="xs"
        >
          <ReactPlayer
            controls
            volume={1}
            height="250px"
            width={`${width - 60}px`}
            onReady={setVideoReady}
            url={tutorial.video}
          />
        </ContainerVideo>
        <RowStyled margin="10px 0 0" between="xs">
          <Accordion
            onChange={onChange}
            padding="10px 0 0"
            withNoArrow
            fontSize="16px"
            fields={formatFaq}
            ref={$accordionRef}
          />
        </RowStyled>
        <Documents docs={tutorial.docs} />
      </Content>
    </Container>
  );
}

export default withPortal(Tutorial);
