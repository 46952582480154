import React from "react";
import { Text } from "components";
import { useResponsiveHook } from "hooks";
import { colorByCategory } from "components/utils";
import { renameVitamins } from "containers/Menu/utils";
import { Container, Nutrient } from "./styles";

export default function GridNutritionalValues({ data, numberColumns, renderValue, withBorder }) {
  const { isTabletOrMobile } = useResponsiveHook();
  const templateColumns = new Array(numberColumns).fill("1fr").join(" ");

  return (
    <Container templateColumns={templateColumns} withBorder={withBorder}>
      {data.map((item, index) => (
        <Nutrient key={`nutrient-${item.label}-${index}`} withBorder={withBorder}>
          <Text
            bold
            fontSize={"16px"}
            marginLeft={isTabletOrMobile ? "" : "5px"}
            marginRight={isTabletOrMobile ? "" : "5px"}
            defaultColor={colorByCategory[item.category]}
            value={renderValue(item)}
          />
          <Text
            fontSize={"14px"}
            marginLeft={isTabletOrMobile ? "" : "5px"}
            marginRight={isTabletOrMobile ? "" : "5px"}
            defaultColor={colorByCategory[item.category]}
            value={renameVitamins(item.label)}
          />
        </Nutrient>
      ))}
    </Container>
  );
}
