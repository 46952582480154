import React, { useMemo } from "react";
import { colors } from "@wfp/ui";
import { Text } from "components";
import { RowStyled } from "components/utils";
import { StyledGrid, ColWithBorder } from "./styles";

export default function BoxesWithBorder({ menus, children, placeholder, withPaddingTop }) {
  const renderPlaceholder = useMemo(
    () => (
      <ColWithBorder xs paddingtop="true">
        <Text wrap value={placeholder} bold defaultColor={colors["ui-05"].hex} />
      </ColWithBorder>
    ),
    [placeholder],
  );

  return (
    <StyledGrid>
      <RowStyled center="xs" top="xs">
        {(menus || []).map((menu, index) => (
          <ColWithBorder xs key={`box-${index}`} paddingTop={withPaddingTop}>
            {children(menu)}
          </ColWithBorder>
        ))}
        {placeholder && renderPlaceholder}
      </RowStyled>
    </StyledGrid>
  );
}
