import React, { useState, useCallback } from "react";

export default (WrappedComponent) => (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => setModalOpen(false), []);

  return <WrappedComponent isModalOpen={isModalOpen} openModal={openModal} closeModal={closeModal} {...props} />;
};
