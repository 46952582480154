import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Field, useField } from "react-final-form";
import { Autocomplete } from "components";
import { ReactSelectWithDataTestId } from "components/utils";
import {
  selectLoading,
  selectFoodItemOptions,
  selectVamFoodItemOptions,
  selectAdminVamFoodPricesLoading,
} from "containers/Admin/selectors";
import { FIELDS } from "../constants";
import messages from "../messages";

const FoodItem = ({ index }) => {
  const intl = useIntl();
  const { input } = useField(`${FIELDS.VAM_MAPPINGS}[${index}].${FIELDS.ITEM}`);
  const loading = useSelector(selectLoading);
  const foodItemOptions = useSelector(selectFoodItemOptions);
  const vamFoodItemOptions = useSelector(selectVamFoodItemOptions);
  const isAdminVamFoodPricesLoading = useSelector(
    selectAdminVamFoodPricesLoading
  );

  const [searchTerm, setSearchTerm] = useState("");

  // Instead of running API calls every time the user types in, we filter data on the client
  const loadPriorityItems = useCallback((value) => {
    setSearchTerm(value.toLocaleLowerCase());
  }, []);
  // We want to update the option list when we update the search term, or when the page is changed.
  // We figure out changes in pagination by checking the current vamMapping list changes. vamFoodItemsHash is utilized for that purpose.
  const vamFoodItemIds = vamFoodItemOptions
    .map((f) => f && f.value);
  const vamFoodItemsHash = vamFoodItemIds.toString();

  const vamFoodItemIdsSet = useMemo( () => 
    new Set(vamFoodItemIds),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vamFoodItemsHash]
  );

  const options = useMemo(() => {
    const data = [];

    for (let f of foodItemOptions) {
      if (
        !vamFoodItemIdsSet.has(f.value) &&
        (!searchTerm || f.label.toLocaleLowerCase().includes(searchTerm))
      ) {
        data.push(f);
      }
      if (data.length > 100) {
        break;
      }
    }
    return [
      ...data,
      ...vamFoodItemOptions,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminVamFoodPricesLoading, vamFoodItemsHash, searchTerm]);

  const customOnChange = useCallback(
    (el) => {
      input.onChange({ ...el });
    },
    [input]
  );

  const loadingData = useMemo(
    () => loading || isAdminVamFoodPricesLoading,
    [loading, isAdminVamFoodPricesLoading]
  );

  if (loadingData) return null;

  return (
    <Field
      id={`${FIELDS.VAM_MAPPINGS}[${index}].${FIELDS.VALUE}`}
      name={`${FIELDS.VAM_MAPPINGS}[${index}].${FIELDS.ITEM}.${FIELDS.VALUE}`}
      options={options}
      placeholder={intl.formatMessage(messages.typeToSearch)}
      fontSizeLabel="16px"
      components={ReactSelectWithDataTestId}
      loadData={loadPriorityItems}
      isClearable
      component={Autocomplete}
      customOnChange={customOnChange}
    />
  );
};

export default FoodItem;
