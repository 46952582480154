import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Text } from "components";
import { Loading, Wrapper, colors } from "@wfp/ui";
import { loginRedirect } from "./actions";
import { selectLoading } from "./selectors";
import messages from "./messages";

function LoginRedirect({ intl }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector(selectLoading);

  const search = location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");

  useEffect(() => {
    if (code) dispatch(loginRedirect({ code }));
  }, [code, dispatch]);

  return (
    <Wrapper pageWidth="lg" spacing="md">
      <Loading active={loading} />
      <Text
        textAlign="center"
        fontSize="20px"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.wait)}
      />
      <Text
        fontSize="20px"
        textAlign="center"
        defaultColor={colors["brand-01"].hex}
        value={intl.formatMessage(messages.processing)}
      />
    </Wrapper>
  );
}

export default injectIntl(LoginRedirect);
