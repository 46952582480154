import React from "react";
import { injectIntl } from "react-intl";
import { Text } from "components";
import { iconEdit } from "@wfp/icons";
import { Icon } from "@wfp/ui";
import { colors } from "@wfp/ui";
import messages from "components/Button/messages";
import { PlaceholderStyled } from "./styles";

function Placeholder({ height, intl }) {
  return (
    <PlaceholderStyled height={height}>
      <Icon width="26" height="26" fill={colors["brand-01"].hex} icon={iconEdit} />
      <Text bold fontSize="20px" defaultColor={colors["brand-01"].hex} value={intl.formatMessage(messages.edit)} />
    </PlaceholderStyled>
  );
}

export default injectIntl(Placeholder);
