import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { FIELDS } from "../constants";
import { useInitialValues } from "../useInitialValues";

export const useReworkedData = (price) => {
  const { getValues } = useFormContext();
  const initialValues = useInitialValues();

  return useMemo(() => {
    if (!price) return initialValues;

    let result = {};

    result[FIELDS.ADDITIONAL_COSTS] = price.additional_costs.map(({ percentage, value, unit, type }) => {
      if (percentage) {
        return {
          price: Number(value),
          quantity: "%",
          type,
        };
      } else {
        return {
          price: Number(value),
          quantity: unit,
          type,
        };
      }
    });
    result[FIELDS.PRICE] = Number(price.price);
    result[FIELDS.CURRENCY] = price.price_currency;
    result[FIELDS.UNIT] = price.quantity;
    result[FIELDS.REGION] = price.region.id;
    result[FIELDS.PROCUREMENT_SOURCES] = price.source;
    result[FIELDS.ORIGIN] = price.origin;
    result[FIELDS.MONTH] = price.month;
    result[FIELDS.YEAR] = price.year;

    return { ...getValues(), [FIELDS.PERSONAL_ITEM]: result };
  }, [getValues, initialValues, price]);
};
