import { useIntl } from "react-intl";
import { Tooltip, Icon, colors } from "@wfp/ui";
import { iconInfoSolid } from "@wfp/icons";

import { Text } from "components";
import messages from "../messages";
import { RowStyled, ColStyled } from "components/utils";

const SentTo = ({ sharedWith }) => {
  const intl = useIntl();

  return (
    <RowStyled>
      <Text bold fontSize="13px" defaultColor={colors["brand-01"].hex} value={intl.formatMessage(messages.sentTo)} />
      <Text fontSize="13px" marginLeft="5px" value={sharedWith.length > 1 ? sharedWith.length : sharedWith[0]} />
      {sharedWith.length > 1 && (
        <Tooltip
          createRefWrapper
          content={
            <ColStyled middle="xs" width="240px">
              <Text bold fontSize="12px" value={intl.formatMessage(messages.sentMenuTo)} />
              {sharedWith.map((el) => (
                <Text key={el} value={el} fontSize="12px" />
              ))}
            </ColStyled>
          }
        >
          <div>
            <RowStyled margin="1px 5px">
              <Icon description={"info"} icon={iconInfoSolid} fill={colors["brand-01"].hex} />
            </RowStyled>
          </div>
        </Tooltip>
      )}
    </RowStyled>
  );
};

export default SentTo;
