import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Tag } from "@wfp/ui";

import { CheckboxGroupHookForm } from "components/Form";
import { FiltersWrapper } from "./styles";
import { FIELDS } from "containers/MyMenus/constants";
import { selectMenuStatusChoices } from "containers/App/selectors";
import { tagColorByStatus } from "components/utils";

const Status = () => {
  const { control } = useFormContext();
  const menuStatusChoices = useSelector(selectMenuStatusChoices);

  // Show "success" labels first
  const orderedMenuStatusChoices = useMemo(() => {
    const choicesWithColors = menuStatusChoices.map(({ label, value }) => ({
      label,
      value,
      type: tagColorByStatus[value],
    }));
    return R.sortWith([R.ascend(R.prop("type")), R.ascend(R.prop("label"))])(choicesWithColors);
  }, [menuStatusChoices]);

  const getLabelText = useCallback(({ label, value }) => {
    const tagType = tagColorByStatus[value];
    return <Tag type={tagType}>{label}</Tag>;
  }, []);

  return (
    <FiltersWrapper area="1 / 2 / 3 / 3">
      <CheckboxGroupHookForm
        control={control}
        name={FIELDS.STATUS}
        getLabelText={getLabelText}
        options={orderedMenuStatusChoices}
      />
    </FiltersWrapper>
  );
};

export default Status;
