import { useEffect } from "react";

import { Layout } from "components";
import { TEMPORARY_INFO_PAGE_URL } from "utils/constants";

const Info = () => {
  useEffect(() => {
    window.location.href = TEMPORARY_INFO_PAGE_URL;
  }, []);

  return <Layout />;
};

export default Info;
