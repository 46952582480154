import { FIELDS, PROPOSED, OFFICIAL } from "containers/FoodBasket/constants";

export const reworkData = (data) => {
  let result = {};
  const { proposed, ...rest } = data;

  result[FIELDS.ADDITIONAL_COSTS] = data[FIELDS.ADDITIONAL_COSTS].map(({ price, quantity, type }) => {
    const cost = { type, unit: quantity, value: price };
    const isPercentage = quantity === "%";

    if (isPercentage) {
      return {
        ...cost,
        percentage: price,
        price: null,
        price_currency: null,
      };
    } else {
      return {
        ...cost,
        percentage: null,
        price,
        price_currency: quantity,
      };
    }
  });

  // Handling of "proposed" key
  result[FIELDS.PRICE_STATUS] = proposed ? PROPOSED : OFFICIAL;

  return { ...rest, ...result };
};

// We need to transform our local data before sending it to BE
export const getReworkedFilters = (filters) => {
  const bodyKeys = ["date_from", "date_to", "data_origin", "data_source", "regions", "search", "source", "nutrients"];
  let data = {};

  if (!filters) return {};

  Object.keys(filters).forEach((key) => {
    // Do not handle filters not needed by BE
    if (!bodyKeys.includes(key)) return;

    const currentItem = filters[key];

    switch (key) {
      case "regions":
        data[key] = currentItem.map(({ value, label, ...rest }) => ({ id: value, region: label, ...rest }));
        break;
      case "nutrients":
        data[key] = currentItem.reduce(
          (acc, item) => ({
            ...acc,
            [item.name]: item.percentage.map((bound) => bound / 100),
          }),
          {}
        );
        break;
      default:
        data[key] = currentItem;
    }
  });

  return data;
};

// When dealing with AsyncSelect component, we have to tweak data before sending back correct options
export const reworkAllFoodItems = ({ foodItems, fctFoodItems, text }) => {
  const filterByLabel = (label, fullText=false) => {
    if(!fullText){
      return label.toLowerCase().includes(text.toLowerCase())
    }
    return true
  };

  // Filter "regular" food items according to "text" value
  const filteredFoodItems = foodItems.filter(({ label }) => filterByLabel(label));
  const filteredFoodItemsIds = filteredFoodItems.map(({ value }) => value);

  // Since we'll have items with same id but different label (eg. "Fish" from regular food items, "Fish [India] from FCT food items), we need to filter out some elements
  const filteredFCTFoodItems = fctFoodItems.filter(
    ({ value, label }) => !filteredFoodItemsIds.includes(value) && filterByLabel(label, true),
  );

  return [
    ...filteredFoodItems.map(({ label, value, is_priority }) => ({ label, value, is_priority })),
    ...filteredFCTFoodItems,
  ];
};

// This helps making sure we are passing down needed filters when calling "country-database" and "summary-prices" endpoints.
export const getSummaryPricesFilters = (filters) => {
  const bodyKeys = ["date_from", "date_to"];
  let data = {};

  Object.keys(filters).forEach((key) => {
    // Do not handle filters not needed by BE
    if (!bodyKeys.includes(key)) return;

    data[key] = filters[key];
  });

  return data;
};
