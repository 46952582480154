import styled, { css, keyframes } from "styled-components";

const fireCx = 9.487794995307922;
const fireCy = 21.82671907544136;
const scale = (scaleX, scaleY, offsetX, offsetY) =>
  `translate(${fireCx}px, ${fireCy}px) scale(${scaleX}, ${scaleY}) translate(${-fireCx}px, ${-fireCy}px) translate(${
    offsetX * (1 / scaleX)
  }px, ${offsetY * (1 / scaleY)}px)`;

const moveCarrot1 = keyframes`
  from { transform: translate(72px, 17px); }
  to { transform: translate(72px, 118px); }
`;

const moveCarrot2 = keyframes`
  from { transform: translate(94px, 69px); }
  to { transform: translate(94px, 125px); }
`;

const moveCarrot3 = keyframes`
  from { transform: translate(62px, 82px); }
  to { transform: translate(62px, 126px); }
`;

const moveCarrot4 = keyframes`
  from { transform: translate(123px, 87px); }
  to { transform: translate(123px, 128px); }
`;

const moveCarrot5 = keyframes`
  from { transform: translate(121px, 66px); }
  to { transform: translate(121px, 127px); }
`;

const moveCelery1 = keyframes`
  from { transform: translate(140px, 49px); }
  to { transform: translate(140px, 127px); }
`;

const moveCelery2 = keyframes`
  from { transform: translate(138px, 93px); }
  to { transform: translate(138px, 126px); }
`;

const moveOnion1 = keyframes`
  from { transform: translate(101px, 108px); }
  to { transform: translate(101px, 126px); }
`;

const moveOnion2 = keyframes`
  from { transform: translate(54px, 54px); }
  to { transform: translate(54px, 128px); }
`;

const moveFire1 = keyframes`
  0% { transform: ${scale(1, 1, 86, 155)}; }
  50% { transform: ${scale(0.95, 0.95, 86, 155)}; }
`;

const moveFire2 = keyframes`
  0% { transform: ${scale(1, 1, 110, 155)}; }
  50% { transform: ${scale(0.92, 0.97, 110, 155)}; }
`;

export const AnimatedIcon = styled.div`
  position: relative;
  width: 215px;
  height: 215px;

  ${({ isRTL }) =>
    isRTL
      ? css`
          right: calc(50% - 107px);
        `
      : css`
          left: calc(50% - 107px);
        `}

  g {
    #carrot1 {
      animation: ${moveCarrot1} 1s 0.1s ease-in-out infinite alternate;
    }
    #carrot2 {
      animation: ${moveCarrot2} 1s 0.1s ease-in-out infinite alternate;
    }
    #carrot3 {
      animation: ${moveCarrot3} 1s ease-in-out infinite alternate;
    }
    #carrot4 {
      animation: ${moveCarrot4} 1s 0.1s ease-in-out infinite alternate;
    }
    #carrot5 {
      animation: ${moveCarrot5} 1s ease-in-out infinite alternate;
    }
    #celery1 {
      animation: ${moveCelery1} 1s ease-in-out infinite alternate;
    }
    #celery2 {
      animation: ${moveCelery2} 1s 0.1s ease-in-out infinite alternate;
    }
    #onion1 {
      animation: ${moveOnion1} 1s 0.1s ease-in-out infinite alternate;
    }
    #onion2 {
      animation: ${moveOnion2} 1s ease-in-out infinite alternate;
    }
    #fire1 {
      animation: ${moveFire1} 0.5s 0.3s steps(1) infinite;
    }
    #fire2 {
      animation: ${moveFire2} 0.5s steps(1) infinite;
    }
  }
`;

export const ContainerIcons = styled.div`
  position: relative;
  width: 215px;
  height: 215px;
  left: calc(50% - 107px);
  svg {
    position: absolute;
    top: 0;
    left: 0;
    &:first-child {
      opacity: 0.2;
    }
    &:nth-child(2) {
      top: 8%;
      left: 10%;
    }
  }
`;
