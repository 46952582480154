import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  body: {
    border: 1,
    borderColor: colors["ui-04"].hex,
    borderRadius: 2,
  },
  boxHeader: {
    padding: "10 13",
    borderBottom: 1,
    borderTop: 1,
    borderColor: colors["ui-04"].hex,
    backgroundColor: colors["field-01"].hex,
    fontSize: 12,
    fontFamily: "OpenSans",
    fontWeight: "semibold",
  },
  boxContent: {
    flexDirection: "row",
  },
  box: {
    flex: 1,
    borderRight: 1,
    borderColor: colors["ui-04"].hex,
    padding: "10 13",
    overflow: "hidden",
    textAlign: "left",
  },
  title: {
    fontFamily: "OpenSans",
    fontWeight: "bold",
    fontSize: 16,
  },
  headerInBox: {
    borderBottom: 1,
    borderRight: 1,
    borderColor: colors["ui-04"].hex,
    fontWeight: "bold",
    padding: "10 13",
  },
});
