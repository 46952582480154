import { useIntl } from "react-intl";
import { Blockquote } from "@wfp/ui";
import { Text } from "components";
import countriesMessages from "../messages";
import { COMMUNITY } from "containers/MyMenus/labels";

const SubsetWarning = ({ menuType }) => {
  const intl = useIntl();

  return (
    <Blockquote kind="warning">
      <Text
        fontSize="14px"
        bold
        value={intl.formatMessage(
          menuType === COMMUNITY
            ? countriesMessages.nutrientsCommunitySubTitle
            : countriesMessages.nutrientsOptimizedSubTitle,
        )}
      />
    </Blockquote>
  );
};

export default SubsetWarning;
