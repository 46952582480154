import styled from "styled-components";
import { Accordion } from "components";
import { Col } from "react-flexbox-grid";

export const StyledCol = styled(Col)`
  margin: auto 10px;
`;

export const StyledAccordion = styled(Accordion)`
  padding-top: 20px;
  cursor: pointer;
  text-align: left;
  width: auto;
  display: inline-flex;
  flex-direction: column;
  margin: 0 10px;
  border: none;
  .accordion__panel > * {
    padding: 3px 0;
  }
`;
