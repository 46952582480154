import React from "react";
import { useMemo, useCallback, useEffect } from "react";
import { useTable, usePagination, useFlexLayout } from "react-table";

import Pagination from "components/NewTable/Pagination";
import { TableWrapper, NoResults } from "components/NewTable/styles";
import { canEditFoodPrice } from "../utils";

const Table = ({
  manual,
  columns = [],
  data,
  _page,
  pageSize: controlledPageSize,
  totalItems,
  loadData,
  pageSizeOptions = [5, 10, 15],
  controlledPageCount = -1,
  initialState = { pageIndex: 0, pageSize: controlledPageSize },
  hasPagination = true,
  paginationClassName = "",
  noPageRange = undefined,
  noPagesText = undefined,
  customRowsRender,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    gotoPage,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { ...initialState, disableMultiSort: true },
      manualPagination: !hasPagination,
      pageCount: controlledPageCount,
    },
    useFlexLayout,
    usePagination
  );

  useEffect(() => {
    manual && loadData(pageIndex + 1, pageSize);
  }, [loadData, manual, pageIndex, pageSize]);

  useEffect(() => {
    setPageSize(controlledPageSize);
  }, [setPageSize, controlledPageSize]);

  const onChange = useCallback(
    (e: { pageSize: number, page: number }) => {
      if (e.pageSize !== pageSize) {
        setPageSize(e.pageSize);
        !manual && gotoPage(0);
      } else {
        if (manual) {
          loadData(e.page, e.pageSize);
        } else {
          gotoPage(e.page - 1);
        }
      }
    },
    [gotoPage, loadData, manual, pageSize, setPageSize]
  );

  const pagination = hasPagination && <Pagination 
        page={_page}
        onChange={onChange}
        pageSize={pageSize}
        totalItems={totalItems}
        noPageRange={noPageRange}
        noPagesText={noPagesText}
        pageSizeOptions={pageSizeOptions}
        className={paginationClassName}
  />

  return (
    <>
      <TableWrapper>
        <div {...getTableProps()} className="table">
          <div {...getTableBodyProps()} className="tbody">
            {page.length > 0 ? (
              page.map((row: {original: { price_status: string}, getRowProps: () => any }, i: number) => {
                prepareRow(row);
                const isDisabled = !canEditFoodPrice(row.original.price_status);

                return (
                  <div {...row.getRowProps()} key={`row_${i}`} className={`tr padding-big ${isDisabled && "disable"}`}>
                    {customRowsRender[i]}
                  </div>
                );
              })
            ) : (
              <div className="tr">
                <div className="td">
                  <NoResults>No Results</NoResults>
                </div>
              </div>
            )}
          </div>
        </div>
      </TableWrapper>
      { pagination }
    </>
  );
};

export default Table;
