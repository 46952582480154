import { useMemo, useCallback } from "react";
import { renameVitamins } from "containers/Menu/utils";
import { newColorByCategory } from "components/utils";
import ExcessValue from "../ExcessValue";
import Tooltip from "../Tooltip";
import { SELECTED_BAR_WIDTH, BAR_WIDTH, H_PADDING, SPACE_GROUP } from "../constants";
import { StyledBar, Label, TargetLine, BarPercentageContainer } from "./styles";

export default function Bar({
  bar,
  setSelectedCategory,
  selectedCategory,
  setTooltip,
  showTooltip,
  index,
  groupIndex,
  getPixelFromPercentage,
  getPixel,
  barWidth,
  marginBar,
  setMarginRight,
  targetByRestriction,
}) {
  const isSelected = bar.category === selectedCategory;

  const defaultBarWidth = 125;
  const onClickBar = useCallback(() => setSelectedCategory(bar.category), [bar.category, setSelectedCategory]);
  const onMouseOver = useCallback(() => setTooltip(bar.label), [bar.label, setTooltip]);
  const onMouseOut = useCallback(() => setTooltip(""), [setTooltip]);

  const getSpacing = useCallback(
    (barIndex, groupIndex) => {
      if (barIndex) return 2;
      if (groupIndex) return getPixel(SPACE_GROUP);
      return getPixel(marginBar || H_PADDING);
    },
    [getPixel, marginBar],
  );

  const calculateBarWidth = useMemo(
    () => Math.min(getPixel(barWidth || (isSelected ? SELECTED_BAR_WIDTH : BAR_WIDTH)), defaultBarWidth),
    [barWidth, getPixel, isSelected],
  );

  const barStyle = useMemo(
    () => ({
      background: newColorByCategory[bar.category]?.["backgroundColor"],
      marginLeft: getSpacing(index, groupIndex),
      height: getPixelFromPercentage(Math.max(bar.percentage, 0.1)),
      maxHeight: getPixelFromPercentage(bar.percentage > 150 ? 104 : 101),
      width: calculateBarWidth,
    }),
    [bar.category, bar.percentage, calculateBarWidth, getPixelFromPercentage, getSpacing, groupIndex, index],
  );

  const labelStyle = useMemo(
    () => ({
      opacity: Number(!!barWidth || isSelected),
      fontSize: 0.4 * calculateBarWidth,
      width: getPixelFromPercentage(bar.percentage),
      maxWidth: getPixelFromPercentage(bar.percentage > 150 ? 104 : 101),
      height: calculateBarWidth,
      lineHeight: `${calculateBarWidth}px`,
    }),
    [bar.percentage, barWidth, calculateBarWidth, getPixelFromPercentage, isSelected],
  );

  return (
    <StyledBar
      key={`bar-${index}`}
      onClick={onClickBar}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      style={barStyle}
      lastBarMarginRight={setMarginRight ? getSpacing(0, 0) : 0}
    >
      {!!targetByRestriction && !!bar.restriction && (
        <TargetLine
          value={bar.restriction}
          style={{
            bottom: getPixelFromPercentage(bar.restriction),
            width: calculateBarWidth + 14,
          }}
        />
      )}
      {(!!barWidth || isSelected) && (
        <>
          <BarPercentageContainer>
            <span>{bar.percentage}</span>
          </BarPercentageContainer>
          <ExcessValue bar={bar} />
          <Label style={labelStyle}>
            <span>{renameVitamins(bar.label)}</span>
          </Label>
          <Tooltip bar={bar} showTooltip={showTooltip} />
        </>
      )}
    </StyledBar>
  );
}
