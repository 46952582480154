import React from "react";
import { colors } from "@wfp/ui";

export const Beverages = ({ size, disable }) => (
  <svg
    width={size || "17px"}
    height={size || "20px"}
    viewBox="0 0 17 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-222.000000, -370.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(222.000000, 370.000000)">
          <path d="M8.5,0 C14.0375,0 16.935,1.25 16.935,1.9125 C16.9334835,1.94478307 16.9249524,1.97634825 16.91,2.005 C16.5925,2.77 13.5,3.825 8.5,3.825 C8.1875,3.825 7.8825,3.825 7.5875,3.825 C3.13,3.705 0.3875,2.73 0.0875,2.01 C0.0738873261,1.98275613 0.0662180224,1.95293106 0.065,1.9225 C0.065,1.25 2.9625,0 8.5,0 Z" />
          <path d="M13.8175,17.7125 C13.5490469,19.0409948 12.382844,19.9971557 11.0275,20 L5.5275,20 C4.14041361,20.0024439 2.95449329,19.0025503 2.7225,17.635 L0.2625,3.29 L0.3875,3.355 L0.515,3.42 C0.6225,3.4725 0.735,3.5225 0.8525,3.5725 L0.9475,3.6125 C1.1,3.675 1.2625,3.7325 1.43,3.7875 L1.545,3.825 C1.68,3.8675 1.8175,3.91 1.9625,3.95 L2.135,3.995 C2.27,4.0325 2.4125,4.065 2.555,4.1 L2.7175,4.1375 C3.1125,4.225 3.53,4.3 3.9675,4.3675 L4.1575,4.395 L4.6375,4.46 L4.845,4.485 C5.0225,4.5075 5.2025,4.525 5.3825,4.5425 L5.5225,4.5575 C5.75,4.58 5.98,4.5975 6.21,4.615 L6.3625,4.615 L6.9025,4.645 L7.1275,4.645 L7.6275,4.6625 L7.835,4.6625 L8.515,4.6625 L9.1925,4.6625 L9.4,4.6625 L9.9,4.645 L10.1275,4.645 L10.66,4.615 L10.8175,4.615 L11.4875,4.56 L11.6875,4.54 C11.845,4.5225 12.0025,4.5075 12.1575,4.4875 C12.2425,4.4875 12.325,4.4675 12.4075,4.4575 L12.8325,4.4 L13.0825,4.365 C13.2825,4.335 13.48,4.3025 13.6725,4.2675 L13.8825,4.225 L14.2675,4.1475 L14.5,4.0875 L14.8,4.0125 L15.065,3.9425 L15.345,3.86 L15.72,3.7375 L16,3.6325 C16.0775,3.6025 16.15,3.57 16.225,3.5375 L16.425,3.4475 L16.6325,3.3425 C16.6540715,3.33314957 16.6749567,3.32228929 16.695,3.31 L13.8175,17.7125 Z" />
        </g>
      </g>
    </g>
  </svg>
);
