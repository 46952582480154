import React from "react";
import { Switch, Route } from "react-router-dom";
import routesPath from "containers/App/Routes/paths";
import Sources from "./Sources";

const SourcesRouter = () => {
  return (
    <Switch>
      <Route path={routesPath.adminCountriesOptimizedSources} component={Sources} />
      <Route path={routesPath.adminCountriesCommunitySources} component={Sources} />
    </Switch>
  );
};

export default SourcesRouter;
