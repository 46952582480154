import { useCallback } from "react";
import { useController } from "react-hook-form";
import { CheckboxStyled } from "./styles";

const CheckboxHookForm = ({
  control,
  name,
  id,

  labelText,
  fontBold,
  noMarginBottom,
  fontSize,
  checked,
  onChange,
  className,
  defaultChecked,
  customOnChange,
}) => {
  const { field } = useController({ control, name });

  const handleChange = useCallback(
    (e, value) => {
      if (customOnChange) {
        customOnChange(e, field, value);
      } else {
        field.onChange(value);
      }
    },
    [customOnChange, field],
  );

  return (
    <CheckboxStyled
      labelText={labelText}
      id={id}
      checked={field.value}
      onChange={handleChange}
      noMarginBottom={noMarginBottom}
    />
  );
};

export default CheckboxHookForm;
