import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as R from "ramda";
import { useFormContext } from "react-hook-form";

import { useResponsiveHook } from "hooks";
import routesPath from "containers/App/Routes/paths";
import { INFEASIBLE } from "./labels";
import { searchMyMenus } from "./actions";
import Columns, { columnsClickable } from "./Columns";
import { selectPermissions } from "containers/App/selectors";
import { checkPermission } from "components/Unlock/utils";
import { PERMISSIONS } from "containers/Admin/constants";
import { selectFilters } from "./selectors";
import { COMMUNITY, MANUAL } from "containers/MyMenus/labels";
import { NewTable } from "components";

const MenusList = ({ data, loading, totalItems, loadData, setMenuAndAction, comparisonList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();
  const userPermissions = useSelector(selectPermissions);
  const { page, page_size: pageSize } = useSelector(selectFilters);
  const { getValues } = useFormContext();

  const menuInfeasible = useCallback((value) => value === INFEASIBLE, []);
  const callbackOnDelete = useCallback(() => dispatch(searchMyMenus({ body: getValues() })), [dispatch, getValues]);

  const columnsToShow = useMemo(
    () => Columns(setMenuAndAction, comparisonList, callbackOnDelete, isTabletOrMobile, intl),
    [callbackOnDelete, comparisonList, intl, setMenuAndAction, isTabletOrMobile],
  );

  const onClickRow = useCallback(
    (row, cell) => {
      if (!row || !row.original) return;
      if (
        columnsClickable.includes(cell.column.id) &&
        !menuInfeasible(row.original.optimization.type) &&
        R.isEmpty(comparisonList)
      ) {
        if (row.original.type === COMMUNITY) {
          if (
            checkPermission(userPermissions, PERMISSIONS.CHANGE_COMMUNITY_MENU) ||
            checkPermission(userPermissions, PERMISSIONS.VIEW_COMMUNITY_MENU)
          ) {
            history.push(routesPath.resultsCommunity.replace(":id", row.original.id));
          }
        } else if (row.original.type === MANUAL) {
          if (
            checkPermission(userPermissions, PERMISSIONS.CHANGE_MANUAL_MENU) ||
            checkPermission(userPermissions, PERMISSIONS.VIEW_MANUAL_MENU)
          ) {
            history.push(routesPath.results.replace(":id", row.original.id));
          }
        } else {
          if (
            checkPermission(userPermissions, PERMISSIONS.CHANGE_OPTIMIZED_MENU) ||
            checkPermission(userPermissions, PERMISSIONS.VIEW_OPTIMIZED_MENU)
          ) {
            history.push(routesPath.results.replace(":id", row.original.id));
          }
        }
      }
    },
    [comparisonList, history, menuInfeasible, userPermissions],
  );

  const selectedClass = useCallback(
    (row) => {
      return row && comparisonList.find(({ id }) => id === row.original.id) ? "rowSelected" : "";
    },
    [comparisonList],
  );

  const tableProps = {
    manual: true,
    pointer: true,
    columns: columnsToShow,
    data,
    _page: page,
    pageSize,
    totalItems,
    loadData,
    onClickRow,
    loading,
    pageSizeOptions: [10, 30, 40],
    selectedClass,
    paginationClassName: "wfp--pagination-new",
  };

  return <NewTable {...tableProps} />;
};

export default MenusList;
