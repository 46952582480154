import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as R from "ramda";
import { Tag } from "@wfp/ui";
import { Module, Text } from "components";
import { ColStyled } from "components/utils";
import { MODIFIED, MANUAL } from "containers/MyMenus/labels";
import { NutritionalGraph } from "components/Charts";
import messages from "containers/Menu/nutMessages";
import { selectMenuOptimizationType, selectTargetGroup } from "containers/Menu/selectors";
import { GraphForPDF } from "./styles";
import { useResponsiveHook } from "hooks";

function NutritionalValues({ graphValues, onlyPreview, specificDay, inTwoRows, inModal, multipleRows, intl }) {
  const type = useSelector(selectMenuOptimizationType);
  const targetGroup = useSelector(selectTargetGroup);
  const { isTabletOrMobile } = useResponsiveHook();

  const isNotManualOrModified = type !== MODIFIED && type !== MANUAL;

  const properties = useMemo(() => {
    const defaultProps = { marginBar: 20, barWidth: 110, setMarginRight: true };
    return {
      ...defaultProps,
      target: R.pathOr("", ["name"], targetGroup),
    };
  }, [targetGroup]);

  const title = useMemo(
    () => (!onlyPreview ? intl.formatMessage(messages.averageValues) : intl.formatMessage(messages.values)),
    [intl, onlyPreview],
  );

  const requirementsNoMet = useMemo(
    () => graphValues.every(({ percentage, restriction }) => percentage === restriction || percentage > restriction),
    [graphValues],
  );

  return (
    <>
      <Module
        title={title}
        paddingContent={isTabletOrMobile ? "10px 15px" : "10px 15px 30px"}
        elementsOnRight={
          !requirementsNoMet &&
          isNotManualOrModified &&
          !specificDay && (
            <ColStyled margin="0 0 7px">
              <Tag type="warning" children={<Text value={intl.formatMessage(messages.notMet)} />} />
            </ColStyled>
          )
        }
      >
        <NutritionalGraph
          menus={graphValues}
          noReferenceLine
          targetByRestriction={isNotManualOrModified}
          customProps={properties}
          inTwoRows={inTwoRows}
          inModal={inModal}
          multipleRows={multipleRows}
        />
      </Module>
      {!onlyPreview && !specificDay && (
        <GraphForPDF>
          <NutritionalGraph
            id="nutritional-values"
            menus={graphValues}
            noReferenceLine
            targetByRestriction={isNotManualOrModified}
            customProps={properties}
            noEllipsis
          />
        </GraphForPDF>
      )}
    </>
  );
}

export default injectIntl(NutritionalValues);
