import styled from "styled-components";

export const Container = styled.div`
  height: ${({ height }) => height}px;
  margin: 30px 15px 30px 45px;
  position: relative;
`;

export const BarChart = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;
