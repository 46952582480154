import { FIELDS } from "containers/Results/constants";

// Returns "true" if button will be disabled.
export const validation = (items, recipes) => {
  let failedValidation = false;

  // Handle between new recipes (with array of ingredients) and recipe coming from existing one (with object structure).
  const ingredients = recipes.reduce((acc, recipe) => {
    return Array.isArray(recipe.ingredients)
      ? [...acc, ...recipe.ingredients]
      : [...acc, ...Object.values(recipe.ingredients)];
  }, []);

  failedValidation =
    items.some((item) => !item[FIELDS.QUANTITY]) || ingredients.some((ingredient) => !ingredient[FIELDS.QUANTITY]);

  return failedValidation;
};
