import React from "react";
import { colors } from "@wfp/ui";

export const Sugars = ({ size, disable }) => (
  <svg
    width={size || "20px"}
    height={size || "20px"}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-218.000000, -833.000000)" fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}>
        <g transform="translate(218.000000, 833.000000)">
          <path d="M0.174,15.3733333 C0.0738234812,15.4293824 0.00865108878,15.5321543 0.000666666667,15.6466667 C-0.00555244108,15.7609587 0.0473046111,15.8704483 0.140666667,15.9366667 L2.42733333,17.5733333 L4.064,19.86 C4.12608808,19.9472084 4.22628321,19.99929 4.33333333,20 L4.35333333,20 C4.46784572,19.9920156 4.57061757,19.9268432 4.62666667,19.8266667 L6.29633333,16.7666667 L3.23333333,13.705 L0.174,15.3733333 Z" />
          <path d="M11.2473333,3.27533333 C10.3459633,3.30419494 9.457419,3.49724379 8.62533333,3.845 C8.202,9.645 10.9043333,12.584 13.8413333,14.6063333 C13.9746667,14.4873333 14.108,14.3653333 14.2366667,14.2366667 C14.7411259,13.7330006 15.1837994,13.1710425 15.5553333,12.5626667 C13.1746667,10.619 11.217,7.87566667 11.2473333,3.27533333 Z" />
          <path d="M13.0156667,3.487 C12.6559124,3.38866828 12.2875615,3.32504403 11.9156667,3.297 C11.9,7.55466667 13.6866667,10.1333333 15.8853333,11.9666667 C16.7032274,10.4400458 16.9261454,8.66442089 16.511,6.983 L13.0156667,3.487 Z" />
          <path d="M4.58833333,7.21466667 C3.37433333,9.077 2.98566667,11.2183333 3.48833333,13.0176667 L6.993,16.5216667 C7.51223607,16.6638174 8.04832656,16.7350195 8.58666667,16.7333333 C9.00474558,16.7317736 9.42180936,16.6920532 9.83266667,16.6146667 C7.16,14.7106667 4.745,12.028 4.58833333,7.21466667 Z" />
          <path d="M7.938,4.161 C7.13570205,4.58118567 6.40234438,5.12153673 5.76333333,5.76333333 C5.582,5.94466667 5.414,6.133 5.252,6.324 C5.12433333,11.7103333 7.82933333,14.47 10.6976667,16.402 C11.6397183,16.1058298 12.5226946,15.647138 13.3066667,15.0466667 C10.3316667,12.9636667 7.63033333,9.95133333 7.938,4.161 Z" />
          <path d="M19.859312,4.06318608 L17.5722959,2.4268472 L15.9353792,0.140638508 C15.8695778,0.0467915481 15.7597732,-0.00619886677 15.6453349,0.000666547631 C15.530805,0.00864937063 15.4280174,0.0738087103 15.3719598,0.173965166 L13.7027047,3.23268578 L16.7661728,6.29507232 L19.8249734,4.62640659 C19.9251653,4.57036871 19.9903476,4.46761745 19.9983333,4.353128 C20.0047786,4.2390475 19.95231,4.1296192 19.859312,4.06318608 Z" />
        </g>
      </g>
    </g>
  </svg>
);
