import axios, { AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { history } from "utils/history";
import { OPTIMIZED } from "containers/MyMenus/labels";

const pointDomain: Record<string, string> = {
  dev: process.env.API_BASE_URL || "",
  local: "http://localhost:8000",
};

export const host =
  process.env.NODE_ENV === "production"
    ? process.env.API_BASE_URL
    : pointDomain[process.env.ENV || ""];

const refreshAuthLogic = async (failedRequest: any) => {
  const params = new URLSearchParams({
    grant_type: "refresh_token",
    client_id: process.env.CIAM_CLIENT_ID || "",
    refresh_token:
      JSON.parse(localStorage.getItem("token") || "").refresh_token || "",
  });
  const token = await postToRetrieveToken({ params });
  failedRequest.response.config.headers["Auth"] = token.access_token;
  return Promise.resolve();
};

const authAxios = axios.create();
authAxios.interceptors.request.use(
  (config: any) => {
    const token = JSON.parse(localStorage.getItem("token") || "");
    if (token) {
      config.headers.Auth = token.access_token;
      if (process.env.ENV === "TEST") {
        config.headers.Authorization = window.localStorage["basic-token"];
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {
  statusCodes: [401, 403],
});

export function loginWithTestUser(renderApp: () => void) {
  const params = {
    username: "teste2e",
    password: "test123test",
  };
  axios.post(`${host}/api/token-auth/`, params, {}).then(({ data }) => {
    localStorage.setItem("basic-token", `Token ${data.token}`);
    renderApp();
  });
}

export async function postToRetrieveToken({
  params,
}: {
  params: Record<string, any>;
}): Promise<{ access_token: string }> {
  return axios
    .post(`${process.env.CIAM_AUTHENTICATION_URL}/token`, params)
    .then(
      (response) => {
        localStorage.setItem("token", JSON.stringify(response.data));
        return response.data;
      },
      () => {
        localStorage.removeItem("token");
        history.push("/");
      }
    );
}

type AuthorizedGetProps = {
  uri: string;
  params?: Record<string, string>;
};

type AuthorizedPostProps = {
  uri: string;
  params?: Record<string, string>;
  body?: Record<string, string>;
};

async function authorizedPatch({
  uri,
  params,
  body = {},
}: AuthorizedPostProps): Promise<AxiosResponse<any>> {
  return authAxios
    .patch(`${host}${uri}`, body, { params })
    .then((response) => response.data);
}

async function authorizedDelete({
  uri,
  params = {},
}: AuthorizedGetProps): Promise<AxiosResponse<any>> {
  return authAxios
    .delete(`${host}${uri}`, { params })
    .then((response) => response.data);
}

async function authorizedGet({
  uri,
  params = {},
}: AuthorizedGetProps): Promise<AxiosResponse<any>> {
  return authAxios
    .get(`${host}${uri}`, { params })
    .then((response) => response.data);
}

async function authorizedPost({
  uri,
  params,
  body = {},
}: AuthorizedPostProps): Promise<AxiosResponse<any>> {
  return authAxios
    .post(`${host}${uri}`, body, { params })
    .then((response) => response.data);
}

type AuthorizedFileDownload = {
  uri: string;
  fileName: string;
  cb: () => void;
};

export async function authorizedFileDownload({
  uri,
  fileName,
  cb,
}: AuthorizedFileDownload) {
  try {
    const response = await authAxios.get(`${host}${uri}`, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);

    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  } finally {
    if (cb) cb();
  }
}

/**App  */
export function getAccount() {
  return authorizedGet({
    uri: `/api/v1/accounts/me/`,
  });
}

export function postAccount(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/accounts/me/`,
    body,
  });
}

export function getFeatures() {
  return authorizedGet({
    uri: `/api/v1/plusadmin/features/`,
  });
}

export function getConstants() {
  return authorizedGet({
    uri: `/api/v1/common/constants/`,
  });
}

export function getCurrency(countryCode: string) {
  return authorizedGet({
    uri: `/api/geonames/exchange-rate/?country=${countryCode}`,
  });
}

export function getRegions(countryCode: string) {
  return authorizedGet({
    uri: `/api/geonames/admin1/?country=${countryCode}`,
  });
}

export function postValidEmail(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/accounts/is-valid/`,
    body,
  });
}

export function getFoodItems(params: Record<string, string>) {
  return authorizedGet({
    uri: "/api/v1/food/",
    params,
  });
}

/**General Form  */
export function getTargetBeneficiaries() {
  return authorizedGet({
    uri: `/api/v1/target-beneficiaries/`,
  });
}

export function getFoodBasket(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/food/prices/food-basket/`,
    body,
  });
}

export function getFoodBasketV2(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/food/prices/food-basket-v2/`,
    body,
  });
}

/**Select country */
export function getCountries() {
  return authorizedGet({
    uri: `/api/geonames/countries/`,
  });
}

/** Food basket */
export function searchFoodItems(body: Record<string, string>) {
  return authorizedPost({
    uri: "/api/v1/food/prices/country-database/",
    body,
  });
}

export function getAllFoodSimple(params: Record<string, string>) {
  return authorizedGet({
    uri: `/api/v1/food/simple/`,
    params,
  });
}

export function deleteFoodPrice(id: number) {
  return authorizedDelete({
    uri: `/api/v1/food/prices/${id}/`,
  });
}

export function postFoodPrice(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/food/prices/`,
    body,
  });
}

export function patchFoodPrice(id: number, body: Record<string, string>) {
  return authorizedPatch({
    uri: `/api/v1/food/prices/${id}/`,
    body,
  });
}

export function getAllMajorGroup() {
  return authorizedGet({
    uri: "/api/v1/common/constants/major-groups/",
  });
}

export function getAllPriceSources() {
  return authorizedGet({
    uri: "/api/v1/common/constants/origins/",
  });
}

export function getSummaryPrice(itemId: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/food/${itemId}/summary-prices/`,
    body,
  });
}

/**My menus */
export function getMyMenus(params: Record<string, string>) {
  return authorizedGet({
    uri: `/api/v1/menus/`,
    params,
  });
}

export function updateMenu(id: number, body: Record<string, string>) {
  return authorizedPatch({
    uri: `/api/v1/menus/${id}/`,
    body,
  });
}

export function deleteMenu(id: number) {
  return authorizedDelete({
    uri: `/api/v1/menus/${id}/`,
  });
}

export function postFavouriteMenu(id: number) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/toggle-star/`,
  });
}

export function postPinMenu(id: number) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/toggle-pin/`,
  });
}

export function postShareMenu(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/share/`,
    body,
  });
}

export function searchMenus(
  params: Record<string, string>,
  body: Record<string, string>
) {
  return authorizedPost({
    uri: `/api/v1/menus/search/`,
    params,
    body,
  });
}

/**Menu */
export function getMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/`,
  });
}

export function postMenu(body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/`,
    body,
  });
}

export function postCopyMenu(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/copy-menu/`,
    body,
  });
}

export function postOptimizeManualMenu(
  id: number,
  body: Record<string, string>
) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/optimize-manual/`,
    body,
  });
}

export function postPartialCopyMenu(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/partial-copy-menu/`,
    body,
  });
}

/**Comparison page */
export function getCompareInformations(ids: number[]) {
  return authorizedGet({
    uri: `/api/v1/menus/compare/?ids=${ids}`,
  });
}

export function getCompareDiversities(ids: number[]) {
  return authorizedGet({
    uri: `/api/v1/menus/compare-diversities/?ids=${ids}`,
  });
}

export function getCompareNutritions(ids: number[]) {
  return authorizedGet({
    uri: `/api/v1/menus/compare-nutritions/?ids=${ids}`,
  });
}

export function getCompareSourcing(ids: number[]) {
  return authorizedGet({
    uri: `/api/v1/menus/compare-sources/?ids=${ids}`,
  });
}

/**Optimized menu */
export function getPartialDiversity(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/partial-diversity/`,
  });
}

export function postPartialPlusMenu(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/partial-diversity/`,
    body,
  });
}

export function postMenuCalculation(id: number) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/optimize/`,
  });
}
export function postMenuWithoutSourcing(id: number) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/optimize-without-sourcing/`,
  });
}

/**Diversity page */
export function getListGroupsAndItems(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/food-groups-and-items/`,
  });
}

export function getItemRestriction(id: number, params: Record<string, string>) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/item-diversity/`,
    params,
  });
}

export function postItemRestriction(
  id: number,
  params: Record<string, string>,
  body: Record<string, string>
) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/item-diversity/`,
    params,
    body,
  });
}

export function delItemRestriction(id: number, params: Record<string, string>) {
  return authorizedDelete({
    uri: `/api/v1/menus/${id}/del-item-diversity/`,
    params,
  });
}

export function getGroupRestriction(
  id: number,
  params: Record<string, string>
) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/group-diversity/`,
    params,
  });
}

export function postGroupRestriction(
  id: number,
  params: Record<string, string>,
  body: Record<string, string>
) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/group-diversity/`,
    params,
    body,
  });
}

export function delGroupRestriction(
  id: number,
  params: Record<string, string>
) {
  return authorizedDelete({
    uri: `/api/v1/menus/${id}/del-group-diversity/`,
    params,
  });
}

export function getFoodsByMenu(id: number, phrase: string) {
  return authorizedGet({
    uri: `/api/v1/food/?menu=${id}&page_size=INFINITY&${phrase || ""}`,
  });
}

export function getMenuComposition(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/composition/`,
  });
}

export function getMealComposition(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/meal-composition/`,
  });
}

/**Nutritional  */
export function getPercentageOfPackages() {
  return authorizedGet({
    uri: "/api/v1/common/constants/nutrients",
  });
}

export function getBeneficiariesNutrition(id: number, menuType = OPTIMIZED) {
  return authorizedGet({
    uri: `/api/v1/target-beneficiaries/${id}/nutritional-requirements/?menu_type=${menuType}`,
  });
}

export function getNutrientionalAdvanced(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/advanced-nutritional-requirements/`,
  });
}

export function patchNutritionalAdvanced(
  id: number,
  body: Record<string, string>
) {
  return authorizedPatch({
    uri: `/api/v1/menus/${id}/advanced-nutritional-requirements/`,
    body,
  });
}

/**Sourcing page */
export function getMenuSources(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/available-sources/`,
  });
}

export function getFoodBasketBySource(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/source-food-basket/`,
  });
}

/**Results page */
export function getFoodItemsMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/selected-foods/`,
  });
}

export function getNutrionalValuesMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/nutritional-values/`,
  });
}

export function postSelectedFoodItem(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/selected-food/`,
    body,
  });
}

export function patchSelectedFoodItem(
  menuId: number,
  foodItemId: number,
  body: Record<string, string>
) {
  return authorizedPatch({
    uri: `/api/v1/menus/${menuId}/selected-food/${foodItemId}/`,
    body,
  });
}

export function deleteSelectedFoodItem(menuId: number, foodItemId: number) {
  return authorizedDelete({
    uri: `/api/v1/menus/${menuId}/selected-food/${foodItemId}/`,
  });
}

export function getAvailableRecipes(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/available_recipes/`,
  });
}

export function getMenuRecipes(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/recipes/`,
  });
}

export function postMenuRecipe(id: number, body: Record<string, string>) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/recipes/`,
    body,
  });
}

export function deleteMenuRecipe(menuId: number, recipeId: number) {
  return authorizedDelete({
    uri: `/api/v1/menus/${menuId}/recipes/${recipeId}/`,
  });
}

export function createRecipeFromSelectedItems(
  id: number,
  body: Record<string, string>
) {
  return authorizedPost({
    uri: `/api/v1/menus/${id}/recipe-from-selected-items/`,
    body,
  });
}

export function getMenuScores(id: number) {
  return authorizedGet({
    uri: `/api/v1/menus/${id}/score/`,
  });
}

/**Tutorials */
export function getTutorials() {
  return authorizedGet({
    uri: `/api/v1/tutorials/`,
  });
}

/** Community menus */
export function getCommunityMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/community/menus/${id}/`,
  });
}

export function postCommunityMenu(body: any) {
  return authorizedPost({
    uri: `/api/v1/community/menus/`,
    body,
  });
}

export function patchCommunityMenu(id: number, body: any) {
  return authorizedPatch({
    uri: `/api/v1/community/menus/${id}/`,
    body,
  });
}

export function postFoodItemsCommunityMenu(id: number, body: any) {
  return authorizedPost({
    uri: `/api/v1/community/menus/${id}/selected-foods/`,
    body,
  });
}

export function getFoodItemsCommunityMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/community/menus/${id}/selected-foods/`,
  });
}

export function getNutrionalValuesCommunityMenu(id: number) {
  return authorizedGet({
    uri: `/api/v1/community/menus/${id}/nutritional-values/`,
  });
}

export function getAllNutritionalValuesCommunityMenu() {
  return authorizedGet({
    uri: `/api/v1/community/menus/nutritional-values/`,
  });
}

export function getSingleNutrionalValuesCommunityMenu(
  menuId: number,
  foodItemId: number
) {
  return authorizedGet({
    uri: `/api/v1/community/menus/${menuId}/nutritional-values/${foodItemId}/`,
  });
}

export function getCommunityPartialDiversity(id: number) {
  return authorizedGet({
    uri: `/api/v1/community/menus/${id}/partial-diversity/`,
  });
}

export function postCommunityPartialPlusMenu(id: number, body: any) {
  return authorizedPost({
    uri: `/api/v1/community/menus/${id}/partial-diversity/`,
    body,
  });
}

export function getAvailableNutrientsCommunityMenu() {
  return authorizedGet({
    uri: "/api/v1/community/menus/available_nutrients/",
  });
}

export function postCommunityFoodPrices(id: number, body: any) {
  return authorizedPost({
    uri: `/api/v1/community/menus/${id}/foodprices/`,
    body,
  });
}

export function getCommunityFoodSources(countryCode: string) {
  return authorizedGet({
    uri: `/api/geonames/sources/?country=${countryCode}`,
  });
}

export function patchMenuMealNames(id: number, body: any) {
  return authorizedPatch({
    uri: `/api/v1/menus/${id}/meal-names/`,
    body,
  });
}

export function getRecommendedNutritionalRequirements(countryCode: string) {
  return authorizedGet({
    uri: `/api/geonames/recommended-nutrients/?country=${countryCode}`,
  });
}

export function getUsers(params: Record<string, string>) {
  return authorizedGet({
    uri: "/api/v1/plusadmin/user/",
    params,
  });
}

export function getSingleUser(userId: number) {
  return authorizedGet({
    uri: `/api/v1/plusadmin/user/${userId}/`,
  });
}

export function patchSingleUser(userId: number, body: any) {
  return authorizedPatch({
    uri: `/api/v1/plusadmin/user/${userId}/`,
    body,
  });
}

export function getUserGroups() {
  return authorizedGet({
    uri: "/api/v1/plusadmin/group/",
  });
}

export function postCommunityMenuAsOptimized(
  id: number,
  body: any,
  params = ""
) {
  // TODO update params so it would be an argument object and not a string.
  return authorizedPost({
    uri: `/api/v1/community/menus/${id}/optimize/${params}`,
    body,
  });
}

export function getAdminCountriesPreferences(countryIso3: string) {
  return authorizedGet({
    uri: `/api/v1/plusadmin/country/${countryIso3}/`,
  });
}

export function getAdminConstants() {
  return authorizedGet({
    uri: `/api/v1/plusadmin/constants/`,
  });
}

export function patchAdminCountriesPreferences(countryIso3: string, body: any) {
  return authorizedPatch({
    uri: `/api/v1/plusadmin/country/${countryIso3}/`,
    body,
  });
}

export function getVamMappings(params: Record<string, string>) {
  return authorizedGet({
    uri: "/api/v1/plusadmin/vam_mapping/",
    params,
  });
}

export function patchVamMapping(vamCode: string, body: any) {
  return authorizedPatch({
    uri: `/api/v1/plusadmin/vam_mapping/${vamCode}/`,
    body,
  });
}

export function deleteVamMapping(vamCode: string) {
  return authorizedDelete({
    uri: `/api/v1/plusadmin/vam_mapping/${vamCode}/`,
  });
}

export function getAdminFoodPrices(params: Record<string, string>) {
  return authorizedGet({
    uri: `/api/v1/plusadmin/foodprices/`,
    params,
  });
}

export function approveAdminFoodPrices(params: Record<string, string>) {
  return authorizedPost({
    uri: "/api/v1/plusadmin/foodprices/approve/",
    params,
  });
}

export function postApproveFoodPrice(foodPriceId: number) {
  return authorizedPost({
    uri: `/api/v1/plusadmin/foodprices/${foodPriceId}/approve/`,
  });
}

export function postRejectFoodPrice(foodPriceId: number) {
  return authorizedPost({
    uri: `/api/v1/plusadmin/foodprices/${foodPriceId}/reject/`,
  });
}

export function getAdminFoodItems(params: Record<string, string>) {
  return authorizedGet({ uri: `/api/v1/plusadmin/food-items/`, params });
}

export function patchAdminFoodItemsCustomTranslations(
  params: Record<string, any>
) {
  return authorizedPatch({
    uri: `/api/v1/plusadmin/food-items/${params.id}/local-translations/`,
    body: params.data,
  });
}

export function getAdminRecipes(params: Record<string, any>) {
  return authorizedGet({
    uri: "/api/v1/plusadmin/recipes/",
    params,
  });
}

export function getSingleAdminRecipe(id: number) {
  return authorizedGet({
    uri: `/api/v1/plusadmin/recipes/${id}/`,
  });
}

export function postSingleAdminRecipe(body: any) {
  return authorizedPost({
    uri: `/api/v1/plusadmin/recipes/`,
    body,
  });
}

export function patchSingleAdminRecipe(recipeId: number, body: any) {
  return authorizedPatch({
    uri: `/api/v1/plusadmin/recipes/${recipeId}/`,
    body,
  });
}
