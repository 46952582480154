import { Line } from "components";
import { MyModule } from "./styles";

const NewNewModule = ({
  padding,
  headerPadding,
  innerPadding,
  contentPadding,
  contentBackground,
  footerPadding,
  footerBackground,
  withBorder,
  withHeaderBorder,
  withFooterBorder,
  hasFooterLine,
  header,
  body,
  footer,
}) => {
  return (
    <MyModule
      padding={padding}
      headerPadding={headerPadding}
      innerPadding={innerPadding}
      contentPadding={contentPadding}
      contentBackground={contentBackground}
      footerPadding={footerPadding}
      footerBackground={footerBackground}
      withBorder={withBorder}
      withHeaderBorder={withHeaderBorder}
      withFooterBorder={withFooterBorder}
    >
      {header}
      {body}
      {footer && (
        <>
          {hasFooterLine && <Line />}
          {footer}
        </>
      )}
    </MyModule>
  );
};

export default NewNewModule;
