import messages from "../../messages";

export const allVamMappingsFields = (intl) => [
  {
    name: "vam_commodity",
    type: "text",
    label: intl.formatMessage(messages.commodity),
  },
  {
    name: "food_item",
    type: "text",
    label: intl.formatMessage(messages.foodItem),
  },
];
