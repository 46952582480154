import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Text } from "components";
import { Basket } from "components/Icons";
import { RowStyled, ColStyled } from "components/utils";
import { Tag, Link } from "@wfp/ui";
import { colors } from "@wfp/ui";
import routesPath from "containers/App/Routes/paths";
import { showLowNumberModal } from "containers/GeneralInfo/WarningLowNumber";
import menuMessages from "containers/Menu/messages";
import appMessages from "containers/App/messages";
import BoxNumber from "./BoxNumber";
import messages from "./messages";
import { Box } from "./styles";
import { selectLoadingBasket } from "../selectors";

export default function FoodBasket({ items_count, group_count }) {
  const loading = useSelector(selectLoadingBasket);

  return (
    <Box padding="40px 25px">
      <RowStyled bottom="xs">
        <Basket />
        <Text
          bold
          wrap
          marginLeft="10px"
          marginBottom="-5px"
          fontSize="20px"
          value={<FormattedMessage {...messages.basketTitle} />}
        />
      </RowStyled>
      <Text
        wrap
        marginTop="5px"
        defaultColor={colors["text-02"].hex}
        value={
          <FormattedMessage
            {...messages.basketInfo}
            values={{
              link: (
                <Link
                  inline
                  href={routesPath.foodPrices}
                  children={<FormattedMessage {...appMessages.foodPricesLower} />}
                />
              ),
            }}
          />
        }
      />
      <Text bold wrap marginTop="30px" fontSize="16px" value={<FormattedMessage {...messages.composition} />} />
      <Text
        wrap
        defaultColor={colors["text-02"].hex}
        marginBottom="10px"
        value={<FormattedMessage {...messages.compositionInfo} />}
      />
      <RowStyled center="xs" margin="0 -20px 15px">
        <ColStyled xs={6}>
          <BoxNumber loading={loading} value={group_count} text={<FormattedMessage {...menuMessages.foodGroups} />} />
        </ColStyled>
        <ColStyled xs={6}>
          <BoxNumber loading={loading} value={items_count} text={<FormattedMessage {...menuMessages.foodItems} />} />
        </ColStyled>
      </RowStyled>
      {showLowNumberModal(group_count, items_count) && <Tag type="warning" children="Low number of items" />}
    </Box>
  );
}
