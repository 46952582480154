import React, { createContext, useCallback, useState, useMemo, useContext } from "react";
import { colors } from "@wfp/ui";

import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import { FormWizardHorizontal } from "./styles";

export const FormWizardContext = createContext({});
export const useFormWizardContext = () => useContext(FormWizardContext);

const MenuWizard = ({
  hasValidationErrors,
  children,
  labels,
  handleSubmit,
  onSubmit,
  actionsOnNext,
  disableSubmit,
  passPage,
  showLoadingText,
  defaultPage,
}) => {
  const [page, setPage] = useState(defaultPage || 0);

  const formattedLabels = useMemo(() => labels.map((el) => el.localeKey), [labels]);

  const isLastPage = useMemo(() => page === React.Children.count(children) - 1, [children, page]);
  const activePage = useMemo(() => React.Children.toArray(children)[page], [children, page]);

  const next = useCallback(() => {
    setPage(Math.min(page + 1, children.length - 1));
    passPage(Math.min(page + 1, children.length - 1));
    window.scrollTo(0, 0);
  }, [children.length, page, passPage]);

  const customSubmit = (values) => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      const pageLabel = labels[page].label;

      // Use actionsOnNext to trigger someone clicking on "Next" button.
      if (actionsOnNext && actionsOnNext[pageLabel]) {
        return actionsOnNext[pageLabel](next);
      }
      return next();
    }
  };

  const contextValue = useMemo(() => ({ setPage }), []);

  return (
    <FormWizardContext.Provider value={contextValue}>
      {/** Using react-hook-form's handleSubmit for validation */}
      <form onSubmit={handleSubmit(customSubmit)}>
        <FormWizardHorizontal
          formControls={
            <Footer
              disabledButton={hasValidationErrors || disableSubmit}
              isLastPage={isLastPage}
              page={page}
              labels={labels}
              handleSubmit={customSubmit}
              setPage={setPage}
              passPage={passPage}
              showLoadingText={showLoadingText}
            />
          }
          sidebar={<NavigationBar page={page} labels={formattedLabels} />}
          backgroundColor={page === 0 ? colors["ui-01"].hex : colors["ui-02"].hex}
          padding={page === 1 ? "0" : "2.2rem"}
        >
          {activePage}
        </FormWizardHorizontal>
      </form>
    </FormWizardContext.Provider>
  );
};

MenuWizard.Page = ({ children }) => children;

export default MenuWizard;
