import { useRef, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import useSize from "@react-hook/size";
import { useIntl } from "react-intl";

import messages from "containers/OptimizedMenu/Rules/messages";
import { NutritionalGraph } from "components/ChartsNivo";
import { ColStyled } from "components/utils";
import { FIELDS } from "../constants";

const nutrientValues = [];

const Graph = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const $section = useRef();
  const [sectionWidth] = useSize($section);

  const macronutrients = useWatch({ control, name: FIELDS.MACRONUTRIENTS });
  const micronutrients = useWatch({ control, name: FIELDS.MICRONUTRIENTS });

  // TODO: We need a way to add the category to each of our nutrient. Ask BE about data format.
  const graphValues = useMemo(() => {
    const reworkedMacronutrients = (macronutrients || []).map(({ label, percentage }) => ({
      label,
      category: "vitamins",
      restriction: null,
      percentage,
    }));
    const reworkedMicronutrients = (micronutrients || []).map(({ label, percentage }) => ({
      label,
      category: "minerals",
      restriction: null,
      percentage,
    }));
    return [...reworkedMacronutrients, ...reworkedMicronutrients];
  }, [macronutrients, micronutrients]);

  return (
    <ColStyled xl={7} xs={8}>
      <div ref={$section} style={{ position: "relative" }}>
        <label className="wfp--label" style={{ position: "absolute", left: "5%" }}>
          {intl.formatMessage(messages.nutritionalGraphTitle)}
        </label>
        <NutritionalGraph
          graphValues={graphValues}
          nutrientValues={nutrientValues}
          hasTooltip={false}
          isInteractive={false}
          width={sectionWidth}
        />
      </div>
    </ColStyled>
  );
};

export default Graph;
