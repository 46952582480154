import styled from "styled-components";
import { colors } from "@wfp/ui";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.25rem;
  margin-bottom: 2.5rem;
  padding: 0 1.25rem;
`;

export const FiltersWrapper = styled.div`
  grid-area: ${({ area }) => area || "auto"};
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
  padding: 1.25rem;
`;

export const GridArea = styled.div`
  grid-area: ${({ area }) => area};
  align-self: ${({ alignSelf }) => alignSelf ?? "auto"};
`;
