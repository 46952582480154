import { useIntl } from "react-intl";
import { View, Image, Text } from "@react-pdf/renderer";
import generalStyles from "containers/PDF/styles";
import SpacedInRow from "containers/PDF/SpacedInRow";
import { renameVitamins } from "containers/Menu/utils";
import messages from "containers/Menu/nutMessages";
import compareStyles from "./styles";

const PageTwo = ({ imageUrlNutrients, nutrients }) => {
  const intl = useIntl();
  const { fontSize9 } = generalStyles;
  const { body, box, boxContent, boxHeader } = compareStyles;

  return (
    <View style={[body, { marginTop: 10 }]}>
      <Text style={[boxHeader, { borderTop: 0 }]}>{intl.formatMessage(messages.values)}</Text>
      {imageUrlNutrients && <Image style={{ objectFit: "contain" }} src={imageUrlNutrients} />}
      <View style={boxContent}>
        {nutrients.map(({ fulfilled }, index) => (
          <View
            style={[box, index + 1 === nutrients.length ? { border: 0 } : {}, { padding: "0 13" }]}
            key={`box-${index}`}
          >
            {(fulfilled || []).map(({ label, percentage }) => (
              <SpacedInRow
                key={label}
                heightRow={16}
                label={renameVitamins(label)}
                value={`${percentage}%`}
                textTransform="capitalize"
                fontSizeStyle={fontSize9}
              />
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default PageTwo;
