import { defineMessages } from "react-intl";

export default defineMessages({
  generic: {
    id: "error.generic",
    defaultMessage: "Something went wrong",
  },
  genericError: {
    id: "error.genericError",
    defaultMessage: "Error",
  },
  minGreaterMax: {
    id: "error.minGreaterMax",
    defaultMessage: "The min value cannot be greater than max",
  },
  greaterThanAvailableGroups: {
    id: "error.greaterThanAvailableGroups",
    defaultMessage: "Invalid number, you have only {value} food groups in this menu",
  },
  greaterThanAvailableItems: {
    id: "error.greaterThanAvailableItems",
    defaultMessage: "Invalid number, you have only {value} food items in this menu",
  },
  errorPair: {
    id: "error.errorPair",
    defaultMessage: "You cannot insert this {typePair}. {otherTypePair} pair with these items exists.",
  },
  errorSamePair: {
    id: "error.errorSamePair",
    defaultMessage: "This {typePair} pair already exists.",
  },
  maxAllowed: {
    id: "error.maxAllowed",
    defaultMessage: "Max allowed: {value}",
  },
  fieldRequired: {
    id: "error.fieldRequired",
    defaultMessage: "Field required",
  },
});
