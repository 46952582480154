import { defineMessages } from "react-intl";

export default defineMessages({
  location: {
    id: "generalInfo.locationTitle",
    defaultMessage: "Location",
  },
  locationInfo: {
    id: "generalInfo.locationInfo",
    defaultMessage: "Regions where this menu will be served",
  },
  placeholderCountry: {
    id: "generalInfo.placeholderCountry",
    defaultMessage: "Whole country (default)",
  },
  checkboxCountry: {
    id: "generalInfo.checkboxCountry",
    defaultMessage: "Also, use prices that are common for the whole country",
  },
  duration: {
    id: "generalInfo.durationTitle",
    defaultMessage: "Menu duration",
  },
  durationInfo: {
    id: "generalInfo.durationInfoDay",
    defaultMessage: "No. of days in a school week",
  },
  durationInfoWeek: {
    id: "generalInfo.durationInfoWeek",
    defaultMessage: "Number of weeks",
  },
  seasonality: {
    id: "generalInfo.seasonalityTitle",
    defaultMessage: "Seasonality",
  },
  seasonalityInfo: {
    id: "generalInfo.seasonalityInfo",
    defaultMessage: "Months during which this menu will be served",
  },
  targeted: {
    id: "generalInfo.targetedTitle",
    defaultMessage: "Targeted group",
  },
  targetedInfo: {
    id: "generalInfo.targetedInfo",
    defaultMessage: "Define the age group and gender of your targeted group",
  },
  weekTwo: {
    id: "generalInfo.weekTwo",
    defaultMessage: "Two weeks ({number} meals)",
  },
  weekOne: {
    id: "generalInfo.weekOne",
    defaultMessage: "One week ({number} meals)",
  },
  weekThree: {
    id: "generalInfo.weekThree",
    defaultMessage: "Three weeks ({number} meals)",
  },
  weekFour: {
    id: "generalInfo.weekFour",
    defaultMessage: "Four weeks ({number} meals)",
  },
  yearAround: {
    id: "generalInfo.yearAround",
    defaultMessage: "Year around",
  },
  months: {
    id: "generalInfo.months",
    defaultMessage: "Months",
  },
  wholeCountry: {
    id: "generalInfo.wholeCountry",
    defaultMessage: "Whole country",
  },
});
