import styled from "styled-components";

export const WrapperTooltip = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 1;
  background-color: white;
  pointer-events: none;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  > * {
    padding: 0 10px;
  }
`;
