import React from "react";
import { injectIntl } from "react-intl";
import { colors } from "@wfp/ui";
import messages from "containers/Results/messages";
import { ToggleContainer, ToggleUnit } from "./styles";

function Toggle({ activeIndex, setActiveIndex, intl }) {
  return (
    <ToggleContainer>
      <ToggleUnit isActive={activeIndex === 0} onClick={() => setActiveIndex(0)}>
        <span
          style={{ fontWeight: "bold", color: activeIndex === 0 ? colors["brand-01-80"].hex : colors["ui-01"].hex }}
        >
          {intl.formatMessage(messages.communityMenuComposition)}
        </span>
      </ToggleUnit>
      <ToggleUnit isActive={activeIndex === 1} onClick={() => setActiveIndex(1)}>
        <span
          style={{ fontWeight: "bold", color: activeIndex === 1 ? colors["brand-01-80"].hex : colors["ui-01"].hex }}
        >
          {intl.formatMessage(messages.communityMenuNutrition)}
        </span>
      </ToggleUnit>
    </ToggleContainer>
  );
}

export default injectIntl(Toggle);
