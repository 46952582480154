import React from "react";
import { Breadcrumbs } from "components";
import { useHistory } from "react-router-dom";
import { LayoutContainer, UIWrapper } from "./styles";

export default function Layout({ children, withBreadcrumbs, backgroundColor, marginTop, overflow }) {
  const history = useHistory();

  return (
    <LayoutContainer backgroundColor={backgroundColor} marginTop={marginTop}>
      {withBreadcrumbs && <Breadcrumbs history={history} />}
      <UIWrapper background="lighter" pageWidth="lg" backgroundColor={backgroundColor} overflow={overflow}>
        {children}
      </UIWrapper>
    </LayoutContainer>
  );
}
