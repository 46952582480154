import React, { useCallback, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { colors, Modal, TextInput, InputGroup, RadioButton, Loading } from "@wfp/ui";
import { copyMenu, partialCopyMenu, copyCommunityMenuAsOptimized, resetDuplicationData } from "containers/Menu/actions";
import { MANUAL, COMMUNITY, OPTIMIZED } from "containers/MyMenus/labels";
import { selectMenuLoading, selectDuplication, selectMenuType } from "containers/Menu/selectors";
import menuMessages from "containers/Menu/messages";
import { ACTIONS } from "../../constants";
import messages from "../messages";
import { Text } from "components";
import { ColStyled } from "components/utils";
import { optionsClone, getDuplicateFunctionToCall } from "../utils";
import CopyButton from "./CopyButton";
import BackAndContinueButtons from "./BackAndContinueButtons";

const Duplicate = ({ menuAndAction, closeModal }) => {
  const [menuName, setMenuName] = useState(`${menuAndAction.name}_copy`);
  const [optionClone, setOptionClone] = useState();
  const dispatch = useDispatch();
  const intl = useIntl();
  const loadingMenu = useSelector(selectMenuLoading);
  const duplication = useSelector(selectDuplication);
  const menuType = useSelector(selectMenuType);

  const changeOptions = useCallback((e) => setOptionClone(e.target.value), []);

  const copyMenuWithOption = useCallback(() => {
    let type,
      functionToCall,
      // Setting the copy_results to true by default to not alter the previously implemented logic (for duplicating the community menu)
      body = { copy_results: true };

    switch (optionClone) {
      // Create a copy of this menu
      // Since we won't have the menuType selector value if the duplication is happening from the homepage, we look for it in the menuAndAction prop
      case "1":
        type = menuType ?? menuAndAction.type;
        functionToCall = getDuplicateFunctionToCall(type);
        break;
      // Create a new optimized menu with the same general info
      case "2":
        type = OPTIMIZED;
        functionToCall = copyMenu;
        body = { ...body, copy_results: false };
        break;
      // Create a new manual menu with the same general info
      case "3":
        type = MANUAL;
        functionToCall = partialCopyMenu;
        break;
      // Create a new community menu with the same general info
      case "4":
        type = COMMUNITY;
        functionToCall = partialCopyMenu;
        break;
      // Create a new optimized menu based on this menu
      case "5":
        type = COMMUNITY;
        functionToCall = copyCommunityMenuAsOptimized;
        break;
      // Create an exact copy of the menu and opens it up at Results page
      case "6":
        type = menuType ?? menuAndAction.type;
        functionToCall = getDuplicateFunctionToCall(type);
        body = { ...body, optionClone };
        break;
      // Create and opens in the Results page a new manual menu, Blank, but with the same general settings
      case "7":
        type = MANUAL;
        functionToCall = partialCopyMenu;
        body = { ...body, copy_results: false, optionClone };
        break;
      // case "8": Create a new auto menu, with all the settings and rules from the original menu and opens it up at step 2
      // case "9": Create a new auto menu, with all the settings and rules from the original menu and opens it up at step 1
      case "8":
      case "9":
        type = OPTIMIZED;
        functionToCall = copyMenu;
        body = { ...body, copy_results: false, optionClone };
        break;
      // Create a new auto menu, same general settings, rules in Blank
      case "10":
        type = OPTIMIZED;
        functionToCall = partialCopyMenu;
        body = { ...body, copy_results: false };
        break;
      default:
        type = menuType;
        functionToCall = partialCopyMenu;
    }

    dispatch(
      functionToCall({
        id: menuAndAction.id,
        body: {
          ...body,
          name: menuName,
          type,
        },
      })
    );
  }, [dispatch, menuAndAction.id, menuAndAction.type, menuName, menuType, optionClone]);

  const AlertText = useMemo(
    () =>
      menuAndAction.regions.length > 1 &&
      optionClone === 4 && (
        <Text
          defaultColor={colors["support-01"].hex}
          value={intl.formatMessage(messages.duplicateAlert, { region: menuAndAction.regions[0] })}
        />
      ),
    [intl, menuAndAction.regions, optionClone]
  );

  const availableCloneOptions = useMemo(() => {
    switch (menuType ?? menuAndAction.type) {
      case MANUAL:
        return [optionsClone[5], optionsClone[6]];
      case OPTIMIZED:
        return [optionsClone[5], optionsClone[7], optionsClone[8], optionsClone[9]];
      default:
        return [optionsClone[0], optionsClone[1], optionsClone[2], optionsClone[3], optionsClone[4]];
    }
  }, [menuType, menuAndAction.type]);

  const onClickBackButton = useCallback(() => {
    dispatch(resetDuplicationData());
  }, [dispatch]);

  const onClickContinueButton = useCallback(() => {
    dispatch(
      copyCommunityMenuAsOptimized({
        id: menuAndAction.id,
        body: {
          name: menuName,
          type: COMMUNITY,
        },
        options: {
          acceptUnpricedFood: true,
        },
      })
    );
  }, [dispatch, menuAndAction.id, menuName]);

  const modalFooter = useCallback(
    () =>
      duplication ? (
        <BackAndContinueButtons onClickBack={onClickBackButton} onClickContinue={onClickContinueButton} />
      ) : (
        <CopyButton onClick={copyMenuWithOption} disabled={!optionClone || !menuName} />
      ),
    [copyMenuWithOption, duplication, menuName, onClickBackButton, onClickContinueButton, optionClone]
  );

  return (
    <Modal
      open={menuAndAction && !!menuAndAction.id && menuAndAction.action === ACTIONS.CLONE}
      onRequestClose={closeModal}
      modalHeading={intl.formatMessage(messages.duplicateTitle)}
      modalFooter={modalFooter}
    >
      <Loading active={loadingMenu} />
      {duplication?.status === "KO" ? (
        <ColStyled xs>
          <Text wrap marginBottom="20px" value={duplication.reason} />
          {duplication.items.map((item) => (
            <Text
              key={item.id}
              wrap
              defaultColor={item.is_priority ? colors["support-02"].hex : colors["text-01"].hex}
              value={item.display_name}
            />
          ))}
        </ColStyled>
      ) : (
        <>
          <TextInput
            id="menuName"
            name="text-menu-name"
            defaultValue={menuName}
            labelText={intl.formatMessage(menuMessages.name)}
            placeholder={intl.formatMessage(menuMessages.placeholderName)}
            onChange={(e) => setMenuName(e.target.value)}
          />
          <InputGroup controlled={false} name="radio" vertical>
            {availableCloneOptions.map((option) => (
              <RadioButton key={option.id} {...option} onChange={changeOptions} />
            ))}
          </InputGroup>
          {AlertText}
        </>
      )}
    </Modal>
  );
};

export default Duplicate;
