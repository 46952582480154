import { defineMessage } from "react-intl";

export default {
  createNewMenu: defineMessage({
    id: "button.createNewMenu",
    defaultMessage: "Create a new menu",
  }),
  title: defineMessage({
    id: "createNewMenu.title",
    defaultMessage: "Choose a menu type",
  }),
  titleOptimized: defineMessage({
    id: "createNewMenu.titleOptimized",
    defaultMessage: "Optimised menu",
  }),
  automatic: defineMessage({
    id: "createNewMenu.automatic",
    defaultMessage: "Automatic",
  }),
  infoOptimized: defineMessage({
    id: "createNewMenu.infoOptimized",
    defaultMessage: "Use our algorithm to create the most cost efficient and nutritent dense meal.",
  }),
  titleManual: defineMessage({
    id: "createNewMenu.titleManual",
    defaultMessage: "Manual menu",
  }),
  manual: defineMessage({
    id: "createNewMenu.manual",
    defaultMessage: "Manual",
  }),
  infoManual: defineMessage({
    id: "createNewMenu.infoManual",
    defaultMessage: "Create a menu yourself by adding food items one at a time. This menu is not optimised.",
  }),
  titleCommunity: defineMessage({
    id: "createNewMenu.titleCommunity",
    defaultMessage: "Community menu",
  }),
  community: defineMessage({
    id: "createNewMenu.community",
    defaultMessage: "Community",
  }),
  infoCommunity: defineMessage({
    id: "createNewMenu.infoCommunity",
    defaultMessage: "Create a menu in a participatory process with the school community.",
  }),
};
