import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  containerList: {
    borderLeft: 1,
    borderColor: colors["ui-04"].hex,
    width: "25%",
    paddingLeft: 10,
  },
  tag: {
    fontSize: 7,
    padding: 5,
    fontFamily: "OpenSans",
    fontWeight: "normal",
    backgroundColor: "#fdedc9",
    color: colors["support-03"].hex,
    borderRadius: 12,
  },
  section: {
    flex: 1,
    overflow: "hidden",
    fontSize: 30,
    flexDirection: "row",
    flexWrap: "wrap",
    border: 1,
    marginTop: 15,
    borderColor: colors["ui-04"].hex,
    borderRadius: 2,
  },
});
