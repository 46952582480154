import styled from "styled-components";

export const TableWrapper = styled.div`
  display: block;
  overflow: auto;

  .table {
    border-spacing: 0;

    .tr {
      background-color: white;
      border-radius: 0.4rem;
      margin-bottom: 0.75rem;

      &.padding-big {
        padding: 1.25rem;
      }

      :last-child {
        /* Added so we have space between table and pagination components */
        margin-bottom: 2rem;

        .td {
          border-bottom: 0;
        }
      }
    }

    .td {
      margin: 0;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .-loading {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;

      & > div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      &.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;

        & > div {
          transform: translateY(50%);
        }
      }
    }
  }
`;

export const NoResults = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;
