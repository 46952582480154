import { colors } from "@wfp/ui";
import styled from "styled-components";

import { RowStyled } from "components/utils";

export const ActiveFiltersContainer = styled(RowStyled)`
  border-bottom: 1px solid ${colors["ui-04"].hex};
  padding: 15px;
  margin-bottom: 1.5rem;
`;
