import React, { useState } from "react";
import { SecondHeader } from "components";
import RenameMenu from "./RenameMenu";
import ActionButtons from "./ActionButtons";

export default function NameAndActions({
  name,
  id,
  enableButtons,
  enableAddEditButton,
  loading,
  toggleIndex,
  setToggleIndex,
  test,
  values,
  handleSubmit,
}) {
  const [menuName, setMenuName] = useState(name);

  return (
    <SecondHeader>
      <RenameMenu
        name={name}
        menuId={id}
        menuName={menuName}
        setMenuName={setMenuName}
        toggleIndex={toggleIndex}
        setToggleIndex={setToggleIndex}
      />

      <ActionButtons
        menuId={id}
        test={test}
        loading={loading}
        menuName={menuName}
        enableButtons={enableButtons}
        enableAddEditButton={enableAddEditButton}
        values={values}
        handleSubmit={handleSubmit}
      />
    </SecondHeader>
  );
}
