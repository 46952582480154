import { Controller } from "react-hook-form";

import { CheckboxStyled } from "../CheckboxHookForm/styles";

const CheckboxGroupHookForm = ({ control, name, getLabelText, options }) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) =>
      options.map((option) => (
        <CheckboxStyled
          key={option.value}
          id={option.value}
          labelText={getLabelText ? getLabelText(option) : option.label}
          checked={field?.value?.includes(option.value)}
          onChange={(e, checked) => {
            if (checked) {
              field.onChange([...field.value, e.target.id]);
            } else {
              field.onChange(field?.value?.filter((value) => value !== e.target.id));
            }
          }}
        />
      ))
    }
  />
);

export default CheckboxGroupHookForm;
