import { useCallback } from "react";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";

import { Grid } from "containers/FoodBasket/styles";

import { mappingUnit } from "utils/constants";
import Legend from "./Legend";
import Item from "./Item";

const NutritionalValues = ({ title, hasLegend, data, itemsPerRow }) => {
  // Render text content for each block inside nutritional values grid
  const renderValue = useCallback(({ value, unit }) => (unit ? `${value} ${mappingUnit[unit]}` : value), []);

  return (
    <>
      {hasLegend && <Legend />}
      <RowStyled margin="1.5rem 0 0 0">
        <Text marginBottom="0.5rem" value={title} />
      </RowStyled>
      <RowStyled>
        <ColStyled xs padding="0">
          <Grid itemsPerRow={itemsPerRow}>
            {data.map((item) => (
              <Item key={item.label} nutrient={item} renderValue={renderValue} />
            ))}
          </Grid>
        </ColStyled>
      </RowStyled>
    </>
  );
};

export default NutritionalValues;
