export const FIELDS = {
  ADVANCED_REQUIREMENTS: "advNutritionalRequirements",
  MAX_LIMITS: "nutrient_max_limits",
};

export const mapSliders = {
  energy: "staple_calories_percentage",
  fat: "fat_calories_percentage",
  protein: "protein_calories_percentage",
};
