export const FIELDS = {
  PRELIMINARY_INFORMATION: "preliminary_information",
  MENU_GENERAL_PARAMETER: "menu_general_parameter",
  REGIONS: "regions",
  START_MONTH: "start_month",
  END_MONTH: "end_month",
  SEASONALITY: "seasonality",
  DAYS_IN_WEEK: "days_in_week",
  WEEK_COUNT: "week_count",
  TARGET_GROUP: "target_group",
  PRICE_SOURCE_VALIDITY: "price_source_validity",
  INCLUDE_WHOLE_COUNTRY: "include_whole_country",
  COUNTRY: "country",
  MENU_PRICING_LIST: "menu_pricing_list",
  SOURCING_CHANNELS: "sourcing_channels",
  AVERAGE_COST: "average_cost_per_meal",
  COST_CURRENCY: "cost_currency",
  MICRONUTRIENTS: "micronutrients",
  MACRONUTRIENTS: "macronutrients",
  SOURCING_CONSTRAINTS: "sourcing_constraints",
  MENU_NAME: "name",

  RESTRICTION: "restriction",
  BASKET: "basket",
  FILTERS: "filters",
  UNIT: "quantity",
  CURRENCY: "price_currency",
  SOURCES: "sources_informations",
  DRAFT: "draft",
  SOURCE: "source",
  REGION: "region",
  PRICE: "price",
  CUSTOM_PRICE: "customPrice",
  ITEM: "item",
  NEW_ITEM: "new_item",

  PRICES: "prices",
  USER_PRICE_PER_GRAM: "user_price_per_gram",
  USER_PROVIDED_PRICE: "user_provided_price",
};

export const SOURCE_INITIAL_VALUES = {
  [FIELDS.SOURCE]: [],
  [FIELDS.PRICE]: 0,
};

export const NEW_ITEM_INITIAL_VALUES = {
  [FIELDS.ITEM]: [],
  [FIELDS.CURRENCY]: "USD",
  [FIELDS.UNIT]: "1 kg",
  [FIELDS.PRICE]: 0,
  [FIELDS.SOURCE]: [],
};

export const INITIAL_VALUES = {
  [FIELDS.PRELIMINARY_INFORMATION]: {
    [FIELDS.REGIONS]: [],
    [FIELDS.START_MONTH]: 1,
    [FIELDS.END_MONTH]: 12,
    [FIELDS.SEASONALITY]: "y",
    [FIELDS.DAYS_IN_WEEK]: 5,
    [FIELDS.WEEK_COUNT]: 1,
    [FIELDS.TARGET_GROUP]: 4,
    [FIELDS.PRICE_SOURCE_VALIDITY]: null,
    [FIELDS.INCLUDE_WHOLE_COUNTRY]: true,
    [FIELDS.SOURCING_CHANNELS]: [],
    [FIELDS.AVERAGE_COST]: "",
    [FIELDS.COST_CURRENCY]: "USD",
  },
  [FIELDS.FILTERS]: {
    [FIELDS.UNIT]: "1 kg",
    [FIELDS.CURRENCY]: "USD",
  },
  [FIELDS.BASKET]: {},
  [FIELDS.DRAFT]: {
    [FIELDS.SOURCE]: SOURCE_INITIAL_VALUES,
    [FIELDS.NEW_ITEM]: NEW_ITEM_INITIAL_VALUES,
  },
};
