import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModuleFooter } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, NewModule } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { selectDaysInWeek, selectWeekCount } from "containers/Menu/selectors";
import {
  saveItemRestrictionRequest,
  saveGroupRestrictionRequest,
  deleteGroupRestriction,
  deleteItemRestriction,
} from "../actions";

import { FOOD_RESTRICTION_TYPES } from "../../constants";
import {
  FIELDS,
  FOOD_RESTRICTION_PATH,
  GROUP_RESTRICTION_PATH,
} from "./constants";
import Footer from "./Footer";
import GroupRestriction from "./GroupRestriction";
import ItemRestriction from "./ItemRestriction";
import IncludeExclude from "./IncludeExclude";
import {
  makeItemRestrictionRequestBody,
  makeGroupRestrictionRequestBody,
} from "./ItemRestriction/utils";
import { IconStyled } from "../styles";
import { getIcon } from "components/Icons";
import { HeaderWrapper, ModuleBodyWrapper } from "./styles";
import React from "react";

type FoodRestrictionProps = {
  menuId: string,
  restrictionAttributeName: string,
  name: string,
  resetSelectedIndex: () => void,
};

const FoodRestriction = ({
  menuId,
  restrictionAttributeName,
  name,
  resetSelectedIndex,
}: FoodRestrictionProps): JSX.Element => {
  const dispatch = useDispatch();

  const { control, setValue } = useFormContext();
  const restriction = useWatch({ control, name: FIELDS.RESTRICTION });

  const isItemRestriction = restrictionAttributeName === "item_id";

  const restrictionPath = isItemRestriction ? FOOD_RESTRICTION_PATH : GROUP_RESTRICTION_PATH;
  const restrictionType = useWatch({
    control,
    name: `${restrictionPath}.${FIELDS.RESTRICTION_TYPE}`,
  });
  const [defaultMinPortionSize, defaultMaxPortionSize] = useWatch({
    control,
    name: `${restrictionPath}.${FIELDS.MIN_MAX_AMOUNT_RANGE}`,
  });

  const [minPortionSize, maxPortionSize] = useWatch({
    control,
    name: `${restrictionPath}.${FIELDS.AMOUNT_RANGE}`,
  });

  const majorLabel = useWatch({
    control,
    name: `${
      isItemRestriction ? FOOD_RESTRICTION_PATH : GROUP_RESTRICTION_PATH
    }.${FIELDS.MAJOR_LABEL}`,
  });
  const foodRestrictionId = useWatch({
    control,
    name: `${
      isItemRestriction ? FOOD_RESTRICTION_PATH : GROUP_RESTRICTION_PATH
    }.id`,
  });

  const daysInWeek = useSelector(selectDaysInWeek);
  const weekCount = useSelector(selectWeekCount);

  // Convert FOOD_RESTRICTION_TYPES in to EXCLUTION_CHOICES
  const exclutionChoice = useMemo(() => {
    switch (restrictionType) {
      case FOOD_RESTRICTION_TYPES.INCLUDE:
        return "include";
      case FOOD_RESTRICTION_TYPES.EXCLUDE:
        return "exclude";
      default:
        return "no-rule";
    }
  }, [restrictionType]);

  const close = useCallback(() => {
    setValue(FIELDS.RESTRICTION, null);
    resetSelectedIndex();
  }, [resetSelectedIndex, setValue]);

  const save = useCallback(() => {
    const params = { [restrictionAttributeName]: restriction.id };
    const isNoRuleSelected = exclutionChoice === "no-rule";
    const isPortionSizeValuesChanged = (minPortionSize !== defaultMinPortionSize) || (maxPortionSize !== defaultMaxPortionSize);
    // When changing an already existing restriction to "no-rule", delete it
    if (foodRestrictionId && isNoRuleSelected && !isPortionSizeValuesChanged) {
      if (isItemRestriction) {
        dispatch(deleteItemRestriction({ id: menuId, params }));
      } else {
        dispatch(deleteGroupRestriction({ id: menuId, params }));
      }
    } else {
      if (isItemRestriction) {
        const body = makeItemRestrictionRequestBody(restriction);
        dispatch(saveItemRestrictionRequest({ id: menuId, params, body }));
      } else {
        const body = makeGroupRestrictionRequestBody(restriction);
        dispatch(saveGroupRestrictionRequest({ id: menuId, params, body }));
      }
    }

    close();
  }, [restrictionAttributeName, restriction, exclutionChoice, minPortionSize, defaultMinPortionSize, maxPortionSize, defaultMaxPortionSize, foodRestrictionId, close, isItemRestriction, dispatch, menuId]);

  const icon = useMemo(() => {
    return (
      <IconStyled marginInlineEnd="0">
        {getIcon(majorLabel) && getIcon(majorLabel)()}
      </IconStyled>
    );
  }, [majorLabel]);

  const header = useMemo(
    () => (
      <HeaderWrapper>
        <RowStyled>
          {icon}
          <Text bold fontSize="16px" value={name} />
          <IncludeExclude
            exclutionChoice={exclutionChoice}
            restrictionAttributeName={restrictionAttributeName}
          />
        </RowStyled>
      </HeaderWrapper>
    ),
    [icon, name, exclutionChoice, restrictionAttributeName]
  );

  const body = useMemo(
    () => (
      <ModuleBodyWrapper>
        <RowStyled>
          <ColStyled xs={12}>
            {restrictionAttributeName === "item_id"
              ? restriction &&
                restriction.food_item_restriction && (
                  <ItemRestriction
                    daysInWeek={daysInWeek}
                    name={name}
                    weekCount={weekCount}
                    exclutionChoice={exclutionChoice}
                  />
                )
              : restriction &&
                restriction.food_group_restriction && (
                  <GroupRestriction
                    daysInWeek={daysInWeek}
                    name={name}
                    weekCount={weekCount}
                    restrictionAttributeName={restrictionAttributeName}
                    exclutionChoice={exclutionChoice}
                  />
                )}
          </ColStyled>
        </RowStyled>
      </ModuleBodyWrapper>
    ),
    [
      restrictionAttributeName,
      restriction,
      daysInWeek,
      name,
      weekCount,
      exclutionChoice,
    ]
  );

  const footer = useMemo(
    () => (
      <ModuleFooter>
        <Footer
          restrictionAttributeName={restrictionAttributeName}
          name={name}
          close={close}
          save={save}
          daysInWeek={daysInWeek}
          weekCount={weekCount}
        />
      </ModuleFooter>
    ),
    [restrictionAttributeName, name, close, save, daysInWeek, weekCount]
  );

  const moduleStyles = useMemo(
    () => ({
      headerPadding: "1.2rem",
      contentPadding: "1.2rem !important",
      footerPadding: "1.2rem !important",
      contentBackground: "#fff",
      footerBackground: "#fff",
    }),
    []
  );

  return (
    <ColStyled xs={12}>
      <NewModule
        header={header}
        body={body}
        footer={footer}
        withBorder
        withHeaderBorder
        withFooterBorder
        {...moduleStyles}
      />
    </ColStyled>
  );
};

export default FoodRestriction;
