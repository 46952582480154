import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { Draggable } from "react-beautiful-dnd";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import buttonMessages from "components/Button/messages";
import { NumberInputHookForm } from "components/Form";
import { getIcon } from "components/Icons";
import { RowStyled, ColStyled, newColorBySource } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import { getPrices } from "containers/Results/utils";
import { Chip } from "components/ChipWithLabel/styles";

const FoodItem = ({ remove, fieldPrefix, draggable = true, canBeRemoved = true, index }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  // Listen for quantity changes in order to calculate correct costs.
  const watchItem = useWatch({ control, name: `${fieldPrefix}.${index}` });

  const costs = useMemo(() => getPrices(watchItem), [watchItem]);
  const renderCost = useMemo(() => `$ ${costs.price.toFixed(4)}`, [costs.price]);

  const getItemStyle = useCallback(
    (isDragging, draggableStyle) => ({
      userSelect: "none",
      ...draggableStyle,
    }),
    []
  );

  if (draggable) {
    return (
      <Draggable draggableId={`item_${index}.${watchItem.display_name}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <RowStyled
              middle="xs"
              around="xs"
              padding="10px"
              style={{ borderBottom: `1px solid ${colors["ui-04"].hex}` }}
            >
              <ColStyled xs={1}>{getIcon(watchItem.major_group) && getIcon(watchItem.major_group)()}</ColStyled>

              <ColStyled xs={5} padding="0">
                <Text value={watchItem.display_name.split(";")[0]} />
              </ColStyled>

              <ColStyled xs={4}>
                <RowStyled middle="xs">
                  <NumberInputHookForm control={control} name={`${fieldPrefix}.${index}.${FIELDS.QUANTITY}`} />
                  <span style={{ marginLeft: "5px" }}>g</span>
                </RowStyled>
              </ColStyled>

              <ColStyled xs={2} padding="0">
                <RowStyled middle="xs" end="xs">
                  <span style={{ marginRight: "5px" }}>{renderCost}</span>
                  {canBeRemoved && (
                    <Icon
                      className="pointer"
                      description={intl.formatMessage(buttonMessages.remove)}
                      icon={iconDelete}
                      width="18"
                      height="18"
                      fill={colors["categorical-corporate-07"].hex}
                      onClick={() => remove(index)}
                    />
                  )}
                </RowStyled>
              </ColStyled>
              {provided.placeholder}
            </RowStyled>
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <RowStyled middle="xs" around="xs" padding="10px" style={{ borderBottom: `1px solid ${colors["ui-04"].hex}` }}>
      <ColStyled xs={1}>{getIcon(watchItem.major_group) && getIcon(watchItem.major_group)()}</ColStyled>

      <ColStyled xs={5} padding="0">
        <RowStyled middle="xs">
          <Text marginRight="5px" value={watchItem.display_name.split(";")[0]} />
          <Chip size="13px" backgroundColor={newColorBySource[watchItem.source]} />
        </RowStyled>
      </ColStyled>

      <ColStyled xs={4}>
        <RowStyled middle="xs">
          <NumberInputHookForm control={control} name={`${fieldPrefix}.${index}.${FIELDS.QUANTITY}`} />
          <span style={{ marginLeft: "5px" }}>g</span>
        </RowStyled>
      </ColStyled>

      <ColStyled xs={2} padding="0">
        <RowStyled middle="xs" end="xs">
          <span style={{ marginRight: "5px" }}>{renderCost}</span>
          {canBeRemoved && (
            <Icon
              className="pointer"
              description={intl.formatMessage(buttonMessages.remove)}
              icon={iconDelete}
              width="18"
              height="18"
              fill={colors["categorical-corporate-07"].hex}
              onClick={() => remove(index)}
            />
          )}
        </RowStyled>
      </ColStyled>
    </RowStyled>
  );
};

export default FoodItem;
