import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { useFormContext } from "react-hook-form";

import { Star, StarFilled, OptimizedMenu, ManualMenu, CommunityMenu } from "components/Icons";
import { INFEASIBLE, OPTIMIZED, MANUAL } from "containers/MyMenus/labels";
import { changeFavourite } from "../actions";
import { ContainerIcons } from "./styles";

const StarAndMenuIcon = ({ data, comparisonMode }) => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();

  const infeasible = useMemo(() => R.pathOr("", ["optimization", "type"], data) === INFEASIBLE, [data]);
  const type = useMemo(() => R.pathOr("", ["type"], data), [data]);

  const onClickStar = useCallback(() => {
    const body = getValues();
    dispatch(changeFavourite({ id: data.id, body }));
  }, [data.id, dispatch, getValues]);

  const menuIcon = useMemo(() => {
    if (type === OPTIMIZED) return <OptimizedMenu />;
    if (type === MANUAL) return <ManualMenu />;
    return <CommunityMenu />;
  }, [type]);

  return (
    <ContainerIcons className={infeasible || comparisonMode ? "disable" : ""}>
      <div data-test-id="star-container" onClick={onClickStar}>
        {data.is_starred ? <StarFilled size={24} /> : <Star size={24} />}
      </div>
      <div>{menuIcon}</div>
    </ContainerIcons>
  );
};

export default StarAndMenuIcon;
