import React from "react";
import { colors } from "@wfp/ui";
import styled, { css } from "styled-components";
import Select from "react-select";

const MySelect = ({ marginTop, wfpStyle, ...props }) => <Select {...props} />;

export const UISelect = styled(MySelect)`
  text-align: left;
  margin-top: ${({ marginTop }) => marginTop || 0};
  ${({ wfpStyle }) =>
    wfpStyle &&
    css`
      margin-top: -10px;
      height: 30px;
      font-size: 16px;
      font-weight: 600;
      color: ${colors["text-01"].hex};
      .wfp--react-select__single-value {
        color: ${colors["text-01"].hex};
      }
      .wfp--react-select__indicators {
        margin-right: 3px;
      }
      > div[class*="control"] {
        border: none;
        box-shadow: none;
        padding: 5px 0 0;
        min-height: 24px;
        background-color: white;
      }
    `}
`;
