import React from "react";
import { colors } from "@wfp/ui";

export const Eggs = ({ size, disable }) => (
  <svg
    width={size || "25px"}
    height={size || "20px"}
    viewBox="0 0 29 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-216.000000, -411.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(216.000000, 411.000000)">
          <g>
            <path d="M12.2947917,5.63636364 C11.05625,2.6969697 9.12291667,0.272727273 6.91770833,0.272727273 C3.20208333,0.272727273 0.151041667,7.21212121 0.151041667,12.030303 C0.151041667,16.8484848 3.171875,19.7272727 6.91770833,19.7272727 C8.12604167,19.7272727 9.27395833,19.4242424 10.2708333,18.8484848 C8.73020833,17.3636364 7.79375,15.3333333 7.79375,12.969697 C7.79375,9.6969697 9.515625,7.03030303 12.2947917,5.63636364 Z M5.98125,6.36363636 C5.528125,7.21212121 4.50104167,9.48484848 4.50104167,12 C4.50104167,12.4545455 4.13854167,12.8484848 3.68541667,12.8484848 C3.23229167,12.8484848 2.86979167,12.4848485 2.86979167,12 C2.86979167,9.12121212 4.04791667,6.54545455 4.56145833,5.57575758 C4.77291667,5.15151515 5.28645833,5 5.67916667,5.21212121 C6.04166667,5.45454545 6.19270833,5.96969697 5.98125,6.36363636 Z" />
            <path d="M21.9614583,7 L19.2729167,10.030303 L21.5083333,12.7878788 C21.75,13.0909091 21.75,13.5454545 21.5083333,13.8484848 L19.2125,16.5454545 L21.025,19.1818182 C24.9822917,18.1515152 28.8489583,15.7575758 28.8489583,12.969697 C28.8489583,10.3636364 25.55625,8.15151515 21.9614583,7 Z" />
            <path d="M17.5510417,15.969697 L19.8166667,13.3030303 L17.5510417,10.5151515 C17.2791667,10.2121212 17.309375,9.72727273 17.58125,9.42424242 L20.1791667,6.48484848 C19.121875,6.27272727 18.0947917,6.15151515 17.1583333,6.15151515 C12.3552083,6.15151515 9.45520833,9.18181818 9.45520833,12.9393939 C9.45520833,16.6969697 12.3552083,19.7272727 17.1583333,19.7272727 C17.8229167,19.7272727 18.5479167,19.6666667 19.303125,19.5454545 L17.490625,16.969697 C17.2791667,16.6666667 17.309375,16.2424242 17.5510417,15.969697 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
