import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Text } from "components";
import {
  SubNavigation,
  SubNavigationHeader,
  SubNavigationContent,
  SubNavigationList,
  SubNavigationGroup,
  SubNavigationItem,
} from "@wfp/ui";
import { selectIsSuperUser } from "containers/App/selectors";
import Link from "../Link";
import { getPages } from "./utils";

const Content = ({ mainNavigationRef }) => {
  const isSuperUser = useSelector(selectIsSuperUser);

  const pages = useMemo(() => getPages(isSuperUser), [isSuperUser]);

  return (
    <SubNavigation>
      <SubNavigationHeader>
        <Text bold wrap fontSize="16px" value="Admin Area" />
      </SubNavigationHeader>
      <SubNavigationContent>
        <SubNavigationList>
          <SubNavigationGroup>
            {pages.map((page) => (
              <SubNavigationItem key={page.page}>
                <Link
                  path={page.path}
                  message={page.display_name}
                  mainNavigationRef={mainNavigationRef}
                  targetBlank={page.targetBlank}
                />
              </SubNavigationItem>
            ))}
          </SubNavigationGroup>
        </SubNavigationList>
      </SubNavigationContent>
    </SubNavigation>
  );
};

export default Content;
