import React from "react";
import { useSelector } from "react-redux";
import { selectRegions } from "containers/App/selectors";
import { selectTargetBeneficiaries, selectNutritionalReq } from "../selectors";
import TargetedGroup from "./TargetedGroup";
import NutritionalRequirements from "./NutritionalRequirements";
import FoodSources from "./FoodSources";
import MenuDuration from "./MenuDuration";
import Seasonality from "./Seasonality";
import Location from "./Location";

export default function MenuFields() {
  const regions = useSelector(selectRegions);
  const targetBeneficiaries = useSelector(selectTargetBeneficiaries);
  const nutritionalRequirements = useSelector(selectNutritionalReq);

  return (
    <>
      <TargetedGroup options={targetBeneficiaries} nutritionalRequirements={nutritionalRequirements} />
      <NutritionalRequirements />
      <FoodSources />
      <MenuDuration />
      <Location options={regions} />
      <Seasonality />
    </>
  );
}
