import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Link } from "@wfp/ui";

import routesPath from "containers/App/Routes/paths";
import { Actions, SourcingConstraintsSet, Title, Sentence } from "./components";
import messages from "./messages";

const OptimalWithoutSourcing = ({ setPage, menuId, closeModal, sourcingSet, majorGroups }) => {
  const intl = useIntl();
  const history = useHistory();

  const goToMenuSourcing = useCallback(() => {
    setPage(1);
    closeModal();
  }, [closeModal, setPage]);

  const goToResults = useCallback(() => {
    history.push(routesPath.results.replace(":id", menuId));
    closeModal();
  }, [closeModal, history, menuId]);

  return (
    <>
      <Title value={intl.formatMessage(messages.sourcingNotMet)} />
      <SourcingConstraintsSet sourcingSet={sourcingSet} majorGroups={majorGroups} />
      <Sentence
        value={intl.formatMessage(messages.goBackAndLink, {
          link: <Link onClick={goToMenuSourcing} children={intl.formatMessage(messages.sourcing)} />,
        })}
      />
      <Sentence
        value={intl.formatMessage(messages.optimalResultsLink, {
          link: <Link onClick={goToResults} children={intl.formatMessage(messages.results)} />,
        })}
      />
      <Actions middleScreen goToResults={goToResults} goToMenuSourcing={goToMenuSourcing} />
    </>
  );
};

export default OptimalWithoutSourcing;
