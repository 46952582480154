import React from "react";
import { injectIntl } from "react-intl";
import { Text } from "components";
import { colors } from "@wfp/ui";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { ModalStyled } from "components/UIKitStyled";
import { ACTIONS } from "containers/OptionsAndModals/constants";
import Email from "./Email";
import ShareButton from "./ShareButton";
import AddNewEmail from "./AddNewEmail";
import messages from "./messages";
import { PREFIX } from "./utils";

function Share({ menuAndAction, closeModal, intl }) {
  return (
    <Form
      initialValues={{ [`${PREFIX}1`]: { value: "" } }}
      onSubmit={() => {}}
      mutators={arrayMutators}
      render={({ values }) => (
        <ModalStyled
          open={menuAndAction && !!menuAndAction.id && menuAndAction.action === ACTIONS.SHARE}
          onRequestClose={closeModal}
          modalHeading={intl.formatMessage(messages.shareTitle)}
          minWidth="700px"
          modalFooter={() => <ShareButton closeModal={closeModal} id={menuAndAction.id} values={values} />}
        >
          <Text
            marginTop="-25px"
            marginBottom="30px"
            defaultColor={colors["text-02"].hex}
            value={intl.formatMessage(messages.shareInfo)}
          />
          {Object.keys(values).map((emailId, index) => (
            <Field key={emailId} index={index} values={values} name={`${emailId}.value`} component={Email} />
          ))}
          <AddNewEmail values={values} />
        </ModalStyled>
      )}
    />
  );
}

export default injectIntl(Share);
