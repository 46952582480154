import styled from "styled-components";
import { colors } from "@wfp/ui";

export const DayBoxInnerDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid ${colors["ui-04"].hex};
  overflow: hidden;
  margin: 0;

  &:last-child {
    border-right: 0;
  }
`;

export const GroupRowInnerDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  margin: 0;
  border-top: 1px solid ${colors["ui-04"].hex};
`;
