import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectMyMenus = () => (state) => R.pathOr(initialState, ["myMenus"], state);

export default () => createSelector(selectMyMenus(), (myMenusState) => myMenusState);

export const selectLoading = createSelector(selectMyMenus(), (myMenusState) => R.pathOr([], ["loading"], myMenusState));

export const selectMenus = createSelector(selectMyMenus(), (myMenusState) => R.pathOr([], ["results"], myMenusState));

export const selectCount = createSelector(selectMyMenus(), (myMenusState) => R.pathOr([], ["count"], myMenusState));

export const selectFilters = createSelector(selectMyMenus(), (myMenusState) => R.pathOr([], ["filters"], myMenusState));

export const selectComparisonList = createSelector(selectMyMenus(), (myMenusState) =>
  R.pathOr([], ["comparisonList"], myMenusState),
);

export const selectLoadingFilters = createSelector(selectMyMenus(), (myMenusState) =>
  R.pathOr(false, ["loadingFilters"], myMenusState),
);
