import { defineMessages } from "react-intl";

export default defineMessages({
  dailyFirstRow: {
    id: "repetition.dailyFirstRow",
    defaultMessage: "For example, if you select ‘Fruits & fruit products:",
  },
  dailySecondRow: {
    id: "repetition.dailySecondRow",
    defaultMessage:
      "Setting the min at '1' and max at '3'; ensures that every single meal will provide you with at least 1 fruit, and a maximum of 3",
  },
  dailyThirdRow: {
    id: "repetition.dailyThirdRow",
    defaultMessage: "Setting the min at '0' and max at '0'; you will not have fruits at all, any day.",
  },
  dailyFourthRow: {
    id: "repetition.dailyFourthRow",
    defaultMessage: "Setting the min at '0' and max at '1'; you may have one fruit a day or none.",
  },
  dailyFifthRow: {
    id: "repetition.dailyFifthRow",
    defaultMessage: "Setting the min at '2' and max at '2'; you will have two fruits at every meal.",
  },
  weeklyFirstRow: {
    id: "repetition.weeklyFirstRow",
    defaultMessage:
      "Setting the min at '5' and max at '10'; ensures that every five days (school week) you will have between 5 and 10 pieces of fruit in your menu.",
  },
  weeklySecondRow: {
    id: "repetition.weeklySecondRow",
    defaultMessage: "Setting the min at '0' and max at '0'; you will not have fruits at all, any day.",
  },
  weeklyThirdRow: {
    id: "repetition.weeklyThirdRow",
    defaultMessage:
      "Setting the min at '10' and max at '10'; you will have a total of 10 pieces of fruit in a school week.",
  },
  periodFirstRow: {
    id: "repetition.periodFirstRow",
    defaultMessage:
      "Setting the max at ‘3’; ensures that you will not have the same fruit more than 3 times in your menu.",
  },
  periodSecondRow: {
    id: "repetition.periodSecondRow",
    defaultMessage:
      "Setting the max at ‘1’; ensures that you will not have the same fruit more than once in your menu.",
  },
  periodThirdRow: {
    id: "repetition.periodThirdRow",
    defaultMessage:
      "Setting the max at ‘5’; ensures that any fruit can be present in as many meals as necessary in your menu.",
  },
  infoDaily: {
    id: "repetition.infoDaily",
    defaultMessage: "information on daily repetition",
  },
  infoWeekly: {
    id: "repetition.infoWeekly",
    defaultMessage: "information on weekly repetition",
  },
  infoMenu: {
    id: "repetition.infoMenu",
    defaultMessage: "information on menu repetition",
  },
});
