import "url-search-params-polyfill";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { history } from "utils/history";
import "./index.css";
import App from "./containers/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import appStore from "./AppStore";
import ScrollTop from "./ScrollTop";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.ENV,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

ReactDOM.render(
  <div className="page_root">
    <Provider store={appStore}>
      <ConnectedRouter history={history}>
        <ScrollTop />
        <App />
      </ConnectedRouter>
    </Provider>
  </div>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
