import styled, { css } from "styled-components";
import { colors } from "@wfp/ui";

export const DayNameContainer = styled.div`
  background-color: ${colors["ui-03"].hex};
  border-bottom: 1px solid ${colors["ui-04"].hex};
  border-top: 1px solid ${colors["ui-04"].hex};
  padding: 10px;
`;

export const DayContentInnerDiv = styled.div`
  margin: 0;
  border-bottom: 1px solid ${colors["ui-04"].hex};
  min-height: 32px;
  display: flex;
  align-items: center;

  ${({ padding }) =>
    padding &&
    css`
      ${{ padding }}
    `};

  &:last-child {
    border-bottom: 0;
  }
`;
