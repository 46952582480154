import React, { useCallback } from "react";
import { injectIntl } from "react-intl";
import * as R from "ramda";
import { Field } from "react-final-form";
import { colors } from "@wfp/ui";
import { Text, Checkbox } from "components";
import { useField } from "react-final-form";
import { ReactSelectWithDataTestId } from "components/utils";
import { Select } from "components/InputFields";
import { RowStyled, ColStyled } from "components/utils";
import messages from "./messages";
import { FIELDS } from "../utils";

function Location({ options, intl }) {
  const {
    input: { onChange: regionChange, value: regions },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.REGIONS}`);
  const {
    input: { onChange: checkboxChange },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.INCLUDE_WHOLE_COUNTRY}`);

  const setCheckboxTrue = useCallback(() => {
    if (R.isEmpty(regions)) checkboxChange(true);
  }, [checkboxChange, regions]);

  const onChangeCustom = useCallback(
    (element) => {
      if (element.value === "country") {
        regionChange([]);
      } else {
        regionChange([element]);
      }
    },
    [regionChange],
  );

  return (
    <ColStyled margin="0 0 40px">
      <Text bold fontSize="20px" value={intl.formatMessage(messages.location)} />
      <Text
        wrap
        marginBottom="5px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.locationInfo)}
      />
      <ColStyled xs={6} padding="0">
        <Field
          name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.REGIONS}`}
          placeholder={intl.formatMessage(messages.placeholderCountry)}
          options={[{ value: "country", label: intl.formatMessage(messages.wholeCountry) }, ...options]}
          data-test-id="location"
          components={ReactSelectWithDataTestId}
          component={Select}
          customChange={setCheckboxTrue}
          onChangeCustom={onChangeCustom}
          onChangeCustomOnly
          objectInsideArray
        />
        {!R.isEmpty(regions) && (
          <RowStyled middle="xs" margin="10px 0 0">
            <Field name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.INCLUDE_WHOLE_COUNTRY}`} type="checkbox">
              {({ input }) => (
                <Checkbox
                  noMarginBottom
                  labelText={intl.formatMessage(messages.checkboxCountry)}
                  id={`checkbox-${FIELDS.INCLUDE_WHOLE_COUNTRY}`}
                  onChange={input.onChange}
                  checked={input.checked}
                />
              )}
            </Field>
          </RowStyled>
        )}
      </ColStyled>
    </ColStyled>
  );
}

export default injectIntl(Location);
