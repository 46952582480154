import React from "react";
import { Text } from "components";
import { Chip } from "components/ChipWithLabel/styles";

export default function CipLegend({ value, fillColor, textColor, noEllipsis, fontSize = "14px" }) {
  return (
    <>
      <Chip size="13px" backgroundColor={fillColor} />
      <Text
        bold
        wrap
        value={value}
        marginLeft="5px"
        marginRight="18px"
        fontSize={fontSize}
        defaultColor={textColor}
        noEllipsis={noEllipsis}
      />
    </>
  );
}
