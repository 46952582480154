import {createSelector} from "@reduxjs/toolkit";
import * as R from "ramda";

import {initialState} from "./reducer";

const selectResults = () => (state) => R.pathOr(initialState, ["results"], state);
const selectMenuBasket = () => (state) => R.pathOr(initialState, ["menu", "data", "payload", "basket"], state)

export default () => createSelector(selectResults(), (resultsState) => resultsState);

export const selectLoading = createSelector(selectResults(), (resultsState) =>
  R.pathOr(false, ["loading"], resultsState)
);

export const selectLoadingNutritionalValues = createSelector(selectResults(), (resultsState) =>
  R.pathOr(false, ["loadingNutritionalValues"], resultsState)
);

export const selectInformations = createSelector(selectMenuBasket(), (basket) => {
    const reworkedRecs = []
    for (let rec of Object.values(R.pathOr({}, ["food_items"], basket))) {
      for (let src of Object.keys(R.pathOr([], ["prices"], rec))) {
        const item = {
          ...rec,
          display_name: `${rec['display_name']}; ${R.pathOr('', ["prices", src, "source_display"], rec)}`,
          food_item: rec["value"],
          ...rec["prices"][src],
          local_usd_additional_cost_per_gram:rec["prices"][src]["local_additional_cost_per_gram"],
          local_usd_price_per_gram:rec["prices"][src]["local_price_per_gram"],
          source:src,
          key: `${rec["value"]}_${src}`
        }
        delete item['prices']
        reworkedRecs.push(item)
      }
    }
    return reworkedRecs
  }
);

export const selectFoodItems = createSelector(selectResults(), (resultsState) =>
  R.pathOr(null, ["selectedFoodItems"], resultsState)
);

export const selectNutrients = createSelector(selectResults(), (resultsState) =>
  R.pathOr(null, ["nutrients"], resultsState)
);

export const selectNutRequirements = createSelector(selectResults(), (resultsState) =>
  R.pathOr([], ["nutritionalRequirements"], resultsState)
);

export const selectNutRestrictions = createSelector(selectResults(), (resultsState) =>
  R.pathOr([], ["nutrientRestrictions"], resultsState)
);

export const selectFormFoods = createSelector(selectResults(), (resultsState) =>
  R.pathOr({}, ["form", "values", "foods"], resultsState)
);

export const selectMajorGroups = createSelector(selectResults(), (resultsState) =>
  R.pathOr([], ["majorGroups"], resultsState)
);

export const selectAvailableRecipes = createSelector(selectResults(), (resultsState) =>
  R.pathOr(null, ["availableRecipes"], resultsState)
);

export const selectMenuRecipes = createSelector(selectResults(), (resultsState) =>
  R.pathOr(null, ["menuRecipes"], resultsState)
);

export const selectMenuScores = createSelector(selectResults(), (resultsState) =>
  R.pathOr(null, ["menuScores"], resultsState)
);
