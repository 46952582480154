import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { Loading } from "@wfp/ui";

import { initAdminPriceApprovalManage } from "containers/Admin/actions";
import { selectLoadingCountriesSlice } from "containers/Admin/selectors";
import { useAsyncForm } from "./useInitialValues";
import Prices from "./Prices";

const PriceApproval = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingCountriesSlice);

  const { useFormApi } = useAsyncForm();

  useEffect(() => {
    dispatch(initAdminPriceApprovalManage());
  }, [dispatch]);

  return (
    <FormProvider {...useFormApi}>
      <Loading active={loading} />
      <Prices />
    </FormProvider>
  );
};

export default PriceApproval;
