import React from "react";

import { WFPLogo } from "containers/App/Footer/styles";
import { HeaderWrapper } from "./styles";

const Header = () => {
  return (
    <HeaderWrapper className="wfp--main-navigation">
      <WFPLogo logo={"https://cdn.wfp.org/guides/ui/assets/v0.0.1/logos/standard/en/wfp-logo-standard-white-en.png"} />
      <label className="title-label">PLUS School Menus</label>
    </HeaderWrapper>
  );
};

export default Header;
