import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { selectFoodPrices } from "containers/Admin/selectors";
import { FIELDS, INITIAL_VALUES } from "./constants";

export const useInitialValues = () => INITIAL_VALUES;

// Define form default values and reset its slices when specific data changes
export const useAsyncForm = () => {
  const foodPrices = useSelector(selectFoodPrices);

  const formValues = useInitialValues();
  const useFormApi = useForm({ defaultValues: formValues });

  // "foodPrices" change
  useEffect(() => {
    useFormApi.reset({ [FIELDS.FOOD_PRICES]: foodPrices });
  }, [foodPrices, useFormApi]);

  return useMemo(() => ({ useFormApi }), [useFormApi]);
};
