import React from "react";
import { Beverages } from "./Beverages";
import { Eggs } from "./Eggs";
import { Fish } from "./Fish";
import { Fruits } from "./Fruits";
import { Grains } from "./Grains";
import { Herbs } from "./Herbs";
import { Legumes } from "./Legumes";
import { Milks } from "./Milks";
import { Meats } from "./Meats";
import { Oils } from "./Oils";
import { Roots } from "./Roots";
import { Sugars } from "./Sugars";
import { Vegetables } from "./Vegetables";
import { Supplements } from "./Supplements";
import { Composite } from "./Composite";

export const FOOD_GROUPS = {
  BEVERAGES: (props) => <Beverages {...props} />,
  FISH_SEAFOOD_AMPHIBIANS_AND_INVERTEBRATES: (props) => <Fish {...props} />,
  EGGS_AND_EGG_PRODUCTS: (props) => <Eggs {...props} />,
  FRUIT_AND_FRUIT_PRODUCTS: (props) => <Fruits {...props} />,
  HERBS_SPICES_AND_CONDIMENTS: (props) => <Herbs {...props} />,
  LEGUMES_NUTS_AND_SEEDS: (props) => <Legumes {...props} />,
  MILK_AND_MILK_PRODUCTS: (props) => <Milks {...props} />,
  MEAT_AND_OFFAL: (props) => <Meats {...props} />,
  OILS_AND_FATS: (props) => <Oils {...props} />,
  ROOTS_AND_TUBERS: (props) => <Roots {...props} />,
  SUGARS_AND_CONFECTIONARY: (props) => <Sugars {...props} />,
  VEGETABLES_AND_VEGETABLE_PRODUCTS: (props) => <Vegetables {...props} />,
  GRAINS_AND_GRAIN_BASED_PRODUCTS: (props) => <Grains {...props} />,
  SUPPLEMENTS_AND_INFANT_FOODS: (props) => <Supplements {...props} />,
  COMPOSITE_DISHES: (props) => <Composite {...props} />,
};
