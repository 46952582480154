import { useController } from "react-hook-form";
import { TextArea } from "@wfp/ui";

const TextAreaHookForm = ({ control, name, labelText = "", rows }) => {
  const { field } = useController({ control, name });

  return <TextArea labelText={labelText} rows={rows} value={field.value} onChange={field.onChange} />;
};

export default TextAreaHookForm;
