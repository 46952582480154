import React, { useMemo } from "react";
import * as R from "ramda";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoading, selectRoles } from "containers/App/selectors";
import { Loading } from "@wfp/ui";
import { ROLES } from "containers/Admin/constants";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const roles = useSelector(selectRoles);
  const loading = useSelector(selectLoading);

  const isGlobalAdmin = useMemo(() => roles.includes(ROLES.GLOBAL_ADMIN), [roles]);
  const isCountryAdmin = useMemo(() => roles.includes(ROLES.COUNTRY_ADMIN), [roles]);

  return (
    <Route
      {...rest}
      render={(props) => {
        // If we don't have any roles, it means the user profile is still being loaded
        if (R.isEmpty(roles)) {
          return <Loading active={loading} />;
        } else {
          if (isGlobalAdmin || isCountryAdmin) {
            return <Component {...props} />;
          } else {
            return <Redirect to={"/not-found"} />;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
