import React from "react";
import * as R from "ramda";
import { Text, Checkbox } from "components";
import { colors } from "@wfp/ui";
import { useIntl } from "react-intl";
import { useResponsiveHook } from "hooks";
import { Field } from "react-final-form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "../utils";
import messages from "./messages";

function Sources({ priceSources }) {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();

  return (
    <ColStyled margin="0 0 30px">
      <Text
        wrap
        marginBottom="5px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.priceInfo)}
      />
      {!R.isEmpty(priceSources) && (
        <RowStyled>
          {(priceSources || []).map((list, index) => (
            <ColStyled key={list.value} xs={!index ? 2 : isTabletOrMobile ? 4 : 3}>
              <Text marginBottom="5px" fontSize="16px" bold value={list.label} wrap />
              {list.checkboxes.map((source) => (
                <Field
                  key={`${list.value}-${source.value}`}
                  name={`${FIELDS.PRICING_LIST}.${list.value}.${source.value}`}
                  type="checkbox"
                >
                  {({ input }) => (
                    <Checkbox
                      id={`${FIELDS.PRICING_LIST}.${list.value}.${source.value}`}
                      name={input.name}
                      labelText={source.label}
                      checked={input.checked}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              ))}
            </ColStyled>
          ))}
        </RowStyled>
      )}
    </ColStyled>
  );
}

export default Sources;
