// TODO remove constants and create an actual FoodPrice type.
export const FIELDS = {
  FOOD_PRICES: "food_prices",
  ADDITIONAL_COSTS: "additional_costs",
  STAPLE: "is_staple",
  ITEM: "item",
  ITEM_DISPLAY_NAME: "item_display_name",
  MONTH: "month",
  ORIGIN: "origin",
  PRICE: "price",
  CURRENCY: "price_currency",
  PRICE_STATUS: "price_status",
  REGION: "region",
  PROCUREMENT_SOURCE: "source",
  PROCUREMENT_SOURCE_DISPLAY_NAME: "source_display",
  USERNAME: "username",
  YEAR: "year",
  TYPE: "type",
  QUANTITY: "quantity",
};

export const INITIAL_VALUES = {
  [FIELDS.FOOD_PRICES]: [],
};

export const ADDITIONAL_COSTS_INITIAL_VALUES = {
  [FIELDS.TYPE]: [],
  [FIELDS.PRICE]: "",
  [FIELDS.QUANTITY]: [],
};
