import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import routesPath from "containers/App/Routes/paths";

import RecipesTable from "./RecipesTable";
import RecipePage from "./RecipePage";
import { initAdminRecipeManage } from "containers/Admin/actions";

const Recipes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAdminRecipeManage());
  }, [dispatch]);

  return (
    <Switch>
      <Route path={routesPath.adminCountriesRecipesPage} component={RecipePage} />
      <Route component={RecipesTable} />
    </Switch>
  );
};

export default Recipes;
