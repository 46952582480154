import React, { useRef, useMemo, useCallback } from "react";
import * as R from "ramda";
import { injectIntl } from "react-intl";
import { Text, Line } from "components";
import { useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import useSize from "@react-hook/size";
import messages from "containers/Results/messages";
import { Container, ContainerList } from "./styles";
import ItemRow from "./ItemRow";

function ItemsList({ allValues, values, day, intl }) {
  const $add = useRef();
  const form = useForm();
  const [, height] = useSize($add);
  const dayKey = useMemo(() => `day_${day}`, [day]);

  const sumValues = useMemo(() => {
    if (!day || R.isEmpty(values[dayKey])) return 0;
    return values[dayKey].reduce((acc, item) => (acc = acc + item.quantity), 0);
  }, [day, dayKey, values]);

  const removeItem = useCallback(
    (index) => {
      const data = R.clone(values[dayKey]);
      const removedItem = data[index];
      data.splice(index, 1);
      form.change(dayKey, data);

      // Remove item from values.foods only if it doesnt'appear in other days
      let newFoods = values.foods;
      const numberOfTimes = allValues.filter((food) => food.display_name === removedItem.display_name).length;

      if (numberOfTimes === 1) {
        newFoods = R.omit([removedItem.display_name], values.foods);
      }

      form.change("foods", newFoods);
    },
    [form, dayKey, values, allValues],
  );

  return (
    <Container>
      <ContainerList heightToRemove={height - 50}>
        <FieldArray name={dayKey}>
          {({ fields }) =>
            fields.map((name, index) => (
              <ItemRow
                key={`row${index}`}
                name={name}
                index={index}
                removeItem={removeItem}
                dayKey={dayKey}
                values={values}
              />
            ))
          }
        </FieldArray>
      </ContainerList>
      <Line margin="0 0 20px" />
      <Text bold fontSize="16px" value={intl.formatMessage(messages.totalWeight, { number: sumValues.toFixed(1) })} />
    </Container>
  );
}

export default injectIntl(ItemsList);
