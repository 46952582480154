import styled from "styled-components";
import { colors } from "@wfp/ui";

export const ResultsContainer = styled.div`
  padding: 30px;
  margin-top: 20px;
  width: 100%;
  background-color: ${colors["ui-01"].hex};
  border-radius: 0.4rem;
`;

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 6fr) minmax(0, 3fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  grid-template-areas:
    "costs graph channels"
    "costs graph channels"
    "foodinfo graph channels";
`;
