import { defineMessages } from "react-intl";

export default defineMessages({
  values: {
    id: "nutritional.values",
    defaultMessage: "Nutritional values",
  },
  averageValues: {
    id: "nutritional.averageValues",
    defaultMessage: "Average nutritional values",
  },
  macros: {
    id: "nutritional.macros",
    defaultMessage: "Macros",
  },
  vitamins: {
    id: "nutritional.vitamins",
    defaultMessage: "Vitamins",
  },
  minerals: {
    id: "nutritional.minerals",
    defaultMessage: "Minerals",
  },
  notMet: {
    id: "nutritional.notMet",
    defaultMessage: "Nutritional requirements not met",
  },
  target: {
    id: "nutritional.target",
    defaultMessage: "Nutritional target",
  },
  warningNutrients: {
    id: "nutritional.warningNutrients",
    defaultMessage:
      "The nutritional requirements you requested can not be met with the list of available food and the conditions for menu structure and sourcing you set up. PLUS calculated instead the most nutritious menu you could get within those conditions",
  },
});
