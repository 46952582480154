import { useMemo } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { SelectHookForm, NumberInputHookForm } from "components/Form";
import { ModalStyled } from "components/UIKitStyled";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { selectDaysInWeek } from "containers/Menu/selectors";
import { FIELDS } from "containers/Results/constants";
import resultsMessages from "containers/Results/messages";
import { useResponsiveHook } from "hooks";
import { selectInformations } from "../selectors";

const AddNewItem = ({ buttonText, specificDay, showModal, toggleModal, onClose, onSubmit }) => {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();

  const { control, getValues } = useFormContext();
  const newFoodItem = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}` });

  const daysInWeek = useSelector(selectDaysInWeek);
  const foodItems = useSelector(selectInformations);

  const weeks = useMemo(() => {
    const values = getValues();
    const allKeys = Object.keys(values[FIELDS.MENU_COMPOSITION]);

    return [
      {
        label: intl.formatMessage(resultsMessages.allMeals),
        value: "all",
      },
      ...R.splitEvery(daysInWeek, allKeys).map((_, index) => ({
        label: intl.formatMessage(menuMessages.week, { week: index + 1 }),
        value: `week_${index + 1}`,
      })),
    ];
  }, [daysInWeek, getValues, intl]);

  const foodItemsOptions = useMemo(
    () => foodItems.map((el) => ({ value: el.food_item, label: el.display_name })),
    [foodItems],
  );

  const validate = useMemo(() => !newFoodItem[FIELDS.QUANTITY] || R.isEmpty(newFoodItem[FIELDS.ITEM]), [newFoodItem]);

  return (
    <>
      <Button widthAuto onClick={toggleModal} children={buttonText} />

      <ModalStyled
        overflow="visible"
        open={showModal}
        primaryButtonText={intl.formatMessage(buttonMessages.apply)}
        secondaryButtonText={intl.formatMessage(buttonMessages.cancel)}
        minWidth={isTabletOrMobile ? "85%" : "55%"}
        modalHeading={buttonText}
        primaryButtonDisabled={validate}
        onRequestSubmit={onSubmit}
        onSecondarySubmit={onClose}
        onRequestClose={onClose}
      >
        <RowStyled bottom="xs">
          {!specificDay && (
            <ColStyled xs={3}>
              <SelectHookForm
                control={control}
                name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.WEEK}`}
                options={weeks.length === 2 ? [weeks[0]] : weeks}
                label={intl.formatMessage(resultsMessages.selectMeal)}
              />
            </ColStyled>
          )}
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.ITEM}`}
              options={foodItemsOptions}
              placeholder={intl.formatMessage(resultsMessages.placeholderSelect)}
              label={intl.formatMessage(resultsMessages.itemAndSource)}
            />
          </ColStyled>
          <ColStyled xs={3}>
            <NumberInputHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.QUANTITY}`}
              labelText={intl.formatMessage(resultsMessages.portionSize)}
            />
          </ColStyled>
        </RowStyled>
      </ModalStyled>
    </>
  );
};

export default AddNewItem;
