import styled from "styled-components";

export const ChooseFromGlobalBasketContainer = styled.div`
  height: 100%;
  margin-top: 1.5rem;
`;

export const AddToFoodBasketContainer = styled.div`
  height: 100%;
  margin-top: 1.5rem;
`;
