import React, { useCallback } from "react";
import { RowStyled, ColStyled } from "components/utils";
import RcSlider from "rc-slider";
import { railStyle, trackStyle, handleStyle } from "./utils";

export default function Slider({
  className,
  color,
  minValue,
  maxValue,
  minLabel,
  maxLabel,
  input,
  customXs,
  customLg,
  changeSlider,
}) {
  const onChange = useCallback(
    (value) => {
      input.onChange(value);
      if (changeSlider) changeSlider(value);
    },
    [changeSlider, input],
  );

  return (
    <RowStyled middle="xs" className={`spaceBetween ${className || ""}`}>
      <div className="wfp--slider__range-label">{minLabel || minValue}</div>
      <ColStyled xs={customXs || 9} lg={customLg || 9}>
        <RcSlider
          min={minValue}
          max={maxValue}
          trackStyle={color ? { ...trackStyle, backgroundColor: color } : trackStyle}
          railStyle={railStyle}
          handleStyle={color ? { ...handleStyle, borderColor: color, backgroundColor: color } : handleStyle}
          onChange={onChange}
          value={Number(input.value)}
        />
      </ColStyled>
      <div className="wfp--slider__range-label">{maxLabel || maxValue}</div>
      <input className="wfp--input wfp--slider-text-input" readOnly value={input.value} />
    </RowStyled>
  );
}
