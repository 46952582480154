import { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { Icon, Tooltip, colors } from "@wfp/ui";
import { iconOverflowMenu } from "@wfp/icons";

import { ColStyled } from "components/utils";
import { PERMISSIONS } from "containers/Admin/constants";
import { selectUsername, selectPermissions } from "containers/App/selectors";
import { COMMUNITY, INFEASIBLE, MANUAL } from "containers/MyMenus/labels";
import { checkPermission } from "components/Unlock/utils";
import Delete from "./Delete";
import Rename from "./Rename";
import Clone from "./Clone";
import Remove from "./Remove";
import Edit from "./Edit";
import Share from "./Share";

const Options = ({
  original,
  setMenuAndAction,
  comparisonMode,
  onRemove,
  classNameIcon,
  noClone,
  placement,
  callbackOnDelete,
}) => {
  const username = useSelector(selectUsername);
  const userPermissions = useSelector(selectPermissions);
  const infeasible = useMemo(() => R.pathOr("", ["optimization", "type"], original) === INFEASIBLE, [original]);
  const createdByMe = useMemo(
    () => R.pathOr("", ["owner_details", "username"], original) === username,
    [original, username],
  );
  const menuType = useMemo(() => R.pathOr("", ["type"], original), [original]);

  const hasChangePermission = useMemo(() => {
    if (menuType === COMMUNITY) {
      return checkPermission(userPermissions, PERMISSIONS.CHANGE_COMMUNITY_MENU);
    } else if (menuType === MANUAL) {
      return checkPermission(userPermissions, PERMISSIONS.CHANGE_MANUAL_MENU);
    } else {
      return checkPermission(userPermissions, PERMISSIONS.CHANGE_OPTIMIZED_MENU);
    }
  }, [userPermissions, menuType]);

  const hasDeletePermission = useMemo(() => {
    if (menuType === COMMUNITY) {
      return checkPermission(userPermissions, PERMISSIONS.DELETE_COMMUNITY_MENU);
    } else if (menuType === MANUAL) {
      return checkPermission(userPermissions, PERMISSIONS.DELETE_MANUAL_MENU);
    } else {
      return checkPermission(userPermissions, PERMISSIONS.DELETE_OPTIMIZED_MENU);
    }
  }, [userPermissions, menuType]);

  return (
    <>
      {!comparisonMode && (
        <Tooltip
          createRefWrapper
          trigger="click"
          placement={placement || "bottom"}
          content={
            <ColStyled>
              {!infeasible && hasChangePermission && <Rename menu={original} setMenuAndAction={setMenuAndAction} />}
              {hasChangePermission && <Edit {...original} />}
              {!noClone && <Clone menu={original} setMenuAndAction={setMenuAndAction} />}
              {createdByMe && <Share menu={original} setMenuAndAction={setMenuAndAction} />}
              {onRemove && <Remove menu={original} onRemove={onRemove} />}
              {hasDeletePermission && <Delete {...original} callbackOnDelete={callbackOnDelete} />}
            </ColStyled>
          }
        >
          <span>
            <ColStyled margin="2px 5px" className="end-xs">
              <Icon
                className={`${classNameIcon || ""} pointer`}
                description=""
                icon={iconOverflowMenu}
                fill={colors["ui-05"].hex}
                width="17px"
                height="17px"
              />
            </ColStyled>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default Options;
