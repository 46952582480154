import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "components";
import messages from "components/Button/messages";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetMenu } from "containers/Menu/actions";
import routesPath from "containers/App/Routes/paths";
import { COMMUNITY, MANUAL } from "containers/MyMenus/labels";

function NextButton({ type, changeStatus }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const createMenu = useCallback(() => {
    dispatch(resetMenu());
    changeStatus();

    const currentPage = history.location.pathname;

    let whereToPush;

    switch (type) {
      case MANUAL:
        whereToPush = routesPath.createManual;
        break;
      case COMMUNITY:
        whereToPush = routesPath.createCommunity;
        break;
      default:
        whereToPush = routesPath.createOptimized;
    }

    history.push(whereToPush);

    if (type === MANUAL && routesPath.createManual === currentPage) {
      window.location.reload();
    } else if (type === COMMUNITY && routesPath.createCommunity === currentPage) {
      window.location.reload();
    } else if (routesPath.createOptimized === currentPage) {
      window.location.reload();
    }
  }, [dispatch, history, type, changeStatus]);

  return (
    <Row end="xs">
      <Col xs={5}>
        <Button disabled={!type} onClick={createMenu}>
          <FormattedMessage {...messages.next} />
        </Button>
      </Col>
    </Row>
  );
}

export default NextButton;
