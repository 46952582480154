import { memo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { Button } from "components";
import messages from "components/Button/messages";
import { selectLanguage, selectRateFromUSD, selectLocalCurrency } from "containers/App/selectors";
import compareMenusSelector from "containers/Compare/selectors";
import selectMenu from "containers/Menu/selectors";
import selectPDF from "./selectors";
import { loadMapping, mappingName, downloadBlob } from "./utils";

const PDF = ({ page, disabled }) => {
  const intl = useIntl();

  const preliminaryInfo = useSelector(selectMenu());
  const compareMenus = useSelector(compareMenusSelector());
  const pdf = useSelector(selectPDF());
  const conversionRate = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);
  const language = useSelector(selectLanguage);

  const [generating, setGenerating] = useState(false);

  const onDownload = useCallback(async () => {
    setGenerating(true);

    const blob = await loadMapping[page]({
      preliminaryInfo,
      pdf,
      compareMenus,
      language,
      conversionRate,
      localCurrency,
      intl,
    });
    downloadBlob(blob, mappingName[page](R.pathOr("", ["name"], preliminaryInfo), intl));

    setGenerating(false);
  }, [compareMenus, conversionRate, intl, language, localCurrency, page, pdf, preliminaryInfo]);

  return (
    <Button kind="secondary" widthAuto onClick={onDownload} disabled={disabled}>
      {generating ? intl.formatMessage(messages.generatingPdf) : intl.formatMessage(messages.downloadPdf)}
    </Button>
  );
};

export default memo(PDF);
