import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ModuleHeader, ModuleBody, ModuleFooter, Loading } from "@wfp/ui";

import { Text, NewModule } from "components";
import { RowStyled } from "components/utils";
import messages from "containers/FoodBasket/messages";
import { selectLoadingFoodPrice } from "containers/FoodBasket/selectors";
import ChooseFromGlobalBasket from "./ChooseFromGlobalBasket";
import AddToFoodBasket from "./AddToFoodBasket";
import Footer from "./Footer";

const AddPersonalFoodItem = ({ close }) => {
  const intl = useIntl();
  const loadingFoodPrice = useSelector(selectLoadingFoodPrice);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <Text bold fontSize="16px" value={intl.formatMessage(messages.addPersonalFoodItem)} />
      </ModuleHeader>
    ),
    [intl],
  );

  const body = useMemo(
    () => (
      <ModuleBody>
        <RowStyled>
          <ChooseFromGlobalBasket />
          <AddToFoodBasket />
        </RowStyled>
      </ModuleBody>
    ),
    [],
  );

  const footer = useMemo(
    () => (
      <ModuleFooter>
        <Footer close={close} />
      </ModuleFooter>
    ),
    [close],
  );

  return (
    <>
      <NewModule header={header} body={body} footer={footer} />
      <Loading active={loadingFoodPrice} />
    </>
  );
};

export default AddPersonalFoodItem;
