import { FOOD_GROUPS } from "./FoodGroups";
import { Pin } from "./Pin";
import { Star } from "./Star";
import { Share } from "./Share";
import { StarFilled } from "./StarFilled";
import { GridView } from "./GridView";
import { ChartView } from "./ChartView";
import { Adjust } from "./Adjust";
import { Basket } from "./Basket";
import { Meal } from "./Meal";
import { Menu } from "./Menu";
import { OptimizedMenu } from "./OptimizedMenu";
import { ManualMenu } from "./ManualMenu";
import { CommunityMenu } from "./CommunityMenu";

const getIcon = (name) => FOOD_GROUPS[name.replace(/,/g, "").replace(/-/g, "_").toUpperCase().replace(/ /g, "_")];

export {
  FOOD_GROUPS,
  getIcon,
  Pin,
  Star,
  Share,
  StarFilled,
  GridView,
  ChartView,
  Adjust,
  Basket,
  Meal,
  Menu,
  OptimizedMenu,
  ManualMenu,
  CommunityMenu,
};
