import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Row } from "react-flexbox-grid";
import { Layout, Text, Line, Button } from "components";
import { ColStyled } from "components/utils";
import { ModuleWhite } from "components/UIKitStyled";
import buttonMessages from "components/Button/messages";
import { colors, Loading } from "@wfp/ui";
import {
  loadSelectedNutrients,
  loadAllNutrients,
  updateFormState,
  setMenuType,
  updateNutrients,
  resetNutrients,
} from "containers/Admin/actions";
import { loadCountries } from "containers/SelectCountry/actions";
import {
  selectSelectedNutrients,
  selectAllNutrients,
  selectLoadingCountriesSlice,
  selectMenuType,
} from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";
import { useInitialValues } from "./useInitialValues";
import { validation } from "./utils";
import SubsetWarning from "./SubsetWarning";
import Nutrient from "./Nutrient";
import { FIELDS } from "../constants";
import countriesMessages from "../messages";
import { getMenuType, getNutrientsDescription } from "../utils";

const Nutrients = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const selectedNutrients = useSelector(selectSelectedNutrients);
  const allNutrients = useSelector(selectAllNutrients);
  const countries = useSelector(selectCountries);
  const loadingNutrients = useSelector(selectLoadingCountriesSlice);
  const menuType = useSelector(selectMenuType);
  const initialValues = useInitialValues();

  const [initialize, setInitialize] = useState();

  // Cleanup nutrients and selectedNutrients when changing tabs
  useEffect(() => {
    dispatch(resetNutrients());
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const menuType = getMenuType(location.pathname);
    dispatch(setMenuType({ menuType }));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!countries) {
      dispatch(loadCountries());
    }
  }, [dispatch, countries]);

  // Do not run API calls if countries are missing or nutrients are already in place
  useEffect(() => {
    if (!countries) return;

    if (!selectedNutrients) {
      dispatch(loadSelectedNutrients());
    }
  }, [dispatch, countries, selectedNutrients]);

  useEffect(() => {
    if (!allNutrients) {
      dispatch(loadAllNutrients());
    }
  }, [dispatch, allNutrients]);

  // We initialize data only when both selected nutrient and whole nutrients list are available
  useDeepCompareEffect(() => {
    if (selectedNutrients && allNutrients) {
      setInitialize(initialValues);
    }
  }, [initialValues, selectedNutrients, allNutrients]);

  const description = useMemo(() => getNutrientsDescription(intl, menuType), [intl, menuType]);

  const updateForm = useCallback(
    (state) => {
      dispatch(updateFormState({ formState: state }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    dispatch(updateNutrients());
  }, [dispatch]);

  return (
    <Layout withBreadcrumbs={false} backgroundColor={colors["ui-02"].hex} marginTop="0">
      <ModuleWhite margin="15px 0" paddingContent="10px 30px 20px">
        <Text bold fontSize="16px" value={intl.formatMessage(countriesMessages.nutrientsTitle)} />
        <SubsetWarning menuType={menuType} />
        <Line margin="10px -30px" />

        {!R.isEmpty(initialize) ? (
          <Form
            initialValues={initialize}
            mutators={arrayMutators}
            onSubmit={() => {}}
            render={({ values }) => {
              const disableSubmit = validation(menuType, values);

              return (
                <>
                  <FormSpy subscription={{ values: true }} onChange={updateForm} />
                  <Loading active={loadingNutrients} />

                  <ColStyled xs>
                    <Text bold fontSize="20px" marginBottom="20px" value={description} />
                    <FieldArray name={FIELDS.NUTRIENTS}>
                      {({ fields }) => fields.map((name) => <Nutrient key={`field-${name}`} name={name} />)}
                    </FieldArray>
                  </ColStyled>

                  <Line margin="15px -30px" />
                  <Row end="xs">
                    <Button type="submit" onClick={onSubmit} disabled={disableSubmit} className="col-xs-2">
                      <FormattedMessage {...buttonMessages.submit} />
                    </Button>
                  </Row>
                </>
              );
            }}
          />
        ) : (
          <Loading active={true} />
        )}
      </ModuleWhite>
    </Layout>
  );
};

export default Nutrients;
