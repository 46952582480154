import React from "react";
import styled from "styled-components";
import { mediaTablet } from "components/utils";
import { Wrapper, colors } from "@wfp/ui";

const StyledWrapper = ({ backgroundColor, overflow, ...props }) => <Wrapper {...props} />;

export const LayoutContainer = styled.div`
  flex-grow: 1;
  padding: 20px 30px 30px;
  background-color: ${({ backgroundColor }) => backgroundColor || "var(--background-color)"};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "var(--header-height)")};

  ${mediaTablet} {
    padding: 20px 0 0;
  }
`;

export const UIWrapper = styled(StyledWrapper)`
  background-color: ${({ backgroundColor }) => backgroundColor || colors["ui-01"].hex};
  padding: 0;

  .wfp--wrapper {
    padding: 0;
    overflow: ${({ overflow }) => overflow};
  }
`;
