import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { colors, Icon } from "@wfp/ui";
import { iconArrowRight } from "@wfp/icons";

import { Text } from "components";
import { SelectHookForm, MultiSelectHookForm, CheckboxHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { selectRegions, selectFoodSources } from "containers/App/selectors";
import menuMessages from "containers/Menu/messages";
import { FIELDS } from "containers/GeneralInfo/constants";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import { getPeriodOptions } from "containers/GeneralInfo/GeneralForm/utils";
import { useMonths } from "utils/utils";

const MenuFields = () => {
  const intl = useIntl();
  const { control, setValue } = useFormContext();
  const [showIncludeWholeCountry, setShowIncludeWholeCountry] = useState(false);

  const seasonality = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.SEASONALITY}`,
  });
  const startMonth = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.START_MONTH}`,
  });
  const endMonth = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.END_MONTH}`,
  });

  const watchRegions = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.REGIONS}`,
  });

  useEffect(() => {
    setShowIncludeWholeCountry(
      watchRegions.length > 0 && watchRegions.filter((item) => item.region === "country").length === 0
    );
  }, [watchRegions]);

  const regions = useSelector(selectRegions);
  const procurementSources = useSelector(selectFoodSources);

  const periodOptions = useMemo(() => getPeriodOptions(intl), [intl]);
  const months = useMonths(intl);

  const onRegionChange = useCallback(({ value, meta, field }) => {
    if (meta.action === "clear") {
      return field.onChange([]);
    }
    return field.onChange(value);
  }, []);

  const onSourcingChannelsChange = useCallback(({ value, meta, field }) => {
    if (meta.action === "clear") {
      return field.onChange([]);
    }

    // If user selected "All sources", set it as selected option.
    // Note: when removing a selected option we won't have meta.option, that's why we need to use optional chaining operator.
    const userSelectedAllSources = meta?.option?.value === "all";
    if (userSelectedAllSources) {
      field.onChange([meta.option]);
      return;
    }

    // If user didn't select "All sources":
    // - if "All sources" is the selected option, remove it and set the incoming one;
    // - if "All sources" is NOT the selected option, put incoming one along with selected ones.
    const allSourcesSelected = Boolean(value.find((option) => option.value === "all"));

    if (allSourcesSelected) {
      field.onChange([meta.option]);
    } else {
      field.onChange(value);
    }
  }, []);

  const onSeasonalityChange = useCallback(
    (el, field) => {
      // If setting to "Year around", update start and end month accordingly.
      if (el.value === "y") {
        if (startMonth !== 1) {
          setValue(`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.START_MONTH}`, 1);
        }
        if (endMonth !== 12) {
          setValue(`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.END_MONTH}`, 12);
        }
      }

      field.onChange(el.value);
    },
    [endMonth, setValue, startMonth]
  );

  return (
    <ColStyled xs={6}>
      <ColStyled padding="0 0 30px">
        <Text bold fontSize="16px" value={<FormattedMessage {...messages.location} />} />
        <ColStyled xs={6} padding="0">
          <MultiSelectHookForm
            control={control}
            name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.REGIONS}`}
            options={regions}
            helperText={<FormattedMessage {...messages.locationInfo} />}
            customOnChange={onRegionChange}
            placeholder={<FormattedMessage {...messages.locationAll} />}
          />
        </ColStyled>
        {showIncludeWholeCountry && (
          <RowStyled middle="xs" margin="10px 0 0">
            <CheckboxHookForm
              name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.INCLUDE_WHOLE_COUNTRY}`}
              noMarginBottom
              control={control}
              labelText={intl.formatMessage(messages.checkboxCountry)}
              id={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.INCLUDE_WHOLE_COUNTRY}`}
            />
          </RowStyled>
        )}
      </ColStyled>

      <ColStyled padding="0 0 30px">
        <Text bold fontSize="16px" value={<FormattedMessage {...menuMessages.sourcing} />} />
        <ColStyled xs={6} padding="0">
          <MultiSelectHookForm
            control={control}
            name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.SOURCING_CHANNELS}`}
            options={procurementSources}
            helperText={<FormattedMessage {...menuMessages.sourcingInfo} />}
            customOnChange={onSourcingChannelsChange}
            placeholder={<FormattedMessage {...menuMessages.sourcingAll} />}
          />
        </ColStyled>
      </ColStyled>

      <Text bold fontSize="16px" value={<FormattedMessage {...messages.seasonality} />} />
      <div className="wfp--form__helper-text">{<FormattedMessage {...messages.seasonalityInfo} />}</div>
      <RowStyled middle="xs">
        <ColStyled xs={3} padding="0">
          <SelectHookForm
            control={control}
            name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.SEASONALITY}`}
            options={periodOptions}
            customOnChange={onSeasonalityChange}
          />
        </ColStyled>
        <ColStyled xs={4} className={seasonality === "y" ? "disable" : ""}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.START_MONTH}`}
            options={months}
            data-test-id="start-month"
          />
        </ColStyled>
        <ColStyled xs={1} padding="0">
          <RowStyled center="xs">
            <Icon description="period" icon={iconArrowRight} width="20" height="20" fill={colors["ui-05"].hex} />
          </RowStyled>
        </ColStyled>
        <ColStyled xs={4} padding="0 0 0 8px" className={seasonality === "y" ? "disable" : ""}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.END_MONTH}`}
            options={months}
            data-test-id="end-month"
          />
        </ColStyled>
      </RowStyled>
    </ColStyled>
  );
};

export default MenuFields;
