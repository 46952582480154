import React, { useCallback, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { colors, Loading, ModuleBody, ModuleFooter } from "@wfp/ui";

import { useResponsiveHook } from "hooks";
import {
  selectLoadingCountriesSlice,
  selectTableParams,
  selectAdminRecipes,
  selectAdminRecipesCount,
} from "containers/Admin/selectors";
import { loadAdminRecipes, resetTableFilters, resetTableParams } from "containers/Admin/actions";

import { ColStyled } from "components/utils";
import { getColumns } from "./utils";
import { reworkOrdering } from "utils/utils";
import buttonMessages from "components/Button/messages";
import routesPath from "containers/App/Routes/paths";

import Filters from "./Filters";
import { Button, Table, NewModule } from "components";

const RecipesTable = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();
  const recipes = useSelector(selectAdminRecipes, shallowEqual);
  const recipesCount = useSelector(selectAdminRecipesCount);
  const { page: pageNum, page_size: pageSize } = useSelector(selectTableParams, R.equals);
  const loading = useSelector(selectLoadingCountriesSlice);

  const columns = useMemo(() => getColumns(intl), [intl]);

  useEffect(() => {
    return () => {
      dispatch(resetTableParams());
      dispatch(resetTableFilters());
    };
  }, [dispatch]);

  const loadData = useCallback(
    (page, pageSize, ordering) => {
      let params = { page, page_size: pageSize };
      if (ordering && !R.isEmpty(ordering)) {
        params = { ...params, ordering: reworkOrdering(ordering) };
      }
      dispatch(loadAdminRecipes({ params }));
    },
    [dispatch]
  );

  const handleClickRow = useCallback(
    (row, cell) => {
      if (!row || !row.original) return;
      history.push(`${routesPath.adminCountriesRecipes}/${row.original.id}`);
    },
    [history]
  );

  const handleCreateRecipeClick = useCallback(() => {
    history.push(`${routesPath.adminCountriesRecipes}/create`);
  }, [history]);

  const tableProps = {
    manual: true,
    pointer: true,
    columns,
    data: recipes,
    pageNum,
    pageSize,
    totalItems: recipesCount,
    loadData,
    loading: loading,
    pageSizeOptions: [10, 30, 40],
    initialState: { pageSize: 10 },
    border: `1px solid ${colors["ui-04"].hex}`,
    margin: isTabletOrMobile ? 0 : "30px 0 0",
    onClickRow: handleClickRow,
  };

  return (
    <ColStyled xs padding="20px 8px" data-test-id="recipes-table-component">
      <Filters />
      <Loading active={loading} />
      <NewModule
        body={
          <ModuleBody>
            <Table {...tableProps} />
          </ModuleBody>
        }
        footer={
          <ModuleFooter>
            <Button
              widthAuto
              children={intl.formatMessage(buttonMessages.createRecipe)}
              onClick={handleCreateRecipeClick}
            />
          </ModuleFooter>
        }
        contentPadding={"0"}
        footerPadding={"0.8rem 1.2rem"}
      />
    </ColStyled>
  );
};

export default RecipesTable;
