import React, { useState, useCallback } from "react";
import * as R from "ramda";
import { Icon } from "@wfp/ui";
import { iconStartOutline } from "@wfp/icons";
import { Text, Line } from "components";
import { ColStyled, RowStyled } from "components/utils";
import { ContainerVideo, ReactPlayerWrapper, TextWrapper, VideoPlaceholder } from "./styles";

const VideosPage = ({ tutorials }) => {
  const getDefaultSelectedVideo = useCallback(() => {
    const item = Object.values(tutorials || {}).find((item) => item.videos.length > 0);
    if (item) return item.videos[0];
    return null;
  }, [tutorials]);

  const [selectedVideo, setSelectedVideo] = useState(getDefaultSelectedVideo);

  return (
    <RowStyled>
      <ColStyled xs={7} padding="0 5rem 0 0">
        {selectedVideo && (
          <div data-test-id="selected-video-container">
            <ContainerVideo margin="0 0 10px">
              <ReactPlayerWrapper controls volume={1} url={selectedVideo.video} width="100%" />
            </ContainerVideo>
            <ColStyled>
              <Text fontSize="20px" bold wrap value={selectedVideo.question} marginBottom="0.5rem" />
              <Text fontSize="14px" wrap value={selectedVideo.answer} />
            </ColStyled>
          </div>
        )}
      </ColStyled>
      <ColStyled xs={5} padding="0">
        {Object.values(tutorials || {}).map(
          (tutorial, index) =>
            R.pathOr([], ["videos"], tutorial).length > 0 && (
              <div data-test-id="tutorial-item-container" key={index} style={{ marginBottom: "1.5rem" }}>
                <Text fontSize="18px" wrap bold value={tutorial.title} marginBottom="1rem" />
                {R.pathOr([], ["videos"], tutorial).map((video, i) => (
                  <div key={i}>
                    <RowStyled cursor="pointer" flexWrap="nowrap" onClick={() => setSelectedVideo(video)}>
                      <VideoPlaceholder>
                        <Icon icon={iconStartOutline} height="40px" width="40px" />
                      </VideoPlaceholder>
                      <ColStyled>
                        <TextWrapper fontSize="16px" bold wrap lines={1} value={video.question} marginBottom="0.3rem" />
                        <TextWrapper fontSize="14px" wrap lines={3} value={video.answer} />
                      </ColStyled>
                    </RowStyled>
                    <Line margin="15px 0 15px" />
                  </div>
                ))}
              </div>
            )
        )}
      </ColStyled>
    </RowStyled>
  );
};

export default VideosPage;
