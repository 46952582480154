import React from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { Icon, TextInput } from "@wfp/ui";

export const IconStyled = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
`;

const MyTextInput = ({ ...props }) => <TextInput {...props} />;

export const TextInputStyled = styled(MyTextInput)`
  ::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 16px;
    letter-spacing: 0.7;
  }
`;

export const FiltersAndSortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.75rem 0 0 0;
  height: 100%;
  overflow-y: auto;
`;

export const ContainerText = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const Title = styled(TextareaAutosize)`
  display: block;
  font-size: ${({ fontSize }) => fontSize || "1.75rem"};
  outline: none;
  padding: 5px;
  margin-right: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid #8ca4b5;
  pointer-events: "auto";
  resize: none;
  font-family: "Open Sans", sans-serif;
  :focus {
    outline: none;
    box-shadow: 0px 2px 8px 0 #c2dbec;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
