import * as R from "ramda";

import { FIELDS } from "./constants";

// Returns "true" if button will be disabled
export const validation = (foodPrice: Record<string, any>) => {
  // Price check
  if (!foodPrice[FIELDS.PRICE]) {
    return true;
  }

  // Additional costs checks
  if (!R.isEmpty(foodPrice[FIELDS.ADDITIONAL_COSTS])) {
    const additionalCosts = foodPrice[FIELDS.ADDITIONAL_COSTS];
    const failedValidation = additionalCosts.some((cost: Record<string, any>) => {
      return !cost[FIELDS.PRICE] || R.isEmpty(cost[FIELDS.TYPE]) || R.isEmpty(cost[FIELDS.QUANTITY]);
    });

    if (failedValidation) return true;
  }

  return false;
};
