import { useIntl } from "react-intl";
import { colors, Icon, Link } from "@wfp/ui";
import { iconWarning } from "@wfp/icons";
import { Row } from "react-flexbox-grid";

import { Text } from "components";
import buttonMessages from "components/Button/messages";
import { ModalStyled } from "components/UIKitStyled";
import appMessages from "containers/App/messages";
import routesPath from "containers/App/Routes/paths";
import messages from "./messages";

export const showLowNumberModal = (groups, items) => groups <= 5 || items <= 10;

const LowNumberModal = ({ visible, groupsCount, itemsCount, clickOnContinue, closeModal }) => {
  const intl = useIntl();

  return (
    <ModalStyled
      open={visible}
      padding="0 3rem"
      primaryButtonText={intl.formatMessage(buttonMessages.continue)}
      secondaryButtonText={intl.formatMessage(buttonMessages.changeSettings)}
      onRequestSubmit={clickOnContinue}
      onRequestClose={closeModal}
      onSecondarySubmit={closeModal}
    >
      <Row middle="xs">
        <Icon width="28" height="28" fill={colors["support-03"].hex} description=" " icon={iconWarning} />
        <Text bold marginLeft="10px" fontSize="28px" value={intl.formatMessage(messages.tag)} />
      </Row>
      <Text
        wrap
        fontSize="16px"
        value={intl.formatMessage(messages.title, { numGroups: groupsCount, numItems: itemsCount })}
      />
      <Text
        wrap
        marginTop="15px"
        fontSize="16px"
        value={intl.formatMessage(messages.info, {
          link: <Link inline href={routesPath.foodBasket} children={intl.formatMessage(appMessages.foodPricesLower)} />,
        })}
      />
    </ModalStyled>
  );
};

export default LowNumberModal;
