import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  overflow-wrap: break-word;
`;
