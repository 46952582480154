import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled } from "components/utils";
import { saveResultsRequest } from "containers/Results/actions";
import { FIELDS, INITIAL_VALUES } from "containers/Results/constants";
import { useInitialValues } from "containers/Results/useInitialValues";
import { getMenuDiff, getRecipesDiff } from "./utils";

const Footer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: menuId } = useParams();

  const { getValues, reset } = useFormContext();

  const initialValues = useInitialValues();

  const save = useCallback(() => {
    const values = getValues();

    const menuComposition = values[FIELDS.MENU_COMPOSITION];
    const mealNames = values[FIELDS.MEAL_NAMES];

    const deletedIds = values[FIELDS.DELETED].filter(Boolean);
    const menuDiff = getMenuDiff(initialValues[FIELDS.MENU_COMPOSITION], menuComposition, deletedIds);
    const menuUpdates = { ...menuDiff, deleted: deletedIds };

    const deletedRecipesIds = values[FIELDS.DELETED_RECIPES];
    const recipes = values[FIELDS.INCLUDED_RECIPES];
    const recipesDiff = getRecipesDiff(initialValues[FIELDS.INCLUDED_RECIPES], recipes);
    const recipesUpdates = { ...recipesDiff, deleted: deletedRecipesIds };

    // Reset FIELDS.DELETED and FIELDS.DELETED_RECIPES array: if users keep changing the menu content we need to make sure these list are reset after each "Save".
    const cb = () => {
      reset({
        ...values,
        [FIELDS.DELETED]: INITIAL_VALUES[FIELDS.DELETED],
        [FIELDS.DELETED_RECIPES]: INITIAL_VALUES[FIELDS.DELETED_RECIPES],
      });
    };

    dispatch(
      saveResultsRequest({
        id: menuId,
        body: { menuUpdates, mealNames, recipesUpdates },
        cb,
      }),
    );
  }, [getValues, initialValues, reset, dispatch, menuId]);

  return (
    <RowStyled end="xs" padding="10px">
      <Button widthAuto onClick={save} children={intl.formatMessage(buttonMessages.save)} />
    </RowStyled>
  );
};

export default Footer;
