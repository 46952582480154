import { call, select, put, takeLatest, all } from "redux-saga/effects";

import { setAlert } from "containers/App/actions";
import { loadConstantsSaga, loadRegionsSaga } from "containers/App/sagas";
import { loadTargetBeneficiariesSaga } from "containers/GeneralInfo/GeneralForm/sagas";
import menuMessages from "containers/Menu/messages";
import { searchMenus, deleteMenu, postFavouriteMenu } from "api/api";
import {
  loadStarted,
  loadSuccess,
  loadEnded,
  searchMyMenus,
  changeFavourite,
  removeMenu,
  loadFiltersRequest,
  loadFiltersStarted,
  loadFiltersEnded,
} from "./actions";
import { selectFilters } from "./selectors";
import { getReworkedFilters } from "./utils";

export function* _loadMyMenus(body) {
  try {
    const filters = yield select(selectFilters);
    const payload = body || filters;
    const { params, data } = getReworkedFilters(payload);
    const { results, count } = yield call(searchMenus, params, data);
    yield put(loadSuccess({ results, count, params }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* changeFavouriteById({ payload: { id, body } }) {
  try {
    yield put(loadStarted());
    yield call(postFavouriteMenu, id);
    yield* _loadMyMenus(body);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadMenus({ payload: { body } }) {
  try {
    yield put(loadStarted());
    yield* _loadMyMenus(body);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* removeMenuById({ payload: { id, cb } }) {
  try {
    yield put(loadStarted());
    yield call(deleteMenu, id);
    if (cb) cb(id);
    yield put(loadEnded());
    yield put(
      setAlert({
        type: "success",
        text: menuMessages.menuDeleted,
        title: menuMessages.genericSuccess,
      })
    );
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadFiltersSaga() {
  try {
    yield put(loadFiltersStarted());
    yield all([loadConstantsSaga(), loadRegionsSaga({ inline: true }), loadTargetBeneficiariesSaga()]);
    yield put(loadFiltersEnded());
  } catch (e) {
    yield put(loadFiltersEnded());
    yield put(setAlert(e));
  }
}

export default function* myMenusSaga() {
  yield takeLatest(searchMyMenus, loadMenus);
  yield takeLatest(changeFavourite, changeFavouriteById);
  yield takeLatest(removeMenu, removeMenuById);
  yield takeLatest(loadFiltersRequest, loadFiltersSaga);
}
