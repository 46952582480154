import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as R from "ramda";
import { colors, Icon } from "@wfp/ui";
import { iconUser, iconWfpHumFoodSecurityClusterPos } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import foodBasketMessages from "containers/FoodBasket/messages";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useUserProvidedNumbers } from "containers/GeneralInfo/GeneralForm/hooks";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import AddPersonalFoodItem from "./AddPersonalFoodItem";
import { defaultColumnNumber } from "./constants";
import Filters from "./Filters";
import FoodGroup from "./FoodGroup";
import { ItemsContainer } from "./styles";

const PageOne = ({ openAddNewFoodItem, openAddNewFoodItemSection, resetAndCloseAddNewFoodItem, editFoodItem }) => {
  const { control } = useFormContext();
  const basket = useWatch({ control, name: FIELDS.BASKET });

  const { groupsCount, itemsCount, personalItemsCount, customItemsCount, customSourcesNumber } =
    useUserProvidedNumbers();

  const itemsChunks = useMemo(() => {
    // Persist key inside each food item otherwise we would end up losing that, which is necessary when handling stuff in the modal.
    const basketArray = Object.keys(basket).map((itemId) => ({ ...basket[itemId], id: parseInt(itemId) }));
    const groupBy = R.groupBy((item) => item.major_group, basketArray);

    const arrFromObject = Object.entries(groupBy).map(([key, value]) => ({ [key]: value }));

    // Calc chunk length based on number of incoming groups. Default to 1 if we have less than "defaultColumnNumber" groups
    const numOfGroups = arrFromObject.length;
    const chunkSize = Math.ceil(numOfGroups / defaultColumnNumber) || 1;
    const chunks = R.splitEvery(chunkSize, arrFromObject);

    return chunks;
  }, [basket]);

  const renderList = useMemo(() => {
    if (R.isEmpty(itemsChunks)) {
      return (
        <RowStyled middle="xs" center="xs" flex={1} span>
          <Text fontSize="16px" value={<FormattedMessage {...foodBasketMessages.noResults} />} />
        </RowStyled>
      );
    }

    return itemsChunks.map((chunk) => {
      return chunk.map((group) => {
        const labelGroup = Object.keys(group)[0];
        const groupItems = group[labelGroup];

        return <FoodGroup key={labelGroup} title={labelGroup} items={groupItems} onClick={editFoodItem} />;
      });
    });
  }, [itemsChunks, editFoodItem]);

  return (
    <>
      {/* Add new food item section */}
      {openAddNewFoodItem && <AddPersonalFoodItem resetAndClose={resetAndCloseAddNewFoodItem} />}

      {/* Header (Food basket + Add a new food item button) */}
      <RowStyled middle="xs" between="xs" margin="0 0 10px">
        <ColStyled xs={6}>
          <RowStyled middle="xs">
            <Text bold fontSize="22px" value={<FormattedMessage {...messages.foodBasketModalHeaderTitle} />} />
            <Text
              fontSize="16px"
              marginLeft="5px"
              marginTop="2px"
              defaultColor={colors["text-02"].hex}
              value={<FormattedMessage {...messages.foodBasketModalHeaderGroups} />}
            />
            <Text bold fontSize="16px" marginLeft="5px" marginTop="2px" value={`${groupsCount}, `} />
            <Text
              fontSize="16px"
              marginLeft="5px"
              marginTop="2px"
              defaultColor={colors["text-02"].hex}
              value={<FormattedMessage {...messages.foodBasketModalHeaderItems} />}
            />
            <Text
              bold
              fontSize="16px"
              marginLeft="5px"
              marginRight="10px"
              marginTop="2px"
              value={itemsCount + customItemsCount}
            />
            {personalItemsCount > 0 && (
              <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
                <Icon icon={iconUser} fill={colors["support-03"].hex} />
                <Text defaultColor={colors["support-03"].hex} value={personalItemsCount} />
              </RowStyled>
            )}
            {customItemsCount > 0 && (
              <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
                <Icon
                  icon={iconWfpHumFoodSecurityClusterPos}
                  height="20"
                  width="20"
                  fill={colors["categorical-corporate-08"].hex}
                />
                <Text defaultColor={colors["categorical-corporate-08"].hex} value={customItemsCount} />
              </RowStyled>
            )}
            {customSourcesNumber > 0 && (
              <RowStyled middle="xs" center="xs" margin="0 0 0 5px">
                <Icon
                  icon={iconWfpHumFoodSecurityClusterPos} // TODO: check if we need to change this icon since we are using it for custom menu items
                  height="20"
                  width="20"
                  fill={colors["categorical-corporate-06"].hex}
                />
                <Text defaultColor={colors["categorical-corporate-06"].hex} value={customSourcesNumber} />
              </RowStyled>
            )}
          </RowStyled>
        </ColStyled>
        <ColStyled xs={6}>
          <RowStyled end="xs">
            <Button
              widthAuto
              children={<FormattedMessage {...buttonMessages.addNewFoodItem} />}
              onClick={openAddNewFoodItemSection}
            />
          </RowStyled>
        </ColStyled>
      </RowStyled>

      <Filters />

      <ItemsContainer padding="8px 8px 40px">{renderList}</ItemsContainer>
    </>
  );
};

export default PageOne;
