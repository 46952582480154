import styled, { css } from "styled-components";
import { Checkbox } from "@wfp/ui";

const CheckboxProps = ({ noMarginBottom, fontBold, fontSize, ...props }) => <Checkbox {...props} />;

export const CheckboxStyled = styled(CheckboxProps)`
  white-space: nowrap;
  ${({ noMarginBottom }) =>
    noMarginBottom &&
    css`
      margin-bottom: -15px;
    `};
  ${({ fontBold }) =>
    fontBold &&
    css`
      font-weight: 600;
    `};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;
