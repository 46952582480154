import React from "react";
import { Text } from "components";
import { colors } from "@wfp/ui";
import { RowStyled, ColStyled } from "components/utils";
import { UISecondaryNavigation } from "./styles";

const SecondHeader = ({ title, info, afterText, button, children, xsButton, padding }) => {
  return (
    <UISecondaryNavigation pageWidth="lg" padding={padding}>
      {children ? (
        children
      ) : (
        <RowStyled middle="xs">
          <ColStyled xs>
            <h2>{title}</h2>
            {info && (
              <Text
                marginTop="10px"
                marginBottom="10px"
                fontSize="16px"
                value={info}
                defaultColor={colors["text-02"].hex}
                wrap
              />
            )}
            {afterText}
          </ColStyled>
          {button && (
            <ColStyled xs={xsButton || 2} className="end-xs">
              {button}
            </ColStyled>
          )}
        </RowStyled>
      )}
    </UISecondaryNavigation>
  );
};

export default SecondHeader;
