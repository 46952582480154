import { useCallback, useEffect, useState, useMemo } from "react";
import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { colors, Loading } from "@wfp/ui";
import { FormProvider } from "react-hook-form";

import { Layout, NewModule, Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled } from "components/utils";
import routesPath from "containers/App/Routes/paths";
import { MANUAL } from "containers/MyMenus/labels";
import { resetMenu, createOrUpdateMenuRequest } from "containers/Menu/actions";
import { selectMenuLoading, selectMenuId } from "containers/Menu/selectors";
import { withAuth, withCountryKey } from "hocs";
import { FIELDS } from "./constants";
import Header from "./Header";
import GeneralForm from "./GeneralForm";
import { useUserProvidedNumbers } from "./GeneralForm/hooks";
import { selectLoading, selectLoadingBasket } from "./GeneralForm/selectors";
import { useAsyncForm } from "./useInitialValues";
import WarningLowNumber, { showLowNumberModal } from "./WarningLowNumber";

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [showModal, setModal] = useState();

  const loading = useSelector(selectLoading);
  const loadingMenu = useSelector(selectMenuLoading);
  const loadingFoodBasket = useSelector(selectLoadingBasket);
  const menuId = useSelector(selectMenuId);

  const currentMenuId = useMemo(() => id ?? menuId, [id, menuId]);

  const { useFormApi } = useAsyncForm(currentMenuId);

  const { groupsCount, itemsCount, customItemsCount } = useUserProvidedNumbers(useFormApi.control);

  useEffect(() => {
    dispatch(resetMenu());
  }, [dispatch]);

  const closeWarningModal = useCallback(() => setModal(false), []);

  const callbackOnSubmit = useCallback(
    (id) => {
      history.push(routesPath.results.replace(":id", id));
    },
    [history],
  );

  const onSubmit = useCallback(() => {
    const values = useFormApi.getValues();
    const body = R.pick(
      [FIELDS.PRELIMINARY_INFORMATION, FIELDS.MENU_PRICING_LIST, FIELDS.MENU_NAME, FIELDS.BASKET],
      values,
    );
    dispatch(createOrUpdateMenuRequest({ menuId: currentMenuId, menuType: MANUAL, body, callback: callbackOnSubmit }));
  }, [callbackOnSubmit, currentMenuId, dispatch, useFormApi]);

  const openModalOrCall = useCallback(() => {
    if (showLowNumberModal(groupsCount, itemsCount + customItemsCount)) return setModal(true);
    return onSubmit();
  }, [customItemsCount, groupsCount, itemsCount, onSubmit]);

  const content = useMemo(() => <GeneralForm menuId={currentMenuId} />, [currentMenuId]);

  return (
    <FormProvider {...useFormApi}>
      <Layout backgroundColor={colors["ui-02"].hex}>
        <Loading active={loading} />
        <Header />
        <NewModule body={content} innerPadding="2.2rem" />
        <RowStyled end="xs" margin="0">
          <Button type="submit" onClick={openModalOrCall} disabled={loadingMenu} className="col-xs-2">
            {loadingMenu || loadingFoodBasket ? (
              <FormattedMessage {...buttonMessages.loading} />
            ) : (
              <FormattedMessage {...buttonMessages.submit} />
            )}
          </Button>
        </RowStyled>
        <WarningLowNumber
          visible={showModal}
          groupsCount={groupsCount}
          itemsCount={itemsCount + customItemsCount}
          clickOnContinue={onSubmit}
          closeModal={closeWarningModal}
        />
      </Layout>
    </FormProvider>
  );
};

export default compose(withAuth, withCountryKey)(GeneralInfo);
