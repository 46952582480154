import * as R from "ramda";
import { FormattedMessage } from "react-intl";

import { validateMinAndMax, minGreaterMax } from "utils/errors";
import { FIELDS } from "../constants";
import messages from "../../messages";

const {
  INCLUDE,
  INCLUDED_DAYS,
  EXCLUDED_DAYS,
  REPETITION,
  MAX_PER_WEEK,
  MAJOR_LABEL,
  EXCLUDE_FROM_GROUP,
  AMOUNT_RANGE,
  DEFAULT_AMOUNT_RANGE,
  MATCH_WITH,
  UNMATCH_WITH,
  EXCLUDED_GROUP,
} = FIELDS;

// Food item key
const TYPE = "food_item_restriction";

export const validation = (values, daysInWeek, weekCount) => {
  if (!values[TYPE]) return "";
  const itemInclusion = values[TYPE][INCLUDE];
  const isIncluded = itemInclusion === true || itemInclusion === "include";
  const includedDays = values[TYPE][INCLUDED_DAYS];
  const excludedDays = values[TYPE][EXCLUDED_DAYS];
  const repetition = values[TYPE][REPETITION];
  const maxPerWeek = values[TYPE][MAX_PER_WEEK];
  const majorGroup = values[TYPE][MAJOR_LABEL];
  const excludeFromGroup = values[TYPE][EXCLUDE_FROM_GROUP];
  const amountRange = values[TYPE][AMOUNT_RANGE];
  const defaultAmountRange = values[TYPE][DEFAULT_AMOUNT_RANGE];
  const restrictionId = values[TYPE]["id"];

  if (!restrictionId && (itemInclusion === "no-rule" || itemInclusion === null)) {
    return [true];
  }

  if (restrictionId && (itemInclusion === "no-rule" || itemInclusion === null)) {
    return "";
  }

  if (
    repetition &&
    !excludeFromGroup &&
    (includedDays.length > maxPerWeek ||
      repetition[1] >
        (maxPerWeek > daysInWeek * weekCount - excludedDays.length
          ? daysInWeek * weekCount - excludedDays.length
          : maxPerWeek))
  ) {
    return {
      title: (
        <FormattedMessage {...messages.maxRepetitionValidation} values={{ group: majorGroup, value: maxPerWeek }} />
      ),
      info: <FormattedMessage {...messages.checkTheBoxRepetition} />,
    };
  }

  if (repetition && (repetition[0] < 1 || repetition[1] < 1)) {
    return {
      title: <FormattedMessage {...messages.itemRepetitionNegativeValues} />,
    };
  }

  if (
    repetition &&
    excludeFromGroup &&
    (repetition[0] < includedDays.length || repetition[1] > daysInWeek * weekCount - excludedDays.length)
  ) {
    return {
      title: <FormattedMessage {...messages.invalidItemRepetition} />,
    };
  }

  if (validateMinAndMax(repetition) || validateMinAndMax(amountRange)) {
    return minGreaterMax;
  }

  if (
    (isIncluded ||
      !R.isEmpty(values[UNMATCH_WITH]) ||
      !R.isEmpty(values[MATCH_WITH]) ||
      !R.equals(amountRange, defaultAmountRange)) &&
    values[EXCLUDED_GROUP]
  ) {
    return {
      info: <FormattedMessage {...messages.itemBelongsToGroupExcluded} />,
      kind: "warning",
      backgroundColor: "#f7b8253d",
    };
  }

  return "";
};
