import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectGeneralForm = () => (state) => R.pathOr(initialState, ["generalForm"], state);

export default () => createSelector(selectGeneralForm(), (generalFormState) => generalFormState);

export const selectLoadingBasket = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr(false, ["loadingBasket"], generalFormState),
);

export const selectLoading = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr(false, ["loading"], generalFormState),
);

export const selectTargetBeneficiaries = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr([], ["targetBeneficiaries"], generalFormState),
);

export const selectFoodBasket = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr({}, ["foodBasket"], generalFormState),
);

export const selectFoodBasketItems = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr({}, ["foodBasket", "food_items"], generalFormState),
);

export const selectGroupCount = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr(0, ["foodBasket", "group_count"], generalFormState),
);

export const selectItemsCount = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr(0, ["foodBasket", "items_count"], generalFormState),
);

export const selectNutritionalReq = createSelector(selectGeneralForm(), (generalFormState) =>
  R.pathOr([], ["nutritionalRequirements"], generalFormState),
);
