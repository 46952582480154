import styled, { css } from "styled-components";
import { colors } from "@wfp/ui";

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  overflow: hidden;
  text-align: center;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${colors["ui-04"].hex};
      border-left: 1px solid ${colors["ui-04"].hex};
    `};
`;

export const Nutrient = styled.div`
  overflow: hidden;
  padding: 10px 0;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid ${colors["ui-04"].hex};
      border-right: 1px solid ${colors["ui-04"].hex};
    `};
`;
