import { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { selectVamMappings, selectFoodItems } from "containers/Admin/selectors";

export const useInitialValues = () => {
  const vamMappings = useSelector(selectVamMappings);
  const foodItems = useSelector(selectFoodItems);
  const isMissingData = useMemo(() => {
    return R.isEmpty(vamMappings) || R.isEmpty(foodItems);
  }, [foodItems, vamMappings]);

  return useMemo(() => {
    if (isMissingData) return {};

    const reworkedVamMappings = vamMappings.map((el) => ({
      value: el.vam_commodity.id,
      label: el.vam_commodity.description,
      item: el.item ? { value: el.item.id, label: el.item.display_name } : null,
    }));

    return { vam_mappings: reworkedVamMappings };
  }, [isMissingData, vamMappings]);
};
