import * as R from "ramda";
import { TUTORIAL_IDS } from "containers/Tutorials/utils";

export const FORM_NAME = "GeneralInfo";

export const FIELDS = {
  PRELIMINARY_INFO: "preliminary_information",
  PRICING_LIST: "menu_pricing_list",
  NUTRITIONAL_REQUIREMENTS: "nutritional_requirements",
  INCLUDE_WHOLE_COUNTRY: "include_whole_country",
  TARGET: "target_group",
  DAYS_IN_WEEK: "days_in_week",
  WEEK_COUNT: "week_count",
  MONTH_FROM: "start_month",
  MONTH_TO: "end_month",
  REGIONS: "regions",
  PRICE_VALIDITY: "price_source_validity",
  CREATED_AT: "created_timestamp",
  SEASONALITY: "seasonality",
  FOOD_SOURCES: "food_sources",
};

export const changedSpecificFields = (nextValues, prevValues) => {
  if (R.isEmpty(nextValues) || R.isEmpty(prevValues)) return false;

  const nextPreliminaryInformation = R.pathOr({}, [FIELDS.PRELIMINARY_INFO], nextValues);
  const prevPreliminaryInformation = R.pathOr({}, [FIELDS.PRELIMINARY_INFO], prevValues);

  // When switching from "m" to "y" we do a batch change since the form could change up to three values, leading to multiple call to food-basket endpoint.
  // If we had "January" and "December" before the change, do not fetch since it's unnecessary. Otherwise, fetch new food items ffrom food-basket.
  if (
    nextPreliminaryInformation[FIELDS.SEASONALITY] === "y" &&
    prevPreliminaryInformation[FIELDS.SEASONALITY] === "m"
  ) {
    if (prevPreliminaryInformation[FIELDS.MONTH_FROM] === 1 && prevPreliminaryInformation[FIELDS.MONTH_TO] === 12) {
      return false;
    }

    return true;
  }

  return (
    !R.eqProps(`${FIELDS.INCLUDE_WHOLE_COUNTRY}`, nextPreliminaryInformation, prevPreliminaryInformation) ||
    !R.equals(nextPreliminaryInformation[FIELDS.REGIONS], prevPreliminaryInformation[FIELDS.REGIONS]) ||
    !R.eqProps(`${FIELDS.MONTH_FROM}`, nextPreliminaryInformation, prevPreliminaryInformation) ||
    !R.eqProps(`${FIELDS.MONTH_TO}`, nextPreliminaryInformation, prevPreliminaryInformation) ||
    !R.eqProps(`${FIELDS.PRICE_VALIDITY}`, nextPreliminaryInformation, prevPreliminaryInformation) ||
    !R.equals(nextValues[FIELDS.PRICING_LIST], prevValues[FIELDS.PRICING_LIST])
  );
};

export const hasTrainingMaterials = (tutorials) => {
  return Object.values(tutorials).find((tutorial) => tutorial.page === TUTORIAL_IDS.COMMUNITY_TRAINING);
};

// When getting data from BE, we get foodSources as ["source_name"].
// If all are set true, we get [].
// We need to rework data in order to have { "source1": true, "source2": false, ... }
// The same goes for property "regions": we need to have it correctly shaped for our "Location" select component
export const reworkInitialMenu = (foodSources, data) => {
  let initialFoodSources, initialRegions;
  const beFoodSources = data.preliminary_information.food_sources;
  const beRegions = data.preliminary_information.regions;

  // If [], return object with all sources set to true
  if (R.isEmpty(beFoodSources)) {
    initialFoodSources = foodSources.reduce(
      (acc, item) => ({
        ...acc,
        [item.value]: true,
      }),
      {},
    );
  } else {
    initialFoodSources = foodSources.reduce(
      (acc, item) => ({
        ...acc,
        [item.value]: beFoodSources.includes(item.value),
      }),
      {},
    );
  }

  // If empty, we'll get []. If not, handle regions structure and get first and only item
  if (R.isEmpty(beRegions)) {
    initialRegions = beRegions;
  } else {
    initialRegions = beRegions.map(({ id, region, ...rest }) => ({ value: id, label: region, ...rest }))[0];
  }

  return {
    ...data,
    preliminary_information: {
      ...data.preliminary_information,
      food_sources: initialFoodSources,
      regions: initialRegions,
    },
  };
};
