import { useIntl } from "react-intl";
import * as R from "ramda";
import { Text, View } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

import generalStyles from "containers/PDF/styles";
import messages from "containers/Results/messages";
import Day from "./Day";

const viewStyle = {
  marginTop: 20,
  flexWrap: "wrap",
  border: 1,
  borderBottom: 0,
  borderTopLeftRadius: 2,
  borderTopRightRadius: 2,
  borderColor: colors["ui-04"].hex,
};

function PageTwo({ pdf }) {
  const intl = useIntl();
  const { fontSize12, row } = generalStyles;
  const { values, recipes, daysInWeek, days } = R.pathOr({}, ["results"], pdf);
  const weeks = R.splitEvery(daysInWeek, days);

  return (
    <View style={[row, viewStyle]}>
      <Text style={[fontSize12, { marginTop: 3, padding: "5 0 10 15" }]}>
        {intl.formatMessage(messages.menuComposition)}
      </Text>
      {weeks.map((week, weekIndex) => (
        <View
          key={`week-${week}`}
          style={[
            row,
            {
              borderBottom: weeks.length === weekIndex + 1 ? 1 : 0,
              borderColor: colors["ui-04"].hex,
            },
          ]}
        >
          {week.map((day, index) => (
            <Day
              key={`day-${day}`}
              index={index}
              day={day}
              week={weekIndex}
              foodItemsList={values[`day_${day}`]}
              recipesList={recipes[`day_${day}`]}
              daysInWeek={daysInWeek}
            />
          ))}
        </View>
      ))}
    </View>
  );
}

export default PageTwo;
