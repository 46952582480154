import React from "react";
import { useField, Field } from "react-final-form";
import { Checkbox } from "components";
import { FIELDS } from "../constants";

const Source = ({ name }) => {
  const {
    input: { value: labelValue },
  } = useField(`${name}.${FIELDS.LABEL}`);

  return (
    <Field name={`${name}.${FIELDS.CHECKED}`} type="checkbox">
      {({ input }) => (
        <Checkbox
          id={`${name}.${FIELDS.CHECKED}`}
          labelText={labelValue}
          checked={input.checked}
          onChange={input.onChange}
        />
      )}
    </Field>
  );
};

export default Source;
