import { defineMessage } from "react-intl";

export default {
  shareTitle: defineMessage({
    id: "modal.shareTitle",
    defaultMessage: "Share menu",
  }),
  shareInfo: defineMessage({
    id: "modal.shareInfo",
    defaultMessage: "Type in and validate emails of users you want to share the menu with",
  }),
  shareEmail: defineMessage({
    id: "modal.shareEmail",
    defaultMessage: "Email",
  }),
  shareAddEmail: defineMessage({
    id: "modal.shareAddEmail",
    defaultMessage: "Add new email",
  }),
  sharePlaceholderEmail: defineMessage({
    id: "modal.sharePlaceholderEmail",
    defaultMessage: "Type a valid email",
  }),
  shareEmailDoesNotExist: defineMessage({
    id: "modal.shareEmailDoesNotExist",
    defaultMessage: "This email does not correspond to an active PLUS user",
  }),
  shareMenuWithYourself: defineMessage({
    id: "modal.shareMenuWithYourself",
    defaultMessage: "You cannot share a menu with yourself",
  }),
  emailAlreadyEntered: defineMessage({
    id: "modal.emailAlreadyEntered",
    defaultMessage: "This email is already entered",
  }),
};
