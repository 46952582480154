export const WIZARD_LABELS = [
  {
    label: "Setup menu & food basket",
    localeKey: "setupMenuAndFoodBasket",
  },
  {
    label: "Set rules & generate menu",
    localeKey: "setupRulesAndGenerateMenu",
  },
];

export const defaultGeneralParameter = {
  nutrition_level: "basic",
  all_macronutrient_restricted: false,
  all_micronutrient_restricted: false,
  macronutrient_restriction: 30,
  micronutrient_restriction: 30,
  all_sources_restricted: false,
  sourcing_range: [0, 100],
  min_number_items_in_meal: 0,
  max_number_items_in_meal: 0,
  min_number_items_in_menu: 0,
  min_number_groups_in_meal: 0,
  min_number_groups_in_menu: 0,
  max_weight: "",
};

export const MIN = "min";
export const MAX = "max";
