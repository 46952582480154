import { useIntl } from "react-intl";
import { ColStyled, RowStyled } from "components/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { colors, Loading, TextInput } from "@wfp/ui";
import { Button, Table } from "components";
import { getColumns } from "./utils";
import { useResponsiveHook } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAdminFoodItems,
  selectAdminFoodItemsCount,
  selectLoadingAdminFoodItems,
  selectLoadingCountriesSlice,
  selectTableParams,
} from "../../selectors";
import * as R from "ramda";
import { reworkOrdering } from "utils/utils";
import { loadAdminFoodItems, resetFoodItems, resetTableFilters, resetTableParams } from "../../actions";
import { selectSelectedCountry } from "../../../App/selectors";
import { iconFilter, iconRestart } from "@wfp/icons";
import messages from "components/Button/messages";
import { useForm } from "react-hook-form";

const FoodTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();
  const selectedCountry = useSelector(selectSelectedCountry);
  const columns = useMemo(
    () => getColumns(intl, isTabletOrMobile, selectedCountry),
    [intl, isTabletOrMobile, selectedCountry]
  );
  const foodItems = useSelector(selectAdminFoodItems);
  const foodItemsCount = useSelector(selectAdminFoodItemsCount);
  const { page: pageNum, page_size: pageSize } = useSelector(selectTableParams);
  const loading = useSelector(selectLoadingCountriesSlice);
  const loadingAdminFoodItems = useSelector(selectLoadingAdminFoodItems);
  const { register, getValues, handleSubmit, reset } = useForm();
  const [search, setSearch] = useState();

  const onSubmitFilter = () => {
    setSearch(getValues("search"));
  };

  const onClearFilter = () => {
    reset({ search: null });
    setSearch(getValues("search"));
  };

  const loadData = useCallback(
    (page, pageSize, ordering) => {
      let params = { page, page_size: pageSize };
      if (!R.isEmpty(search)) {
        params = { ...params, search };
      }
      if (ordering && !R.isEmpty(ordering)) {
        params = { ...params, ordering: reworkOrdering(ordering) };
      }
      dispatch(loadAdminFoodItems(params));
    },
    [dispatch, search]
  );

  const loadingData = useMemo(() => loading || loadingAdminFoodItems, [loading, loadingAdminFoodItems]);

  useEffect(() => {
    return () => {
      dispatch(resetTableParams());
      dispatch(resetTableFilters());
      dispatch(resetFoodItems());
    };
  }, [dispatch]);

  const tableProps = {
    manual: true,
    pointer: false,
    overflowId: "item",
    columns,
    data: foodItems,
    pageNum,
    pageSize,
    totalItems: foodItemsCount,
    loadData,
    loading: loadingData,
    pageSizeOptions: [10, 30, 40],
    initialState: { pageSize: 10 },
    border: `1px solid ${colors["ui-04"].hex}`,
    margin: isTabletOrMobile ? 0 : "30px 0 0",
  };

  return (
    <ColStyled xs padding="20px 8px">
      <RowStyled>
        <ColStyled xs={3}>
          <TextInput labelText={intl.formatMessage(messages.search)} {...register("search")}></TextInput>
        </ColStyled>
      </RowStyled>
      <RowStyled end="xs" margin="10px 0 0 0 !important">
        <ColStyled>
          <Button
            widthAuto
            icon={iconFilter}
            marginRight="10px"
            kind="primary"
            onClick={handleSubmit(onSubmitFilter)}
            children={intl.formatMessage(messages.filter)}
          />
          <Button
            widthAuto
            icon={iconRestart}
            kind="tertiary"
            onClick={onClearFilter}
            children={intl.formatMessage(messages.clear)}
          />
        </ColStyled>
      </RowStyled>
      <Loading active={loadingData} />
      <Table {...tableProps}></Table>
    </ColStyled>
  );
};

export default FoodTable;
