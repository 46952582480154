import { useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";

import { FIELDS } from "containers/GeneralInfo/constants";
import { loadFoodBasket } from "../actions";
import validationNumbers from "containers/OptimizedMenu/Rules/FoodRules/AdvancedSettings/NumberOfGroupsAndItems/validation";
import { FIELDS as RULES_FIELDS } from "containers/OptimizedMenu/Rules/constants";
import { selectGroupsAndItems } from "containers/OptimizedMenu/Rules/FoodRules/selectors";

// This util will listen for changes happening in step one of menu creation flow. If firstMount is false, it means this won't run. It was added so we don't run this before the other hook responsible for the first data fetch.
export const useListenForChanges = (menuType, firstMount) => {
  const dispatch = useDispatch();
  const { control, getValues } = useFormContext();

  const formPricingList = useWatch({
    control,
    name: FIELDS.MENU_PRICING_LIST,
  });

  const formRegions = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.REGIONS}`,
  });

  const formIncludeWholeCountry = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.INCLUDE_WHOLE_COUNTRY}`,
  });

  const formSourcingChannels = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.SOURCING_CHANNELS}`,
  });

  const formStartMonth = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.START_MONTH}`,
  });

  const formEndMonth = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.END_MONTH}`,
  });

  useDeepCompareEffect(() => {
    if (firstMount.current) return;

    dispatch(
      loadFoodBasket({
        body: {
          ...R.pick([FIELDS.PRELIMINARY_INFORMATION, FIELDS.MENU_PRICING_LIST, FIELDS.MENU_NAME], getValues()),
          type: menuType,
        },
      })
    );
  }, [
    dispatch,
    firstMount,
    formEndMonth,
    formPricingList,
    formRegions,
    formSourcingChannels,
    formStartMonth,
    formIncludeWholeCountry,
    getValues,
    menuType,
  ]);
};

// When adding a custom food item or a custom price source we have to show the amount of these user provided data.
export const useUserProvidedNumbers = (controlProp) => {
  const { control } = useFormContext?.() ?? { control: controlProp };
  const basket = useWatch({ control, name: FIELDS.BASKET });

  const groupsCount = useMemo(() => {
    const groups = R.groupBy((item) => item.major_group, Object.values(basket));
    return Object.keys(groups).length;
  }, [basket]);

  const itemsCount = useMemo(() => {
    // Exclude user provided items from the count before returning it.
    return Object.values(basket).filter((item) => !item.user_provided_item).length;
  }, [basket]);

  const personalItemsCount = useMemo(() => {
    return Object.values(basket).filter((item) => item.has_personal_prices).length;
  }, [basket]);

  const customItemsCount = useMemo(() => {
    return Object.values(basket).filter((item) => item.user_provided_item).length;
  }, [basket]);

  const customSourcesNumber = useMemo(() => {
    const customSources = Object.values(basket).filter(({ prices }) => {
      const pricesArray = Object.values(prices);
      const hasCustomSources = pricesArray.some((price) => price.added_by_user);
      return hasCustomSources;
    });

    return customSources.length;
  }, [basket]);

  return useMemo(
    () => ({
      groupsCount,
      itemsCount,
      personalItemsCount,
      customItemsCount,
      customSourcesNumber,
    }),
    [personalItemsCount, customItemsCount, customSourcesNumber, groupsCount, itemsCount]
  );
};

// When adding invalid data in AdvancedSettings we need to show errors to the user + do not let them submit the form
export const useMenuGeneralParameterValidations = (controlProp) => {
  const { control } = useFormContext?.() ?? { control: controlProp };
  const menuGeneralParameter = useWatch({
    control,
    name: `${RULES_FIELDS.MENU_GENERAL_PARAMETER}`,
  });
  const groupsAndItems = useSelector(selectGroupsAndItems);
  const groupCount = R.pathOr(0, ["group_count"], groupsAndItems);
  const itemCount = R.pathOr(0, ["item_count"], groupsAndItems);
  const errors = useMemo(
    () => validationNumbers({ [RULES_FIELDS.MENU_GENERAL_PARAMETER]: menuGeneralParameter }, groupCount, itemCount),
    [menuGeneralParameter, groupCount, itemCount]
  );

  return useMemo(() => ({ errors }), [errors]);
};
