import { useMemo, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { colors, Icon, Tooltip } from "@wfp/ui";
import { iconInfoSolid } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { NumberInputHookForm, CheckboxHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import DailyHelper from "./DailyHelper";
import helperMessages from "./messages";
import PeriodHelper from "./PeriodHelper";
import { ContainerText } from "./styles";
import WeeklyHelper from "./WeeklyHelper";

const PortionNumber = ({ onlyOneField, title, name, checkboxName, min, max, helper }) => {
  const intl = useIntl();
  const { control, setValue, getValues } = useFormContext();
  const enableMax = useWatch({ control, name: checkboxName });
  const possibleMax = useWatch({ control, name: `${name}[1]` });

  const handleChange = useCallback(
    (_, field, value) => {
      field.onChange(value);

      if (onlyOneField) {
        return value ? setValue(name, max) : setValue(name, "");
      }

      const repetition = getValues(name);
      setValue(`${name}[0]`, repetition[0]);
      setValue(`${name}[1]`, max);
    },
    [getValues, max, name, onlyOneField, setValue]
  );

  const HelperComponent = useMemo(() => {
    if (helper === "DailyHelper") return <DailyHelper />;
    if (helper === "WeeklyHelper") return <WeeklyHelper />;
    return <PeriodHelper />;
  }, [helper]);

  const Description = useMemo(() => {
    if (helper === "DailyHelper") return intl.formatMessage(helperMessages.infoDaily);
    if (helper === "WeeklyHelper") return intl.formatMessage(helperMessages.infoWeekly);
    return intl.formatMessage(helperMessages.infoMenu);
  }, [helper, intl]);

  if (onlyOneField) {
    return (
      <>
        <Text bold wrap marginBottom="10px" value={title} />
        <RowStyled margin="0 0 10px 0">
          <ColStyled className={enableMax ? "" : "disable"}>
            <NumberInputHookForm
              control={control}
              name={name}
              helperText={<FormattedMessage {...messages.max} />}
              step={1}
              min={min}
              max={max}
              allowZero
              allowEmpty
            />
          </ColStyled>
        </RowStyled>
        <RowStyled>
          <CheckboxHookForm
            control={control}
            name={checkboxName}
            id={checkboxName}
            labelText={<FormattedMessage {...messages.groupEnableMax} />}
            customOnChange={handleChange}
          />
        </RowStyled>
      </>
    );
  }

  return (
    <>
      <ContainerText>
        <Text bold wrap value={title} />
        {helper && (
          <Tooltip content={HelperComponent}>
            <div>
              <Icon icon={iconInfoSolid} description={Description} fill={colors["brand-01"].hex} />
            </div>
          </Tooltip>
        )}
      </ContainerText>
      <RowStyled margin="0 0 10px 0">
        <ColStyled>
          <NumberInputHookForm
            control={control}
            name={`${name}[0]`}
            helperText={<FormattedMessage {...messages.min} />}
            step={1}
            max={possibleMax}
            allowZero
          />
        </ColStyled>

        <ColStyled className={enableMax ? "" : "disable"}>
          <NumberInputHookForm
            control={control}
            name={`${name}[1]`}
            helperText={<FormattedMessage {...messages.max} />}
            step={1}
            max={max}
            allowZero
          />
        </ColStyled>
      </RowStyled>
      <RowStyled>
        <CheckboxHookForm
          control={control}
          name={checkboxName}
          id={checkboxName}
          labelText={<FormattedMessage {...messages.groupEnableMax} />}
          customOnChange={handleChange}
        />
      </RowStyled>
    </>
  );
};

export default PortionNumber;
