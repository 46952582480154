import React, { useState, useCallback, useEffect } from "react";
import { compose } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { withAuth } from "hocs";
import { Loading } from "@wfp/ui";
import { saveAccount } from "containers/App/actions";
import { Grid } from "react-flexbox-grid";
import Choice from "./Choice";
import Confirmation from "./Confirmation";
import { loadCountries } from "./actions";
import { selectProfile, selectCountryCode, selectSelectedCountry } from "containers/App/selectors";
import routesPath from "containers/App/Routes/paths";
import { selectCountries, selectLoading } from "./selectors";

function SelectCountry() {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(selectSelectedCountry);
  const loading = useSelector(selectLoading);
  const countries = useSelector(selectCountries);
  const profile = useSelector(selectProfile);
  const countryCode = useSelector(selectCountryCode);
  const [country, setCountry] = useState("");

  const onClickContinue = useCallback(() => {
    const body = {
      profile: { country: country ? country.value : profile.country },
    };
    dispatch(saveAccount({ body, path: routesPath.menus, fetchNutritionalValues: true }));
    localStorage.removeItem("mustSelectCountry");
  }, [country, dispatch, profile]);

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  useEffect(() => {
    if (countryCode) {
      setCountry((countries || []).find((el) => el.value === countryCode));
    }
  }, [countries, countryCode, dispatch]);

  return (
    <Grid
      style={{
        flexGrow: 1,
        padding: "10vh 0 0",
      }}
    >
      {loading ? (
        <Loading active={loading} />
      ) : selectedCountry && countryCode ? (
        <Confirmation onClickContinue={onClickContinue} setCountry={setCountry} profile={profile} />
      ) : (
        <Choice onClickContinue={onClickContinue} country={country} profile={profile} setCountry={setCountry} />
      )}
    </Grid>
  );
}

export default compose(withAuth)(SelectCountry);
