import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectCommunityForm = () => (state) => R.pathOr(initialState, ["communityMenu"], state);

export default () => createSelector(selectCommunityForm(), (communityState) => communityState);

export const selectLoadingBasket = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr(false, ["loadingBasket"], communityState),
);

export const selectLoading = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr(false, ["loading"], communityState),
);

export const selectTargetBeneficiaries = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr([], ["targetBeneficiaries"], communityState),
);

export const selectFoodBasket = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr({}, ["foodBasket"], communityState),
);

export const selectFormValues = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr({}, ["form", "values"], communityState),
);

export const selectNutritionalReq = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr([], ["nutritionalRequirements"], communityState),
);

export const selectAvailableNutrients = createSelector(selectCommunityForm(), (communityState) =>
  R.pathOr([], ["availableNutrients"], communityState),
);
