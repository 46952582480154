import React from "react";
import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { Row, Col } from "react-flexbox-grid";

export const ContainerText = styled.div`
  display: inline-flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const Title = styled(TextareaAutosize)`
  display: block;
  font-size: ${({ fontSize }) => fontSize || "1.75rem"};
  outline: none;
  padding: 5px;
  margin-right: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid #8ca4b5;
  pointer-events: "auto";
  resize: none;
  font-family: "Open Sans", sans-serif;
  :focus {
    outline: none;
    box-shadow: 0px 2px 8px 0 #c2dbec;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RowWithMarginTop = ({ withMarginTop, ...props }) => <Row {...props} />;
const ColWithFlexAndMargin = ({ noMarginRight, flex, isRTL, ...props }) => <Col {...props} />;

export const RowStyled = styled(RowWithMarginTop)`
  margin-top: ${({ withMarginTop }) => (withMarginTop ? "25px" : 0)};
  margin-left: 0;
  margin-right: 0;
`;

export const ColStyled = styled(ColWithFlexAndMargin)`
  flex: ${({ flex }) => flex || 1};

  ${({ noMarginRight, isRTL }) =>
    isRTL
      ? css`
          margin-left: ${noMarginRight ? 0 : "30px"};
        `
      : css`
          margin-right: ${noMarginRight ? 0 : "30px"};
        `}

  ${({ flex, isRTL }) =>
    flex === 0.5 &&
    css`
      &:nth-child(2) {
        ${isRTL
          ? css`
              margin-left: 10px;
            `
          : css`
              margin-right: 10px;
            `}
      }
    `};
`;
