import { useCallback, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { NewModule } from "components";
import Header from "./Header";
import Filters from "./Filters";
import Footer from "./Footer";
import { searchFoodItemsRequest } from "../actions";
import { FIELDS } from "../constants";

const SearchAndFilter = () => {
  const dispatch = useDispatch();
  const { control, reset } = useFormContext();
  const formFilters = useWatch({ control, name: FIELDS.FILTERS });
  const [visibleFilters, setVisibleFilters] = useState(false);

  const closeFilters = useCallback(() => {
    reset();
    setVisibleFilters(false);
  }, [reset]);

  // Click on "Apply": close filters section, take form values and fetch food items
  const closeFiltersAndFetchFoodItems = useCallback(() => {
    setVisibleFilters(false);
    dispatch(searchFoodItemsRequest({ body: formFilters }));
  }, [dispatch, formFilters]);

  const header = useMemo(
    () => (
      <Header
        visibleFilters={visibleFilters}
        setVisibleFilters={setVisibleFilters}
        closeFiltersAndFetchFoodItems={closeFiltersAndFetchFoodItems}
        closeFilters={closeFilters}
      />
    ),
    [visibleFilters, closeFiltersAndFetchFoodItems, closeFilters],
  );

  const body = useMemo(() => (visibleFilters ? <Filters /> : null), [visibleFilters]);

  const footer = useMemo(
    () =>
      visibleFilters ? (
        <Footer closeFiltersAndFetchFoodItems={closeFiltersAndFetchFoodItems} closeFilters={closeFilters} />
      ) : null,
    [visibleFilters, closeFiltersAndFetchFoodItems, closeFilters],
  );

  return <NewModule innerPadding="10px 15px" hasFooterLine header={header} body={body} footer={footer} />;
};

export default SearchAndFilter;
