import React from "react";
import { colors } from "@wfp/ui";

export const Legumes = ({ size, disable }) => (
  <svg
    width={size || "17px"}
    height={size || "20px"}
    viewBox="0 0 17 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-222.000000, -621.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(222.000000, 621.000000)">
          <g>
            <path d="M15.6059261,6.84521951 C15.2597602,6.19014342 14.6586008,5.70323909 13.941429,5.49707317 C12.6575244,5.16628457 11.3432988,5.91769847 10.9911159,7.18392683 C10.8132951,7.9016656 10.9387467,8.66015086 11.3385072,9.28426829 C11.9756377,10.3769512 11.9766232,10.6100244 11.6508145,10.7958293 C10.9892386,11.1384586 10.4974263,11.7333661 10.2889913,12.443122 C9.95414672,13.7141856 10.7131309,15.0157048 11.9924652,15.3642683 C12.1985891,15.4185705 12.4110009,15.4459571 12.6242986,15.4457317 C13.1504506,15.4377157 13.6649138,15.2908685 14.1146072,15.0203415 C17.0539072,13.3401463 17.6111377,10.2841951 15.6059261,6.84521951 Z" />
            <path d="M4.26283623,2.75885366 C4.26283623,4.02029268 4.1459058,4.22226829 3.77008261,4.22226829 C3.02400463,4.19134136 2.29749419,4.46318951 1.75861304,4.97492683 C1.3075643,5.42212904 1.05597298,6.0293727 1.05991304,6.66131707 C1.05991304,7.87321951 1.99104493,9.10034146 3.77005797,9.10034146 C7.16404638,9.10034146 9.19034783,6.72987805 9.19034783,2.75887805 C9.19044638,-0.763414634 4.26283623,-0.763414634 4.26283623,2.75885366 Z" />
            <path d="M3.27732899,13.4906829 C3.27732899,14.752122 3.16039855,14.9540976 2.78457536,14.9540976 C2.03849009,14.9231543 1.3119704,15.1950147 0.773105797,15.7067805 C0.322047111,16.1539681 0.0704459649,16.7612053 0.0743811594,17.3931463 C0.0743811594,18.6050488 1.00553768,19.8321707 2.78452609,19.8321707 C6.17848986,19.8321707 8.20481594,17.4617317 8.20481594,13.4907073 C8.20491449,9.96829268 3.27732899,9.96829268 3.27732899,13.4906829 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
