import { Container, Label } from "./styles";

const CheckboxGroupContainer = ({ label, children }) => (
  <Container>
    {label && <Label bold value={label} />}
    {children}
  </Container>
);

export default CheckboxGroupContainer;
