import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Text, Button } from "components";
import buttonMessages from "components/Button/messages";
import { ColStyled, RowStyled } from "components/utils";
import { selectFoodSources } from "containers/App/selectors";
import Columns from "./Columns";
import CustomTable from "./CustomTable";

const TooltipContent = ({ item, openModal }) => {
  const intl = useIntl();
  const procurementSources = useSelector(selectFoodSources);

  const columns = useMemo(() => Columns(intl, procurementSources), [intl, procurementSources]);

  const tableProps = {
    data: item.sources_informations.slice(0, 3),
    columns,
    hasPagination: false,
    theme: "dark",
  };

  return (
    <>
      <ColStyled padding="10px">
        <Text bold fontSize="18px" defaultColor="white" value={item.label} />
        <CustomTable {...tableProps} />
        <RowStyled middle="xs" end="xs">
          <Button
            widthAuto
            kind="inverse--primary"
            children={intl.formatMessage(buttonMessages.viewMore)}
            onClick={() => openModal(item)}
          />
        </RowStyled>
      </ColStyled>
    </>
  );
};

export default TooltipContent;
