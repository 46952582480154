import styled from "styled-components";

import { colors } from "@wfp/ui";

import { StyledFooter } from "containers/App/Footer/styles";
import { TextStyled } from "components/Text/styles";

export const FirstSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
  height: 100vh;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  z-index: 4;
  width: 100%;
  border-bottom: 2px solid white;
  padding: 0 50px 0;

  > .title-label {
    font-weight: 600;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
  }
`;

export const CustomStyledFooter = styled(StyledFooter)`
  background-color: ${colors["categorical-corporate-05"].hex};
  border-top: 2px solid white;

  > .wfp--footer__meta {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  overflow: auto;
  background-color: ${colors["categorical-corporate-05"].hex};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  position: absolute;
  left: 10%;

  > button {
    max-width: 230px;
  }
`;

export const TextStyledWrapper = styled(TextStyled)`
  padding: ${({ padding }) => padding || 0};
  font-weight: ${({ fontWeight }) => fontWeight || null};
`
