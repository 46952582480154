import { useCallback, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { colors } from "@wfp/ui";

import { Text, Line } from "components";
import { loadItemRestrictionRequest, loadGroupRestrictionRequest } from "./actions";
import messages from "../messages";
import { SecondLevelContainerText, SecondLevelWrapper } from "./styles";

const SecondLevel = ({ menuId, majorGroup, list, firstLevelHeight }) => {
  const dispatch = useDispatch();

  // Load restriction of clicked food item/food group.
  const clickOnElement = useCallback(
    (params, name) => {
      if (Object.keys(params).includes("item_id")) {
        dispatch(loadItemRestrictionRequest({ id: menuId, params, name }));
      } else {
        dispatch(loadGroupRestrictionRequest({ id: menuId, params }));
      }
    },
    [dispatch, menuId],
  );

  return (
    <SecondLevelWrapper maxHeight={firstLevelHeight}>
      <SecondLevelContainerText onClick={() => clickOnElement({ major_group_id: majorGroup.major_id })}>
        <Text
          withPointer
          fontSize="16px"
          textAlign="left"
          value={
            <FormattedMessage {...messages.foodGroupAll} values={{ group: majorGroup.major_label.toLowerCase() }} />
          }
        />
      </SecondLevelContainerText>
      <Line />
      {list.map((minorGroup) => (
        <Fragment key={minorGroup.minor_label}>
          {list.length > 1 && (
            <SecondLevelContainerText onClick={() => clickOnElement({ minor_group_id: minorGroup.minor_id })}>
              <Text withPointer value={minorGroup.minor_label} fontSize="16px" textAlign="left" />
            </SecondLevelContainerText>
          )}
          {minorGroup.items.map((item) => (
            <SecondLevelContainerText key={item.id} onClick={() => clickOnElement({ item_id: item.id }, item.name)}>
              <Text
                withPointer
                width="70%"
                fontSize="16px"
                marginLeft="30px"
                textAlign="left"
                value={item.name}
                defaultColor={item.is_priority ? colors["support-02"].hex : colors["text-01"].hex}
              />
              <Text
                width="30%"
                fontSize="12px"
                textAlign="right"
                value={`${item.amount_range[0]}-${item.amount_range[1]}G`}
              />
            </SecondLevelContainerText>
          ))}
        </Fragment>
      ))}
    </SecondLevelWrapper>
  );
};

export default SecondLevel;
