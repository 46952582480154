import React from "react";
import { Wrapper, colors } from "@wfp/ui";
import { useIntl } from "react-intl";
import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";
import messages from "../messages";
import { RotateIcon } from "./styles";

function NotFound() {
  const intl = useIntl();

  return (
    <Wrapper pageWidth="lg" spacing="md">
      <RowStyled center="xs" margin="20px">
        <ColStyled xs={12}>
          <RotateIcon
            icon={iconWfpHumEmergencyResponsePos}
            description="not found"
            fill={colors["brand-01"].hex}
            width="200"
            height="200"
          />
          <Text noFontWeight fontSize="54px" value={intl.formatMessage(messages.notFound)} />
        </ColStyled>
      </RowStyled>
    </Wrapper>
  );
}

export default NotFound;
