import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { Button } from "components";
import messages from "components/Button/messages";
import { updateMenu } from "containers/Menu/actions";
import { RowStyled, ColStyled } from "components/utils";
import { Title } from "./styles";

const RenameMenu = ({ menuName, menuId, localMenuName, setLocalMenuName, setEditMode }) => {
  const dispatch = useDispatch();

  const rename = useCallback(() => {
    dispatch(updateMenu({ id: menuId, body: { name: localMenuName } }));
    setEditMode(false);
  }, [dispatch, localMenuName, menuId, setEditMode]);

  const handleChange = useCallback(
    (e) => {
      setLocalMenuName(e.currentTarget.value);
    },
    [setLocalMenuName],
  );

  const disableRenameButton = useMemo(() => !menuName || localMenuName === menuName, [localMenuName, menuName]);

  return (
    <RowStyled middle="xs" center="xs" margin="0 5px">
      <ColStyled xs={10}>
        <Title defaultValue={menuName} onChange={handleChange} />
      </ColStyled>
      <ColStyled xs={2} className="center-xs">
        <Button disabled={disableRenameButton} kind="secondary" onClick={rename}>
          <FormattedMessage {...messages.rename} />
        </Button>
      </ColStyled>
    </RowStyled>
  );
};

export default RenameMenu;
