import { useIntl } from "react-intl";
import { View, Text } from "@react-pdf/renderer";

import menuMessages from "containers/Menu/messages";
import generalStyles from "containers/PDF/styles";
import messages from "containers/Results/messages";
import styles from "./styles";

const AverageCost = ({ costs }) => {
  const intl = useIntl();
  const { fontSize12, row, fontSize10 } = generalStyles;
  const { title } = styles;

  return (
    <>
      <Text style={[fontSize12, { marginTop: 3 }]}>{intl.formatMessage(menuMessages.averageCostPerMeal)}</Text>
      {(costs || []).map(({ total, withoutAdditional, currency }, index) => (
        <View key={`${currency}-${index}`}>
          <View style={[row, { marginTop: 9 }]}>
            <Text style={title}>{total}</Text>
            <Text style={[fontSize10, { fontWeight: "semibold", padding: "5 0 0 3" }]}>{currency}</Text>
          </View>
          <Text style={fontSize10}>
            {intl.formatMessage(messages.excludingAdditional, { price: `${withoutAdditional} ${currency}` })}
          </Text>
        </View>
      ))}
    </>
  );
};

export default AverageCost;
