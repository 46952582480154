import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@mymenus/LOAD_STARTED_MY_MENUS");
export const loadEnded = createAction("@@mymenus/LOAD_ENDED_MY_MENUS");
export const loadSuccess = createAction("@@mymenus/LOAD_SUCCESS_MY_MENUS");

export const clearFilters = createAction("@@mymenus/CLEAR_FILTERS");
export const saveComparisonList = createAction("@@mymenus/SAVE_COMPARISON_LIST");
export const resetComparisonList = createAction("@@mymenus/RESET_COMPARISON_LIST");

export const changeFavourite = createAction("@@mymenus/CHANGE_FAVOURITE");
export const removeMenu = createAction("@@mymenus/REMOVE_MENU");

export const searchMyMenus = createAction("@@mymenus/SEARCH_MY_MENUS");

export const resetReducer = createAction("@@mymenus/RESET_REDUCER");

export const loadFiltersRequest = createAction("@@mymenus/LOAD_FILTERS_REQUEST");
export const loadFiltersStarted = createAction("@@mymenus/LOAD_FILTERS_STARTED");
export const loadFiltersEnded = createAction("@@mymenus/LOAD_FILTERS_ENDED");
