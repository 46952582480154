import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectPriceSources } from "containers/App/selectors";
import { Line, Accordion } from "components";
import Sources from "./Sources";
import Validity from "./Validity";
import messages from "./messages";

function PricesFields() {
  const intl = useIntl();
  const priceSources = useSelector(selectPriceSources);

  return (
    <>
      <Line margin="25px 0 10px" />
      <Accordion
        padding="10px 0 5px"
        fields={[
          {
            title: intl.formatMessage(messages.priceTitle),
            content: (
              <>
                <Sources priceSources={priceSources} />
                <Validity />
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default PricesFields;
