import { colors } from "@wfp/ui";

export const targetOptions = [
  { label: "⬇️ ⬇️", value: 0 },
  { label: "⬇️", value: 25 },
  { label: "↔️", value: 50 },
  { label: "⬆️", value: 75 },
  { label: "⬆️ ⬆️", value: 100 },
];

export const targetColor = colors["brand-01-80"].hex;

export const HEIGHT_CHART = 304;
export const TABLET_HEIGHT_CHART = 247;
export const MODAL_SIZE = 160;
export const H_PADDING = 4;
export const SELECTED_BAR_WIDTH = 20;
export const BAR_WIDTH = 100;
export const SPACE_GROUP = 60;
