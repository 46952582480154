import { useFormContext } from "react-hook-form";

import { NumberInputHookForm } from "components/Form";
import { FIELDS } from "containers/GeneralInfo/constants";

const CustomAveragePrice = ({ source }) => {
  const { control } = useFormContext();

  return (
    <NumberInputHookForm
      control={control}
      name={`${FIELDS.DRAFT}.${FIELDS.ITEM}.${FIELDS.PRICES}.${source}.${FIELDS.USER_PROVIDED_PRICE}`}
      step="0.001"
    />
  );
};

export default CustomAveragePrice;
