import React from "react";
import { useIntl } from "react-intl";
import { Field, useField } from "react-final-form";
import { InputGroup, RadioButton } from "@wfp/ui";
import { colors } from "@wfp/ui";
import { Text } from "components";
import { useResponsiveHook } from "hooks";
import { FieldNumber } from "components/InputFields";
import { ColStyled } from "components/utils";
import { weekOptions } from "./utils";
import { FIELDS } from "../utils";
import messages from "./messages";

function MenuDuration() {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();
  const {
    input: { value: daysInWeek },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.DAYS_IN_WEEK}`);
  const {
    input: { value: weekCount },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.WEEK_COUNT}`);

  return (
    <ColStyled xs={11} lg={12} margin="0 0 40px" padding="0">
      <Text bold fontSize="20px" value={intl.formatMessage(messages.duration)} />
      <Text marginBottom="5px" defaultColor={colors["text-02"].hex} value={intl.formatMessage(messages.durationInfo)} />
      <Field
        name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.DAYS_IN_WEEK}`}
        placeholder=""
        component={FieldNumber}
        maxValue={7}
        minValue={1}
      />
      <Text
        marginTop="10px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.durationInfoWeek)}
      />
      <InputGroup vertical={isTabletOrMobile}>
        {(weekOptions(daysInWeek, intl) || []).map(({ value, label }) => (
          <Field
            key={String(value)}
            name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.WEEK_COUNT}`}
            type="radio"
            value={String(value)}
          >
            {({ input }) => {
              return (
                <RadioButton
                  key={`key-${value}`}
                  value={String(value)}
                  name={input.name}
                  id={String(value)}
                  checked={String(weekCount) === input.value}
                  labelText={label}
                  onChange={input.onChange}
                  data-test-id={`school-children-option-${value}`}
                />
              );
            }}
          </Field>
        ))}
      </InputGroup>
    </ColStyled>
  );
}

export default MenuDuration;
