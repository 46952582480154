import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconEditGlyph } from "@wfp/icons";
import { ContainerIcon } from "./styles";
import { Text } from "components";
import { useHtmlHook } from "hooks";
import { MANUAL, COMMUNITY } from "containers/MyMenus/labels";
import { useHistory } from "react-router-dom";
import routesPath from "containers/App/Routes/paths";
import messages from "components/Button/messages";

function Edit({ id, type }) {
  const history = useHistory();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const editMenu = useCallback(() => {
    let whereToPush;

    switch (type) {
      case MANUAL:
        whereToPush = routesPath.editManual;
        break;
      case COMMUNITY:
        whereToPush = routesPath.editCommunity;
        break;
      default:
        whereToPush = routesPath.editOptimized;
    }

    history.push(whereToPush.replace(":id", id));
  }, [history, id, type]);

  return (
    <ContainerIcon onClick={editMenu} hover={colors["brand-01"].hex} isRTL={isRTL}>
      <Icon icon={iconEditGlyph} kind="inverse" width="18px" height="18px" fill={colors["text-01"].hex} />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.edit)} />
    </ContainerIcon>
  );
}

export default Edit;
