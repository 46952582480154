import React from "react";
import { Text } from "components";
import { TextInputStyled } from "./styles";

export default function TextInput({ labelText, marginTop, ...props }) {
  return (
    <>
      {labelText && <Text bold fontSize="14px" marginTop={marginTop || "0px"} value={labelText} />}
      <TextInputStyled {...props} />
    </>
  );
}
