import React from "react";
import { ContainerDay } from "./styles";

function DayBox({ customFlex }) {
  return (
    <ContainerDay xs customFlex={customFlex}>
      <div style={{ height: "100%" }} />
    </ContainerDay>
  );
}

export default DayBox;
