import messages from "../messages";
import { Text } from "components";
import CustomTranslationButton from "./CustomTranslationButton";

const calculatePixelCol = (size, isTabletOrMobile) => ((window.innerWidth - (isTabletOrMobile ? 0 : 62)) / 7) * size;

export const getColumns = (intl, isTabletOrMobile, selectedCountry) => {
  return [
    {
      Header: "#",
      accessor: "id",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: intl.formatMessage(messages.foodItem),
      accessor: "name",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: intl.formatMessage(messages.foodItemAttributeDescription),
      disableSortBy: true,
      accessor: "attribute_description",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: intl.formatMessage(messages.foodItemLocalTranslations),
      disableSortBy: true,
      accessor: "local_translations",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      //Cell: ({ cell: { value } }) => {
      Cell: ({ cell: { value }, row: { original } }) => {
        return <CustomTranslationButton key={original.id} rec={original} country={selectedCountry.iso3} />;
      },
    },
  ];
};
