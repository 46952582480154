import { call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "containers/App/actions";
import { getCountries } from "api/api";
import { loadStarted, loadEnded, loadCountries, loadCountriesSuccess } from "./actions";

export function* loadCountriesSaga() {
  try {
    yield put(loadStarted());
    const { countries } = yield call(getCountries);
    yield put(loadCountriesSuccess({ countries }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* foodPiceSaga() {
  yield takeLatest(loadCountries, loadCountriesSaga);
}
