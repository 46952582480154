import { useMemo, useState, createElement, useCallback } from "react";
import { useTheme } from "@nivo/core";
import { useTooltip } from "@nivo/tooltip";
import { animated, to } from "@react-spring/web";
import { colors } from "@wfp/ui";

// "height" of 10% is 30px. If I resize it stays the same. So maximum height should be 300px.
const MAX_BAR_HEIGHT = 300;

// 1% step.
export const STEP = 3;

// Based on BarItem from @nivo/bar
// Reference: https://github.com/plouc/nivo/blob/master/packages/bar/src/BarItem.tsx
const Bar = ({
  bar: { data, ...bar },
  style: { borderColor, color, height, labelOpacity, labelX, transform, width },
  borderRadius,
  borderWidth,
  label,
  shouldRenderLabel,
  isInteractive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  isFocusable,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  targetByRestriction,
}) => {
  const theme = useTheme();
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const restrictionPercentage = useMemo(() => data.data.restriction, [data.data.restriction]);

  const percentageOver = useCallback((percentage) => data.value > percentage, [data.value]);

  const renderTooltip = useMemo(() => () => createElement(tooltip, { ...bar, ...data }), [tooltip, bar, data]);

  const handleClick = useCallback(
    (event) => {
      onClick?.({ color: bar.color, ...data }, event);
      setTooltipVisible(true);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [bar, data, onClick, setTooltipVisible, renderTooltip, showTooltipFromEvent],
  );

  const handleTooltip = useCallback(
    (event) => {
      return tooltipVisible ? showTooltipFromEvent(renderTooltip(), event) : null;
    },
    [showTooltipFromEvent, renderTooltip, tooltipVisible],
  );

  const handleMouseEnter = useCallback(
    (event) => {
      onMouseEnter?.(data, event);
      setTooltipVisible(true);
      showTooltipFromEvent(renderTooltip(), event);
    },
    [data, onMouseEnter, renderTooltip, showTooltipFromEvent],
  );

  const handleMouseLeave = useCallback(
    (event) => {
      onMouseLeave?.(data, event);
      hideTooltip();
      setTooltipVisible(false);
    },
    [data, hideTooltip, onMouseLeave, setTooltipVisible],
  );

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }, [showTooltipAt, renderTooltip, bar]);

  const handleBlur = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  return (
    <animated.g transform={percentageOver(100) ? `translate(${bar.x} 0)` : transform}>
      <animated.rect
        width={to(width, (value) => Math.max(value, 0))}
        height={to(percentageOver(100) ? MAX_BAR_HEIGHT : height, (value) => Math.max(value, 0))}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ?? color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        focusable={isFocusable}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
      />

      {/* Nutrient label */}
      {shouldRenderLabel && (
        <animated.text
          x={percentageOver(100) ? bar.width / 2 : labelX}
          y={percentageOver(100) ? MAX_BAR_HEIGHT - 10 : bar.height - 10}
          textAnchor="center"
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            fill: colors["ui-01"].hex,
            fontSize: "13px",
            textTransform: "uppercase",
          }}
          transform={`rotate(-90 ${bar.width / 2} ${percentageOver(100) ? MAX_BAR_HEIGHT - 10 : bar.height - 10})`}
        >
          {data.indexValue}
        </animated.text>
      )}

      {/* Triangle shape */}
      {percentageOver(100) && (
        <animated.svg
          xmlns="http://www.w3.org/2000/svg"
          width={to(width, (value) => Math.max(value, 0))}
          height={30}
          y={`${-30 - 1}`}
        >
          <animated.polygon points={`${bar.width / 2} 0, ${bar.width} 30, 0 30`} fill={data.fill ?? color} />
        </animated.svg>
      )}

      {/* Percentage, shown above bar */}
      {label !== "0" && (
        <text
          x={bar.width / 2}
          y={-10}
          textAnchor="middle"
          dominantBaseline="central"
          fillOpacity={labelOpacity}
          style={{
            ...theme.labels.text,
            pointerEvents: "none",
            fill: percentageOver(100) ? colors["ui-01"].hex : colors["text-02"].hex,
            fontSize: "13px",
          }}
        >
          {label}
        </text>
      )}

      {/* Target line */}
      {!!targetByRestriction && !!restrictionPercentage && (
        <TargetLine bar={bar} y={(percentageOver(100) ? MAX_BAR_HEIGHT : bar.height) - STEP * restrictionPercentage} />
      )}
    </animated.g>
  );
};

export default Bar;

export const TargetLine = ({ bar, y }) => <rect width={bar.width} fill={colors["text-01"].hex} height={2} y={y} />;
