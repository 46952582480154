import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

export const selectAdmin = () => (state) => R.pathOr(initialState, ["admin"], state);

export default () => createSelector(selectAdmin(), (adminState) => adminState);

export const selectLoading = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["users", "loading"], adminState)
);

export const selectUsers = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["users", "results"], adminState)
);

export const selectCount = createSelector(selectAdmin(), (adminState) => R.pathOr(0, ["users", "count"], adminState));

export const selectTableParams = createSelector(selectAdmin(), (adminState) => R.pathOr({}, ["params"], adminState));

export const selectTableFilters = createSelector(selectAdmin(), (adminState) => R.pathOr({}, ["filters"], adminState));

export const selectUserGroups = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["users", "groups"], adminState)
);

export const selectLoadingCountriesSlice = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "loading"], adminState)
);

export const selectFormValues = createSelector(selectAdmin(), (adminState) =>
  R.pathOr({}, ["form", "values"], adminState)
);

export const selectMenuType = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "menuType"], adminState)
);

export const selectSelectedSources = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "selectedSources"], adminState)
);

export const selectAllSources = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "allSources"], adminState)
);

export const selectSelectedNutrientsLevel = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "selectedNutrientsLevel"], adminState)
);

export const selectAllNutrientsLevel = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "allNutrientsLevel"], adminState)
);

export const selectSelectedNutrients = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "selectedNutrients"], adminState)
);

export const selectAllNutrients = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "allNutrients"], adminState)
);

export const selectPriorityItems = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "priorityItems"], adminState)
);

export const selectVamMappings = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "vamMappings"], adminState)
);

export const selectVamMappingsCount = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(null, ["countries", "count"], adminState)
);

export const selectVamFoodItems = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "vamFoodItems"], adminState)
);

export const selectVamFoodItemOptions = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "vamFoodItemOptions"], adminState)
);

export const selectFoodItems = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "foodItems"], adminState)
);

export const selectFoodItemOptions = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "foodItemOptions"], adminState)
);

export const selectLoadingFoodItems = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "loadingFoodItems"], adminState)
);

export const selectFoodPrices = createSelector(selectAdmin(), (adminState) =>
  R.pathOr([], ["countries", "foodPrices", "results"], adminState)
);

export const selectFoodPricesCount = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(0, ["countries", "foodPrices", "count"], adminState)
);

export const selectShowApproveAllButton = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "approveAllButtonVisibility"], adminState)
);

export const selectAdminFoodItems = createSelector(selectAdmin(), (adminState) => {
  return R.pathOr([], ["countries", "adminFoodItems", "results"], adminState);
});

export const selectAdminFoodItemsCount = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(0, ["countries", "adminFoodItems", "count"], adminState)
);

export const selectLoadingAdminFoodItems = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "loadingAdminFoodItems"], adminState)
);

export const selectAdminFoodItemsParams = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "adminFoodItemsParams"], adminState)
);

export const selectAdminRecipes = createSelector(selectAdmin(), (adminState) => {
  return R.pathOr([], ["countries", "adminRecipes", "data", "results"], adminState);
});

export const selectAdminRecipesCount = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(0, ["countries", "adminRecipes", "data", "count"], adminState)
);

export const selectAdminRecipesLoading = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "adminRecipeLoading"], adminState)
);

export const selectAdminVamFoodPricesLoading = createSelector(selectAdmin(), (adminState) =>
  R.pathOr(false, ["countries", "adminVamFoodPricesLoading"], adminState)
);


