import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  containerRow: {
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  firstCol: {
    flex: 1,
    textWrap: "nowrap",
    textOverflow: "ellipsis",
  },
  secondCol: {
    flexShrink: 0,
    marginLeft: 3,
  },
});
