import React, { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { Field, useField, useForm } from "react-final-form";
import { colors, InputGroup, RadioButton, Icon } from "@wfp/ui";
import { iconArrowRight, iconArrowLeft } from "@wfp/icons";
import { useResponsiveHook, useHtmlHook } from "hooks";
import { ReactSelectWithDataTestId, RowStyled, ColStyled } from "components/utils";
import { Text } from "components";
import { Select } from "components/InputFields";
import { useMonths } from "utils/utils";
import { periodOptions } from "./utils";
import { FIELDS } from "../utils";
import messages from "./messages";

function Seasonality() {
  const form = useForm();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();
  const { isTabletOrMobile } = useResponsiveHook();

  const {
    input: { value: seasonality, onChange },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.SEASONALITY}`);
  const {
    input: { value: startMonth, onChange: changeStartMonth },
    meta,
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.MONTH_FROM}`);
  const {
    input: { value: endMonth, onChange: changeEndMonth },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.MONTH_TO}`);
  const months = useMonths(intl);

  const defaultSeasonality = useMemo(() => (startMonth === 1 && endMonth === 12 ? "y" : "m"), [endMonth, startMonth]);

  const onChangeSeasonality = useCallback(
    (e) => {
      form.batch(() => {
        if (e.target.value === "y") {
          if (startMonth !== 1) {
            changeStartMonth(1);
          }

          if (endMonth !== 12) {
            changeEndMonth(12);
          }
        }

        onChange(e.target.value);
      });
    },
    [form, startMonth, endMonth, changeEndMonth, changeStartMonth, onChange],
  );

  return (
    <ColStyled xs={isTabletOrMobile ? 9 : 6} padding="0">
      <Text bold fontSize="20px" value={intl.formatMessage(messages.seasonality)} />
      <Text
        wrap
        marginBottom="5px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.seasonalityInfo)}
      />
      <InputGroup>
        {periodOptions(intl).map(({ value, label }) => (
          <Field key={value} name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.SEASONALITY}`} type="radio" value={value}>
            {({ input }) =>
              startMonth &&
              endMonth && (
                <RadioButton
                  key={`key-${value}`}
                  value={value}
                  name={input.name}
                  id={value}
                  checked={(seasonality || defaultSeasonality) === value}
                  labelText={label}
                  onChange={(e) => onChangeSeasonality(e)}
                />
              )
            }
          </Field>
        ))}
      </InputGroup>
      <RowStyled margin="20px 0 0" middle="xs" className={(seasonality || defaultSeasonality) !== "m" ? "disable" : ""}>
        <ColStyled xs={4} minwidth="140px" margin="0 0 0 -8px">
          <Field
            name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.MONTH_FROM}`}
            options={months}
            data-test-id="start-month"
            menuPlacement="top"
            components={ReactSelectWithDataTestId}
            component={Select}
            capitalize
          />
        </ColStyled>
        <ColStyled xs={1} className="center-xs">
          <Icon
            width="20"
            height="20px"
            icon={isRTL ? iconArrowLeft : iconArrowRight}
            description=" "
            fill={colors["brand-01"].hex}
          />
        </ColStyled>
        <ColStyled xs={4} minwidth="140px">
          <Field
            name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.MONTH_TO}`}
            options={months}
            data-test-id="end-month"
            menuPlacement="top"
            components={ReactSelectWithDataTestId}
            component={Select}
            capitalize
          />
        </ColStyled>
      </RowStyled>
      {meta.error && <Text defaultColor={colors["support-01"].hex} value={meta.error} />}
    </ColStyled>
  );
}

export default Seasonality;
