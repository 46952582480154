import styled from "styled-components";
import { mediaTablet } from "components/utils";

export const SourceContainer = styled.div`
  @media (max-width: 768px) {
    .min-max-percentage.col-xs-2 {
      flex-basis: 33%;
      max-width: 33%;
    }
  }

  ${mediaTablet} {
    .col-xs-2 {
      flex-basis: 20%;
      max-width: 21%;
    }

    .col-xs-5 {
      flex-basis: 54%;
      max-width: 54%;
    }

    .min-max-percentage.col-xs-2 {
      flex-basis: 28%;
      max-width: 28%;
    }
  }

  @media (max-width: 768px) {
    .min-max-percentage.col-xs-2 {
      flex-basis: 33%;
      max-width: 33%;
    }
  }
`;
