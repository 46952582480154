import { colors } from "@wfp/ui";
import styled, { css } from "styled-components";
import { components } from "react-select";
import { Row, Col } from "react-flexbox-grid";

import {
  ADJUSTED,
  COMMUNITY,
  DRAFT,
  ERROR,
  INFEASIBLE,
  MANUAL,
  OPTIMAL,
  OPTIMIZED,
  RUNNING,
  RUNNING_SUBOPTIMAL,
  SUBOPTIMAL,
} from "containers/MyMenus/labels";

export const mediaTablet = `@media (max-width: 1024px)`;

// Used for "react-loader-spinner" wrapperStyle prop
export const loaderWrapperStyle = { justifyContent: "center" };

// TODO: Following colors are azure, blue and darker blue, which are the ones used before new layout. Keep them for the time being so nothing breaks, but in case they aren't used anymore will be removed at the end of layout rework
export const colorByCategory = {
  macros: colors["brand-01-70"].hex,
  vitamins: colors["brand-01-40"].hex,
  minerals: colors["brand-01-60"].hex,
};

// TODO: Probably "textColor" can be removed. Wait for refactoring to be over and make sure this is true.
export const newColorByCategory = {
  macros: { backgroundColor: colors["brand-01-70"].hex, textColor: colors["support-02"].hex },
  vitamins: { backgroundColor: colors["categorical-corporate-04"].hex, textColor: "#A89652" },
  minerals: { backgroundColor: colors["categorical-corporate-03"].hex, textColor: "#99693B" },
};

// TODO: Remove once we end up refactoring whole app and this isn't needed anymore.
export const colorBySource = {
  school_vicinity_shf: "#A56EFF",
  "school_vicinity_-_trader": "#6F2DDA",
  centralized_shf_direct: "#079E9E",
  centralized_shf_indirect: "#036849",
  international: "#9F1853",
  "centralized_-_trader": "#D7347B",
};

export const newColorBySource = {
  "centralized_-_trader": "#5FD79A",
  centralized_shf_direct: "#F77F5B",
  centralized_shf_indirect: "#36B5C5",
  international: "#8C9BA5",
  "school_vicinity_-_trader": "#F5A70F",
  school_vicinity_shf: "#B79F8D",
};

export const tagColorByStatus = {
  [ADJUSTED]: "success",
  [COMMUNITY]: "success",
  [DRAFT]: "warning",
  [ERROR]: "error",
  [INFEASIBLE]: "error",
  [MANUAL]: "success",
  [OPTIMAL]: "success",
  [OPTIMIZED]: "success",
  [RUNNING]: "warning",
  [RUNNING_SUBOPTIMAL]: "warning",
  [SUBOPTIMAL]: "warning",
};

const { Control } = components;

const ControlWithDataTestId = ({ innerProps, ...props }) => (
  <Control {...props} innerProps={{ ...innerProps, "data-test-id": props.selectProps["data-test-id"] }} />
);

const CustomCol = ({ svgDisabled, margin, padding, height, width, maxWidth, display, ...props }) => <Col {...props} />;

const CustomRow = ({ padding, margin, width, height, overflow, border, cursor, flex, flexWrap, span, ...props }) => (
  <Row {...props} />
);

export const ReactSelectWithDataTestId = {
  ...components,
  Control: ControlWithDataTestId,
};

export const RowStyled = styled(CustomRow)`
  margin: ${({ margin }) => margin || 0};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  column-span: ${({ span }) => (span ? "all" : "none")};
  ${({ padding }) =>
    padding &&
    css`
      ${{ padding }}
    `};
  ${({ width }) =>
    width &&
    css`
      ${{ width }}
    `};
  ${({ height }) =>
    height &&
    css`
      ${{ height }}
    `};
  ${({ overflow }) =>
    overflow &&
    css`
      ${{ overflow }}
    `};
  ${({ border }) =>
    border &&
    css`
      ${{ border }}
    `};
  ${({ cursor }) =>
    cursor &&
    css`
      ${{ cursor }}
    `};
  ${({ flex }) =>
    flex &&
    css`
      ${{ flex }}
    `};
`;

export const ColStyled = styled(CustomCol)`
  margin: ${({ margin }) => margin || 0};
  ${({ padding }) =>
    padding &&
    css`
      ${{ padding }}
    `};
  ${({ height }) =>
    height &&
    css`
      ${{ height }}
    `};
  ${({ width }) =>
    width &&
    css`
      ${{ width }}
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      ${{ maxWidth }}
    `};
  ${({ display }) =>
    display &&
    css`
      ${{ display }}
    `};
  ${({ svgDisabled }) =>
    svgDisabled &&
    css`
      svg {
        g {
          fill: ${colors["text-02"].hex};
        }
      }
    `};
  ${mediaTablet} {
    .col-xs-2 {
      flex-basis: 25%;
      max-width: 25%;
    }
    .col-xs-4 {
      flex-basis: 36%;
      max-width: 36%;
    }
  }
`;
