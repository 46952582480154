import { useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { ModalStyled } from "components/UIKitStyled";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useInitialValues } from "containers/GeneralInfo/useInitialValues";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";

const FoodBasketModal = ({ open, setOpenFoodBasketModal }) => {
  const { setValue } = useFormContext();
  const initialValues = useInitialValues();

  const [step, setStep] = useState(0);
  const [foodItemIndex, setFoodItemIndex] = useState();
  const [openAddPriceSource, setOpenAddPriceSource] = useState(false);
  const [openAddNewFoodItem, setOpenAddNewFoodItem] = useState(false);

  const openAddNewFoodItemSection = useCallback(() => setOpenAddNewFoodItem(true), []);

  const resetAndCloseAddNewPriceSource = useCallback(() => {
    setOpenAddPriceSource(false);
    setValue(`${FIELDS.DRAFT}.${FIELDS.SOURCE}`, initialValues[FIELDS.DRAFT][FIELDS.SOURCE]);
  }, [initialValues, setValue]);

  const resetAndCloseAddNewFoodItem = useCallback(() => {
    setOpenAddNewFoodItem(false);
    setValue(`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}`, initialValues[FIELDS.DRAFT][FIELDS.NEW_ITEM]);
  }, [initialValues, setValue]);

  const closeModal = useCallback(() => {
    setOpenFoodBasketModal(false);
    resetAndCloseAddNewPriceSource();
    resetAndCloseAddNewFoodItem();
    setStep(0);
    setFoodItemIndex();
  }, [resetAndCloseAddNewFoodItem, resetAndCloseAddNewPriceSource, setOpenFoodBasketModal]);

  const editFoodItem = useCallback(
    (item) => {
      setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}`, item);
      setFoodItemIndex(item.id);
      setStep(1);
    },
    [setFoodItemIndex, setStep, setValue],
  );

  return (
    <ModalStyled open={open} lazyLoad onRequestClose={closeModal} noFooter padding="2rem" maxWidth="80%" width="100%">
      {step === 0 && (
        <PageOne
          openAddNewFoodItem={openAddNewFoodItem}
          openAddNewFoodItemSection={openAddNewFoodItemSection}
          resetAndCloseAddNewFoodItem={resetAndCloseAddNewFoodItem}
          editFoodItem={editFoodItem}
        />
      )}

      {step === 1 && (
        <PageTwo
          foodItemIndex={foodItemIndex}
          setStep={setStep}
          openAddPriceSource={openAddPriceSource}
          setOpenAddPriceSource={setOpenAddPriceSource}
        />
      )}
    </ModalStyled>
  );
};

export default FoodBasketModal;
