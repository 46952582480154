import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectPlusMenu = () => (state) => R.pathOr(initialState, ["optimizedMenu"], state);

export default () => createSelector(selectPlusMenu(), (plusMenuState) => plusMenuState);

export const selectLoading = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr(false, ["loading"], plusMenuState),
);

export const selectInitValues = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr({}, ["initValues"], plusMenuState),
);
export const selectSourcingConstraints = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr([], ["plusMenu", "sourcing_constraints"], plusMenuState),
);

export const selectMenuGeneralParameter = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr({}, ["plusMenu", "menu_general_parameter"], plusMenuState),
);

export const selectNutrientRestrictions = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr([], ["plusMenu", "nutrient_restrictions"], plusMenuState),
);

export const selectInfeasibleNutrients = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr({}, ["initValues", "advNutritionalRequirements", "infeasible_nutrients"], plusMenuState),
);

export const selectCriticNutrients = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr({}, ["initValues", "advNutritionalRequirements", "critic_nutrients"], plusMenuState),
);

export const selectAvailableSources = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr([], ["availableSources"], plusMenuState),
);

export const selectFoodBasketSources = createSelector(selectPlusMenu(), (plusMenuState) =>
  R.pathOr([], ["foodBasketSources"], plusMenuState),
);
