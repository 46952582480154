import FoodTable from "./FoodTable";
import {Route, Switch} from "react-router-dom";


const FoodItems = ()  => {



    return (
       <Switch>
           <Route component={FoodTable}/>
       </Switch>
    )
}

export default FoodItems
