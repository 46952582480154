import { colors, Icon } from "@wfp/ui";
import { iconCheckmarkGlyph, iconCloseGlyph } from "@wfp/icons";
import { Text } from "components";
import messages from "./messages";
import ActivationButton from "./ActivationButton";
import Roles from "./Roles";
import { ROLES } from "../constants";

const calculatePixelCol = (size, isTabletOrMobile) => ((window.innerWidth - (isTabletOrMobile ? 0 : 62)) / 7) * size;

export const getColumns = (countries, isTabletOrMobile) => {
  return [
    {
      Header: "Email",
      accessor: "email",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: "First name",
      accessor: "first_name",
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: "Last name",
      accessor: "last_name",
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value} />,
    },
    {
      Header: "Country",
      accessor: "country",
      Cell: ({ cell: { row } }) => {
        const currentUserCountryCode = row.original.profile.country;
        const country = countries?.find((country) => country.value === currentUserCountryCode);
        return <Text fontSize="16px" value={country?.label} />;
      },
    },
    {
      Header: "Role",
      disableSortBy: true,
      accessor: "role",
      Cell: ({ cell: { row } }) => <Roles user={row.original} />,
    },
    {
      Header: "Active",
      accessor: "is_active",
      Cell: ({ cell: { row } }) => boolIconCell(row.original.is_active),
    },
    {
      Header: "",
      disableSortBy: true,
      accessor: "switch_active",
      Cell: ({ cell: { row } }) => <ActivationButton user={row.original} />,
    },
  ];
};

export const columnsClickable = ["email", "first_name", "last_name", "country", "is_active"];

export const boolIconCell = (value) => (
  <Icon
    fill={value ? colors["categorical-corporate-03"].hex : colors["categorical-corporate-07"].hex}
    icon={value ? iconCheckmarkGlyph : iconCloseGlyph}
  />
);

export const reworkData = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    // Filter coming from select, structured like { value: "value", label: "label" }
    if (typeof data[key] === "object" && !Array.isArray(data[key])) {
      // Handle "is_active" field
      if (key === "is_active") {
        const keyName = "is_active";
        // Do not include "is_active" filter if user selected "All Users"
        if (data[key].value === "") return { ...acc };
        const value = data[key].value === "active";
        return {
          ...acc,
          [keyName]: Number(value),
        };
      }

      return { ...acc };
    } else {
      return {
        ...acc,
        [key]: data[key],
      };
    }
  }, {});
};

export const reworkUserGroups = (userGroups, loggedInUserRoles) => {
  // If logged in user is a country_admin, we do not show the option to mark a user as global admin
  if (loggedInUserRoles?.[0] === ROLES.COUNTRY_ADMIN) {
    return userGroups
      .filter((el) => el.name !== ROLES.GLOBAL_ADMIN)
      .map((el) => ({
        id: el.id,
        label: el.label,
        value: el.name,
      }));
  }

  return userGroups.map((el) => ({
    id: el.id,
    label: el.label,
    value: el.name,
  }));
};

export const intlTabs = (intl) => [
  {
    label: intl.formatMessage(messages.usersFirstTab),
    href: "/plusadmin/users/all",
  },
];
