import styled from "styled-components";
import { colors } from "@wfp/ui";
import TextareaAutosize from "react-textarea-autosize";

export const RecipeTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${colors["ui-03"].hex};
`;

export const TextareaAutosizeStyled = styled(TextareaAutosize)`
  display: block;
  outline: none;
  padding: 5px;
  width: 100%;
  background: transparent;
  border: 1px solid #8ca4b5;
  pointer-events: "auto";
  resize: none;
  font-family: "Open Sans", sans-serif;

  :focus {
    outline: none;
    box-shadow: 0px 2px 8px 0 #c2dbec;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 20px;
`;
