import { useEffect, useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { selectMealCount, selectMenuNotes, selectMealNames } from "containers/Menu/selectors";
import { FIELDS, INITIAL_VALUES } from "./constants";
import { selectFoodItems, selectAvailableRecipes, selectMenuRecipes } from "./selectors";
import { initInitialValues, initAvailableRecipes, getDayRecipes } from "./utils";

// Hook to get both sync and async initial values
export const useInitialValues = () => {
  const mealCount = useSelector(selectMealCount);
  const selectedFoodItems = useSelector(selectFoodItems);
  const menuNotes = useSelector(selectMenuNotes);
  const mealNames = useSelector(selectMealNames);
  const availableRecipes = useSelector(selectAvailableRecipes);
  const menuRecipes = useSelector(selectMenuRecipes);

  return useMemo(() => {
    if (
      mealCount === 0 ||
      R.isNil(selectedFoodItems) ||
      R.isNil(mealNames) ||
      R.isNil(availableRecipes) ||
      R.isNil(menuRecipes)
    ) {
      return INITIAL_VALUES;
    }

    return {
      ...INITIAL_VALUES,
      [FIELDS.MENU_COMPOSITION]: {
        ...INITIAL_VALUES[FIELDS.MENU_COMPOSITION],
        ...initInitialValues({ mealCount, selectedFoodItems }),
      },
      [FIELDS.MEAL_NAMES]: {
        ...INITIAL_VALUES[FIELDS.MEAL_NAMES],
        ...mealNames,
      },
      [FIELDS.NOTES]: menuNotes,
      [FIELDS.AVAILABLE_RECIPES]: [...initAvailableRecipes(availableRecipes)],
      [FIELDS.INCLUDED_RECIPES]: new Array(mealCount).fill().reduce(
        (acc, _, index) => ({
          ...acc,
          [`day_${index + 1}`]: getDayRecipes({ day: index + 1, menuRecipes }),
        }),
        {},
      ),
    };
  }, [mealCount, selectedFoodItems, mealNames, availableRecipes, menuRecipes, menuNotes]);
};

// Define form default values and reset its slices when specific data changes
export const useAsyncForm = () => {
  const mealCount = useSelector(selectMealCount);
  const selectedFoodItems = useSelector(selectFoodItems);
  const menuNotes = useSelector(selectMenuNotes);
  const mealNames = useSelector(selectMealNames);
  const availableRecipes = useSelector(selectAvailableRecipes);
  const menuRecipes = useSelector(selectMenuRecipes);

  const formValues = useInitialValues();
  const useFormApi = useForm({ defaultValues: formValues });

  // "mealCount" or "selectedFoodItems" change: run reset only when we have both available
  useEffect(() => {
    if (selectedFoodItems && mealCount !== 0) {
      useFormApi.reset({
        ...useFormApi.getValues(),
        [FIELDS.MENU_COMPOSITION]: initInitialValues({ mealCount, selectedFoodItems }),
      });
    }
  }, [mealCount, selectedFoodItems, useFormApi]);

  // "notes" change
  useEffect(() => {
    useFormApi.reset({ ...useFormApi.getValues(), [FIELDS.NOTES]: menuNotes });
  }, [menuNotes, useFormApi]);

  // "mealNames" change
  useEffect(() => {
    useFormApi.reset({ ...useFormApi.getValues(), [FIELDS.MEAL_NAMES]: mealNames });
  }, [mealNames, useFormApi]);

  // "recipes" change: we need data coming from multiple endpoints
  useEffect(() => {

    if (!R.isNil(availableRecipes) && mealCount !== 0 && !R.isNil(menuRecipes)) {
      useFormApi.reset({
        ...useFormApi.getValues(),
        [FIELDS.AVAILABLE_RECIPES]: [...initAvailableRecipes(availableRecipes)],
        [FIELDS.INCLUDED_RECIPES]: new Array(mealCount).fill().reduce(
          (acc, _, index) => ({
            ...acc,
            [`day_${index + 1}`]: getDayRecipes({ day: index + 1, menuRecipes }),
          }),
          {},
        ),
      });
    }
  }, [availableRecipes, mealCount, menuRecipes, useFormApi]);

  return useMemo(() => ({ useFormApi }), [useFormApi]);
};
