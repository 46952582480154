import { useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ModuleHeader, ModuleBody, ModuleFooter } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, NewModule } from "components";
import { RowStyled } from "components/utils";
import { selectWeightChoices, selectRateFromUSD, selectRateFromLocal } from "containers/App/selectors";
import { selectFCTFoodItems } from "containers/FoodBasket/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import AddToFoodBasket from "./AddToFoodBasket";
import ChooseFromGlobalBasket from "./ChooseFromGlobalBasket";
import { DEFAULT_NEW_ITEM_PROPERTIES } from "../constants";
import { DECIMAL_DIGITS } from "../../constants";
import Footer from "./Footer";
import { normalizePrice } from "./utils";

const AddPersonalFoodItem = ({ resetAndClose }) => {
  const intl = useIntl();
  const { control, getValues, setValue } = useFormContext();

  const formUnit = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.UNIT}` });
  const formCurrency = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.CURRENCY}` });

  const fctFoodItems = useSelector(selectFCTFoodItems);
  const units = useSelector(selectWeightChoices);
  const conversionRateFromUsd = useSelector(selectRateFromUSD);
  const conversionRateFromLocal = useSelector(selectRateFromLocal);

  const unit = useMemo(() => units.find((unit) => unit.value === formUnit), [formUnit, units]);
  const gramUnit = useMemo(() => units.find((unit) => unit.value === "1 g"), [units]);

  const save = useCallback(() => {
    const values = getValues();
    const basket = values[FIELDS.BASKET];
    const draftItem = values[FIELDS.DRAFT][FIELDS.NEW_ITEM];

    const {
      is_priority,
      label: display_name,
      major_group,
      major_group_id,
      value,
    } = fctFoodItems.find((item) => item.value === draftItem.item);
    const price = normalizePrice({
      from: unit,
      to: gramUnit,
      fromCurrency: formCurrency,
      toCurrency: "USD",
      price: draftItem.price,
      conversionRateFromUsd,
      conversionRateFromLocal,
      decimals: DECIMAL_DIGITS,
    });
    const newItem = {
      is_priority,
      display_name,
      major_group,
      major_group_id,
      value,
      prices: {
        [draftItem.source]: {
          ...DEFAULT_NEW_ITEM_PROPERTIES,
          user_price_per_gram: price,
        },
      },
      user_provided_item: true,
    };

    const newBasket = { ...basket, [draftItem.item]: newItem };
    setValue(FIELDS.BASKET, newBasket);
    resetAndClose();
  }, [
    conversionRateFromLocal,
    conversionRateFromUsd,
    fctFoodItems,
    formCurrency,
    getValues,
    gramUnit,
    resetAndClose,
    setValue,
    unit,
  ]);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <Text bold fontSize="16px" value={intl.formatMessage(messages.addToThisMenu)} />
      </ModuleHeader>
    ),
    [intl],
  );

  const body = useMemo(
    () => (
      <ModuleBody>
        <RowStyled>
          <ChooseFromGlobalBasket />
          <AddToFoodBasket />
        </RowStyled>
      </ModuleBody>
    ),
    [],
  );

  const footer = useMemo(
    () => (
      <ModuleFooter>
        <Footer close={resetAndClose} save={save} />
      </ModuleFooter>
    ),
    [resetAndClose, save],
  );

  return (
    <NewModule
      withBorder
      header={header}
      headerPadding="2.2rem 0.8rem 0"
      body={body}
      contentPadding="0.8rem"
      footer={footer}
      footerPadding="0.8rem"
    />
  );
};

export default AddPersonalFoodItem;
