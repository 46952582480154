import React, { useCallback, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Modal, Button, TextInput } from "@wfp/ui";
import { updateMenu } from "containers/Menu/actions";
import { ACTIONS } from "../constants";
import buttonMessages from "components/Button/messages";
import messages from "./messages";

function Rename({ menuAndAction, closeModal, callback, intl }) {
  const [menuName, setMenuName] = useState(menuAndAction.name);
  const dispatch = useDispatch();

  const callbackToClose = useCallback(() => {
    closeModal();
    callback();
  }, [callback, closeModal]);

  const renameMenuName = useCallback(() => {
    if (!menuAndAction) return;
    dispatch(updateMenu({ id: menuAndAction.id, body: { name: menuName }, callback: callbackToClose }));
  }, [callbackToClose, dispatch, menuAndAction, menuName]);

  const onChangeMenu = useCallback(({ target: { value } }) => {
    setMenuName(value);
  }, []);

  const RenameButton = useCallback(
    () => (
      <Button kind="secondary" onClick={renameMenuName} disabled={!menuName}>
        <FormattedMessage {...buttonMessages.rename} />
      </Button>
    ),
    [menuName, renameMenuName],
  );

  return (
    <Modal
      open={menuAndAction && !!menuAndAction.id && menuAndAction.action === ACTIONS.RENAME}
      onRequestClose={closeModal}
      modalHeading={intl.formatMessage(messages.renameTitle)}
      modalFooter={RenameButton}
    >
      <TextInput
        id="menuNameModal"
        defaultValue={menuName}
        labelText={intl.formatMessage({ id: "menu.titleName", defaultMessage: "Menu name" })}
        placeholder={intl.formatMessage({ id: "menu.placeholderName", defaultMessage: "Write menu name" })}
        onChange={onChangeMenu}
      />
    </Modal>
  );
}

export default injectIntl(Rename);
