import { colors, Icon } from "@wfp/ui";
import { iconWfpHumFoodSecurityClusterPos } from "@wfp/icons";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { RecipeNameContainer, FoodItemContainer } from "./styles";

const RecipePreview = ({ recipe }) => {
  return (
    <>
      <RecipeNameContainer>
        <ColStyled xs className="start-xs" padding="0">
          <RowStyled middle="xs">
            <Text bold value={recipe.name} marginRight="5px" />
            <Icon
              icon={iconWfpHumFoodSecurityClusterPos}
              description="recipe"
              fill={colors["brand-01"].hex}
              width="18"
              height="18"
            />
          </RowStyled>
        </ColStyled>
      </RecipeNameContainer>

      {Object.entries(recipe.ingredients).map(([ingredientKey, { display_name, is_priority, quantity }]) => (
        <FoodItemContainer key={ingredientKey}>
          <ColStyled xs={8} className="start-xs" padding="0">
            <Text
              value={display_name.split(";")[0]}
              defaultColor={is_priority ? colors["support-02"].hex : colors["text-01"].hex}
            />
          </ColStyled>
          <ColStyled xs={4} className="end-xs">
            <Text defaultColor={colors["text-02"].hex} value={`${quantity.toFixed(1)} g`} />
          </ColStyled>
        </FoodItemContainer>
      ))}
    </>
  );
};

export default RecipePreview;
