import { useMemo } from "react";
import { FixedSizeList as List } from "react-window";

const HEIGHT = 40;

// https://stackoverflow.com/questions/62143009/efficiently-rendering-large-list-of-data-in-react-select/69112081#69112081
const MenuList = ({ options, getValue, maxHeight, children }) => {
  const [value] = getValue();
  const initialOffset = useMemo(() => options.indexOf(value) * HEIGHT, [options, value]);

  return (
    <List height={maxHeight} itemCount={children.length} itemSize={HEIGHT} initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export default MenuList;
