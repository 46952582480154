import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import messages from "components/Button/messages";
import { selectMenuId } from "containers/Menu/selectors";
import { saveNutritionalAdvancedRequest } from "containers/OptimizedMenu/actions";
import { withModalHandling } from "hocs";
import CaloriesPercentage from "./CaloriesPercentage";
import { FIELDS } from "./constants";
import Limit from "./Limit";

const ModalNutrient = ({ open, nutrient, initialValues, label, limit, setStatusModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { control, getValues, setValue, reset } = useFormContext();
  const menuId = useSelector(selectMenuId);

  const advNutrient = useWatch({ control, name: FIELDS.ADVANCED_REQUIREMENTS });

  const isInvalid = useMemo(
    () => advNutrient[FIELDS.MAX_LIMITS][`key_${nutrient}`].value > limit,
    [advNutrient, limit, nutrient],
  );

  const submitModal = useCallback(() => {
    const body = {
      ...advNutrient,
      [FIELDS.MAX_LIMITS]: Object.values(advNutrient[FIELDS.MAX_LIMITS]),
    };

    // Once PATCH call is done, update react-hook-form data as well.
    const cb = () => {
      const values = getValues();
      reset({ ...values, [FIELDS.ADVANCED_REQUIREMENTS]: advNutrient });
    };

    dispatch(saveNutritionalAdvancedRequest({ id: menuId, body, cb }));
    setStatusModal(false);
  }, [advNutrient, dispatch, getValues, menuId, reset, setStatusModal]);

  const resetAndClose = useCallback(() => {
    setValue(FIELDS.ADVANCED_REQUIREMENTS, initialValues[FIELDS.ADVANCED_REQUIREMENTS]);
    setStatusModal(false);
  }, [initialValues, setStatusModal, setValue]);

  return (
    <Modal
      modalHeading={label}
      primaryButtonText={intl.formatMessage(messages.save)}
      onRequestSubmit={submitModal}
      primaryButtonDisabled={isInvalid}
      onRequestClose={resetAndClose}
      open={open}
    >
      <CaloriesPercentage nutrient={nutrient} />
      <Limit label={label} limit={limit} nutrient={nutrient} />
    </Modal>
  );
};

export default compose(withModalHandling)(ModalNutrient);
