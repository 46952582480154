import { useCallback, useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { compose } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "@wfp/ui";

import { Layout, Text, Line } from "components";
import messages from "components/MenuWizard/messages";
import { ModuleWhite } from "components/UIKitStyled";
import routesPath from "containers/App/Routes/paths";
import { updateFormState } from "containers/GeneralInfo/GeneralForm/actions";
import { selectFoodBasket } from "containers/GeneralInfo/GeneralForm/selectors";
import { createOrUpdateCommunityMenu, resetMenu } from "containers/Menu/actions";
import { withAuth, withCountryKey } from "hocs";
import GeneralForm from "./GeneralForm";
import WarningLowNumber, { showLowNumberModal } from "./WarningLowNumber";

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams();
  const [showModal, setModal] = useState();
  const { group_count, items_count } = useSelector(selectFoodBasket);
  const menuId = useMemo(() => id ?? "", [id]);

  useEffect(() => {
    dispatch(resetMenu());
  }, [dispatch]);

  const callbackOnSubmit = useCallback(
    (id) => {
      history.push(routesPath.resultsCommunity.replace(":id", id));
      dispatch(updateFormState({ formState: {} }));
    },
    [dispatch, history],
  );

  const onSubmit = useCallback(() => {
    dispatch(createOrUpdateCommunityMenu({ callback: callbackOnSubmit }));
  }, [callbackOnSubmit, dispatch]);

  const openModalOrCall = useCallback(() => {
    if (showLowNumberModal(group_count, items_count)) return setModal(true);
    return onSubmit();
  }, [group_count, items_count, onSubmit]);

  return (
    <Layout withBreadcrumbs={false} backgroundColor={colors["ui-02"].hex}>
      <ModuleWhite margin="15px 0" paddingContent="10px 30px 20px">
        <Text bold fontSize="16px" value={intl.formatMessage(messages.general)} />
        <Line margin="10px -30px" />
        <GeneralForm onSubmit={openModalOrCall} menuId={menuId} />
        <WarningLowNumber showModal={showModal} setModal={setModal} clickOnContinue={onSubmit} />
      </ModuleWhite>
    </Layout>
  );
};

export default compose(withAuth, withCountryKey)(GeneralInfo);
