import styled from "styled-components";

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  align-items: center;
`;

export const Label = styled.span`
  position: absolute;
  transition: all 0.2s linear;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  right: 55%;
  top: -5%;
  user-select: none;
  line-height: normal;
`;
