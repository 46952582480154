import React from "react";
import { colors } from "@wfp/ui";

export const Roots = ({ size, disable }) => (
  <svg
    width={size || "20px"}
    height={size || "20px"}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-222.000000, -792.000000)" fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}>
        <g transform="translate(222.000000, 792.000000)">
          <g>
            <path
              d="M0.186138474,19.2573166 L0.305325146,18.7713407 C1.42839349,14.1929966 -0.119192873,6.03721899 5.58602807,6.44703718 C10.65708,6.81124106 15.2098105,14.4244154 11.9809988,17.008443 C10.4674122,18.2197851 7.02688846,17.976239 3.9921206,18.4660232 C3.01194722,18.6242378 1.50318456,19.1092167 0.186138474,19.2573166 Z"
              fillRule="nonzero"
            />
            <path d="M10.9664886,9.8432224 C10.9711044,9.84169362 11.0453977,9.80578169 11.1073132,9.77597055 C11.1506189,9.75511146 11.1879275,9.73726596 11.1911467,9.73620744 C13.249012,9.07241847 15.7589617,10.5312794 17.1502003,9.60310016 C17.4870771,9.37835394 18.0770555,9.04062321 17.8025229,8.55494237 C17.4044063,7.85055244 15.0694657,8.26165055 14.2360107,8.23998313 C15.7717797,7.94207563 17.4395224,7.44263362 18.6539896,6.55311609 C18.9402843,6.34342255 19.4360829,6.01996787 19.2696354,5.66703735 C19.0272934,5.15315911 17.3613149,5.68976333 16.5148813,5.77619849 L16.0489969,5.82378198 C15.9416145,5.58124885 19.3818388,2.52604497 18.215862,1.36006445 C18.2089972,1.35319962 18.2017942,1.34673165 18.1946501,1.34021963 C18.1881381,1.33307548 18.1816701,1.32587256 18.1748053,1.31900773 C17.0088136,0.153027211 13.9535959,3.59325221 13.7110877,3.48587283 L13.7586567,3.01998841 C13.8451064,2.17355481 14.3817102,0.507574451 13.8678178,0.265234354 C13.5149033,0.0987879091 13.1914461,0.594585019 12.981754,0.880880133 C12.0922365,2.09535481 11.592778,3.76308257 11.294872,5.29885903 C11.2732191,4.46541893 11.6843151,2.13047835 10.9799274,1.73234685 C10.4942278,1.45782692 10.1565162,2.04780604 9.93175463,2.38468435 C9.02039083,3.75072754 10.3660925,6.3456876 9.78635841,8.38839377 C9.77342252,8.43396327 10.9373441,9.7948027 10.9665086,9.84324085 L10.9664886,9.8432224 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
