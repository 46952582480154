import { FormattedMessage } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext } from "react-hook-form";

import { Text } from "components";
import { NumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "./constants";
import messages from "../../messages";

const Limit = ({ label, limit, nutrient }) => {
  const { control } = useFormContext();

  return (
    <RowStyled middle="xs" between="xs" margin="20px 0 0">
      <ColStyled xs padding="0">
        <Text bold value={<FormattedMessage {...messages.maximum} values={{ value: `${label} - (${limit})` }} />} />
        <Text fontSize="12px" colors={colors["text-02"].hex} value={<FormattedMessage {...messages.maximumAmount} />} />
      </ColStyled>
      <ColStyled xs={3} padding="0">
        <NumberInputHookForm
          control={control}
          name={`${FIELDS.ADVANCED_REQUIREMENTS}.${FIELDS.MAX_LIMITS}.key_${nutrient}.value`}
        />
      </ColStyled>
    </RowStyled>
  );
};

export default Limit;
