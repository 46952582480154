import React, { useState, useCallback, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { colors } from "@wfp/ui";
import { injectIntl } from "react-intl";
import * as R from "ramda";
import { Text } from "components";
import useSize from "@react-hook/size";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import Placeholder from "./Placeholder";
import { ContainerDay, Header } from "./styles";
import { selectPermissions } from "containers/App/selectors";
import { PERMISSIONS } from "containers/Admin/constants";
import { checkPermission } from "components/Unlock/utils";

function DayBox({
  day,
  list,
  mealName,
  maxWidth,
  customFlex,
  daysInWeek,
  week,
  setWeek,
  setAbsoluteDay,
  setRelativeDay,
  intl,
}) {
  const [hover, setHover] = useState();
  const $containerDay = useRef();
  const [, height] = useSize($containerDay);
  const calcXsItem = daysInWeek > 3 ? daysInWeek : 3;
  const calcXsGrams = daysInWeek - (daysInWeek > 5 ? 2 : 1);
  const userPermissions = useSelector(selectPermissions);

  const hasChangePermission = useMemo(
    () => checkPermission(userPermissions, PERMISSIONS.CHANGE_COMMUNITY_MENU),
    [userPermissions],
  );

  const activeHover = useCallback(() => setHover(true), []);
  const disactiveHover = useCallback(() => setHover(false), []);

  const orderedList = useMemo(() => {
    return R.sortWith([R.ascend(R.prop("ordering_value")), R.ascend(R.prop("display_name"))])(list || []);
  }, [list]);

  const dayToShow = useMemo(() => day - daysInWeek * (week - 1), [day, week, daysInWeek]);
  const headerText = useMemo(
    () => (!mealName ? intl.formatMessage(menuMessages.day, { day: dayToShow }) : mealName),
    [intl, dayToShow, mealName],
  );

  const clickOnDay = useCallback(() => {
    if (!hasChangePermission) return null;
    setWeek(week);
    setRelativeDay(dayToShow);
    setAbsoluteDay(day);
  }, [day, week, setWeek, setAbsoluteDay, setRelativeDay, dayToShow, hasChangePermission]);

  const calcColor = useCallback((price, is_priority) => {
    return price === 0
      ? colors["categorical-corporate-08"].hex
      : is_priority
      ? colors["support-02"].hex
      : colors["text-01"].hex;
  }, []);

  return (
    <ContainerDay
      xs
      maxWidth={maxWidth}
      onMouseEnter={activeHover}
      onMouseLeave={disactiveHover}
      onClick={clickOnDay}
      customFlex={customFlex}
      noPermissionCursor={!hasChangePermission}
    >
      <div style={{ height: "100%" }} ref={$containerDay}>
        {hover && hasChangePermission && <Placeholder height={height} />}
        <Header fontSize="16px" bold value={headerText} />
        {orderedList.map(({ display_name, quantity, price, is_priority }, index) => (
          <RowStyled key={`row-${display_name}`} center="xs" padding={`${!index ? "5px" : "0"} 10px 0`}>
            <ColStyled xs={calcXsItem} className="start-xs" padding="0">
              <Text value={display_name.split(";")[0]} defaultColor={() => calcColor(price, is_priority)} />
            </ColStyled>
            <ColStyled xs={daysInWeek === 1 ? daysInWeek : calcXsGrams} className="end-xs">
              <Text value={`${quantity.toFixed(1)} g`} />
            </ColStyled>
          </RowStyled>
        ))}
      </div>
    </ContainerDay>
  );
}

export default injectIntl(DayBox);
