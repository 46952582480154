import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { NumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import { validateMinAndMax, minGreaterMax } from "utils/errors";
import { FIELDS, FOOD_RESTRICTION_PATH, GROUP_RESTRICTION_PATH } from "./constants";

const PortionSize = ({ kindRestriction }) => {
  const { control } = useFormContext();

  const fieldName = useMemo(
    () =>
      kindRestriction === "item"
        ? `${FOOD_RESTRICTION_PATH}.${FIELDS.AMOUNT_RANGE}`
        : `${GROUP_RESTRICTION_PATH}.${FIELDS.AMOUNT_RANGE}`,
    [kindRestriction]
  );

  const portionSize = useWatch({
    control,
    name: fieldName,
  });

  const invalid = useMemo(() => validateMinAndMax(portionSize), [portionSize]);

  return (
    <>
      <Text bold wrap marginBottom="10px" value={<FormattedMessage {...messages.commonPortionSize} />} />

      <RowStyled>
        <ColStyled>
          <NumberInputHookForm
            control={control}
            name={`${fieldName}[0]`}
            helperText={<FormattedMessage {...messages.min} />}
            step={1}
          />
        </ColStyled>

        <ColStyled>
          <NumberInputHookForm
            control={control}
            name={`${fieldName}[1]`}
            helperText={<FormattedMessage {...messages.max} />}
            step={1}
          />
        </ColStyled>
      </RowStyled>
      {invalid && <Text marginTop="5px" defaultColor={colors["support-01"].hex} value={minGreaterMax} />}
    </>
  );
};

export default PortionSize;
