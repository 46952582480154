import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { NumberInputHookForm } from "components/Form";
import { Text, Checkbox } from "components";
import { useFormContext, useWatch } from "react-hook-form";
import { RowStyled, ColStyled } from "components/utils";
import { colors } from "@wfp/ui";
import { FIELDS } from "./contants";
import { FIELDS as RULES_FIELDS } from "containers/OptimizedMenu/Rules/constants";
import messages from "../messages";

export default function MaxWeight() {
  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const maxWeight = useWatch({ control, name: `${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MAX_WEIGHT}` });

  const [isChecked, setChecked] = useState(!!maxWeight || maxWeight === 0);
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());

  // For auto-checking the checkbox if there is a value for max weight
  useEffect(() => {
    if ((maxWeight || maxWeight === 0) && isChecked === false) {
      setChecked(true);
    }
  }, [maxWeight, isChecked]);
  const onChangeCheckbox = useCallback(
    (_, check) => {
      const maxWeightDefaultValue = defaultValues[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MAX_WEIGHT];
      if (!check && (maxWeight || maxWeight === 0 || maxWeightDefaultValue)) {
        // When unchecking the box, set the value of the max weight field to the defaultValue if there isn't
        // one, or undefined if there is one. We do this because even though the defaultValue should be "",
        // we set it to undefined so that so that the disabled input field shows the old default value
        // (this will only happen if the menu was already saved in the backend, i.e. there is a defaultValue for maxWeight)
        setValue(
          `${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MAX_WEIGHT}`,
          maxWeightDefaultValue || maxWeightDefaultValue === 0 ? undefined : maxWeightDefaultValue
        );
        setRefreshKey(new Date().valueOf());
      } else if (check && maxWeightDefaultValue) {
        // On check, set the value of the max weight back to its original value, but only if there is one
        // (again, it can only happen if the menu was already saved in the backend so that the form has
        // some values for defaultValues)
        setValue(`${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MAX_WEIGHT}`, maxWeightDefaultValue);
      }
      setChecked(check);
    },
    [defaultValues, maxWeight, setValue]
  );

  return (
    <>
      <ColStyled margin="20px 0 0">
        <Text bold fontSize="16px" value={<FormattedMessage {...messages.maxWeightTitle} />} />
        <Text defaultColor={colors["text-02"].hex} value={<FormattedMessage {...messages.maxWeightInfo} />} />
      </ColStyled>
      <RowStyled bottom="xs" key={refreshKey}>
        <ColStyled xs={5} md={6} padding="0">
          <Checkbox
            id="checkbox-max-weight"
            checked={isChecked}
            noMarginBottom
            labelText={<FormattedMessage {...messages.maxWeightCheckbox} />}
            onChange={onChangeCheckbox}
          />
        </ColStyled>
        <ColStyled xs={2}>
          <NumberInputHookForm
            className={!isChecked ? "disable" : ""}
            control={control}
            name={`${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MAX_WEIGHT}`}
            step={1}
            min={0}
            allowZero
            allowEmpty
            defaultValue={""}
          />
        </ColStyled>
      </RowStyled>
    </>
  );
}
