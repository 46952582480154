import { useCallback } from "react";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";

const Actions = ({ source, removeSource }) => {
  const onRemove = useCallback(() => removeSource(source), [removeSource, source]);

  return (
    <Icon
      className="pointer"
      description="Delete"
      icon={iconDelete}
      fill={colors["categorical-corporate-07"].hex}
      onClick={onRemove}
    />
  );
};

export default Actions;
