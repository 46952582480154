import { defineMessages } from "react-intl";

export default defineMessages({
  summaryWholeMenu: {
    id: "diversity.summaryWholeMenu",
    defaultMessage: "Summary: Whole menu",
  },
  summaryPerMeal: {
    id: "diversity.summaryPerMeal",
    defaultMessage: "Summary: Per meal",
  },
  day: {
    id: "summaryWholeMenu.day",
    defaultMessage: "day",
  },
  week: {
    id: "summaryWholeMenu.week",
    defaultMessage: "week",
  },
  shortWeek: {
    id: "menu.shortWeek",
    defaultMessage: "W {week}",
  },
  exception: {
    id: "summaryWholeMenu.exception",
    defaultMessage: "Exception",
  },
  recipeWith: {
    id: "summaryWholeMenu.recipeWith",
    defaultMessage: "will always appear with {items}",
  },
  unmatchedWith: {
    id: "summaryWholeMenu.unmatchedWith",
    defaultMessage: "will never appear with {items}",
  },
  rangePortionsPer: {
    id: "summaryWholeMenu.rangePortionsPer",
    defaultMessage: "{first}-{second} portions/{key}",
  },
  atLeastPortionsPer: {
    id: "summaryWholeMenu.atLeastPortionsPer",
    defaultMessage: "at least {first} portions/{key}",
  },
  maxRepetition: {
    id: "summaryWholeMenu.maxRepetition",
    defaultMessage: "max {maxRepetition} repetition of items",
  },
  rangePortionSize: {
    id: "summaryWholeMenu.rangePortionSize",
    defaultMessage: "portion size: {minPortion}-{maxPortion} G",
  },
  minPortionSize: {
    id: "summaryWholeMenu.minPortionSize",
    defaultMessage: "min portion size: {minPortion} G",
  },
  maxPortionSize: {
    id: "summaryWholeMenu.maxPortionSize",
    defaultMessage: "max portion size: {maxPortion} G",
  },
  rangeMeals: {
    id: "summaryWholeMenu.rangeMeals",
    defaultMessage: "{value} meals/menu",
  },
  addedRules: {
    id: "summaryWholeMenu.addedRules",
    defaultMessage: "Rules added",
  },
  rulesSummary: {
    id: "summaryWholeMenu.rulesSummary",
    defaultMessage: "Rules summary",
  },
  maxRepetitionValidation: {
    id: "diversity.maxRepetitionValidation",
    defaultMessage: "Maximum repetition of {group} is set to {value}.",
  },
  checkTheBoxRepetition: {
    id: "diversity.checkTheBoxRepetition",
    defaultMessage: "Check the box, if you want to exclude this food item from other restrictions.",
  },
  itemBelongsToGroupExcluded: {
    id: "diversity.itemBelongsToGroupExcluded",
    defaultMessage:
      "You excluded from the menu the food group/subgroup to which this item belongs, but since you want to add this item to the basket, PLUS will include it in the menu as an exception.",
  },
  invalidDailyRepetition: {
    id: "diversity.invalidDailyRepetition",
    defaultMessage: "Invalid daily repetition of {group}",
  },
  infoRepetitionValidation: {
    id: "diversity.infoRepetitionValidation",
    defaultMessage:
      "The value that you originally set is incompatible with the new restrictions you created: It must be in the range [{value}].",
  },
  invalidWeeklyRepetition: {
    id: "diversity.invalidWeeklyRepetition",
    defaultMessage: "Invalid weekly repetition of {group}",
  },
  itemRepetition: {
    id: "diversity.itemRepetition",
    defaultMessage: "How many meals must include this food item as an ingredient?",
  },
  itemRepetitionValuesTooLow: {
    id: "diversity.itemRepetitionValuesTooLow",
    defaultMessage: "Item repetition values must not be lower than 1",
  },
  invalidItemRepetition: {
    id: "diversity.invalidItemRepetition",
    defaultMessage: "Item repetition values must match the information from the included and excluded days",
  },
});
