import { useRef, useCallback, useMemo } from "react";
import Text from "components/Text";
import AsyncSelect from "react-select/async";
import { useEffect } from "react";
import { colors } from "@wfp/ui";

export default function Autocomplete({
  options,
  placeholder,
  input,
  labelText,
  fontSizeLabel,
  loadData,
  className,
  height,
  customOnChange,
  isClearable = false,
}) {
  const $options = useRef([]);
  $options.current = options;
  const $resolve = useRef(null);

  useEffect(() => {
    if ($resolve.current) $resolve.current(options);
  }, [options]);

  const promiseOptions = useCallback(
    (inputValue) => {
      loadData(inputValue);
      return new Promise((resolve) => {
        $resolve.current = resolve;
      });
    },
    [loadData],
  );

  const defaultValue = useMemo(() => (options ? options.find((el) => el.value === input.value) : ""), [input, options]);

  const onChangeField = useCallback(
    (option) => {
      if (customOnChange) {
        return customOnChange(option);
      }

      input.onChange(option.value);
    },
    [customOnChange, input],
  );

  const fixedHeight = useMemo(() => (height ? { height, minHeight: height } : {}), [height]);

  const styles = useMemo(() => {
    return {
      menu: (styles) => ({ ...styles, zIndex: 9999, ...fixedHeight }),
      menuList: (styles) => ({ ...styles, ...fixedHeight }),
      // Selected option
      singleValue: (styles, { data }) => ({
        ...styles,
        color: data.is_priority ? colors["support-02"].hex : colors["text-01"].hex,
      }),
      // All available options
      option: (styles, { data }) => ({
        ...styles,
        color: data.is_priority ? colors["support-02"].hex : colors["text-01"].hex,
      }),
    };
  }, [fixedHeight]);

  return (
    <>
      {labelText && <Text marginBottom="5px" bold fontSize={fontSizeLabel} value={labelText} />}
      <AsyncSelect
        key={defaultValue ? defaultValue.value : "empty"}
        className={`wfp--react-select-container ${className || ""}`}
        classNamePrefix="wfp--react-select"
        defaultOptions={options}
        styles={styles}
        loadOptions={promiseOptions}
        placeholder={placeholder || "Type to search..."}
        value={defaultValue}
        onChange={onChangeField}
        isClearable={isClearable}
      />
    </>
  );
}
