import * as R from "ramda";
import {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useMediaQuery } from "react-responsive";
import { getCookieConsentValue } from "react-cookie-consent";

import { checkPermission } from "components/Unlock/utils";
import {
  selectCurrency,
  selectPriceSources,
  selectAccountId,
  selectPermissions,
} from "containers/App/selectors";
import { selectMealCount } from "containers/Menu/selectors";
import {
  selectNutrients,
  selectNutRestrictions,
  selectNutRequirements,
} from "containers/Results/selectors";

export const useResponsiveHook = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  return {
    isTabletOrMobile,
  };
};

export const useHtmlHook = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isRTL = useMemo(
    () => document.documentElement.dir === "rtl",
    []
  );

  return { isRTL };
};

// If we switch to arabic we could encounter some texts in latin language. This hook checks that out
export const useArabicCheck = (string: string) => {
  const { isRTL } = useHtmlHook();

  const arabicRegex = /[\u0600-\u06FF]/;

  if (isRTL) {
    return arabicRegex.test(string);
  }
};

export const useBoolean = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, { toggle, setTrue, setFalse }];
};

// Currencies are used in few components under the FoodBasket domain
export const useCurrencies = () => {
  const currencyInfo = useSelector(selectCurrency);

  return useMemo(() => {
    if (!currencyInfo) return [];

    const { to_currency, from_currency } = currencyInfo;
    return [
      { value: to_currency, label: to_currency },
      { value: from_currency, label: from_currency },
    ];
  }, [currencyInfo]);
};

export const usePriceSourcesCheckboxes = () => {
  const priceSources: { checkboxes: HTMLInputElement }[] =
    useSelector(selectPriceSources);

  const checkboxes = R.flatten(priceSources.map((el) => el.checkboxes));
  const checkboxesValues = checkboxes.map((el) => el.value);

  return useMemo(
    () =>
      checkboxes.filter(
        ({ value }, i) => !checkboxesValues.includes(value, i + 1)
      ),
    [checkboxes, checkboxesValues]
  );
};

type Item = {
  food_item: number;
  display_name: string;
  edible_portion_size_scaling_factor: number;
  quantity: number;
};

type UseGraphValuesProps = {
  singleDay: boolean;
  items: Item[];
};

type NutrientRestriction = {
  nutrient: string;
  nutrient_display: string;
  percentage: number;
};

type NutritionalRequirement = {
  nutrient: string;
  nutrient_display: string;
  category: string;
  unit: string;
  value_range: [ number, number | null ];
};

// Graph values are used in few components under the Results domain
export const useGraphValues = ({
  items,
  singleDay = true,
}: UseGraphValuesProps) => {
  
  const nutrients = useSelector(selectNutrients) as Record<
    number,
    { nutrient: string; value: string, unit: string }[]
  > | null;

  const nutrientRestrictions: NutrientRestriction[] = useSelector(
    selectNutRestrictions
  );

  const nutritionalRequirements: NutritionalRequirement[] = useSelector(
    selectNutRequirements
  );

  const mealCount = useSelector(selectMealCount);

  if (
    R.isEmpty(items) ||
    R.isNil(nutrients) ||
    R.isEmpty(nutritionalRequirements)
  ) {
    return [];
  }

  // 'calsium' -> 30
  const nutrientToPercentage: Record<string, number> = {};

  for (let nutrientRestriction of nutrientRestrictions) {
    nutrientToPercentage[nutrientRestriction.nutrient] =
      nutrientRestriction.percentage || 0;
  }

  const nutrientTotal: Record<string, number> = {};
  // Set default values. Go through all nutritional requirements.
  for (let nutritionalRequirement of nutritionalRequirements) {
    nutrientTotal[nutritionalRequirement.nutrient] = 0
  }

  for (let item of items) {
    const { food_item, edible_portion_size_scaling_factor, quantity } = item;
    const foodItemNutritions = nutrients?.[food_item];
    for (let foodItemNutrition of foodItemNutritions) {
      const currentNutrient = foodItemNutrition.nutrient;
      if (nutrientTotal[currentNutrient] !== undefined) {
        nutrientTotal[currentNutrient] += Number(foodItemNutrition?.value || 0) * edible_portion_size_scaling_factor * (quantity / 100);
      }
    }
  }

  return nutritionalRequirements.map(
    ({
      nutrient,
      nutrient_display,
      category,
      unit,
      value_range: [nutrientRequirement],
    }) => ({
      label: nutrient_display,
      value: nutrientTotal[nutrient].toFixed(2),
      unit,
      category,
      restriction: nutrientToPercentage[nutrient] || 0,
      percentage: Math.round(
        (items.reduce(
          (
            acc,
            { food_item, edible_portion_size_scaling_factor: edible, quantity }
          ) => {
            const findNutrient = nutrients?.[food_item]?.find(
              (el) => el.nutrient === nutrient
            );
            return (
              acc +
              (findNutrient ? Number(findNutrient.value) : 0) *
                edible * (quantity / 100)
            );
          },
          0
        ) *
          100) /
          nutrientRequirement /
          (singleDay ? 1 : mealCount)
      ),
    })
  );
};

// Given a nutrient percentage, calc how each food items is partecipating on giving that nutrient its percentage value.
// Eg. "Energy" is 75% of nutrient requirement. Returns which food items are partecipating and relative percentages.
export const useNutrientValues = ({
  items,
  singleDay = true,
}: UseGraphValuesProps) => {

  const nutrients = useSelector(selectNutrients) as Record<
    number,
    { nutrient: string; unit: string; value: string }[]
  > | null;

  const nutritionalRequirements: {
    nutrient: string;
    nutrient_display: string;
    category: string;
    unit: string;
    value_range: [number, number | null];
  }[] = useSelector(selectNutRequirements);

  const mealCount = useSelector(selectMealCount);

  if (
    R.isEmpty(items) ||
    R.isNil(nutrients) ||
    R.isEmpty(nutritionalRequirements)
  )
    return [];

  const groupedItems = R.groupBy((item) => item.display_name, items);

  const itemsInfo: Record<
    string,
    { food_item: number; quantity: number; edible: number }
  > = Object.keys(groupedItems).reduce(
    (acc, group) => ({
      ...acc,
      [group]: {
        food_item: groupedItems[group]?.[0]["food_item"],
        quantity: groupedItems[group]?.reduce(
          (acc, el) => acc + el.quantity,
          0
        ),
        edible: groupedItems[group]?.[0]["edible_portion_size_scaling_factor"],
      },
    }),
    {}
  );

  return nutritionalRequirements.map(
    ({ nutrient, value_range: [nutrientRequirement] }) => {
      const values = Object.keys(itemsInfo).map((item) => {
        const { food_item, quantity, edible } = itemsInfo[item];
        const nutrientObject = nutrients?.[food_item]?.find(
          (el) => el.nutrient === nutrient
        );
        // Get average percentage of nutrient we are iterating.
        const averageNutrientContribution =
          ((nutrientObject ? Number(nutrientObject.value) : 0) *
            edible *
            (quantity / 100) *
            100) /
          nutrientRequirement /
          (singleDay ? 1 : mealCount);

        // Get total average percentage of nutrient we are iterating.
        const totalAveragePercentage =
          (items.reduce(
            (
              acc,
              {
                food_item,
                edible_portion_size_scaling_factor: edible,
                quantity,
              }
            ) => {
              const nutrientObject = nutrients?.[food_item]?.find(
                (el) => el.nutrient === nutrient
              );
              return (
                acc +
                  (nutrientObject ? Number(nutrientObject.value) : 0) *
                    edible *
                    (quantity / 100)
              );
            },
            0
          ) *
            100) /
            nutrientRequirement /
            (singleDay ? 1 : mealCount);

        // Get percentage current nutrient is being covered. Prevent dividing by 0.
        const percentage = Math.round(
          (100 * averageNutrientContribution) /
            (totalAveragePercentage === 0 ? 1 : totalAveragePercentage)
        );

        const nutrientUnit = nutrientObject ? nutrientObject.unit : ""; 
        const nutrientValue = nutrientObject ? (Number(nutrientObject.value) * quantity * edible) : 0;

        return {
          display_name: item,
          nutrient,
          percentage,
          nutrientValue,
          nutrientUnit,
        };

      });
      return R.sortWith([R.descend(R.prop("nutrientValue"))], values);
    }
  );
};

export const useCookieConsent = () => {
  // Init cookieConsent value by reading CookieConsent from document.cookie.
  const [
    cookieConsent,
    { setTrue: acceptCookieConsent, setFalse: declineCookieConsent },
  ] = useBoolean(getCookieConsentValue() === "true") as [
    boolean,
    { setTrue: () => void; setFalse: () => void }
  ];

  return {
    cookieConsent,
    acceptCookieConsent,
    declineCookieConsent,
  };
};

export const useAnalytics = ({ cookieConsent }: { cookieConsent: boolean }) => {
  const location = useLocation();
  const accountId = useSelector(selectAccountId);

  // Init Google Analytics
  useEffect(() => {
    if (cookieConsent && process.env.NODE_ENV === "production") {
      ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID!);
    }
  }, [cookieConsent]);

  // Set User ID
  useEffect(() => {
    if (cookieConsent) {
      if (accountId) {
        ReactGA.set({ userId: accountId });
      }
    }
  }, [accountId, cookieConsent]);

  // Track location changes
  useEffect(() => {
    if (cookieConsent) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [cookieConsent, location.pathname, location.search]);
};

// TODO: Future: reuse this hook when we land in a different section and we need to check for user permissions. Substitute existing logic with this util.
export const useCheckUserPermissions = (
  permissionToBeChecked: string | undefined
) => {
  const userPermissions: string[] = useSelector(selectPermissions);
  const [redirectUser, setRedirectUser] = useState(false);

  useEffect(() => {
    if (!R.isEmpty(userPermissions)) {
      setRedirectUser(!checkPermission(userPermissions, permissionToBeChecked));
    }
  }, [permissionToBeChecked, userPermissions]);

  return useMemo(
    () => ({
      redirectUser,
      userPermissions,
    }),
    [redirectUser, userPermissions]
  );
};
