import React from "react";
import { useSelector } from "react-redux";
import { Loading } from "@wfp/ui";
import { selectCountryCode } from "containers/App/selectors";
import { isInTest } from "utils/testUtils";

export default (WrappedComponent) => (props) => {
  const countryCode = useSelector(selectCountryCode);

  if (isInTest) return <WrappedComponent {...props} />;
  if (!countryCode) return <Loading active />;

  return <WrappedComponent key={countryCode} {...props} />;
};
