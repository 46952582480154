import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSelectedNutrients, selectAllNutrients, selectMenuType } from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";

export const useInitialValues = () => {
  const selectedNutrients = useSelector(selectSelectedNutrients);
  const allNutrients = useSelector(selectAllNutrients);
  const countries = useSelector(selectCountries);
  const menuType = useSelector(selectMenuType);

  const isMissingData = useMemo(() => {
    return !selectedNutrients || !allNutrients || !countries || !menuType;
  }, [selectedNutrients, allNutrients, countries, menuType]);

  return useMemo(() => {
    if (isMissingData) return {};

    const nutrients = allNutrients.map((nutrient) => ({
      ...nutrient,
      checked: selectedNutrients.includes(nutrient.value),
    }));

    return { nutrients };
  }, [isMissingData, selectedNutrients, allNutrients]);
};
