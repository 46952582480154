import React, { useCallback, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import Pagination from "./Pagination";
import { ReactTableWrapper, Header, TrBody, NoResults } from "./styles";

const Table = ({
  manual,
  pointer,
  overflowId,
  columns,
  data,
  loadData,
  pageSize: controlledPageSize,
  pageNum,
  totalItems,
  onClickRow,
  loading,
  pageSizeOptions = [5, 10, 15],
  initialState = {},
  noBorderBottom,
  noHeaderBackground,
  border,
  margin,
  width,
  minHeight,
  noPageRange,
  noPagesText,
  selectedClass,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      manualPagination: manual,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (manual && loadData) {
      loadData(pageIndex + 1, pageSize, sortBy);
    }
  }, [manual, loadData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    setPageSize(controlledPageSize);
  }, [setPageSize, controlledPageSize]);

  // In order to prevent duplicate API calls, we check for different pageSize instead of blindly running setPageSize and loadData functions every time.
  const onChange = useCallback(
    (e) => {
      if (e.pageSize !== pageSize) {
        setPageSize(e.pageSize);
        !manual && gotoPage(0);
      } else {
        if (manual && loadData) {
          loadData(e.page, e.pageSize);
        } else {
          gotoPage(e.page - 1);
        }
      }
    },
    [pageSize, setPageSize, manual, loadData, gotoPage]
  );

  const paginationProps = {
    page: pageNum,
    onChange,
    pageSize,
    totalItems,
    noPageRange,
    noPagesText,
    pageSizeOptions,
  };

  return (
    <ReactTableWrapper className="ReactTable--wrapper" border={border} margin={margin} width={width}>
      <div className="ReactTable">
        <div {...getTableProps()} className="rt-table">
          <Header className="rt-thead -header" noBorderBottom={noBorderBottom} noHeaderBackground={noHeaderBackground}>
            {
              // Looping over the header rows
              headerGroups.map((headerGroup) => (
                // Applying the header row props
                <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {
                    // Looping over the headers in each row
                    headerGroup.headers.map((column) => (
                      <div
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={`rt-th rt-resizable-header -cursor-pointer ${
                          column.isSorted ? (column.isSortedDesc ? "-sort-desc" : "-sort-asc") : ""
                        }`}
                        style={{
                          ...column.getHeaderProps.style,
                          minWidth: column.width,
                          width: column.width,
                          flex: column.flex,
                        }}
                      >
                        <div className="rt-resizable-header-content">{column.render("Header")}</div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </Header>

          <div className="rt-tbody">
            {/* Applying the table body props */}
            <div {...getTableBodyProps()} className="rt-tr-group">
              {
                // Looping over the table rows
                page.length > 0 ? (
                  page.map((row) => {
                    // Preparing row for rendering
                    prepareRow(row);
                    return (
                      <TrBody
                        {...row.getRowProps()}
                        key={row.id}
                        className="rt-tr"
                        minHeight={minHeight}
                        noBorderBottom={noBorderBottom}
                      >
                        {
                          // Looping over the row cells
                          row.cells.map((cell) => {
                            return (
                              // Applying the cell props and rendering the cell content
                              <div
                                {...cell.getCellProps({
                                  style: {
                                    overflow: cell.column.id === overflowId ? "visible" : "hidden",
                                    cursor: pointer ? "pointer" : "auto",
                                    minWidth: cell.column.width,
                                    width: cell.column.width,
                                    flex: cell.column.flex,
                                  },
                                })}
                                className={`rt-td ${selectedClass && selectedClass(row)}`}
                                onClick={() => onClickRow && onClickRow(row, cell)}
                              >
                                {cell.render("Cell")}
                              </div>
                            );
                          })
                        }
                      </TrBody>
                    );
                  })
                ) : (
                  <NoResults>No Results</NoResults>
                )
              }
            </div>
          </div>
        </div>
        {/* Loading overlay */}
        <div className={`-loading ${loading ? "-active" : ""}`}>
          <div className="-loading-inner">Loading...</div>
        </div>
      </div>
      <Pagination {...paginationProps} />
    </ReactTableWrapper>
  );
};

export default Table;
