import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { colors, Tag } from "@wfp/ui";

import { Text } from "components";
import { daysAndWeeks, seasonality, regional, targeted } from "containers/Menu/utils";
import { INFEASIBLE } from "containers/MyMenus/labels";
import SentTo from "./SentTo";
import ReceivedFrom from "./ReceivedFrom";
import { SharedContainer, Container, Helper } from "./styles";
import { RowStyled, ColStyled, tagColorByStatus } from "components/utils";

const NameAndSettings = ({ data }) => {
  const intl = useIntl();

  const preliminaryInfo = R.pathOr("", ["preliminary_information"], data);
  const infeasible = R.pathOr("", ["optimization", "type"], data) === INFEASIBLE;
  const sharedWith = R.pathOr("", ["shared_with"], data);
  const sharedBy = R.pathOr("", ["shared_by"], data);
  const seasonalityMonths = useMemo(() => seasonality(preliminaryInfo, false, intl), [intl, preliminaryInfo]);
  const duration = useMemo(() => daysAndWeeks(preliminaryInfo, intl), [intl, preliminaryInfo]);
  const regions = useMemo(() => regional(preliminaryInfo, intl), [intl, preliminaryInfo]);
  const target = useMemo(() => targeted(preliminaryInfo), [preliminaryInfo]);

  const status = useMemo(() => {
    const menuType = R.pathOr("", ["optimization", "type"], data);
    const content = R.pathOr("", ["optimization", "type_translated"], data);
    const tagType = tagColorByStatus[menuType];

    return (
      <Tag type={tagType} style={{ marginLeft: "0.5rem" }}>
        {content}
      </Tag>
    );
  }, [data]);

  return (
    <Container className={infeasible ? "disable" : ""}>
      <RowStyled middle="xs" between="xs" flex={1}>
        <ColStyled xs>
          <Text
            bold
            wrap
            fontSize="20px"
            disable={infeasible}
            disableColor={colors["text-02"].hex}
            defaultColor={colors["brand-01"].hex}
            value={data.name}
          />
          <Helper>
            {duration} - {seasonalityMonths} | {regions} | {target}
          </Helper>
          <SharedContainer hasMarginTop={!R.isEmpty(sharedWith) || sharedBy}>
            {!R.isEmpty(sharedWith) && <SentTo sharedWith={sharedWith} />}
            {sharedBy && <ReceivedFrom sharedBy={sharedBy} />}
          </SharedContainer>
        </ColStyled>
        {status}
      </RowStyled>
    </Container>
  );
};

export default NameAndSettings;
