import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { RowStyled, ColStyled } from "components/utils";
import { Button, Text, Toggle, Unlock } from "components";
import messages from "components/Button/messages";
import { updateMenu } from "containers/Menu/actions";
import { Title, ContainerText } from "./styles";
import { useResponsiveHook, useHtmlHook } from "hooks";
import { Icon, colors } from "@wfp/ui";
import { iconEdit } from "@wfp/icons";
import TutorialLink from "containers/Tutorials/TutorialLink";
import { TUTORIAL_IDS } from "../../Tutorials/utils";
import { PERMISSIONS } from "containers/Admin/constants";

export default function RenameMenu({ name, menuId, menuName, setMenuName, toggleIndex, setToggleIndex }) {
  const dispatch = useDispatch();
  const { isRTL } = useHtmlHook();
  const { isTabletOrMobile } = useResponsiveHook();

  const [isEdit, setEditMode] = useState(false);

  const onRename = useCallback(async () => {
    if (name !== menuName) {
      dispatch(updateMenu({ id: menuId, body: { name: menuName } }));
    }
    setEditMode(false);
  }, [dispatch, menuId, menuName, name, setEditMode]);

  const handleChange = useCallback(
    (event) => {
      setMenuName(event.currentTarget.value);
    },
    [setMenuName],
  );

  const onClickEdit = useCallback(() => {
    setEditMode(true);
  }, []);

  return isEdit ? (
    <RowStyled center="xs" middle="xs" margin="0 5px">
      <ColStyled xs={10} margin="10px 0">
        <Title defaultValue={menuName || ""} fontSize={isTabletOrMobile ? "25px" : null} onChange={handleChange} />
      </ColStyled>
      <ColStyled xs={2} className="center-xs">
        <Button disabled={!menuName} kind="secondary" onClick={onRename}>
          <FormattedMessage {...messages.rename} />
        </Button>
      </ColStyled>
    </RowStyled>
  ) : (
    <RowStyled middle="xs" between="xs" margin="5px">
      <ColStyled xs className="start-xs">
        <ContainerText>
          <Text wrap bold fontSize={isTabletOrMobile ? "25px" : "28px"} value={menuName || ""} />
          <Unlock permission={PERMISSIONS.CHANGE_COMMUNITY_MENU}>
            <Icon
              className={isRTL ? "marginRightSmall" : "marginLeftSmall"}
              onClick={onClickEdit}
              icon={iconEdit}
              description="edit"
              fill={colors["brand-01"].hex}
              width="25"
              height="25"
            />
          </Unlock>
          <ColStyled margin={isRTL ? "10px 10px 0 0" : "10px 0 0 10px"}>
            <TutorialLink kind={TUTORIAL_IDS.RESULTS} />
          </ColStyled>
        </ContainerText>
      </ColStyled>
      <Toggle activeIndex={toggleIndex} setActiveIndex={setToggleIndex} />
    </RowStyled>
  );
}
