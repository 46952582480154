import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadSuccess,
  changeMealNames,
  copyCommunityMenuAsOptimizedKo,
  resetMenu,
  resetDuplicationData,
} from "./actions";

export const initialState = {
  loading: false,
  data: null,
  duplication: null,
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadSuccess.type]: (state, action) => {
    state.data = action.payload.data;
  },
  [changeMealNames.type]: (state, action) => {
    state.data.meal_names = { ...state.data.meal_names, ...action.payload.body };
  },
  [copyCommunityMenuAsOptimizedKo.type]: (state, action) => {
    state.duplication = action.payload;
  },

  [resetDuplicationData.type]: (state) => {
    state.duplication = initialState.duplication;
  },
  [resetMenu.type]: () => initialState,
});
