import { useCallback } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Link } from "@wfp/ui";

import { selectTutorials } from "./selectors";
import messages from "./messages";
import { setTutorial } from "./actions";

const TutorialLink = ({ kind }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const tutorial = R.pathOr("", [kind], useSelector(selectTutorials));

  const openTutorial = useCallback(() => setTimeout(() => dispatch(setTutorial({ kind })), 0), [dispatch, kind]);

  return tutorial && <Link inline onClick={openTutorial} children={intl.formatMessage(messages.needHelp)} />;
};

export default TutorialLink;
