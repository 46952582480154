import * as R from "ramda";

import { FIELDS } from "containers/GeneralInfo/constants";

// Returns "true" if button will be disabled
export const validation = (item) => {
  if (!item[FIELDS.PRICE]) {
    return true;
  }

  if (R.isEmpty(item[FIELDS.SOURCE]) || R.isEmpty(item[FIELDS.ITEM])) {
    return true;
  }

  return false;
};
