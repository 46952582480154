import { useMemo, useCallback } from "react";
import { useController } from "react-hook-form";
import { colors, Icon, Input } from "@wfp/ui";
import { iconCaretDown } from "@wfp/icons";

import MenuList from "../SelectHookForm/MenuList";
import { UISelect } from "../SelectHookForm/styles";

/**
 * Select wrapper that handles react-hook-form's handleSubmit() validation
 *
 * TODO: In the future, replace all the existing SelectHookForm
 * components that need a submit validation, with this component
 */
const ValidatedSelectHookForm = ({
  control,
  name,
  label,
  helperText,
  options = [],
  wfpStyle,
  className = "",
  customOnChange,
  virtualized = false,
  rules = {},
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  const selectedValue = useMemo(() => options.find((el) => el.value === field.value) ?? "", [field.value, options]);

  const components = useMemo(
    () =>
      wfpStyle
        ? {
            DropdownIndicator: () => <Icon fill={colors["brand-01"].hex} icon={iconCaretDown} description="" />,
            IndicatorSeparator: () => null,
          }
        : virtualized
        ? { MenuList }
        : {},
    [virtualized, wfpStyle]
  );

  const handleChange = useCallback(
    (el) => {
      if (customOnChange) {
        customOnChange(el, field);
      } else {
        field.onChange(el.value);
      }
    },
    [customOnChange, field]
  );

  return (
    <div className="wfp--form-item">
      {label && (
        <label htmlFor={name} className="wfp--label">
          {label}
        </label>
      )}
      {helperText && <div className="wfp--form__helper-text">{helperText}</div>}
      <Input invalid={error} invalidText={error && error.message}>
        {() => (
          <UISelect
            {...rest}
            id={name}
            onChange={handleChange}
            wfpStyle={wfpStyle}
            components={components}
            className={`wfp--react-select-container ${className}`}
            classNamePrefix="wfp--react-select"
            value={selectedValue}
            options={options}
            styles={{
              control: (base) => ({ ...base, borderColor: error && `${colors["support-01"].hex} !important` }),
            }}
          />
        )}
      </Input>
    </div>
  );
};

export default ValidatedSelectHookForm;
