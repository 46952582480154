import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { loadAdminRecipes } from "containers/Admin/actions";

import { allRecipesFields } from "./constants";

import FiltersTemplate from "containers/Admin/Countries/Filters";

const Filters = () => {
  const intl = useIntl();
  const filterFields = useMemo(() => allRecipesFields(intl), [intl]);

  return <FiltersTemplate fields={filterFields} actionFunction={loadAdminRecipes} />;
};

export default Filters;
