import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { RowStyled, ColStyled } from "components/utils";
import { Text, Button, Select } from "components";
import { FormattedMessage } from "react-intl";
import buttonMessages from "components/Button/messages";
import messages from "./messages";
import { Container } from "./styles";
import { selectCountries } from "./selectors";

export default function Choice({ onClickContinue, setCountry, country }) {
  const countries = useSelector(selectCountries);
  return (
    <Container key={`country-${R.pathOr("", ["value"], country)}`}>
      <RowStyled center="xs">
        <ColStyled xs={6}>
          <Text fontSize="28px" value={<FormattedMessage {...messages.choiceTitle} />} />
          <Text wrap fontSize="16px" marginTop="10px" value={<FormattedMessage {...messages.choiceDescription} />} />
        </ColStyled>
      </RowStyled>
      <RowStyled center="xs">
        <ColStyled xs={4}>
          <Select
            defaultValue={R.pathOr("", ["value"], country)}
            marginTop="20px"
            options={countries}
            onChange={setCountry}
          />
          <Button disabled={!country} onClick={onClickContinue} marginTop="20px">
            <FormattedMessage {...buttonMessages.continue} />
          </Button>
        </ColStyled>
      </RowStyled>
    </Container>
  );
}
