import { useMemo } from "react";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { SourcesGraph } from "components/Charts";
import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/Results/messages";
import { SourcingChannelsContainer, GraphForPDF } from "./styles";
import { FIELDS } from "../constants";
import { getAverageSources } from "../utils";

const SourcingChannels = ({ menuComposition }) => {
  const intl = useIntl();

  const totalCost = useMemo(
    () => menuComposition.reduce((acc, item) => acc + item[FIELDS.PRICE], 0),
    [menuComposition]
  );

  const averageSources = useMemo(() => getAverageSources(totalCost, menuComposition), [menuComposition, totalCost]);

  return (
    <SourcingChannelsContainer>
      <RowStyled center="xs" middle="xs" padding="20px 0">
        <ColStyled width="100%">
          <Text
            bold
            regularWrap
            defaultColor={colors["text-02"].hex}
            value={intl.formatMessage(messages.sourcingChannels)}
          />
          <SourcesGraph menus={averageSources} showLabelOnGraph={false} />
        </ColStyled>
      </RowStyled>

      <GraphForPDF className="visuallyHidden">
        <SourcesGraph id="sourcing-channels" menus={averageSources} noEllipsis />
      </GraphForPDF>
    </SourcingChannelsContainer>
  );
};

export default SourcingChannels;
