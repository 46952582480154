import messages from "./messages";

export const weekOptions = (daysInWeek, intl) => [
  {
    value: 1,
    label: intl.formatMessage(messages.weekOne, { number: daysInWeek * 1 }),
  },
  {
    value: 2,
    label: intl.formatMessage(messages.weekTwo, { number: daysInWeek * 2 }),
  },
  {
    value: 3,
    label: intl.formatMessage(messages.weekThree, { number: daysInWeek * 3 }),
  },
  {
    value: 4,
    label: intl.formatMessage(messages.weekFour, { number: daysInWeek * 4 }),
  },
];

export const periodOptions = (intl) => [
  {
    value: "y",
    label: intl.formatMessage(messages.yearAround),
  },
  {
    value: "m",
    label: intl.formatMessage(messages.months),
  },
];
