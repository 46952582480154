import React from "react";

import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
import { FirstSectionContainer } from "./styles";

const FirstSection = () => {
  return (
    <FirstSectionContainer>
      <Header/>
      <Content/>
      <Footer/>
    </FirstSectionContainer>
  );
};

export default FirstSection;
