import pkceChallenge from "pkce-challenge";

import { Layout } from "components";

const CiamLogin = () => {
  const { code_verifier, code_challenge } = pkceChallenge();
  localStorage.setItem("code_verifier", code_verifier);
  const ciamLoginUrl =
    `${process.env.CIAM_AUTHENTICATION_URL}` +
    `/authorize?client_id=${process.env.CIAM_CLIENT_ID}` +
    `&redirect_uri=${process.env.CIAM_CALLBACK_URL}&scope=openid&response_type=code` +
    `&redirect_homepage=${process.env.BASE_URL}` +
    `&code_challenge_method=S256&code_challenge=${code_challenge}`;

  window.open(ciamLoginUrl, "_self");
  localStorage.setItem("mustSelectCountry", true);
  return <Layout />;
};

export default CiamLogin;
