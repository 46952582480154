import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

import { SelectHookForm, NumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { selectWeightChoices, selectFoodSources } from "containers/App/selectors";
import foodBasketMessages from "containers/FoodBasket/messages";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useCurrencies } from "hooks";
import { AddToFoodBasketContainer } from "./styles";

const AddToFoodBasket = () => {
  const intl = useIntl();
  const { control } = useFormContext();

  const units = useSelector(selectWeightChoices);
  const procurementSources = useSelector(selectFoodSources);
  const currencies = useCurrencies();

  return (
    <ColStyled xs={8}>
      <AddToFoodBasketContainer>
        <RowStyled>
          <ColStyled xs={4}>
            <NumberInputHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.PRICE}`}
              labelText={intl.formatMessage(foodBasketMessages.foodPrice)}
            />
          </ColStyled>
          <ColStyled xs={4}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.CURRENCY}`}
              options={currencies}
              label={intl.formatMessage(foodBasketMessages.currency)}
            />
          </ColStyled>
          <ColStyled xs={4}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.UNIT}`}
              options={units}
              label={intl.formatMessage(foodBasketMessages.unit)}
            />
          </ColStyled>
        </RowStyled>

        <RowStyled margin="1.5rem 0 0 0">
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}.${FIELDS.SOURCE}`}
              options={procurementSources}
              label={intl.formatMessage(foodBasketMessages.procurementSource)}
            />
          </ColStyled>
        </RowStyled>
      </AddToFoodBasketContainer>
    </ColStyled>
  );
};

export default AddToFoodBasket;
