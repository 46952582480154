import { PRICE_STATUS } from "./Filters/constants";

const handleAdditionalCosts = (additional_costs) => {
  // "id" will be replaced by useFieldArray, so we need to save it in a different property
  return additional_costs.map(({ id, percentage, price, price_currency, type }) => {
    if (percentage) {
      return {
        _id: id,
        price: Number(percentage),
        quantity: "%",
        type,
      };
    } else {
      return {
        _id: id,
        price: Number(price),
        quantity: price_currency,
        type,
      };
    }
  });
};

export const reworkFoodPrices = (foodPrices) => {
  // "id" will be replaced by useFieldArray, so we need to save it in a different property
  return foodPrices.map(
    ({
      id,
      item,
      source,
      price,
      additional_costs,
      price_currency,
      creator_details,
      region,
      month,
      year,
      origin,
      is_staple,
      price_status,
      quantity,
    }) => ({
      _id: id,
      item,
      source,
      price,
      additional_costs: handleAdditionalCosts(additional_costs),
      price_currency,
      username: creator_details?.username ?? "",
      region: region.id,
      month,
      year,
      origin,
      is_staple,
      price_status,
      quantity,
    })
  );
};

// Before sending a food price to the BE, we need to rework additional costs structure
export const reworkAdditionalCosts = (additionalCosts) => {
  return additionalCosts.map(({ price, quantity, type }) => {
    const cost = { type, unit: quantity, value: price };
    const isPercentage = quantity === "%";

    if (isPercentage) {
      return {
        ...cost,
        percentage: price,
        price: null,
        price_currency: null,
      };
    } else {
      return {
        ...cost,
        percentage: null,
        price,
        price_currency: quantity,
      };
    }
  });
};

// Rework food prices FE structure before sending these to BE
export const feToBeReworkFoodPrice = ({ _id, ...foodPrice }) => {
  return {
    ...foodPrice,
    additional_costs: reworkAdditionalCosts(foodPrice.additional_costs),
    id: _id,
  };
};

export const reworkFoodPricesParamsAndFilters = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    // Do not include the filter if user selected "All" option.
    if (value === "") return { ...acc };
    return { ...acc, [key]: data[key] };
  }, {});
};

// Mock columns for table: columns are mandatory for react-table, this is to make things work when we don't need them.
export const mockColumns = [
  {
    accessor: "mockColumn",
  },
];

// Users won't be able to edit a price whose status is official or rejected.
export const canEditFoodPrice = (status) => ![PRICE_STATUS.REJECTED, PRICE_STATUS.OFFICIAL].includes(status);
