import React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useFieldArray } from "react-hook-form";

import { ColStyled } from "components/utils";
import {
  approveFoodPriceRequest,
  rejectFoodPriceRequest,
  approveAllFoodPricesRequest,
  loadFoodPricesRequest,
  resetTableParams,
  resetTableFilters,
} from "containers/Admin/actions";
import {
  selectLoadingCountriesSlice,
  selectTableParams,
  selectFoodPricesCount,
  selectShowApproveAllButton,
} from "containers/Admin/selectors";
import { FIELDS } from "./constants";
import CustomTable from "./CustomTable";
import ApproveAll from "./ApproveAll";
import Filters from "./Filters";
import Price from "./Price";
import { mockColumns } from "./utils";

const Prices = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoadingCountriesSlice);
  const foodPricesCount = useSelector(selectFoodPricesCount);
  const showApproveAllButton = useSelector(selectShowApproveAllButton);
  const { page: pageNum, page_size: pageSize } = useSelector(selectTableParams) as {page: number, page_size: number};

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: FIELDS.FOOD_PRICES,
  });

  useEffect(() => {
    return () => {
      dispatch(resetTableFilters());
      dispatch(resetTableParams());
    };
  }, [dispatch]);

  const loadData = useCallback(
    (page: number, pageSize: number) => {
      const params = { page, page_size: pageSize };
      dispatch(loadFoodPricesRequest({ params }));
    },
    [dispatch]
  );

  const approveFoodPrice = useCallback(
    (foodPrice: any) => {
      dispatch(approveFoodPriceRequest({ foodPrice }));
    },
    [dispatch]
  );

  const rejectFoodPrice = useCallback(
    (foodPriceId: any) => {
      dispatch(rejectFoodPriceRequest({ foodPriceId }));
    },
    [dispatch]
  );

  const approveAllFoodPrices = useCallback(() => {
    dispatch(approveAllFoodPricesRequest({ foodPrices: fields }));
  }, [dispatch, fields]);

  const customRowsRender = useMemo(() => {
    return fields.map((field, i) => (
      <Price key={field.id} index={i} approve={approveFoodPrice} reject={rejectFoodPrice} />
    ));
  }, [approveFoodPrice, fields, rejectFoodPrice]);

  const tableProps = useMemo(
    () => ({
      manual: true,
      columns: mockColumns,
      data: fields,
      _page: pageNum,
      pageSize,
      totalItems: foodPricesCount,
      loadData,
      loading,
      pageSizeOptions: [10, 30, 40],
      initialState: { pageSize: 10 },
      customRowsRender,
    }),
    [customRowsRender, fields, foodPricesCount, loadData, loading, pageNum, pageSize]
  );

  return (
    <ColStyled xs style={{ padding: "20px 8px" }}>
      <Filters />
      {fields.length > 0 && showApproveAllButton && <ApproveAll approveAllFoodPrices={approveAllFoodPrices} totalCount={foodPricesCount}/>}
      <CustomTable {...tableProps} />
    </ColStyled>
  );
};

export default Prices;
