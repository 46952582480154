import React, { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { injectIntl } from "react-intl";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { Module, ChipWithLabel } from "components";
import { ColStyled } from "components/utils";
import useSize from "@react-hook/size";
import messages from "containers/Results/messages";
import DayBox from "./DayBox";
import { ContainerWeek } from "./styles";
import { selectLoading } from "containers/Results/selectors";
import { useResponsiveHook } from "hooks";
import { colors } from "@wfp/ui";
import EmptyDayBox from "./EmptyDayBox";
import WeekNumber from "./WeekNumber";
import { RowStyled } from "components/utils";
import DayModal from "./DayModal";
import { saveInResultsPdf } from "containers/PDF/actions";
import { selectMealNames } from "containers/Menu/selectors";

const daysPerRow = 5;

function MenuComposition({ menuId, values, daysInWeek, weekCount, optionsFood, graphValues, intl }) {
  const dispatch = useDispatch();
  const [absoluteDay, setAbsoluteDay] = useState(); // Day 1, 2, 3, 4, 5, 6
  const [relativeDay, setRelativeDay] = useState(); // Week 1 - Day 1, 2, 3; Week 2 - Day 1, 2, 3
  const [, setWeek] = useState();
  const loading = useSelector(selectLoading);
  const days = new Array(weekCount * daysInWeek).fill().map((_, i) => i + 1);
  const weeks = new Array(weekCount).fill().map((_, i) => i + 1);
  const $menu = useRef();
  const [menuWidth] = useSize($menu);
  const { isTabletOrMobile } = useResponsiveHook();
  const mealNames = useSelector(selectMealNames);

  useEffect(() => {
    if (!R.isEmpty(values)) {
      dispatch(saveInResultsPdf({ values, daysInWeek, days }));
    }
  }, [dispatch, values, daysInWeek, weekCount, days]);

  const calcColor = useCallback((percentage) => {
    // Given a nutrient percentage and a minimum requirement, calc the % of satisfied requirement
    const satisfiedPercentage = (percentage.percentage * 100) / percentage.restriction;
    if (satisfiedPercentage < 75) return colors["categorical-corporate-07"].hex;
    if (satisfiedPercentage >= 75 && satisfiedPercentage <= 99) return colors["support-03"].hex;
    return colors["categorical-corporate-03"].hex;
  }, []);

  const chips = useMemo(
    () => (
      <RowStyled margin="0 0 10px 0">
        {(graphValues || []).map((percentage, i) => (
          <ChipWithLabel
            key={percentage.label}
            backgroundColor={calcColor(percentage)}
            value={percentage.label}
            marginLeft={i !== 0 && "20px"}
          />
        ))}
      </RowStyled>
    ),
    [graphValues, calcColor],
  );

  return (
    <div ref={$menu} style={{ width: "100%" }}>
      <ColStyled margin="10px 0 0" xs={12}>
        <Module
          noLine
          padding={isTabletOrMobile && "0"}
          marginHeader="0 0 10px"
          margin="0 0 10px"
          title={intl.formatMessage(messages.menuComposition)}
          elementsAfterTitle={chips}
        >
          {!loading &&
            weeks.map((week, index) => (
              <React.Fragment key={`week-${index}`}>
                <WeekNumber week={week} />

                <ContainerWeek center="xs">
                  {R.splitEvery(daysInWeek, days.slice(index * daysInWeek, daysInWeek * (index + 1))).map((days) => {
                    let result = [];

                    days.forEach((day) =>
                      result.push(
                        <DayBox
                          key={`day-${day}-${index}`}
                          day={day}
                          mealName={mealNames && mealNames[day]}
                          setAbsoluteDay={setAbsoluteDay}
                          setRelativeDay={setRelativeDay}
                          daysInWeek={daysInWeek}
                          week={week}
                          setWeek={setWeek}
                          list={values[`day_${day}`]}
                          maxWidth={daysInWeek > 5 ? "20%" : menuWidth / days.length}
                          customFlex={daysInWeek > 5 ? "1 0 20%" : null}
                        />,
                      ),
                    );

                    // If we have more than 5 days per week, we need to put some empty boxes to fill the whole row
                    if (daysInWeek > 5) {
                      // Diff between menu days and days per row
                      const diffBetweenDays = days.length - daysPerRow;
                      const numOfEmptyBoxes = daysPerRow - diffBetweenDays;

                      const arrEmptyBoxes = new Array(numOfEmptyBoxes).fill().map((_, i) => i + 1);

                      arrEmptyBoxes.forEach((emptyBox) =>
                        result.push(<EmptyDayBox key={`empty-${emptyBox}.${index}`} customFlex="1 0 20%" />),
                      );
                    }

                    return result;
                  })}
                </ContainerWeek>
              </React.Fragment>
            ))}
        </Module>
      </ColStyled>

      <DayModal
        menuId={menuId}
        values={values}
        mealNames={mealNames}
        absoluteDay={absoluteDay}
        setAbsoluteDay={setAbsoluteDay}
        relativeDay={relativeDay}
        setRelativeDay={setRelativeDay}
        setWeek={setWeek}
        optionsFood={optionsFood}
      />
    </div>
  );
}

export default injectIntl(MenuComposition);
