import React, { useState, useMemo, useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import * as R from "ramda";
import { Button } from "components";
import { ModalWithoutFooter } from "components/UIKitStyled";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import { getPercentageByTotal } from "containers/Results/utils";
import menuMessages from "containers/Menu/messages";
import messages from "containers/Results/messages";
import MainCostsList from "./MainCostsList";

export const options = (intl) => [
  { label: intl.formatMessage(menuMessages.foodItems), value: FIELDS.DISPLAY_NAME },
  { label: intl.formatMessage(menuMessages.foodGroups), value: FIELDS.MAJOR_GROUP },
];

function MainCostsDrivers({ values, totalCost, intl }) {
  const [mainCostType, setMainCostType] = useState(options(intl)[0]);
  const [showModal, setModal] = useState(false);

  const mainCosts = useMemo(() => {
    if (!totalCost || !values) return [[]];
    const keysToCycle = R.groupBy((item) => item[mainCostType.value], values);
    const mainCostObject = Object.keys(keysToCycle).reduce(
      (acc, item) => ({
        ...acc,
        [item]: getPercentageByTotal(keysToCycle[item], "price", totalCost.cost),
      }),
      {},
    );
    const orderValues = R.sortWith(
      [R.descend(R.prop("value"))],
      Object.keys(mainCostObject).map((key) => ({ label: key, value: mainCostObject[key] })),
    );
    return orderValues.map((el) => ({ ...el, value: `${el.value.toFixed(2)}%` }));
  }, [mainCostType.value, totalCost, values]);

  const onChangeStatusModal = useCallback(() => setModal(!showModal), [showModal]);
  return (
    <RowStyled margin="10px 0 0 0">
      <MainCostsList
        options={options(intl)}
        mainCosts={mainCosts}
        mainCostType={mainCostType}
        setMainCostType={setMainCostType}
      />
      <Button
        widthAuto
        kind="ghost"
        marginTop="10px"
        marginBottom="5px"
        marginLeft="auto"
        marginRight="auto"
        onClick={onChangeStatusModal}
      >
        <FormattedMessage {...messages.viewFullPrice} />
      </Button>
      <ModalWithoutFooter open={showModal} onRequestClose={onChangeStatusModal}>
        <ColStyled margin="20px 0 0 0">
          <MainCostsList
            inModal
            options={options(intl)}
            mainCosts={mainCosts}
            mainCostType={mainCostType}
            setMainCostType={setMainCostType}
          />
        </ColStyled>
      </ModalWithoutFooter>
    </RowStyled>
  );
}

export default injectIntl(MainCostsDrivers);
