import { useMemo, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { colors } from "@wfp/ui";

import { Module, Text, Select } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { StyledAccordion, StyledCol } from "./styles";
import menuMessages from "containers/Menu/messages";
import messages from "../messages";

const MenuDiversity = ({ menus }) => {
  const intl = useIntl();

  const wholeMenuOption = useMemo(
    () => ({ label: intl.formatMessage(menuMessages.wholeMenu), value: "whole_menu" }),
    [intl],
  );
  const [optionSelected, setOption] = useState(wholeMenuOption);
  const [foodGroup, setFoodGroup] = useState();
  const selectOptions = useMemo(() => {
    if (!menus || R.isEmpty(menus)) return;
    const withMoreKeys = R.clone(menus).sort((a, b) => Object.keys(b.items).length - Object.keys(a.items).length)[0].id;
    const weekOptions = Object.keys(R.clone(menus).find((el) => el.id === withMoreKeys).items);
    return weekOptions.map((el) =>
      el === wholeMenuOption.value
        ? wholeMenuOption
        : {
            value: el,
            label: intl.formatMessage(menuMessages.week, { week: el.replace("week_", "") }),
          },
    );
  }, [intl, menus, wholeMenuOption]);

  const numberOfFoodGroups = useCallback(
    (items) => {
      return items[optionSelected.value].length;
    },
    [optionSelected.value],
  );

  const numberOfFoodItems = useCallback(
    (items) => {
      return items[optionSelected.value].reduce((acc, { items }) => (acc += items.length), 0);
    },
    [optionSelected.value],
  );

  const formatDataForAccordion = useCallback(
    (items) => {
      const refactorName = (name) =>
        menus.length === 4 && name.length > 31 ? name.substring(0, 28).concat("...") : name;
      return items[optionSelected.value].map((el) => ({
        uuid: el.group && el.group.replace(/\s/g, ""),
        title: (
          <Text
            wrap
            bold
            fontSize="16px"
            value={`${refactorName(el.group)} (${el.items.length})`}
            defaultColor={colors["text-01"].hex}
          />
        ),
        content: el.items.map((item) => <Text key={item} fontSize="16px" value={item} />),
      }));
    },
    [menus.length, optionSelected.value],
  );

  const widthCol = menus.length === 2 ? "50%" : menus.length === 3 ? "75%" : "100%";

  return (
    <>
      {menus && (
        <Module
          title={intl.formatMessage(messages.menuStructure)}
          elementsOnRight={
            <ColStyled xs={2} className="end-xs" margin="0 0 10px">
              <Select wfpStyle defaultValue={optionSelected} options={selectOptions} onChange={setOption} />
            </ColStyled>
          }
        >
          <Module.ContentWithBorder menus={menus}>
            {({ items }) => (
              <>
                {R.isEmpty(items[optionSelected.value]) || !items[optionSelected.value] ? (
                  <StyledCol xs={7}>
                    <Text value="NA" textAlign="right" bold fontSize="28px" defaultColor={colors["ui-05"].hex} />
                  </StyledCol>
                ) : (
                  <>
                    <StyledCol xs>
                      <RowStyled middle="xs" margin="0 auto" width={widthCol}>
                        <Text bold fontSize="28px" value={numberOfFoodGroups(items)} />
                        <Text
                          bold
                          fontSize="16px"
                          value={intl.formatMessage(menuMessages.foodGroupsLower)}
                          marginLeft="5px"
                          marginTop="8px"
                        />
                      </RowStyled>
                      <RowStyled middle="xs" margin="0 auto" width={widthCol}>
                        <Text
                          bold
                          fontSize="28px"
                          value={numberOfFoodItems(items)}
                          defaultColor={colors["text-02"].hex}
                        />
                        <Text
                          bold
                          marginLeft="5px"
                          marginTop="8px"
                          fontSize="16px"
                          value={intl.formatMessage(menuMessages.foodItemsLower)}
                          defaultColor={colors["text-02"].hex}
                        />
                      </RowStyled>
                    </StyledCol>
                    <RowStyled middle="xs" center="xs">
                      <StyledAccordion
                        withNoArrow
                        padding="10px 0 5px"
                        onChange={(uuid) => setFoodGroup(uuid)}
                        preExpanded={foodGroup}
                        fields={formatDataForAccordion(items)}
                      />
                    </RowStyled>
                  </>
                )}
              </>
            )}
          </Module.ContentWithBorder>
        </Module>
      )}
    </>
  );
};

export default MenuDiversity;
