import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@menu/LOAD_STARTED");
export const loadEnded = createAction("@@menu/LOAD_ENDED");
export const loadSuccess = createAction("@@menu/LOAD_SUCCESS");

export const createOrUpdateMenuRequest = createAction("@@menu/CREATE_OR_UPDATE_MENU_REQUEST");

export const createMenu = createAction("@@menu/CREATE_MENU");
export const updateMenu = createAction("@@menu/UPDATE_MENU");

export const loadMenuRequest = createAction("@@menu/LOAD_MENU_REQUEST");

export const changeMealNames = createAction("@@menu/CHANGE_MEAL_NAMES");

export const createOrUpdateCommunityMenu = createAction("@@menu/CREATE_OR_UPDATE_COMMUNITY_MENU");
export const copyCommunityMenuAsOptimized = createAction("@@menu/COPY_COMMUNITY_MENU_AS_OPTIMIZED");
export const copyCommunityMenuAsOptimizedKo = createAction("@@menu/COPY_COMMUNITY_MENU_AS_OPTIMIZED_KO");

export const copyMenu = createAction("@@menu/COPY_MENU");
export const partialCopyMenu = createAction("@@menu/PARTIAL_COPY_MENU");
export const shareMenu = createAction("@@menu/SHARE_MENU");
export const optimizeManualMenu = createAction("@@menu/OPTIMIZE_MANUAL_MENU");

export const resetDuplicationData = createAction("@@menu/RESET_DUPLICATION");
export const resetMenu = createAction("@@menu/RESET_MENU");
