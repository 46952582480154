import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";
import messages from "components/Button/messages";
import { Text } from "components";
import { Share as IconShare } from "components/Icons";
import { useHtmlHook } from "hooks";
import { ContainerIcon } from "./styles";
import { ACTIONS } from "../constants";

function Share({ setMenuAndAction, menu }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const clickOnShare = useCallback(() => {
    setMenuAndAction({ id: menu.id, action: ACTIONS.SHARE, name: menu.name });
  }, [menu.id, menu.name, setMenuAndAction]);

  return (
    <ContainerIcon onClick={clickOnShare} hover={colors["brand-01"].hex} isRTL={isRTL}>
      <IconShare width="18px" height="18px" />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.share)} />
    </ContainerIcon>
  );
}

export default Share;
