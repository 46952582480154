import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { Text } from "components";
import { removeMenu } from "containers/MyMenus/actions";
import messages from "components/Button/messages";
import { useHtmlHook } from "hooks";
import { ContainerIcon } from "./styles";

function Delete({ id, callbackOnDelete }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const deleteMenu = useCallback(() => {
    dispatch(removeMenu({ id, cb: callbackOnDelete }));
  }, [callbackOnDelete, dispatch, id]);

  return (
    <ContainerIcon onClick={deleteMenu} hover={colors["support-01"].hex} isRTL={isRTL}>
      <Icon icon={iconDelete} kind="inverse" width="20px" height="20px" fill={colors["text-01"].hex} />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.delete)} />
    </ContainerIcon>
  );
}

export default Delete;
