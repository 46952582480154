import { useIntl } from "react-intl";
import * as R from "ramda";
import { Text } from "@react-pdf/renderer";
import generalStyles from "containers/PDF/styles";
import SpacedInRow from "containers/PDF/SpacedInRow";
import messages from "containers/Results/messages";

const AverageCost = ({ costsDrivers = [] }) => {
  const intl = useIntl();
  const { fontSize12, fontSize10 } = generalStyles;

  return (
    <>
      <Text style={[fontSize12, { margin: "3 0 7" }]}>{intl.formatMessage(messages.mainCostsDrivers)}</Text>
      {costsDrivers.map((cost, index) => (
        <SpacedInRow
          key={`${R.pathOr("", ["label"], cost)}-${index}`}
          label={R.pathOr("", ["label"], cost)}
          value={R.pathOr("", ["value"], cost)}
          fontSizeStyle={fontSize10}
          heightRow={20}
          noPadding
        />
      ))}
    </>
  );
};

export default AverageCost;
