import React from "react";
import { useHistory } from "react-router-dom";

import { Button } from "components";
import { ContentWrapper, InfoWrapper, TextStyledWrapper } from "./styles";
import routesPath from "containers/App/Routes/paths";

const Content = () => {
  const history = useHistory();

  return (
    <ContentWrapper>
      <InfoWrapper>
        <TextStyledWrapper
          regularWrap
          noFontWeight
          lineHeight="1.3"
          defaultColor={"white"}
          fontSize="56px"
          marginBottom={"10px"}
        >
          Nutritious, local meals at the lowest cost
        </TextStyledWrapper>
        <TextStyledWrapper
          padding="0 100px 0 0"
          fontWeight="400"
          lineHeight="1.4"
          regularWrap
          defaultColor={"white"}
          fontSize="20px"
          marginBottom={"30px"}
        >
          PLUS School Menus is the <span style={{ fontWeight: "bold" }}>first digital solution</span> for school feeding
          programmes that creates <span style={{ fontWeight: "bold" }}>low cost, nutrient dense menus</span> that are
          <span style={{ fontWeight: "bold" }}> locally sourced and respect food habits worldwide</span>
        </TextStyledWrapper>
        <Button maxwWidth onClick={() => history.push(routesPath.ciamLogin)}>
          Sign in
        </Button>
      </InfoWrapper>
    </ContentWrapper>
  );
};

export default Content;
