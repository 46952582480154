import { useController } from "react-hook-form";
import { Range } from "rc-slider";

import { RowStyled } from "components/utils";

const RangeValues = ({ value }) => (
  <RowStyled middle="xs" center="xs">
    <input className="wfp--input wfp--slider-text-input" readOnly value={`${value[0]} - ${value[1]}`} />
  </RowStyled>
);

const RangeHookForm = ({
  control,
  name,
  allowCross,
  pushable = false,
  min = 0,
  max = 100,
  step = null,
  trackStyle = {},
  handleStyle = {},
  railStyle = {},
  dotStyle = {},
  activeDotStyle = {},
  marks = {},
  vertical = false,
  label = null,
  showRangeValues = false,
}) => {
  const { field } = useController({ control, name });

  return (
    <>
      <Range
        pushable={pushable}
        allowCross={allowCross}
        min={min}
        max={max}
        step={step}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        railStyle={railStyle}
        dotStyle={dotStyle}
        activeDotStyle={activeDotStyle}
        marks={marks}
        vertical={vertical}
        value={field.value}
        onChange={field.onChange}
      />
      {label}
      {showRangeValues && <RangeValues value={field.value} />}
    </>
  );
};

export default RangeHookForm;
