import * as R from "ramda";
import { FIELDS, ADDITIONAL_FIELDS } from "./constants";
import { genericError } from "utils/errors";

export default function validation(values) {
  let errors = {};

  if (values === undefined) return;

  Object.keys(values).forEach((label) => {
    const item = values[label];

    // Check price
    if (item[FIELDS.PRICE] === 0 || !item[FIELDS.PRICE]) {
      errors[FIELDS.PRICE] = "0 or empty is not allowed";
    }

    // Check price
    const price = String(item[FIELDS.PRICE]).split(".");
    if (price[1] && price[1].length > 2) {
      errors.price = "More than 2 decimal places are not allowed";
    }

    // Check additional_costs
    if (!R.isEmpty(item[ADDITIONAL_FIELDS.ADDITIONAL_COSTS])) {
      item[ADDITIONAL_FIELDS.ADDITIONAL_COSTS].forEach((el) => {
        if (!el[ADDITIONAL_FIELDS.UNIT] || !el[ADDITIONAL_FIELDS.VALUE] || !el[ADDITIONAL_FIELDS.TYPE]) {
          errors.additional_costs = genericError;
        }
      });
    }
  });

  return errors;
}
