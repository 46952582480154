import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text, BulletList } from "components";
import { MIN, MAX } from "containers/OptimizedMenu/constants";
import { joinStrings } from "utils/utils";
import messages from "../messages";

const li = (value) => <Text wrap fontSize="18px" defaultColor={colors["text-02"].hex} value={value} />;

const SourcingConstraintsSet = ({ specificSourcing, sourcingSet, majorGroups }) => {
  const intl = useIntl();

  const requirement = useCallback(
    (limit) => {
      const source = sourcingSet.filter((el) => el.limit === limit).map((el) => el.source_display);
      const lastSource = source.pop();
      return source.length ? joinStrings(source, lastSource, intl) : lastSource;
    },
    [intl, sourcingSet],
  );

  const hasFoodGroups = useMemo(() => {
    const foodGroups = sourcingSet.reduce(
      (acc, { source_display, group_sourcing_constraints }) => ({
        ...acc,
        [source_display]: group_sourcing_constraints.map((el) => majorGroups[el.group]),
      }),
      {},
    );
    const foodGroupsSet = R.uniq(R.flatten(Object.values(foodGroups)));
    const lastFoodGroup = foodGroupsSet.pop();
    return foodGroupsSet.length ? joinStrings(foodGroupsSet, lastFoodGroup, intl) : lastFoodGroup;
  }, [intl, majorGroups, sourcingSet]);

  const bulletList = useMemo(() => {
    const list = [];
    if (requirement(MIN))
      list.push(li(intl.formatMessage(messages.setHighMin, { value: <span>{requirement(MIN)}</span> })));
    if (requirement(MAX))
      list.push(li(intl.formatMessage(messages.setLowMax, { value: <span>{requirement(MAX)}</span> })));
    if (hasFoodGroups)
      list.push(li(intl.formatMessage(messages.setFoodGroups, { value: <span>{hasFoodGroups}</span> })));
    return list;
  }, [hasFoodGroups, intl, requirement]);

  return (
    <>
      {!!bulletList.length && (
        <Text
          wrap
          bold
          fontSize="20px"
          marginBottom="10px"
          defaultColor={colors["text-02"].hex}
          value={intl.formatMessage(messages.sourcingSet, {
            sentence: specificSourcing ? intl.formatMessage(messages.forSourcing) : "",
          })}
        />
      )}
      <BulletList color={colors["text-02"].hex} list={bulletList} fontSize="18px" />
    </>
  );
};

export default SourcingConstraintsSet;
