import styled, { css } from "styled-components";

export const GroupRowInnerDiv = styled.div`
  margin: 0;
  min-height: 32px;
  display: flex;
  align-items: center;

  ${({ padding }) =>
    padding &&
    css`
      ${{ padding }}
    `};
`;
