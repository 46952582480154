import { useMemo } from "react";
import { colors, Icon } from "@wfp/ui";
import { iconCaretRight, iconCaretLeft } from "@wfp/icons";

import { Text } from "components";
import { getIcon } from "components/Icons";
import { useHtmlHook } from "hooks";
import { FirstLevelContainerText, IconStyled } from "./styles";

const FirstLevel = ({ el, selectedIndex, index, onClick }) => {
  const { isRTL } = useHtmlHook();

  const selected = useMemo(() => selectedIndex === index, [index, selectedIndex]);
  const noSelection = useMemo(() => selectedIndex === null, [selectedIndex]);

  return (
    <FirstLevelContainerText key={el.major_id} selected={selected} noSelection={noSelection} onClick={onClick}>
      <IconStyled>{getIcon(el.major_en) && getIcon(el.major_en)()}</IconStyled>
      <Text withPointer width="100%" value={el.major_label} fontSize="16px" marginInlineEnd="10px" />
      <Icon width="9px" icon={isRTL ? iconCaretLeft : iconCaretRight} fill={colors["ui-05"].hex} description="" />
    </FirstLevelContainerText>
  );
};

export default FirstLevel;
