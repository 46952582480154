import React, { useRef } from "react";
import * as R from "ramda";
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from "recharts";
import { colors } from "@wfp/ui";
import { useResponsiveHook } from "hooks";
import LabelInside from "./LabelInside";
import Percentage from "./Percentage";
import { newColorBySource } from "components/utils";

export default function Chart({ source, setOnHover, onHover, isLast, showLabelOnGraph = true }) {
  const { isTabletOrMobile } = useResponsiveHook();
  const $container = useRef();

  const getSize = () => {
    if (!$container.current) return {};
    return {
      width: $container.current?.state?.containerWidth,
      height: $container.current?.state?.containerHeight,
    };
  };

  return (
    <ResponsiveContainer ref={$container}>
      <PieChart style={{ borderRight: isLast ? 0 : `1px solid ${colors["ui-04"].hex}` }}>
        <Pie
          data={R.isEmpty(source) ? [{ value: 100 }] : source}
          innerRadius={isTabletOrMobile ? 70 : 90}
          outerRadius={isTabletOrMobile ? 75 : 100}
          paddingAngle={1}
          minAngle={0}
          dataKey="value"
          labelLine={false}
          isAnimationActive={false}
          fill={colors["ui-02"].hex}
          label={(el) => <Percentage onHover={onHover} {...el} />}
          onMouseOver={(data) => setOnHover(data ? data.source : null)}
          onMouseOut={() => setOnHover(null)}
        >
          {source.map((el, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                onHover
                  ? onHover === el.source
                    ? newColorBySource[el.source]
                    : colors["ui-02"].hex
                  : newColorBySource[el.source]
              }
            />
          ))}
          {showLabelOnGraph &&
            source.map(
              (el, index) =>
                el.source === onHover && (
                  <Label content={<LabelInside {...el} {...getSize()} />} key={`label-${index}`} />
                )
            )}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
