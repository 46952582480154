import { call, all, put, takeLatest, select } from "redux-saga/effects";
import { setAlert } from "containers/App/actions";
import * as R from "ramda";
import { getTargetBeneficiaries, getFoodBasketV2 } from "api/api";
import {
  loadStarted,
  loadEnded,
  loadBasketStarted,
  loadBasketEnded,
  loadGeneralInfo,
  loadFoodBasket,
  loadFoodBasketSuccess,
  saveNutritionalReq,
  loadNutritionalReq,
  loadTargetBeneficiariesSuccess,
  loadTargetBeneficiariesRequest,
} from "./actions";
import { loadMenuSaga } from "containers/Menu/sagas";
import { loadConstantsSaga, loadRegionsSaga, loadPricesSourcesSaga, loadCurrencySaga } from "containers/App/sagas";
import { selectCountryCode } from "containers/App/selectors";
import { loadBeneficiariesNutrition } from "containers/OptimizedMenu/Rules/sagas";
import { transformForBackend } from "containers/Menu/utils";

export function* loadNutritionalReqSaga({ payload: { id, menuType } }) {
  try {
    const { nutritional_requirements } = yield call(loadBeneficiariesNutrition, id, menuType);
    yield put(
      saveNutritionalReq({ nutritionalRequirements: R.sortBy((key) => key.nutrient_order, nutritional_requirements) })
    );
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadFoodBasketSaga({ payload: { body } }) {
  try {
    yield put(loadBasketStarted());
    const reworkedBody = transformForBackend(body);
    const foodBasket = yield call(getFoodBasketV2, reworkedBody);
    yield put(loadFoodBasketSuccess({ foodBasket }));
    yield put(loadBasketEnded());
  } catch (e) {
    yield put(loadBasketEnded());
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadTargetBeneficiariesSaga() {
  try {
    const target = yield call(getTargetBeneficiaries);
    const refactoredTarget = target.map(({ id, name }) => ({ value: id, label: name }));
    yield put(loadTargetBeneficiariesSuccess({ targetBeneficiaries: refactoredTarget }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadGeneralInfoSaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    const countryCode = yield select(selectCountryCode);

    yield all([
      !!id && loadMenuSaga({ payload: { id } }),
      loadConstantsSaga(),
      loadRegionsSaga(),
      loadPricesSourcesSaga(),
      loadTargetBeneficiariesSaga(),
      countryCode && loadCurrencySaga(countryCode),
    ]);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* appSaga() {
  yield takeLatest(loadGeneralInfo, loadGeneralInfoSaga);
  yield takeLatest(loadFoodBasket, loadFoodBasketSaga);
  yield takeLatest(loadNutritionalReq, loadNutritionalReqSaga);
  yield takeLatest(loadTargetBeneficiariesRequest, loadTargetBeneficiariesSaga);
}
