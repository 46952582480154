import { Controller } from "react-hook-form";
import { TextInput } from "@wfp/ui";

const TextInputController = ({ control, name, labelText = "", ...rest }) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => <TextInput id={name} labelText={labelText} {...field} {...rest} />}
  />
);

export default TextInputController;
