import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  pagination: {
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    margin: "0 25",
    justifyContent: "space-between",
  },
});
