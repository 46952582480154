import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { iconFilter } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import messages from "components/Button/messages";
import { SelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { selectWeightChoices } from "containers/App/selectors";
import { FIELDS } from "containers/FoodBasket/constants";
import { useCurrencies } from "hooks";
// TODO: Create below options once we know what to show. Will eventually introduce this in the future
// import { intlOrderByOptions } from "../constants";

const OrderByAndFilter = ({ visibleFilters, setVisibleFilters, closeFiltersAndFetchFoodItems, closeFilters }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const units = useSelector(selectWeightChoices);
  const currencies = useCurrencies();

  // TODO: Will eventually introduce this in the future
  // const orderByOptions = useMemo(() => [{ label: "A - Z", value: "updated_timestamp" }], []);

  // I think we'll have to do something similar to MyMenus page: close filters and start API call to fetch food items
  const handleFilterChange = useCallback(
    async (el, field) => {
      // Do not change if new value is same as old one
      if (el.value === field.value) return;

      await field.onChange(el.value);
      // closeFiltersAndFetchFoodItems();
    },
    // [closeFiltersAndFetchFoodItems],
    [],
  );

  const onFilterClick = useCallback(() => {
    if (visibleFilters) {
      closeFilters();
    } else {
      setVisibleFilters(true);
    }
  }, [closeFilters, setVisibleFilters, visibleFilters]);

  return (
    <RowStyled end="xs">
      <ColStyled xs={2}>
        <SelectHookForm
          control={control}
          name={`${FIELDS.FILTERS}.${FIELDS.UNIT}`}
          options={units}
          customOnChange={handleFilterChange}
        />
      </ColStyled>
      <ColStyled xs={2}>
        <SelectHookForm
          control={control}
          name={`${FIELDS.FILTERS}.${FIELDS.CURRENCY}`}
          options={currencies}
          customOnChange={handleFilterChange}
        />
      </ColStyled>
      {/* TODO: Will eventually introduce this in the future */}
      {/* <ColStyled xs={3}>
        <SelectHookForm
          control={control}
          name={`${FIELDS.FILTERS}.${FIELDS.ORDERING}`}
          options={orderByOptions}
          customOnChange={handleFilterChange}
        />
      </ColStyled> */}
      <Button
        widthAuto
        icon={iconFilter}
        marginLeft="0.5rem"
        kind={visibleFilters ? "primary" : "secondary"}
        onClick={onFilterClick}
        children={intl.formatMessage(messages.filter)}
      />
    </RowStyled>
  );
};

export default OrderByAndFilter;
