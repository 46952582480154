import { FIELDS } from "./constants";
import { validation as validationItem } from "./ItemRestriction/validation";
import { validation as validationGroup } from "./GroupRestriction/validation";

export default {
  item_id: (_, values, daysInWeek, weekCount) => {
    if (values[FIELDS.UNMATCH_WITH]?.includes("") || values[FIELDS.MATCH_WITH]?.includes("")) return "disable";
    if (Object.keys(validationItem(values, daysInWeek, weekCount)).length && !validationItem(values).kind)
      return "disable";
    return "";
  },
  major_group_id: (name, values, daysInWeek, weekCount) => {
    if (values[FIELDS.UNMATCH_WITH]?.includes("")) return "disable";
    if (Object.keys(validationGroup(name, values, daysInWeek, weekCount)).length) return "disable";
    return "";
  },
  minor_group_id: (name, values, daysInWeek, weekCount) => {
    if (Object.keys(validationGroup(name, values, daysInWeek, weekCount)).length) return "disable";
    return "";
  },
};
