import React, { useEffect, useState, useCallback } from "react";
import * as R from "ramda";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Row } from "react-flexbox-grid";
import { Layout, Text, Line, Button } from "components";
import { ColStyled } from "components/utils";
import { ModuleWhite } from "components/UIKitStyled";
import buttonMessages from "components/Button/messages";
import { colors, Loading } from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import { loadPriorityItems, updateFormState, updatePriorityItems } from "containers/Admin/actions";
import { loadCountries } from "containers/SelectCountry/actions";
import { loadFoodItems, loadRegions } from "containers/App/actions";
import { selectLoadingCountriesSlice, selectPriorityItems } from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";
import { selectFoodItems, selectRegions } from "containers/App/selectors";
import { useInitialValues } from "./useInitialValues";
import PriorityItem from "./PriorityItem";
import { FIELDS } from "../constants";
import countriesMessages from "../messages";
import { validation } from "./utils";

const PriorityItems = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const priorityItems = useSelector(selectPriorityItems);
  const foodItems = useSelector(selectFoodItems);
  const countries = useSelector(selectCountries);
  const regions = useSelector(selectRegions);
  const loadingPriorityItems = useSelector(selectLoadingCountriesSlice);
  const initialValues = useInitialValues();

  const [initialize, setInitialize] = useState();

  useEffect(() => {
    if (!countries) {
      dispatch(loadCountries());
    }
  }, [dispatch, countries]);

  useDeepCompareEffect(() => {
    if (R.isEmpty(regions)) {
      dispatch(loadRegions());
    }
  }, [dispatch, regions]);

  // Do not run API calls if countries are missing or priority items are already in place
  useEffect(() => {
    if (!countries) return;

    if (!priorityItems) {
      dispatch(loadPriorityItems());
    }
  }, [dispatch, countries, priorityItems]);

  useEffect(() => {
    if (!foodItems && priorityItems) {
      dispatch(loadFoodItems({ params: null, initialPriorityItems: priorityItems }));
    }
  }, [dispatch, foodItems, priorityItems]);

  // We initialize data only when both priority items and whole food items list are available
  useDeepCompareEffect(() => {
    if (priorityItems && foodItems) {
      setInitialize(initialValues);
    }
  }, [initialValues, priorityItems, foodItems]);

  const updateForm = useCallback(
    (state) => {
      dispatch(updateFormState({ formState: state }));
    },
    [dispatch],
  );

  const onSubmit = useCallback(() => {
    dispatch(updatePriorityItems());
  }, [dispatch]);

  return (
    <Layout withBreadcrumbs={false} backgroundColor={colors["ui-02"].hex} marginTop="0" overflow="visible">
      <ModuleWhite margin="15px 0" paddingContent="10px 30px 20px">
        <Text bold fontSize="16px" value={intl.formatMessage(countriesMessages.priorityItemsTitle)} />
        <Line margin="10px -30px" />
        {!R.isEmpty(initialize) && (
          <Form
            initialValues={initialize}
            mutators={arrayMutators}
            onSubmit={() => {}}
            render={({ values }) => {
              const disableSubmit = validation(values);

              return (
                <>
                  <FormSpy subscription={{ values: true }} onChange={updateForm} />
                  <Loading active={loadingPriorityItems} />

                  <ColStyled xs>
                    <Text
                      bold
                      fontSize="20px"
                      marginBottom="20px"
                      value={intl.formatMessage(countriesMessages.priorityItemsDescription)}
                    />

                    <FieldArray name={FIELDS.PRIORITY_ITEMS}>
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <PriorityItem
                              key={`field-${name}`}
                              name={name}
                              index={index}
                              values={values[FIELDS.PRIORITY_ITEMS]}
                            />
                          ))}

                          <Button
                            widthAuto
                            kind="secondary"
                            icon={iconAddGlyph}
                            children={intl.formatMessage(countriesMessages.addPriorityItem)}
                            onClick={() => fields.push({})}
                          />
                        </>
                      )}
                    </FieldArray>
                  </ColStyled>

                  <Line margin="15px -30px" />
                  <Row end="xs">
                    <Button type="submit" onClick={onSubmit} disabled={disableSubmit} className="col-xs-2">
                      <FormattedMessage {...buttonMessages.submit} />
                    </Button>
                  </Row>
                </>
              );
            }}
          />
        )}
      </ModuleWhite>
    </Layout>
  );
};

export default PriorityItems;
