import { FIELDS } from "../constants";

export const validation = (values) => {
  const priorityItems = values[FIELDS.PRIORITY_ITEMS];
  if (!priorityItems) return true;

  const invalidItems = priorityItems.some((item) => !item.value);

  return invalidItems;
};
