import styled from "styled-components";

import { Text } from "components";

export const Container = styled.div`
  margin: 2.5rem 0;
`;

export const Label = styled(Text)`
  margin-bottom: 1rem;
`;
