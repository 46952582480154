import styled from "styled-components";

export const TabsContainer = styled.div`
  .wfp--tabs:last-of-type {
    margin-bottom: -1.75rem;
  }
  .wfp--tabs__nav__bar {
    z-index: 0;
  }
`;
