import React, { useState, useCallback, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Layout, Button, Text, Select } from "components";
import { Icon, colors, Module, ModuleHeader, ModuleBody, TextInput, Loading } from "@wfp/ui";
import { iconChevronLeft } from "@wfp/icons";
import messages from "../messages";
import buttonMessages from "components/Button/messages";
import { ColStyled, RowStyled } from "components/utils";
import { selectUsers, selectUserGroups, selectLoading } from "containers/Admin/selectors";
import { selectRoles } from "containers/App/selectors";
import { loadSingleUser, updateSingleUser, loadUserGroups } from "containers/Admin/actions";
import { intlStateOptions } from "containers/Admin/constants";
import { reworkUserGroups } from "../utils";

const UserPage = ({ prevPath }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { userId } = useParams();
  const users = useSelector(selectUsers);
  const userGroups = useSelector(selectUserGroups);
  const loading = useSelector(selectLoading);
  const loggedInUserRoles = useSelector(selectRoles);

  const selectedUser = useMemo(() => users.find((user) => user.id === Number(userId)), [users, userId]);
  const stateOptions = useMemo(() => intlStateOptions(intl), [intl]);
  const roleOptions = useMemo(() => reworkUserGroups(userGroups, loggedInUserRoles), [userGroups, loggedInUserRoles]);

  const [data, setData] = useState({
    last_name: selectedUser?.last_name,
    first_name: selectedUser?.first_name,
    role: selectedUser?.plusadmin_roles[0],
    is_active: selectedUser?.is_active,
  });

  useEffect(() => {
    if (!selectedUser) {
      dispatch(loadSingleUser({ userId }));
    }
  }, [dispatch, selectedUser, userId]);

  useEffect(() => {
    if (R.isEmpty(userGroups)) {
      dispatch(loadUserGroups());
    }
  }, [dispatch, userGroups]);

  // Update local state with selectedUser data
  useEffect(() => {
    if (selectedUser) {
      setData({
        last_name: selectedUser.last_name,
        first_name: selectedUser.first_name,
        role: selectedUser.plusadmin_roles[0],
        is_active: selectedUser.is_active,
      });
    }
  }, [selectedUser]);

  const onSubmit = useCallback(() => {
    const body = {
      ...R.omit(["role"], data),
      plusadmin_roles: [data.role],
    };
    dispatch(updateSingleUser({ user: selectedUser, body, prevPath }));
  }, [dispatch, selectedUser, data, prevPath]);

  const onChange = useCallback((e) => {
    const { value, id } = e.target;
    setData((prevState) => ({ ...prevState, [id]: value }));
  }, []);

  const renderRole = useCallback(
    (role) => {
      const defaultValue = roleOptions.filter((el) => el.id === role.id);
      return (
        <Select
          id="role"
          options={roleOptions}
          defaultValue={defaultValue}
          customOnChange={(el) => onChange({ target: { id: "role", value: el } })}
        />
      );
    },
    [onChange, roleOptions]
  );

  const renderIsActive = useCallback(
    (isActive) => {
      const defaultValue = isActive ? stateOptions[1] : stateOptions[2];
      return (
        <Select
          id="is_active"
          options={stateOptions.slice(1)}
          defaultValue={defaultValue}
          customOnChange={(el) => onChange({ target: { id: "is_active", value: el } })}
        />
      );
    },
    [onChange, stateOptions]
  );

  return (
    <Layout backgroundColor={colors["ui-02"].hex}>
      <Loading active={loading} />
      <ColStyled xs style={{ padding: "20px 8px" }}>
        <Module>
          <ModuleHeader>
            <RowStyled middle="xs">
              <Link exact="true" to={prevPath} style={{ paddingRight: "10px" }}>
                <Icon fill={colors["brand-01"].hex} icon={iconChevronLeft} height="20px" width="20px" />
              </Link>
              <Text
                bold
                fontSize="16px"
                value={selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : null}
              />
            </RowStyled>
          </ModuleHeader>
          <ModuleBody>
            {selectedUser && userGroups.length && (
              <ColStyled margin="0 0 20px">
                <RowStyled middle="xs" between="xs">
                  <ColStyled xs={4}>
                    <Text bold fontSize="18px" value={intl.formatMessage(messages.email)} />
                  </ColStyled>
                  <ColStyled xs={6}>
                    <TextInput id="email" disabled hideLabel value={selectedUser.email} />
                  </ColStyled>
                </RowStyled>
                <RowStyled middle="xs" between="xs" margin="15px 0 0">
                  <ColStyled xs={4}>
                    <Text bold fontSize="18px" value={intl.formatMessage(messages.lastName)} />
                  </ColStyled>
                  <ColStyled xs={6}>
                    <TextInput id="last_name" hideLabel defaultValue={selectedUser.last_name} onChange={onChange} />
                  </ColStyled>
                </RowStyled>
                <RowStyled middle="xs" between="xs" margin="15px 0 0">
                  <ColStyled xs={4}>
                    <Text bold fontSize="18px" value={intl.formatMessage(messages.firstName)} />
                  </ColStyled>
                  <ColStyled xs={6}>
                    <TextInput id="first_name" hideLabel defaultValue={selectedUser.first_name} onChange={onChange} />
                  </ColStyled>
                </RowStyled>
                <RowStyled middle="xs" between="xs" margin="15px 0 0">
                  <ColStyled xs={4}>
                    <Text bold fontSize="18px" value={intl.formatMessage(messages.role)} />
                  </ColStyled>
                  <ColStyled xs={6}>{renderRole(selectedUser.plusadmin_roles[0])}</ColStyled>
                </RowStyled>
                <RowStyled middle="xs" between="xs" margin="15px 0 0">
                  <ColStyled xs={4}>
                    <Text bold fontSize="18px" value={intl.formatMessage(messages.active)} />
                  </ColStyled>
                  <ColStyled xs={6}>{renderIsActive(selectedUser.is_active)}</ColStyled>
                </RowStyled>
              </ColStyled>
            )}
            <RowStyled end="xs">
              <Button
                widthAuto
                marginRight="5px"
                kind="secondary"
                onClick={onSubmit}
                children={intl.formatMessage(buttonMessages.save)}
              />
            </RowStyled>
          </ModuleBody>
        </Module>
      </ColStyled>
    </Layout>
  );
};

export default UserPage;
