import { defineMessage } from "react-intl";

export default {
  renameTitle: defineMessage({
    id: "modal.renameTitle",
    defaultMessage: "Rename menu",
  }),
  duplicateTitle: defineMessage({
    id: "modal.duplicateTitle",
    defaultMessage: "Duplicate menu",
  }),
  shareTitle: defineMessage({
    id: "modal.shareTitle",
    defaultMessage: "Share menu",
  }),
  shareInfo: defineMessage({
    id: "modal.shareInfo",
    defaultMessage: "Type in and validate emails of users you want to share the menu with",
  }),
  downloadTitle: defineMessage({
    id: "modal.downloadTitle",
    defaultMessage: "Download menu",
  }),
  optionOne: defineMessage({
    id: "modal.optionOne",
    defaultMessage: "Create a copy of this menu",
  }),
  optionTwo: defineMessage({
    id: "modal.optionTwo",
    defaultMessage: "Create a new optimized menu with the same general info",
  }),
  optionThree: defineMessage({
    id: "modal.optionThree",
    defaultMessage: "Create a new manual menu with the same general info",
  }),
  optionFour: defineMessage({
    id: "modal.optionFour",
    defaultMessage: "Create a new community menu with the same general info",
  }),
  optionFive: defineMessage({
    id: "modal.optionFive",
    defaultMessage: "Create a new optimized menu based on this menu",
  }),
  optionSix: defineMessage({
    id: "modal.optionSix",
    defaultMessage: "Duplicate this menu",
  }),
  optionSeven: defineMessage({
    id: "modal.optionSeven",
    defaultMessage: "Create another menu with the same settings",
  }),
  optionEight: defineMessage({
    id: "modal.optionEight",
    defaultMessage: "Modify rules",
  }),
  optionNine: defineMessage({
    id: "modal.optionNine",
    defaultMessage: "Modify food basket",
  }),
  optionTen: defineMessage({
    id: "modal.optionTen",
    defaultMessage: "New rules",
  }),
  duplicateAlert: defineMessage({
    id: "modal.duplicateAlert",
    defaultMessage: "NOTE: New menu will be created with {region} region only",
  }),
};
