import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { Text, Select } from "components";
import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/Results/messages";
import { useDispatch } from "react-redux";
import { saveInResultsPdf } from "../../../PDF/actions";

function MainCostsDrivers({ mainCosts, mainCostType, setMainCostType, inModal, options, intl }) {
  const dispatch = useDispatch();

  useEffect(() => {
    //Save this information in redux to display it in the pdf
    if (!inModal) dispatch(saveInResultsPdf({ costsDrivers: new Array(3).fill().map((_, index) => mainCosts[index]) }));
  }, [dispatch, inModal, mainCosts]);

  return (
    <>
      {inModal && (
        <Text bold fontSize="28px" marginBottom="20px" value={intl.formatMessage(messages.mainCostsDrivers)} />
      )}
      <ColStyled xs={inModal ? 6 : 9} padding="0" margin={`0 0 ${inModal ? "20px" : "10px"}`}>
        <Select wfpStyle defaultValue={mainCostType} options={options} onChange={setMainCostType} />
      </ColStyled>
      {mainCosts.map(
        ({ label, value }, index) =>
          (inModal || (!inModal && index < 3)) && (
            <RowStyled key={`row-cost-${index}`} width="100%" between="xs" margin={"10px 0 0 0"}>
              <ColStyled xs={9} padding="0" margin="0">
                <Text value={label} />
              </ColStyled>
              <ColStyled className={"end-xs"} xs={2} padding="0">
                <Text value={value} />
              </ColStyled>
            </RowStyled>
          ),
      )}
    </>
  );
}

export default injectIntl(MainCostsDrivers);
