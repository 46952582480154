import styled, { keyframes } from "styled-components";
import { ToastNotification } from "@wfp/ui";
import { colors } from "@wfp/ui";

const appear = keyframes`
  from {transform: translate(-100%, 0); }
  to {transform: translate(10px, 0); }
`;

const disappear = keyframes`
  from {transform: translate(10px, 0); }
  to {transform: translate(-100%, 0); }
`;

export const Toast = styled(ToastNotification)`
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 9999;
  width: auto;
  transform: translate(10px, 0);
  animation: ${({ error }) => (error === "true" ? appear : disappear)} 0.3s linear;

  &&&& {
    background-color: ${colors["ui-01"].hex};
  }
`;
