import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  dayHeader: {
    fontSize: 10,
    fontFamily: "OpenSans",
    fontWeight: "semibold",
    textAlign: "center",
    borderBottom: 1,
    borderColor: colors["ui-04"].hex,
    height: 21,
    paddingTop: 3,
    backgroundColor: colors["ui-01"].hex,
  },
  dayContainer: {
    borderTop: 1,
    minHeight: 100,
    borderColor: colors["ui-04"].hex,
  },
  baseTag: {
    fontSize: 7,
    paddingTop: 3,
    fontFamily: "OpenSans",
    fontWeight: "normal",
    backgroundColor: "#dae9f4",
    color: colors["brand-01"].hex,
    borderRadius: 12,
    height: 15,
    marginLeft: 3,
  },
  dayTag: {
    width: "33%",
  },
  recipeTag: {
    textAlign: "center",
    width: "75%",
  },
});
