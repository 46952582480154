import React from "react";
import { colors } from "@wfp/ui";
import styled, { css } from "styled-components";
import { mediaTablet } from "components/utils";
import { StepNavigationItem, FormWizard, Module, Modal, SecondaryNavigation } from "@wfp/ui";

const StyledModule = ({ withTop, margin, paddingContent, height, ...props }) => <Module {...props} />;
const StyledModal = ({
  noFooter,
  noHeader,
  maxContentHeight,
  background,
  padding,
  height,
  minWidth,
  withBorderRadius,
  withNoBorderTop,
  maxWidth,
  width,
  setStatus,
  overflow,
  ...props
}) => <Modal {...props} />;

export const ModalStyled = styled(StyledModal)`
  .wfp--modal-container {
    padding: ${({ padding }) => padding || "2.5rem 3rem 0 3rem"};
    ${({ minWidth }) =>
      minWidth &&
      css`
        ${{ minWidth }}
      `};
    ${({ maxWidth }) =>
      maxWidth &&
      css`
        ${{ maxWidth }}
      `};
    ${({ width }) =>
      width &&
      css`
        ${{ width }}
      `};
    ${({ background }) =>
      background &&
      css`
        ${{ background }}
      `};
    max-height: ${({ height }) => `${height}px` || "auto"};
    overflow: ${({ overflow }) => overflow || "scroll"};
    &::-webkit-scrollbar,
    &::before {
      display: none;
    }
    ${mediaTablet} {
      max-width: ${({ maxWidth }) => maxWidth || "none"};
    }
  }
  .wfp--modal-content {
    height: ${({ maxContentHeight }) => (maxContentHeight ? "100%" : "auto")};
    margin-bottom: ${({ maxContentHeight }) => (maxContentHeight ? 0 : "2rem")};
    ${mediaTablet} {
      height: calc(100% - 6rem);
    }
  }
  ${({ noHeader }) =>
    noHeader &&
    css`
      .wfp--modal-header {
        display: none;
      }
    `};
  ${({ noFooter }) =>
    noFooter &&
    css`
      .wfp--modal-footer {
        display: none;
      }
    `};
`;

export const ModalWithoutFooter = styled(StyledModal)`
  .wfp--modal-header {
    position: sticky;
    top: 0;
    margin: 0;
  }
  .wfp--modal-container {
    ${({ minWidth }) =>
      minWidth &&
      css`
        ${{ minWidth }}
      `};
    ${({ maxWidth }) =>
      maxWidth &&
      css`
        ${{ maxWidth }}
      `};
    border-radius: ${({ withBorderRadius }) => withBorderRadius};
    padding: ${({ padding }) => padding || "0 30px 30px"};
    max-height: ${({ height }) => height || "auto"};
    overflow: scroll;
    &::-webkit-scrollbar,
    &::before {
      display: none;
    }
  }
  .wfp--modal-footer {
    display: none;
  }
  .wfp--modal-content {
    margin-bottom: 0;
  }
  .wfp--modal-close {
    right: 0;
  }
`;

export const ModuleWhite = styled(StyledModule)`
  margin: ${({ margin }) => margin || "0"};
  padding: 0;
  .wfp--module__inner {
    background: #fff;
    padding: ${({ paddingContent }) => paddingContent || 0};
  }
`;

export const FormWizardHorizontal = styled(FormWizard)`
  flex-direction: column;
  background-color: var(--background-color);
  .wfp--step-navigation__nav {
    flex-direction: row;
    width: 670px;
    margin: 0 auto;
  }
`;

export const StepNavigationItemStyled = styled(StepNavigationItem)`
  flex-direction: column;
  flex: 1;
  &.wfp--step-navigation__nav-item--locked .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["ui-05"].hex};
  }
  &.wfp--step-navigation__nav-item--selected .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["text-01"].hex};
  }
  .wfp--step-navigation__nav-item__indicator {
    margin: 0 0 15px;
    span {
      font-weight: bold;
    }
    svg {
      width: 18px;
      height: 18px;
    }
    &::after {
      left: calc(-50% - 45px);
      top: -10px;
      transform: rotate(90deg);
      height: 60px;
    }
  }
`;

export const SecondaryNavigationLarger = styled(SecondaryNavigation)`
  .wfp--wrapper--width-lg {
    max-width: 100%;
  }
  /* @media only screen and (min-width: 768px) {
    .container {
      width: 100%;
    }
  } */
`;
