import { useState, useMemo, useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { InputGroup, RadioButton } from "@wfp/ui";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { ModalStyled } from "components/UIKitStyled";
import { FIELDS, INITIAL_VALUES, NEW_INGREDIENT, NEW_RECIPE_INITIAL_VALUES } from "containers/Results/constants";
import resultsMessages from "containers/Results/messages";
import { getPrices } from "containers/Results/utils";
import { useResponsiveHook } from "hooks";
import RecipeForm from "./RecipeForm";
import SelectExistingRecipe from "./SelectExistingRecipe";
import { validation, EXISTING, CREATE } from "../utils";

const CreateRecipe = ({ buttonText, showModal, toggleModal, foodItems, foodItemsOptions, onClose, onSubmit, day }) => {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();

  const { control, setValue, getValues } = useFormContext();
  const newRecipe = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}` });
  const existingRecipe = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}` });
  const {
    fields: ingredients,
    append,
    remove,
    replace,
  } = useFieldArray({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}.${FIELDS.INGREDIENTS}` });
  const availableRecipes = useWatch({ control, name: FIELDS.AVAILABLE_RECIPES });
  const selectedRecipeId = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.SELECTED_RECIPE}` });
  const dayRecipes = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}` });

  const hasAvailableRecipes = useMemo(() => {
    if (R.isEmpty(availableRecipes)) return false;

    const usedRecipes = availableRecipes.filter((recipe) =>
      dayRecipes.some((dayRecipe) => dayRecipe.recipe_id === recipe.value),
    );

    return !(usedRecipes.length === availableRecipes.length);
  }, [availableRecipes, dayRecipes]);

  const [choice, setChoice] = useState(hasAvailableRecipes ? EXISTING : CREATE);

  const validate = useMemo(
    () =>
      choice === CREATE
        ? validation(newRecipe, undefined, choice)
        : validation(existingRecipe, selectedRecipeId, choice),
    [choice, newRecipe, existingRecipe, selectedRecipeId],
  );

  // Filter out recipes already included in the day.
  const filteredAvailableRecipes = useMemo(
    () => availableRecipes.filter((recipe) => !dayRecipes.some((dayRecipe) => dayRecipe.recipe_id === recipe.value)),
    [availableRecipes, dayRecipes],
  );

  const addIngredient = useCallback(() => append(NEW_INGREDIENT), [append]);

  // Reset to default then close the modal.
  const handleClose = useCallback(() => {
    replace(NEW_RECIPE_INITIAL_VALUES[FIELDS.INGREDIENTS]);
    setChoice(hasAvailableRecipes ? EXISTING : CREATE);
    setValue(`${FIELDS.DRAFT}.${FIELDS.SELECTED_RECIPE}`, INITIAL_VALUES[FIELDS.SELECTED_RECIPE]);
    onClose();
  }, [hasAvailableRecipes, onClose, replace, setValue]);

  const handleChoiceChange = useCallback((e) => setChoice(e.target.value), []);

  const addRecipe = useCallback(() => {
    const values = getValues();

    if (choice === CREATE) {
      const newRecipe = values[FIELDS.DRAFT][FIELDS.NEW_RECIPE];

      const recipe = { recipe_id: uuidv4(), name: newRecipe[FIELDS.NAME], day };

      // Handle ingredients, structured as [{ item: 123, quantity: 100 }, {...}]
      const ingredients = newRecipe[FIELDS.INGREDIENTS].map((ingredient) => {
        const foodItem = foodItems.find((foodItem) => foodItem.food_item === ingredient.item);
        return {
          ...foodItem,
          quantity: ingredient.quantity,
          ...getPrices({ ...foodItem, quantity: ingredient.quantity }),
        };
      });

      onSubmit({ ...recipe, ingredients });
      handleClose();
    } else {
      const availableRecipes = values[FIELDS.AVAILABLE_RECIPES];
      const recipeId = values[FIELDS.DRAFT][FIELDS.SELECTED_RECIPE];

      const recipeName = availableRecipes.find((recipe) => recipe.value === recipeId).label;

      const recipe = { recipe_id: recipeId, name: recipeName, day };

      // Handle ingredients, structured as { "_7": {...}, "_8": {...} }
      const existingIngredients = values[FIELDS.DRAFT][FIELDS.EXISTING_RECIPE][FIELDS.INGREDIENTS];

      const ingredients = Object.keys(existingIngredients).reduce((acc, key) => {
        const numericKey = key.replace("_", "");
        const currentItem = existingIngredients[key];

        const recipe = availableRecipes.find((recipe) => recipe.value === recipeId);
        const basket = recipe.recipe_basket[numericKey].basket;

        const foodItem = basket.find((basketItem) => basketItem.display_name === currentItem.ingredient);

        return {
          ...acc,
          [numericKey]: {
            ...foodItem,
            quantity: currentItem.quantity,
            ...getPrices({ ...foodItem, quantity: currentItem.quantity }),
          },
        };
      }, {});

      // Added the "new" flag so we can distinguish between newly created recipe based on existing one and already persisted recipes.
      onSubmit({ ...recipe, ingredients, new: true });
      handleClose();
    }
  }, [choice, day, foodItems, getValues, handleClose, onSubmit]);

  return (
    <>
      <Button widthAuto marginInlineStart="5px" onClick={toggleModal} children={buttonText} />

      <ModalStyled
        overflow="visible"
        open={showModal}
        primaryButtonText={intl.formatMessage(buttonMessages.save)}
        secondaryButtonText={intl.formatMessage(buttonMessages.cancel)}
        minWidth={isTabletOrMobile ? "85%" : "55%"}
        modalHeading={buttonText}
        primaryButtonDisabled={validate}
        onRequestSubmit={addRecipe}
        onSecondarySubmit={handleClose}
        onRequestClose={handleClose}
      >
        {hasAvailableRecipes && (
          <InputGroup name="choice-group" labelText={intl.formatMessage(resultsMessages.existingOrNewRecipe)}>
            <RadioButton
              name="choice"
              value={EXISTING}
              id="radio-existing-recipe"
              labelText={intl.formatMessage(resultsMessages.existingRecipe)}
              checked={choice === EXISTING}
              onChange={handleChoiceChange}
            />
            <RadioButton
              name="choice"
              value={CREATE}
              id="radio-create-recipe"
              labelText={intl.formatMessage(resultsMessages.createRecipe)}
              checked={choice === CREATE}
              onChange={handleChoiceChange}
            />
          </InputGroup>
        )}

        {(!hasAvailableRecipes || choice === CREATE) && (
          <RecipeForm
            ingredients={ingredients}
            foodItems={foodItemsOptions}
            addIngredient={addIngredient}
            removeIngredient={remove}
          />
        )}

        {choice === EXISTING && <SelectExistingRecipe availableRecipes={filteredAvailableRecipes} />}
      </ModalStyled>
    </>
  );
};

export default CreateRecipe;
