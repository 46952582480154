import { FormattedMessage } from "react-intl";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";

const Actions = ({ middleScreen, goToMenuSourcing, goToNutrition, goToResults }) => {
  return (
    <RowStyled margin="40px -45px 0">
      <ColStyled xs={middleScreen ? 6 : 4} className="start-xs">
        <Button onClick={goToMenuSourcing}>
          <FormattedMessage {...messages.reviewSourcing} />
        </Button>
      </ColStyled>
      {goToNutrition && (
        <ColStyled xs={3} className="center-xs">
          <Button onClick={goToNutrition}>
            <FormattedMessage {...messages.reviewNutrition} />
          </Button>
        </ColStyled>
      )}
      <ColStyled xs={middleScreen ? 6 : 5} className="end-xs">
        <Button onClick={goToResults}>
          <FormattedMessage {...messages.resultsWithoutSourcing} />
        </Button>
      </ColStyled>
    </RowStyled>
  );
};

export default Actions;
