import { useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { TextInputHookForm, NumberInputHookForm, SelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import resultsMessages from "containers/Results/messages";
import { ModalContentContainer } from "../styles";

const RecipeForm = ({ ingredients, foodItems, addIngredient, removeIngredient }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  // Exclude ingredients already inside the recipe.
  const getFilteredOptions = useCallback(
    (index) => {
      if (R.isEmpty(foodItems)) return foodItems;
      const selectedIngredientsIds = ingredients.filter((_, i) => index !== i).map((ingredient) => ingredient.item);
      return foodItems.filter((foodItem) => !selectedIngredientsIds.includes(foodItem.value));
    },
    [foodItems, ingredients],
  );

  return (
    <ModalContentContainer>
      <RowStyled bottom="xs">
        <ColStyled xs={4}>
          <TextInputHookForm
            control={control}
            name={`${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}.${FIELDS.NAME}`}
            labelText={intl.formatMessage(resultsMessages.name)}
          />
        </ColStyled>
        <ColStyled xs={4}>
          <TextInputHookForm
            control={control}
            name={`${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}.${FIELDS.DESCRIPTION}`}
            labelText={intl.formatMessage(resultsMessages.description)}
          />
        </ColStyled>
        <ColStyled xs={4}>
          <Button widthAuto onClick={addIngredient} children={intl.formatMessage(resultsMessages.addIngredient)} />
        </ColStyled>
      </RowStyled>

      {ingredients.map((ingredient, i) => (
        <RowStyled key={ingredient.id} bottom="xs" margin="20px 0 0">
          <ColStyled xs={5}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}.${FIELDS.INGREDIENTS}.${i}.${FIELDS.ITEM}`}
              options={getFilteredOptions(i)}
              label={intl.formatMessage(resultsMessages.foodItem)}
            />
          </ColStyled>
          <ColStyled xs={5}>
            <NumberInputHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}.${FIELDS.INGREDIENTS}.${i}.${FIELDS.QUANTITY}`}
              labelText={intl.formatMessage(resultsMessages.portionSize)}
            />
          </ColStyled>
          <ColStyled xs={1}>
            <Icon
              className="pointer"
              description={intl.formatMessage(buttonMessages.remove)}
              icon={iconDelete}
              width="23"
              height="23"
              fill={colors["brand-01"].hex}
              onClick={() => removeIngredient(i)}
            />
          </ColStyled>
        </RowStyled>
      ))}
    </ModalContentContainer>
  );
};

export default RecipeForm;
