import { defineMessages } from "react-intl";

export default defineMessages({
  nutritionTitle: {
    id: "nutrition.title",
    defaultMessage: "Nutritional requirements",
  },
  min: {
    id: "label.min",
    defaultMessage: "Min",
  },
  max: {
    id: "label.max",
    defaultMessage: "Max",
  },
  absorbed: {
    id: "label.absorbed",
    defaultMessage: "Absorbed",
  },
});
