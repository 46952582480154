import { useSelector } from "react-redux";

import { selectLoadingFilters } from "containers/MyMenus/selectors";
import { InlineLoading } from "components";
import { GridContainer } from "./styles";
import Mode from "./Mode";
import Status from "./Status";
import Region from "./Region";
import Target from "./Target";
import DateRange from "./DateRange";

const Filters = () => {
  const loadingFilters = useSelector(selectLoadingFilters);

  if (loadingFilters) return <InlineLoading />;

  return (
    <GridContainer>
      <Mode />
      <Status />
      <Region />
      <Target />
      <DateRange />
    </GridContainer>
  );
};

export default Filters;
