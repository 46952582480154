import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { Field, useField } from "react-final-form";
import { useDispatch } from "react-redux";
import { colors } from "@wfp/ui";
import { Text } from "components";
import { Select } from "components/InputFields";
import { RowStyled, ColStyled, ReactSelectWithDataTestId } from "components/utils";
import { loadNutritionalReq } from "../../actions";
import NutritionalRequirements from "./NutritionalRequirements";
import messages from "../messages";
import { FIELDS } from "../../utils";

function TargetedGroup({ options, nutritionalRequirements, intl }) {
  const {
    input: { value },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.TARGET}`);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value) dispatch(loadNutritionalReq(value));
  }, [dispatch, value]);

  return (
    <ColStyled xs={5} margin="10px 0 40px" minwidth="360px" padding="0">
      <Text bold fontSize="20px" value={intl.formatMessage(messages.targeted)} />
      <Text
        wrap
        defaultColor={colors["text-02"].hex}
        marginBottom="5px"
        value={intl.formatMessage(messages.targetedInfo)}
      />
      <RowStyled middle="xs">
        <ColStyled xs padding="0">
          <Field
            name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.TARGET}`}
            placeholder="Choose an option"
            options={options}
            data-test-id="school-children-target"
            components={ReactSelectWithDataTestId}
            component={Select}
          />
        </ColStyled>
        <ColStyled xs={1}>
          <NutritionalRequirements nutritionalRequirements={nutritionalRequirements} />
        </ColStyled>
      </RowStyled>
    </ColStyled>
  );
}

export default injectIntl(TargetedGroup);
