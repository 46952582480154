import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "./constants";
import validation from "./validation";

const Footer = ({ restrictionAttributeName, name, close, save, daysInWeek, weekCount }) => {
  const { control } = useFormContext();
  const restriction = useWatch({ control, name: FIELDS.RESTRICTION });
  return (
    <RowStyled end="xs" flex={1}>
      <ColStyled>
        <Button widthAuto marginInlineEnd="10px" kind="secondary" onClick={close}>
          <FormattedMessage {...buttonMessages.cancel} />
        </Button>
        <Button
          widthAuto
          className={
            restrictionAttributeName !== "" ? validation[restrictionAttributeName](name, restriction, daysInWeek, weekCount) : "disable"
          }
          onClick={save}
        >
          <FormattedMessage {...buttonMessages.save} />
        </Button>
      </ColStyled>
    </RowStyled>
  );
};

export default Footer;
