import styled from "styled-components";

export const ContainerText = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  > {
    :nth-child(2) {
      margin-left: 10px;
    }
  }
`;
