import { useIntl } from "react-intl";
import { View, Text, Image } from "@react-pdf/renderer";
import generalStyles from "containers/PDF/styles";
import menuMessages from "containers/Menu/messages";
import compareStyles from "../styles";
import BasicSettings from "./BasicSettings";
import CostPerMeal from "./CostPerMeal";
import messages from "../../messages";

const PageOne = ({ infoMenus, imageUrlSourcing, language, conversionRate, localCurrency }) => {
  const intl = useIntl();
  const { header } = generalStyles;
  const { body, boxContent, boxHeader } = compareStyles;

  return (
    <>
      <Text style={[header, { border: 0 }]}>{intl.formatMessage(messages.headerCompare)}</Text>
      <View style={body}>
        <View style={boxContent}>
          {infoMenus.map(({ name, preliminary_information }, index) => (
            <BasicSettings
              key={`menu-${index}`}
              isLast={infoMenus.length === index + 1}
              name={name}
              {...preliminary_information}
            />
          ))}
        </View>
        <Text style={boxHeader}>{intl.formatMessage(menuMessages.costPerMeal)}</Text>
        <View style={[boxContent, { height: 115 }]}>
          {infoMenus.map(({ cost, cost_without_additionals }, index) => (
            <CostPerMeal
              key={`cost-${index}`}
              isLast={infoMenus.length === index + 1}
              cost={cost}
              fullPage={infoMenus.length === 4}
              language={language}
              withoutAdditional={cost_without_additionals}
              conversionRate={conversionRate}
              localCurrency={localCurrency}
            />
          ))}
        </View>
        <Text style={boxHeader}>{intl.formatMessage(menuMessages.sourcing)}</Text>
        <View style={[boxContent, { height: 190, padding: "0 3" }]}>
          {imageUrlSourcing && <Image style={{ objectFit: "contain" }} src={imageUrlSourcing} />}
        </View>
      </View>
    </>
  );
};

export default PageOne;
