import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { colors, Icon } from "@wfp/ui";
import { iconHeaderUser } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { RowStyled, newColorBySource } from "components/utils";
import {
  selectRateFromUSD,
  selectLocalCurrency,
  selectWeightChoices,
  selectFoodSources,
} from "containers/App/selectors";
import { FIELDS, INITIAL_VALUES } from "containers/FoodBasket/constants";
import { useResponsiveHook } from "hooks";
import TooltipContent from "./TooltipContent";
import { FoodItemContainer, FoodItemLabel, CustomTooltip } from "./styles";
import { Chip } from "components/ChipWithLabel/styles";

const FoodItem = ({ item, openModal }) => {
  const { isTabletOrMobile } = useResponsiveHook();
  const rateFromUSD = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);
  const units = useSelector(selectWeightChoices);
  const procurementSources = useSelector(selectFoodSources);

  const { control } = useFormContext();
  const formCurrency = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.CURRENCY}` });
  const formUnit = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.UNIT}` });

  // eg. "$ 1.30 - 7.00", prices for "1 Kg" (conversion_rate: 1000)
  // Switch to "1 oz" => 1000 : min_avg = 28.3495 : x => 1000 : 1.30 = 28.3495 : x => 0.03685435
  // Switch to "1 oz" => 1000 : max_avg = 28.3495 : x => 1000 : 7.00 = 28.3495 : x => 0.1984465
  const renderPrices = useCallback(
    ({ min_avg, max_avg }) => {
      const hasSamePrice = min_avg === max_avg;
      const defaultUnit = formUnit === INITIAL_VALUES[FIELDS.FILTERS][FIELDS.UNIT];

      if (defaultUnit) {
        // If USD, use incoming price. Otherwise, use currency data in order to calc local currency values
        if (formCurrency === "USD") {
          return hasSamePrice ? `$ ${min_avg.toFixed(2)}` : `$ ${min_avg.toFixed(2)} - ${max_avg.toFixed(2)}`;
        } else {
          const localMinAvg = (rateFromUSD * min_avg).toFixed(2);
          const localMaxAvg = (rateFromUSD * max_avg).toFixed(2);
          return hasSamePrice ? `${localCurrency} ${localMinAvg}` : `${localCurrency} ${localMinAvg} - ${localMaxAvg}`;
        }
      } else {
        // Calc prices when switching to a unit different from the default one (1 kg). We use conversion_rate property to do so
        const defaultConversionRate = units.find(
          (unit) => unit.value === INITIAL_VALUES[FIELDS.FILTERS][FIELDS.UNIT]
        ).conversion_rate;
        const selectedConversionRate = units.find((unit) => unit.value === formUnit).conversion_rate;
        const minAvgConverted = (min_avg * selectedConversionRate) / defaultConversionRate;
        const maxAvgConverted = (max_avg * selectedConversionRate) / defaultConversionRate;

        if (formCurrency === "USD") {
          return hasSamePrice
            ? `$ ${minAvgConverted.toFixed(2)}`
            : `$ ${minAvgConverted.toFixed(2)} - ${maxAvgConverted.toFixed(2)}`;
        } else {
          const localMinAvg = (rateFromUSD * minAvgConverted).toFixed(2);
          const localMaxAvg = (rateFromUSD * maxAvgConverted).toFixed(2);
          return hasSamePrice ? `${localCurrency} ${localMinAvg}` : `${localCurrency} ${localMinAvg} - ${localMaxAvg}`;
        }
      }
    },
    [formCurrency, formUnit, localCurrency, rateFromUSD, units]
  );

  const sources = useMemo(() => {
    const allSources = item.sources_informations;
    const uniqueSources = [...new Set(allSources?.map((item) => item.source))];
    return uniqueSources.map((item) => procurementSources.find((source) => source.value === item));
  }, [item, procurementSources]);

  return (
    <CustomTooltip
      createRefWrapper
      usePortal
      trigger="click"
      dark
      isTabletOrMobile={isTabletOrMobile}
      content={<TooltipContent item={item} openModal={openModal} />}
    >
      <FoodItemContainer between="xs" middle="xs">
        <RowStyled flex={1} middle="xs">
          <FoodItemLabel color={item.personal_price ? colors["support-03"].hex : colors["interactive-01"].hex}>
            {item.label}
          </FoodItemLabel>
          {item.personal_price && <Icon icon={iconHeaderUser} fill={colors["support-03"].hex} />}
        </RowStyled>
        <Text value={renderPrices(item)} />
        {sources.map(({ value }) => (
          <div key={value}>
            <Chip
              size="13px"
              backgroundColor={newColorBySource[value]}
              style={{ margin: value.length > 1 ? "0 -15px 0 7px" : "0 0 0 7px" }}
            />
          </div>
        ))}
      </FoodItemContainer>
    </CustomTooltip>
  );
};

export default FoodItem;
