import { defineMessages } from "react-intl";

export default defineMessages({
  usersHeader: {
    id: "admin.users.header",
    defaultMessage: "Admin",
  },
  usersSubHeader: {
    id: "admin.users.subHeader",
    defaultMessage: "Edit user details",
  },
  usersNoUsersFound: {
    id: "admin.users.noUsersFound",
    defaultMessage: "No users found",
  },
  email: {
    id: "admin.email",
    defaultMessage: "Email",
  },
  firstName: {
    id: "admin.firstName",
    defaultMessage: "First name",
  },
  lastName: {
    id: "admin.lastName",
    defaultMessage: "Last name",
  },
  country: {
    id: "admin.country",
    defaultMessage: "Country",
  },
  role: {
    id: "admin.role",
    defaultMessage: "Role",
  },
  state: {
    id: "admin.state",
    defaultMessage: "State",
  },
  staff: {
    id: "admin.staff",
    defaultMessage: "Staff",
  },
  active: {
    id: "admin.active",
    defaultMessage: "Active",
  },
  genericSuccess: {
    id: "admin.genericSuccess",
    defaultMessage: "Success",
  },
  userUpdated: {
    id: "admin.userUpdated",
    defaultMessage: "User successfully updated",
  },
  usersFirstTab: {
    id: "admin.users.firstTab",
    defaultMessage: "All Users",
  },
  staffYes: {
    id: "admin.staffYes",
    defaultMessage: "Yes",
  },
  staffNo: {
    id: "admin.staffNo",
    defaultMessage: "No",
  },
  stateAllUsers: {
    id: "admin.stateAllUsers",
    defaultMessage: "All Users",
  },
  stateActive: {
    id: "admin.stateActive",
    defaultMessage: "Active",
  },
  stateInactive: {
    id: "admin.stateInactive",
    defaultMessage: "Inactive",
  },
  activateUser: {
    id: "admin.activateUser",
    defaultMessage: "Activate",
  },
  deactivateUser: {
    id: "admin.deactivateUser",
    defaultMessage: "Deactivate",
  },
});
