import { useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { validate as uuidValidate } from "uuid";

import menuMessages from "containers/Menu/messages";
import {
  FIELDS,
  INITIAL_VALUES,
  NEW_ITEM_INITIAL_VALUES,
  NEW_RECIPE_INITIAL_VALUES,
} from "containers/Results/constants";
import { selectInformations } from "containers/Results/selectors";
import { getPrices } from "containers/Results/utils";
import { useBoolean } from "hooks";

export const useFoodItemsOptions = () => {
  const foodItems = useSelector(selectInformations);

  const foodItemsOptions = useMemo(() => {
    return foodItems.map(({ display_name, food_item, ...rest }) => ({
      label: display_name,
      value: food_item,
      ...rest,
    }));
  }, [foodItems]);

  return { foodItems, foodItemsOptions };
};

export const useDayCompositionData = ({ dayToShow, setDayToShow }) => {
  const intl = useIntl();
  const { control, getValues, setValue, reset } = useFormContext();

  const {
    fields: dayComposition,
    append: appendFood,
    remove: removeFood,
  } = useFieldArray({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_COMPOSITION}` });
  const {
    fields: dayRecipes,
    append: appendDayRecipe,
    remove: removeDayRecipe,
  } = useFieldArray({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}` });

  const newFoodItem = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}` });

  const { foodItems, foodItemsOptions } = useFoodItemsOptions();

  const selectedDay = useMemo(() => `day_${dayToShow}`, [dayToShow]);

  // When this mounts, fill our draft values with "official" form data. Then:
  // - if user saves, I'll persist all the edits made;
  // - if user doesn't save, I'll discard every update when closing this section.
  useEffect(() => {
    const values = getValues();
    const dayComposition = values[FIELDS.MENU_COMPOSITION][selectedDay];
    const dayRecipes = values[FIELDS.INCLUDED_RECIPES][selectedDay];
    const deletedRecipes = values[FIELDS.DELETED_RECIPES];
    const deletedFoodItems = values[FIELDS.DELETED];
    const dayMealName =
      values[FIELDS.MEAL_NAMES][dayToShow] ?? intl.formatMessage(menuMessages.day, { day: dayToShow });

    reset({
      ...values,
      [FIELDS.DRAFT]: {
        ...values[FIELDS.DRAFT],
        [FIELDS.DAY_MEAL_NAME]: dayMealName,
        [FIELDS.DAY_COMPOSITION]: dayComposition,
        [FIELDS.DAY_RECIPES]: dayRecipes,
        [FIELDS.DELETED_RECIPES]: deletedRecipes,
        [FIELDS.DELETED]: deletedFoodItems,
      },
    });
  }, [getValues, reset, intl, selectedDay, dayToShow]);

  // Handle "Add a new food item" modal status
  const [showItemModal, { toggle: toggleItemModal, setFalse: hideItemModal }] = useBoolean();

  // Handle "Create recipe" modal status
  const [showRecipeModal, { toggle: toggleRecipeModal, setFalse: hideRecipeModal }] = useBoolean();

  const onItemModalClose = useCallback(() => {
    setValue(`${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}`, NEW_ITEM_INITIAL_VALUES);
    hideItemModal();
  }, [hideItemModal, setValue]);

  const onRecipeModalClose = useCallback(() => {
    setValue(`${FIELDS.DRAFT}.${FIELDS.NEW_RECIPE}`, NEW_RECIPE_INITIAL_VALUES);
    setValue(`${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}`, INITIAL_VALUES[FIELDS.DRAFT][FIELDS.EXISTING_RECIPE]);
    setValue(`${FIELDS.DRAFT}.${FIELDS.SELECTED_RECIPE}`, INITIAL_VALUES[FIELDS.DRAFT][FIELDS.SELECTED_RECIPE]);
    hideRecipeModal();
  }, [hideRecipeModal, setValue]);

  const addNewFoodItem = useCallback(() => {
    const foodItem = foodItems.find((foodItem) => foodItem.food_item === newFoodItem[FIELDS.ITEM]);

    const additionalFoodItem = {
      ...foodItem,
      day: dayToShow,
      quantity: newFoodItem[FIELDS.QUANTITY],
      ...getPrices({ ...foodItem, quantity: newFoodItem[FIELDS.QUANTITY] }),
    };
    appendFood(additionalFoodItem);

    onItemModalClose();
  }, [appendFood, dayToShow, foodItems, newFoodItem, onItemModalClose]);

  // Restore initial FIELDS.DAY_COMPOSITION and FIELDS.DAY_RECIPES content, then close section
  const close = useCallback(() => {
    setValue(FIELDS.DRAFT, INITIAL_VALUES[FIELDS.DRAFT]);
    setDayToShow();
  }, [setDayToShow, setValue]);

  const save = useCallback(() => {
    const formValues = getValues();

    // Since quantities could be updated, we need to update all food item prices as well
    const updatedDayComposition = formValues[FIELDS.DRAFT][FIELDS.DAY_COMPOSITION].map((item) => ({
      ...item,
      ...getPrices(item),
    }));

    reset({
      ...formValues,
      // Updating MENU_COMPOSITION with data from DAY_COMPOSITION.
      [FIELDS.MENU_COMPOSITION]: { ...formValues[FIELDS.MENU_COMPOSITION], [selectedDay]: updatedDayComposition },

      // Updating INCLUDED_RECIPES with data from DAY_RECIPES.
      [FIELDS.INCLUDED_RECIPES]: {
        ...formValues[FIELDS.INCLUDED_RECIPES],
        [selectedDay]: formValues[FIELDS.DRAFT][FIELDS.DAY_RECIPES],
      },

      [FIELDS.DELETED_RECIPES]: formValues[FIELDS.DRAFT][FIELDS.DELETED_RECIPES],
      [FIELDS.DELETED]: formValues[FIELDS.DRAFT][FIELDS.DELETED],

      [FIELDS.MEAL_NAMES]: {
        ...formValues[FIELDS.MEAL_NAMES],
        [dayToShow]: formValues[FIELDS.DRAFT][FIELDS.DAY_MEAL_NAME],
      },

      [FIELDS.DRAFT]: INITIAL_VALUES[FIELDS.DRAFT],
    });
    setDayToShow();
  }, [getValues, reset, selectedDay, setDayToShow, dayToShow]);

  // Keep track of removed recipes ids in order to dispatch expected DELETE API calls when saving.
  const deleteRecipe = useCallback(
    (index) => {
      const recipe = dayRecipes[index];
      const recipeId = recipe.recipe_id;

      // This means the recipe was saved on BE, so we need to track its ID in order to dispatch related DELETE API call.
      if (!uuidValidate(recipeId) && !recipe.new) {
        const values = getValues();
        const draftDeletedRecipes = values[FIELDS.DRAFT][FIELDS.DELETED_RECIPES];
        setValue(`${FIELDS.DRAFT}.${FIELDS.DELETED_RECIPES}`, [...draftDeletedRecipes, recipeId]);
      }

      removeDayRecipe(index);
    },
    [dayRecipes, getValues, removeDayRecipe, setValue]
  );

  // Keep track of removed food items ids in order to dispatch expected DELETE API calls when saving.
  const deleteFoodItem = useCallback(
    (index) => {
      const foodItemId = dayComposition[index]._id;

      const values = getValues();
      const draftDeletedFoodItems = values[FIELDS.DRAFT][FIELDS.DELETED];

      setValue(`${FIELDS.DRAFT}.${FIELDS.DELETED}`, [...draftDeletedFoodItems, foodItemId]);
      removeFood(index);
    },
    [dayComposition, getValues, removeFood, setValue]
  );

  return useMemo(
    () => ({
      control,
      selectedDay,
      dayComposition,
      dayRecipes,
      deleteFoodItem,
      deleteRecipe,
      showItemModal,
      toggleItemModal,
      onItemModalClose,
      addNewFoodItem,
      showRecipeModal,
      toggleRecipeModal,
      foodItems,
      foodItemsOptions,
      onRecipeModalClose,
      appendDayRecipe,
      close,
      save,
    }),
    [
      addNewFoodItem,
      appendDayRecipe,
      close,
      control,
      dayComposition,
      dayRecipes,
      deleteFoodItem,
      deleteRecipe,
      foodItems,
      foodItemsOptions,
      onItemModalClose,
      onRecipeModalClose,
      save,
      selectedDay,
      showItemModal,
      showRecipeModal,
      toggleItemModal,
      toggleRecipeModal,
    ]
  );
};
