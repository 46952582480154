import { FOOD_RESTRICTION_TYPES } from "containers/OptimizedMenu/Rules/constants";
import { FIELDS } from "../constants";

export const makeItemRestrictionRequestBody = (restriction: any) => {
    const matchWith = restriction[FIELDS.MATCH_WITH].map((el: {value:any}) => el.value);
    const unmatchWith = restriction[FIELDS.UNMATCH_WITH].map((el: {value: any}) => el.value);
    let restrictionType = restriction[FIELDS.ITEM_RESTRICTION][FIELDS.RESTRICTION_TYPE];
    // If there is no restriction on the server api will return default values with
    // restrictionType set to null, So null value happens if there is no restriction and
    // include-exclude value is not changed.
    if (restrictionType == null) {
      restrictionType = FOOD_RESTRICTION_TYPES.DEFAULT
    }

    return {
      ...restriction,
      [FIELDS.MATCH_WITH]: matchWith,
      [FIELDS.UNMATCH_WITH]: unmatchWith,
      [FIELDS.ITEM_RESTRICTION]: { ...restriction[FIELDS.ITEM_RESTRICTION], [FIELDS.RESTRICTION_TYPE]: restrictionType },
    };
}

export const makeGroupRestrictionRequestBody = (restriction: any) => {
    // TODO find or create the types of restriction data here.
    const unmatchWith = restriction[FIELDS.UNMATCH_WITH].map((el: {value: any}) => el.value);
    const maxRepetition = restriction[FIELDS.GROUP_RESTRICTION][FIELDS.MAXIMUM_REPETITION];
    const enabledRepMax = restriction[FIELDS.GROUP_RESTRICTION][FIELDS.ENABLED_REP_MAX];
    let restrictionType = restriction[FIELDS.GROUP_RESTRICTION][FIELDS.RESTRICTION_TYPE];

    // If there is no restriction on the server api will return default values with
    // restrictionType set to null, So null value happens if there is no restriction and
    // include-exclude value is not changed.
    if (restrictionType == null) {
      restrictionType = FOOD_RESTRICTION_TYPES.DEFAULT
    }
    return {
      ...restriction,
      [FIELDS.UNMATCH_WITH]: unmatchWith,
      [FIELDS.GROUP_RESTRICTION]: {
        ...restriction[FIELDS.GROUP_RESTRICTION],
        [FIELDS.RESTRICTION_TYPE]: restrictionType,
        [FIELDS.MAXIMUM_REPETITION]: !maxRepetition || maxRepetition === "" ? null : maxRepetition,
        // Force enabled_rep_max to false if maxRepetition is null
        [FIELDS.ENABLED_REP_MAX]: (!maxRepetition || maxRepetition === "") && enabledRepMax ? false : enabledRepMax,
      },
    };
}
