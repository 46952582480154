import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { TextInputHookForm } from "components/Form";
import buttonMessages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import AddNewItem from "containers/Results/MenuComposition/AddNewItem";
import resultsMessages from "containers/Results/messages";
import FoodItem from "./FoodItem";
import Footer from "./Footer";
import Graph from "./Graph";
import { useDayCompositionData } from "./hooks";
import Recipe from "./Recipe";
import CreateRecipe from "./Recipe/CreateRecipe";
import Stats from "./Stats";
import {
  TitleContainer,
  ItemsListContainer,
  GraphAndStatsContainer,
  GraphAndStatsWrapper,
  DayCompositionContainer,
} from "./styles";

const DayComposition = ({ dayToShow, setDayToShow }) => {
  const intl = useIntl();

  const {
    control,
    selectedDay,
    dayComposition,
    dayRecipes,
    deleteFoodItem,
    deleteRecipe,
    showItemModal,
    toggleItemModal,
    onItemModalClose,
    addNewFoodItem,
    showRecipeModal,
    toggleRecipeModal,
    foodItems,
    foodItemsOptions,
    onRecipeModalClose,
    appendDayRecipe,
    close,
    save,
  } = useDayCompositionData({ dayToShow, setDayToShow });

  return (
    <DayCompositionContainer>
      <TitleContainer>
        <RowStyled middle="xs" center="xs" flex={1}>
          <ColStyled xs>
            <TextInputHookForm control={control} name={`${FIELDS.DRAFT}.${FIELDS.DAY_MEAL_NAME}`} />
          </ColStyled>
        </RowStyled>
      </TitleContainer>

      <RowStyled>
        <ItemsListContainer xs={6}>
          <RowStyled padding="10px" style={{ borderBottom: `1px solid ${colors["ui-04"].hex}` }}>
            <AddNewItem
              buttonText={intl.formatMessage(buttonMessages.addNewFoodItem)}
              specificDay
              showModal={showItemModal}
              toggleModal={toggleItemModal}
              onClose={onItemModalClose}
              onSubmit={addNewFoodItem}
            />
            <CreateRecipe
              buttonText={intl.formatMessage(resultsMessages.addRecipe)}
              showModal={showRecipeModal}
              toggleModal={toggleRecipeModal}
              foodItems={foodItems}
              foodItemsOptions={foodItemsOptions}
              onClose={onRecipeModalClose}
              onSubmit={appendDayRecipe}
              day={dayToShow}
            />
          </RowStyled>

          {dayComposition.map((item, index) => (
            <FoodItem
              key={item.id}
              remove={deleteFoodItem}
              fieldPrefix={`${FIELDS.DRAFT}.${FIELDS.DAY_COMPOSITION}`}
              draggable={false}
              index={index}
            />
          ))}

          {dayRecipes.map((recipe, index) => (
            <Recipe
              key={`recipe-${selectedDay}-${recipe.recipe_id}`}
              recipe={recipe}
              remove={deleteRecipe}
              index={index}
            />
          ))}

          <Footer close={close} save={save} />
        </ItemsListContainer>

        <GraphAndStatsContainer xs={6}>
          <RowStyled middle="xs" center="xs" style={{ height: "61px" }}>
            <Text
              bold
              regularWrap
              defaultColor={colors["text-02"].hex}
              value={intl.formatMessage(resultsMessages.dayAverageNutritionalValues, { day: dayToShow })}
            />
          </RowStyled>

          <GraphAndStatsWrapper>
            <Graph />
            <Stats />
          </GraphAndStatsWrapper>
        </GraphAndStatsContainer>
      </RowStyled>
    </DayCompositionContainer>
  );
};

export default DayComposition;
