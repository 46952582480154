import React from "react";

import Groups from "./Groups";
import Items from "./Items";
import { useMenuGeneralParameterValidations } from "containers/GeneralInfo/GeneralForm/hooks";

export default function NumberOfGroupsAndItems() {
  const { errors } = useMenuGeneralParameterValidations();
  return (
    <>
      <Groups errors={errors} />
      <Items errors={errors} />
    </>
  );
}
