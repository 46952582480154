import * as R from "ramda";

import { FIELDS } from "containers/Results/constants";

export const EXISTING = "existing";
export const CREATE = "create";

// Function to validate either newly created recipe or existing recipe selection.
export const validation = (recipe, selectedRecipeId, choice) => {
  if (choice === EXISTING) {
    if (R.isNil(selectedRecipeId) || R.isEmpty(recipe[FIELDS.INGREDIENTS])) return true;

    // Ingredients check
    const ingredients = recipe[FIELDS.INGREDIENTS];
    const failedValidation = Object.values(ingredients).some((ingredient) => {
      return (
        R.isNil(ingredient[FIELDS.INGREDIENT]) ||
        R.isEmpty(ingredient[FIELDS.QUANTITY]) ||
        ingredient[FIELDS.QUANTITY] === 0
      );
    });

    if (failedValidation) return true;
  } else {
    if (!recipe[FIELDS.NAME] || R.isEmpty(recipe[FIELDS.INGREDIENTS])) return true;

    // Ingredients check
    const ingredients = recipe[FIELDS.INGREDIENTS];
    const failedValidation = ingredients.some((ingredient) => {
      return (
        R.isEmpty(ingredient[FIELDS.ITEM]) ||
        R.isEmpty(ingredient[FIELDS.QUANTITY]) ||
        ingredient[FIELDS.QUANTITY] === 0
      );
    });

    if (failedValidation) return true;
  }
};
