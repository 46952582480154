import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { MultiSelectHookForm } from "components/Form";
import messages from "containers/OptimizedMenu/Rules/messages";
import { FIELDS } from "../constants";

const MatchOrUnmatch = ({ keyField, entity, name, options }) => {
  const { control } = useFormContext();

  const matchedItems = useWatch({ control, name: `${FIELDS.RESTRICTION}.${FIELDS.MATCH_WITH}` });
  const unmatchedItems = useWatch({ control, name: `${FIELDS.RESTRICTION}.${FIELDS.UNMATCH_WITH}` });

  const messageKey = useMemo(() => `${keyField}${entity}`, [entity, keyField]);

  const formFieldName = useMemo(() => {
    if (keyField === "unmatching") {
      return FIELDS.UNMATCH_WITH;
    } else {
      return FIELDS.MATCH_WITH;
    }
  }, [keyField]);

  const filteredOptions = useMemo(() => {
    if (formFieldName === FIELDS.MATCH_WITH) {
      // We are dealing with "must appear with" options, so we need to filter out selected options for "must NOT appear with".
      const unmatchedValues = unmatchedItems.map((el) => el.value);
      const filteredOptions = options.filter((option) => !unmatchedValues.includes(option.value));
      return filteredOptions;
    } else {
      // We are dealing with "must NOT appear with" options, so we need to filter out selected options for "must appear with".
      // We need to use (matchedItems || []) when dealing with GroupRestriction, since the API doesn't give us back "match_with" property.
      const matchedValues = (matchedItems || []).map((el) => el.value);
      const filteredOptions = options.filter((option) => !matchedValues.includes(option.value));
      return filteredOptions;
    }
  }, [formFieldName, matchedItems, options, unmatchedItems]);

  return (
    <>
      <Text bold marginBottom="10px" value={<FormattedMessage {...messages[messageKey]} values={{ name }} />} />
      <MultiSelectHookForm
        control={control}
        name={`${FIELDS.RESTRICTION}.${formFieldName}`}
        options={filteredOptions}
        helperText={<FormattedMessage {...messages.canChooseMultiple} />}
      />
    </>
  );
};

export default MatchOrUnmatch;
