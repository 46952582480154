import styled, { css } from "styled-components";
import { Row } from "react-flexbox-grid";
import { mediaTablet } from "components/utils";

export const StyledRow = styled(Row)`
  padding: 15px;
`;

export const ContainerLegend = styled.div`
  padding: 10px 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 177px;
  overflow-x: hidden;
  ${({ inPdf }) =>
    inPdf &&
    css`
      > * {
        margin-right: 10px;
      }
    `}
  ${mediaTablet} {
    padding: 10px 0 0;
  }
`;

export const ColLegend = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ContainerGraph = styled.div`
  height: 240px;
  display: flex;
  margin-top: 20px;
  > * {
    flex: 1;
  }
`;

export const ForeignObject = styled.foreignObject`
  width: 130px;
  height: 130px;
  color: ${({ fill }) => fill};

  > p {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
`;
