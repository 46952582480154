import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MODIFIED, MANUAL } from "containers/MyMenus/labels";
import { saveInResultsPdf } from "containers/PDF/actions";
import { selectMenuOptimizationType } from "containers/Menu/selectors";

export default function PDFResults({ graphValues, NutritionalValuesComponent, CostPerChildAndNumbersComponent }) {
  const dispatch = useDispatch();
  const type = useSelector(selectMenuOptimizationType);

  const isNotManualOrModified = type !== MODIFIED && type !== MANUAL;

  const requirementsNoMet = useMemo(
    () => graphValues.every(({ percentage, restriction }) => percentage === restriction || percentage > restriction),
    [graphValues],
  );

  // Nutritional
  useEffect(() => {
    dispatch(
      saveInResultsPdf({
        nutrients: graphValues,
        reqNotMet: !requirementsNoMet && isNotManualOrModified,
      }),
    );
  }, [dispatch, graphValues, isNotManualOrModified, requirementsNoMet]);

  return (
    <div className="visuallyHidden">
      {NutritionalValuesComponent()}
      {CostPerChildAndNumbersComponent()}
    </div>
  );
}
