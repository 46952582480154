import React from "react";
import { useIntl } from "react-intl";
import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled } from "components/utils";

const BackAndContinueButtons = ({ onClickBack, onClickContinue }) => {
  const intl = useIntl();

  return (
    <RowStyled middle="xs" end="xs">
      <Button widthAuto kind="secondary" onClick={onClickBack} children={intl.formatMessage(buttonMessages.back)} />
      <Button
        widthAuto
        kind="primary"
        onClick={onClickContinue}
        children={intl.formatMessage(buttonMessages.continue)}
      />
    </RowStyled>
  );
};

export default BackAndContinueButtons;
