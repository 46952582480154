import { useCallback, useEffect, useMemo } from "react";
import { useTable, usePagination, useFlexLayout } from "react-table";

import Pagination from "./Pagination";
import { NoResults, TableWrapper } from "./styles";

const Table = ({
  manual,
  pointer,
  columns,
  data,
  _page,
  pageSize: controlledPageSize,
  totalItems,
  loadData,
  onClickRow,
  loading,
  pageSizeOptions = [5, 10, 15],
  controlledPageCount = -1,
  initialState = { pageIndex: 0, pageSize: controlledPageSize },
  selectedClass,
  paginationClassName = "",
  noPageRange,
  noPagesText,
  overflowId,
}) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    gotoPage,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFlexLayout,
    usePagination,
  );

  useEffect(() => {
    loadData(pageIndex + 1, pageSize);
  }, [loadData, pageIndex, pageSize]);

  // TODO: Check along the way if future tables still need this
  // useEffect(() => {
  //   setPageSize(controlledPageSize);
  // }, [setPageSize, controlledPageSize]);

  const onChange = useCallback(
    (e) => {
      if (e.pageSize !== pageSize) {
        setPageSize(e.pageSize);
        !manual && gotoPage(0);
      } else {
        if (manual) {
          loadData(e.page, e.pageSize);
        } else {
          gotoPage(e.page - 1);
        }
      }
    },
    [gotoPage, loadData, manual, pageSize, setPageSize],
  );

  const paginationProps = {
    page: _page,
    onChange,
    pageSize,
    totalItems,
    noPageRange,
    noPagesText,
    pageSizeOptions,
    className: paginationClassName,
  };

  return (
    <>
      <TableWrapper>
        <div {...getTableProps()} className="table">
          <div {...getTableBodyProps()} className="tbody">
            {page.length > 0 ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...cell.getCellProps({
                            style: {
                              overflow: cell.column.id === overflowId ? "visible" : "hidden",
                              cursor: pointer ? "pointer" : "auto",
                            },
                          })}
                          className={`td ${selectedClass && selectedClass(row)}`}
                          onClick={() => onClickRow && onClickRow(row, cell)}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div className="tr">
                <div className="td">
                  <NoResults>No Results</NoResults>
                </div>
              </div>
            )}
          </div>
          {/* Loading overlay */}
          <div className={`-loading ${loading ? "-active" : ""}`}>
            <div className="-loading-inner">Loading...</div>
          </div>
        </div>
      </TableWrapper>
      <Pagination {...paginationProps} />
    </>
  );
};

export default Table;
