import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SubNavigation, SubNavigationItem, SubNavigationHeader } from "@wfp/ui";

import { Text, Button } from "components";
import { selectProfile, selectFirstName } from "containers/App/selectors";
import SelectCountry from "./SelectCountry";
import messages from "./messages";

const Content = ({ mainNavigationRef, userIsInactive }) => {
  const intl = useIntl();
  const firstName = useSelector(selectFirstName);
  const profile = useSelector(selectProfile);

  const onLogout = useCallback(() => {
    const idToken = JSON.parse(localStorage.getItem("token")).id_token;
    localStorage.removeItem("token");
    const path = `${process.env.CIAM_LOGOUT_URL}?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.BASE_URL}`;
    window.open(path, "_self");
  }, []);

  return (
    <SubNavigation>
      <SubNavigationHeader>
        <Text bold wrap fontSize="18px" value={intl.formatMessage(messages.welcome, { name: firstName })} />
        <SubNavigationItem>
          <Button onClick={onLogout} children={intl.formatMessage(messages.logout)} />
        </SubNavigationItem>
      </SubNavigationHeader>
      {!userIsInactive && <SelectCountry profile={profile} mainNavigationRef={mainNavigationRef} />}
    </SubNavigation>
  );
};

export default Content;
