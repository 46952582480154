import { useMemo } from "react";
import { useIntl } from "react-intl";

import { loadVamMappings } from "containers/Admin/actions";

import { allVamMappingsFields } from "./constants";

import FiltersTemplate from "containers/Admin/Countries/Filters";

const Filters = () => {
  const intl = useIntl();
  const filterFields = useMemo(() => allVamMappingsFields(intl), [intl]);

  return <FiltersTemplate fields={filterFields} actionFunction={loadVamMappings} />;
};

export default Filters;
