import React, { useCallback, useMemo } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import * as R from "ramda";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { SubNavigationContent } from "@wfp/ui";
import { Text, Button } from "components";
import { ColStyled } from "components/utils";
import { setSelectedCountry } from "containers/App/actions";
import routesPath from "containers/App/Routes/paths";
import CountryLink from "./CountryLink";
import messages from "../messages";
import countryMessages from "containers/SelectCountry/messages";

function SelectCountry({ profile, history, mainNavigationRef, intl }) {
  const dispatch = useDispatch();

  const selectAnotherCountry = useCallback(() => {
    dispatch(setSelectedCountry(false));
    history.push(routesPath.selectCountry);
    mainNavigationRef.current.onChangeSub("close");
  }, [dispatch, history, mainNavigationRef]);

  const previousCountries = useMemo(() => R.pathOr([], ["previous_countries"], profile), [profile]);

  return (
    <>
      {profile && !history.location.pathname.includes("select_country") && (
        <SubNavigationContent>
          <ColStyled width="322px">
            <Text
              fontSize="16px"
              bold
              wrap
              value={intl.formatMessage(messages.usingCountry, { country: R.pathOr("", ["country_name"], profile) })}
            />
            {!!previousCountries.length && (
              <>
                <Text wrap value={intl.formatMessage(messages.previousCountries)} />
                {previousCountries.map(
                  (country, i) =>
                    !!i && (
                      <CountryLink
                        key={country.country}
                        mainNavigationRef={mainNavigationRef}
                        history={history}
                        {...country}
                      />
                    ),
                )}
              </>
            )}
            <Button
              marginTop="15px"
              kind="secondary"
              onClick={selectAnotherCountry}
              children={intl.formatMessage(countryMessages.anotherCountry)}
            />
          </ColStyled>
        </SubNavigationContent>
      )}
    </>
  );
}

export default compose(withRouter, injectIntl)(SelectCountry);
