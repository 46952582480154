import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

type AlertProps = {
  type: string,
  title: {id: string, defaultMessage: string},
  text: {id: string, defaultMessage: string},
}

export const loadStarted = createAction("@@app/LOAD_STARTED");
export const loadEnded = createAction("@@app/LOAD_ENDED");
export const loadSmallStarted = createAction("@@menu/LOAD_SMALL_STARTED");
export const loadSmallEnded = createAction("@@menu/LOAD_SMALL_ENDED");
export const loadGeneralAppInfoStarted = createAction("@@menu/LOAD_GENERAL_APP_INFO_STARTED");
export const loadGeneralAppInfoEnded = createAction("@@menu/LOAD_GENERAL_APP_INFO_ENDED");
export const loadAccount = createAction("@@app/LOAD_ACCOUNT");
export const loadEssentialInfo = createAction("@@app/LOAD_ESSENTIALS");
export const loadGeneralAppInfo = createAction("@@app/LOAD_GENERAL_APP_INFO");
export const saveAccount = createAction("@@app/SAVE_ACCOUNT");
export const loginRedirect = createAction("@@app/LOGIN_REDIRECT");

export const loadRegions = createAction("@@app/LOAD_REGIONS");
export const loadRegionsSuccess = createAction("@@app/LOAD_REGIONS_SUCCESS");
export const loadPriceSourcesSuccess = createAction("@@app/LOAD_PRICE_SOURCES_SUCCESS");
export const loadConstantsSuccess = createAction("@@app/LOAD_CONSTANTS_SUCCESS");
export const loadCurrencySuccess = createAction("@@app/LOAD_CURRENCY_SUCCESS");
export const loadAccountSuccess = createAction("@@app/LOAD_ACCOUNT_SUCCESS");
export const loadGlobalFeaturesSuccess = createAction("@@app/LOAD_GLOBAL_FEATURES_SUCCESS");
export const setSelectedCountry = createAction("@@app/SET_SELECTED_COUNTRY");

export const setAlert: ActionCreatorWithPayload<AlertProps> = createAction("@@app/SET_ERROR");
export const clearAlert = createAction("@@app/CLEAR_ERROR");

export const validEmail = createAction("@@menu/VALID_EMAIL");

export const loadAvailableNutrientsSuccess = createAction("@@app/LOAD_AVAILABLE_NUTRIENTS_SUCCESS");
export const loadFoodSourcesSuccess = createAction("@@app/LOAD_FOOD_SOURCES_SUCCESS");

export const loadAllNutritionalValuesStarted = createAction("@@app/LOAD_NUTRITIONAL_VALUES_STARTED");
export const loadAllNutritionalValuesSuccess = createAction("@@app/LOAD_NUTRITIONAL_VALUES_SUCCESS");

export const loadFoodItems = createAction("@@app/LOAD_FOOD_ITEMS");
export const loadFoodItemsSuccess = createAction("@@app/LOAD_FOOD_ITEMS_SUCCESS");

export const loadingRegionsStarted = createAction("@@app/LOADING_REGIONS_STARTED");
export const loadingRegionsEnded = createAction("@@app/LOADING_REGIONS_ENDED");
