import React from "react";
import { colors } from "@wfp/ui";

export const Fruits = ({ size, disable }) => (
  <svg
    width={size || "23px"}
    height={size || "20px"}
    viewBox="0 0 23 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-219.000000, -497.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(219.000000, 497.000000)">
          <path d="M19.0823721,3.91194615 C20.5810824,3.45168974 20.9981836,2.22285385 21.8817349,1.79820513 C20.1366618,1.07298205 19.1145519,0.892005128 18.0800978,1.38920769 C17.2762926,1.73317088 16.69773,2.4652451 16.5415545,3.33597692 C17.2325591,3.9623187 18.1942983,4.18033205 19.0823721,3.91194615 Z" />
          <path d="M15.8518376,6.47223077 C16.0615624,4.22721326 15.3481715,1.99397951 13.8803205,0.300497436 C13.8544899,0.272221049 13.8182906,0.256028788 13.7802903,0.255753008 C13.74229,0.255477227 13.705866,0.271142437 13.6796392,0.299041026 L13.2700042,0.725451282 C13.2187065,0.778163333 13.2167797,0.86229714 13.2656089,0.917369231 C14.5670172,2.46244875 15.185597,4.48207241 14.9764955,6.50330513 C11.3824068,4.36729487 7.60341582,6.70326667 7.77951747,10.5803615 C7.81499427,11.2533335 7.91663982,11.9210279 8.08294055,12.5735026 C10.1174725,14.2963282 12.6737077,15.4806282 15.7523209,16.1015744 C16.1184065,16.1719951 16.4581772,16.3437143 16.7340923,16.597759 C17.0183549,16.8673025 17.1847811,17.2408232 17.1964176,17.6353846 L17.2078974,18.017541 C17.2148671,18.2456805 17.1839307,18.4733826 17.1163852,18.6911026 C17.0832353,18.8356106 17.0393856,18.9773753 16.9852093,19.1151897 C19.9814775,19.2646462 22.819177,14.4300821 22.9940807,10.5803462 C23.1692901,6.72291282 19.4294825,4.39207436 15.8518376,6.47223077 Z" />
          <path d="M16.0261245,17.3759103 C15.8916836,17.2538639 15.7263573,17.172271 15.5487229,17.1403 C9.48795747,15.9180308 4.38554396,12.4108256 2.27619121,5.16551538 C2.22548244,5.01756394 2.15405108,4.87778528 2.0640933,4.75047692 C1.96275856,4.58318172 1.91315326,4.38892335 1.92167527,4.19275641 L1.96944451,2.81877692 C1.97298207,2.71733359 1.93554663,2.61882155 1.8658018,2.5460392 C1.79605696,2.47325685 1.70004789,2.43251264 1.59999374,2.4332359 L0.883050879,2.43833846 C0.778776439,2.43909181 0.679738139,2.48479825 0.610661799,2.56404676 C0.541585459,2.64329526 0.508990003,2.74860693 0.521013187,2.85368974 L0.689595604,4.32748718 C0.693804293,4.54311345 0.624038563,4.75352514 0.49223033,4.9227359 C0.302820455,5.30669828 0.220696353,5.73595882 0.254771758,6.16392564 C0.109391538,17.7085846 7.80180473,21.0750487 14.9031887,19.2985821 C15.8061308,19.0726846 15.8903765,18.9248103 15.9679244,18.816341 C16.0328702,18.6859502 16.0807577,18.5475154 16.1103727,18.4045462 C16.1497783,18.2906767 16.1680993,18.1703994 16.1644126,18.0497744 L16.152857,17.6700744 C16.1511752,17.5587527 16.1055175,17.4527747 16.0261245,17.3759103 Z" />
        </g>
      </g>
    </g>
  </svg>
);
