import { StyleSheet, Font } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";
import OpenSansBold from "./fonts/OpenSans-Bold.ttf";
import OpenSansSemiBold from "./fonts/OpenSans-SemiBold.ttf";
import OpenSansRegular from "./fonts/OpenSans-Regular.ttf";

Font.register({
  family: "OpenSans",
  fonts: [
    {
      src: OpenSansBold,
      fontStyle: "normal",
      fontWeight: "bold",
    },
    {
      src: OpenSansSemiBold,
      fontStyle: "normal",
      fontWeight: "semibold",
    },
    {
      src: OpenSansRegular,
      fontStyle: "normal",
      fontWeight: "normal",
    },
  ],
});

export default StyleSheet.create({
  page: {
    padding: "25 25 40",
    display: "flex",
  },
  row: {
    width: "100%",
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
  },
  fontSize12: {
    fontSize: 12,
    fontFamily: "OpenSans",
    fontWeight: "semibold",
  },
  fontSize10: {
    fontSize: 10,
    fontFamily: "OpenSans",
    fontWeight: "normal",
  },
  fontSize9: {
    fontSize: 9,
    fontFamily: "OpenSans",
    fontWeight: "normal",
  },
  header: {
    borderBottom: 1,
    paddingBottom: 10,
    borderColor: colors["ui-04"].hex,
    fontFamily: "OpenSans",
    fontWeight: "semibold",
    fontSize: 20,
  },
  description: {
    fontSize: 10,
    fontFamily: "OpenSans",
    fontWeight: "normal",
    color: colors["text-02"].hex,
    marginTop: 3,
  },
  pagination: {
    position: "absolute",
    bottom: 25,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    margin: "0 20",
    color: colors["text-01"].hex,
    justifyContent: "space-between",
    fontSize: 10,
  },
});
