import { useCallback, useMemo } from "react";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { CardStyled } from "./styles";

const Card = ({ image, title, info, value, setType, type }) => {
  const onClick = useCallback(() => {
    setType(value);
  }, [setType, value]);

  const isSelected = useMemo(() => value === type, [type, value]);

  return (
    <CardStyled xs onClick={onClick} isSelected={isSelected}>
      {image}
      <Text fontSize="28px" transform="uppercase" value={title} bold />
      <Text marginTop="15px" fontSize="16px" value={info} bold defaultColor={colors["text-02"].hex} wrap />
    </CardStyled>
  );
};

export default Card;
