import React, { useState, useEffect, useCallback, useRef } from "react";
import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Loading, Blockquote } from "@wfp/ui";
import { Layout, Text } from "components";
import { withAuth } from "hocs";
import { RowStyled, ColStyled } from "components/utils";
import { resetMenu } from "containers/Menu/actions";
import messages from "containers/Menu/nutMessages";
import {
  selectMealCount,
  selectMenuOptimizationType,
  selectMenuName,
  selectDaysInWeek,
  selectWeekCount,
  selectMenuNotes,
} from "containers/Menu/selectors";
import {
  loadCommunityResults,
  updateFormState,
  resetResults,
  saveAllCommunityResults,
} from "containers/Results/actions";
import CostPerChildAndNumbers from "./CostPerChildAndNumbers";
import NutritionalValues from "./NutritionalValues";
import MenuComposition from "./MenuComposition";
import { SUBOPTIMAL } from "../MyMenus/labels";
import Header from "./Header";
import Notes from "./Notes";
import { initial, calcDisableSave, calcGraphValues } from "./utils";
import {
  selectLoading,
  selectFoodItems,
  selectInformations,
  selectNutRequirements,
  selectNutRestrictions,
  selectLoadingNutritionalValues,
} from "containers/Results/selectors";
import { useResponsiveHook } from "hooks";
import PDFResults from "./PDFResults";
import {
  selectPermissions,
  selectNutritionalValues,
  selectLoadingAllNutritionalValues,
} from "containers/App/selectors";
import { PERMISSIONS } from "containers/Admin/constants";
import { checkPermission } from "components/Unlock/utils";

function Results() {
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();
  const { id: menuId } = useParams();
  const mealCount = useSelector(selectMealCount) || 1;
  const type = useSelector(selectMenuOptimizationType);
  const nameMenu = useSelector(selectMenuName);
  const notesMenu = useSelector(selectMenuNotes);

  const loadingResults = useSelector(selectLoading);
  const loadingNutritionalValues = useSelector(selectLoadingNutritionalValues);
  const loadingAllNutritionalValues = useSelector(selectLoadingAllNutritionalValues);
  const nutrientRestrictions = useSelector(selectNutRestrictions);
  const selectedFoods = useSelector(selectFoodItems);
  const nutritionalRequirements = useSelector(selectNutRequirements);
  const nutritionalValues = useSelector(selectNutritionalValues);
  const optionsFood = useSelector(selectInformations);
  const daysInWeek = useSelector(selectDaysInWeek);
  const weekCount = useSelector(selectWeekCount);
  const userPermissions = useSelector(selectPermissions);

  const [initialValues, setInitialValues] = useState({});
  const [toggleIndex, setToggleIndex] = useState(0);
  const [redirectUser, setRedirectUser] = useState();

  const initRef = useRef({});
  initRef.current = { mealCount, selectedFoods };

  const updateForm = useCallback(
    (state) => {
      // https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
      setTimeout(() => dispatch(updateFormState(state)), 0);
    },
    [dispatch],
  );

  // If we have no permission to view the page, we redirect user to not found page
  useEffect(() => {
    if (userPermissions.length !== 0) {
      setRedirectUser(!checkPermission(userPermissions, PERMISSIONS.VIEW_COMMUNITY_MENU));
    }
  }, [userPermissions]);

  useEffect(() => {
    window.scroll(0, 0);

    // If we don't have yet user permissions, do not fetch
    if (userPermissions.length !== 0 && redirectUser === false) {
      dispatch(loadCommunityResults({ id: menuId }));
    }

    return () => {
      dispatch(resetResults());
      dispatch(resetMenu());
    };
  }, [dispatch, menuId, userPermissions.length, redirectUser]);

  useEffect(() => {
    if (!selectedFoods) return;
    if (initRef) {
      setInitialValues(initial(mealCount, selectedFoods));
    }
  }, [initRef, mealCount, selectedFoods]);

  // Global "Save" button
  const onSubmit = useCallback(
    (values) => {
      const data = R.omit(["draft", "foods", "draftFoods", "filters"], values);

      const body = {
        selected_food_items: R.flatten(Object.values(data)),
        foods: values.foods,
      };

      dispatch(saveAllCommunityResults({ id: menuId, body }));
    },
    [dispatch, menuId],
  );

  if (redirectUser) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Layout>
      <Loading active={loadingResults || loadingNutritionalValues || loadingAllNutritionalValues} />
      {!loadingResults && !loadingAllNutritionalValues && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={arrayMutators}
          render={({ values, handleSubmit }) => {
            const allValues = R.flatten(
              Object.keys(values)
                .filter((el) => el !== "draft" && el !== "foods" && el !== "draftFoods")
                .map((el) => values[el]),
            );
            const disableSave = calcDisableSave(initialValues, values);
            const graphValues = calcGraphValues(
              allValues,
              nutritionalValues,
              nutritionalRequirements,
              nutrientRestrictions,
              mealCount,
            );

            return (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }} onChange={updateForm} />
                {nameMenu && (
                  <Header
                    name={nameMenu}
                    id={menuId}
                    enableButtons={!disableSave || R.isEmpty(allValues)}
                    enableAddEditButton={!R.isEmpty(values.draftFoods)}
                    loading={!loadingResults}
                    toggleIndex={toggleIndex}
                    setToggleIndex={setToggleIndex}
                    values={values}
                    handleSubmit={handleSubmit}
                  />
                )}
                {R.equals(type, SUBOPTIMAL) && (
                  <Blockquote margin="20px 0 0" kind="warning">
                    <Text fontSize="18px" wrap value={<FormattedMessage {...messages.warningNutrients} />} />
                  </Blockquote>
                )}
                <RowStyled margin={isTabletOrMobile ? 0 : "10px 0 0"} top="xs">
                  {!R.isEmpty(allValues) && toggleIndex === 1 && (
                    <>
                      <ColStyled xs={8}>
                        <NutritionalValues graphValues={graphValues} multipleRows={isTabletOrMobile} />
                      </ColStyled>
                      <ColStyled xs={4}>
                        <CostPerChildAndNumbers values={allValues} />
                      </ColStyled>
                    </>
                  )}
                  {toggleIndex === 0 && (
                    <MenuComposition
                      menuId={menuId}
                      values={values}
                      optionsFood={optionsFood}
                      daysInWeek={daysInWeek}
                      weekCount={weekCount}
                      graphValues={graphValues}
                    />
                  )}
                  <Notes menuId={menuId} notes={notesMenu || ""} />
                </RowStyled>

                <PDFResults
                  graphValues={graphValues}
                  NutritionalValuesComponent={() => <NutritionalValues graphValues={graphValues} />}
                  CostPerChildAndNumbersComponent={() => <CostPerChildAndNumbers values={allValues} />}
                />
              </form>
            );
          }}
        />
      )}
    </Layout>
  );
}

export default withAuth(Results);
