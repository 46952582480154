import { defineMessages } from "react-intl";

export default defineMessages({
  countriesHeader: {
    id: "admin.countries.header",
    defaultMessage: "Countries",
  },
  countriesSubHeader: {
    id: "admin.countries.subHeader",
    defaultMessage: "Edit country preferences",
  },
  countriesFirstTab: {
    id: "admin.countries.firstTab",
    defaultMessage: "Nutrients",
  },
  countriesSecondTab: {
    id: "admin.countries.secondTab",
    defaultMessage: "Food Composition Tables",
  },
  countriesThirdTab: {
    id: "admin.countries.thirdTab",
    defaultMessage: "Priority items",
  },
  countriesFourthTab: {
    id: "admin.countries.fourthTab",
    defaultMessage: "VAM Food Prices",
  },
  countriesFifthTab: {
    id: "admin.countries.fifthTab",
    defaultMessage: "Price approval",
  },
  countriesSixthTab: {
    id: "admin.countries.sixthTab",
    defaultMessage: "Food Database",
  },
  countriesSeventhTab: {
    id: "admin.countries.seventhTab",
    defaultMessage: "Recipes",
  },
  genericSuccess: {
    id: "admin.genericSuccess",
    defaultMessage: "Success",
  },
  nutrientsUpdated: {
    id: "admin.nutrientsUpdated",
    defaultMessage: "Nutrients successfully updated",
  },
  nutrientsTitle: {
    id: "admin.nutrientsTitle",
    defaultMessage: "Nutrients list",
  },
  nutrientsOptimizedSubTitle: {
    id: "admin.nutrientsOptimizedSubTitle",
    defaultMessage: "Note: subset of nutrients must include at least 5 nutrients",
  },
  nutrientsCommunitySubTitle: {
    id: "admin.nutrientsCommunitySubTitle",
    defaultMessage: "Note: subset of nutrients must include between 5 and 8 nutrients",
  },
  optimizedNutrientsDescription: {
    id: "admin.optimizedNutrientsDescription",
    defaultMessage: "Select desired Optimized/Manual nutrients",
  },
  communityNutrientsDescription: {
    id: "admin.communityNutrientsDescription",
    defaultMessage: "Select desired Community nutrients",
  },
  sourcesUpdated: {
    id: "admin.sourcesUpdated",
    defaultMessage: "Food Composition Tables successfully updated",
  },
  sourcesTitle: {
    id: "admin.sourcesTitle",
    defaultMessage: "Food Composition Tables list",
  },
  sourcesSubTitle: {
    id: "admin.sourcesSubTitle",
    defaultMessage: "Note: subset of food composition tables must include at least 1 food composition table",
  },
  optimizedSourcesDescription: {
    id: "admin.optimizedSourcesDescription",
    defaultMessage: "Select desired Optimized/Manual food composition tables",
  },
  communitySourcesDescription: {
    id: "admin.communitySourcesDescription",
    defaultMessage: "Select desired Community food composition tables",
  },
  sourcesFirstSecondaryTab: {
    id: "admin.sourcesFirstSecondaryTab",
    defaultMessage: "Optimized/Manual menus",
  },
  sourcesSecondSecondaryTab: {
    id: "admin.sourcesSecondSecondaryTab",
    defaultMessage: "Community menu",
  },
  communityNutrientsLevelTitle: {
    id: "admin.communityNutrientsLevelTitle",
    defaultMessage: "Nutrients level list",
  },
  communityNutrientsLevelDescription: {
    id: "admin.communityNutrientsLevelDescription",
    defaultMessage: "Select desired Community nutrients level",
  },
  nutrientsLevelUpdated: {
    id: "admin.nutrientsLevelUpdated",
    defaultMessage: "Nutrients level successfully updated",
  },
  nutrientsFirstSecondaryTab: {
    id: "admin.nutrientsFirstSecondaryTab",
    defaultMessage: "Optimized/Manual menus",
  },
  nutrientsSecondSecondaryTab: {
    id: "admin.nutrientsSecondSecondaryTab",
    defaultMessage: "Community Menu",
  },
  nutrientsThirdSecondaryTab: {
    id: "admin.nutrientsThirdSecondaryTab",
    defaultMessage: "Default Nutrients - Community menu",
  },
  priorityItemsTitle: {
    id: "admin.priorityItemsTitle",
    defaultMessage: "Priority Items",
  },
  priorityItemsDescription: {
    id: "admin.priorityItemsDescription",
    defaultMessage: "Select desired Priority Items",
  },
  addPriorityItem: {
    id: "admin.addPriorityItem",
    defaultMessage: "Add priority item",
  },
  foodItem: {
    id: "admin.foodItem",
    defaultMessage: "Food item",
  },
  recipeName: {
    id: "admin.recipeName",
    defaultMessage: "Recipe name",
  },
  authorName: {
    id: "admin.authorName",
    defaultMessage: "Author name",
  },
  typeToSearch: {
    id: "admin.typeToSearch",
    defaultMessage: "Type to search...",
  },
  regionsPlaceholder: {
    id: "admin.regionsPlaceholder",
    defaultMessage: "Whole country (default)",
  },
  regions: {
    id: "admin.regions",
    defaultMessage: "Regions",
  },
  priorityItemsUpdated: {
    id: "admin.priorityItemsUpdated",
    defaultMessage: "Priority items successfully updated",
  },
  vamMappingsTitle: {
    id: "admin.vamMappingsTitle",
    defaultMessage: "VAM Food Prices",
  },
  vamMappingsUpdated: {
    id: "admin.vamMappingsUpdated",
    defaultMessage: "VAM mappings successfully updated",
  },
  commodity: {
    id: "admin.commodity",
    defaultMessage: "Commodity",
  },
  description: {
    id: "admin.description",
    defaultMessage: "Description",
  },
  allFoodPricesApproved: {
    id: "admin.allFoodPricesApproved",
    defaultMessage: "All food prices successfully approved",
  },
  foodPriceApproved: {
    id: "admin.foodPriceApproved",
    defaultMessage: "Food price successfully updated",
  },
  foodPriceRejected: {
    id: "admin.foodPriceRejected",
    defaultMessage: "Food price successfully rejected",
  },
  priceStatusAllPricesStatus: {
    id: "admin.priceStatusAllPricesStatus",
    defaultMessage: "All Prices",
  },
  priceStatusProposed: {
    id: "admin.priceStatusProposed",
    defaultMessage: "Proposed",
  },
  priceStatusOfficial: {
    id: "admin.priceStatusOfficial",
    defaultMessage: "Official",
  },
  priceStatusRejected: {
    id: "admin.priceStatusRejected",
    defaultMessage: "Rejected",
  },
  user: {
    id: "admin.user",
    defaultMessage: "User",
  },
  source: {
    id: "admin.source",
    defaultMessage: "Source",
  },
  quantity: {
    id: "admin.quantity",
    defaultMessage: "Quantity",
  },
  addAdditionalItem: {
    id: "admin.addAdditionalItem",
    defaultMessage: "Add additional item",
  },
  newRecipe: {
    id: "admin.newRecipe",
    defaultMessage: "New recipe",
  },
  adminRecipeCreate: {
    id: "admin.adminRecipeCreated",
    defaultMessage: "Recipe successfully created",
  },
  adminRecipeUpdated: {
    id: "admin.adminRecipeUpdated",
    defaultMessage: "Recipe successfully updated",
  },
  foodItemAttributeDescription: {
    id: "foodItem.attributeDescription",
    defaultMessage: "Attribute Description",
  },
  foodItemLocalTranslations: {
    id: "foodItem.localTranslations",
    defaultMessage: "Local Translations",
  },
  foodItemLocalTranslationsUpdated: {
    id: "foodItem.foodItemLocalTranslationsUpdated",
    defaultMessage: "Local Translations Updated",
  },
});
