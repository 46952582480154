import { defineMessages } from "react-intl";

export default defineMessages({
  min: {
    id: "label.min",
    defaultMessage: "Min",
  },
  max: {
    id: "label.max",
    defaultMessage: "Max",
  },
  optionalStep: {
    id: "sourcing.optionalStep",
    defaultMessage: "This step is optional.",
  },
  description: {
    id: "sourcing.description",
    defaultMessage:
      "If needed, you can define how much of the food basket (estimated in monetary value) should come from an available procurement source. For example, you can limit the % of food coming from an international source, or set up a minimum % of food coming from a SHF (small holder farm).",
  },
  viewFoodBasket: {
    id: "sourcing.viewFoodBasket",
    defaultMessage: "View food basket sorted by procurement source",
  },
  addRequirement: {
    id: "sourcing.addRequirement",
    defaultMessage: "Add requirement",
  },
  advancedSettings: {
    id: "advancedSetting.title",
    defaultMessage: "Advanced settings",
  },
  addAnotherRequirement: {
    id: "sourcing.addAnotherRequirement",
    defaultMessage: "Add another requirement",
  },
  selectSourcing: {
    id: "sourcing.selectSourcing",
    defaultMessage: "Select a sourcing channel",
  },
  setLimit: {
    id: "sourcing.setLimit",
    defaultMessage: "Set min/max limit for the food items sourced from the chosen channel",
  },
  advancedDescription: {
    id: "sourcing.advancedDescription",
    defaultMessage: "You can further limit the sourcing of a food group from a sourcing channel.",
  },
  selectFoodGroup: {
    id: "sourcing.selectFoodGroup",
    defaultMessage: "Select a food group",
  },
  selectGroupLimit: {
    id: "sourcing.selectGroupLimit",
    defaultMessage: "Set minimum or maximum limit",
  },
  addFoodGroup: {
    id: "sourcing.addFoodGroup",
    defaultMessage: "Add food group",
  },
  addAnotherFoodGroup: {
    id: "sourcing.addAnotherFoodGroup",
    defaultMessage: "Add another food group",
  },
  invalidSumOfMin: {
    id: "sourcing.invalidSumOfMin",
    defaultMessage: "Invalid constraints: the sum of minimums cannot be greater than 100",
  },
  invalidSumOfMax: {
    id: "sourcing.invalidSumOfMax",
    defaultMessage: "Invalid constraints: the sum of maximums should be equal or greater than 100",
  },
  invalidSumOfMinGroups: {
    id: "sourcing.invalidSumOfMinGroups",
    defaultMessage: "Invalid constraints: the sum of minimums for {group} cannot be greater than 100",
  },
  invalidSumOfMaxGroups: {
    id: "sourcing.invalidSumOfMaxGroups",
    defaultMessage: "Invalid constraints: the sum of maximums for {group} should be equal or greater than 100",
  },
});
