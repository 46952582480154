import { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import {
  selectProfile,
  selectPriceSources,
  selectAvailableNutrients,
  selectFoodSources,
} from "containers/App/selectors";
import { selectFormValues, selectFoodBasket, selectLoading } from "./GeneralForm/selectors";
import validation from "./GeneralForm/validation";
import { FIELDS } from "./GeneralForm/utils";

export const useInitialValues = () => {
  const profile = useSelector(selectProfile);
  const priceSources = useSelector(selectPriceSources);
  const availableNutrients = useSelector(selectAvailableNutrients);
  const foodSources = useSelector(selectFoodSources);
  const loading = useSelector(selectLoading);

  const isLoadingData = useMemo(
    () => loading || R.isEmpty(priceSources) || !profile || !availableNutrients || !foodSources,
    [availableNutrients, foodSources, loading, priceSources, profile]
  );

  return useMemo(() => {
    if (isLoadingData) return {};

    const initialCheckboxes = priceSources.reduce(
      (acc, item) => ({
        ...acc,
        [item.value]: item.checkboxes.reduce((checks, el) => ({ ...checks, [el.value]: true }), {}),
      }),
      {}
    );

    const initialFoodSources = foodSources.reduce(
      (acc, item) => ({
        ...acc,
        [item.value]: true,
      }),
      {}
    );

    return {
      preliminary_information: {
        regions: [],
        sourcing_channels: [],
        start_month: 1,
        end_month: 12,
        seasonality: "y",
        days_in_week: 5,
        week_count: "1",
        target_group: 4,
        price_source_validity: null,
        include_whole_country: true,
        country: R.pathOr(null, ["country"], profile),
        food_sources: initialFoodSources,
      },
      menu_pricing_list: initialCheckboxes,
      nutritional_requirements: availableNutrients,
    };
  }, [priceSources, availableNutrients, profile, foodSources, isLoadingData]);
};

export const useValidateGeneralForm = () => {
  const values = useSelector(selectFormValues);
  const basket = useSelector(selectFoodBasket);
  return !R.isEmpty(validation(values, basket)[FIELDS.PRELIMINARY_INFO]);
};
