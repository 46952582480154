import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { selectFoodSources } from "containers/App/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import AddPriceSource from "./AddPriceSource";
import Header from "./Header";
import PriceSources from "./PriceSources";

const PageTwo = ({ foodItemIndex, setStep, openAddPriceSource, setOpenAddPriceSource }) => {
  const { control } = useFormContext();
  const basket = useWatch({ control, name: FIELDS.BASKET });
  const draftItem = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.ITEM}` });

  const procurementSources = useSelector(selectFoodSources);

  // If we are in food item detail, we need to show/hide the "Add" button depending on the amount of available sources.
  const hasAvailableSources = useMemo(() => {
    const amountOfSelectedSources = Object.values(draftItem[FIELDS.PRICES]).length;
    const amountOfAvailableSources = procurementSources.length;
    return amountOfSelectedSources === amountOfAvailableSources ? false : true;
  }, [draftItem, procurementSources.length]);

  return (
    <>
      <Header item={draftItem} setStep={setStep} setOpenAddPriceSource={setOpenAddPriceSource} />
      {openAddPriceSource && <AddPriceSource item={draftItem} setOpenAddPriceSource={setOpenAddPriceSource} />}
      <PriceSources
        initialItem={basket[foodItemIndex]}
        item={draftItem}
        hasAvailableSources={hasAvailableSources}
        setOpenAddPriceSource={setOpenAddPriceSource}
        setStep={setStep}
      />
    </>
  );
};

export default PageTwo;
