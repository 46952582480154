import { useMemo, useCallback } from "react";
import { useController } from "react-hook-form";
import { colors, Icon } from "@wfp/ui";
import { iconCaretDown } from "@wfp/icons";

import { UIAsyncSelect } from "./styles";

const AsyncSelectHookForm = ({
  control,
  name,
  label,
  isLoading,
  defaultOptions = [],
  wfpStyle,
  className = "",
  customOnChange,
  loadOptions,
  styles,
  ...rest
}) => {
  const { field } = useController({ name, control });

  const selectedValue = useMemo(
    () => defaultOptions.find((el) => el.value === field.value) ?? "",
    [field.value, defaultOptions],
  );

  const components = useMemo(
    () =>
      wfpStyle
        ? {
            DropdownIndicator: () => <Icon fill={colors["brand-01"].hex} icon={iconCaretDown} description="" />,
            IndicatorSeparator: () => null,
          }
        : {},
    [wfpStyle],
  );

  const handleChange = useCallback(
    (el) => {
      if (customOnChange) {
        customOnChange(el, field);
      } else {
        field.onChange(el.value);
      }
    },
    [customOnChange, field],
  );

  return (
    <div className="wfp--form-item">
      {label && (
        <label htmlFor={name} className="wfp--label">
          {label}
        </label>
      )}
      <UIAsyncSelect
        {...rest}
        id={name}
        onChange={handleChange}
        wfpStyle={wfpStyle}
        components={components}
        className={`wfp--react-select-container ${className}`}
        classNamePrefix="wfp--react-select"
        value={selectedValue}
        isLoading={isLoading}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        styles={styles}
      />
    </div>
  );
};

export default AsyncSelectHookForm;
