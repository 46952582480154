import React from "react";
import { newColorBySource } from "components/utils";
import { ForeignObject } from "./styles";

export default function LabelInside({ label, source, width, height }) {
  const xLabel = ((width || 0) - 130) / 2;
  const yLabel = ((height || 0) - 130) / 2;
  return (
    <ForeignObject x={xLabel} y={yLabel} fill={newColorBySource[source]}>
      <p>{label}</p>
    </ForeignObject>
  );
}
