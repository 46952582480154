import React from "react";
import { colors } from "@wfp/ui";

export const Fish = ({ size, disable }) => (
  <svg
    width={size || "24px"}
    height={size || "20px"}
    viewBox="0 -3 28 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-217.000000, -461.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(217.000000, 461.000000)">
          <path d="M27.9832054,12.3728325 C27.9742308,12.3348224 27.0029839,8.59882484 27.0029839,6.49926423 C27.0029839,4.40070389 27.9742308,0.663706071 27.9832054,0.625695922 C28.0230923,0.47565586 27.981211,0.315613126 27.8864796,0.192580275 C27.7448809,0.00753086508 27.4666696,-0.053485427 27.2582603,0.0505423496 C23.1877985,2.10108987 21.1396043,6.10415873 21.0538474,6.27420414 C20.9820509,6.41624206 20.9820509,6.58328667 21.0538474,6.72432432 C21.1386071,6.89336946 23.1877985,10.8964383 27.2582603,12.9479861 C27.3280624,12.9829955 27.4227939,13 27.5005734,13 C27.6551353,13 27.8007226,12.9279808 27.8954541,12.8049479 C27.9911828,12.6829153 28.0230923,12.5228726 27.9832054,12.3728325 Z" />
          <path d="M11.7531511,0 C10.0920014,0 8.4989398,0.277 7,0.761 C8.06437743,2.239 8.69919896,4.045 8.69919896,6 C8.69919896,7.955 8.06437743,9.761 7,11.239 C8.4989398,11.723 10.0920014,12 11.7531511,12 C16.7366003,12 21.1613264,9.647 24,6 C21.1613264,2.353 16.7366003,0 11.7531511,0 Z" />
          <path d="M6.324,1 C3.902,1.99282787 1.773,3.57581967 0.132,5.60040984 C-0.044,5.81762295 -0.044,6.18237705 0.132,6.39959016 C1.773,8.42520492 3.902,10.0071721 6.324,11 C7.371,9.6147541 8,7.88319672 8,6 C8,4.11680328 7.371,2.3852459 6.324,1 Z M4.5,6 C3.948,6 3.5,5.54098361 3.5,4.97540984 C3.5,4.40983607 3.948,3.95081967 4.5,3.95081967 C5.052,3.95081967 5.5,4.40983607 5.5,4.97540984 C5.5,5.54098361 5.052,6 4.5,6 Z" />
        </g>
      </g>
    </g>
  </svg>
);
