import messages from "./messages";

/** Map tutorial with a specific key fixed on the backend */
export const TUTORIAL_IDS = {
  MENUS: "menus",
  FOOD_PRICE: "price_database",
  RESULTS: "results",
  COMPARE: "compare",
  GENERAL: "general_info",
  STRUCTURE: "structure",
  NUTRITION: "nutrition",
  SOURCING: "sourcing",
  SUMMARY: "summary",
  COMMUNITY_TRAINING: "community_training",
};

/** Map tutorial with the first part of the url in which rendered it */
export const mapTutorials = {
  menus: "",
  price_database: "food_prices",
  results: "results",
  compare: "menu",
  general_info: ["create", "edit"],
  structure: ["create", "edit"],
  nutrition: ["create", "edit"],
  sourcing: ["create", "edit"],
  summary: ["create", "edit"],
  community_training: ["create", "community"],
};

export const downloadDoc = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const intlTabs = (intl) => [
  intl.formatMessage(messages.videosLabel),
  intl.formatMessage(messages.faqLabel),
];
