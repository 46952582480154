import * as R from "ramda";
import { call, put, takeLatest, select, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { setAlert } from "containers/App/actions";
import {
  getUsers,
  getSingleUser,
  patchSingleUser,
  getUserGroups,
  getAdminCountriesPreferences,
  getAdminConstants,
  patchAdminCountriesPreferences,
  getVamMappings,
  getAllFoodSimple,
  patchVamMapping,
  deleteVamMapping,
  getAdminFoodPrices,
  approveAdminFoodPrices,
  postApproveFoodPrice,
  postRejectFoodPrice,
  patchFoodPrice,
  getAdminFoodItems,
  patchAdminFoodItemsCustomTranslations,
  getAdminRecipes,
  getSingleAdminRecipe,
  patchSingleAdminRecipe,
  postSingleAdminRecipe,
} from "api/api";
import {
  loadUsers,
  loadSuccess,
  loadStarted,
  loadEnded,
  loadSingleUser,
  loadSingleUserSuccess,
  updateSingleUser,
  loadUserGroups,
  loadUserGroupsSuccess,
  updateTableParams,
  loadCountriesSliceStarted,
  loadCountriesSliceEnded,
  loadSelectedSources,
  loadSelectedSourcesSuccess,
  loadAllSources,
  loadAllSourcesSuccess,
  updateSources,
  loadSelectedCommunityNutrientsLevel,
  loadSelectedCommunityNutrientsLevelSuccess,
  loadAllCommunityNutrientsLevel,
  loadAllCommunityNutrientsLevelSuccess,
  updateNutrientsLevel,
  loadSelectedNutrients,
  loadSelectedNutrientsSuccess,
  loadAllNutrients,
  loadAllNutrientsSuccess,
  updateNutrients,
  loadPriorityItems,
  loadPriorityItemsSuccess,
  updatePriorityItems,
  loadVamMappings,
  loadVamMappingsSuccess,
  loadFoodItems,
  loadFoodItemsSuccess,
  updateVamMappings,
  initAdminPriceApprovalManage,
  loadFoodPricesSuccess,
  approveFoodPriceRequest,
  rejectFoodPriceRequest,
  approveAllFoodPricesRequest,
  loadFoodPricesRequest,
  showApproveAllButton,
  resetTableParams,
  resetTableFilters,
  resetFoodItems,
  loadAdminFoodItems,
  loadAdminFoodItemsSuccess,
  updateAdminFoodItemCustomTranslations,
  storeAdminFoodItemsParams,
  loadAdminRecipes,
  loadAdminRecipesSuccess,
  initAdminRecipeManage,
  initAdminRecipeManageStarted,
  initAdminRecipeManageEnded,
  loadSingleAdminRecipe,
  loadSingleAdminRecipeSuccess,
  updateSingleAdminRecipe,
  createSingleAdminRecipe,
  initAdminVamFoodPricesManage,
  initAdminVamFoodPricesManageStarted,
  initAdminVamFoodPricesManageEnded,
} from "./actions";
import { initialState } from "./reducer";
import {
  selectTableParams,
  selectTableFilters,
  selectFormValues,
  selectMenuType,
  selectSelectedSources,
  selectPriorityItems,
  selectVamMappings,
  selectAdminFoodItemsParams,
} from "./selectors";
import {
  loadRegionsSaga,
  loadCurrencySaga,
  loadConstantsSaga,
} from "containers/App/sagas";
import {
  selectCountryIso3,
  selectCountryCode,
  selectRegions,
} from "containers/App/selectors";
import { reworkData } from "./Users/utils";
import { DEFAULT_NUTRIENT_PERCENTAGE } from "./Countries/constants";
import { PRICE_STATUS } from "./Countries/PriceApproval/Filters/constants";
import { getPropertyName, reworkPriorityItems } from "./Countries/utils";
import {
  reworkFoodPrices,
  feToBeReworkFoodPrice,
  reworkFoodPricesParamsAndFilters,
  canEditFoodPrice,
} from "./Countries/PriceApproval/utils";
import usersMessages from "./Users/messages";
import countriesMessages from "./Countries/messages";

export function* loadUsersSaga({ payload: { params } }) {
  try {
    yield put(loadStarted());
    const tableFilters = yield select(selectTableFilters);
    const tableParamsAndFilters = reworkData({ ...params, ...tableFilters });
    const { results, count } = yield call(getUsers, tableParamsAndFilters);
    yield put(loadSuccess({ results, count }));
    yield put(updateTableParams({ params }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadSingleUserSaga({ payload: { userId } }) {
  try {
    yield put(loadStarted());
    const user = yield call(getSingleUser, userId);
    yield put(loadSingleUserSuccess({ user }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* updateSingleUserSaga({ payload: { user, body, prevPath } }) {
  try {
    yield put(loadStarted());
    const reworkedBody = reworkData(body);
    yield call(patchSingleUser, user.id, { ...user, ...reworkedBody });
    yield put(loadEnded());
    yield put(
      setAlert({
        type: "success",
        title: usersMessages.genericSuccess,
        text: usersMessages.userUpdated,
      })
    );

    if (prevPath) {
      yield put(push(prevPath));
    } else {
      const tableParams = yield select(selectTableParams);
      yield put(loadUsers({ payload: { params: tableParams } }));
    }
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadUserGroupsSaga() {
  try {
    yield put(loadStarted());
    const userGroups = yield call(getUserGroups);
    yield put(loadUserGroupsSuccess({ userGroups }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadSelectedSourcesSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const iso3 = yield select(selectCountryIso3);
    const { source_include } = yield call(getAdminCountriesPreferences, iso3);
    yield put(loadSelectedSourcesSuccess({ sources: source_include }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadAllSourcesSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const { sources } = yield call(getAdminConstants);
    const reworkedSources = sources.map((source) => ({
      ...source,
      checked: false,
    }));
    yield put(loadAllSourcesSuccess({ sources: reworkedSources }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateSourcesSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const { sources } = yield select(selectFormValues);
    const iso3 = yield select(selectCountryIso3);
    const menuType = yield select(selectMenuType);
    const initialSources = yield select(selectSelectedSources);

    // Rework new selection of sources, then take into account all initial sources of different type
    const reworkedSources = sources
      .filter((source) => source.checked)
      .map((source) => ({ type: menuType, source: source.value }));
    const filteredInitialSources = initialSources.filter(
      (source) => source.type !== menuType
    );

    yield call(patchAdminCountriesPreferences, iso3, {
      source_include: [...filteredInitialSources, ...reworkedSources],
    });
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.sourcesUpdated,
      })
    );

    // Reload selected sources
    yield put(loadSelectedSources());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadSelectedCommunityNutrientsLevelSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const iso3 = yield select(selectCountryIso3);
    const { community_nutrients, recommended_nutrients } = yield call(
      getAdminCountriesPreferences,
      iso3
    );
    yield put(
      loadSelectedCommunityNutrientsLevelSuccess({
        selectedNutrients: recommended_nutrients,
      })
    );
    yield put(
      loadSelectedNutrientsSuccess({ selectedNutrients: community_nutrients })
    );
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadAllCommunityNutrientsLevelSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const { recommended_nutrients } = yield call(getAdminConstants);
    yield put(
      loadAllCommunityNutrientsLevelSuccess({
        allNutrients: recommended_nutrients,
      })
    );
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateNutrientsLevelSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const { community_nutrients_level } = yield select(selectFormValues);
    const iso3 = yield select(selectCountryIso3);

    const reworkedNutrientsLevel = community_nutrients_level
      .filter((nutrient) => nutrient.pct !== DEFAULT_NUTRIENT_PERCENTAGE)
      .map((nutrient) => ({ nutrient: nutrient.value, pct: nutrient.pct }));

    yield call(patchAdminCountriesPreferences, iso3, {
      recommended_nutrients: reworkedNutrientsLevel,
    });
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.nutrientsLevelUpdated,
      })
    );

    // Reload selected nutrients level
    yield put(loadSelectedCommunityNutrientsLevel());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadSelectedNutrientsSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const menuType = yield select(selectMenuType);
    const iso3 = yield select(selectCountryIso3);

    // Distinguish between menu_type in order to get correct property from response
    const countriesPreferences = yield call(getAdminCountriesPreferences, iso3);
    const property = getPropertyName(menuType);
    const nutrients = countriesPreferences[property];

    yield put(loadSelectedNutrientsSuccess({ selectedNutrients: nutrients }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadAllNutrientsSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const menuType = yield select(selectMenuType);

    // Distinguish between menu_type in order to get correct property from response
    const countriesConstants = yield call(getAdminConstants);
    const property = getPropertyName(menuType);
    const nutrients = countriesConstants[property];
    const reworkedNutrients = nutrients.map((nutrient) => ({
      ...nutrient,
      checked: false,
    }));

    yield put(loadAllNutrientsSuccess({ allNutrients: reworkedNutrients }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateNutrientsSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const menuType = yield select(selectMenuType);
    const { nutrients } = yield select(selectFormValues);
    const iso3 = yield select(selectCountryIso3);

    // Distinguish between menu_type in order to send correct property to BE
    const property = getPropertyName(menuType);
    const reworkedNutrients = nutrients
      .filter((nutrient) => nutrient.checked)
      .map((nutrient) => nutrient.value);

    yield call(patchAdminCountriesPreferences, iso3, {
      [property]: reworkedNutrients,
    });
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.nutrientsUpdated,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadPriorityItemsSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const iso3 = yield select(selectCountryIso3);
    const { priority_items } = yield call(getAdminCountriesPreferences, iso3);
    yield put(loadPriorityItemsSuccess({ priorityItems: priority_items }));
  } catch (e) {
    yield put(setAlert(e));
  }
  yield put(loadCountriesSliceEnded());
}

export function* updatePriorityItemsSaga() {
  try {
    yield put(loadCountriesSliceStarted());
    const { priority_items } = yield select(selectFormValues);
    const originalPriorityItems = yield select(selectPriorityItems);
    const iso3 = yield select(selectCountryIso3);

    const priorityItems = reworkPriorityItems(
      priority_items,
      originalPriorityItems
    );

    yield call(patchAdminCountriesPreferences, iso3, {
      priority_items: priorityItems,
    });
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.priorityItemsUpdated,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadVamMappingsSaga({ payload: { params } = {} }) {
  try {
    yield put(loadCountriesSliceStarted());
    if (R.isNil(params) || R.isEmpty(params)) {
      params = yield select(selectTableParams);
    }
    const tableFilters = yield select(selectTableFilters);
    const tableParamsAndFilters = { ...params, ...tableFilters };
    const { results, count } = yield call(
      getVamMappings,
      tableParamsAndFilters
    );

    const vamFoodItems = results.map((vamMapping) => vamMapping.item);
    yield put(loadVamMappingsSuccess({ results, count, vamFoodItems }));
    yield put(updateTableParams({ params }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(setAlert(e));
  }
  yield put(loadCountriesSliceEnded());
}

export function* loadFoodItemsSaga() {
  try {
    const foodItems = yield call(getAllFoodSimple);
    yield put(loadFoodItemsSuccess({ foodItems: foodItems }));
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* updateVamMappingsSaga() {
  try {
    yield put(loadCountriesSliceStarted());

    const initialVamMappings = yield select(selectVamMappings);
    const { vam_mappings: formVamMappings } = yield select(selectFormValues);

    // Make sure objects share the same shape
    const reworkedInitialVamMappings = initialVamMappings.map((mapping) => ({
      vam_code: mapping.vam_code,
      value: mapping.vam_commodity.id,
      label: mapping.vam_commodity.description,
      item: mapping.item
        ? { value: mapping.item.id, label: mapping.item.display_name }
        : null,
    }));

    let diffs = [];
    reworkedInitialVamMappings.forEach((mapping, i) => {
      if (!R.equals(mapping.item, formVamMappings[i].item)) {
        diffs.push({
          vam_code: mapping.vam_code,
          item_id: mapping.item ? mapping.item.value : null,
          content: formVamMappings[i].item,
        });
      }
    });

    // Check for "content" property: if it's empty, we deleted a food item mapping. If it's not, we changed an existing mapping
    let promises = [];
    diffs.forEach((diff) => {
      if (R.isEmpty(diff.content)) {
        promises.push(call(deleteVamMapping, diff.vam_code));
      } else {
        const body = { item_id: diff.content.value };
        promises.push(call(patchVamMapping, diff.vam_code, body));
      }
    });
    yield all(promises);

    // Clear table params and refresh data
    yield put(resetTableParams());
    yield call(loadVamMappingsSaga, { payload: { params: {} } });

    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.vamMappingsUpdated,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* patchAndApproveFoodPrice(foodPrice) {
  try {
    yield call(patchFoodPrice, foodPrice.id, foodPrice);
    yield call(postApproveFoodPrice, foodPrice.id);
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateFoodPrice(foodPrice) {
  try {
    yield call(patchFoodPrice, foodPrice.id, foodPrice);
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* approveFoodPriceSaga({ payload: { foodPrice } }) {
  try {
    yield put(loadCountriesSliceStarted());

    const regions = yield select(selectRegions);
    const { value, label, ...region } = regions.find(
      (region) => region.value === foodPrice.region
    );
    const reworkedFoodPrice = feToBeReworkFoodPrice(foodPrice);
    const reworkedData = {
      ...reworkedFoodPrice,
      region: { id: value, region: label, ...region },
    };
    yield call(patchAndApproveFoodPrice, reworkedData);

    // Reload foodPrices list right after approval
    yield call(_loadFoodPrices, initialState.params);
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.foodPriceApproved,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* rejectFoodPriceSaga({
  payload: { foodPriceId },
}: {
  payload: { foodPriceId: number };
}) {
  try {
    yield put(loadCountriesSliceStarted());
    yield call(postRejectFoodPrice, foodPriceId);
    // Reload foodPrices list right after rejection
    yield call(_loadFoodPrices, initialState.params);
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.foodPriceRejected,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* approveAllFoodPricesSaga({ payload: { foodPrices } }) {
  try {
    yield put(loadCountriesSliceStarted());

    const regions = yield select(selectRegions);

    // Remove official/rejected food prices.
    const filteredFoodPrices = foodPrices.filter((foodPrice) =>
      canEditFoodPrice(foodPrice.price_status)
    );

    yield all(
      filteredFoodPrices.map((foodPrice) => {
        const { value, label, ...region } = regions.find(
          (region) => region.value === foodPrice.region
        );
        const reworkedFoodPrice = feToBeReworkFoodPrice(foodPrice);
        const reworkedData = {
          ...reworkedFoodPrice,
          region: { id: value, region: label, ...region },
        };
        return call(updateFoodPrice, reworkedData);
      })
    );

    const tableFilters = yield select(selectTableFilters);

    yield call(approveAdminFoodPrices, reworkFoodPricesParamsAndFilters(tableFilters));

    // Reload foodPrices list right after approval
    yield call(_loadFoodPrices, initialState.params);
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.allFoodPricesApproved,
      })
    );
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* _loadFoodPrices(params) {
  try {
    yield put(showApproveAllButton(false));
    if (R.isNil(params) || R.isEmpty(params)) {
      params = yield select(selectTableParams);
    }
    const tableFilters = yield select(selectTableFilters);
    const tableParamsAndFilters = reworkFoodPricesParamsAndFilters({
      ...params,
      ...tableFilters,
    });

    const { results, count } = yield call(
      getAdminFoodPrices,
      tableParamsAndFilters
    );
    const reworkedFoodPrices = reworkFoodPrices(results);
    yield put(loadFoodPricesSuccess({ results: reworkedFoodPrices, count }));
    yield put(updateTableParams({ params }));
    let isApproveAllFoodPricesButtonVisible = false;
    if (tableFilters.price_status === PRICE_STATUS.PROPOSED) {
      isApproveAllFoodPricesButtonVisible = true; 
    }
    yield put(showApproveAllButton(isApproveAllFoodPricesButtonVisible));
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadFoodPricesSaga({ payload: { params } = {} }) {
  try {
    yield put(loadCountriesSliceStarted());
    yield* _loadFoodPrices(params);
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadAdminFoodItemsSaga(params) {
  try {
    yield put(loadCountriesSliceStarted());
    const tableFilters = yield select(selectTableFilters);
    if (!params.payload) {
      params.payload = yield select(selectAdminFoodItemsParams);
    } else {
      yield put(storeAdminFoodItemsParams({ params: params.payload }));
    }
    const tableParamsAndFilters = { ...params.payload, ...tableFilters };
    const foodItems = yield call(getAdminFoodItems, tableParamsAndFilters);
    yield put(loadAdminFoodItemsSuccess({ foodItems }));

    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateAdminFoodItemCustomTranslationsSaga(params) {
  try {
    yield put(loadCountriesSliceStarted());
    yield call(patchAdminFoodItemsCustomTranslations, {
      id: params.payload.id,
      data: params.payload.data,
    });
    yield put(loadAdminFoodItems());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.foodItemLocalTranslationsUpdated,
      })
    );
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadAdminRecipesSaga({ payload: { params } = {} }) {
  try {
    yield put(loadCountriesSliceStarted());
    if (R.isNil(params) || R.isEmpty(params)) {
      params = yield select(selectTableParams);
    }
    const tableFilters = yield select(selectTableFilters);
    const tableParamsAndFilters = { ...params, ...tableFilters };
    const adminRecipes = yield call(getAdminRecipes, tableParamsAndFilters);
    yield put(loadAdminRecipesSuccess({ adminRecipes }));
    yield put(updateTableParams({ params }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* loadSingleAdminRecipeSaga({ payload: { id } }) {
  try {
    yield put(loadCountriesSliceStarted());
    const recipe = yield call(getSingleAdminRecipe, id);
    yield put(loadSingleAdminRecipeSuccess({ recipe }));
    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* createSingleAdminRecipeSaga({
  payload: { recipe, prevPath },
}) {
  try {
    yield put(loadCountriesSliceStarted());
    yield call(postSingleAdminRecipe, recipe);
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.adminRecipeCreate,
      })
    );

    if (prevPath) {
      yield put(push(prevPath));
    } else {
      yield put(loadAdminRecipes());
    }
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* updateSingleAdminRecipeSaga({
  payload: { recipe, prevPath },
}) {
  try {
    yield put(loadCountriesSliceStarted());
    yield call(patchSingleAdminRecipe, recipe.id, recipe);
    yield put(loadCountriesSliceEnded());
    yield put(
      setAlert({
        type: "success",
        title: countriesMessages.genericSuccess,
        text: countriesMessages.adminRecipeUpdated,
      })
    );

    if (prevPath) {
      yield put(push(prevPath));
    } else {
      yield put(loadAdminRecipes());
    }
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export function* initAdminRecipeManageSaga() {
  try {
    yield put(initAdminRecipeManageStarted());
    yield call(loadFoodItemsSaga);
  } catch (e) {
    yield put(setAlert(e));
  }
  yield put(initAdminRecipeManageEnded());
}

export function* initAdminVamFoodPricesManageSaga() {
  try {
    yield put(initAdminVamFoodPricesManageStarted());
    yield put(resetTableParams());
    yield put(resetTableFilters());
    yield put(resetFoodItems());
    yield call(loadFoodItemsSaga);
    yield call(loadVamMappingsSaga, { payload: { params: {} } });
  } catch (e) {
    yield put(setAlert(e));
  }
  yield put(initAdminVamFoodPricesManageEnded());
}

export function* initAdminPriceApprovalManageSaga() {
  try {
    yield put(loadCountriesSliceStarted());

    const countryCode = yield select(selectCountryCode);

    yield all([
      loadConstantsSaga(),
      loadFoodItemsSaga(),
      loadCurrencySaga(countryCode),
      loadRegionsSaga(),
      loadAllSourcesSaga(),
    ]);

    yield put(loadCountriesSliceEnded());
  } catch (e) {
    yield put(loadCountriesSliceEnded());
    yield put(setAlert(e));
  }
}

export default function* adminSaga() {
  yield takeLatest(loadUsers, loadUsersSaga);
  yield takeLatest(loadSingleUser, loadSingleUserSaga);
  yield takeLatest(updateSingleUser, updateSingleUserSaga);
  yield takeLatest(loadUserGroups, loadUserGroupsSaga);
  yield takeLatest(loadSelectedSources, loadSelectedSourcesSaga);
  yield takeLatest(loadAllSources, loadAllSourcesSaga);
  yield takeLatest(updateSources, updateSourcesSaga);
  yield takeLatest(
    loadSelectedCommunityNutrientsLevel,
    loadSelectedCommunityNutrientsLevelSaga
  );
  yield takeLatest(
    loadAllCommunityNutrientsLevel,
    loadAllCommunityNutrientsLevelSaga
  );
  yield takeLatest(updateNutrientsLevel, updateNutrientsLevelSaga);
  yield takeLatest(loadSelectedNutrients, loadSelectedNutrientsSaga);
  yield takeLatest(loadAllNutrients, loadAllNutrientsSaga);
  yield takeLatest(updateNutrients, updateNutrientsSaga);
  yield takeLatest(loadPriorityItems, loadPriorityItemsSaga);
  yield takeLatest(updatePriorityItems, updatePriorityItemsSaga);
  yield takeLatest(loadVamMappings, loadVamMappingsSaga);
  yield takeLatest(loadFoodItems, loadFoodItemsSaga);
  yield takeLatest(updateVamMappings, updateVamMappingsSaga);
  yield takeLatest(approveFoodPriceRequest, approveFoodPriceSaga);
  yield takeLatest(rejectFoodPriceRequest, rejectFoodPriceSaga);
  yield takeLatest(approveAllFoodPricesRequest, approveAllFoodPricesSaga);
  yield takeLatest(loadFoodPricesRequest, loadFoodPricesSaga);
  yield takeLatest(loadAdminFoodItems, loadAdminFoodItemsSaga);
  yield takeLatest(
    updateAdminFoodItemCustomTranslations,
    updateAdminFoodItemCustomTranslationsSaga
  );
  yield takeLatest(loadAdminRecipes, loadAdminRecipesSaga);
  yield takeLatest(loadSingleAdminRecipe, loadSingleAdminRecipeSaga);
  yield takeLatest(createSingleAdminRecipe, createSingleAdminRecipeSaga);
  yield takeLatest(updateSingleAdminRecipe, updateSingleAdminRecipeSaga);
  yield takeLatest(initAdminRecipeManage, initAdminRecipeManageSaga);
  yield takeLatest(
    initAdminVamFoodPricesManage,
    initAdminVamFoodPricesManageSaga
  );
  yield takeLatest(initAdminPriceApprovalManage, initAdminPriceApprovalManageSaga);
}
