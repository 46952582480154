import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@generalform/LOAD_STARTED");
export const loadEnded = createAction("@@generalform/LOAD_ENDED");
export const loadBasketStarted = createAction("@@generalform/LOAD_BASKET_STARTED");
export const loadBasketEnded = createAction("@@generalform/LOAD_BASKET_ENDED");
export const loadFoodBasket = createAction("@@generalform/LOAD_FOOD_BASKET");
export const loadGeneralInfo = createAction("@@generalform/LOAD_GENERAL_INFO");

export const loadFoodBasketSuccess = createAction("@@generalform/LOAD_FOOD_BASKET_SUCCESS");

export const loadTargetBeneficiariesRequest = createAction("@@generalform/LOAD_TARGET_BENEFICIARIES_REQUEST");
export const loadTargetBeneficiariesSuccess = createAction("@@generalform/LOAD_TARGET_BENEFICIARIES_SUCCESS");
export const resetResults = createAction("@@generalform/RESET_RESULTS");
export const loadNutritionalReq = createAction("@@generalform/LOAD_NUTRITIONAL_REQ");
export const saveNutritionalReq = createAction("@@generalform/SAVE_NUTRITIONAL_REQ");

export const resetGeneralForm = createAction("@@generalform/RESET_GENERAL_FORM");

// Still being used in Community scope
export const updateFormState = createAction("@@generalform/UPDATE_FORM_STATE");
