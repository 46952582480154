import React, { useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import TextInput from "./TextInput";
import { Container, ColStyled } from "./styles";
import { Button, Select } from "components";
import { iconFilter, iconRestart } from "@wfp/icons";
import { RowStyled } from "components/utils";
import { allUsersFields } from "./constants";
import { loadUsers, updateTableFilters, resetTableFilters } from "containers/Admin/actions";
import { selectTableFilters } from "containers/Admin/selectors";
import messages from "components/Button/messages";

const Filters = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const filtersState = useSelector(selectTableFilters);

  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const fields = useMemo(() => allUsersFields(intl), [intl]);

  const onChange = useCallback(
    (e) => {
      const { value, id } = e.target;
      dispatch(updateTableFilters({ filters: { [id]: value } }));
    },
    [dispatch],
  );

  const onSubmit = useCallback(() => {
    setIsDataFiltered(true);
    dispatch(loadUsers({ params: { page: 1, page_size: 10 } }));
  }, [dispatch]);

  const onClear = useCallback(() => {
    setIsDataFiltered(false);
    dispatch(resetTableFilters());
    dispatch(loadUsers({ params: { page: 1, page_size: 10 } }));
  }, [dispatch]);

  return (
    <Container>
      <RowStyled>
        {fields.map(({ type, name, label, options, defaultValue }) => {
          switch (type) {
            case "select":
              return (
                <ColStyled key={name} xs={2}>
                  <Select
                    id={name}
                    label={label}
                    value={filtersState[name] || defaultValue}
                    options={options}
                    customOnChange={(el) => onChange({ target: { id: name, value: el } })}
                  />
                </ColStyled>
              );
            default:
              return (
                <ColStyled key={name} xs={2}>
                  <TextInput id={name} labelText={label} value={filtersState[name] || ""} onChange={onChange} />
                </ColStyled>
              );
          }
        })}
      </RowStyled>
      <RowStyled end="xs" margin="10px 0 0 0 !important">
        <ColStyled>
          <Button
            widthAuto
            icon={iconFilter}
            marginRight="10px"
            kind={isDataFiltered ? "primary" : "secondary"}
            onClick={onSubmit}
            children={intl.formatMessage(messages.filter)}
          />
          <Button
            widthAuto
            icon={iconRestart}
            kind="tertiary"
            onClick={onClear}
            children={intl.formatMessage(messages.clear)}
          />
        </ColStyled>
      </RowStyled>
    </Container>
  );
};

export default Filters;
