import React, { useCallback } from "react";
import { injectIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { Field, useField } from "react-final-form";
import { Text, Select } from "components";
import { ColStyled } from "components/utils";
import { subYears, differenceInYears, format } from "date-fns";
import { FIELDS } from "../utils";
import { optionsValidity } from "./utils";
import messages from "./messages";

function Validity({ intl }) {
  const {
    input: { onChange: changeValidity },
  } = useField(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.PRICE_VALIDITY}`);
  const {
    input: { value: createdAt },
  } = useField(FIELDS.CREATED_AT);

  const onChange = useCallback(
    ({ value }) => {
      if (!value) return changeValidity(null);
      changeValidity(format(subYears(new Date(), value), "YYY-L-d"));
    },
    [changeValidity],
  );

  return (
    <>
      <Text fontSize="16px" bold marginBottom="5px" value={intl.formatMessage(messages.priceValidity)} />
      <Text
        wrap
        marginBottom="5px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.priceValidityInfo)}
      />
      <ColStyled xs={2} margin="0 0 80px">
        <Field
          name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.PRICE_VALIDITY}`}
          render={({ input }) => (
            <Select
              defaultValue={input.value ? differenceInYears(new Date(createdAt), new Date(input.value)) : null}
              options={optionsValidity}
              onChange={onChange}
            />
          )}
        />
      </ColStyled>
    </>
  );
}

export default injectIntl(Validity);
