import { Controller } from "react-hook-form";
import { Search } from "@wfp/ui";

const SearchController = ({ control, name, onKeyUp }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value, ref } }) => (
      <Search
        id={name}
        placeholder="Search by name"
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        inputRef={ref}
      />
    )}
  />
);

export default SearchController;
