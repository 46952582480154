import React from "react";

import { colors } from "@wfp/ui";

type TooltipProps = {
  bar: {
    index: number;
    value: number;
    data: {
      value: number;
      unit: string;
      label: string;
    };
  };
  nutrientValues: {
    display_name: string;
    percentage: number;
    nutrientUnit: string;
    nutrientValue: number;
  }[][];
};

const Tooltip = ({ bar, nutrientValues }: TooltipProps) => {
  return (
    <div
      style={{
        color: "white",
        background: colors["ui-06"].hex,
        borderRadius: "0.4rem",
      }}
    >
      <div style={{ padding: "15px", borderBottom: "1px solid white" }}>
        <strong>
          {bar.data.label} - {bar.value}% ({bar.data.value} {bar.data.unit})
        </strong>
      </div>
      <div style={{ padding: "15px" }}>
        {nutrientValues[bar.index].filter((o: {percentage: number}) => o.percentage > 0).slice(0, 10).map(
          ({ display_name, percentage, nutrientUnit, nutrientValue }, i) => (
            <p key={i}>
              {display_name.split(";")[0]}: {percentage}%{" "}
              {nutrientValue ? ` (${ (nutrientValue / 100).toFixed(2) } ${nutrientUnit})` : null}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default Tooltip;
