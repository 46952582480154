import { Fragment } from "react";
import * as R from "ramda";
import { IntlProvider } from "react-intl";
import { Document, Page, pdf } from "@react-pdf/renderer";

import styles from "containers/PDF/styles";
import messages from "locales";
import { getBase64 } from "utils/utils";
import PageOne from "./PageOne";
import PageThree from "./PageThree";
import PageTwo from "./PageTwo";
import Pagination from "../Pagination";

const getNutritionalValuesImage = () => {
  const el = document.getElementById("nutritional-values");
  return el && getBase64(el);
};

const getSourcingChannelsImage = () => {
  const el = document.getElementById("sourcing-channels");
  return el && getBase64(el);
};

export default async (props) => {
  const [imageUrlNutrients, imageUrlSourcing] = await Promise.all([
    getNutritionalValuesImage(),
    getSourcingChannelsImage(),
  ]);
  const { info, nutritions, diversity } = R.pathOr({}, ["compareMenus"], props);

  return await pdf(
    <IntlProvider
      locale={props.language}
      defaultLocale={"en"}
      messages={messages[props.language]}
      textComponent={Fragment}
    >
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <PageOne imageUrlSourcing={imageUrlSourcing} infoMenus={info} {...props} />
          <PageTwo imageUrlNutrients={imageUrlNutrients} nutrients={nutritions} />
          <PageThree diversity={diversity} />
          <Pagination />
        </Page>
      </Document>
    </IntlProvider>,
  ).toBlob();
};
