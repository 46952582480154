import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { CheckboxGroupContainer, ChipWithLabel } from "components";
import { NutritionalGraph } from "components/NewCharts";
import { CheckboxGroupHookForm, SelectHookForm, MultiSelectHookForm } from "components/Form";
import { RowStyled, ColStyled, newColorBySource } from "components/utils";
import { selectRegions, selectFoodSources } from "containers/App/selectors";
import { FIELDS, getYearsOptions, getDataSourcesOptions } from "containers/FoodBasket/constants";
import foodBasketMessages from "containers/FoodBasket/messages";
import { usePriceSourcesCheckboxes, useResponsiveHook } from "hooks";

const Filters = () => {
  const intl = useIntl();
  const { control } = useFormContext();
  const { isTabletOrMobile } = useResponsiveHook();
  const formNutrients = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.NUTRIENTS}` });
  const regions = useSelector(selectRegions);
  const procurementSources = useSelector(selectFoodSources);

  const years = useMemo(() => getYearsOptions(intl), [intl]);
  const dataSources = useMemo(() => getDataSourcesOptions(intl), [intl]);
  const priceSources = usePriceSourcesCheckboxes();

  const getProcurementSourceLabelText = useCallback(
    ({ label, value }) => <ChipWithLabel backgroundColor={newColorBySource[value]} value={label} />,
    [],
  );

  const onRegionChange = useCallback(({ value, meta, field }) => {
    if (meta.action === "clear") {
      return field.onChange([]);
    }
    return field.onChange(value)
  }, []);

  return (
    <RowStyled margin="2.5rem 0 0 0">
      <ColStyled xs={isTabletOrMobile ? 12 : 5}>
        <RowStyled>
          <ColStyled xs={6}>
            <MultiSelectHookForm
              control={control}
              name={`${FIELDS.FILTERS}.${FIELDS.REGIONS}`}
              options={regions}
              label={intl.formatMessage(foodBasketMessages.regions)}
              customOnChange={onRegionChange}
              placeholder={intl.formatMessage(foodBasketMessages.allRegions)}
            />

            <CheckboxGroupContainer label={intl.formatMessage(foodBasketMessages.dataSource)}>
              <CheckboxGroupHookForm
                control={control}
                name={`${FIELDS.FILTERS}.${FIELDS.DATA_SOURCE}`}
                options={dataSources}
              />
            </CheckboxGroupContainer>

            <CheckboxGroupContainer label={intl.formatMessage(foodBasketMessages.priceDataOrigins)}>
              <CheckboxGroupHookForm
                control={control}
                name={`${FIELDS.FILTERS}.${FIELDS.PRICE_DATA_ORIGINS}`}
                options={priceSources}
              />
            </CheckboxGroupContainer>
          </ColStyled>
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.FILTERS}.${FIELDS.DATE_FROM}`}
              options={years}
              label={intl.formatMessage(foodBasketMessages.year)}
            />

            <CheckboxGroupContainer label={intl.formatMessage(foodBasketMessages.procurementSource)}>
              <CheckboxGroupHookForm
                control={control}
                name={`${FIELDS.FILTERS}.${FIELDS.PROCUREMENT_SOURCES}`}
                getLabelText={getProcurementSourceLabelText}
                options={procurementSources}
              />
            </CheckboxGroupContainer>
          </ColStyled>
        </RowStyled>
      </ColStyled>

      {/* Graph stuff */}
      <ColStyled xs={7} display={isTabletOrMobile ? "none" : "block"}>
        <NutritionalGraph nutrients={formNutrients} name={`${FIELDS.FILTERS}.${FIELDS.NUTRIENTS}`} />
      </ColStyled>
    </RowStyled>
  );
};

export default Filters;
