export const ACTIONS = {
  CLONE: "CLONE",
  RENAME: "RENAME",
  SHARE: "SHARE",
  DOWNLOAD: "DOWNLOAD",
};

export const DOWNLOADS = {
  EXCEL: "excel",
  PDF: "pdf",
};
