import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Row } from "react-flexbox-grid";

import { Button, Unlock } from "components";
import { ModalStyled } from "components/UIKitStyled";
import { PERMISSIONS } from "containers/Admin/constants";
import { COMMUNITY, MANUAL, OPTIMIZED } from "containers/MyMenus/labels";
import Card from "./Card";
import { Manual, Automatic, Community } from "./Icons";
import messages from "./messages";
import NextButton from "./NextButton";
import { isCommunityFeatureEnabled } from "utils/constants";

function CreateNewMenu() {
  const intl = useIntl();
  const [openModal, changeStatusModal] = useState();
  const [type, setType] = useState();

  const changeStatus = useCallback(() => {
    changeStatusModal(!openModal);
    setType(!openModal ? OPTIMIZED : null);
  }, [openModal]);

  return (
    <>
      <Button widthAuto children={intl.formatMessage(messages.createNewMenu)} onClick={changeStatus} />
      {openModal && (
        <ModalStyled
          open={openModal}
          onRequestClose={changeStatus}
          modalHeading={intl.formatMessage(messages.title)}
          modalFooter={() => <NextButton type={type} changeStatus={changeStatus} />}
          maxWidth="65%"
        >
          <Row center="xs">
            <Unlock permission={PERMISSIONS.ADD_OPTIMIZED_MENU}>
              <Card
                setType={setType}
                type={type}
                image={<Automatic highlight={type === OPTIMIZED} />}
                value={OPTIMIZED}
                title={intl.formatMessage(messages.automatic)}
                info={intl.formatMessage(messages.infoOptimized)}
              />
            </Unlock>
            <Unlock permission={PERMISSIONS.ADD_MANUAL_MENU}>
              <Card
                setType={setType}
                type={type}
                image={<Manual highlight={type === MANUAL} />}
                value={MANUAL}
                title={intl.formatMessage(messages.manual)}
                info={intl.formatMessage(messages.infoManual)}
              />
            </Unlock>
            {isCommunityFeatureEnabled && (
              <Unlock permission={PERMISSIONS.ADD_COMMUNITY_MENU}>
                <Card
                  setType={setType}
                  type={type}
                  image={<Community highlight={type === COMMUNITY} />}
                  value={COMMUNITY}
                  title={intl.formatMessage(messages.community)}
                  info={intl.formatMessage(messages.infoCommunity)}
                />
              </Unlock>
            )}
          </Row>
        </ModalStyled>
      )}
    </>
  );
}

export default CreateNewMenu;
