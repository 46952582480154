import { defineMessage } from "react-intl";

export default {
  confirmDescription: defineMessage({
    id: "selectCountry.confirmDescription",
    defaultMessage: "Please confirm if you want to continue using PLUS in this country or choose another.",
  }),
  confirmTitle: defineMessage({
    id: "selectCountry.confirmTitle",
    defaultMessage: "Continue with {country} ?",
  }),
  choiceTitle: defineMessage({
    id: "selectCountry.choiceTitle",
    defaultMessage: "Select a country",
  }),
  choiceDescription: defineMessage({
    id: "selectCountry.choiceDescription",
    defaultMessage: "PLUS will load country specific information like availability of food items and their prices.",
  }),
  currentCountry: defineMessage({
    id: "selectCountry.currentCountry",
    defaultMessage: "Continue with current country",
  }),
  anotherCountry: defineMessage({
    id: "selectCountry.anotherCountry",
    defaultMessage: "Select another country",
  }),
};
