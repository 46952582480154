import { useRef, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { User, MainNavigation, MainNavigationItem } from "@wfp/ui";
import { saveComparisonList, clearFilters } from "containers/MyMenus/actions";
import { selectFirstName, selectLanguage, selectIsInactive, selectRoles } from "containers/App/selectors";
import { LANGUAGES } from "./Languages/utils";
import LogoAndCountry from "./LogoAndCountry";
import Languages from "./Languages";
import Profile from "./Profile";
import Admin from "./Admin";
import Link from "./Link";
import { selectAccount } from "../selectors";
import { HeaderWrapper } from "./styles";
import { ROLES } from "containers/Admin/constants";
import messages from "containers/App/messages";
import routesPaths from "containers/App/Routes/paths";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const mainNavigationRef = useRef();
  const language = useSelector(selectLanguage);
  const account = useSelector(selectAccount);
  const firstName = useSelector(selectFirstName);
  const inactive = useSelector(selectIsInactive);
  const roles = useSelector(selectRoles);
  const isMenusPage = useMemo(() => history.location.pathname === routesPaths.menus, [history.location.pathname]);

  useEffect(() => {
    const { pathname } = history.location;
    if (!pathname.includes("compare") && !pathname.includes("results") && !isMenusPage) {
      dispatch(clearFilters());
      dispatch(saveComparisonList([]));
    }
  }, [dispatch, history.location, history.location.pathname, isMenusPage]);

  // Close "Pages" dropdown on page change
  useEffect(() => {
    mainNavigationRef.current.onChangeSub("close");
  }, [pathname]);

  const userIsInactive = useMemo(() => inactive || R.isEmpty(account), [account, inactive]);

  const showLanguageCode = useMemo(
    () =>
      R.pathOr(
        "NA",
        ["valueToShow"],
        LANGUAGES.find((el) => el.language === language),
      ),
    [language],
  );

  const isGlobalAdmin = useMemo(() => roles.includes(ROLES.GLOBAL_ADMIN), [roles]);
  const isCountryAdmin = useMemo(() => roles.includes(ROLES.COUNTRY_ADMIN), [roles]);

  return (
    <HeaderWrapper>
      <MainNavigation ref={mainNavigationRef} pageWidth="lg" logo={<LogoAndCountry userIsInactive={userIsInactive} />}>
        {!userIsInactive && (isGlobalAdmin || isCountryAdmin) && (
          <MainNavigationItem subNavigation={<Admin mainNavigationRef={mainNavigationRef} />}>
            <div>
              <FormattedMessage {...messages.admin} />
            </div>
          </MainNavigationItem>
        )}
        {!userIsInactive && (
          <MainNavigationItem>
            <Link path={routesPaths.menus} message={messages.menus} />
          </MainNavigationItem>
        )}
        {!userIsInactive && (
          <MainNavigationItem>
            <Link path={routesPaths.foodBasket} message={messages.foodBasket} />
          </MainNavigationItem>
        )}
        <MainNavigationItem
          className="wfp--main-navigation__user"
          subNavigation={<Profile mainNavigationRef={mainNavigationRef} userIsInactive={userIsInactive} />}
        >
          <User missingImage="letter" name={firstName} />
        </MainNavigationItem>
        <MainNavigationItem
          className="wfp--main-navigation__user"
          subNavigation={<Languages mainNavigationRef={mainNavigationRef} />}
        >
          <div>{showLanguageCode}</div>
        </MainNavigationItem>
      </MainNavigation>
    </HeaderWrapper>
  );
};

export default Header;
