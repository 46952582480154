import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div + div {
    margin-left: 0.5rem;
  }
`;

export const Helper = styled.span`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;

  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `};
`;

export const SharedContainer = styled.div`
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "20px" : "0")};
`;
