import { createReducer } from "@reduxjs/toolkit";
import {
  loadStarted,
  loadEnded,
  loadSuccess,
  clearFilters,
  saveComparisonList,
  loadFiltersStarted,
  loadFiltersEnded,
  resetComparisonList,
  resetReducer,
} from "./actions";

export const initialState = {
  loading: false,
  results: [],
  count: 0,
  filters: {
    page: 1,
    page_size: 10,
  },
  loadingFilters: false,
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadSuccess.type]: (state, action) => {
    state.results = action.payload.results;
    state.count = action.payload.count;
    state.filters = action.payload.filters;
  },
  [saveComparisonList.type]: (state, action) => {
    state.comparisonList = action.payload;
  },
  [clearFilters.type]: (state) => {
    state.filters = initialState.filters;
  },
  [loadFiltersStarted.type]: (state) => {
    state.loadingFilters = true;
  },
  [loadFiltersEnded.type]: (state) => {
    state.loadingFilters = false;
  },
  [resetComparisonList.type]: (state) => {
    state.comparisonList = initialState.comparisonList;
    state.filters = initialState.filters;
  },
  [resetReducer.type]: () => initialState,
});
