import { call, put, takeLatest } from "redux-saga/effects";
import { setAlert } from "containers/App/actions";
import { getTutorials } from "api/api";
import { loadStarted, loadEnded, loadTutorials, loadTutorialsSuccess } from "./actions";

export function* loadTutorialsSaga() {
  try {
    yield put(loadStarted());
    const { results } = yield call(getTutorials);
    yield put(loadTutorialsSuccess({ tutorials: results }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* tutorialsSaga() {
  yield takeLatest(loadTutorials, loadTutorialsSaga);
}
