import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadItemsSuccess,
  loadGroupsAndItemsSuccess,
  loadMajorGroupsSuccess,
  loadCompositionsSuccess,
  loadRestrictionSuccess,
  resetMenuStructure,
} from "./actions";

export const initialState = {
  compositions: {
    menu: {},
    meals: [],
  },
  groupsAndItems: {
    major_groups: [],
    group_count: 0,
    item_count: 0,
  },
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadItemsSuccess.type]: (state, action) => {
    state.foodItems = action.payload.foodItems;
  },
  [loadGroupsAndItemsSuccess.type]: (state, action) => {
    state.groupsAndItems = action.payload.groupsAndItems;
  },
  [loadMajorGroupsSuccess.type]: (state, action) => {
    state.majorGroups = action.payload.majorGroups;
  },
  [loadCompositionsSuccess.type]: (state, action) => {
    state.compositions = action.payload.composition;
  },
  [loadRestrictionSuccess.type]: (state, action) => {
    state.restriction = action.payload.restriction;
  },
  [resetMenuStructure.type]: () => initialState,
});
