import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Module, Text, Line } from "components";
import { FIELDS } from "containers/Results/constants";
import menuMessages from "containers/Menu/messages";
import messages from "containers/Results/messages";
import { selectMealCount } from "containers/Menu/selectors";
import { selectRateFromUSD, selectLocalCurrency } from "containers/App/selectors";
import TotalCost from "./TotalCost";
import MainCostsDrivers from "./MainCostsDrivers";
import { useResponsiveHook } from "hooks";

function CostPerChildAndNumbers({ values, onlyPreview, specificDay, intl }) {
  const mealCount = useSelector(selectMealCount);
  const conversionRate = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);
  const { isTabletOrMobile } = useResponsiveHook();

  const totalCost = useMemo(() => {
    if (!values) return;
    return values.reduce(
      (acc, item) => ({
        ...acc,
        cost: acc.cost + item[FIELDS.PRICE],
        costWithoutAdd: acc.costWithoutAdd + item[FIELDS.PRICE_WITHOUT_ADD],
      }),
      { cost: 0, costWithoutAdd: 0 },
    );
  }, [values]);

  const title = useMemo(
    () =>
      onlyPreview ? intl.formatMessage(menuMessages.costPerMeal) : intl.formatMessage(menuMessages.averageCostPerMeal),
    [intl, onlyPreview],
  );

  return (
    <>
      <Module title={title} padding={isTabletOrMobile && "10px 2px 0"} minHeight={!onlyPreview && "354px"}>
        <TotalCost
          totalCost={totalCost}
          mealCount={specificDay ? 1 : mealCount}
          conversionRate={conversionRate}
          localCurrency={localCurrency}
          onlyPreview={onlyPreview}
        />
        {!onlyPreview && (
          <>
            <Line margin="10px 0 15px" />
            <Text
              bold
              fontSize="16px"
              value={intl.formatMessage(isTabletOrMobile ? messages.mainCostsDriversShort : messages.mainCostsDrivers)}
            />
            <MainCostsDrivers values={values} totalCost={totalCost} />
          </>
        )}
      </Module>
    </>
  );
}

export default injectIntl(CostPerChildAndNumbers);
