import React from "react";
import { useField, Field } from "react-final-form";
import { RowStyled, ColStyled } from "components/utils";
import { Slider } from "components/InputFields";
import { useResponsiveHook } from "hooks";
import { capitalize } from "utils/utils";
import { FIELDS } from "../../constants";

const NutrientLevel = ({ name }) => {
  const { isTabletOrMobile } = useResponsiveHook();
  const {
    input: { value: labelValue },
  } = useField(`${name}.${FIELDS.LABEL}`);

  return (
    <ColStyled xs={7}>
      <div className="wfp--module__inner nutritionModule formItem">
        <RowStyled between="xs">{capitalize(labelValue)}</RowStyled>
        <RowStyled bottom="xs">
          <ColStyled xs={10} lg={9} margin="20px 0 0">
            <Field
              name={`${name}.${FIELDS.PERCENTAGE}`}
              minLabel="0%"
              maxLabel="100%"
              minValue={0}
              maxValue={100}
              customXs={isTabletOrMobile ? 9 : 10}
              customLg={7}
              component={Slider}
            />
          </ColStyled>
        </RowStyled>
      </div>
    </ColStyled>
  );
};

export default NutrientLevel;
