import React from "react";
import styled from "styled-components";
import { colors } from "@wfp/ui";
import { ColStyled } from "components/utils";

const ColWithProps = ({ customFlex, ...props }) => <ColStyled {...props} />;

export const ContainerDay = styled(ColWithProps)`
  border-top: 1px solid ${colors["ui-04"].hex};
  border-right: 1px solid ${colors["ui-04"].hex};
  flex: ${({ customFlex }) => customFlex};
  padding: 0 0 10px;
  position: relative;
  min-height: 230px;
  overflow: hidden;
  &:last-child {
    border-right: 0;
  }
`;
