import { useIntl } from "react-intl";
import { View, Text } from "@react-pdf/renderer";
import generalStyles from "containers/PDF/styles";
import messages from "containers/Results/messages";
import compareStyles from "../styles";

const CostPerMeal = ({ isLast, cost, withoutAdditional, conversionRate, localCurrency, language, fullPage }) => {
  const intl = useIntl();
  const { box, title } = compareStyles;
  const { row, fontSize10 } = generalStyles;
  const costs = [
    { total: cost, withoutAdditional, currency: "USD" },
    {
      total: (cost * conversionRate).toFixed(3),
      withoutAdditional: (withoutAdditional * conversionRate).toFixed(3),
      currency: localCurrency,
    },
  ];
  const tooMenuNotInEN = fullPage && language !== "en";

  return (
    <View style={[box, isLast ? { border: 0 } : {}, tooMenuNotInEN ? { padding: "5 13" } : {}]}>
      {(costs || []).map(({ total, withoutAdditional, currency }, index) => (
        <View key={`${currency}-${index}`}>
          <View style={[row, { marginTop: index ? 9 : 0 }]}>
            <Text style={title}>{total}</Text>
            <Text style={[fontSize10, { fontWeight: "semibold", padding: "5 0 0 3" }]}>{currency}</Text>
          </View>
          <Text style={fontSize10}>
            {intl.formatMessage(messages.excludingAdditional, { price: `${withoutAdditional} ${currency}` })}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default CostPerMeal;
