export const INFEASIBLE = "infeasible";
export const SUBOPTIMAL = "suboptimal";
export const RUNNING_SUBOPTIMAL = "running suboptimal";
export const OPTIMAL = "optimal";
export const OPTIMIZED = "optimized";
export const MANUAL = "manual";
export const COMMUNITY = "communitymenu";
export const MODIFIED = "modified";
export const TO_BE_PROCESSED = "to be processed";
export const RUNNING = "running";
export const ADJUSTED = "adjusted";
export const DRAFT = "draft";
export const ERROR = "error";
