import { useIntl } from "react-intl";
import { Pagination as WfpPagination } from "@wfp/ui";
import messages from "components/Table/messages";

const Pagination = ({ noPagesText, noPageRange, onChange, page, pageSize, totalItems, pageSizeOptions }) => {
  const intl = useIntl();

  return (
    <WfpPagination
      pageSizes={pageSizeOptions}
      backwardText="Backward"
      forwardText="Forward"
      itemsPerPageText={!noPagesText ? intl.formatMessage(messages.itemsPerPage) : ""}
      pageNumberText="Page Number"
      itemRangeText={() => ""}
      pageRangeText={(current, total) =>
        noPageRange ? "" : intl.formatMessage(messages.pageRange, { current, total })
      }
      onChange={onChange}
      page={page}
      pageSize={pageSize}
      totalItems={totalItems}
    />
  );
};

export default Pagination;
