import { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Tabs, Tab } from "@wfp/ui";

import { Button, InlineLoading, Unlock } from "components";
import buttonMessages from "components/Button/messages";
import { ModalStyled } from "components/UIKitStyled";
import { RowStyled } from "components/utils";
import { PERMISSIONS } from "containers/Admin/constants";
import foodBasketMessages from "containers/FoodBasket/messages";
import { selectSummary, selectLoadingSummaryPrices, selectLoadingFoodPrice } from "containers/FoodBasket/selectors";
import AddFoodPrice from "containers/FoodBasket/AddFoodPrice";
import NutritionalValues from "containers/FoodBasket/NutritionalValues";
import { useResponsiveHook } from "hooks";
import AveragePriceChart from "../AveragePriceChart";
import PriceSources from "../PriceSources";

const FoodModal = ({
  open,
  modalHeading,
  onRequestClose,
  openPersonalPrice,
  setOpenPersonalPrice,
  price,
  setPrice,
  itemId,
  editFoodPrice,
}) => {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();
  const summary = useSelector(selectSummary);
  const loadingSummaryPrices = useSelector(selectLoadingSummaryPrices);
  const loadingFoodPrice = useSelector(selectLoadingFoodPrice);

  const nutritionalValues = useMemo(() => R.pathOr([], ["nutritional_values"], summary), [summary]);
  const itemSource = useMemo(() => R.pathOr([], ["item_source"], summary), [summary]);

  // Handle nutritional values in order to show them in the corresponding grid
  const data = useMemo(
    () => nutritionalValues.map((el) => ({ ...el, label: el.nutrient_display })),
    [nutritionalValues],
  );

  const renderNutritionalValues = useMemo(() => {
    const itemsPerRow = isTabletOrMobile ? 2 : 8;

    return (
      <NutritionalValues
        title={intl.formatMessage(foodBasketMessages.nutritionalInfo, { itemSource })}
        hasLegend
        data={data}
        itemsPerRow={itemsPerRow}
      />
    );
  }, [data, intl, isTabletOrMobile, itemSource]);

  return (
    <ModalStyled
      open={open}
      lazyLoad
      modalHeading={modalHeading}
      onRequestClose={onRequestClose}
      noFooter
      padding="2rem"
      maxWidth="80%"
      width="100%"
    >
      {loadingSummaryPrices || loadingFoodPrice ? (
        <InlineLoading />
      ) : (
        <Tabs selected={0}>
          <Tab label={intl.formatMessage(foodBasketMessages.tabPrices)}>
            {openPersonalPrice ? (
              <AddFoodPrice close={setOpenPersonalPrice.setFalse} price={price} setPrice={setPrice} itemId={itemId} />
            ) : (
              <Unlock permission={PERMISSIONS.ADD_FOOD_PRICES}>
                <RowStyled middle="xs" end="xs" margin="0.5rem 0">
                  <Button
                    widthAuto
                    onClick={setOpenPersonalPrice.setTrue}
                    children={intl.formatMessage(buttonMessages.addPersonalPrice)}
                  />
                </RowStyled>
              </Unlock>
            )}
            <AveragePriceChart />
            <PriceSources onEdit={editFoodPrice} onRequestClose={onRequestClose} />
          </Tab>
          <Tab label={intl.formatMessage(foodBasketMessages.tabNutritionalValues)}>{renderNutritionalValues}</Tab>
        </Tabs>
      )}
    </ModalStyled>
  );
};

export default FoodModal;
