import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAlert } from "../actions";
import { selectError } from "../selectors";

const useError = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const [openedError, setOpenedError] = useState(null);

  const onAnimationEnd = useCallback(() => {
    if (!error) setOpenedError(null);
  }, [error]);

  const closeAlert = useCallback(() => dispatch(clearAlert()), [dispatch]);

  useEffect(() => {
    if (!error) return;
    setOpenedError(error);
    const timeoutId = setTimeout(closeAlert, 3000);
    return () => clearTimeout(timeoutId);
  }, [closeAlert, error]);

  return {
    isOpen: !!error,
    error: openedError,
    closeAlert,
    onAnimationEnd,
  };
};

export { useError };
