import React from "react";
import styled, { css } from "styled-components";
import { Module, colors } from "@wfp/ui";
import { mediaTablet } from "components/utils";

export const WrapperModule = styled.div`
  ${({ width }) =>
    width &&
    css`
      ${{ width }}
    `};
  ${({ useSticky }) =>
    useSticky &&
    css`
      position: sticky;
      top: calc(var(--header-height) - 10px);
      z-index: 2;
    `};
`;

const StyledModule = ({ useSticky, withTop, height, padding, paddingContent, minHeight, noBorder, ...props }) => (
  <Module {...props} />
);

export const ModuleWithoutShadow = styled(StyledModule)`
  padding: ${({ padding }) => padding || "10px 0"};
  ${({ margin }) =>
    margin &&
    css`
      ${{ margin }}
    `};
  .wfp--module__inner {
    ${({ height }) =>
      height &&
      css`
        ${{ height }}
      `};
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${({ minHeight }) => minHeight || "auto"};
      `};
    ${mediaTablet} {
      ${({ height }) =>
        height &&
        css`
          height: ${`calc(${height} + 30px)`};
        `};
    }
    box-shadow: none;
    background: ${({ background }) => background || "white"};
    border: ${({ noBorder }) => (noBorder ? "none" : `1px solid ${colors["ui-04"].hex}`)};
    padding: ${({ paddingContent }) => paddingContent || "10px 15px"};
  }
`;
