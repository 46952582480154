import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectMenuStructure = () => (state) => R.pathOr(initialState, ["menuStructure"], state);

export default () => createSelector(selectMenuStructure(), (menuStructureState) => menuStructureState);

export const selectFoodRulesLoading = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr(false, ["loading"], menuStructureState),
);

export const selectGroupsAndItems = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr({}, ["groupsAndItems"], menuStructureState),
);

export const selectRestriction = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr(null, ["restriction"], menuStructureState),
);

export const selectRestrictionTarget = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr("", ["restriction", "name"], menuStructureState),
);

export const selectFoodItems = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr([], ["foodItems"], menuStructureState),
);

export const selectCompositions = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr({}, ["compositions"], menuStructureState),
);

export const selectMajorGroups = createSelector(selectMenuStructure(), (menuStructureState) =>
  R.pathOr({}, ["majorGroups"], menuStructureState),
);
