import React from "react";
import { FormattedMessage } from "react-intl";
import { FIELDS } from "./constants";
import LabelAndField from "../LabelAndField";
import { Text } from "components";
import { FIELDS as RULES_FIELDS } from "containers/OptimizedMenu/Rules/constants";
import messages from "../../messages";

export default function Groups({ errors }) {
  return (
    <>
      <Text fontSize="16px" marginTop="10px" bold value={<FormattedMessage {...messages.numberOfFoodGroups} />} />
      <LabelAndField
        label={<FormattedMessage {...messages.min} />}
        text={<FormattedMessage {...messages.minFoodGroupsInMeal} />}
        fieldName={`${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MIN_NUMBER_MEAL}`}
        invalid={Object.keys(errors || {}).includes(FIELDS.MIN_NUMBER_MEAL)}
        invalidText={Object.keys(errors || {}).includes(FIELDS.MIN_NUMBER_MEAL) && errors[FIELDS.MIN_NUMBER_MEAL]}
      />
      <LabelAndField
        label={<FormattedMessage {...messages.min} />}
        className="paddingTopSmall"
        text={<FormattedMessage {...messages.minFoodGroupsInMenu} />}
        fieldName={`${RULES_FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.MIN_NUMBER_MENU}`}
        invalid={Object.keys(errors || {}).includes(FIELDS.MIN_NUMBER_MENU)}
        invalidText={Object.keys(errors || {}).includes(FIELDS.MIN_NUMBER_MENU) && errors[FIELDS.MIN_NUMBER_MENU]}
      />
    </>
  );
}
