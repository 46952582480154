import { defineMessage } from "react-intl";

export default {
  general: defineMessage({
    id: "step.generalInfo",
    defaultMessage: "General Info",
  }),
  structure: defineMessage({
    id: "step.structure",
    defaultMessage: "Structure",
  }),
  nutrition: defineMessage({
    id: "step.nutrition",
    defaultMessage: "Nutrition",
  }),
  sourcing: defineMessage({
    id: "step.sourcing",
    defaultMessage: "Sourcing",
  }),
  summary: defineMessage({
    id: "step.summary",
    defaultMessage: "Summary",
  }),
  pagination: defineMessage({
    id: "step.pagination",
    defaultMessage: "Step {current} of {total} - {name} {link}",
  }),

  // TODO: Check older labels and remove them from everywhere if unused.
  setupMenuAndFoodBasket: defineMessage({
    id: "step.setupMenuAndFoodBasket",
    defaultMessage: "Setup menu & food basket",
  }),
  setupRulesAndGenerateMenu: defineMessage({
    id: "step.setupRulesAndGenerateMenu",
    defaultMessage: "Setup rules & generate menu",
  }),
  adjustAndFinalize: defineMessage({
    id: "step.adjustAndFinalize",
    defaultMessage: "Adjust & finalize the menu",
  }),
};
