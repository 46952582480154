import { useEffect, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { resetTableFilters, resetUsers } from "../actions";
import UsersTable from "./UsersTable";
import UserPage from "./UserPage";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTableFilters());
      dispatch(resetUsers());
    };
  }, [dispatch]);

  const renderAllUsersUserPage = useCallback(() => {
    return <UserPage prevPath={"/plusadmin/users/all"} />;
  }, []);

  return (
    <Switch>
      {/* Edit page for selected user */}
      <Route path="/plusadmin/users/all/:userId" component={renderAllUsersUserPage} />

      {/* Table list */}
      <Route component={UsersTable} />
    </Switch>
  );
};

export default Users;
