import usersMessages from "./Users/messages";
import countriesMessages from "./Countries/messages";
import { PRICE_STATUS } from "./Countries/PriceApproval/Filters/constants";

// Roles coming from accounts/me API call
// Following roles are ignored since they comes from Django but are not meaningful for the application:
// plusadmin.add_plusadminpermission, plusadmin.delete_plusadminpermission, plusadmin.view_plusadminpermission, plusadmin.change_plusadminpermission
export const PERMISSIONS = {
  // App permissions
  ADD_COMMUNITY_MENU: "plusadmin.add_plus_menu_community", // ✅
  ADD_FOOD_PRICES: "plusadmin.add_plus_food_prices", // ✅
  ADD_MANUAL_MENU: "plusadmin.add_plus_menu_manual", // ✅
  ADD_OPTIMIZED_MENU: "plusadmin.add_plus_menu_optimized", // ✅

  CHANGE_COMMUNITY_MENU: "plusadmin.change_plus_menu_community", // ✅
  CHANGE_FOOD_PRICES: "plusadmin.change_plus_food_prices", // ✅
  CHANGE_MANUAL_MENU: "plusadmin.change_plus_menu_manual", // ✅
  CHANGE_OPTIMIZED_MENU: "plusadmin.change_plus_menu_optimized", // ✅

  DELETE_COMMUNITY_MENU: "plusadmin.delete_plus_menu_community", // ✅
  DELETE_FOOD_PRICES: "plusadmin.delete_plus_food_prices", // ✅
  DELETE_MANUAL_MENU: "plusadmin.delete_plus_menu_manual", // ✅
  DELETE_OPTIMIZED_MENU: "plusadmin.delete_plus_menu_optimized", // ✅

  VIEW_COMMUNITY_MENU: "plusadmin.view_plus_menu_community", // ✅
  VIEW_FOOD_PRICES: "plusadmin.view_plus_food_prices", // ✅
  VIEW_MANUAL_MENU: "plusadmin.view_plus_menu_manual", // ✅
  VIEW_OPTIMIZED_MENU: "plusadmin.view_plus_menu_optimized", // ✅

  // Admin area permissions
  ADD_NUTRITIONAL_DB: "plusadmin.add_plus_admin_nutritional_db",
  ADD_NUTRITIONAL_REQUIREMENTS: "plusadmin.add_plus_admin_nutritional_requirements",
  ADD_PRICES: "plusadmin.add_plus_admin_prices",
  ADD_RECOMMENDED_ITEMS: "plusadmin.add_plus_admin_recommended_items",

  CHANGE_NUTRITIONAL_DB: "plusadmin.change_plus_admin_nutritional_db",
  CHANGE_NUTRITIONAL_REQUIREMENTS: "plusadmin.change_plus_admin_nutritional_requirements",
  CHANGE_PRICES: "plusadmin.change_plus_admin_prices",
  CHANGE_RECOMMENDED_ITEMS: "plusadmin.change_plus_admin_recommended_items",

  DELETE_NUTRITIONAL_DB: "plusadmin.delete_plus_admin_nutritional_db",
  DELETE_NUTRITIONAL_REQUIREMENTS: "plusadmin.delete_plus_admin_nutritional_requirements",
  DELETE_PRICES: "plusadmin.delete_plus_admin_prices",
  DELETE_RECOMMENDED_ITEMS: "plusadmin.delete_plus_admin_recommended_items",

  VIEW_NUTRITIONAL_DB: "plusadmin.view_plus_admin_nutritional_db",
  VIEW_NUTRITIONAL_REQUIREMENTS: "plusadmin.view_plus_admin_nutritional_requirements",
  VIEW_PRICES: "plusadmin.view_plus_admin_prices",
  VIEW_RECOMMENDED_ITEMS: "plusadmin.view_plus_admin_recommended_items",
};

export const ROLES = {
  GLOBAL_ADMIN: "admin_tec",
  COUNTRY_ADMIN: "country_admin",
  REGULAR_USER: "regular_user",
};

export const intlStateOptions = (intl) => [
  {
    value: "",
    label: intl.formatMessage(usersMessages.stateAllUsers),
  },
  {
    value: "active",
    label: intl.formatMessage(usersMessages.stateActive),
  },
  {
    value: "inactive",
    label: intl.formatMessage(usersMessages.stateInactive),
  },
];

export const intlPriceStatusOptions = (intl) => [
  {
    value: "",
    label: intl.formatMessage(countriesMessages.priceStatusAllPricesStatus),
  },
  {
    value: PRICE_STATUS.PROPOSED,
    label: intl.formatMessage(countriesMessages.priceStatusProposed),
  },
  {
    value: PRICE_STATUS.OFFICIAL,
    label: intl.formatMessage(countriesMessages.priceStatusOfficial),
  },
  {
    value: PRICE_STATUS.REJECTED,
    label: intl.formatMessage(countriesMessages.priceStatusRejected),
  },
];
