import { useCallback } from "react";
import { useController } from "react-hook-form";
import { NumberInput } from "@wfp/ui";

const transform = {
  input: (value) => (isNaN(value) || value === 0 || !value ? "" : parseFloat(value)),
  output: (value, allowEmpty) => {
    const output = parseFloat(value);
    return isNaN(output) ? (allowEmpty ? "" : 0) : output;
  },
};

/**
 * NumberInput wrapper that handles react-hook-form's handleSubmit() validation
 *
 * TODO: In the future, replace all the existing NumberInputHookForm
 * components that need a submit validation, with this component
 */
const ValidatedNumberInputHookForm = ({
  control,
  name,
  className,
  defaultValue,
  allowEmpty = false,
  labelText = "",
  helperText = "",
  min = 0,
  max,
  step = "0.01",
  allowZero,
  invalid = false,
  invalidText = "",
  rules = {},
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  // If we trigger onChange event by pressing plus/minus buttons, we get the value right away (typeof "number")
  // If we write by hand, we get a regular onChange event (typeof "string" which is translated to number afterwards)
  const onChange = useCallback(
    (e) => {
      const value = typeof e !== "number" ? e.target.value : e;
      const valueReturned = value || value === 0 ? Number(value) : allowEmpty ? "" : 0;
      field.onChange(transform.output(valueReturned, allowEmpty));
    },
    [field, allowEmpty]
  );

  return (
    <NumberInput
      className={`${className || ""} error-field`}
      id={name}
      labelText={labelText}
      helperText={helperText}
      min={min}
      max={max}
      onChange={onChange}
      step={step}
      allowEmpty={allowEmpty}
      value={allowZero ? field.value : transform.input(field.value)}
      invalid={invalid || error}
      invalidText={invalidText || (error && error.message)}
      inputRef={field.ref}
    />
  );
};

export default ValidatedNumberInputHookForm;
