import React from "react";
import { injectIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { Text } from "components";
import messages from "containers/Menu/messages";
import { WeekNumberContainer } from "./styles";

function WeekNumber({ week, intl }) {
  return (
    <WeekNumberContainer>
      <Text defaultColor={colors["brand-01-60"].hex} value={intl.formatMessage(messages.week, { week })} />
    </WeekNumberContainer>
  );
}

export default injectIntl(WeekNumber);
