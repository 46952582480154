import React, { useCallback, useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { colors } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { Text } from "components";
import { UISelect } from "components/Form/SelectHookForm/styles";
import { ColStyled, RowStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useMonths, getPastYears } from "utils/utils";
import messages from "./messages";

function Validity({ intl }) {
  const { control, setValue } = useFormContext();

  const priceValidity = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.PRICE_SOURCE_VALIDITY}`,
  });

  const year = useMemo(() => (priceValidity ? moment(priceValidity).year() : null), [priceValidity]);
  const month = useMemo(() => (priceValidity ? moment(priceValidity).month() + 1 : null), [priceValidity]);

  const formatDate = useCallback((date) => moment(date).format("YYYY-MM-DD"), []);
  const setPriceValidityValue = useCallback(
    (value) => setValue(`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.PRICE_SOURCE_VALIDITY}`, value),
    [setValue]
  );

  const onChangeYear = useCallback(
    ({ value }) => {
      if (!value) {
        return setPriceValidityValue(null);
      }
      setPriceValidityValue(formatDate(`${value}-${month || "01"}-01`));
    },
    [month, formatDate, setPriceValidityValue]
  );

  const onChangeMonth = useCallback(
    ({ value }) => {
      if (!value) {
        return setPriceValidityValue(formatDate(`${year}-01-01`));
      }
      setPriceValidityValue(formatDate(`${year}-${value}-01`));
    },
    [year, formatDate, setPriceValidityValue]
  );

  const years = useMemo(
    () => [
      {
        value: null,
        label: <FormattedMessage {...messages.optionAllYears} />,
      },
      ...getPastYears(moment().year(), 10),
    ],
    []
  );

  const months = [
    {
      value: null,
      label: <FormattedMessage {...messages.optionAllMonths} />,
    },
    ...useMonths(intl),
  ];

  return (
    <>
      <Text fontSize="16px" bold marginBottom="5px" value={intl.formatMessage(messages.priceValidity)} />
      <Text
        wrap
        marginBottom="5px"
        defaultColor={colors["text-02"].hex}
        value={intl.formatMessage(messages.priceValidityInfo)}
      />
      <RowStyled middle="xs">
        <ColStyled xs={2} padding="0" margin="0 8px 0 0">
          <UISelect
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            value={years.find((el) => el.value === year)}
            options={years}
            onChange={onChangeYear}
          />
        </ColStyled>

        <ColStyled xs={2} padding="0" className={!year ? "disable" : ""}>
          <UISelect
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            isDisabled={!year}
            value={months.find((el) => el.value === month)}
            options={!year ? months : months.slice(1)}
            onChange={onChangeMonth}
          />
        </ColStyled>
      </RowStyled>
    </>
  );
}

export default injectIntl(Validity);
