import { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Text, Button } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { useUserProvidedNumbers } from "containers/GeneralInfo/GeneralForm/hooks";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import BoxNumber from "./BoxNumber";
import FoodBasketModal from "./FoodBasketModal";
import { selectLoadingBasket } from "../../selectors";

const FoodBasket = () => {
  const loadingBasket = useSelector(selectLoadingBasket);

  const { groupsCount, itemsCount, personalItemsCount, customItemsCount, customSourcesNumber } =
    useUserProvidedNumbers();

  const [openFoodBasketModal, setOpenFoodBasketModal] = useState(false);

  const openModal = useCallback(() => setOpenFoodBasketModal(true), []);

  return (
    <>
      <ColStyled xs={5}>
        <RowStyled middle="xs" center="xs">
          <ColStyled xs>
            <Text
              textAlign="left"
              bold
              marginBottom="10px"
              fontSize="16px"
              value={<FormattedMessage {...messages.foodBasketTitle} />}
            />
            <Text
              textAlign="left"
              regularWrap
              marginBottom="10px"
              value={<FormattedMessage {...messages.foodBasketInfo} />}
            />

            <RowStyled center="xs" margin="0 0 15px">
              <ColStyled xs={6} padding="0 5px 0 0">
                <BoxNumber
                  loading={loadingBasket}
                  value={groupsCount}
                  text={<FormattedMessage {...menuMessages.foodGroups} />}
                />
              </ColStyled>
              <ColStyled xs={6} padding="0 0 0 5px">
                <BoxNumber
                  loading={loadingBasket}
                  value={itemsCount + customItemsCount}
                  personalItemsCount={personalItemsCount}
                  customItemsCount={customItemsCount}
                  customSourcesNumber={customSourcesNumber}
                  text={<FormattedMessage {...menuMessages.foodItems} />}
                />
              </ColStyled>
            </RowStyled>

            <Button
              disabled={loadingBasket}
              children={<FormattedMessage {...buttonMessages.viewFoodBasket} />}
              onClick={openModal}
            />
          </ColStyled>
        </RowStyled>
      </ColStyled>

      <FoodBasketModal open={openFoodBasketModal} setOpenFoodBasketModal={setOpenFoodBasketModal} />
    </>
  );
};

export default FoodBasket;
