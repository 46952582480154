import React, { useMemo, useState, useCallback, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import messages from "containers/Results/messages";
import { Text, Select } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { USD } from "utils/constants";
import { saveInResultsPdf } from "containers/PDF/actions";
import { useResponsiveHook } from "hooks";

function TotalCost({ totalCost, mealCount, localCurrency, conversionRate, onlyPreview, intl }) {
  const [currency, setCurrency] = useState(USD);
  const options = useMemo(() => [USD, { label: localCurrency, value: localCurrency }], [localCurrency]);
  const useCurrency = R.equals(USD, currency) ? 1 : conversionRate;
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();

  const renderByKey = useCallback(
    (key, conversionRate) => {
      return ((totalCost[key] / Number(mealCount)) * conversionRate).toFixed(4);
    },
    [mealCount, totalCost],
  );

  const renderCost = useMemo(() => renderByKey("cost", useCurrency), [renderByKey, useCurrency]);
  const renderWithoutAdditional = useMemo(() => renderByKey("costWithoutAdd", useCurrency), [renderByKey, useCurrency]);

  useEffect(() => {
    //Save this information in redux to display it in the pdf
    if (!onlyPreview && R.equals(USD, currency)) {
      dispatch(
        saveInResultsPdf({
          costs: [
            { total: renderByKey("cost", 1), withoutAdditional: renderByKey("costWithoutAdd", 1), currency: USD.label },
            {
              total: renderByKey("cost", conversionRate),
              withoutAdditional: renderByKey("costWithoutAdd", conversionRate),
              currency: localCurrency,
            },
          ],
        }),
      );
    }
  }, [conversionRate, currency, dispatch, localCurrency, onlyPreview, renderByKey]);

  return (
    <>
      <RowStyled middle="xs">
        <ColStyled>
          <Text value={renderCost} fontSize="28px" bold />
        </ColStyled>
        <ColStyled xs>
          <Select wfpStyle defaultValue={currency} options={options} onChange={setCurrency} />
        </ColStyled>
      </RowStyled>
      {isTabletOrMobile ? (
        <Text
          wrap
          marginBottom="6px"
          value={intl.formatMessage(messages.excludingAdditional, {
            price: `${renderWithoutAdditional} ${currency.value}`,
          })}
        />
      ) : (
        <RowStyled middle="xs">
          <Text
            wrap
            marginBottom="6px"
            value={intl.formatMessage(messages.excludingAdditional, {
              price: `${renderWithoutAdditional} ${currency.value}`,
            })}
          />
        </RowStyled>
      )}
    </>
  );
}

export default injectIntl(TotalCost);
