import { useMemo, useCallback, memo } from "react";
import * as R from "ramda";
import { useFormContext } from "react-hook-form";

import { RangeHookForm } from "components/Form";
import { newColorByCategory } from "components/utils";
import { renameVitamins } from "containers/Menu/utils";
import { SliderContainer, Label } from "./styles";
import { targetOptions } from "../constants";

const Nutrient = ({ name, nutrient }) => {
  const { control } = useFormContext();

  // Covered area
  const trackStyle = useMemo(
    () =>
      Array(nutrient.percentage.length).fill({
        backgroundColor: newColorByCategory[nutrient.category]["backgroundColor"],
        height: 4,
        borderRadius: 0,
        width: 6,
      }),
    [nutrient.category, nutrient.percentage.length]
  );

  // Uncovered area
  const railStyle = useMemo(
    () => ({
      width: 6,
    }),
    []
  );

  const handleStyle = useMemo(
    () =>
      Array(nutrient.percentage.length).fill({
        height: 20,
        width: 20,
        marginLeft: -7,
        border: 0,
        backgroundColor: newColorByCategory[nutrient.category]["backgroundColor"],
      }),
    [nutrient.category, nutrient.percentage.length]
  );

  const dotStyle = useMemo(
    () => ({
      marginLeft: -3,
    }),
    []
  );

  const marks = useMemo(() => targetOptions.reduce((acc, el) => ({ ...acc, [el.value]: "" }), {}), []);

  // Already selected marks
  const getActiveDotStyle = useCallback((category) => {
    const borderColor = newColorByCategory[category]["backgroundColor"];
    return { borderColor };
  }, []);

  const NutrientLabel = useMemo(
    () => (
      <Label color={newColorByCategory[nutrient.category]["backgroundColor"]}>{renameVitamins(nutrient.label)}</Label>
    ),
    [nutrient.category, nutrient.label]
  );

  return (
    <SliderContainer>
      <RangeHookForm
        control={control}
        name={`${name}.percentage`}
        allowCross={false}
        pushable={25}
        step={25}
        trackStyle={trackStyle}
        railStyle={railStyle}
        handleStyle={handleStyle}
        dotStyle={dotStyle}
        activeDotStyle={getActiveDotStyle(nutrient.category)}
        marks={marks}
        vertical
        label={NutrientLabel}
      />
    </SliderContainer>
  );
};

const nutrientPropsAreEqual = (prevProps, nextProps) => {
  const prevPercentage = prevProps.nutrient.percentage;
  const nextPercentage = nextProps.nutrient.percentage;
  return R.equals(prevPercentage, nextPercentage);
};

export default memo(Nutrient, nutrientPropsAreEqual);
