import * as R from "ramda";
import validation from "./Header/validation";

export const CUSTOM_KEYS = {
  PRICE: "price",
  PRICE_WITHOUT_ADD: "price_without_additional",
};

export const KEYS = {
  USD_PRICE: "usd_price_per_gram",
  USD_ADD_COST: "usd_additional_cost_per_gram",
  QUANTITY: "quantity",
  MAJOR_GROUP: "major_group",
  DISPLAY_NAME: "display_name",
};

export const FIELDS = {
  DRAFT: "draft",
  FILTERS: "filters",
  FOOD_GROUP: "food_group",
  NUTRIENT: "nutrient",
};

export const DEFAULTS = {
  FOOD_GROUP: 16,
  NUTRIENT: 1,
};

export const getPrices = (item) => ({
  [CUSTOM_KEYS.PRICE]: (Number(item[KEYS.USD_PRICE]) + Number(item[KEYS.USD_ADD_COST])) * Number(item[KEYS.QUANTITY]),
  [CUSTOM_KEYS.PRICE_WITHOUT_ADD]: Number(item[KEYS.USD_PRICE]) * Number(item[KEYS.QUANTITY]),
});

export const calcInitialSelectedFoods = (selectedFoods, initial = true) => {
  const keysToCycle = R.groupBy((item) => item["display_name"], selectedFoods);

  // Labels of user selected food items
  const foodItemsLabels = Object.keys(keysToCycle);

  // Setup of selected food items properties
  const itemsWithPrices = foodItemsLabels
    .map((label) => {
      return selectedFoods.find((selectedFood) => selectedFood.display_name === label);
    })
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.display_name]: {
          ...item,
          price_currency: "USD",
          quantity: "100 g",
          price: parseFloat(((item.usd_price_per_gram + item.usd_additional_cost_per_gram) * 100).toFixed(2)),
          additional_costs: initial ? [] : item.additional_costs,
        },
      }),
      {},
    );

  return itemsWithPrices;
};

export const initial = (mealCount, selectedFoods) => {
  const initialFoods = calcInitialSelectedFoods(selectedFoods);

  const emptyMenu = new Array(mealCount).fill().reduce((acc, _, index) => ({ ...acc, [`day_${index + 1}`]: [] }), 0);
  emptyMenu.draft = {
    week: Object.keys(emptyMenu),
    filters: {
      food_group: DEFAULTS.FOOD_GROUP,
      nutrient: DEFAULTS.NUTRIENT,
    },
  };
  emptyMenu.foods = { ...initialFoods };
  emptyMenu.draftFoods = { ...initialFoods };

  const refactoredResults = selectedFoods.map((item) => ({
    ...item,
    ...getPrices(item),
  }));

  const existingMenu = R.groupBy(
    (item) => `day_${item.day}`,
    R.sortBy((key) => key.day, refactoredResults),
  );
  return R.isEmpty(selectedFoods) ? emptyMenu : { ...emptyMenu, ...existingMenu };
};

export const getPercentageByTotal = (list, key, total) =>
  (list.reduce((acc, el) => (acc = acc + el[key]), 0) / Number(total || 1)) * 100;

export const transformNutrients = (nutrients) =>
  nutrients.map((nutrient) => ({
    value: nutrient.id,
    label: nutrient.nutrient_display,
  }));

export const getOrderedNutrients = (nutrients, nutrient) => {
  let incomingNutrients = { ...nutrients };

  // Remove nutrients that doesn't have information for desired nutrient
  Object.keys(incomingNutrients).forEach((key) => {
    const filteredNutrients = incomingNutrients[key].filter((item) => item.nutrient === nutrient);

    if (filteredNutrients.length > 0) {
      incomingNutrients[key] = filteredNutrients;
    } else {
      delete incomingNutrients[key];
    }
  });

  const arrayOfNutrients = Object.keys(incomingNutrients).map((nutrient) => ({
    food_item: nutrient,
    ...incomingNutrients[nutrient][0],
  }));
  const sortingFunction = R.sortWith([R.descend(R.prop("normalized_value"))]);
  const orderedNutrients = sortingFunction(arrayOfNutrients);

  return orderedNutrients;
};

export const calcDisableSave = (initialValues, values) => {
  const equalValues = R.equals(initialValues, values);
  let validationIsFine = true;

  if (values.foods !== undefined) {
    validationIsFine = R.isEmpty(validation(R.clone(values.foods)));
  }

  return equalValues || !validationIsFine;
};

export const calcGraphValues = (allValues, nutrients, nutritionalRequirements, nutrientRestrictions, mealCount) => {
  if (R.isEmpty(allValues) || !nutrients || R.isEmpty(nutritionalRequirements)) return [];

  // Iterate over nutritionalRequirements: if we find out that a nutrient from nutrientRestrictions is also in nutritionalRequirements, we use the nutritionalRequirements values to get item percentage and category
  let result = [];

  nutritionalRequirements.forEach((nutritionalReq) => {
    let item = {};
    const nutrientRestrictionIndex = nutrientRestrictions.findIndex(
      (restriction) => nutritionalReq.nutrient === restriction.nutrient,
    );

    if (nutrientRestrictionIndex !== -1) {
      item.label = nutritionalReq.nutrient_display;
      item.category = nutritionalReq.category;
      item.restriction = nutrientRestrictions[nutrientRestrictionIndex].percentage;
      item.percentage = Math.round(
        (allValues.reduce((acc, { food_item, edible_portion_size_scaling_factor: edible, quantity }) => {
          const findNutrient =
            nutrients[food_item] && nutrients[food_item].find((el) => el.nutrient === nutritionalReq.nutrient);
          return acc + (findNutrient ? Number(findNutrient.value) : 0) * edible * (quantity / 100);
        }, 0) *
          100) /
          nutritionalReq.value_range[0] /
          mealCount,
      );

      result.push(item);
    }
  });

  return result;
};
