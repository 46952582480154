import React from "react";
import { useIntl } from "react-intl";
import { StepNavigation } from "@wfp/ui";
import { useHtmlHook } from "hooks";
import { StepNavigationItem } from "./styles";
import messages from "./messages";

const noop = () => null;

function NavigationBar({ page, labels }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  return (
    <StepNavigation selectedPage={page} handleTabClick={noop}>
      {labels.map((label, index) => (
        <StepNavigationItem
          iconDescription=" "
          key={`step_${index}`}
          label={intl.formatMessage(messages[label])}
          page={index}
          status={page >= index + 1 ? "complete" : "locked"}
          isRTL={isRTL}
        />
      ))}
    </StepNavigation>
  );
}

export default NavigationBar;
