import { FIELDS } from "../constants";

// At least one selection
export const validation = (values) => {
  const sources = values[FIELDS.SOURCES];
  if (!sources) return true;

  const numOfCheckedSources = sources.filter((source) => source.checked).length;

  if (numOfCheckedSources < 1) return true;

  return false;
};
