import React from "react";
import { useSelector } from "react-redux";
import routesPath from "containers/App/Routes/paths";
import { useLocation, Redirect } from "react-router-dom";
import { selectIsInactive } from "containers/App/selectors";
import { isInTest } from "utils/testUtils";

export default (WrappedComponent) => (props) => {
  if (isInTest) return <WrappedComponent {...props} />;
  const location = useLocation();
  const isUserInactive = useSelector(selectIsInactive);
  const hasToken = localStorage.getItem("token");
  const mustSelectCountry = JSON.parse(localStorage.getItem("mustSelectCountry") || "false");
  const isSelectingCountry = location.pathname === routesPath.selectCountry;
  if (!hasToken) return <Redirect to={routesPath.ciamLogin} />;
  if (isUserInactive) return <Redirect to={routesPath.notActive} />;
  if (!isSelectingCountry && mustSelectCountry) return <Redirect to={routesPath.selectCountry} />;
  return <WrappedComponent {...props} />;
};
