import { useEffect, useMemo, memo } from "react";
import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import { selectGroupsAndItems } from "containers/OptimizedMenu/Rules/FoodRules/selectors";
import { FIELDS, GROUP_RESTRICTION_PATH, GROUP_VALIDATION_PATH } from "../constants";
import MatchOrUnmatch from "../MatchOrUnmatch";
import PortionNumber from "./PortionNumber";
import PortionSize from "../PortionSize";

const GroupRestriction = ({ daysInWeek, name, weekCount, restrictionAttributeName, exclutionChoice }) => {
  const { control, setValue } = useFormContext();

  const dailyRepetition = useWatch({ control, name: `${GROUP_RESTRICTION_PATH}.${FIELDS.DAILY_REPETITION}` });
  const minMaxRepetition = useWatch({ control, name: `${GROUP_VALIDATION_PATH}.${FIELDS.MAXIMUM_REQUIRED}` });
  const dailyMax = useWatch({ control, name: `${GROUP_VALIDATION_PATH}.${FIELDS.NUMBER_ITEMS_IN_GROUP}` });
  const dailyRequired = useWatch({ control, name: `${GROUP_VALIDATION_PATH}.${FIELDS.DAILY_REQUIRED}` });
  const weeklyRequired = useWatch({ control, name: `${GROUP_VALIDATION_PATH}.${FIELDS.WEEKLY_REQUIRED}` });
  const enabledMaxRepetition = useWatch({ control, name: `${GROUP_RESTRICTION_PATH}.${FIELDS.ENABLED_REP_MAX}` });
  const maxRepetition = useWatch({ control, name: `${GROUP_RESTRICTION_PATH}.${FIELDS.MAXIMUM_REPETITION}` });

  const groupsAndItems = useSelector(selectGroupsAndItems);

  const minWeeklySetByDaily = useMemo(() => dailyRepetition[0] * daysInWeek, [dailyRepetition, daysInWeek]);
  const maxWeeklySetByDaily = useMemo(
    () => (dailyRepetition[1] || dailyMax) * daysInWeek,
    [dailyMax, dailyRepetition, daysInWeek]
  );
  const minWeekly = useMemo(
    () => Math.max(minWeeklySetByDaily, weeklyRequired[0]),
    [minWeeklySetByDaily, weeklyRequired]
  );
  const maxWeekly = useMemo(
    () => Math.min(maxWeeklySetByDaily, weeklyRequired[1]),
    [maxWeeklySetByDaily, weeklyRequired]
  );

  const calcMaxRepetition = useMemo(
    () => Math.ceil(((daysInWeek * weekCount) / (dailyMax || 1)) * dailyRepetition[0]),
    [dailyMax, dailyRepetition, daysInWeek, weekCount]
  );

  const excludeSelected = exclutionChoice === "exclude"; 
  const noRuleSelected = exclutionChoice === "no-rule";

  const options = useMemo(() => {
    if (R.isEmpty(groupsAndItems)) return [];
    return groupsAndItems.major_groups
      .map((el) => ({ value: el.major_id, label: el.major_label }))
      .filter((el) => el.label !== name);
  }, [groupsAndItems, name]);

  // When changing "daily_repetition" value: update WEEKLY_REPETITION.
  useEffect(() => {
    setValue(`${GROUP_RESTRICTION_PATH}.${FIELDS.WEEKLY_REPETITION}[0]`, Math.min(minWeekly, maxWeekly));
    setValue(`${GROUP_RESTRICTION_PATH}.${FIELDS.WEEKLY_REPETITION}[1]`, maxWeekly);
  }, [dailyRepetition, maxWeekly, minWeekly, setValue]);

  // When changing "dailyRepetition" value: update MAXIMUM_REPETITION.
  useEffect(() => {
    if (enabledMaxRepetition) {
      setValue(
        `${GROUP_RESTRICTION_PATH}.${FIELDS.MAXIMUM_REPETITION}`,
        Math.max(
          Math.max(Math.max(minMaxRepetition, calcMaxRepetition, 1), maxRepetition || 1),
          Math.min(Math.max(daysInWeek * weekCount, Math.max(minMaxRepetition, calcMaxRepetition)), maxRepetition || 1)
        )
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcMaxRepetition, dailyRepetition, minMaxRepetition, daysInWeek, weekCount, setValue]);

  return (
    <RowStyled>
      <ColStyled xs={12}>
        <RowStyled margin="0 0 30px">
          <ColStyled xs={5} className={(excludeSelected || noRuleSelected) ? "disable" : ""}>
            <PortionNumber
              title={<FormattedMessage {...messages.groupDayRepetition} />}
              name={`${GROUP_RESTRICTION_PATH}.${FIELDS.DAILY_REPETITION}`}
              checkboxName={`${GROUP_RESTRICTION_PATH}.${FIELDS.ENABLED_DAILY_MAX}`}
              min={dailyRequired[0]}
              max={dailyRequired[1]}
              helper="DailyHelper"
            />
          </ColStyled>
          <ColStyled xs={7} className={excludeSelected ? "disable" : ""}>
            <PortionSize kindRestriction="group" />
          </ColStyled>
        </RowStyled>

        <RowStyled margin="0 0 30px" className={(excludeSelected || noRuleSelected)  ? "disable" : ""}>
          <ColStyled xs={5}>
            <PortionNumber
              title={<FormattedMessage {...messages.groupWeeklyRepetition} />}
              name={`${GROUP_RESTRICTION_PATH}.${FIELDS.WEEKLY_REPETITION}`}
              checkboxName={`${GROUP_RESTRICTION_PATH}.${FIELDS.ENABLED_WEEKLY_MAX}`}
              min={Math.min(minWeekly, maxWeekly)}
              max={maxWeekly}
              helper="WeeklyHelper"
            />
          </ColStyled>
          {restrictionAttributeName === "major_group_id" && (
            <ColStyled xs={5}>
              <MatchOrUnmatch keyField="unmatching" entity="Groups" name={name} options={options} />
            </ColStyled>
          )}
        </RowStyled>

        <RowStyled margin="0 0 30px" className={(excludeSelected || noRuleSelected)  ? "disable" : ""}>
          <ColStyled xs={12}>
            <PortionNumber
              onlyOneField
              title={<FormattedMessage {...messages.groupMaxRepetition} />}
              name={`${GROUP_RESTRICTION_PATH}.${FIELDS.MAXIMUM_REPETITION}`}
              checkboxName={`${GROUP_RESTRICTION_PATH}.${FIELDS.ENABLED_REP_MAX}`}
              min={Math.max(minMaxRepetition, calcMaxRepetition, 1)}
              max={Math.max(daysInWeek * weekCount, Math.max(minMaxRepetition, calcMaxRepetition))}
              helper="PeriodHelper"
            />
          </ColStyled>
        </RowStyled>
      </ColStyled>
    </RowStyled>
  );
};

export default memo(GroupRestriction);
