import messages from "containers/Admin/Countries/messages";

export const allRecipesFields = (intl) => [
  {
    name: "name",
    type: "text",
    label: intl.formatMessage(messages.recipeName),
  },
  {
    name: "author_full_name",
    type: "text",
    label: intl.formatMessage(messages.authorName),
  },
];
