import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import { RowStyled, ColStyled } from "components/utils";
import messages from "components/Button/messages";

const Footer = ({ closeFiltersAndFetchFoodItems, closeFilters }) => {
  const intl = useIntl();
  const { formState } = useFormContext();

  const disableApply = useMemo(() => Object.keys(formState.dirtyFields).length === 0, [formState]);

  return (
    <ColStyled xs margin="0.7rem 0" padding="0">
      <RowStyled end="xs">
        <Button
          widthAuto
          kind="secondary"
          marginRight="0.5rem"
          children={intl.formatMessage(messages.close)}
          onClick={closeFilters}
        />
        <Button
          widthAuto
          disabled={disableApply}
          children={intl.formatMessage(messages.apply)}
          onClick={closeFiltersAndFetchFoodItems}
        />
      </RowStyled>
    </ColStyled>
  );
};

export default Footer;
