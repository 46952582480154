import * as R from "ramda";

const iterateOverChildren = (children, data = {}) => {
  for (const [key, value] of Object.entries(children)) {
    data[key] = {
      data: value.menu_name,
      index: key,
      hasChildren: !R.isEmpty(value.children),
      children: !R.isEmpty(value.children) ? Object.keys(value.children) : [],
    };

    if (!R.isEmpty(value.children)) {
      iterateOverChildren(value.children, data);
    }
  }

  return data;
};

// "index" must be a String otherwise opening/closing trees won't work.
export const createTreeStructure = (parents, children, menuId) => {
  let tree = {};

  // I. I reverse it before working on the structure. Doing so will make possible to just iterate over all items in step II.
  if (!R.isEmpty(parents)) {
    const reversedParents = R.reverse(parents);
    const root = {
      data: "root",
      hasChildren: true,
      index: "root",
      children: [String(R.head(reversedParents).menu_id)],
    };
    tree = { ...tree, root };

    // II. Iterate over all parents and create objects.
    reversedParents.forEach((item, i) => {
      tree = {
        ...tree,
        [item.menu_id]: {
          data: item.menu_name,
          hasChildren: true,
          index: String(item.menu_id),
          // If this is last item of reversedParents, we won't have the children id available in the list. Otherwise, we can get it from subsequent item.
          children: i === reversedParents.length - 1 ? [String(menuId)] : [String(reversedParents[i + 1].menu_id)],
        },
      };
    });

    // III. Handle "This menu".
    const childrenNames = Object.keys(children);
    tree = {
      ...tree,
      [menuId]: {
        data: "This menu",
        index: menuId,
        hasChildren: childrenNames.length > 0,
        children: childrenNames,
      },
    };
  } else {
    // If we don't have parents we need to mark current menu as the "root" node! Otherwise the library responsible of showing the tree view will fail.
    const childrenNames = Object.keys(children);

    // III. Handle "This menu".
    const root = {
      data: "root",
      hasChildren: childrenNames.length > 0,
      index: "root",
      children: [menuId],
    };
    tree = {
      ...tree,
      root,
      [menuId]: {
        data: "This menu",
        index: menuId,
        hasChildren: childrenNames.length > 0,
        children: childrenNames,
      },
    };
  }

  // IV: Iterate over all children and create objects.
  const managedChildren = iterateOverChildren(children);
  tree = {
    ...tree,
    ...managedChildren,
  };

  return tree;
};
