import { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconDelete, iconEdit, iconCheckmarkOutline } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Text } from "components";
import buttonMessages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { FIELDS } from "containers/Results/constants";
import { useBoolean } from "hooks";
import FoodItem from "../FoodItem";
import { RecipeTitleContainer, TextareaAutosizeStyled } from "./styles";

const Recipe = ({ recipe, remove, index }) => {
  const intl = useIntl();
  const { setValue } = useFormContext();

  const [localRecipeName, setLocalRecipeName] = useState(
    recipe.name || intl.formatMessage(menuMessages.recipe, { recipe: index + 1 }),
  );

  // When changing local value make sure to update react-hook-form value as well
  const changeRecipeName = useCallback(
    (e) => {
      setLocalRecipeName(e.target.value || intl.formatMessage(menuMessages.recipe, { recipe: index + 1 }));
      setValue(`${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}.${index}.${FIELDS.NAME}`, e.target.value);
    },
    [index, intl, setValue],
  );

  const [editMode, { toggle: toggleEditMode }] = useBoolean(false);

  return (
    <>
      <RecipeTitleContainer>
        {editMode ? (
          <RowStyled middle="xs" center="xs" flex={1}>
            <ColStyled xs>
              <TextareaAutosizeStyled defaultValue={localRecipeName} onChange={changeRecipeName} />
            </ColStyled>
            <ColStyled>
              <Icon
                onClick={toggleEditMode}
                icon={iconCheckmarkOutline}
                description="change recipe name"
                fill={colors["brand-01"].hex}
                width="18"
                height="18"
                className="pointer"
              />
            </ColStyled>
          </RowStyled>
        ) : (
          <>
            <RowStyled middle="xs">
              <Text fontSize="16px" bold value={localRecipeName} />
              <Icon
                onClick={toggleEditMode}
                icon={iconEdit}
                description="edit name"
                fill={colors["brand-01"].hex}
                width="18"
                height="18"
                className="pointer marginLeftSmall"
              />
            </RowStyled>
            <Icon
              className="pointer"
              description={intl.formatMessage(buttonMessages.remove)}
              icon={iconDelete}
              width="18"
              height="18"
              fill={colors["categorical-corporate-07"].hex}
              onClick={() => remove(index)}
            />
          </>
        )}
      </RecipeTitleContainer>
      {Object.entries(recipe.ingredients).map(([key, item]) => (
        <FoodItem
          key={`recipe-item-${item.display_name}`}
          draggable={false}
          canBeRemoved={false}
          fieldPrefix={`${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}.${index}.${FIELDS.INGREDIENTS}`}
          index={Number(key)}
        />
      ))}
    </>
  );
};

export default Recipe;
