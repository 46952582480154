import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { SecondHeader } from "components";
import { selectMenuName } from "containers/Menu/selectors";
import Actions from "./Actions";
import RenameMenu from "./RenameMenu";

const Header = ({ menuId }) => {
  const menuName = useSelector(selectMenuName);

  const [editMode, setEditMode] = useState(false);
  const [localMenuName, setLocalMenuName] = useState(menuName);

  // Since "menuName" retrieval is async we update localMenuName with useEffect.
  useEffect(() => {
    setLocalMenuName(menuName);
  }, [menuName]);

  return (
    <SecondHeader>
      {editMode ? (
        <RenameMenu
          menuName={menuName}
          menuId={menuId}
          setEditMode={setEditMode}
          localMenuName={localMenuName}
          setLocalMenuName={setLocalMenuName}
        />
      ) : (
        <Actions menuId={menuId} menuName={menuName} setEditMode={setEditMode} />
      )}
    </SecondHeader>
  );
};

export default Header;
