export const Optimized = ({ size = "130" }) => (
  <svg height={size} width={size} viewBox="0 0 215 216" xmlns="http://www.w3.org/2000/svg">
    <g fill="none">
      <circle cx="107.1931" cy="108.06952" fill="#7cccbf" r="105.34495" />
      <path
        d="m26.29224 175.52722c19.3242 23.14978 48.38779 37.88721 80.90086 37.88721s61.57664-14.73743 80.90086-37.88721z"
        fill="#d19391"
      />
      <path
        d="m107.1931 215.26257c-48.08096 0-90.56691-32.32465-103.3181-78.61163-1.66406-5.89099-2.7921-12.03467-3.34979-18.25055-.35195-3.16565-.52521-6.55878-.52521-10.33087 0-59.10492 48.08636-107.19312 107.1931-107.19312s107.1931 48.0882 107.1931 107.19312-48.08636 107.19305-107.1931 107.19305zm0-210.68988c-57.06906 0-103.49678 46.42773-103.49678 103.49683 0 3.63495.16605 6.89447.50716 9.95911.54144 6.03174 1.62796 11.94806 3.23067 17.62964 12.31442 44.69873 53.33484 75.90802 99.75896 75.90802 57.06908 0 103.49678-46.42773 103.49678-103.49677-.00001-57.06909-46.42771-103.49683-103.49679-103.49683z"
        fill="#4b4145"
      />
      <path
        d="m212.03088 177.37537h-209.67556c-1.02155 0-1.84816-.8266-1.84816-1.84814s.82661-1.84814 1.84816-1.84814h209.67557c1.02155 0 1.84814.8266 1.84814 1.84814s-.8266 1.84814-1.84815 1.84814z"
        fill="#4b4145"
      />
      <g transform="translate(138 93)" id="celery1">
        <path
          d="m7.877377 2.508083h8.062257v17.942272h-8.062257z"
          fill="#68c187"
          transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 28.446125 11.175667)"
        />
        <path
          d="m15.40424 22.5235c-.49091 0-.96017-.19495-1.3067-.54144l-12.68622-12.68805c-.72192-.72192-.72192-1.89148 0-2.6134l5.69968-5.69971c.72195-.72192 1.89148-.72192 2.6134 0l12.68805 12.68445c.72192.72192.72192 1.89148 0 2.6134l-5.70151 5.70331c-.34653.34649-.81577.54144-1.3067.54144zm-10.07282-14.5362 10.07281 10.07465 3.0881-3.0899-10.07464-10.07105z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(101 108)" id="onion2">
        <path
          d="m7.20486 2.954587h8.062257v17.942272h-8.062257z"
          fill="#fff2bc"
          transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 10.748272 28.303524)"
        />
        <path
          d="m7.74165 22.9693c-.47287 0-.94574-.18048-1.3067-.54144l-5.70149-5.69971c-.34653-.3465-.54146-.81573-.54146-1.3067 0-.49091.19493-.96014.54146-1.3067l12.68803-12.68799c.34653-.34656.81578-.54144 1.30669-.54144.49094 0 .96017.19489 1.30673.54144l5.69968 5.70325c.72192.72198.72192 1.89148 0 2.61346l-12.68623 12.68439c-.36097.36096-.83384.54144-1.30671.54144zm-3.08807-7.54785 3.08807 3.0863 10.07283-10.07098-3.0863-3.0899z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(132 152)">
        <path
          d="m14.28293 2.18085h-11.99878v9.37408h9.43646c1.41513 0 2.56232 1.14716 2.56232 2.56232v9.43646h9.37408v-11.99878-2.93713c0-3.55505-2.88193-6.43695-6.43695-6.43695z"
          fill="#7d9297"
        />
        <path
          d="m23.65668 25.40063h-9.37433c-1.02155 0-1.84818-.8266-1.84818-1.84814v-9.43573c0-.39343-.31946-.71472-.71289-.71472h-9.43753c-1.02155 0-1.84814-.8266-1.84814-1.84814v-9.37433c0-1.02155.8266-1.84814 1.84814-1.84814h14.93686c4.56805 0 8.28424 3.71796 8.28424 8.28784v14.93323c0 1.02154-.82663 1.84813-1.84817 1.84813zm-7.52619-3.69628h5.67804v-13.08508c0-2.5304-2.05753-4.59155-4.58792-4.59155h-13.08868v5.67804h7.58936c2.43112 0 4.40921 1.97809 4.40921 4.41101v7.58758z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(56 152)">
        <path
          d="m12.10326 2.18085h11.99881v9.37408h-9.43648c-1.41513 0-2.56233 1.14716-2.56233 2.56232v9.43646h-9.37405v-11.99878-2.93713c0-3.55505 2.88191-6.43695 6.43694-6.43695z"
          fill="#7d9297"
        />
        <path
          d="m12.10387 25.40063h-9.37434c-1.02155 0-1.84816-.8266-1.84816-1.84814v-14.93323c0-4.56989 3.71617-8.28784 8.28421-8.28784h14.93687c1.02153 0 1.84814.8266 1.84814 1.84814v9.37433c0 1.02155-.82661 1.84814-1.84814 1.84814h-9.43752c-.39346 0-.71292.32129-.71292.71472v9.43573c0 1.02156-.82661 1.84815-1.84814 1.84815zm-7.5262-3.69628h5.67804v-7.58759c0-2.43292 1.9781-4.41101 4.40923-4.41101h7.58936v-5.67804h-13.08871c-2.53038 0-4.58791 2.06116-4.58791 4.59155z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(86 155)" id="fire1">
        <path
          d="m9.48811 2.16168c-4.03586 1.87-6.85164 5.92932-6.85164 10.66974 0 .52673.03477 1.04504.10211 1.55292.98918 7.46039 12.50986 7.46039 13.49905 0 .06734-.50787.1021-1.02618.1021-1.55292.00001-4.74042-2.81577-8.79974-6.85162-10.66974z"
          fill="#ffcd7e"
        />
        <path
          d="m9.48811 7.99878c-1.92841 1.21594-3.27383 3.85535-3.27383 6.93768 0 .34253.01662.6795.04878 1.00977.47266 4.85089 5.97743 4.85089 6.45009 0 .03218-.33026.04878-.66724.04878-1.00977 0-3.08233-1.34543-5.72174-3.27382-6.93768z"
          fill="#f7941d"
        />
        <path
          d="m9.48691 21.82672c-4.49045 0-8.01891-2.95996-8.58022-7.20135-.07761-.58472-.11913-1.18396-.11913-1.79401 0-5.27008 3.10974-10.11792 7.92506-12.34869.49091-.22382 1.06125-.22382 1.55215 0 4.81352 2.23077 7.92326 7.07861 7.92326 12.34869 0 .61005-.03972 1.21289-.11913 1.80127-.56129 4.23413-4.08974 7.19409-8.58199 7.19409zm.0018-17.58636c-3.06642 1.76154-5.00482 5.04633-5.00482 8.591 0 .44403.03069.8844.08662 1.31036.38985 2.9455 2.89317 3.98865 4.9164 3.98865 2.02502 0 4.52834-1.04315 4.91818-3.98865.05775-.42957.08664-.86633.08664-1.31036 0-3.54468-1.9384-6.82947-5.00302-8.591z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(110 155)" id="fire2">
        <path
          d="m8.8981 2.16168c-4.03586 1.87-6.85164 5.92932-6.85164 10.66974 0 .52673.03477 1.04504.10211 1.55292.98918 7.46039 12.50987 7.46039 13.49904 0 .06735-.50787.10211-1.02618.10211-1.55292.00001-4.74042-2.81576-8.79974-6.85162-10.66974z"
          fill="#ffcd7e"
        />
        <path
          d="m8.8981 7.99878c-1.92841 1.21594-3.27383 3.85535-3.27383 6.93768 0 .34253.0166.6795.0488 1.00977.47263 4.85089 5.97742 4.85089 6.45007 0 .03217-.33026.0488-.66724.0488-1.00977 0-3.08233-1.34543-5.72174-3.27384-6.93768z"
          fill="#f7941d"
        />
        <path
          d="m8.89929 21.82672c-4.49225 0-8.02071-2.95996-8.58202-7.20135-.07941-.58112-.11911-1.18396-.11911-1.79401 0-5.27008 3.10974-10.11792 7.92323-12.34869.49094-.22382 1.06125-.22382 1.55219 0 4.81531 2.23077 7.92505 7.07861 7.92505 12.34869 0 .61005-.0415 1.20929-.11911 1.79401-.56131 4.24139-4.08978 7.20135-8.58023 7.20135zm-.0018-17.58636c-3.06461 1.76154-5.00302 5.04633-5.00302 8.591 0 .44403.02888.8808.08664 1.3031.38985 2.95276 2.89316 3.99591 4.91818 3.99591 2.02322 0 4.52655-1.04315 4.91638-3.98865.05597-.42596.08664-.86633.08664-1.31036 0-3.54468-1.93838-6.82947-5.00482-8.591z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(0 116)">
        <path d="m33.49785 6.54657h12.70608v9.31794h-12.70608z" fill="#c0bdba" />
        <path
          d="m46.20392 17.71265h-12.70607c-1.02155 0-1.84816-.8266-1.84816-1.84814v-9.31659c0-1.02155.82661-1.84814 1.84816-1.84814h12.70607c1.02155 0 1.84816.8266 1.84816 1.84814v9.31659c0 1.02154-.82662 1.84814-1.84816 1.84814zm-10.85791-3.69629h9.00975v-5.6203h-9.00975z"
          fill="#4b4145"
        />
        <path
          d="m33.4886 2.23438v17.927h-27.83313c-.55453-1.95892-1.03508-3.91809-1.44162-5.914-.88708-3.91809-1.47862-7.91016-1.84816-12.013z"
          fill="#5f6e7d"
        />
        <path d="m33.4886 14.24738v5.914h-27.83313c-.55453-1.95892-1.03508-3.91809-1.44162-5.914z" fill="#53616e" />
        <path
          d="m33.48882 22.00818h-27.83244c-.82661 0-1.55217-.54865-1.77777-1.34277-.56853-2.00336-1.05943-4.00677-1.47455-6.04987-.86452-3.81177-1.48178-7.81854-1.87885-12.21515-.04692-.51617.12634-1.02875.47649-1.41138.35013-.38623.84467-.60278 1.36446-.60278h31.12267c1.02155 0 1.84816.8266 1.84816 1.84814v17.92566c-.00001 1.02155-.82663 1.84815-1.84817 1.84815zm-26.42467-3.69629h24.57651v-14.22937h-27.23323c.37721 3.45087.90784 6.65985 1.60991 9.75696.31043 1.52331.65696 3.00323 1.04681 4.47241z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(77 6)">
        <path
          d="m68.52396 46.35626-36.61798 25.6402c-8.93599 6.25702-21.25237 4.08533-27.50941-4.85065l-2.45139-3.50098 52.79802-36.96954z"
          fill="#d1d3d4"
        />
        <g fill="#f8f8f9" transform="translate(29 34)">
          <path d="m11.26984 28.19171-3.97318 2.78204-6.37504-20.91821 3.97317-2.78204z" />
          <path d="m20.32077 21.85419-3.97321 2.78204-6.37503-20.91821 3.97318-2.78205z" />
        </g>
        <path
          d="m32.47264 65.01898c-8.62245 6.03748-20.33998 4.16235-26.77667-4.00049l-3.75079 2.62634 2.45139 3.50098c6.25696 8.93585 18.57336 11.10773 27.50941 4.85065l36.61798-25.6402-3.09192-4.41571z"
          fill="#f8f8f9"
        />
        <path
          d="m20.60759 77.40814c-6.81688 0-13.52728-3.20898-17.72534-9.20105l-2.45097-3.5014c-.58476-.83746-.38263-1.98895.45482-2.57373l52.79701-36.97034c.40067-.28156.9006-.38623 1.38071-.30682.48187.08301.91144.35736 1.19299.75806l13.78177 19.68359c.58478.8374.38263 1.98889-.45483 2.57367l-36.61841 25.63959c-3.76128 2.63507-8.08027 3.89843-12.35775 3.89843zm-16.08835-13.3089 1.39153 1.98535c5.65999 8.08929 16.84819 10.05658 24.93568 4.40021l35.10414-24.58197-11.66107-16.65503z"
          fill="#4b4145"
        />
        <path
          d="m96.65048 17.78564-32.29697 22.61462-9.61032-13.72498 32.297-22.61462c3.79004-2.65381 9.01379-1.73267 11.6676 2.05731l.00003.00006c2.65381 3.79005 1.7327 9.01381-2.05734 11.66761z"
          fill="#5f6e7d"
        />
        <path
          d="m64.35333 42.24988c-.10651 0-.21478-.01086-.32126-.02887-.4819-.08307-.91147-.35736-1.19302-.75806l-9.61078-13.7276c-.58475-.83746-.3826-1.98895.45483-2.57373l32.29764-22.61462c4.61496-3.23792 11.00772-2.10809 14.24197 2.50873 3.23248 4.62036 2.10626 11.00952-2.51053 14.24377h-.0018l-32.29764 22.61469c-.31222.22015-.68221.33569-1.05941.33569zm-7.03708-15.12097 7.49188 10.6991 30.78336-21.55341h-.0018c2.94913-2.06836 3.66925-6.14728 1.60452-9.09644-2.06293-2.9491-6.14188-3.66742-9.0946-1.60626zm39.33469-9.34186h.0361z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(72 17)" id="carrot1">
        <path
          d="m25.93835 5.99835 6.35832 6.35834-19.60266 19.60266-6.35832-6.35834c-5.41312-5.41315-5.41312-14.18951 0-19.60266h.00002c5.41313-5.41314 14.18953-5.41314 19.60264 0z"
          fill="#e5926c"
        />
        <path
          d="m12.69395 33.80914c-.49092 0-.96017-.19495-1.3067-.5415l-6.35846-6.36023c-2.96715-2.96716-4.60054-6.91254-4.60054-11.10699 0-4.19806 1.63339-8.13983 4.60054-11.10699 2.96716-2.96716 6.91074-4.60236 11.1088-4.60236 4.19446 0 8.13983 1.63519 11.10699 4.60236l6.35846 6.35663c.34653.34656.54144.8158.54144 1.30676 0 .49091-.19492.96014-.54144 1.3067l-19.60239 19.60413c-.34652.34655-.81577.54149-1.3067.54149zm3.44364-30.02173c-3.209 0-6.2267 1.24896-8.49538 3.51941-2.26869 2.26691-3.51764 5.28461-3.51764 8.49359 0 3.20538 1.24895 6.22308 3.51764 8.49359l5.05174 5.05353 16.98897-16.99072-5.05174-5.04999c-2.26869-2.27044-5.28639-3.51941-8.49359-3.51941z"
          fill="#4b4145"
        />
        <ellipse
          cx="22.498105"
          cy="22.159241"
          fill="#de764a"
          rx="13.861243"
          ry="5.062823"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -9.079407 22.398853)"
        />
        <path
          d="m14.51143 34.46246c-1.22188 0-2.29214-.36456-3.12238-1.19482-3.35881-3.3606.86993-10.64856 6.21948-15.99811 2.76683-2.76501 5.78813-4.98492 8.50621-6.24835 4.21069-1.96002 6.39816-1.06482 7.4883.02887 3.35519 3.35706-.87175 10.64496-6.2213 15.99451-4.02479 4.02844-9.14872 7.4179-12.87031 7.4179zm15.96562-20.89642c-.50536 0-1.40416.15521-2.80292.80493-2.30298 1.07208-5.01926 3.0791-7.45218 5.51202-5.57335 5.5769-6.80426 10.18652-6.2213 10.77124.57574.58838 5.19073-.64612 10.76768-6.22308 5.57335-5.5733 6.80424-10.18292 6.22128-10.76764-.03607-.03613-.19852-.09747-.51256-.09747z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(121 66)" id="carrot5">
        <path
          d="m16.7222 2.30573c-1.41998-1.41998-5.72266.58032-9.61066 4.46832-3.88782 3.88782-5.88849 8.19086-4.46851 9.61084l4.12354 4.1236 14.07919-14.07922z"
          fill="#e5926c"
        />
        <path
          d="m6.76648 22.35706c-.47287 0-.94574-.18054-1.3067-.5415l-4.12405-4.12225c-2.66214-2.66754.4512-8.20837 4.46878-12.22595s9.55844-7.13275 12.22418-4.46881l4.12405 4.12225c.34653.34656.54147.8158.54147 1.3067s-.19495.96021-.54147 1.3067l-14.07956 14.08136c-.36096.36095-.83383.5415-1.3067.5415zm8.6145-18.66932c-.897 0-3.66925 1.10095-6.96307 4.39301-3.56094 3.55914-4.55542 6.51184-4.37131 7.14355l2.71265 2.67841 11.47339-11.47522-2.73254-2.73254c-.03608-.0036-.07578-.00721-.11912-.00721zm.17868.02167h.0379z"
          fill="#4b4145"
        />
        <path
          d="m6.76648 22.35706c-.47287 0-.94574-.18054-1.3067-.5415l-4.12405-4.12225c-2.66214-2.66754.4512-8.20837 4.46878-12.22595s9.55844-7.13275 12.22418-4.46881l4.12405 4.12225c.34653.34656.54147.8158.54147 1.3067s-.19495.96021-.54147 1.3067l-14.07956 14.08136c-.36096.36095-.83383.5415-1.3067.5415zm8.6145-18.66932c-.897 0-3.66925 1.10095-6.96307 4.39301-3.56094 3.55914-4.55542 6.51184-4.37131 7.14355l2.71265 2.67841 11.47339-11.47522-2.73254-2.73254c-.03608-.0036-.07578-.00721-.11912-.00721zm.17868.02167h.0379z"
          fill="#4b4145"
        />
        <ellipse
          cx="13.809103"
          cy="13.469972"
          fill="#de764a"
          rx="9.955535"
          ry="3.636267"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -5.480116 13.709774)"
        />
        <path
          d="m8.02988 22.80823c-.99808 0-1.88065-.30322-2.5701-.99268-2.66394-2.66754.4494-8.20837 4.46878-12.22595 4.01758-4.01758 9.55664-7.13275 12.22418-4.46881h.0018c2.66214 2.66754-.4512 8.20844-4.47058 12.22601-2.978 2.97797-6.79343 5.46143-9.65408 5.46143zm11.43185-14.99823c-.93671.03613-3.67645 1.15149-6.91977 4.39301-3.56094 3.55914-4.55542 6.51184-4.37131 7.14355.38983-.05774 3.34076-1.05402 6.8999-4.61316 3.24509-3.24512 4.35868-5.98486 4.39118-6.9234zm.22199.02167h.0379z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(62 82)" id="carrot3">
        <g transform="translate(1)">
          <path
            d="m6.5218 20.82471c1.41998 1.41998 5.72267-.58038 9.61067-4.46838 3.8878-3.88776 5.88849-8.19086 4.46851-9.61084l-4.12355-4.12354-14.07919 14.07916z"
            fill="#e5926c"
          />
          <path
            d="m7.78404 23.12402c-.99808 0-1.87883-.30322-2.56828-.99268l-4.12405-4.12225c-.34654-.34656-.54146-.8158-.54146-1.3067s.19492-.96021.54146-1.3067l14.07956-14.08136c.34653-.34656.81578-.5415 1.3067-.5415s.96017.19495 1.3067.5415l4.12225 4.12585c.90242.9024 1.65866 2.66754.12634 5.9632-.93311 2.00696-2.56468 4.23053-4.59332 6.25916-2.97978 2.97802-6.79523 5.46148-9.6559 5.46148zm.04152-3.60968.0036.0036c-.0018 0-.0018-.0036-.0036-.0036zm-.08122-.07946c.71832.10474 3.62231-.92767 7.0822-4.38574 3.55734-3.55914 4.55362-6.50824 4.36952-7.13995l-2.71088-2.68201-11.47337 11.47522z"
            fill="#4b4145"
          />
        </g>
        <path
          d="m8.78404 23.12402c-.99808 0-1.87883-.30322-2.56828-.99268l-4.12405-4.12225c-.34654-.34656-.54146-.8158-.54146-1.3067s.19492-.96021.54146-1.3067l14.07956-14.08136c.34653-.34656.81578-.5415 1.3067-.5415s.96017.19495 1.3067.5415l4.12225 4.12585c.90242.9024 1.65866 2.66754.12634 5.9632-.93311 2.00696-2.56468 4.23053-4.59332 6.25916-2.97978 2.97802-6.79523 5.46148-9.6559 5.46148zm.04152-3.60968.0036.0036c-.0018 0-.0018-.0036-.0036-.0036zm-.08122-.07946c.71832.10474 3.62231-.92767 7.0822-4.38574 3.55734-3.55914 4.55362-6.50824 4.36952-7.13995l-2.71088-2.68201-11.47337 11.47522z"
          fill="#4b4145"
        />
        <ellipse
          cx="10.440732"
          cy="9.663075"
          fill="#de764a"
          rx="9.955535"
          ry="3.636267"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -3.774806 10.212962)"
        />
        <path
          d="m4.66179 19.00177c-.99808 0-1.88065-.30322-2.57008-.99268 0 0 0 0-.00182 0-2.66214-2.66754.45122-8.20844 4.4706-12.22601 4.01758-4.01398 9.55663-7.13635 12.22418-4.46875 2.66396 2.66754-.4494 8.20837-4.46878 12.22595-2.97798 2.97803-6.79343 5.46149-9.6541 5.46149zm11.41202-15.21844c-.38985.05774-3.34076 1.05402-6.89992 4.61316-3.2451 3.24512-4.35869 5.98486-4.39117 6.9234.93672-.03613 3.67647-1.15149 6.91977-4.39301 3.56094-3.55914 4.5554-6.51184 4.37132-7.14355z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(123 87)" id="carrot4">
        <g transform="translate(0 1)">
          <path
            d="m2.70465 16.02356c3.86914 3.86914 12.74542 6.99121 16.90796 2.82867 4.16269-4.16266 1.04041-13.03876-2.82874-16.9079z"
            fill="#e5926c"
          />
          <path
            d="m14.18054 22.67932c-1.11902 0-2.30661-.14081-3.54834-.42957-3.45987-.79773-6.99918-2.68201-9.23355-4.91998-.72192-.72198-.72192-1.89148 0-2.6134l14.07776-14.07776c.72192-.72198 1.89148-.72198 2.6134 0 2.23621 2.23798 4.12225 5.77545 4.92001 9.23352.99265 4.29553.24905 7.94855-2.09003 10.2876-1.66405 1.66406-3.9905 2.51959-6.73925 2.51959zm-8.74988-6.76819c1.70197 1.2309 3.90927 2.24884 6.03357 2.73615 1.74167.4115 4.94708.79413 6.84216-1.10095 2.77765-2.77948 1.10275-9.08917-1.63519-12.87573z"
            fill="#4b4145"
          />
        </g>
        <path
          d="m14.18054 23.67932c-1.11902 0-2.30661-.14081-3.54834-.42957-3.45987-.79773-6.99918-2.68201-9.23355-4.91998-.72192-.72198-.72192-1.89148 0-2.6134l14.07776-14.07776c.72192-.72198 1.89148-.72198 2.6134 0 2.23621 2.23798 4.12225 5.77545 4.92001 9.23352.99265 4.29553.24905 7.94855-2.09003 10.2876-1.66405 1.66406-3.9905 2.51959-6.73925 2.51959zm-8.74988-6.76819c1.70197 1.2309 3.90927 2.24884 6.03357 2.73615 1.74167.4115 4.94708.79413 6.84216-1.10095 2.77765-2.77948 1.10275-9.08917-1.63519-12.87573z"
          fill="#4b4145"
        />
        <ellipse
          cx="9.74749"
          cy="9.985276"
          fill="#de764a"
          rx="9.955535"
          ry="3.636267"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -4.205683 9.817136)"
        />
        <path
          d="m3.99942 19.33325c-1.25076 0-2.07196-.4765-2.60077-1.00348 0 0 0 0-.0018 0-.90244-.90247-1.65506-2.6712-.12454-5.9632.93311-2.00702 2.56467-4.23059 4.59332-6.25922 3.37506-3.37146 7.05692-5.46869 9.61078-5.46869h.0036c1.33377 0 2.1568.5451 2.6116.99994.90063.9024 1.65686 2.66754.12454 5.96313-.93311 2.00702-2.56467 4.23059-4.59332 6.25922-2.03046 2.02863-4.2522 3.66022-6.25739 4.59515-1.36987.63527-2.47622.87715-3.36602.87715zm11.37952-15.22565c-.38983.05774-3.34076 1.05402-6.8999 4.61316-3.24329 3.24512-4.35867 5.98126-4.39117 6.9198.93671-.03253 3.67645-1.14789 6.92157-4.39301 3.55733-3.55913 4.55361-6.50823 4.3695-7.13995z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(94 69)" id="carrot2">
        <g transform="translate(0 1)">
          <path
            d="m16.47136 20.67163c-1.41998 1.41998-5.72267-.58038-9.61067-4.46838-3.8878-3.88782-5.88849-8.19086-4.46851-9.61084l4.12355-4.12354 14.07919 14.07916z"
            fill="#e5926c"
          />
          <path
            d="m15.16399 22.97742c-2.55385 0-6.23572-2.09363-9.61078-5.46869-2.02864-2.02863-3.66022-4.2522-4.59332-6.25916-1.5323-3.29565-.77608-5.06079.12634-5.9632l4.12225-4.12585c.69307-.69305 1.92035-.69305 2.61342 0l14.07956 14.08136c.34653.3465.54144.8158.54144 1.3067s-.19492.96014-.54144 1.3067l-4.12225 4.12225c-.45482.45483-1.27783.99988-2.6116.99988-.00182.00001-.00362.00001-.00362.00001zm-8.64879-17.89679-2.81555 2.81915c-.08664.4873.90964 3.4364 4.46698 6.99554 3.45445 3.45087 6.31152 4.44714 7.08038 4.3858l2.73433-2.73254z"
            fill="#4b4145"
          />
        </g>
        <path
          d="m15.16399 23.97742c-2.55385 0-6.23572-2.09363-9.61078-5.46869-2.02864-2.02863-3.66022-4.2522-4.59332-6.25916-1.5323-3.29565-.77608-5.06079.12634-5.9632l4.12225-4.12585c.69307-.69305 1.92035-.69305 2.61342 0l14.07956 14.08136c.34653.3465.54144.8158.54144 1.3067s-.19492.96014-.54144 1.3067l-4.12225 4.12225c-.45482.45483-1.27783.99988-2.6116.99988-.00182.00001-.00362.00001-.00362.00001zm-8.64879-17.89679-2.81555 2.81915c-.08664.4873.90964 3.4364 4.46698 6.99554 3.45445 3.45087 6.31152 4.44714 7.08038 4.3858l2.73433-2.73254z"
          fill="#4b4145"
        />
        <ellipse
          cx="13.558161"
          cy="10.509857"
          fill="#de764a"
          rx="3.636267"
          ry="9.955535"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -3.460498 12.665333)"
        />
        <path
          d="m19.33136 19.84796c-2.86067 0-6.6743-2.48346-9.65408-5.46149-4.01938-4.01758-7.13274-9.55841-4.4706-12.22595 2.66756-2.6712 8.20662.44757 12.226 4.46875 4.01938 4.01758 7.13272 9.55847 4.47058 12.22601-.0018 0-.0018 0-.0018 0-.68945.68946-1.57202.99268-2.5701.99268zm1.2634-2.29938h.03609zm-12.73857-12.69885c-.07219 0-.13174.0072-.17868.02167.05775.38983 1.05223 3.34253 4.61317 6.90167 3.2433 3.24152 5.98305 4.35687 6.91975 4.39301-.03249-.93854-1.14607-3.67828-4.39117-6.9234-3.29384-3.292-6.06607-4.39295-6.96307-4.39295z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(54 54)" id="onion1">
        <path
          d="m7.369855 2.929879h8.062257v17.942272h-8.062257z"
          fill="#fff2bc"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -5.076017 11.547439)"
        />
        <path
          d="m14.89078 22.94324c-.49092 0-.96017-.19495-1.30672-.5415l-12.68622-12.68799c-.34653-.34656-.54146-.8158-.54146-1.3067s.19493-.96021.54146-1.3067l5.70149-5.69971c.72194-.72192 1.89146-.72192 2.6134 0l12.68623 12.68445c.72194.72192.72194 1.89142 0 2.6134l-5.70149 5.70325c-.34652.34655-.81579.5415-1.30669.5415zm-10.07282-14.5362 10.07281 10.07458 3.08807-3.08984-10.07281-10.07104z"
          fill="#4b4145"
        />
      </g>
      <g transform="translate(140 49)" id="celery2">
        <path
          d="m7.464329 3.032425h8.062257v17.942272h-8.062257z"
          fill="#68c187"
          transform="matrix(.70710678 .70710678 -.70710678 .70710678 11.85474 -4.612754)"
        />
        <path
          d="m8.00415 23.04126c-.47287 0-.94574-.18048-1.3067-.54144l-5.70148-5.69971c-.34653-.3465-.54147-.8158-.54147-1.3067s.19495-.96014.54147-1.3067l12.68622-12.68805c.34653-.3465.8158-.54144 1.3067-.54144.49091 0 .96017.19495 1.3067.54144l5.70151 5.70331c.72192.72198.72192 1.89148 0 2.6134l-12.68625 12.68445c-.36096.36096-.83383.54144-1.3067.54144zm-3.08807-7.54785 3.08807 3.0863 10.07281-10.07104-3.08807-3.08984z"
          fill="#4b4145"
        />
      </g>
      <g>
        <path
          d="m168.18228 122.54675h-121.97836v16.89514c0 8.14008 6.59886 14.73895 14.73898 14.73895h92.5004c8.14011 0 14.73898-6.59888 14.73898-14.73895z"
          fill="#d77891"
        />
        <path
          d="m53.36552 139.88092v-17.33435h-7.16161v16.89532c0 8.14008 6.59895 14.73883 14.73901 14.73883h6.72235c-7.89752.00001-14.29975-6.40222-14.29975-14.2998z"
          fill="#e5a9b6"
        />
        <path
          d="m156.7854 122.54657v17.60083c0 7.75043-6.28311 14.03333-14.03354 14.03333h10.69141c8.14008 0 14.73901-6.59875 14.73901-14.73883v-16.89533l-11.39688.00001z"
          fill="#cf5c7f"
        />
        <path
          d="m49.58461 148.72894c2.70297 3.30127 6.75891 5.45178 11.35832 5.45178h92.50034c4.59943 0 8.65533-2.15051 11.35831-5.45178z"
          fill="#e5a9b6"
        />
        <path
          d="m153.44394 156.02771h-92.50169c-9.14513 0-16.58649-7.43951-16.58649-16.58649v-16.89331c0-1.02155.82663-1.84814 1.84816-1.84814h121.97836c1.02155 0 1.84814.8266 1.84814 1.84814v16.89331c.00001 9.14698-7.44134 16.58649-16.58648 16.58649zm-105.39186-31.63165v15.04517c0 7.10748 5.78271 12.8902 12.89017 12.8902h92.50169c7.10748 0 12.8902-5.78271 12.8902-12.8902v-15.04517z"
          fill="#4b4145"
        />
      </g>
    </g>
  </svg>
);
