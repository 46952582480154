import React, { useCallback, useMemo, useState, useEffect } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field, useForm, useField } from "react-final-form";
import { ModalStyled } from "components/UIKitStyled";
import { RowStyled, ColStyled } from "components/utils";
import { iconClose, iconEdit } from "@wfp/icons";
import { colors, Icon } from "@wfp/ui";
import messages from "components/Button/messages";
import resultsMessages from "containers/Results/messages";
import { Text, Line, Toggle, Button } from "components";
import { Select } from "components/InputFields";
import { ReactSelectWithDataTestId } from "components/utils";
import ItemsList from "./ItemsList";
import { selectMajorGroups, selectNutRestrictions, selectNutRequirements } from "containers/Results/selectors";
import { selectNutritionalValues } from "containers/App/selectors";
import ListOfFoods from "./ListOfFoods";
import TextInput from "./TextInput";
import {
  FIELDS,
  DEFAULTS,
  transformNutrients,
  getOrderedNutrients,
  calcGraphValues,
} from "containers/ResultsCommunity/utils";
import NutritionalValues from "../../NutritionalValues";
import CostPerChildAndNumbers from "../../CostPerChildAndNumbers";
import { loadSingleNutritionalValues } from "containers/Results/actions";
import { FiltersAndSortContainer, ContainerText, Title } from "./styles";
import { useResponsiveHook } from "hooks";
import { changeMealNames } from "containers/Menu/actions";

const FONT_SIZE_LABEL = "16px";

function DayModal({
  menuId,
  values,
  mealNames,
  absoluteDay,
  setAbsoluteDay,
  relativeDay,
  setRelativeDay,
  setWeek,
  optionsFood,
  intl,
}) {
  const dispatch = useDispatch();
  const form = useForm();
  const { isTabletOrMobile } = useResponsiveHook();
  const [text, setText] = useState("");
  const [toggleIndex, setToggleIndex] = useState(0);
  const [initialValues, setInitialValues] = useState(values);
  const [editMode, setEditMode] = useState(false);
  const [initialMealName, setInitialMealName] = useState(null);
  const [mealName, setMealName] = useState(null);

  const majorGroups = useSelector(selectMajorGroups);
  const reduxNutrientsRestrictions = useSelector(selectNutRestrictions);
  const reduxNutritionalValues = useSelector(selectNutritionalValues);
  const nutritionalRequirements = useSelector(selectNutRequirements);

  const nutrientsRestrictions = useMemo(
    () => transformNutrients(reduxNutrientsRestrictions),
    [reduxNutrientsRestrictions],
  );

  const {
    input: { value: fieldFoodGroup },
  } = useField(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.FOOD_GROUP}`);
  const {
    input: { value: fieldNutrient },
  } = useField(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.NUTRIENT}`);

  const filteredOptionsFood = useMemo(() => {
    // We start from the regular optionsFood and we filter them step by step
    let filteredOptionsFood = optionsFood;

    // Filter for food group
    const selectedMajorGroup = majorGroups.find((group) => group.value === fieldFoodGroup);
    if (selectedMajorGroup) {
      filteredOptionsFood = optionsFood.filter((food) => food.major_group === selectedMajorGroup.label);
    }

    // Filter for nutrient. When fieldNutrient is 1, it means the selected options is "None", therefore we do not enter the "if"
    if (fieldNutrient && fieldNutrient !== 1) {
      const nutrient = reduxNutrientsRestrictions.find((nutrient) => nutrient.id === fieldNutrient);
      const orderedNutrients = getOrderedNutrients(reduxNutritionalValues, nutrient.nutrient);
      let temp = [];
      orderedNutrients.forEach((nutrient) => {
        const found = filteredOptionsFood.find((filtered) => filtered.food_item === Number(nutrient.food_item));
        if (found) {
          temp.push(found);
        }
      });
      filteredOptionsFood = temp;
    }

    // Filter for food item
    if (text) {
      filteredOptionsFood = filteredOptionsFood.filter((food) =>
        food.display_name.split(";")[0].toLowerCase().includes(text.toLowerCase()),
      );
    }

    return filteredOptionsFood;
  }, [
    fieldFoodGroup,
    fieldNutrient,
    optionsFood,
    majorGroups,
    reduxNutritionalValues,
    reduxNutrientsRestrictions,
    text,
  ]);

  const resetState = useCallback(() => {
    setRelativeDay(0);
    setWeek(0);
    setToggleIndex(0);
    setText("");
    setEditMode(false);
    setMealName(null);
    setInitialMealName(null);
    setAbsoluteDay();
  }, [setAbsoluteDay, setRelativeDay, setWeek]);

  const onTextChange = useCallback((e) => setText(e.target.value), []);

  const onClose = useCallback(() => {
    setTimeout(() => {
      form.change(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.FOOD_GROUP}`, DEFAULTS.FOOD_GROUP);
      form.change(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.NUTRIENT}`, DEFAULTS.NUTRIENT);
    }, 250);

    // Reset food items of opened day
    form.change(`day_${absoluteDay}`, initialValues[`day_${absoluteDay}`]);

    // Also reset values.foods
    form.change("foods", values.draftFoods);

    // Reset mealName to initial value
    dispatch(changeMealNames({ body: { [absoluteDay]: initialMealName } }));

    resetState();
  }, [dispatch, form, resetState, absoluteDay, initialValues, values.draftFoods, initialMealName]);

  const onSubmit = useCallback(() => {
    setTimeout(() => {
      form.change(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.FOOD_GROUP}`, DEFAULTS.FOOD_GROUP);
      form.change(`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.NUTRIENT}`, DEFAULTS.NUTRIENT);
    }, 250);

    // Since the modal doesn't actually unmount but it is simply hidden from the view, we need to update the initialValues stored inside our useState when the user save the edits.
    setInitialValues(values);

    form.change("draftFoods", values.foods);

    // Update mealName
    dispatch(changeMealNames({ body: { [absoluteDay]: mealName } }));

    // We need to check if all the selected food items have nutritional informations in the FE or if we need to call BE and get the missing ones.
    dispatch(loadSingleNutritionalValues({ menuId, foods: values.foods }));

    resetState();
  }, [form, resetState, absoluteDay, dispatch, menuId, values, mealName]);

  const allValues = useMemo(() => {
    return R.flatten(
      Object.keys(values)
        .filter((el) => el !== "draft" && el !== "foods" && el !== "draftFoods")
        .map((el) => values[el]),
    );
  }, [values]);

  const allDayValues = useMemo(() => R.flatten(values[`day_${absoluteDay}`] || []), [absoluteDay, values]);
  const dayGraphValues = useMemo(
    () => calcGraphValues(allDayValues, reduxNutritionalValues, nutritionalRequirements, reduxNutrientsRestrictions, 1),
    [allDayValues, nutritionalRequirements, reduxNutritionalValues, reduxNutrientsRestrictions],
  );

  const headerText = useMemo(
    () => mealName || intl.formatMessage(resultsMessages.dayPerMeal, { day: relativeDay }),
    [intl, relativeDay, mealName],
  );

  const onClickEdit = useCallback(() => {
    setEditMode(true);
    setMealName(headerText);
  }, [headerText]);
  const onRename = useCallback(() => setEditMode(false), []);
  const onMealNameChange = useCallback((e) => setMealName(e.target.value), []);

  useEffect(() => {
    if (mealNames && mealNames[absoluteDay]) {
      setInitialMealName(mealNames[absoluteDay]);
      setMealName(mealNames[absoluteDay]);
    }
  }, [absoluteDay, mealNames]);

  return (
    <ModalStyled
      noHeader
      background="white"
      maxContentHeight
      open={!!relativeDay}
      primaryButtonText={intl.formatMessage(messages.save)}
      secondaryButtonText={intl.formatMessage(messages.cancel)}
      primaryButtonDisabled={editMode}
      onRequestSubmit={onSubmit}
      minWidth="90%"
      onRequestClose={onClose}
      onSecondarySubmit={onClose}
      inPortal={false}
      height={window.innerHeight}
    >
      {editMode ? (
        <RowStyled center="xs" middle="xs">
          <ColStyled xs={isTabletOrMobile ? 10 : 11} margin="0 0 5px 0">
            <Title defaultValue={headerText} fontSize={isTabletOrMobile ? "25px" : null} onChange={onMealNameChange} />
          </ColStyled>
          <ColStyled xs={isTabletOrMobile ? 2 : 1} className="center-xs">
            <Button disabled={!mealName} kind="secondary" onClick={onRename}>
              <FormattedMessage {...messages.rename} />
            </Button>
          </ColStyled>
          <button className="wfp--modal-close" type="button">
            <Icon icon={iconClose} className="wfp--modal-close__icon" width="16" height="16" onClick={onClose} />
          </button>
        </RowStyled>
      ) : (
        <RowStyled middle="xs" between="xs" style={{ marginBottom: "1.5rem" }}>
          <ContainerText>
            <h2 style={{ fontSize: "1.75rem" }}>{headerText}</h2>
            <Icon
              className="marginLeftSmall"
              onClick={onClickEdit}
              icon={iconEdit}
              description="edit"
              fill={colors["brand-01"].hex}
              width="25"
              height="25"
            />
          </ContainerText>
          <Toggle activeIndex={toggleIndex} setActiveIndex={setToggleIndex} />
          <button className="wfp--modal-close" type="button">
            <Icon icon={iconClose} className="wfp--modal-close__icon" width="16" height="16" onClick={onClose} />
          </button>
        </RowStyled>
      )}

      <RowStyled height="100%" padding="0 0 1.5rem">
        <ColStyled xs={4} height={window.innerHeight - 262}>
          <Text wrap fontSize="16px" bold value={intl.formatMessage(resultsMessages.currentMenu)} />
          <Line margin="10px 0 0" />
          <ItemsList allValues={allValues} values={values} day={absoluteDay} />
        </ColStyled>

        {toggleIndex === 0 ? (
          <>
            <ColStyled xs={4} height={window.innerHeight - 262} padding="0 0 0 8px">
              <Text wrap fontSize="16px" bold value={intl.formatMessage(resultsMessages.addFoodItem)} />
              <Line margin="10px 0 0" />

              <FiltersAndSortContainer>
                <div>
                  <Text wrap bold marginBottom="10px" value={intl.formatMessage(resultsMessages.filter)} />
                  <Field
                    data-test-id="filter-food-group"
                    labelText={intl.formatMessage(resultsMessages.foodGroup)}
                    fontSizeLabel={FONT_SIZE_LABEL}
                    id={`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.FOOD_GROUP}`}
                    name={`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.FOOD_GROUP}`}
                    options={[
                      { value: 16, label: intl.formatMessage(resultsMessages.foodGroupDefaultOption) },
                      ...majorGroups,
                    ]}
                    components={ReactSelectWithDataTestId}
                    component={Select}
                  />

                  <TextInput
                    labelText={intl.formatMessage(resultsMessages.foodItem)}
                    value={text}
                    placeholder={intl.formatMessage(resultsMessages.foodItemPlaceholder)}
                    onChange={onTextChange}
                    marginTop="10px"
                  />
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Text wrap bold marginBottom="10px" value={intl.formatMessage(resultsMessages.sort)} />
                  <Field
                    data-test-id="filter-nutrient"
                    labelText={intl.formatMessage(resultsMessages.nutrient)}
                    fontSizeLabel={FONT_SIZE_LABEL}
                    id={`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.NUTRIENT}`}
                    name={`${FIELDS.DRAFT}.${FIELDS.FILTERS}.${FIELDS.NUTRIENT}`}
                    options={[
                      { value: 1, label: intl.formatMessage(resultsMessages.nutrientDefaultOption) },
                      ...nutrientsRestrictions,
                    ]}
                    components={ReactSelectWithDataTestId}
                    component={Select}
                  />
                </div>
              </FiltersAndSortContainer>
            </ColStyled>

            <ColStyled xs={4} height="100%" padding="0" style={{ borderBottom: `1px solid ${colors["ui-04"].hex}` }}>
              <Line margin="34px 0 0" />

              <RowStyled padding="0.75rem 0" height={window.innerHeight - 262} overflow="auto">
                <ColStyled xs padding="0 0 0 15px">
                  <ListOfFoods optionsFood={filteredOptionsFood} day={absoluteDay} values={values} />
                </ColStyled>
              </RowStyled>
            </ColStyled>
          </>
        ) : (
          <>
            <ColStyled xs={4} height="100%" padding="0 0 0 8px" margin="24px 0 0 0" style={{ overflowY: "auto" }}>
              <NutritionalValues
                graphValues={dayGraphValues}
                nutrients={reduxNutritionalValues}
                nutrientRestrictions={reduxNutrientsRestrictions}
                nutritionalRequirements={nutritionalRequirements}
                inTwoRows
                inModal
                onlyPreview
                multipleRows
              />
            </ColStyled>
            <ColStyled xs={4} height="100%" padding="0 0 0 8px" margin="24px 0 0 0" style={{ overflowY: "auto" }}>
              <CostPerChildAndNumbers values={allValues} />
            </ColStyled>
          </>
        )}
      </RowStyled>
    </ModalStyled>
  );
}

export default injectIntl(DayModal);
