import { useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Tag } from "@wfp/ui";

import { Text } from "components";
import { ColStyled } from "components/utils";
import messages from "containers/FoodBasket/messages";
import { ActiveFiltersContainer } from "./styles";

const ActiveFilters = ({ activeFilters }) => {
  const intl = useIntl();

  const renderPercentageRange = useCallback(({ label, percentage }) => {
    if (percentage[0] === percentage[1]) {
      return (
        <FormattedMessage {...messages.nutrientRangeEqualBounds} values={{ nutrient: label, lower: percentage[0] }} />
      );
    } else {
      return (
        <FormattedMessage
          {...messages.nutrientRangeDifferentBounds}
          values={{ nutrient: label, lower: percentage[0], upper: percentage[1] }}
        />
      );
    }
  }, []);

  return (
    <ActiveFiltersContainer middle="xs">
      <ColStyled margin="0 0.75rem 0 0">
        <Text bold value={intl.formatMessage(messages.showing)} />
      </ColStyled>

      <ColStyled>
        {activeFilters.map((filter) => (
          <Tag key={filter.label} type="info">
            {renderPercentageRange(filter)}
          </Tag>
        ))}
      </ColStyled>
    </ActiveFiltersContainer>
  );
};

export default ActiveFilters;
