import moment from "moment";

export const FIELDS = {
  NAME: "name",
  MODE: "mode",
  STATUS: "status",
  REGION: "region",
  TARGET: "target",
  DATE: "date",
  DATE_FROM: "created_from",
  DATE_TO: "created_to",
  ORDERING: "ordering",
};

export const INITIAL_VALUES = {
  [FIELDS.NAME]: "",
  [FIELDS.ORDERING]: "updated_timestamp",
  [FIELDS.MODE]: [],
  [FIELDS.STATUS]: [],
  [FIELDS.REGION]: [],
  [FIELDS.TARGET]: [],
  [FIELDS.DATE]: {
    [FIELDS.DATE_FROM]: null,
    [FIELDS.DATE_TO]: moment(),
  },
};
