import React from "react";
import { colors } from "@wfp/ui";

export const Composite = ({ size, disable }) => (
  <svg
    width={size || "22px"}
    height={size || "22px"}
    viewBox="0 0 28 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-103.000000, -604.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(103.000000, 604.000000)">
          <g>
            <path d="M21.8857143,8.17142857 C21.6,6.74285714 20.3142857,5.65714286 18.8,5.65714286 C18.1714286,5.65714286 17.5714286,5.85714286 17.0857143,6.17142857 C16.5142857,5.28571429 15.5428571,4.71428571 14.4285714,4.71428571 C13.4857143,4.71428571 12.6285714,5.14285714 12.0571429,5.8 C11.5714286,5.48571429 10.9714286,5.31428571 10.3714286,5.31428571 C9.65714286,5.31428571 9,5.54285714 8.48571429,5.94285714 C8.14285714,7.34285714 7.37142857,8.54285714 6.28571429,9.31428571 C6.34285714,9.97142857 6.4,10.6 6.45714286,11.2 L24.3142857,11.2 C24.2857143,9.74285714 23.2571429,8.51428571 21.8857143,8.17142857 Z" />
            <path d="M26.2857143,12.7428571 L6.54285714,12.7428571 C6.65714286,14.5142857 6.65714286,16 6.57142857,17.2 L8.25714286,17.7142857 C8.77142857,17.8857143 9.22857143,18.2285714 9.48571429,18.7142857 C9.85714286,19.4 10.5714286,19.8 11.3428571,19.8 L17.5714286,19.8 C18.3428571,19.8 19.0571429,19.3714286 19.4285714,18.7142857 C19.6857143,18.2285714 20.1428571,17.8857143 20.6571429,17.7142857 L23.5428571,16.8285714 C24.9714286,16.4 26.2,15.5142857 27.1142857,14.3428571 C27.5714286,13.7142857 27.1142857,12.7428571 26.2857143,12.7428571 Z" />
            <path d="M7.08571429,4.4 C7.08571429,2.05714286 5.54285714,0.171428571 3.6,0.171428571 C1.68571429,0.171428571 0.114285714,2.05714286 0.114285714,4.4 C0.114285714,6.28571429 1.14285714,7.91428571 2.57142857,8.42857143 C2.17142857,12.6 1.42857143,19.8 3.6,19.8 C5.77142857,19.8 5.02857143,12.6 4.62857143,8.42857143 C6.05714286,7.91428571 7.08571429,6.31428571 7.08571429,4.4 Z M5.22857143,4.94285714 C4.94285714,4.94285714 4.68571429,4.68571429 4.68571429,4.4 C4.68571429,3.48571429 4.2,2.77142857 3.77142857,2.6 C3.48571429,2.51428571 3.34285714,2.2 3.42857143,1.91428571 C3.51428571,1.62857143 3.82857143,1.48571429 4.11428571,1.57142857 C5.05714286,1.88571429 5.77142857,3.08571429 5.77142857,4.4 C5.77142857,4.71428571 5.54285714,4.94285714 5.22857143,4.94285714 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
