import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { SelectHookForm, NumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import messages from "../messages";
import { selectTargetBeneficiaries } from "../selectors";

const TargetGroupAndDuration = () => {
  const targetBeneficiaries = useSelector(selectTargetBeneficiaries);

  const { control } = useFormContext();

  const daysInWeek = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.DAYS_IN_WEEK}`,
  });
  const weekCount = useWatch({
    control,
    name: `${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.WEEK_COUNT}`,
  });

  const daysAndWeeksCount = useMemo(
    () =>
      weekCount === 1 ? (
        <FormattedMessage {...messages.daysAndWeek} values={{ days: daysInWeek }} />
      ) : (
        <FormattedMessage {...messages.daysAndWeeks} values={{ days: daysInWeek * weekCount, weeks: weekCount }} />
      ),
    [daysInWeek, weekCount],
  );

  return (
    <div style={{ marginBottom: "40px" }}>
      <RowStyled>
        <ColStyled xs={6}>
          <ColStyled xs={10} padding="0">
            <Text bold fontSize="16px" value={<FormattedMessage {...messages.targeted} />} />
            <SelectHookForm
              control={control}
              name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.TARGET_GROUP}`}
              options={targetBeneficiaries}
              helperText={<FormattedMessage {...messages.targetedInfo} />}
            />
          </ColStyled>
        </ColStyled>
        <ColStyled xs={6}>
          <RowStyled bottom="xs">
            <ColStyled xs={4}>
              <Text bold fontSize="16px" value={<FormattedMessage {...messages.duration} />} />
              <NumberInputHookForm
                control={control}
                name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.DAYS_IN_WEEK}`}
                step={1}
                helperText={<FormattedMessage {...messages.durationInfo} />}
                min={1}
                max={7}
              />
            </ColStyled>
            <ColStyled xs={4}>
              <NumberInputHookForm
                control={control}
                name={`${FIELDS.PRELIMINARY_INFORMATION}.${FIELDS.WEEK_COUNT}`}
                step={1}
                helperText={<FormattedMessage {...messages.durationInfoWeek} />}
                min={1}
                max={4}
              />
            </ColStyled>
            <ColStyled xs={4}>
              <Text bold value={daysAndWeeksCount} />
            </ColStyled>
          </RowStyled>
        </ColStyled>
      </RowStyled>
    </div>
  );
};

export default TargetGroupAndDuration;
