import { createReducer } from "@reduxjs/toolkit";

import { loadStarted, loadEnded, setTutorial, loadTutorialsSuccess } from "./actions";

export const initialState = {
  tutorial: null,
  data: {},
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadTutorialsSuccess.type]: (state, action) => {
    state.data = action.payload.tutorials.reduce((acc, item) => ({ ...acc, [item.page]: item }), {});
  },
  [setTutorial.type]: (state, action) => {
    state.tutorial = state.data[action.payload.kind];
  },
});
