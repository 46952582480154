import { Text, View } from "@react-pdf/renderer";

import generalStyles from "containers/PDF/styles";
import styles from "./styles";

const SpacedInRow = ({ label, value, fontSizeStyle, heightRow, noPadding, textTransform }) => {
  const { row } = generalStyles;
  const { containerRow, firstCol, secondCol } = styles;
  return (
    <View style={[row, containerRow, { padding: noPadding ? 0 : "0 5" }]}>
      <Text style={[fontSizeStyle, firstCol, { textTransform: textTransform || "none", height: heightRow }]}>
        {label}
      </Text>
      <Text style={[fontSizeStyle, secondCol]}>{value}</Text>
    </View>
  );
};

export default SpacedInRow;
