import styled, { css } from "styled-components";

export const ContainerIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  svg {
    ${({ isRTL }) =>
      isRTL
        ? css`
            margin-left: 15px;
          `
        : css`
            margin-right: 15px;
          `}
  }
  :hover {
    p,
    svg {
      color: ${({ hover }) => hover};
      fill: ${({ hover }) => hover};
    }
  }
`;
