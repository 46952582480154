import { defineMessages } from "react-intl";

export default defineMessages({
  needHelp: {
    id: "tutorials.needHelp",
    defaultMessage: "Need help?",
  },
  videosLabel: {
    id: "tutorials.videosLabel",
    defaultMessage: "Videos",
  },
  faqLabel: {
    id: "tutorials.faqLabel",
    defaultMessage: "FAQs",
  },
  helpLabel: {
    id: "tutorials.help",
    defaultMessage: "Help",
  }
});
