import { useMemo, useCallback } from "react";
import { useController } from "react-hook-form";

import { UISelect } from "./styles";

const MultiSelectHookForm = ({
  control,
  name,
  label,
  helperText,
  options = [],
  customOnChange,
  placeholder,
  className = "",
}) => {
  const { field } = useController({ control, name });

  const selectedValue = useMemo(() => {
    const values = field.value.map((el) => el.value);
    return options.filter((option) => values.includes(option.value));
  }, [field, options]);

  const onChange = useCallback(
    (value, meta) => {
      if (customOnChange) {
        customOnChange({ value, meta, field });
      } else {
        field.onChange(value);
      }
    },
    [customOnChange, field],
  );

  return (
    <div className="wfp--form-item">
      {label && (
        <label htmlFor={name} className="wfp--label">
          {label}
        </label>
      )}
      {helperText && <div className="wfp--form__helper-text">{helperText}</div>}
      <UISelect
        isMulti
        className={`wfp--react-select-container ${className}`}
        classNamePrefix="wfp--react-select"
        styles={{ menu: (styles) => ({ ...styles, zIndex: 1000 }) }}
        menuPlacement="auto"
        options={options}
        value={selectedValue}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default MultiSelectHookForm;
