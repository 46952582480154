import messages from "containers/MyMenus/messages";

export const intlOrderByOptions = (intl) => [
  {
    value: "updated_timestamp",
    label: intl.formatMessage(messages.updatedColumn),
  },
  {
    value: "target_group",
    label: intl.formatMessage(messages.targetColumn),
  },
  {
    value: "name",
    label: intl.formatMessage(messages.nameColumn),
  },
  {
    value: "type",
    label: intl.formatMessage(messages.typeColumn),
  },
  {
    value: "average_cost_per_meal",
    label: intl.formatMessage(messages.costColumn),
  },
];
