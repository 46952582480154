import { all } from "redux-saga/effects";

import appSaga from "containers/App/sagas";
import compareMenusSaga from "containers/Compare/sagas";
import myMenusSaga from "containers/MyMenus/sagas";
import resultsSaga from "containers/Results/sagas";
import menuSaga from "containers/Menu/sagas";
import foodBasketSaga from "containers/FoodBasket/sagas";
import optimizedMenuSaga from "containers/OptimizedMenu/sagas";
import generalFormSaga from "containers/GeneralInfo/GeneralForm/sagas";
import communityFormSaga from "containers/GeneralInfoCommunity/GeneralForm/sagas";
import diversitySaga from "containers/OptimizedMenu/Rules/FoodRules/sagas";
import selectCountrySaga from "containers/SelectCountry/sagas";
import tutorialsSaga from "containers/Tutorials/sagas";
import adminSaga from "containers/Admin/sagas";

export default function* rootSaga() {
  yield all([
    appSaga(),
    compareMenusSaga(),
    myMenusSaga(),
    resultsSaga(),
    menuSaga(),
    generalFormSaga(),
    communityFormSaga(),
    foodBasketSaga(),
    optimizedMenuSaga(),
    diversitySaga(),
    selectCountrySaga(),
    tutorialsSaga(),
    adminSaga(),
  ]);
}
