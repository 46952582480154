import { useIntl } from "react-intl";
import * as R from "ramda";
import { View, Text } from "@react-pdf/renderer";
import styles from "./styles";
import messages from "../../messages";

const PageThree = ({ menuNumbers, index, items }) => {
  const intl = useIntl();
  const { headerInBox, groupBox, itemBox } = styles;
  const lastBorder = index + 1 === menuNumbers ? { borderRight: 0 } : {};
  const wholeMenu = R.pathOr([], ["whole_menu"], items);
  const itemsNumber = wholeMenu.reduce((acc, { items }) => (acc += items.length), 0);

  return (
    <View style={{ flexDirection: "column", flex: 1 }}>
      <Text style={[headerInBox, lastBorder]}>
        {intl.formatMessage(messages.menuStructure, { groups: wholeMenu.length, items: itemsNumber })}
      </Text>
      <View style={[lastBorder, { padding: "13" }]}>
        {wholeMenu.map(({ group, items }, groupIndex) => (
          <View key={`whole-menu-${groupIndex}-${group}`} style={{ marginTop: groupIndex ? 15 : 0 }}>
            <Text style={groupBox}>{group}</Text>
            {items.map((item, itemIndex) => (
              <Text key={`item-${itemIndex}-${item}`} style={itemBox}>
                {item}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

export default PageThree;
