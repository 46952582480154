import * as R from "ramda";
import { validate as uuidValidate } from "uuid";

export const getMenuDiff = (initial, current, deletedIds) => {
  const flatInitial = R.flatten(Object.values(initial));
  const flatCurrent = R.flatten(Object.values(current));

  // Filter out deleted items.
  const currentWithoutDeleted = flatCurrent.filter((el) => !deletedIds.includes(el._id));

  // Updated items: PATCH calls.
  const diff = currentWithoutDeleted.filter(
    (a) => R.has("created_timestamp", a) && !flatInitial.some((b) => R.equals(a, b)),
  );

  // Created items: POST calls.
  // Newly created items won't have "created_timestamp" property
  const newItems = flatCurrent.filter((el) => !R.has("created_timestamp", el));

  return {
    created: newItems,
    updated: diff,
  };
};

export const getRecipesDiff = (initial, current) => {
  const flatInitial = R.flatten(Object.values(initial));
  const flatCurrent = R.flatten(Object.values(current));

  // Filter out newly created recipes.
  const currentWithoutNewlyCreated = flatCurrent.filter((recipe) => !recipe.new && !uuidValidate(recipe.recipe_id));

  // Updated recipes: PATCH calls.
  const diff = currentWithoutNewlyCreated.filter((a) => !flatInitial.some((b) => R.equals(a, b)));

  // Created recipes: POST calls.
  const newRecipes = flatCurrent.filter((recipe) => recipe.new || uuidValidate(recipe.recipe_id));

  return {
    created: newRecipes,
    updated: diff,
  };
};
