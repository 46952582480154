import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Module } from "components";
import { SourcesGraph } from "components/Charts";
import { GraphForPDF } from "./styles";
import messages from "containers/Menu/messages";

const SourcingChannels = ({ menus, withAdditionalCost }) => {
  const intl = useIntl();

  const dataToShow = useMemo(() => {
    if (!menus) return;
    return menus.map((el) =>
      el.precentage_costs_per_source.map((el) => ({
        ...el,
        value: Number(withAdditionalCost ? el.cost : el.cost_without_additionals),
      }))
    );
  }, [menus, withAdditionalCost]);

  return (
    <>
      {menus && (
        <Module title={intl.formatMessage(messages.sourcing)}>
          <SourcesGraph menus={dataToShow} showLabelOnGraph={false} />
        </Module>
      )}
      <GraphForPDF>
        <SourcesGraph id="sourcing-channels" menus={dataToShow} noEllipsis inPdf />
      </GraphForPDF>
    </>
  );
};

export default SourcingChannels;
