import React from "react";
import { useResponsiveHook } from "hooks";
import { newColorBySource } from "components/utils";

const RADIAN = Math.PI / 180;

export default function Percentage({ cx, cy, midAngle, innerRadius, outerRadius, source, value, label, onHover }) {
  const { isTabletOrMobile } = useResponsiveHook();

  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      {label && ((onHover && onHover === source) || !onHover) && value !== 0 && (
        <text
          x={x}
          y={y}
          fill={newColorBySource[source]}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontWeight: 600, fontSize: isTabletOrMobile ? "11px" : "16px" }}
        >
          {`${value}%`}
        </text>
      )}
    </>
  );
}
