import * as R from "ramda";

import { FIELDS } from "./constants";

// Rework data in useInitialValues before setting initialValues
export const initInitialValues = ({ mealCount, selectedFoodItems }) => {
  const emptyMenu = new Array(mealCount).fill().reduce((acc, _, index) => ({ ...acc, [`day_${index + 1}`]: [] }), {});

  const refactoredResults = selectedFoodItems
    .filter((foodItem) => !foodItem.recipe)
    .map(({ id, ...foodItem }) => ({
      ...foodItem,
      ...getPrices(foodItem),
      _id: id,
    }));

  const existingMenu = R.groupBy(
    (item) => `day_${item.day}`,
    R.sortBy((key) => key.day, reorderList(refactoredResults))
  );

  const result = R.isEmpty(selectedFoodItems) ? emptyMenu : { ...emptyMenu, ...existingMenu };
  return result;
};

export const initAvailableRecipes = (availableRecipes) => {
  const reworkedAvailableRecipes = availableRecipes.map((recipe) => {
    let newBasket = {};

    for (const [key, foodItem] of Object.entries(recipe.recipe_basket)) {
      const reworkedBasket = foodItem.basket.map((item) => ({
        ...item,
        label: item.display_name,
        value: item.display_name,
        edible_portion_size_scaling_factor: foodItem.edible_portion_size_scaling_factor,
        is_priority: foodItem.is_priority,
        major_group: foodItem.major_group,
        ordering_value: foodItem.ordering_value,
      }));

      newBasket[key] = { ...foodItem, basket: reworkedBasket };
    }

    return { value: recipe.id, label: recipe.name, recipe_basket: newBasket };
  });

  return reworkedAvailableRecipes;
};

export const getDayRecipes = ({ day, menuRecipes }) => {
  return menuRecipes
    .filter((recipe) => recipe.day === day)
    .map(({ day, id, ingredients, recipe }) => {
      const reworkedIngredients = {};
      Object.entries(ingredients).forEach(([_id, ingredient]) => {
        reworkedIngredients[_id] = { ...ingredient, ...getPrices(ingredient) };
      });
      return {
        day,
        ingredients: reworkedIngredients,
        recipe_id: id,
        name: recipe.recipe,
        description: recipe.description,
      };
    });
};

export const getPrices = (item) => {
  // Use user prices if they exist
  const price = item.user_price_per_gram || item[FIELDS.USD_PRICE];
  const addCost = item.user_additional_cost_per_gram || item[FIELDS.USD_ADD_COST];
  
  return ({
  [FIELDS.PRICE]: (Number(price) + Number(addCost)) * Number(item[FIELDS.QUANTITY]),
  [FIELDS.PRICE_WITHOUT_ADD]: Number(price) * Number(item[FIELDS.QUANTITY]),
})};

export const reorderList = (list = []) => {
  return R.sortWith([R.ascend(R.prop("ordering_value")), R.ascend(R.prop("display_name"))])(list);
};

export const getPercentageByTotal = (list, key, total = 1) =>
  (list.reduce((acc, el) => (acc = acc + el[key]), 0) / Number(total)) * 100;

export const getCosts = (list = []) =>
  list.reduce(
    (acc, item) => ({
      ...acc,
      cost: acc.cost + getPrices(item)[FIELDS.PRICE],
      costWithoutAdd: acc.costWithoutAdd + getPrices(item)[FIELDS.PRICE_WITHOUT_ADD],
    }),
    { cost: 0, costWithoutAdd: 0 }
  );

export const getAverageSources = (cost, composition = []) =>
  Object.entries(
    R.groupBy(
      (item) => item.source_display,
      composition.filter((el) => !R.isEmpty(el))
    )
  ).map(([sourceKey, sourceValue]) => ({
    source: sourceValue[0].source,
    label: sourceKey,
    value: Math.round(getPercentageByTotal(sourceValue, "price", cost)),
  }));
