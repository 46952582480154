import { colors } from "@wfp/ui";

import { RowStyled } from "components/utils";
import FoodBasket from "./FoodBasket";
import MenuFields from "./MenuFields";
import { Separator } from "./styles";

const MenuFieldsAndFoodBasket = () => {
  return (
    <div style={{ backgroundColor: colors["ui-02"].hex, padding: "2.2rem", marginBottom: "40px" }}>
      <RowStyled middle="xs">
        <MenuFields />
        <Separator />
        <FoodBasket />
      </RowStyled>
    </div>
  );
};

export default MenuFieldsAndFoodBasket;
