import { useMemo, useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { colors, Link as WFPLink } from "@wfp/ui";
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from "react-complex-tree";

import { Text } from "components";
import { ModalStyled } from "components/UIKitStyled";
import routesPath from "containers/App/Routes/paths";
import selectMenu from "containers/Menu/selectors";
import messages from "containers/Results/messages";
import { createTreeStructure } from "./utils";

const MenuHistoryModal = ({ menuId, menuParents, menuChildren, open, closeModal }) => {
  const intl = useIntl();
  const history = useHistory();
  const menu = useSelector(selectMenu());

  const treeData = useMemo(() => {
    if (R.isNil(menu)) return;
    return createTreeStructure(menuParents, menuChildren, menuId);
  }, [menu, menuChildren, menuId, menuParents]);

  const viewState = useMemo(
    () => ({
      tree: {
        expandedItems: Object.keys(R.omit(["root"], treeData)),
      },
    }),
    [treeData],
  );

  // Close modal and navigate to clicked item results.
  const goToResultsPage = useCallback(
    (id) => {
      closeModal();
      history.push(routesPath.results.replace(":id", id));
    },
    [closeModal, history],
  );

  return (
    <ModalStyled
      open={open}
      lazyLoad
      modalHeading={intl.formatMessage(messages.menuHistory)}
      onRequestClose={closeModal}
      noFooter
      padding="2rem"
      maxWidth="80%"
      width="100%"
    >
      <UncontrolledTreeEnvironment
        id="tree-structure"
        dataProvider={new StaticTreeDataProvider(treeData, (item, data) => ({ ...item, data }))}
        getItemTitle={(item) => item.data}
        renderItemTitle={({ title, item }) => (
          <WFPLink onClick={() => goToResultsPage(item.index)}>
            <Text
              bold
              defaultColor={item.index === menuId ? colors["categorical-corporate-03"].hex : colors["text-02"].hex}
              value={`ID ${item.index} - ${title}`}
            />
          </WFPLink>
        )}
        viewState={viewState}
      >
        <Tree treeId="tree" rootItem="root" treeLabel={intl.formatMessage(messages.menuHistory)} />
      </UncontrolledTreeEnvironment>
    </ModalStyled>
  );
};

export default MenuHistoryModal;
