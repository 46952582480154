import React from "react";
import { useSelector } from "react-redux";
import { Text } from "components";
import { FormattedMessage } from "react-intl";
import { SubNavigation, SubNavigationHeader, SubNavigationContent } from "@wfp/ui";
import { selectLanguages } from "containers/App/selectors";
import Language from "./Language";
import { Wrapper } from "./styles";
import messages from "./messages";

export default function Content({ mainNavigationRef }) {
  const languages = useSelector(selectLanguages);

  return (
    <SubNavigation>
      <SubNavigationHeader>
        <Text bold wrap fontSize="16px" value={<FormattedMessage {...messages.chooseLanguage} />} />
      </SubNavigationHeader>
      <SubNavigationContent>
        <Wrapper>
          {(languages || []).map((language) => (
            <Language key={language.language} {...language} mainNavigationRef={mainNavigationRef} />
          ))}
        </Wrapper>
      </SubNavigationContent>
    </SubNavigation>
  );
}
