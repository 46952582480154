import React from "react";
import { colors } from "@wfp/ui";

export const Grains = ({ size, disable }) => (
  <svg
    width={size || "20px"}
    height={size || "20px"}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-221.000000, -539.000000)"
        fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}
        fillRule="nonzero"
      >
        <g transform="translate(221.000000, 539.000000)">
          <path d="M3.64307392,15.8950727 C3.49651348,15.7486707 3.26620421,15.7486707 3.11964376,15.8741581 L0.230309271,18.551223 C0.0628116193,18.697625 0.0628116193,18.9485998 0.209372064,19.0950018 L0.984048703,19.8688408 C1.13060915,20.0152428 1.38185563,20.0152428 1.52841607,19.8479263 L4.18744129,16.9617157 C4.33400173,16.8153137 4.31306453,16.5852535 4.16650408,16.4388515 L3.64307392,15.8950727 Z" />
          <path d="M15.0538514,5.39595888 C15.7866537,5.37504431 17.545379,5.20772776 18.6759881,4.07834101 C19.8065973,2.94895427 19.9740949,1.19213045 19.9950322,0.460120525 C19.9950322,0.271889401 19.8484717,0.104572847 19.6390996,0.104572847 C18.9062974,0.125487416 17.1475721,0.29280397 16.0169629,1.42219071 C14.8863538,2.55157745 14.7188561,4.30840128 14.6979189,5.0404112 C14.6979189,5.22864233 14.8654166,5.39595888 15.0538514,5.39595888 Z" />
          <path d="M15.8494653,6.10705424 C14.2582376,6.27437079 13.0229424,7.52924495 12.5413867,8.09393832 C12.4157634,8.2403403 12.4367006,8.47040057 12.5832611,8.57497341 C13.1695029,9.03509394 14.6351073,9.99716413 16.226335,9.82984757 C17.8175627,9.66253102 19.0528579,8.40765686 19.5344136,7.84296349 C19.6600369,7.6965615 19.6390996,7.46650124 19.4925392,7.36192839 C18.9062974,6.92272244 17.440693,5.93973768 15.8494653,6.10705424 Z" />
          <path d="M11.9970193,7.55015952 C12.5623239,7.06912442 13.8185563,5.83516484 13.9860539,4.24565757 C14.1535516,2.6561503 13.1695029,1.19213045 12.7298215,0.60652251 C12.6041983,0.460120525 12.3948262,0.418291386 12.2482658,0.564693371 C11.6829612,1.04572847 10.4267288,2.27968805 10.2592312,3.86919532 C10.0917335,5.45870259 11.0757822,6.92272244 11.5154635,7.50833038 C11.6201496,7.65473236 11.8504588,7.67564693 11.9970193,7.55015952 Z" />
          <path d="M11.9970193,9.95533499 C10.4057916,10.1226515 9.17049642,11.3775257 8.68894067,11.9422191 C8.56331744,12.0886211 8.58425464,12.3186813 8.73081509,12.4232542 C9.31705687,12.8833747 10.7826613,13.8454449 12.373889,13.6781283 C13.9651167,13.5108118 15.2004119,12.2559376 15.6819676,11.6912442 C15.8075909,11.5448423 15.7866537,11.314782 15.6400932,11.2102091 C15.0538514,10.7710032 13.588247,9.78801843 11.9970193,9.95533499 Z" />
          <path d="M8.14457331,11.3984403 C8.70987788,10.9174052 9.96611027,9.68344559 10.1336079,8.09393832 C10.3011056,6.50443105 9.31705687,5.0404112 8.87737553,4.45480326 C8.75175229,4.30840128 8.54238023,4.26657214 8.39581978,4.41297412 C7.83051521,4.89400922 6.57428282,6.12796881 6.40678517,7.71747607 C6.23928752,9.30698334 7.22333622,10.7710032 7.66301756,11.3566111 C7.7886408,11.5030131 7.99801286,11.5239277 8.14457331,11.3984403 Z" />
          <path d="M8.16551051,13.7827012 C6.57428282,13.9500177 5.33898764,15.2048919 4.8574319,15.7695853 C4.73180866,15.9159872 4.75274586,16.1460475 4.89930631,16.2506203 C5.48554809,16.7107409 6.95115254,17.6728111 8.54238023,17.5054945 C10.1336079,17.338178 11.3689031,16.0833038 11.8504588,15.5186104 C11.9760821,15.3722084 11.9551449,15.1421482 11.8085844,15.0375753 C11.2223427,14.5983694 9.7567382,13.6153846 8.16551051,13.7827012 Z" />
          <path d="M6.30209914,11.9213045 C6.46959679,10.3317972 5.48554809,8.86777738 5.04586675,8.28216944 C4.92024351,8.13576746 4.71087145,8.09393832 4.56431101,8.2403403 C3.99900643,8.7213754 2.74277404,9.95533499 2.57527639,11.5448423 C2.40777874,13.1343495 3.39182744,14.5983694 3.83150878,15.1839773 C3.95713202,15.3303793 4.16650408,15.3722084 4.31306453,15.2258065 C4.8783691,14.7447714 6.13460149,13.5108118 6.30209914,11.9213045 Z" />
        </g>
      </g>
    </g>
  </svg>
);
