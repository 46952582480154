import styled from "styled-components";

export const GraphForPDF = styled.div`
  position: absolute;
  top: 0;
  left: 200%;
  background: white;
  width: 1300px;
  height: 500px;
`;
