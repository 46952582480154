import "react-accessible-accordion/dist/fancy-example.css";
import styled from "styled-components";
import { AccordionItemButton as _AccordionItemButton, Accordion as _Accordion } from "react-accessible-accordion";
import { Icon } from "@wfp/ui";

export const AccordionItemButton = styled(_AccordionItemButton)`
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent !important;
  font-weight: 600;
  color: #0a6eb4;
  font-size: ${({ fontSize }) => fontSize || "20px"};
  padding: ${({ padding }) => padding || "10px 20px 5px"};
  cursor: pointer;
`;

export const Accordion = styled(_Accordion)`
  border: none;
  width: 100%;
  .accordion__item + .accordion__item {
    border: none;
  }
  .accordion__panel {
    padding: ${({ padding }) => padding || "10px 20px 5px"};
  }
  .accordion__button[aria-expanded="true"]::before {
    margin-bottom: 3px;
  }
`;

export const IconStyled = styled(Icon)`
  transform: ${({ expanded }) => (expanded === "true" ? "rotate(-180deg)" : "rotate(0deg)")};
  margin: 0 10px;
  padding-top: ${({ expanded }) => (expanded === "true" ? "0" : "5px")};
  transition: transform 0.35s ease-in;
`;
