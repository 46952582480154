import { defineMessage } from "react-intl";

export default {
  modalTitle: defineMessage({
    id: "foodBasket.modalTitle",
    defaultMessage: "Food basket ({numGroups} food groups, {numItems} food items)",
  }),
  modalWithSources: defineMessage({
    id: "foodBasket.modalWithSources",
    defaultMessage: "Food basket ({numSources} sourcing channels, {numGroups} food groups, {numItems} food items)",
  }),
  modalInfo: defineMessage({
    id: "foodBasket.modalInfo",
    defaultMessage:
      "All the food items in your food basket might appear in your menu. It is made up of the food items for which we have price information for the chosen settings. To introduce an item in the food basket, add its price on the food prices page.",
  }),
};
