import styled from "styled-components";
import { Footer } from "@wfp/ui";

import { LogoPartner, LogoAfricanUnion, LogoSodexo, LogoInnovationAccelerator, LogoNorad, LogoFao } from "./assets";

export const StyledFooter = styled(Footer)`
  .wfp--footer__info {
    display: none;
  }
  .wfp--footer__cta {
    width: 100%;
    svg {
      display: none;
    }
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 30px;
  gap: 15px;
`;

export const PartnerLogo = styled.div`
  background-image: url(${LogoPartner});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 223px;
  height: 58px;
`;

export const WFPLogo = styled.div`
  background-image: ${({ logo }) => `url(${logo})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 155px;
  height: 58px;
`;

export const AfricanUnionLogo = styled.div`
  background-image: url(${LogoAfricanUnion});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 223px;
  height: 58px;
`;

export const SodexoLogo = styled.div`
  background-image: url(${LogoSodexo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 155px;
  height: 58px;
`;

export const InnovationAcceleratorLogo = styled.div`
  background-image: url(${LogoInnovationAccelerator});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 185px;
  height: 58px;
`;

export const NoradLogo = styled.div`
  background-image: url(${LogoNorad});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 155px;
  height: 58px;
`;

export const FaoLogo = styled.div`
  background-image: url(${LogoFao});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75px;
  height: 58px;
`;
