import { useCallback } from "react";
import { colors, Icon } from "@wfp/ui";
import { iconArrowLeft } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";

const Header = ({ item, setStep, setOpenAddPriceSource }) => {
  const { setValue } = useFormContext();

  const goBack = useCallback(() => {
    setOpenAddPriceSource(false);
    setStep(0);
    setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}`, undefined);
  }, [setOpenAddPriceSource, setStep, setValue]);

  return (
    <RowStyled middle="xs" margin="0 0 10px">
      <ColStyled xs={12}>
        <RowStyled middle="xs">
          <Icon
            className="pointer"
            icon={iconArrowLeft}
            width="16px"
            height="16px"
            description="back"
            fill={colors["brand-01"].hex}
            onClick={goBack}
          />
          <Text bold fontSize="22px" marginLeft="8px" value={item.display_name} />
        </RowStyled>
      </ColStyled>
    </RowStyled>
  );
};

export default Header;
