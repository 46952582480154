import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Button } from "components";
import { updateSingleUser } from "../actions";
import messages from "./messages";

const ActivationButton = ({ user }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onClick = useCallback(() => {
    dispatch(updateSingleUser({ user, body: { is_active: !user.is_active } }));
  }, [dispatch, user]);

  return (
    <Button
      widthAuto
      kind="primary"
      onClick={onClick}
      children={intl.formatMessage(user.is_active ? messages.deactivateUser : messages.activateUser)}
    />
  );
};

export default ActivationButton;
