import { useMemo, useCallback, useState, useEffect } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";
import { Loading } from "@wfp/ui";

import { Text } from "components";
import { RowStyled } from "components/utils";
import { loadSummaryPricesRequest } from "containers/FoodBasket/actions";
import { FIELDS } from "containers/FoodBasket/constants";
import foodBasketMessages from "containers/FoodBasket/messages";
import { selectFoodItems, selectLoadingFoodItems, selectLoadingFoodPrice } from "containers/FoodBasket/selectors";
import { getSummaryPricesFilters } from "containers/FoodBasket/utils";
import { useBoolean } from "hooks";
import { defaultColumnNumber } from "./constants";
import { ItemsContainer, Wrapper } from "./styles";
import ActiveFilters from "./ActiveFilters";
import LegendSources from "./LegendSources";
import FoodGroup from "./FoodGroup";
import FoodModal from "./FoodModal";

const FoodItemsList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control, getValues } = useFormContext();
  const formNutrients = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.NUTRIENTS}` });

  const [openModal, setOpenModal] = useBoolean();
  const [openPersonalPrice, setOpenPersonalPrice] = useBoolean();
  const [modalHeader, setModalHeader] = useState();
  const [itemId, setItemId] = useState();
  const [price, setPrice] = useState();
  const [activeFilters, setActiveFilters] = useState([]);

  const loadingFoodItems = useSelector(selectLoadingFoodItems);
  const loadingFoodPrice = useSelector(selectLoadingFoodPrice);
  const foodItems = useSelector(selectFoodItems);

  // Check active filters and show/hide <ActiveFilters> section. It runs every time our food items list change
  useEffect(() => {
    if (!R.isNil(formNutrients)) {
      const changedNutrients = formNutrients.filter((nutrient) => nutrient.percentage.some((bound) => bound !== 0));

      if (!R.equals(activeFilters, changedNutrients)) {
        setActiveFilters(changedNutrients);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, foodItems]);

  const itemsChunks = useMemo(() => {
    const groupBy = R.groupBy((item) => item.major_group, foodItems);

    const arrFromObject = Object.entries(groupBy).map(([key, value]) => ({ [key]: value }));

    // Calc chunk length based on number of incoming groups. Default to 1 if we have less than "defaultColumnNumber" groups
    const numOfGroups = arrFromObject.length;
    const chunkSize = Math.ceil(numOfGroups / defaultColumnNumber) || 1;
    const chunks = R.splitEvery(chunkSize, arrFromObject);

    return chunks;
  }, [foodItems]);

  const openModalHandler = useCallback(
    (item) => {
      const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
      dispatch(loadSummaryPricesRequest({ itemId: item.value, filters }));
      setModalHeader(item.label);
      setItemId(item.value);
      setOpenModal.setTrue();
    },
    [dispatch, getValues, setOpenModal],
  );

  const renderList = useMemo(() => {
    if (R.isEmpty(itemsChunks)) {
      return (
        <RowStyled middle="xs" center="xs" flex={1} span>
          <Text fontSize="16px" value={intl.formatMessage(foodBasketMessages.noResults)} />
        </RowStyled>
      );
    }

    return itemsChunks.map((chunk) => {
      return chunk.map((group) => {
        const labelGroup = Object.keys(group)[0];
        const groupItems = group[labelGroup];

        return <FoodGroup key={labelGroup} title={labelGroup} items={groupItems} openModal={openModalHandler} />;
      });
    });
  }, [intl, itemsChunks, openModalHandler]);

  // Close both modal and AddPersonalPrice section
  const onRequestClose = useCallback(() => {
    setPrice();
    setOpenModal.setFalse();
    setOpenPersonalPrice.setFalse();
  }, [setOpenModal, setOpenPersonalPrice]);

  const editFoodPrice = useCallback(
    (price) => {
      setPrice(price);
      setOpenPersonalPrice.setTrue();
    },
    [setOpenPersonalPrice],
  );

  return (
    <>
      <Loading active={loadingFoodItems || loadingFoodPrice} />
      <Wrapper>
        {!R.isEmpty(activeFilters) && <ActiveFilters activeFilters={activeFilters} />}
        <ItemsContainer>{renderList}</ItemsContainer>
        {<LegendSources />}
      </Wrapper>

      <FoodModal
        open={openModal}
        modalHeading={modalHeader}
        onRequestClose={onRequestClose}
        openPersonalPrice={openPersonalPrice}
        setOpenPersonalPrice={setOpenPersonalPrice}
        price={price}
        setPrice={setPrice}
        itemId={itemId}
        editFoodPrice={editFoodPrice}
      />
    </>
  );
};

export default FoodItemsList;
