import * as R from "ramda";

import { Text } from "components";
import foodBasketMessages from "containers/FoodBasket/messages";
import menuMessages from "containers/Menu/messages";

export default (intl, procurementSources) => [
  {
    Header: intl.formatMessage(foodBasketMessages.columnProcurementSource),
    accessor: "source",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      const { label: labelA } = procurementSources.find((source) => source.value === a);
      const { label: labelB } = procurementSources.find((source) => source.value === b);

      if (labelA > labelB) return 1;
      if (labelA < labelB) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const label = R.pathOr(
        "",
        ["label"],
        procurementSources.find((source) => source.value === row.original.source),
      );
      return <Text fontSize="14px" value={label} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnRegion),
    accessor: "region",
    Cell: ({ cell: { row } }) => {
      const region =
        row.original.region === "country" ? intl.formatMessage(menuMessages.wholeCountry) : row.original.region;
      return <Text fontSize="14px" value={region} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnPricePerKg),
    accessor: "price",
    Cell: ({ cell: { row } }) => <Text fontSize="14px" value={row.original.price.toFixed(3)} />,
  },
];
