import React from "react";
import { List } from "./styles";

export default function BulletList({ list, color, fontSize }) {
  return (
    <List color={color} fontSize={fontSize}>
      {(list || []).map((li, index) => (
        <li key={`li-${index}`}>{li}</li>
      ))}
    </List>
  );
}
