import styled, { keyframes } from "styled-components";
import { Icon } from "@wfp/ui";

const rotateIcon = keyframes`
  from {transform: rotate(-20deg)}
  to {transform: rotate(20deg)}
`;

export const RotateIcon = styled(Icon)`
  margin-bottom: 3rem;
  transform: rotate(-20deg);
  animation: ${rotateIcon} 2s ease-in-out infinite alternate;
`;
