import { Text, ChipWithLabel } from "components";
import { newColorBySource } from "components/utils";
import foodBasketMessages from "containers/FoodBasket/messages";
import menuMessages from "containers/Menu/messages";
import Actions from "../Actions";

export const columns = (
  intl,
  unitRate,
  currencyLabel,
  procurementSources,
  months,
  accountId,
  isAdmin,
  onEdit,
  onRequestClose,
) => [
  {
    Header: intl.formatMessage(foodBasketMessages.columnSource),
    accessor: "display_list",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      const labelA = a.split("-")[1];
      const labelB = b.split("-")[1];

      if (labelA > labelB) return 1;
      if (labelA < labelB) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => <Text fontSize="14px" value={row.original.display_list.split("-")[1]} />,
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnProcurementSource),
    accessor: "source",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      const { label: labelA } = procurementSources.find((source) => source.value === a);
      const { label: labelB } = procurementSources.find((source) => source.value === b);

      if (labelA > labelB) return 1;
      if (labelA < labelB) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const { value, label } = procurementSources.find((source) => source.value === row.original.source);
      return <ChipWithLabel backgroundColor={newColorBySource[value]} value={label} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnDate),
    accessor: "date",
    Cell: ({ cell: { row } }) => {
      const { month, year } = row.original;
      const value = month && year ? `${months[month - 1].label} ${year}` : "";
      return <Text fontSize="14px" transform="capitalize" value={value} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnRegion),
    accessor: "region.label",
    Cell: ({ cell: { row } }) => {
      const { region } = row.original;
      const regionLabel =
        region === null || region?.region === "country" ? intl.formatMessage(menuMessages.wholeCountry) : region.region;
      return <Text fontSize="14px" transform="capitalize" value={regionLabel} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnPurchaseCost),
    accessor: currencyLabel === "USD" ? "usd_price_per_gram" : "local_price_per_gram",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const purchasePrice =
        row.original[currencyLabel === "USD" ? "usd_price_per_gram" : "local_price_per_gram"] * unitRate;
      return <Text fontSize="14px" value={purchasePrice.toFixed(3)} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnAdditionalCosts),
    accessor: "additional_costs",
    sortType: (rowA, rowB) => {
      const purchasePriceA =
        rowA.original[currencyLabel === "USD" ? "usd_price_per_gram" : "local_price_per_gram"] * unitRate;
      const totalPriceA =
        rowA.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate;
      const additionalCostsA = (totalPriceA - purchasePriceA).toFixed(2);

      const purchasePriceB =
        rowB.original[currencyLabel === "USD" ? "usd_price_per_gram" : "local_price_per_gram"] * unitRate;
      const totalPriceB =
        rowB.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate;
      const additionalCostsB = (totalPriceB - purchasePriceB).toFixed(2);

      if (Number(additionalCostsA) > Number(additionalCostsB)) return 1;
      if (Number(additionalCostsA) < Number(additionalCostsB)) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const purchasePrice =
        row.original[currencyLabel === "USD" ? "usd_price_per_gram" : "local_price_per_gram"] * unitRate;
      const totalPrice =
        row.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate;
      return <Text fontSize="14px" value={(totalPrice - purchasePrice).toFixed(2)} />;
    },
  },
  {
    Header: intl.formatMessage(foodBasketMessages.columnTotalPrice),
    accessor: "price",
    sortType: (rowA, rowB) => {
      const totalPriceA = (
        rowA.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate
      ).toFixed(3);
      const totalPriceB = (
        rowB.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate
      ).toFixed(3);

      if (Number(totalPriceA) > Number(totalPriceB)) return 1;
      if (Number(totalPriceA) < Number(totalPriceB)) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const totalPrice =
        row.original[currencyLabel === "USD" ? "usd_price_with_additional" : "local_price_with_additional"] * unitRate;
      return <Text fontSize="14px" value={totalPrice.toFixed(3)} />;
    },
  },
  {
    Header: "",
    accessor: "actions",
    disableSortBy: true,
    Cell: ({ cell: { row } }) =>
      accountId === row.original.creator_id || isAdmin ? (
        <Actions price={row.original} onEdit={onEdit} onRequestClose={onRequestClose} />
      ) : null,
  },
];
