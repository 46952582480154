import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";

import { RowStyled, ColStyled } from "components/utils";
import { selectFoodItems } from "containers/OptimizedMenu/Rules/FoodRules/selectors";
import ExcludeFromGroup from "./ExcludeFromGroup";
import MatchOrUnmatch from "../MatchOrUnmatch";
import PortionSize from "../PortionSize";
import Repetition from "../Repetition";

type ItemRestrictionProps = {
  daysInWeek: number,
  name: string
  weekCount: number,
  exclutionChoice: "include" | "exclude" | "no-rule",
}
const ItemRestriction = ({ daysInWeek, name, weekCount, exclutionChoice }: ItemRestrictionProps): JSX.Element => {
  const foodItems: {label: string}[]  = useSelector(selectFoodItems);

  const options = useMemo(() => foodItems.filter((el) => !name.includes(el.label)), [foodItems, name]);
  const includeSelected = exclutionChoice === "include"; 
  const excludeSelected = exclutionChoice === "exclude"; 
  const noRuleSelected = exclutionChoice === "no-rule";

  return (
    <RowStyled>
      <ColStyled xs={12}>
        <Repetition
          disable={excludeSelected || noRuleSelected}
          daysInWeek={daysInWeek}
          weekCount={weekCount}
        />

        <ExcludeFromGroup disable={excludeSelected || noRuleSelected} />

        <RowStyled>
          <ColStyled xs={4} className={includeSelected || noRuleSelected ? "" : "disable"}>
            <PortionSize kindRestriction="item" />
          </ColStyled>
          <ColStyled xs={4} className={includeSelected ? "" : "disable"}>
            <MatchOrUnmatch keyField={"unmatching"} entity="Items" name={name} options={options} />
          </ColStyled>
          <ColStyled xs={4} className={includeSelected ? "" : "disable"}>
            <MatchOrUnmatch keyField={"matching"} entity="Items" name={name} options={options} />
          </ColStyled>
        </RowStyled>
      </ColStyled>
    </RowStyled>
  );
};

export default memo(ItemRestriction);
