import React from "react";
import { Switch, Route } from "react-router-dom";
import MyMenus from "containers/MyMenus";
import SelectCountry from "containers/SelectCountry";
import FoodBasket from "containers/FoodBasket";
import GeneralInfo from "containers/GeneralInfo";
import GeneralInfoCommunity from "containers/GeneralInfoCommunity";
import OptimizedMenu from "containers/OptimizedMenu";
import Compare from "containers/Compare";
import Results from "containers/Results";
import ResultsCommunity from "containers/ResultsCommunity";
import CiamLogin from "containers/App/CiamLogin";
import CiamRedirect from "containers/App/CiamRedirect";
import InactiveUser from "containers/App/InactiveUser";
import Admin from "containers/Admin";
import NotFound from "containers/App/NotFound";
import routesPath from "./paths";
import { isCommunityFeatureEnabled } from "utils/constants";
import PrivateRoute from "components/PrivateRoute";
import Info from "containers/App/Info";

export default function Routes() {
  return (
    <Switch>
      <Route path={routesPath.menus} exact component={MyMenus} />
      <Route path={routesPath.compare} exact component={Compare} />
      <Route path={routesPath.results} exact component={Results} />
      {isCommunityFeatureEnabled && <Route path={routesPath.resultsCommunity} exact component={ResultsCommunity} />}
      <Route path={routesPath.createManual} exact component={GeneralInfo} />
      <Route path={routesPath.createOptimized} exact component={OptimizedMenu} />
      {isCommunityFeatureEnabled && <Route path={routesPath.createCommunity} exact component={GeneralInfoCommunity} />}
      <Route path={routesPath.editManual} exact component={GeneralInfo} />
      <Route path={routesPath.editOptimized} exact component={OptimizedMenu} />
      {isCommunityFeatureEnabled && <Route path={routesPath.editCommunity} exact component={GeneralInfoCommunity} />}
      <Route path={routesPath.duplicateManual} exact component={GeneralInfo} />
      <Route path={routesPath.duplicateOptimized} exact component={OptimizedMenu} />
      {isCommunityFeatureEnabled && (
        <Route path={routesPath.duplicateCommunity} exact component={GeneralInfoCommunity} />
      )}
      <Route path={routesPath.foodBasket} component={FoodBasket} />
      <Route path={routesPath.notActive} component={InactiveUser} />
      <Route path={routesPath.ciamRedirect} component={CiamRedirect} />
      <Route path={routesPath.ciamLogin} component={CiamLogin} />
      <Route path={routesPath.selectCountry} exact component={SelectCountry} />
      <Route path={routesPath.info} exact component={Info} />
      <PrivateRoute path={routesPath.admin} component={Admin} />
      <Route component={NotFound} />
    </Switch>
  );
}
