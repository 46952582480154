import messages from "../messages";
import { intlStateOptions } from "containers/Admin/constants";

export const allUsersFields = (intl) => [
  {
    name: "email",
    type: "text",
    label: intl.formatMessage(messages.email),
  },
  {
    name: "is_active",
    type: "select",
    label: intl.formatMessage(messages.state),
    options: intlStateOptions(intl),
    defaultValue: intlStateOptions(intl)[0],
  },
];
