import { useMemo, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";

import { NewAccordion, Text } from "components";
import { selectAvailableSources } from "containers/OptimizedMenu/selectors";
import { KEYS } from "../constants";
import messages from "../messages";
import SelectFoodGroups from "./SelectFoodGroups";

const AdvancedSettings = ({ name }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const source = useWatch({ control, name });
  const foodGroupsWatch = useWatch({ control, name: `${name}.${KEYS.FOOD_GROUPS}` });
  const {
    fields: foodGroupsFields,
    append,
    replace,
    remove,
  } = useFieldArray({ control, name: `${name}.${KEYS.FOOD_GROUPS}` });

  const availableSources = useSelector(selectAvailableSources);

  const addEmptyGroup = useCallback(() => append({ [KEYS.GROUP]: "" }), [append]);

  const removeEmptyElements = useCallback(() => {
    replace(foodGroupsWatch.filter((el) => !!el[KEYS.GROUP]));
  }, [foodGroupsWatch, replace]);

  const removeFoodGroup = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  // Running when opening/closing the accordion.
  const openAndFill = useCallback(
    (el) => {
      if (R.isEmpty(foodGroupsWatch)) addEmptyGroup();
      if (R.isEmpty(el)) removeEmptyElements();
    },
    [addEmptyGroup, foodGroupsWatch, removeEmptyElements]
  );

  const foodGroupOptions = useMemo(() => {
    if (!source) return [];
    const foundSource = availableSources.find((el) => el.value === source.source);
    return foundSource ? foundSource.groups : [];
  }, [availableSources, source]);

  const accordionClassName = useMemo(() => (!R.pathOr("", ["source"], source) ? "disable" : ""), [source]);

  return (
    <NewAccordion
      className={accordionClassName}
      onChange={openAndFill}
      fields={[
        {
          title: intl.formatMessage(messages.advancedSettings),
          titleFontSize: "16px",
          content: (
            <>
              <Text value={intl.formatMessage(messages.advancedDescription)} />
              <SelectFoodGroups
                name={name}
                addEmptyGroup={addEmptyGroup}
                removeFoodGroup={removeFoodGroup}
                foodGroupOptions={foodGroupOptions}
                foodGroupsFields={foodGroupsFields}
              />
            </>
          ),
        },
      ]}
    />
  );
};

export default AdvancedSettings;
