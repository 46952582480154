import React from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { CheckboxHookForm, SelectHookForm, NumberInputHookForm, TextInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import adminMessages from "containers/Admin/Users/messages";
import { selectFoodItemOptions } from "containers/Admin/selectors";
import {
  selectRegions,
  selectFoodSources,
  selectAdditionalCostChoices,
  selectOrigins,
  selectWeightChoices,
} from "containers/App/selectors";
import { years } from "containers/FoodBasket/constants";
import foodBasketMessages from "containers/FoodBasket/messages";
import generalFormMessages from "containers/GeneralInfo/GeneralForm/messages";
import { useCurrencies } from "hooks";
import { useMonths } from "utils/utils";
import { FIELDS, ADDITIONAL_COSTS_INITIAL_VALUES } from "./constants";
import Footer from "./Footer";
import { PriceContainer } from "./styles";
import { canEditFoodPrice } from "./utils";

type PriceProps = {
  index: number,
  approve: (foodPrice: any) => void,
  reject: (foodPriceId: any) => void,
};

const Price = ({ index, approve, reject }: PriceProps) => {
  const intl = useIntl();
  const regions = useSelector(selectRegions);
  const foodItems = useSelector(selectFoodItemOptions);
  const procurementSources = useSelector(selectFoodSources);
  const additionalCosts = useSelector(selectAdditionalCostChoices);
  const origins = useSelector(selectOrigins);
  const units = useSelector(selectWeightChoices);

  const { control } = useFormContext();
  const foodPrice = useWatch({ control, name: `${FIELDS.FOOD_PRICES}.${index}` });
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ADDITIONAL_COSTS}`,
  });
  const months = useMonths(intl);
  const currencies = useCurrencies();

  const addAdditionalCost = useCallback(() => {
    append(ADDITIONAL_COSTS_INITIAL_VALUES);
  }, [append]);

  const approveFoodPrice = useCallback(() => {
    approve(foodPrice);
  }, [approve, foodPrice]);

  const rejectFoodPrice = useCallback(() => {
    reject(foodPrice._id);
  }, [foodPrice, reject]);

  const isDisabled = useMemo(() => !canEditFoodPrice(foodPrice.price_status), [foodPrice.price_status]);

  return (
    <PriceContainer>
      <RowStyled middle="xs">
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ITEM}`}
            options={foodItems}
            label={intl.formatMessage(foodBasketMessages.foodItem)}
            virtualized
          />
        </ColStyled>
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.PROCUREMENT_SOURCE}`}
            options={procurementSources}
            label={intl.formatMessage(foodBasketMessages.procurementSource)}
          />
        </ColStyled>
        <ColStyled xs={2}>
          <NumberInputHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.PRICE}`}
            labelText={intl.formatMessage(foodBasketMessages.foodPrice)}
          />
        </ColStyled>
        <ColStyled xs={2}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.QUANTITY}`}
            options={units}
            label={intl.formatMessage(foodBasketMessages.unit)}
          />
        </ColStyled>
        <ColStyled xs={2}>
          <CheckboxHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.STAPLE}`}
            labelText={intl.formatMessage(foodBasketMessages.staple)}
            id={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.STAPLE}`}
            noMarginBottom
          />
        </ColStyled>
      </RowStyled>

      <RowStyled margin="1.5rem 0 0 0">
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.REGION}`}
            options={regions}
            label={intl.formatMessage(foodBasketMessages.region)}
            placeholder={intl.formatMessage(generalFormMessages.placeholderCountry)}
          />
        </ColStyled>
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.CURRENCY}`}
            options={currencies}
            label={intl.formatMessage(foodBasketMessages.currency)}
          />
        </ColStyled>
        <ColStyled xs={6}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ORIGIN}`}
            options={origins}
            label={intl.formatMessage(foodBasketMessages.priceCollectedHow)}
          />
        </ColStyled>
      </RowStyled>

      <RowStyled margin="1.5rem 0 0 0">
        <ColStyled xs={6}>
          <TextInputHookForm
            control={control}
            name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.USERNAME}`}
            disabled
            labelText={intl.formatMessage(adminMessages.email)}
          />
        </ColStyled>
        <ColStyled xs={6}>
          <label className="wfp--label" style={{ display: "block" }}>
            {intl.formatMessage(foodBasketMessages.priceCollectedWhen)}
          </label>
          <RowStyled>
            <ColStyled xs={6} padding="0 0.25rem 0 0">
              <SelectHookForm
                control={control}
                name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.MONTH}`}
                options={months}
              />
            </ColStyled>
            <ColStyled xs={6} padding="0 0 0 0.25rem">
              <SelectHookForm
                control={control}
                name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.YEAR}`}
                options={years}
              />
            </ColStyled>
          </RowStyled>
        </ColStyled>
      </RowStyled>

      {isDisabled ? null : (
        <>
          <RowStyled margin="1.5rem 0 0 0.5rem">
            <Button
              widthAuto
              kind="secondary"
              onClick={addAdditionalCost}
              children={intl.formatMessage(buttonMessages.addAdditionalPrice)}
            />
          </RowStyled>

          {fields.map((field, k) => (
            <RowStyled key={field.id} margin="1.5rem 0 0 0">
              <ColStyled xs={6}>
                <SelectHookForm
                  control={control}
                  name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ADDITIONAL_COSTS}.${k}.${FIELDS.TYPE}`}
                  options={additionalCosts}
                  label={intl.formatMessage(foodBasketMessages.type)}
                />
              </ColStyled>
              <ColStyled xs={3}>
                <NumberInputHookForm
                  control={control}
                  name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ADDITIONAL_COSTS}.${k}.${FIELDS.PRICE}`}
                  labelText={intl.formatMessage(foodBasketMessages.price)}
                />
              </ColStyled>
              <ColStyled xs={2}>
                <SelectHookForm
                  control={control}
                  name={`${FIELDS.FOOD_PRICES}.${index}.${FIELDS.ADDITIONAL_COSTS}.${k}.${FIELDS.QUANTITY}`}
                  options={[{ value: "%", label: "%" }, ...currencies]}
                  label={intl.formatMessage(foodBasketMessages.currency)}
                />
              </ColStyled>
              <ColStyled xs={1}>
                <Icon
                  className="pointer paddingTopSmall"
                  description={intl.formatMessage(buttonMessages.remove)}
                  icon={iconDelete}
                  width="23"
                  height="23"
                  fill={colors["brand-01"].hex}
                  onClick={() => remove(k)}
                />
              </ColStyled>
            </RowStyled>
          ))}

          <Footer approve={approveFoodPrice} reject={rejectFoodPrice} index={index} />
        </>
      )}
    </PriceContainer>
  );
};

export default Price;
