import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@results/LOAD_STARTED");
export const loadEnded = createAction("@@results/LOAD_ENDED");
export const loadSuccess = createAction("@@results/LOAD_SUCCESS_RESULTS");

export const loadResults = createAction("@@results/LOAD_RESULTS");
export const loadCommunityResults = createAction("@@results/LOAD_COMMUNITY_RESULTS");
export const saveResultsRequest = createAction("@@results/SAVE_RESULTS_REQUEST");
export const saveAllCommunityResults = createAction("@@results/SAVE_ALL_COMMUNITY_RESULTS");

export const resetResults = createAction("@@results/RESET_RESULTS");

export const loadSingleNutritionalValuesStarted = createAction("@@results/LOAD_SINGLE_NUTRITIONAL_VALUES_STARTED");
export const loadSingleNutritionalValuesEnded = createAction("@@results/LOAD_SINGLE_NUTRITIONAL_VALUES_ENDED");
export const loadSingleNutritionalValues = createAction("@@results/LOAD_SINGLE_NUTRITIONAL_VALUES");
export const loadSingleNutritionalValuesSuccess = createAction("@@results/LOAD_SINGLE_NUTRITIONAL_VALUES_SUCCESS");

export const loadCommunityGroupsAndItemsSuccess = createAction("@@results/LOAD_COMMUNITY_GROUPS_AND_ITEMS_SUCCESS");

// Still using it within ResultsCommunity domain.
export const updateFormState = createAction("@@results/UPDATE_FORM_STATE");
