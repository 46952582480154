import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

type LoadFoodPricesProps = {
  params: any;
};

type ApproveFoodPricesProps = {
  foodPrice: any;
};

type ApproveAllFoodPricesProps = {
  foodPrices: any;
};

type RejectFoodPricesProps = {
  foodPriceId: number;
};

// Users side
export const loadUsers = createAction("@@admin/LOAD_USERS");
export const loadStarted = createAction("@@admin/LOAD_STARTED_USERS");
export const loadEnded = createAction("@@admin/LOAD_ENDED_USERS");
export const loadSuccess = createAction("@@admin/LOAD_USERS_SUCCESS");

export const loadSingleUser = createAction("@@admin/LOAD_SINGLE_USER");
export const loadSingleUserSuccess = createAction(
  "@@admin/LOAD_SINGLE_USER_SUCCESS"
);

export const updateSingleUser = createAction("@@admin/UPDATE_SINGLE_USER");

export const loadUserGroups = createAction("@@admin/LOAD_USER_GROUPS");
export const loadUserGroupsSuccess = createAction(
  "@@admin/LOAD_USER_GROUPS_SUCCESS"
);

export const updateTableParams = createAction("@@admin/UPDATE_TABLE_PARAMS");
export const resetTableParams = createAction("@@admin/RESET_TABLE_PARAMS");
export const updateTableFilters = createAction("@@admin/UPDATE_TABLE_FILTERS");
export const resetTableFilters = createAction("@@admin/RESET_TABLE_FILTERS");
export const resetUsers = createAction("@@admin/RESET_USERS");

// Countries side
export const loadCountriesSliceStarted = createAction(
  "@@admin/LOAD_COUNTRIES_SLICE_STARTED"
);
export const loadCountriesSliceEnded = createAction(
  "@@admin/LOAD_COUNTRIES_SLICE_ENDED"
);
export const updateFormState = createAction("@@admin/UPDATE_FORM_STATE");

export const setMenuType = createAction("@@admin/SET_MENU_TYPE");
export const loadSelectedSources = createAction(
  "@@admin/LOAD_SELECTED_SOURCES"
);
export const loadSelectedSourcesSuccess = createAction(
  "@@admin/LOAD_SELECTED_SOURCES_SUCCESS"
);
export const loadAllSources = createAction("@@admin/LOAD_ALL_SOURCES");
export const loadAllSourcesSuccess = createAction(
  "@@admin/LOAD_ALL_SOURCES_SUCCESS"
);
export const updateSources = createAction("@@admin/UPDATE_SOURCES");
export const resetSources = createAction("@@admin/RESET_SOURCES");

export const loadSelectedCommunityNutrientsLevel = createAction(
  "@@admin/LOAD_SELECTED_COMMUNITY_NUTRIENTS_LEVEL"
);
export const loadSelectedCommunityNutrientsLevelSuccess = createAction(
  "@@admin/LOAD_SELECTED_COMMUNITY_NUTRIENTS_LEVEL_SUCCESS"
);
export const loadAllCommunityNutrientsLevel = createAction(
  "@@admin/LOAD_ALL_COMMUNITY_NUTRIENTS_LEVEL"
);
export const loadAllCommunityNutrientsLevelSuccess = createAction(
  "@@admin/LOAD_ALL_COMMUNITY_NUTRIENTS_LEVEL_SUCCESS"
);
export const updateNutrientsLevel = createAction(
  "@@admin/UPDATE_NUTRIENTS_LEVEL"
);
export const resetNutrientsLevel = createAction(
  "@@admin/RESET_NUTRIENTS_LEVEL"
);

export const loadSelectedNutrients = createAction(
  "@@admin/LOAD_SELECTED_NUTRIENTS"
);
export const loadSelectedNutrientsSuccess = createAction(
  "@@admin/LOAD_SELECTED_NUTRIENTS_SUCCESS"
);
export const loadAllNutrients = createAction("@@admin/LOAD_ALL_NUTRIENTS");
export const loadAllNutrientsSuccess = createAction(
  "@@admin/LOAD_ALL_NUTRIENTS_SUCCESS"
);
export const updateNutrients = createAction("@@admin/UPDATE_NUTRIENTS");
export const resetNutrients = createAction("@@admin/RESET_NUTRIENTS");

export const loadPriorityItems = createAction("@@admin/LOAD_PRIORITY_ITEMS");
export const loadPriorityItemsSuccess = createAction(
  "@@admin/LOAD_PRIORITY_ITEMS_SUCCESS"
);
export const updatePriorityItems = createAction(
  "@@admin/UPDATE_PRIORITY_ITEMS"
);
export const resetPriorityItems = createAction("@@admin/RESET_PRIORITY_ITEMS");

export const loadVamMappings = createAction("@@admin/LOAD_VAM_MAPPINGS");
export const loadVamMappingsSuccess = createAction(
  "@@admin/LOAD_VAM_MAPPINGS_SUCCESS"
);

export const resetFoodItems = createAction("@@admin/RESET_FOOD_ITEMS");

export const loadFoodItems = createAction("@@admin/LOAD_FOOD_ITEMS");
export const loadFoodItemsStarted = createAction(
  "@@admin/LOAD_FOOD_ITEMS_STARTED"
);
export const loadFoodItemsEnded = createAction("@@admin/LOAD_FOOD_ITEMS_ENDED");
export const loadFoodItemsSuccess = createAction(
  "@@admin/LOAD_FOOD_ITEMS_SUCCESS"
);

export const updateVamMappings = createAction("@@admin/UPDATE_VAM_MAPPINGS");

export const initAdminPriceApprovalManage = createAction(
  "@@admin/INIT_ADMIN_PRICE_APPROVAL_MANAGE"
);

export const showApproveAllButton: ActionCreatorWithPayload<boolean> =
  createAction("@@admin/SHOW_APPROVE_ALL_BUTTON");

export const loadFoodPricesSuccess = createAction(
  "@@admin/LOAD_FOOD_PRICES_SUCCESS"
);
export const approveFoodPriceRequest: ActionCreatorWithPayload<ApproveFoodPricesProps> =
  createAction("@@admin/APPROVE_FOOD_PRICE_REQUEST");
export const rejectFoodPriceRequest: ActionCreatorWithPayload<RejectFoodPricesProps> =
  createAction("@@admin/REJECT_FOOD_PRICE_REQUEST");
export const approveAllFoodPricesRequest: ActionCreatorWithPayload<ApproveAllFoodPricesProps> =
  createAction("@@admin/APPROVE_ALL_FOOD_PRICES_REQUEST");
export const loadFoodPricesRequest: ActionCreatorWithPayload<LoadFoodPricesProps> =
  createAction("@@admin/LOAD_FOOD_PRICES_REQUEST");
export const resetFoodPrices = createAction("@@admin/RESET_FOOD_PRICES");

export const loadAdminFoodItems = createAction("@@admin/LOAD_ADMIN_FOOD_ITEMS");
export const storeAdminFoodItemsParams = createAction(
  "@@admin/STORE_ADMIN_FOOD_ITEMS"
);
export const loadAdminFoodItemsStarted = createAction(
  "@@admin/LOAD_ADMIN_FOOD_ITEMS_STARTED"
);
export const loadAdminFoodItemsEnded = createAction(
  "@@admin/LOAD_ADMIN_FOOD_ITEMS_ENDED"
);
export const loadAdminFoodItemsSuccess = createAction(
  "@@admin/LOAD_ADMIN_FOOD_ITEMS_SUCCESS"
);
export const updateAdminFoodItemCustomTranslations = createAction(
  "@@admin/UPDATE_ADMIN_FOOD_ITEM_CUSTOM_TRANSLATIONS"
);

export const loadAdminRecipes = createAction("@@admin/LOAD_ADMIN_RECIPES");
export const loadAdminRecipesSuccess = createAction(
  "@@admin/LOAD_ADMIN_RECIPES_SUCCESS"
);
export const loadSingleAdminRecipe = createAction(
  "@@admin/LOAD_SINGLE_ADMIN_RECIPE"
);
export const loadSingleAdminRecipeSuccess = createAction(
  "@@admin/LOAD_SINGLE_ADMIN_RECIPE_SUCCESS"
);
export const initAdminRecipeManage = createAction(
  "@@admin/INIT_ADMIN_RECIPE_MANAGE"
);
export const initAdminRecipeManageStarted = createAction(
  "@@admin/INIT_ADMIN_RECIPE_MANAGE_STARTED"
);
export const initAdminRecipeManageEnded = createAction(
  "@@admin/INIT_ADMIN_RECIPE_MANAGE_ENDED"
);
export const updateSingleAdminRecipe = createAction(
  "@@admin/UPDATE_SINGLE_ADMIN_RECIPE"
);
export const createSingleAdminRecipe = createAction(
  "@@admin/CREATE_SINGLE_ADMIN_RECIPE"
);

export const initAdminVamFoodPricesManage = createAction(
  "@@admin/INIT_ADMIN_VAM_FOOD_PRICES_MANAGE"
);
export const initAdminVamFoodPricesManageStarted = createAction(
  "@@admin/INIT_ADMIN_VAM_FOOD_PRICES_MANAGE_STARTED"
);
export const initAdminVamFoodPricesManageEnded = createAction(
  "@@admin/INIT_ADMIN_VAM_FOOD_PRICES_MANAGE_ENDED"
);
