export const defaultColumnNumber = 3;

// Defaul properties used when adding a new food item. This way, we keep the same payload shape wether we are dealing with a newly added food item or a "regular" food basket item.
export const DEFAULT_NEW_ITEM_PROPERTIES = {
  is_staple: false,
  local_additional_cost_per_gram: 0,
  local_price_per_gram: 0,
  local_total_cost_per_gram: 0,
  usd_additional_cost_per_gram: 0,
  usd_price_per_gram: 0,
  usd_total_cost_per_gram: 0,
  user_additional_cost_per_gram: null,
  user_is_staple: false,
  user_total_cost_per_gram: null,
};
