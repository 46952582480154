import styled from "styled-components";
import { colors } from "@wfp/ui";

export const StyledBar = styled.div`
  bottom: 0;
  align-self: flex-end;
  position: relative;
  transition: all 0.2s linear;
  svg {
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: auto;
  }
  &:last-child {
    margin-right: ${({ lastBarMarginRight }) => lastBarMarginRight}px;
  }
`;

export const TargetLine = styled.div`
  right: -7px;
  background: ${colors["text-01"].hex};
  height: 2px;
  position: absolute;
`;

export const Label = styled.span`
  position: absolute;
  transition: all 0.2s linear;
  color: ${colors["text-02"].hex};
  transform: rotate(-90deg);
  transform-origin: bottom left;
  bottom: 0%;
  left: 100%;
  font-weight: 600;
  overflow: hidden;

  > span {
    padding: 0 10px;
  }
`;

export const BarPercentageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -35px;
  text-align: center;
  color: ${colors["text-02"].hex};
  z-index: 1;
`;
