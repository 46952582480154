import React, { useState, useMemo } from "react";
import * as R from "ramda";
import Legend from "./Legend";
import { ContainerGraph } from "./styles";
import Chart from "./Chart";

export default function SourcesGraph({ id, menus, percentageInLegend, noEllipsis, inPdf, showLabelOnGraph = true }) {
  const [onHover, setOnHover] = useState();

  const labels = useMemo(() => {
    if (!menus) return;
    const cips = R.uniqBy((el) => el.source, R.flatten(menus));
    return R.splitEvery(2, R.sort(R.descend(R.prop("source")), cips));
  }, [menus]);

  const sourceArray = useMemo(() => (Array.isArray(menus[0]) ? menus : [menus]), [menus]);

  return (
    <div id={id}>
      {!percentageInLegend && (
        <ContainerGraph>
          {sourceArray.map((source, graphIndex) => (
            <Chart
              key={`graph-${graphIndex}`}
              source={source}
              setOnHover={setOnHover}
              onHover={onHover}
              isLast={graphIndex + 1 === sourceArray.length}
              showLabelOnGraph={showLabelOnGraph}
            />
          ))}
        </ContainerGraph>
      )}
      <Legend inPdf={inPdf} percentageInLegend={percentageInLegend} labels={labels} noEllipsis={noEllipsis} />
    </div>
  );
}
