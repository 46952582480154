import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { iconEdit } from "@wfp/icons";
import { colors, Icon } from "@wfp/ui";
import messages from "components/Button/messages";
import { Text } from "components";
import { useHtmlHook } from "hooks";
import { ContainerIcon } from "./styles";
import { ACTIONS } from "../constants";

function Rename({ setMenuAndAction, menu }) {
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const clickOnRename = useCallback(() => {
    setMenuAndAction({ id: menu.id, action: ACTIONS.RENAME, name: menu.name });
  }, [menu.id, menu.name, setMenuAndAction]);

  return (
    <ContainerIcon onClick={clickOnRename} hover={colors["brand-01"].hex} isRTL={isRTL}>
      <Icon icon={iconEdit} kind="inverse" fill={colors["text-01"].hex} width="18px" height="18px" />
      <Text bold fontSize="13px" marginTop="5px" marginBottom="5px" value={intl.formatMessage(messages.rename)} />
    </ContainerIcon>
  );
}

export default Rename;
