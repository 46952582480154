import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { InputGroup } from "@wfp/ui";
import { useFormContext } from "react-hook-form";

import { RadioButtonHookForm } from "components/Form";
import { RowStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import { FIELDS, FOOD_RESTRICTION_PATH, GROUP_RESTRICTION_PATH } from "./constants";
import { FOOD_RESTRICTION_TYPES } from "../../constants";

// When dealing with "include" and "exclude", I set the corresponding property as true/false/null before sending data to BE.
const IncludeExclude = ({ exclutionChoice, restrictionAttributeName }) => {
  const { control } = useFormContext();
  const isItemRestriction = restrictionAttributeName === "item_id";
  const fieldPath = useMemo(
    () =>
     isItemRestriction 
        ? `${FOOD_RESTRICTION_PATH}.${FIELDS.RESTRICTION_TYPE}`
        : `${GROUP_RESTRICTION_PATH}.${FIELDS.RESTRICTION_TYPE}`,
    [isItemRestriction]
  );
  const includeKeyAndId = useMemo(
    () => (isItemRestriction ? "item_restriction_include" : "group_restriction_include"),
    [isItemRestriction]
  );
  const excludeKeyAndId = useMemo(
    () => (isItemRestriction ? "item_restriction_exclude" : "group_restriction_exclude"),
    [isItemRestriction]
  );
  const noRuleKeyAndId = useMemo(
    () => (isItemRestriction ? "item_restriction_no_rule" : "group_restriction_no_rule"),
    [isItemRestriction]
  );
  
  return (
    <RowStyled margin="0 0 0 50px">
      <InputGroup>
        <RadioButtonHookForm
          key={includeKeyAndId}
          id={includeKeyAndId}
          control={control}
          name={fieldPath}
          value={FOOD_RESTRICTION_TYPES.INCLUDE}
          checked={exclutionChoice === "include" ? true : false}
          labelText={<FormattedMessage {...messages.include} />}
        />

        <RadioButtonHookForm
          key={excludeKeyAndId}
          id={excludeKeyAndId}
          control={control}
          name={fieldPath}
          value={FOOD_RESTRICTION_TYPES.EXCLUDE}
          checked={exclutionChoice === "exclude" ? true : false}
          labelText={<FormattedMessage {...messages.exclude} />}
        />

        <RadioButtonHookForm
          key={noRuleKeyAndId}
          id={noRuleKeyAndId}
          control={control}
          name={fieldPath}
          value={FOOD_RESTRICTION_TYPES.DEFAULT}
          checked={exclutionChoice === "no-rule" ? true : false}
          labelText={<FormattedMessage {...messages.noRule} />}
        />
      </InputGroup>
    </RowStyled>
  );
};

export default IncludeExclude;
