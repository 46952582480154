import { Fragment, useEffect, useMemo } from "react";
import axios from "axios";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { Loading, colors } from "@wfp/ui";
import * as Sentry from "@sentry/react";

import Tutorials from "containers/Tutorials";
import { selectTutorial } from "containers/Tutorials/selectors";
import { useCookieConsent, useAnalytics } from "hooks";
import messages from "locales";
import { updateHtmlElement } from "utils/utils";
import { FEATURES } from "utils/constants";
import { loadEssentialInfo, loadGeneralAppInfo } from "./actions";
import Alert from "./Alert";
import { useError } from "./Alert/utils";
import CookieConsentBanner from "./CookieConsent";
import WarningOrientation from "./WarningOrientation";
import ErrorBoundary from "./ErrorBoundary";
import LandingPage from "../LandingPage";
import Header from "./Header";
import Footer from "./Footer";
import Routes from "./Routes";
import GeneralTutorial from "containers/Tutorials/GeneralTutorial";
import { selectLoading, selectLanguage, selectGlobalFeatures, selectLoadingGeneralAppInfo } from "./selectors";
import routesPath from "./Routes/paths";
import "./App.scss";
import "rc-slider/assets/index.css";
import "react-dates/initialize";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./react_complex_tree_overrides.scss";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(selectLanguage);
  const token = localStorage.getItem("token");
  const loading = useSelector(selectLoading);
  const tutorial = useSelector(selectTutorial);
  const loadingGeneralAppInfo = useSelector(selectLoadingGeneralAppInfo);
  const globalFeatures = useSelector(selectGlobalFeatures);
  const enabledLandingPageFeature = useMemo(() => globalFeatures?.includes(FEATURES.LANDING_PAGE), [globalFeatures]);
  const hasToken = localStorage.getItem("token");
  axios.defaults.headers.common["Accept-Language"] = language;
  const error = useError();

  useEffect(() => {
    dispatch(loadGeneralAppInfo());
  }, [dispatch]);

  useEffect(() => {
    if (token) dispatch(loadEssentialInfo());
  }, [dispatch, token]);

  useEffect(() => {
    updateHtmlElement(language);
  }, [language]);

  const { cookieConsent, acceptCookieConsent, declineCookieConsent } = useCookieConsent();
  useAnalytics({ cookieConsent });

  return (
    <IntlProvider
      key={language}
      locale={language}
      defaultLocale="en"
      messages={messages[language]}
      textComponent={Fragment}
    >
      <Sentry.ErrorBoundary fallback={ErrorBoundary({ history })}>
        <Loading active={loading || loadingGeneralAppInfo} />
        <Switch>
          <Route
            path={routesPath.home}
            exact
            render={() =>
              enabledLandingPageFeature && !hasToken ? (
                <LandingPage />
              ) : !hasToken && loadingGeneralAppInfo ? (
                <div style={{ backgroundColor: colors["ui-02"].hex, flexGrow: 1 }} />
              ) : (
                <Redirect to={routesPath.menus} />
              )
            }
          />
          <Route
            render={() => (
              <>
                <Header />
                <Routes />
                <Footer />
                <GeneralTutorial />
                {tutorial && <Tutorials tutorial={tutorial} />}
              </>
            )}
          />
        </Switch>
        <Alert {...error} />
        <WarningOrientation />
        <CookieConsentBanner onAccept={acceptCookieConsent} onDecline={declineCookieConsent} />
      </Sentry.ErrorBoundary>
    </IntlProvider>
  );
};

export default App;
