import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectSelectedSources, selectAllSources, selectMenuType } from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";

export const useInitialValues = () => {
  const selectedSources = useSelector(selectSelectedSources);
  const allSources = useSelector(selectAllSources);
  const countries = useSelector(selectCountries);
  const menuType = useSelector(selectMenuType);

  const isMissingData = useMemo(() => {
    return !selectedSources || !allSources || !countries || !menuType;
  }, [selectedSources, allSources, countries, menuType]);

  return useMemo(() => {
    if (isMissingData) return {};

    const reworkedSelectedSources = selectedSources
      .filter((source) => source.type === menuType)
      .map((source) => source.source);

    const sources = allSources.map((source) => ({
      ...source,
      checked: reworkedSelectedSources.includes(source.value),
    }));

    return { sources };
  }, [isMissingData, selectedSources, allSources, menuType]);
};
