import React from "react";
import { Text } from "components";
import { RowStyled } from "components/utils";
import { Icon } from "@wfp/ui";
import { iconDocument } from "@wfp/icons";
import { ContainerDocument } from "./styles";
import { downloadDoc } from "./utils";

const Documents = ({ docs }) => {
  return (docs || []).map((doc) => (
    <ContainerDocument key={doc.id}>
      <RowStyled cursor="pointer" onClick={() => downloadDoc(doc.file)}>
        <Icon icon={iconDocument} width="24px" height="24px" />
        <Text bold fontSize="16px" marginLeft="10px" value={doc.title} />
      </RowStyled>
    </ContainerDocument>
  ));
};

export default Documents;
