import React from "react";
import {
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { colors } from "@wfp/ui";
import { iconChevronDown } from "@wfp/icons";
import { AccordionItemButton, Accordion, IconStyled } from "./styles";

const AccordionField = React.forwardRef(
  (
    { allowMultipleExpanded, fields, withNoArrow, preExpanded, firstExpanded, fontSize, className, onChange, padding },
    ref,
  ) => (
    <Accordion
      padding={padding}
      className={className}
      allowMultipleExpanded={allowMultipleExpanded}
      key={String(preExpanded || firstExpanded)}
      allowZeroExpanded
      onChange={onChange}
      preExpanded={preExpanded || (firstExpanded ? ["accordion-0"] : [])}
    >
      {(fields || []).map((el, index) => (
        <div ref={ref} key={`accordion-${el.uuid || ""}-${index}`}>
          <AccordionItem uuid={`accordion-${(el.uuid && el.uuid.replace(/\s/g, "")) || index}`}>
            <AccordionItemHeading>
              <AccordionItemButton fontSize={fontSize} padding={padding}>
                {el.title}
                {!withNoArrow && (
                  <AccordionItemState>
                    {({ expanded }) => (
                      <IconStyled
                        description={expanded ? "shrink" : "expand"}
                        expanded={String(expanded)}
                        icon={iconChevronDown}
                        fill={colors["brand-01"].hex}
                        width="15"
                        height="15"
                      />
                    )}
                  </AccordionItemState>
                )}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{el.content}</AccordionItemPanel>
          </AccordionItem>
        </div>
      ))}
    </Accordion>
  ),
);

export default AccordionField;
