import * as R from "ramda";
import { FIELDS } from "./constants";
import { greaterThanAvailableGroups } from "utils/errors";
import { FIELDS as RULES_FIELDS } from "containers/OptimizedMenu/Rules/constants";

const setAlertMessage = (field, error, errors) => R.assocPath([field], error, errors);

export default function validation(values, foodGroupsCount) {
  let errors = undefined;
  if (values[RULES_FIELDS.MENU_GENERAL_PARAMETER]) {
    const minMeal = values[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MIN_NUMBER_MEAL];
    const minMenu = values[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MIN_NUMBER_MENU];
    if (minMeal && minMeal > foodGroupsCount) {
      errors = setAlertMessage(FIELDS.MIN_NUMBER_MEAL, greaterThanAvailableGroups(foodGroupsCount), errors);
    }
    if (minMenu && minMenu > foodGroupsCount) {
      errors = setAlertMessage(FIELDS.MIN_NUMBER_MENU, greaterThanAvailableGroups(foodGroupsCount), errors);
    }
  }
  return errors;
}
