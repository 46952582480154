import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadSuccess,
  loadSingleUserSuccess,
  loadUserGroupsSuccess,
  updateTableParams,
  resetTableParams,
  updateTableFilters,
  resetTableFilters,
  resetUsers,
  loadCountriesSliceStarted,
  loadCountriesSliceEnded,
  setMenuType,
  loadSelectedSourcesSuccess,
  loadAllSourcesSuccess,
  resetSources,
  updateFormState,
  loadSelectedCommunityNutrientsLevelSuccess,
  loadAllCommunityNutrientsLevelSuccess,
  resetNutrientsLevel,
  loadSelectedNutrientsSuccess,
  loadAllNutrientsSuccess,
  resetNutrients,
  loadPriorityItemsSuccess,
  resetPriorityItems,
  loadVamMappingsSuccess,
  loadFoodItemsSuccess,
  loadFoodPricesSuccess,
  resetFoodItems,
  showApproveAllButton,
  resetFoodPrices,
  loadAdminFoodItemsSuccess,
  loadAdminFoodItemsStarted,
  loadAdminFoodItemsEnded,
  storeAdminFoodItemsParams,
  loadAdminRecipesSuccess,
  initAdminRecipeManageStarted,
  initAdminRecipeManageEnded,
  initAdminVamFoodPricesManageStarted,
  initAdminVamFoodPricesManageEnded,
  loadSingleAdminRecipeSuccess,
} from "./actions";
import { PRICE_STATUS } from "./Countries/PriceApproval/Filters/constants";

type UserProfile = {
  id: number,
  country: string,
  language: string
  plusadmin_superuser: boolean,
  managed_country: string,
};

type PlusAdminRole = {
  id: number,
  name: string,
  label: string,
};

type User = {
  id: number,
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  is_active: boolean,
  is_staff: boolean,
  profile: UserProfile,
  plusadmin_roles: PlusAdminRole[],
  enabled_features: unknown[],
}

type SelectedSource = {
  id: number,
}

type Ingredient = {
  id: number,
  quantity: string,
  food_item: {
    id: number,
    display_name: string,
  },
}

type AdminRecipe = {
  id: number,
  name: string,
  description: string,
  author: {
    first_name: string,
    last_name: string,
  },
  ingredients: Ingredient[],
}

export const initialState = {
  users: {
    loading: false,
    results: [] as User[],
    count: 0,
    groups: [],
  },
  countries: {
    loading: false,
    adminVamFoodPricesLoading: false,
    adminRecipeLoading: false,
    menuType: null,
    selectedNutrients: null,
    allNutrients: null,
    selectedSources: null as null | SelectedSource[],
    allSources: null,
    selectedNutrientsLevel: null,
    allNutrientsLevel: null,
    priorityItems: null,
    vamMappings: null,
    vamFoodItems: [],
    vamFoodItemOptions: [],
    foodPrices: {
      results: [],
      count: 0,
    },
    foodItems: [],
    foodItemOptions: [],
    count: 0,
    adminFoodItems: null,
    adminFoodItemsParams: null,
    adminRecipes: {
      data: {
        results: [] as AdminRecipe[],
        count: 0,
      },
    },
    approveAllButtonVisibility: false,
    loadingAdminFoodItems: false,
  },
  filters: {
    price_status: PRICE_STATUS.PROPOSED,
  },
  params: {
    page: 1,
    page_size: 10,
  },
  form: {},
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.users.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.users.loading = false;
  },
  [loadSuccess.type]: (state, action) => {
    state.users.results = action.payload.results;
    state.users.count = action.payload.count;
  },
  [loadSingleUserSuccess.type]: (state, action) => {
    state.users.results = [...state.users.results, action.payload.user];
  },
  [loadUserGroupsSuccess.type]: (state, action) => {
    state.users.groups = action.payload.userGroups;
  },
  [updateTableParams.type]: (state, action) => {
    state.params = { ...state.params, ...action.payload.params };
  },
  [resetTableParams.type]: (state) => {
    state.params = initialState.params;
  },
  [updateTableFilters.type]: (state, action) => {
    state.filters = { ...state.filters, ...action.payload.filters };
  },
  [resetTableFilters.type]: (state) => {
    state.filters = initialState.filters;
  },
  [resetUsers.type]: (state) => {
    state.users = initialState.users;
  },

  [loadCountriesSliceStarted.type]: (state) => {
    state.countries.loading = true;
  },
  [loadCountriesSliceEnded.type]: (state) => {
    state.countries.loading = false;
  },
  [setMenuType.type]: (state, action) => {
    state.countries.menuType = action.payload.menuType;
  },
  [loadSelectedSourcesSuccess.type]: (state, action) => {
    state.countries.selectedSources = action.payload.sources;
  },
  [loadAllSourcesSuccess.type]: (state, action) => {
    state.countries.allSources = action.payload.sources;
  },
  [resetSources.type]: (state) => {
    state.countries.selectedSources = initialState.countries.selectedSources;
    state.countries.allSources = initialState.countries.allSources;
  },

  [loadSelectedCommunityNutrientsLevelSuccess.type]: (state, action) => {
    state.countries.selectedNutrientsLevel = action.payload.selectedNutrients;
  },
  [loadAllCommunityNutrientsLevelSuccess.type]: (state, action) => {
    state.countries.allNutrientsLevel = action.payload.allNutrients;
  },
  [resetNutrientsLevel.type]: (state) => {
    state.countries.selectedNutrientsLevel =
      initialState.countries.selectedNutrientsLevel;
    state.countries.allNutrientsLevel = initialState.countries.allNutrientsLevel;
  },

  [loadSelectedNutrientsSuccess.type]: (state, action) => {
    state.countries.selectedNutrients = action.payload.selectedNutrients;
  },
  [loadAllNutrientsSuccess.type]: (state, action) => {
    state.countries.allNutrients = action.payload.allNutrients;
  },
  [resetNutrients.type]: (state) => {
    state.countries.selectedNutrients = initialState.countries.selectedNutrients;
    state.countries.allNutrients = initialState.countries.allNutrients;
  },

  [loadPriorityItemsSuccess.type]: (state, action) => {
    state.countries.priorityItems = action.payload.priorityItems;
  },
  [resetPriorityItems.type]: (state) => {
    state.countries.priorityItems = initialState.countries.priorityItems;
  },

  [loadVamMappingsSuccess.type]: (state, action) => {
    state.countries.vamMappings = action.payload.results;
    state.countries.count = action.payload.count;
    state.countries.vamFoodItems = action.payload.vamFoodItems;
    state.countries.vamFoodItemOptions = action.payload.vamFoodItems
      .filter((f: any) => f)
      .map((f: { id: number, display_name: string}) => ({ value: f.id, label: f.display_name }));
  },

  [loadFoodItemsSuccess.type]: (state, action) => {
    state.countries.foodItems = action.payload.foodItems;
    state.countries.foodItemOptions = action.payload.foodItems.map((f: { id: number, display_name: string }) => ({
      value: f.id,
      label: f.display_name,
    }));
  },

  [resetFoodItems.type]: (state) => {
    state.countries.foodItems = initialState.countries.foodItems;
    state.countries.foodItemOptions = initialState.countries.foodItemOptions;
  },

  [loadFoodPricesSuccess.type]: (state, action) => {
    state.countries.foodPrices = {
      ...state.countries.foodPrices,
      results: action.payload.results,
      count: action.payload.count,
    };
  },
  [resetFoodPrices.type]: (state) => {
    state.countries.foodPrices = initialState.countries.foodPrices;
  },

  [showApproveAllButton.type]: (state, action) => {
    state.countries.approveAllButtonVisibility = action.payload;
  },

  [updateFormState.type]: (state, action) => {
    state.form = action.payload.formState;
  },

  [loadAdminFoodItemsSuccess.type]: (state, action) => {
    state.countries.adminFoodItems = action.payload.foodItems;
  },

  [loadAdminFoodItemsStarted.type]: (state) => {
    state.countries.loadingAdminFoodItems = true;
  },
  [loadAdminFoodItemsEnded.type]: (state) => {
    state.countries.loadingAdminFoodItems = false;
  },
  [storeAdminFoodItemsParams.type]: (state, action) => {
    state.countries.adminFoodItemsParams = action.payload.params;
  },

  [loadAdminRecipesSuccess.type]: (state, action) => {
    state.countries.adminRecipes.data = action.payload.adminRecipes;
  },
  [loadSingleAdminRecipeSuccess.type]: (state, action) => {
    state.countries.adminRecipes.data.results = [
      ...state.countries.adminRecipes.data.results,
      action.payload.recipe,
    ];
  },
  [initAdminRecipeManageStarted.type]: (state) => {
    state.countries.adminRecipeLoading = true;
  },
  [initAdminRecipeManageEnded.type]: (state) => {
    state.countries.adminRecipeLoading = false;
  },
  [initAdminVamFoodPricesManageStarted.type]: (state) => {
    state.countries.adminVamFoodPricesLoading = true;
  },
  [initAdminVamFoodPricesManageEnded.type]: (state) => {
    state.countries.adminVamFoodPricesLoading = false;
  },
});
