import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Field, useForm, useField } from "react-final-form";
import { Icon } from "@wfp/ui";
import { colors } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { Select, FieldNumber } from "components/InputFields";
import { ReactSelectWithDataTestId } from "components/utils";
import { selectAdditionalCostChoices } from "containers/App/selectors";
import buttonMessages from "components/Button/messages";
import { ADDITIONAL_FIELDS } from "./constants";
import { RowStyled, ColStyled } from "./styles";
import foodBasketMessages from "containers/FoodBasket/messages";
import { useHtmlHook } from "hooks";

const FONT_SIZE_LABEL = "16px";

function FieldsAdditionalCosts({ name, index, currencies, values, foodItemName }) {
  const form = useForm();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();
  const types = useSelector(selectAdditionalCostChoices);

  const onRemove = useCallback(() => {
    form.change(
      `foods[${foodItemName}].additional_costs`,
      values.filter((_, i) => i !== index),
    );
  }, [form, values, index, foodItemName]);

  const { input: fieldPrice } = useField(`${name}.${ADDITIONAL_FIELDS.PRICE}`);
  const { input: fieldPriceCurrency } = useField(`${name}.${ADDITIONAL_FIELDS.PRICE_CURRENCY}`);
  const { input: fieldPercentage } = useField(`${name}.${ADDITIONAL_FIELDS.PERCENTAGE}`);

  const onChangeUnit = useCallback(
    (unit) => {
      if (unit.label === "%") {
        fieldPercentage.onChange(values[index].value);
        fieldPrice.onChange(null);
        fieldPriceCurrency.onChange(null);
      } else {
        fieldPrice.onChange(values[index].value);
        fieldPriceCurrency.onChange(unit.label);
        fieldPercentage.onChange(null);
      }
    },
    [fieldPercentage, fieldPrice, fieldPriceCurrency, index, values],
  );

  const onChangeValue = useCallback(
    (value) => {
      fieldPrice.onChange(value);
    },
    [fieldPrice],
  );

  return (
    <RowStyled withMarginTop middle="xs" margin="0">
      <ColStyled flex={1.22} isRTL={isRTL}>
        <Field
          name={`${name}.${ADDITIONAL_FIELDS.TYPE}`}
          id={`${name}.${ADDITIONAL_FIELDS.TYPE}`}
          fontColorLabel={colors["text-02"].hex}
          options={types}
          labelText={intl.formatMessage(foodBasketMessages.type)}
          placeholder={intl.formatMessage(foodBasketMessages.selectType)}
          data-test-id={`new-additional-type-${index}`}
          fontSizeLabel={FONT_SIZE_LABEL}
          components={ReactSelectWithDataTestId}
          component={Select}
        />
      </ColStyled>
      <ColStyled flex={1.26} isRTL={isRTL}>
        <Field
          id={`${name}.${ADDITIONAL_FIELDS.VALUE}`}
          name={`${name}.${ADDITIONAL_FIELDS.VALUE}`}
          labelText={intl.formatMessage(foodBasketMessages.price)}
          fontColorLabel={colors["text-02"].hex}
          data-test-id={`new-additional-value-${index}`}
          format={(value) => (value ? Number(value) : "")}
          fontSizeLabel={FONT_SIZE_LABEL}
          addActionOnChange={onChangeValue}
          component={FieldNumber}
        />
      </ColStyled>
      <ColStyled flex={1.1} isRTL={isRTL}>
        <Field
          id={`${name}.${ADDITIONAL_FIELDS.UNIT}`}
          name={`${name}.${ADDITIONAL_FIELDS.UNIT}`}
          options={[{ value: "%", label: "%" }, ...currencies]}
          labelText={intl.formatMessage(foodBasketMessages.unit)}
          placeholder={intl.formatMessage(foodBasketMessages.selectUnit)}
          fontColorLabel={colors["text-02"].hex}
          data-test-id={`new-additional-unit-${index}`}
          fontSizeLabel={FONT_SIZE_LABEL}
          components={ReactSelectWithDataTestId}
          onChangeCustom={onChangeUnit}
          component={Select}
        />
      </ColStyled>
      <ColStyled flex={0.1} noMarginRight className="end-xs" isRTL={isRTL}>
        <Icon
          className="pointer paddingTopSmall"
          description={intl.formatMessage(buttonMessages.remove)}
          icon={iconDelete}
          width="23"
          height="23"
          fill={colors["brand-01"].hex}
          onClick={onRemove}
        />
      </ColStyled>
    </RowStyled>
  );
}

export default FieldsAdditionalCosts;
