import * as R from "ramda";
import { FIELDS } from "./constants";
import { greaterThanAvailableItems, minGreaterMax } from "utils/errors";
import { FIELDS as RULES_FIELDS } from "containers/OptimizedMenu/Rules/constants";

const setAlertMessage = (field, error, errors) => R.assocPath([field], error, errors);

export default function validation(values, foodItemsCount) {
  let errors = undefined;
  if (values[RULES_FIELDS.MENU_GENERAL_PARAMETER]) {
    const minMeal = values[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MIN_NUMBER_MEAL];
    const maxMeal = values[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MAX_NUMBER_MEAL];
    const minMenu = values[RULES_FIELDS.MENU_GENERAL_PARAMETER][FIELDS.MIN_NUMBER_MENU];
    if (minMeal && maxMeal && minMeal > maxMeal) {
      errors = setAlertMessage(FIELDS.MIN_NUMBER_MEAL, minGreaterMax, errors);
    }
    if (minMeal && minMeal > foodItemsCount) {
      errors = setAlertMessage(FIELDS.MIN_NUMBER_MEAL, greaterThanAvailableItems(foodItemsCount), errors);
    }
    if (maxMeal && maxMeal > foodItemsCount) {
      errors = setAlertMessage(FIELDS.MAX_NUMBER_MEAL, greaterThanAvailableItems(foodItemsCount), errors);
    }
    if (minMenu && minMenu > foodItemsCount) {
      errors = setAlertMessage(FIELDS.MIN_NUMBER_MENU, greaterThanAvailableItems(foodItemsCount), errors);
    }
  }
  return errors;
}
