import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import useSize from "@react-hook/size";
import { useFormContext, useWatch } from "react-hook-form";

import { RowStyled, ColStyled } from "components/utils";
import { selectMenuId } from "containers/Menu/selectors";
import { loadDiversityRequest } from "./actions";
import FirstLevel from "./FirstLevel";
import FoodRestriction from "./FoodRestriction";
import { FIELDS } from "./FoodRestriction/constants";
import SecondLevel from "./SecondLevel";
import {
  selectGroupsAndItems,
  selectRestriction,
  selectRestrictionTarget,
  selectCompositions,
} from "./selectors";
import { FirstLevelWrapper } from "./styles";
import Summaries from "./Summaries";
import AdvancedSettings from "./AdvancedSettings";
import RulesSummary from "./RulesSummary";

const FoodRules = () => {
  const dispatch = useDispatch();

  const { control, setValue } = useFormContext();

  const formRestriction = useWatch({ control, name: FIELDS.RESTRICTION });

  const $firstLevel = useRef();
  const [, firstLevelHeight] = useSize($firstLevel);

  const [selectedIndex, setSelectedIndex] = useState();

  const menuId = useSelector(selectMenuId);
  const groupsAndItems = useSelector(selectGroupsAndItems);
  const restriction = useSelector(selectRestriction);
  const name = useSelector(selectRestrictionTarget);
  const compositions = useSelector(selectCompositions);

  useEffect(() => {
    if (menuId) dispatch(loadDiversityRequest({ id: menuId }));
  }, [dispatch, menuId]);

  useEffect(() => {
    return () => {
      setValue(FIELDS.RESTRICTION, null);
    };
  }, [setValue]);

  const majorGroups = useMemo(
    () => R.pathOr([], ["major_groups"], groupsAndItems),
    [groupsAndItems]
  );

  // "item_id" | "major_group_id" | "minor_group_id"
  let restrictionAttributeName;
  if (!restriction) {
    // There is no selected restriction.
    // We get this on main menu.
    restrictionAttributeName = "";
  } else if (restriction.item_id) {
    restrictionAttributeName = "item_id";
  } else if (restriction.major_group_id) {
    restrictionAttributeName = "major_group_id";
  } else if (restriction.minor_group_id) {
    restrictionAttributeName = "minor_group_id";
  }

  // True if this is an item restriction false if this is a group restriction
  // const isItemRestriction = Boolean(restriction.item_id);

  // True if this is an item restriction false if this is a group restriction
  // const isItemRestriction = kindRestriction.includes("item");

  const onGroupClick = useCallback((index) => setSelectedIndex(index), []);

  const resetSelectedIndex = useCallback(() => setSelectedIndex(null), []);

  // Remove padding-top and padding-bottom given to RowStyled from the height.
  const _firstLevelHeight = useMemo(
    () => firstLevelHeight - 20,
    [firstLevelHeight]
  );

  return (
    <div ref={$firstLevel}>
      <RowStyled padding="10px 0">
        {R.isNil(formRestriction) ? (
          <>
            <ColStyled xs={6} padding="0 0 0 8px">
              <FirstLevelWrapper>
                {majorGroups.map((el, index) => (
                  <FirstLevel
                    key={el.major_id}
                    el={el}
                    selectedIndex={selectedIndex}
                    index={index}
                    onClick={() => onGroupClick(index)}
                  />
                ))}
              </FirstLevelWrapper>
            </ColStyled>
            <ColStyled xs={6} padding="0 8px 0 0">
              {!R.isNil(selectedIndex) ? (
                <SecondLevel
                  menuId={menuId}
                  majorGroup={majorGroups[selectedIndex]}
                  list={majorGroups[selectedIndex].minor_groups}
                  firstLevelHeight={_firstLevelHeight}
                />
              ) : null}
            </ColStyled>
          </>
        ) : (
          <FoodRestriction
            menuId={menuId}
            restrictionAttributeName={restrictionAttributeName}
            name={name}
            resetSelectedIndex={resetSelectedIndex}
          />
        )}
      </RowStyled>

      <AdvancedSettings />
      {/* Summaries */}
      <Summaries compositions={compositions} menuId={menuId} />
      {compositions && !R.isEmpty(compositions.menu) && (
        <RulesSummary menu={compositions.menu} />
      )}
    </div>
  );
};

export default FoodRules;
