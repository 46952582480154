import { createAction } from "@reduxjs/toolkit";

export const loadStarted = createAction("@@optimized/LOAD_STARTED");
export const loadEnded = createAction("@@optimized/LOAD_ENDED");

export const loadOptimizedInfoRequest = createAction("@@optimized/LOAD_OPTIMIZED_INFO_REQUEST");

export const loadPlusMenuSuccess = createAction("@@optimized/LOAD_PLUS_MENU_SUCCESS");

export const loadAvailableSourcesSuccess = createAction("@@optimized/LOAD_AVAILABLE_SOURCES_SUCCESS");
export const loadFoodBasketSourcesSuccess = createAction("@@optimized/LOAD_FOOD_BASKET_SOURCES_SUCCESS");

export const loadNutrientsStarted = createAction("@@optimized/LOAD_NUTRIENTS_STARTED");
export const loadNutrientsEnded = createAction("@@optimized/LOAD_NUTRIENTS_ENDED");

export const setInitValues = createAction("@@optimized/SET_INIT_VALUES");

export const saveNutritionalAdvancedRequest = createAction("@@optimized/SAVE_NUTRITIONAL_ADVANCED_REQUEST");

export const savePartialAndOptimizeRequest = createAction("@@optimized/SAVE_PARTIAL_AND_OPTIMIZE_REQUEST");

export const optimizeMenuWithoutSourcingRequest = createAction("@@optimized/OPTIMIZED_MENU_WITHOUT_SOURCING_REQUEST");

export const resetOptimizedMenu = createAction("@@optimized/RESET_OPTIMIZED_MENU");
