import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { colors, Icon } from "@wfp/ui";
import { iconHeaderUser, iconWfpHumFoodSecurityClusterPos } from "@wfp/icons";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { RowStyled } from "components/utils";
import { selectRateFromUSD, selectLocalCurrency, selectWeightChoices } from "containers/App/selectors";
import { FIELDS } from "containers/FoodBasket/constants";
import { FoodItemContainer, FoodItemLabel } from "./styles";

const FoodItem = ({ item, onClick }) => {
  const rateFromUSD = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);
  const units = useSelector(selectWeightChoices);

  const { control } = useFormContext();
  const formCurrency = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.CURRENCY}` });
  const formUnit = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.UNIT}` });

  const renderPrices = useCallback(
    (prices) => {
      const pricesArray = Object.values(prices)
        .map((source) => source.user_price_per_gram ?? source.usd_price_per_gram)
        .sort((a, b) => a - b);

      const selectedConversionRate = units.find((unit) => unit.value === formUnit).conversion_rate;

      if (pricesArray.length === 1) {
        const [price] = pricesArray;

        if (formCurrency === "USD") {
          const pricePerQuantity = (price * selectedConversionRate).toFixed(2);
          return `$ ${pricePerQuantity}`;
        } else {
          const pricePerQuantity = (price * selectedConversionRate * rateFromUSD).toFixed(2);
          return `${localCurrency} ${pricePerQuantity}`;
        }
      } else {
        const lowerPrice = R.head(pricesArray);
        const higherPrice = R.last(pricesArray);

        if (formCurrency === "USD") {
          const lowerPricePerQuantity = (lowerPrice * selectedConversionRate).toFixed(2);
          const higherPricePerQuantity = (higherPrice * selectedConversionRate).toFixed(2);
          return `$ ${lowerPricePerQuantity} - ${higherPricePerQuantity}`;
        } else {
          const lowerPricePerQuantity = (lowerPrice * selectedConversionRate * rateFromUSD).toFixed(2);
          const higherPricePerQuantity = (higherPrice * selectedConversionRate * rateFromUSD).toFixed(2);
          return `${localCurrency} ${lowerPricePerQuantity} - ${higherPricePerQuantity}`;
        }
      }
    },
    [formCurrency, formUnit, localCurrency, rateFromUSD, units]
  );

  const isPersonalFoodItem = useMemo(() => item.has_personal_prices, [item.has_personal_prices]);
  const isUserProvidedFoodItem = useMemo(() => item.user_provided_item, [item.user_provided_item]);

  const hasCustomPriceSources = useMemo(
    () => Object.values(item.prices).some((price) => price.user_price_per_gram),
    [item.prices]
  );

  const itemColor = isPersonalFoodItem
    ? colors["support-03"].hex
    : isUserProvidedFoodItem
    ? colors["categorical-corporate-08"].hex
    : colors["interactive-01"].hex;

  return (
    <FoodItemContainer between="xs" middle="xs">
      <RowStyled flex={1} middle="xs" onClick={() => onClick(item)}>
        <FoodItemLabel color={itemColor}>{item.display_name}</FoodItemLabel>
      </RowStyled>
      {isPersonalFoodItem && <Icon icon={iconHeaderUser} fill={colors["support-03"].hex} />}
      {(isUserProvidedFoodItem || hasCustomPriceSources) && (
        <Icon
          icon={iconWfpHumFoodSecurityClusterPos}
          height="20"
          width="20"
          fill={colors["categorical-corporate-08"].hex}
        />
      )}
      <Text
        marginLeft="5px"
        defaultColor={
          isPersonalFoodItem || isUserProvidedFoodItem
            ? itemColor
            : hasCustomPriceSources
            ? colors["categorical-corporate-08"].hex
            : colors["text-01"].hex
        }
        value={renderPrices(item.prices)}
      />
    </FoodItemContainer>
  );
};

export default FoodItem;
