import * as R from "ramda";
import { compose } from "redux";
import { useIntl } from "react-intl";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading, colors } from "@wfp/ui";
import { FormProvider } from "react-hook-form";

import { Layout, SecondHeader } from "components";
import CreateNewMenu from "containers/CreateNewMenu";
import { resetMenu } from "containers/Menu/actions";
import { withAuth, withCountryKey } from "hocs";
import Modals from "containers/OptionsAndModals/Modals";
import { setAlert } from "containers/App/actions";
import { selectPermissions } from "containers/App/selectors";
import MenusList from "./MenusList";
import ComparisonPreview from "./ComparisonPreview";
import { searchMyMenus, loadFiltersRequest, resetComparisonList, resetReducer } from "./actions";
import messages from "./messages";
import { selectMenus, selectCount, selectLoading, selectComparisonList } from "./selectors";
import SearchAndFilter from "./SearchAndFilter";
import { PERMISSIONS } from "containers/Admin/constants";
import { useInitialValues, useAsyncForm } from "./useInitialValues";

const MyMenus = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [menuAndAction, setMenuAndAction] = useState();
  const data = useSelector(selectMenus);
  const comparisonList = useSelector(selectComparisonList);
  const filters = useInitialValues();
  const loading = useSelector(selectLoading);
  const totalItems = useSelector(selectCount);
  const userPermissions = useSelector(selectPermissions);

  const { useFormApi } = useAsyncForm();

  const loadData = useCallback(
    (page, pageSize) => {
      let body = { page, page_size: pageSize };

      if (comparisonList.length) {
        const target = R.pathOr("", ["preliminary_information", "target_group_details", "id"], comparisonList[0]);
        body = { ...body, target };
      }

      body = { ...body, ...useFormApi.getValues() };

      dispatch(searchMyMenus({ body }));
    },
    // By adding the ignore deps, we do not get infinite API calls loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, comparisonList.length, useFormApi],
  );

  useEffect(() => {
    dispatch(loadFiltersRequest());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetReducer());
    };
  }, [dispatch]);

  const clickOnClearList = useCallback(() => {
    dispatch(resetComparisonList());
  }, [dispatch]);

  const onCloseModal = useCallback(() => {
    if (!menuAndAction) return;
    dispatch(resetMenu());
  }, [dispatch, menuAndAction]);

  const callbackOnRename = useCallback(() => {
    const body = useFormApi.getValues();
    dispatch(searchMyMenus(body));
  }, [dispatch, useFormApi]);

  useEffect(() => {
    if (data?.length === 1 && filters.target_group) {
      clickOnClearList();
      dispatch(
        setAlert({
          type: "error",
          text: messages.onlyOneMenuToCompare,
          title: messages.genericError,
        }),
      );
    }
  }, [clickOnClearList, data, dispatch, filters.target_group]);

  // If user can't create any menu, do not show button
  const userHasAtLeastOnePermission = useMemo(() => {
    const checkAtLeastOnePermission = (permission) => {
      return (
        permission === PERMISSIONS.ADD_OPTIMIZED_MENU ||
        permission === PERMISSIONS.ADD_MANUAL_MENU ||
        permission === PERMISSIONS.ADD_COMMUNITY_MENU
      );
    };

    return userPermissions.some(checkAtLeastOnePermission);
  }, [userPermissions]);

  const createButton = useMemo(
    () => (userHasAtLeastOnePermission ? <CreateNewMenu /> : null),
    [userHasAtLeastOnePermission],
  );

  return (
    <Layout backgroundColor={colors["ui-02"].hex}>
      <SecondHeader title={intl.formatMessage(messages.header)} xsButton={4} button={createButton} />
      <Loading active={loading} />

      <div>
        {!R.isEmpty(comparisonList) && data.length > 1 && (
          <ComparisonPreview clickOnClearList={clickOnClearList} comparisonList={comparisonList} />
        )}

        <FormProvider {...useFormApi}>
          <SearchAndFilter />

          <MenusList
            data={data}
            setMenuAndAction={setMenuAndAction}
            totalItems={totalItems}
            loading={loading}
            loadData={loadData}
            comparisonList={comparisonList}
          />
        </FormProvider>
      </div>

      <Modals
        onClose={onCloseModal}
        callbackOnRename={callbackOnRename}
        menuAndAction={menuAndAction}
        setMenuAndAction={setMenuAndAction}
      />
    </Layout>
  );
};

export default compose(withAuth, withCountryKey)(MyMenus);
