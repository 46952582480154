import { useMemo } from "react";
import * as R from "ramda";
import { useFormContext, useWatch } from "react-hook-form";

import { RowStyled } from "components/utils";
import { FIELDS, FOOD_RESTRICTION_PATH } from "../../constants";
import Day from "./Day";
import { SchedulerContainer, DayContainer } from "./styles";

const Scheduler = ({ disable, daysInWeek, weekCount }) => {
  const { control } = useFormContext();
  const includedDays = useWatch({ control, name: `${FOOD_RESTRICTION_PATH}.${FIELDS.INCLUDED_DAYS}` });
  const excludedDays = useWatch({ control, name: `${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDED_DAYS}` });

  const days = useMemo(() => new Array(weekCount * daysInWeek).fill().map((_, i) => i + 1), [daysInWeek, weekCount]);

  return R.splitEvery(daysInWeek, days).map((days, i) => (
    <SchedulerContainer key={`scheduler-week-${i}`} className={disable ? "disable" : ""}>
      <RowStyled width="100%">
        {days.map((dayNumber) => (
          <DayContainer key={dayNumber} middle="xs" center="xs">
            <Day
              dayNumber={dayNumber}
              week={i + 1}
              weekDay={dayNumber - i * daysInWeek}
              includedDays={includedDays}
              excludedDays={excludedDays}
            />
          </DayContainer>
        ))}
      </RowStyled>
    </SchedulerContainer>
  ));
};

export default Scheduler;
