import { useMemo, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { NumberInputHookForm } from "components/Form";
import { ColStyled, RowStyled } from "components/utils";
import { FIELDS, FOOD_RESTRICTION_PATH } from "../constants";
import rulesMessages from "containers/OptimizedMenu/Rules/messages";
import foodRulesMessages from "../../messages";

const IncludedCount = ({ disable, daysInWeek, weekCount }) => {
  const { control, setValue } = useFormContext();
  const includedDays = useWatch({ control, name: `${FOOD_RESTRICTION_PATH}.${FIELDS.INCLUDED_DAYS}` });
  const excludedDays = useWatch({ control, name: `${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDED_DAYS}` });
  const currentRepetition = useWatch({ control, name: `${FOOD_RESTRICTION_PATH}.${FIELDS.REPETITION}` });

  const mealCounts = useMemo(() => weekCount * daysInWeek, [daysInWeek, weekCount]);

  const min = useMemo(() => (includedDays[0] ? includedDays.length : 1), [includedDays]);
  const max = useMemo(
    () => (excludedDays[0] ? mealCounts - excludedDays.length : mealCounts),
    [excludedDays, mealCounts]
  );

  const firstMount = useRef(true);
  // Update "min" and "max" if user includes/excludes food items.
  // But, prevent this from happening on the first render (because when editing an already created food item
  // restriction, it will override the previously set values)
  // Eg. If I have a 1-6 range but user wants the food item to appear in at least 3 days, it should become 3-6.
  useEffect(() => {
    if (!firstMount.current) {
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.REPETITION}[0]`, min);
      setValue(`${FOOD_RESTRICTION_PATH}.${FIELDS.REPETITION}[1]`, max);
    } else {
      firstMount.current = false;
    }
  }, [max, min, setValue]);

  return (
    <ColStyled xs={6} className={disable ? "disable" : ""}>
      <Text bold marginBottom="20px" value={<FormattedMessage {...foodRulesMessages.itemRepetition} />} wrap />
      <RowStyled>
        <ColStyled>
          <NumberInputHookForm
            control={control}
            name={`${FOOD_RESTRICTION_PATH}.${FIELDS.REPETITION}[0]`}
            helperText={<FormattedMessage {...rulesMessages.min} />}
            step={1}
            min={min}
            max={currentRepetition[1]}
            allowZero
          />
        </ColStyled>

        <ColStyled>
          <NumberInputHookForm
            control={control}
            name={`${FOOD_RESTRICTION_PATH}.${FIELDS.REPETITION}[1]`}
            helperText={<FormattedMessage {...rulesMessages.max} />}
            step={1}
            min={currentRepetition[0]}
            max={max}
            allowZero
          />
        </ColStyled>
      </RowStyled>
    </ColStyled>
  );
};

export default IncludedCount;
