import React, { useEffect } from "react";
import * as R from "ramda";
import { injectIntl } from "react-intl";
import * as Sentry from "@sentry/react";

import errorMessages from "utils/messages";
import { Toast } from "./styles";

function Alert({ isOpen, error, onAnimationEnd, intl }) {
  useEffect(() => {
    if (error && R.pathOr("error", ["type"], error) === "error") {
      if (process.env.NODE_ENV === "production") {
        Sentry.captureException(error);
      } else if (process.env.NODE_ENV === "development") {
        console.log(error);
      }
    }
  }, [error]);
  if (!error) return null;
  return (
    <Toast
      error={String(isOpen)}
      caption=""
      hideCloseButton
      iconDescription=""
      kind={R.pathOr("error", ["type"], error)}
      notificationType="toast"
      role="alert"
      lowContrast
      statusIconDescription={R.pathOr("error", ["type"], error)}
      title={intl.formatMessage(R.pathOr(errorMessages.genericError, ["title"], error))}
      subtitle={intl.formatMessage(R.pathOr(errorMessages.generic, ["text"], error))}
      onAnimationEnd={onAnimationEnd}
    />
  );
}

export default injectIntl(Alert);
