import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectApp = () => (state) => R.pathOr(initialState, ["app"], state);

export default () => createSelector(selectApp(), (appState) => appState);

export const selectLoading = createSelector(selectApp(), (appState) => R.pathOr({}, ["loading"], appState));

export const selectLoadingSmall = createSelector(selectApp(), (appState) => R.pathOr({}, ["loadingSmall"], appState));

export const selectAccount = createSelector(selectApp(), (appState) => R.pathOr({}, ["account"], appState));

export const selectConstants = createSelector(selectApp(), (appState) => R.pathOr({}, ["constants"], appState));

export const selectWeightChoices = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "weight_choices"], appState),
);

export const selectFoodSources = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "food_source_choices"], appState),
);

export const selectOrigins = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "origin_choices"], appState),
);

export const selectAdditionalCostChoices = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "additional_cost_choices"], appState),
);

export const selectMenuTypeChoices = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "menu_type_choices"], appState),
);

export const selectMenuStatusChoices = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "menu_status_choices"], appState),
);

export const selectNutrientChoices = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["constants", "nutrient_choices"], appState),
);

export const selectCurrency = createSelector(selectApp(), (appState) => R.pathOr(null, ["currency"], appState));

export const selectRateFromUSD = createSelector(selectApp(), (menuState) =>
  R.pathOr(null, ["currency", "reverse_rate"], menuState),
);

export const selectRateFromLocal = createSelector(selectApp(), (menuState) =>
  R.pathOr(null, ["currency", "rate"], menuState),
);

export const selectLocalCurrency = createSelector(selectApp(), (menuState) =>
  R.pathOr(null, ["currency", "currency_code"], menuState),
);

export const selectRegions = createSelector(selectApp(), (appState) => R.pathOr([], ["regions"], appState));

export const selectPriceSources = createSelector(selectApp(), (appState) => R.pathOr([], ["priceSources"], appState));

export const selectSelectedCountry = createSelector(selectApp(), (appState) =>
  R.pathOr({}, ["selectedCountry"], appState),
);

export const selectLanguages = createSelector(selectApp(), (appState) => R.pathOr({}, ["languages"], appState));

export const selectFirstName = createSelector(selectAccount, (appState) => R.pathOr("", ["first_name"], appState));

export const selectUsername = createSelector(selectAccount, (appState) => R.pathOr("", ["username"], appState));

export const selectIsInactive = createSelector(selectAccount, (appState) => !R.pathOr(true, ["is_active"], appState));

export const selectAccountId = createSelector(selectAccount, (appState) => R.pathOr("", ["id"], appState));

export const selectProfile = createSelector(selectAccount, (appState) => R.pathOr({}, ["profile"], appState));

export const selectCountryCode = createSelector(selectAccount, (appState) =>
  R.pathOr("", ["profile", "country"], appState),
);

export const selectCountryIso3 = createSelector(selectAccount, (appState) =>
  R.pathOr("", ["profile", "iso3"], appState),
);

export const selectLanguage = createSelector(selectAccount, (appState) =>
  R.pathOr("en", ["profile", "language"], appState),
);

export const selectError = createSelector(selectApp(), (appState) => R.pathOr(false, ["error"], appState));

export const selectAvailableNutrients = createSelector(selectApp(), (appState) =>
  R.pathOr([], ["availableNutrients"], appState),
);

export const selectPermissions = createSelector(selectAccount, (appState) =>
  R.pathOr([], ["plus_permissions"], appState),
);

export const selectRoles = createSelector(selectAccount, (appState) => R.pathOr([], ["plusadmin_roles"], appState));

export const selectIsSuperUser = createSelector(selectAccount, (appState) =>
  R.pathOr(false, ["is_superuser"], appState),
);

export const selectEnabledFeatures = createSelector(selectAccount, (appState) =>
  R.pathOr([], ["enabled_features"], appState),
);

export const selectNutritionalValues = createSelector(selectApp(), (appState) =>
  R.pathOr({}, ["nutritionalValues"], appState),
);

export const selectLoadingAllNutritionalValues = createSelector(selectApp(), (appState) =>
  R.pathOr(false, ["loadingAllNutritionalValues"], appState),
);

export const selectFoodItems = createSelector(selectApp(), (appState) => R.pathOr(null, ["foodItems"], appState));

export const selectLoadingRegions = createSelector(selectApp(), (appState) =>
  R.pathOr(false, ["loadingRegions"], appState),
);

export const selectGlobalFeatures = createSelector(selectApp(), (appState) =>
  R.pathOr(null, ["globalFeatures"], appState),
);

export const selectLoadingGeneralAppInfo = createSelector(selectApp(), (appState) =>
  R.pathOr(false, ["loadingGeneralAppInfo"], appState),
);
