export const USD = { label: "USD", value: "USD" };

export const ENVIRONMENTS = {
  DEVELOPMENT: "Develop",
  PRODUCTION: "Production",
  QUALITY: "Quality",
};

export const mappingUnit = {
  gram: "g",
  calorie: "Kcal",
  milligram: "mg",
  microgram: "µg",
};

export const isCommunityFeatureEnabled = process.env.LATEST_FEATURE_ENABLED === "true";

export const TYPES = {
  OPTIMIZED: "to be processed",
  MANUAL: "manual",
  COMMUNITY: "communitymenu",
};

export const WIDTH = {
  TABLET: "900px",
};

export const TEMPORARY_INFO_PAGE_URL = "https://innovation.wfp.org/project/smp-plus";

export const FEATURES = {
  MENU_SCORE: "menu_score",
  LANDING_PAGE: "landing_page",
};
