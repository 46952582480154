import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

export const optionsValidity = [
  {
    value: null,
    label: <FormattedMessage {...messages.optionAll} />,
  },
  {
    value: 1,
    label: <FormattedMessage {...messages.optionOne} />,
  },
  {
    value: 3,
    label: <FormattedMessage {...messages.option} values={{ number: "3 " }} />,
  },
  {
    value: 5,
    label: <FormattedMessage {...messages.option} values={{ number: "5 " }} />,
  },
];
