import styled from "styled-components";
import { InlineLoading as WFPInlineLoading } from "@wfp/ui";

const CustomInlineLoading = ({ radius, padding, centered, ...props }) => <WFPInlineLoading {...props} />;

export const StyledInlineLoading = styled(CustomInlineLoading)`
  padding: ${({ padding }) => padding};
  justify-content: ${({ centered }) => (centered ? "center" : "normal")};

  .wfp--loading__svg circle {
    r: ${({ radius }) => radius};
  }
`;
