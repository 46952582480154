import styled from "styled-components";
import { colors, TextInput } from "@wfp/ui";
import { Col } from "react-flexbox-grid";
import { mediaTablet } from "components/utils";

const MyTextInput = ({ ...props }) => <TextInput {...props} />;

export const TextInputStyled = styled(MyTextInput)`
  ::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 16px;
    letter-spacing: 0.7;
  }
`;

export const ColStyled = styled(Col)`
  margin: 0 5px;
  flex: ${({ flex }) => flex || 0.5};
`;

export const Container = styled.div`
  padding: 10px 0 20px;
  position: sticky;
  top: var(--header-height);
  transition: border 0.1s linear;
  z-index: 3;
  background: ${colors["ui-02"].hex};
  .row {
    margin: 0;
  }
  ${mediaTablet} {
    padding: 10px 10px 20px;
  }
`;
