import * as R from "ramda";
import { colors } from "@wfp/ui";

import { CipLegend } from "components/Charts";
import { newColorBySource } from "components/utils";
import { ContainerLegend, ColLegend, Label } from "./styles";

export default function Legend({ inPdf, labels, percentageInLegend, noEllipsis, fontSize }) {
  const flattenData = R.flatten(labels);
  return (
    <ContainerLegend inPdf={inPdf}>
      {R.splitEvery(2, Object.keys(newColorBySource)).map((coupleSource, i) => (
        <ColLegend key={`block-${i}`}>
          {coupleSource.map((sourceKey) => {
            const { source, label, source_display, value } = flattenData.find((el) => el.source === sourceKey) || {};
            return source ? (
              <Label key={source} middle="xs" start="xs">
                <CipLegend
                  fontSize={fontSize}
                  noEllipsis={noEllipsis}
                  fillColor={newColorBySource[source]}
                  textColor={colors["text-02"].hex}
                  value={percentageInLegend ? `${label || source_display} - ${value}%` : label || source_display}
                />
              </Label>
            ) : null;
          })}
        </ColLegend>
      ))}
    </ContainerLegend>
  );
}
