import { useCallback, useRef, useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Loading, ModuleHeader, ModuleBody, ModuleFooter } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, NewModule } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { createOrUpdateFoodPriceRequest } from "containers/FoodBasket/actions";
import { selectLoadingFoodPrice } from "containers/FoodBasket/selectors";
import { getSummaryPricesFilters } from "containers/FoodBasket/utils";
import PriceFields from "containers/FoodBasket/Shared/PriceFields";
import { reworkData } from "containers/FoodBasket/utils";
import Footer from "./Footer";
import { useReworkedData } from "./utils";
import { FIELDS } from "../constants";
import messages from "../messages";
import { useInitialValues } from "../useInitialValues";

const AddFoodPrice = ({ close, price, setPrice, itemId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control, getValues, reset } = useFormContext();
  const loadingFoodPrice = useSelector(selectLoadingFoodPrice);
  const sectionRef = useRef();
  const customInitialValues = useReworkedData(price);
  const initialValues = useInitialValues();

  const personalItem = useWatch({ control, name: FIELDS.PERSONAL_ITEM });

  useEffect(() => {
    if (price) {
      reset(customInitialValues);
    }

    // Maintain filters state but reset personal item state when section gets closed
    return () => {
      setPrice();
      reset({
        ...getValues(),
        [FIELDS.PERSONAL_ITEM]: initialValues[FIELDS.PERSONAL_ITEM],
      });
    };
  }, [customInitialValues, getValues, initialValues, price, reset, setPrice]);

  useLayoutEffect(() => sectionRef.current?.scrollIntoView({ behavior: "smooth" }), []);

  const save = useCallback(() => {
    const data = reworkData({ ...personalItem, item: itemId });
    const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
    dispatch(createOrUpdateFoodPriceRequest({ id: price?.id, data, filters, cb: close }));
  }, [close, dispatch, getValues, itemId, personalItem, price?.id]);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <Text bold fontSize="16px" marginLeft="0.5rem" value={intl.formatMessage(messages.addPersonalPrice)} />
      </ModuleHeader>
    ),
    [intl]
  );

  const body = useMemo(
    () => (
      <ModuleBody>
        <RowStyled>
          <ColStyled xs={8} padding="0">
            <PriceFields showProposedCheckbox={true} />
          </ColStyled>
        </RowStyled>
      </ModuleBody>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <ModuleFooter>
        <Footer close={close} save={save} />
      </ModuleFooter>
    ),
    [close, save]
  );

  const moduleStyles = useMemo(
    () => ({
      headerPadding: "2.2rem 2.2rem 0",
      footerPadding: "0.8rem 2.2rem",
    }),
    []
  );

  return (
    <div ref={sectionRef}>
      <NewModule header={header} body={body} footer={footer} withBorder {...moduleStyles} />
      <Loading active={loadingFoodPrice} />
    </div>
  );
};

export default AddFoodPrice;
