import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Tag } from "@wfp/ui";

import { CheckboxGroupHookForm } from "components/Form";
import { FiltersWrapper } from "./styles";
import { FIELDS } from "containers/MyMenus/constants";
import { selectMenuTypeChoices } from "containers/App/selectors";

const Mode = () => {
  const { control } = useFormContext();
  const menuTypeChoices = useSelector(selectMenuTypeChoices);

  const getLabelText = useCallback(({ label }) => <Tag type="success">{label}</Tag>, []);

  return (
    <FiltersWrapper area="1 / 1 / 3 / 2">
      <CheckboxGroupHookForm
        control={control}
        name={FIELDS.MODE}
        getLabelText={getLabelText}
        options={menuTypeChoices}
      />
    </FiltersWrapper>
  );
};

export default Mode;
