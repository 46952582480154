import styled from "styled-components";
import { colors } from "@wfp/ui";

export const TickStyled = styled.text`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight};
  fill: ${colors["text-02"].hex};
  text-transform: capitalize;
`;

export const TooltipContainer = styled.div`
  padding: 10px 15px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 1px 2px 4px ${colors["text-02"].hex};
  border: 1px solid ${colors["ui-04"].hex};
`;

export const Block = styled.div`
  margin: 30px 0 10px;
  padding: 1.2rem 1.2rem 1.2rem 0;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
`;
