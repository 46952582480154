import styled from "styled-components";
import { colors } from "@wfp/ui";

import { RowStyled } from "components/utils";

export const RuleContainer = styled(RowStyled)`
  & + & {
    border-top: 1px solid ${colors["ui-04"].hex};
  }
`;

export const RuleNameContainer = styled.div`
  display: flex;
`;
