import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { CheckboxHookForm } from "components/Form";
import { RowStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import { FIELDS, FOOD_RESTRICTION_PATH } from "../../constants";

const ExcludeFromGroup = ({ disable }) => {
  const { control } = useFormContext();

  const name = useWatch({
    control,
    name: `${FOOD_RESTRICTION_PATH}.${FIELDS.ITEM_NAME}`,
  });
  const majorGroup = useWatch({
    control,
    name: `${FOOD_RESTRICTION_PATH}.${FIELDS.MAJOR_LABEL}`,
  });

  return (
    <RowStyled margin="0 0 30px" className={disable ? "disable" : ""}>
      <CheckboxHookForm
        key="item_restriction_exclude_from_group"
        control={control}
        name={`${FOOD_RESTRICTION_PATH}.${FIELDS.EXCLUDE_FROM_GROUP}`}
        labelText={<FormattedMessage {...messages.excludeFromGroup} values={{ name, majorGroup }} />}
        id="item_restriction_exclude_from_group"
      />
    </RowStyled>
  );
};

export default ExcludeFromGroup;
