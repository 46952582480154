import { defineMessage } from "react-intl";

export default {
  editMenu: defineMessage({
    id: "editMenu.title",
    defaultMessage: "Edit menu",
  }),
  duplicateMenu: defineMessage({
    id: "modal.duplicateTitle",
    defaultMessage: "Duplicate menu",
  }),
  newMenu: defineMessage({
    id: "createNewMenu.newMenu",
    defaultMessage: "Create new menu",
  }),
  manualMenu: defineMessage({
    id: "createNewMenu.titleManual",
    defaultMessage: "Manual menu",
  }),
  optimisedMenu: defineMessage({
    id: "createNewMenu.titleOptimized",
    defaultMessage: "Optimised menu",
  }),
  communityMenu: defineMessage({
    id: "createNewMenu.titleCommunity",
    defaultMessage: "Community menu",
  }),
};
