import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Grid } from "react-flexbox-grid";
import { Field, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Text, Button } from "components";
import { Select, FieldNumber } from "components/InputFields";
import { ReactSelectWithDataTestId } from "components/utils";
import { selectWeightChoices } from "containers/App/selectors";
import { RowStyled, ColStyled } from "./styles";
import { FIELDS, ADDITIONAL_FIELDS } from "./constants";
import buttonMessages from "components/Button/messages";
import foodBasketMessages from "containers/FoodBasket/messages";
import FieldsAdditionalCosts from "./FieldsAdditionalCosts";
import { iconAddGlyph } from "@wfp/icons";
import { useHtmlHook } from "hooks";

const FONT_SIZE_LABEL = "16px";

function FieldsPriceInfo({ currencies, foodItem }) {
  const { values } = useFormState();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();
  const weights = useSelector(selectWeightChoices);

  const itemRootProperty = useMemo(() => `foods.${foodItem.display_name}`, [foodItem.display_name]);

  return (
    <Grid style={{ marginBottom: "30px" }}>
      <RowStyled>
        <Text marginBottom="5px" bold value={foodItem.display_name.split(";")[0]} />
      </RowStyled>
      <RowStyled withMarginTop bottom="xs">
        <ColStyled flex={0.5} isRTL={isRTL}>
          <Field
            id={`${itemRootProperty}.${FIELDS.PRICE}`}
            name={`${itemRootProperty}.${FIELDS.PRICE}`}
            format={(value) => (value ? Number(value) : "")}
            data-test-id="new-price-purchase-price"
            labelText={intl.formatMessage(foodBasketMessages.columnPurchaseCost)}
            fontSizeLabel={FONT_SIZE_LABEL}
            component={FieldNumber}
          />
        </ColStyled>
        <ColStyled flex={0.5} isRTL={isRTL}>
          <Field
            id={`${itemRootProperty}.${FIELDS.QUANTITY}`}
            name={`${itemRootProperty}.${FIELDS.QUANTITY}`}
            options={weights}
            labelText={intl.formatMessage(foodBasketMessages.unit)}
            placeholder={intl.formatMessage(foodBasketMessages.unit)}
            data-test-id="new-price-unit"
            fontSizeLabel={FONT_SIZE_LABEL}
            components={ReactSelectWithDataTestId}
            component={Select}
          />
        </ColStyled>
        <ColStyled flex={0.5} isRTL={isRTL}>
          <Field
            id={`${itemRootProperty}.${FIELDS.CURRENCY}`}
            name={`${itemRootProperty}.${FIELDS.CURRENCY}`}
            options={currencies}
            labelText={intl.formatMessage(foodBasketMessages.currency)}
            data-test-id="new-price-currency"
            fontSizeLabel={FONT_SIZE_LABEL}
            placeholder={intl.formatMessage(foodBasketMessages.currency)}
            components={ReactSelectWithDataTestId}
            component={Select}
          />
        </ColStyled>
        <ColStyled />
      </RowStyled>

      <FieldArray name={`${itemRootProperty}.${ADDITIONAL_FIELDS.ADDITIONAL_COSTS}`}>
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <FieldsAdditionalCosts
                key={`${name}-${index}`}
                name={name}
                index={index}
                currencies={currencies}
                foodItemName={foodItem.display_name}
                values={values.foods[foodItem.display_name].additional_costs}
              />
            ))}
            <Button
              widthAuto
              minwWidth="30%"
              kind="secondary"
              icon={iconAddGlyph}
              marginTop="20px"
              children={intl.formatMessage(buttonMessages.addAdditionalPrice)}
              onClick={() => fields.push({})}
            />
          </>
        )}
      </FieldArray>
    </Grid>
  );
}

export default FieldsPriceInfo;
