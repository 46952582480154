import { defineMessages } from "react-intl";

export default defineMessages({
  itemsPerPage: {
    id: "table.itemsPerPage",
    defaultMessage: "Items per page:",
  },
  pageRange: {
    id: "table.pageRange",
    defaultMessage: "{current} of {total} pages",
  },
  noResultData: {
    id: "table.noResultData",
    defaultMessage: "No result",
  },
});
