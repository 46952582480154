import { colors } from "@wfp/ui";

import { Text } from "components";
import { newColorByCategory } from "components/utils";
import { renameVitamins } from "containers/Menu/utils";
import { Box } from "./styles";

const Item = ({ nutrient, renderValue }) => {
  const backgroundColor = newColorByCategory[nutrient.category]["backgroundColor"];

  return (
    <Box backgroundColor={backgroundColor}>
      <Text regularWrap fontSize="12px" defaultColor={colors["ui-01"].hex} value={renderValue(nutrient)} />
      <Text
        regularWrap
        fontSize="12px"
        defaultColor={colors["ui-01"].hex}
        transform="uppercase"
        bold
        value={renameVitamins(nutrient.label)}
      />
    </Box>
  );
};

export default Item;
