import { colors } from "@wfp/ui";

import { Text } from "components";
import { newColorByCategory } from "components/utils";
import { renameVitamins } from "containers/Menu/utils";
import { WrapperTooltip } from "./styles";

export default function Tooltip({ bar, showTooltip }) {
  return (
    <>
      {bar && showTooltip === bar.label && (
        <WrapperTooltip>
          <Text bold fontSize="16px" defaultColor={colors["text-02"].hex} value={renameVitamins(bar.label)} />
          <Text
            bold
            fontSize="16px"
            defaultColor={newColorByCategory[bar.category]?.["backgroundColor"]}
            value={`${bar.percentage}%`}
          />
        </WrapperTooltip>
      )}
    </>
  );
}
