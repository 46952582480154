import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import { saveComparisonList } from "containers/MyMenus/actions";
import messages from "components/Button/messages";
import { INFEASIBLE } from "../labels";
import { searchMyMenus } from "../actions";
import { selectFilters } from "../selectors";

const Compare = ({ data, comparisonList }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const params = useSelector(selectFilters);
  const { getValues } = useFormContext();

  const infeasible = R.pathOr("", ["original", "optimization", "type"], data) === INFEASIBLE;
  const target = R.pathOr(1, ["original", "preliminary_information", "target_group_details", "id"], data);

  const isInComparisonList = useMemo(
    () => comparisonList.find((el) => el.id === data.original.id),
    [comparisonList, data.original.id],
  );

  const clickOnCompare = useCallback(() => {
    if (!params.target_group) {
      const formValues = getValues();
      dispatch(searchMyMenus({ body: { ...params, ...formValues, target_group: target, page: 1 } }));
    }
    dispatch(saveComparisonList([...comparisonList, data.original]));
  }, [comparisonList, data.original, dispatch, getValues, params, target]);

  return (
    <>
      {comparisonList.length !== 4 && !isInComparisonList && (
        <Button
          disable={String(infeasible)}
          className={infeasible ? "disable" : ""}
          kind="ghost"
          onClick={clickOnCompare}
          children={intl.formatMessage(messages.compare)}
        />
      )}
    </>
  );
};

export default Compare;
