import React, { useCallback, useState } from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { ModalStyled } from "components/UIKitStyled";
import { useForm } from "react-final-form";
import { withModalHandling } from "hocs";
import { withRouter } from "react-router-dom";
import Nutrient from "./Nutrient";
import Footer from "./Footer";
import messages from "components/Button/messages";
import { FIELDS } from "../../utils";

function ModalNutrient({ nutritionalRequirements, open, label, setStatusModal, intl }) {
  const form = useForm();
  const [initial] = useState(form.getState().values.nutritional_requirements);

  const submitModal = useCallback(() => {
    setStatusModal(false);
  }, [setStatusModal]);

  const resetAndClose = useCallback(() => {
    for (let key in nutritionalRequirements) {
      form.change(`${FIELDS.NUTRITIONAL_REQUIREMENTS}.${key}.percentage`, initial[key].percentage);
    }

    setStatusModal(false);
  }, [form, initial, setStatusModal, nutritionalRequirements]);

  const renderNutrients = useCallback(() => {
    const nutrients = [];

    for (let key in nutritionalRequirements) {
      nutrients.push(<Nutrient key={nutritionalRequirements[key].nutrient} nutrient={nutritionalRequirements[key]} />);
    }

    return <>{nutrients}</>;
  }, [nutritionalRequirements]);

  return (
    <ModalStyled
      modalHeading={label}
      primaryButtonText={intl.formatMessage(messages.save)}
      onRequestSubmit={submitModal}
      onRequestClose={resetAndClose}
      open={open}
      width="100%"
      inPortal={false}
      height={`${window.innerHeight - 30}px`}
      modalFooter={(props) => <Footer {...props} />}
    >
      {renderNutrients()}
    </ModalStyled>
  );
}

export default compose(withModalHandling, withRouter, injectIntl)(ModalNutrient);
