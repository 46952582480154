import { FormattedMessage } from "react-intl";

import { NewAccordion } from "components";
import FoodRules from "./FoodRules";
// TODO: Temporarily removed, will be added in the future once BE is ready.
// import MealBudgetRules from "./MealBudgetRules";
import messages from "./messages";
import NutritionRules from "./NutritionRules";
import SourcingRules from "./SourcingRules";

const Rules = () => {
  return (
    <>
      <NewAccordion
        customPadding="10px 20px 0px 20px"
        fields={[
          {
            title: <FormattedMessage {...messages.firstAccordion} />,
            content: <NutritionRules />,
          },
        ]}
      />

      <NewAccordion
        fields={[
          {
            title: <FormattedMessage {...messages.secondAccordion} />,
            content: <SourcingRules />,
          },
        ]}
      />

      {/* TODO: Temporarily removed, will be added in the future once BE is ready. */}
      {/* <NewAccordion
        fields={[
          {
            title: <FormattedMessage {...messages.thirdAccordion} />,
            content: <MealBudgetRules />,
          },
        ]}
      /> */}

      <NewAccordion
        fields={[
          {
            title: <FormattedMessage {...messages.fourthAccordion} />,
            content: <FoodRules />,
          },
        ]}
      />
    </>
  );
};

export default Rules;
