import { useCallback, useMemo } from "react";
import Text from "components/Text";
import { SelectStyled } from "./styles";

export default function MultiSelect({
  options = [],
  input,
  className,
  labelText,
  placeholder,
  fontSizeLabel,
  fontColorLabel,
  arrayOfObjects = false,
  customChange,
}) {
  const handleChange = useCallback(
    (elements) => {
      if (arrayOfObjects) {
        input.onChange(elements);
      } else {
        input.onChange(elements.map((el) => el.value));
      }

      if (customChange) customChange();
    },
    [arrayOfObjects, customChange, input],
  );

  const value = useMemo(() => {
    if (!input.value) return;

    if (arrayOfObjects) {
      const selectedDataIds = input.value.map((el) => el.value);
      return options.filter((option) => selectedDataIds.includes(option.value));
    } else {
      return options.filter((el) => input.value.includes(el.value));
    }
  }, [arrayOfObjects, input.value, options]);

  return (
    <>
      {labelText && (
        <Text bold marginBottom="5px" fontSize={fontSizeLabel} defaultColor={fontColorLabel} value={labelText} />
      )}
      <SelectStyled
        isMulti
        className={`wfp--react-select-container ${className || ""}`}
        classNamePrefix="wfp--react-select"
        styles={{ menu: (styles) => Object.assign(styles, { zIndex: 1000 }) }}
        menuPlacement="auto"
        options={options}
        value={options && value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  );
}
