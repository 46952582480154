import { Button, colors, Icon, Modal, Table, Form } from "@wfp/ui";
import { iconAddGlyph, iconEditGlyph } from "@wfp/icons";
import { useMemo, useState } from "react";
import messages from "../messages";
import { useIntl } from "react-intl";
import { LANGUAGES } from "../../../App/Header/Languages/utils";
import { useForm } from "react-hook-form";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { updateAdminFoodItemCustomTranslations } from "../../actions";
import { ColStyled, RowStyled } from "components/utils";
import TextInputController from "components/Form/TextInputHookForm";

const CustomTranslationButton = (props) => {
  const dispatch = useDispatch();
  const fields = ["name", "attribute_description"];
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const defaultValues = useMemo(
    () =>
      fields.reduce((accumulator, currentField) => {
        accumulator[currentField] = LANGUAGES.reduce((prev, current) => {
          prev[current["language_code"]] = R.pathOr(
            "",
            [props.country, currentField, current["language_code"]],
            props.rec.local_translations
          );
          return prev;
        }, {});
        return accumulator;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  const hasCustomTranslation = Object.keys(defaultValues).reduce((hasCustom, current) => {
    return (
      hasCustom ||
      Object.keys(defaultValues[current]).reduce((hasCustom, lang) => {
        return hasCustom || defaultValues[current][lang] !== "";
      }, false)
    );
  }, false);

  const { handleSubmit, control } = useForm({ defaultValues });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data, e) => {
    dispatch(updateAdminFoodItemCustomTranslations({ id: props.rec.id, data }));
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen}>
        {hasCustomTranslation ? (
          <Icon data-testid={"editIcon"} fill={colors["ui-01"].hex} icon={iconEditGlyph} />
        ) : (
          <Icon data-testid={"addIcon"} fill={colors["ui-01"].hex} icon={iconAddGlyph} />
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        modalHeading={intl.formatMessage(messages.foodItemLocalTranslations)}
        primaryButtonText={"Save"}
        onRequestClose={handleClose}
        onRequestSubmit={handleSubmit(onSubmit)}
      >
        <RowStyled>
          <ColStyled xs={12}>{[props.rec.name, props.rec.attribute_description].join(", ")}</ColStyled>
        </RowStyled>

        <Form>
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>{intl.formatMessage(messages.foodItem)}</th>
                <th>{intl.formatMessage(messages.foodItemAttributeDescription)}</th>
              </tr>
            </thead>
            <tbody>
              {LANGUAGES.map((lang) => (
                <tr key={lang.language_code}>
                  <td>{lang.language_code}</td>
                  <td>
                    <TextInputController name={`name.${lang.language_code}`} control={control} />
                  </td>
                  <td>
                    <TextInputController name={`attribute_description.${lang.language_code}`} control={control} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </Modal>
    </>
  );
};

export default CustomTranslationButton;
