import React from "react";

export const Share = ({ size, fill = "#000", description = "", className, onClick }) => (
  <svg
    width={size || "20px"}
    height={size || "20px"}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 110 115"
    enableBackground="new 0 0 100 100"
    className={className}
    onClick={onClick}
    alt={description}
  >
    <path d="M80,35c8.3,0,15-6.7,15-15S88.3,5,80,5s-15,6.7-15,15c0,1.5,0.2,3,0.7,4.4L32.1,41.2C29.4,37.4,25,35,20,35  c-8.3,0-15,6.7-15,15s6.7,15,15,15c5,0,9.4-2.4,12.1-6.2l33.6,16.8C65.2,77,65,78.5,65,80c0,8.3,6.7,15,15,15s15-6.7,15-15  s-6.7-15-15-15c-5,0-9.4,2.4-12.1,6.2L34.3,54.4C34.8,53,35,51.5,35,50s-0.2-3-0.7-4.4l33.6-16.8C70.6,32.6,75,35,80,35z M80,10  c5.5,0,10,4.5,10,10s-4.5,10-10,10s-10-4.5-10-10S74.5,10,80,10z M20,60c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10  S25.5,60,20,60z M80,70c5.5,0,10,4.5,10,10s-4.5,10-10,10s-10-4.5-10-10S74.5,70,80,70z" />
  </svg>
);
