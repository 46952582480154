import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { ModuleHeader, ModuleBody, ModuleFooter } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, NewModule } from "components";
import { SelectHookForm, NumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import {
  selectFoodSources,
  selectWeightChoices,
  selectRateFromUSD,
  selectRateFromLocal,
} from "containers/App/selectors";
import foodBasketMessages from "containers/FoodBasket/messages";
import { FIELDS, SOURCE_INITIAL_VALUES } from "containers/GeneralInfo/constants";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import { DECIMAL_DIGITS } from "../../constants";
import Footer from "./Footer";
import { normalizePrice } from "../../PageOne/AddPersonalFoodItem/utils";

const AddPriceSource = ({ item, setOpenAddPriceSource }) => {
  const { control, getValues, setValue } = useFormContext();
  const draftSource = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.SOURCE}` });

  const procurementSources = useSelector(selectFoodSources);
  const units = useSelector(selectWeightChoices);
  const conversionRateFromUsd = useSelector(selectRateFromUSD);
  const conversionRateFromLocal = useSelector(selectRateFromLocal);

  const formUnit = useMemo(() => {
    const values = getValues();
    return values[FIELDS.FILTERS][FIELDS.UNIT];
  }, [getValues]);

  const formCurrency = useMemo(() => {
    const values = getValues();
    return values[FIELDS.FILTERS][FIELDS.CURRENCY];
  }, [getValues]);

  const unit = useMemo(() => units.find((unit) => unit.value === formUnit), [formUnit, units]);
  const gramUnit = useMemo(() => units.find((unit) => unit.value === "1 g"), [units]);

  const filteredProcurementSources = useMemo(() => {
    const existingSources = Object.keys(item.prices);
    return procurementSources.filter((source) => !existingSources.includes(source.value));
  }, [item.prices, procurementSources]);

  const close = useCallback(() => {
    setValue(`${FIELDS.DRAFT}.${FIELDS.SOURCE}`, SOURCE_INITIAL_VALUES);
    setOpenAddPriceSource(false);
  }, [setOpenAddPriceSource, setValue]);

  const save = useCallback(() => {
    const values = getValues();
    const prevSources = values[FIELDS.DRAFT][FIELDS.ITEM][FIELDS.PRICES];

    const newSource = {
      user_price_per_gram: normalizePrice({
        from: unit,
        to: gramUnit,
        fromCurrency: formCurrency,
        toCurrency: "USD",
        price: draftSource[FIELDS.PRICE],
        conversionRateFromUsd,
        conversionRateFromLocal,
        decimals: DECIMAL_DIGITS,
      }),
      user_provided_price: draftSource[FIELDS.PRICE],
      added_by_user: true,
    };

    setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}.${FIELDS.PRICES}`, {
      ...prevSources,
      [draftSource[FIELDS.SOURCE]]: newSource,
    });
    close();
  }, [
    close,
    conversionRateFromLocal,
    conversionRateFromUsd,
    draftSource,
    formCurrency,
    getValues,
    gramUnit,
    setValue,
    unit,
  ]);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <Text bold fontSize="16px" value={<FormattedMessage {...messages.addPriceSource} />} />
      </ModuleHeader>
    ),
    [],
  );

  const body = useMemo(
    () => (
      <ModuleBody>
        <RowStyled>
          <ColStyled xs={4}>
            <NumberInputHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.SOURCE}.${FIELDS.PRICE}`}
              labelText={<FormattedMessage {...foodBasketMessages.foodPrice} />}
            />
          </ColStyled>
          <ColStyled xs={4}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.DRAFT}.${FIELDS.SOURCE}.${FIELDS.SOURCE}`}
              options={filteredProcurementSources}
              label={<FormattedMessage {...foodBasketMessages.columnSource} />}
            />
          </ColStyled>
        </RowStyled>
      </ModuleBody>
    ),
    [control, filteredProcurementSources],
  );

  const footer = useMemo(
    () => (
      <ModuleFooter>
        <Footer close={close} save={save} />
      </ModuleFooter>
    ),
    [close, save],
  );

  return <NewModule withBorder header={header} body={body} footer={footer} />;
};

export default AddPriceSource;
