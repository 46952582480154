import React from "react";
import { colors } from "@wfp/ui";
import { Line, Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import ContentWithBorder from "./ContentWithBorder";
import { ModuleWithoutShadow, WrapperModule } from "./styles";

const Module = React.forwardRef(
  (
    {
      noTitle,
      title,
      subTitle,
      noLine,
      height,
      minHeight,
      elementsOnRight,
      children,
      paddingContent,
      useSticky,
      margin,
      noBorder,
      titleAndInfoXS,
      alignBottom,
      padding,
      marginHeader,
      elementsAfterTitle,
      width,
    },
    ref,
  ) => (
    <WrapperModule ref={ref} useSticky={useSticky} width={width}>
      <ModuleWithoutShadow
        height={height}
        minHeight={minHeight}
        margin={margin}
        paddingContent={paddingContent}
        noBorder={noBorder}
        padding={padding}
      >
        {!noTitle && (
          <>
            <RowStyled between="xs" middle="xs" bottom={alignBottom} margin={marginHeader}>
              <ColStyled xs={titleAndInfoXS} padding="0">
                <Text wrap marginBottom="5px" fontSize="16px" bold value={title} />
                {subTitle && <Text wrap marginTop="-5px" defaultColor={colors["text-02"].hex} value={subTitle} />}
              </ColStyled>
              {elementsOnRight}
            </RowStyled>
            {elementsAfterTitle}
            {!noLine && <Line />}
          </>
        )}
        {children}
      </ModuleWithoutShadow>
    </WrapperModule>
  ),
);

Module.ContentWithBorder = ContentWithBorder;

export default Module;
