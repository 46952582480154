import styled from "styled-components";
import { colors } from "@wfp/ui";

import { RowStyled } from "components/utils";

export const Box = styled(RowStyled)`
  background-color: ${colors["ui-01"].hex};
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 4px;
  padding: ${({ padding }) => padding || 0};
  height: ${({ height }) => height || "auto"};
`;
