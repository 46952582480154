import { useMemo, useRef } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import useSize from "@react-hook/size";

import { NutritionalGraph } from "components/ChartsNivo";
import { FIELDS } from "containers/Results/constants";
import { selectMenuOptimizationType } from "containers/Menu/selectors";
import { MODIFIED, OPTIMAL, OPTIMIZED } from "containers/MyMenus/labels";
import { useGraphValues, useNutrientValues } from "hooks";

const Graph = () => {
  const type = useSelector(selectMenuOptimizationType);
  const isOptimizedOrModified = useMemo(() => [OPTIMAL, MODIFIED, OPTIMIZED].includes(type), [type]);

  const { control } = useFormContext();
  const dayComposition = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_COMPOSITION}` });
  const dayRecipes = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}` });

  const recipesIngredients = useMemo(
    () => R.flatten(dayRecipes.map((dayRecipe) => Object.values(dayRecipe.ingredients))),
    [dayRecipes],
  );

  const $section = useRef();
  const [sectionWidth] = useSize($section);

  const graphValues = useGraphValues({ items: [...dayComposition, ...recipesIngredients] });
  const nutrientValues = useNutrientValues({ items: [...dayComposition, ...recipesIngredients] });

  return (
    <div ref={$section}>
      <NutritionalGraph
        graphValues={graphValues}
        nutrientValues={nutrientValues}
        targetByRestriction={isOptimizedOrModified}
        width={sectionWidth}
      />
    </div>
  );
};

export default Graph;
