export default {
  home: "/",
  menus: "/menus",
  compare: "/menu/:ids/compare",
  results: "/results/:id",
  resultsCommunity: "/results/community/:id",
  createManual: "/create/manual",
  createOptimized: "/create/optimized",
  createCommunity: "/create/community",
  editManual: "/edit/:id/manual",
  editOptimized: "/edit/:id/optimized",
  editCommunity: "/edit/:id/community",
  duplicateManual: "/duplicate/:id/manual",
  duplicateOptimized: "/duplicate/:id/optimized",
  duplicateCommunity: "/duplicate/:id/community",
  foodBasket: "/food_basket",
  selectCountry: "/select_country",
  ciamLogin: "/ciam_login",
  ciamRedirect: "/ciam-redirect",
  info: "/info",
  admin: "/plusadmin",
  notActive: "/not_active",
  adminUsers: "/plusadmin/users",
  adminCountries: "/plusadmin/countries",
  adminCountriesSources: "/plusadmin/countries/sources",
  adminCountriesOptimizedSources: "/plusadmin/countries/sources/optimized",
  adminCountriesCommunitySources: "/plusadmin/countries/sources/community",
  adminCountriesNutrients: "/plusadmin/countries/nutrients",
  adminCountriesOptimizedNutrients: "/plusadmin/countries/nutrients/optimized",
  adminCountriesCommunityNutrients: "/plusadmin/countries/nutrients/community",
  adminCountriesCommunityNutrientsLevel: "/plusadmin/countries/nutrients-level/community",
  adminCountriesPriorityItems: "/plusadmin/countries/priority-items",
  adminCountriesVam: "/plusadmin/countries/vam-food-prices",
  adminCountriesPriceApproval: "/plusadmin/countries/price-approval",
  adminCountriesFoodItems: "/plusadmin/countries/food-items",
  adminCountriesRecipes: "/plusadmin/countries/recipes",
  adminCountriesRecipesPage: "/plusadmin/countries/recipes/:id",
};
