import { defineMessage } from "react-intl";

export default {
  basketTitle: defineMessage({
    id: "foodBasket.title",
    defaultMessage: "Food basket",
  }),
  basketInfo: defineMessage({
    id: "foodBasket.info",
    defaultMessage:
      "The food basket is made up of the food items for which we have price information for the chosen regions and season. To introduce an item in the food basket, add its price on the {link} page.",
  }),
  composition: defineMessage({
    id: "foodBasket.composition",
    defaultMessage: "Food basket composition",
  }),
  compositionInfo: defineMessage({
    id: "foodBasket.compositionInfo",
    defaultMessage: "These are the number of food groups and items that might appear in your menu.",
  }),
  viewFoodBasket: defineMessage({
    id: "button.viewFoodBasket",
    defaultMessage: "View food basket",
  }),
  modalTitle: defineMessage({
    id: "foodBasket.modalTitle",
    defaultMessage: "Food basket ({numGroups} food groups, {numItems} food items)",
  }),
  modalInfo: defineMessage({
    id: "foodBasket.modalInfo",
    defaultMessage:
      "All the food items in your food basket might appear in your menu. It is made up of the food items for which we have price information for the chosen settings. To introduce an item in the food basket, add its price on the food prices page.",
  }),
};
