import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Icon, Link } from "@wfp/ui";
import { colors } from "@wfp/ui";
import { iconWarning } from "@wfp/icons";
import { Row } from "react-flexbox-grid";
import { ModalStyled } from "components/UIKitStyled";
import buttonMessages from "components/Button/messages";
import appMessages from "containers/App/messages";
import { Text } from "components";
import messages from "./messages";
import { selectFoodBasket } from "../GeneralForm/selectors";

export const showLowNumberModal = (groups, items) => groups <= 5 || items <= 10;

function LowNumberModal({ showModal, setModal, clickOnContinue }) {
  const intl = useIntl();
  const { items_count = "-", group_count = "-" } = useSelector(selectFoodBasket);

  const closeModal = useCallback(() => setModal(!showModal), [setModal, showModal]);

  return (
    <ModalStyled
      open={showModal}
      padding="0 3rem"
      primaryButtonText={intl.formatMessage(buttonMessages.continue)}
      secondaryButtonText={intl.formatMessage(buttonMessages.changeSettings)}
      onRequestSubmit={clickOnContinue}
      onRequestClose={closeModal}
      onSecondarySubmit={closeModal}
    >
      <Row middle="xs">
        <Icon width="28" height="28" fill={colors["support-03"].hex} description=" " icon={iconWarning} />
        <Text bold marginLeft="10px" fontSize="28px" value={intl.formatMessage(messages.tag)} />
      </Row>
      <Text
        wrap
        fontSize="16px"
        value={intl.formatMessage(messages.title, { numGroups: group_count, numItems: items_count })}
      />
      <Text
        wrap
        marginTop="15px"
        fontSize="16px"
        value={intl.formatMessage(messages.info, {
          link: <Link inline href="/food_prices" children={intl.formatMessage(appMessages.foodPricesLower)} />,
        })}
      />
    </ModalStyled>
  );
}

export default LowNumberModal;
