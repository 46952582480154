import { colors } from "@wfp/ui";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { INFEASIBLE } from "containers/MyMenus/labels";

const Cell = ({ data, value, label }) => {
  return (
    <RowStyled middle="xs" center="xs" className={data.optimization.type === INFEASIBLE ? "disable" : "pointer"}>
      <ColStyled>
        <Text wrap value={value} />
        <Text wrap transform="uppercase" marginTop="0.25rem" defaultColor={colors["text-02"].hex} value={label} />
      </ColStyled>
    </RowStyled>
  );
};

export default Cell;
