import React from "react";
import { FormattedMessage } from "react-intl";
import * as R from "ramda";
import messages from "./messages";

const genericError = <FormattedMessage {...messages.genericError} />;
const minGreaterMax = <FormattedMessage {...messages.minGreaterMax} />;
const maxAllowed = <FormattedMessage {...messages.maxAllowed} />;
const genericErrorAlert = <FormattedMessage {...messages.generic} />;
const greaterThanAvailableGroups = (value) => (
  <FormattedMessage {...messages.greaterThanAvailableGroups} values={{ value }} />
);
const greaterThanAvailableItems = (value) => (
  <FormattedMessage {...messages.greaterThanAvailableItems} values={{ value }} />
);
const setAlertMessage = (path, error, errors) => R.assocPath(path, error, errors);

const validateMinAndMax = (range) => range && !R.isNil(range[0]) && !R.isNil(range[1]) && range[0] > range[1];

export {
  genericError,
  greaterThanAvailableGroups,
  greaterThanAvailableItems,
  validateMinAndMax,
  minGreaterMax,
  maxAllowed,
  setAlertMessage,
  genericErrorAlert,
};
