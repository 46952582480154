import { defineMessages } from "react-intl";

export default defineMessages({
  foodSourcesTitle: {
    id: "generalInfo.foodSourcesTitle",
    defaultMessage: "Food sources",
  },
  foodSourcesButton: {
    id: "generalInfo.foodSourcesButton",
    defaultMessage: "Select food sources",
  },
});
