import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useFormWizardContext } from "components/MenuWizard";
import { ModalWithoutFooter } from "components/UIKitStyled";
import { RowStyled, ColStyled } from "components/utils";
import { selectWithoutSourcing } from "containers/Menu/selectors";
import { OPTIMAL, SUBOPTIMAL, INFEASIBLE, RUNNING, RUNNING_SUBOPTIMAL } from "containers/MyMenus/labels";
import { selectMajorGroups } from "containers/OptimizedMenu/Rules/FoodRules/selectors";
import { selectSourcingConstraints } from "containers/OptimizedMenu/selectors";
import { IconWarning } from "./components";
import InfeasibleWithoutSourcing from "./InfeasibleWithoutSourcing";
import OptimalWithoutSourcing from "./OptimalWithoutSourcing";
import Running from "./Running";
import SubOptimalWithoutSourcing from "./SubOptimalWithoutSourcing";

const OptimizedModal = ({ internalStatus, menuId, showModal, closeModal }) => {
  const withoutSourcing = useSelector(selectWithoutSourcing);
  const { setPage } = useFormWizardContext();
  const sourcingSet = useSelector(selectSourcingConstraints);
  const majorGroups = useSelector(selectMajorGroups);

  const isOptimal = useMemo(() => internalStatus === OPTIMAL, [internalStatus]);
  const isSubOptimal = useMemo(() => internalStatus === SUBOPTIMAL, [internalStatus]);
  const isInfeasible = useMemo(() => internalStatus === INFEASIBLE, [internalStatus]);
  const isRunning = useMemo(
    () => internalStatus === RUNNING || internalStatus === RUNNING_SUBOPTIMAL,
    [internalStatus],
  );

  const renderWithoutSourcing = useMemo(() => {
    if (isRunning) return <Running internalStatus={internalStatus} menuId={menuId} />;

    return (
      <>
        <IconWarning />
        {isOptimal && (
          <OptimalWithoutSourcing
            setPage={setPage}
            menuId={menuId}
            closeModal={closeModal}
            sourcingSet={sourcingSet}
            majorGroups={majorGroups}
          />
        )}
        {isSubOptimal && (
          <SubOptimalWithoutSourcing
            setPage={setPage}
            menuId={menuId}
            closeModal={closeModal}
            sourcingSet={sourcingSet}
            majorGroups={majorGroups}
          />
        )}
        {isInfeasible && <InfeasibleWithoutSourcing setPage={setPage} closeModal={closeModal} />}
      </>
    );
  }, [
    closeModal,
    internalStatus,
    isInfeasible,
    isOptimal,
    isRunning,
    isSubOptimal,
    majorGroups,
    menuId,
    setPage,
    sourcingSet,
  ]);

  return (
    <ModalWithoutFooter
      open={!!showModal}
      inPortal={false}
      onRequestClose={closeModal}
      modalHeading=""
      maxWidth="1200px"
      height="40%"
      padding="0 20px 10px"
      withBorderRadius="30px"
    >
      <RowStyled>
        <ColStyled margin="30px 30px 10px" xs>
          {withoutSourcing ? renderWithoutSourcing : <Running internalStatus={internalStatus} menuId={menuId} />}
        </ColStyled>
      </RowStyled>
    </ModalWithoutFooter>
  );
};

export default OptimizedModal;
