import { useMemo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { Chip } from "components/ChipWithLabel/styles";
import { RowStyled, newColorBySource } from "components/utils";
import { selectFoodItems } from "containers/FoodBasket/selectors";
import { selectFoodSources } from "containers/App/selectors";
import { useResponsiveHook } from "hooks";
import { LegendWrapper, LegendContainer } from "./styles";

const LegendSources = () => {
  const { isTabletOrMobile } = useResponsiveHook();
  const foodItems = useSelector(selectFoodItems);
  const procurementSources = useSelector(selectFoodSources);

  const sources = useMemo(() => {
    const allSources = R.flatten(foodItems.map((item) => item.sources_informations));
    const uniqueSources = [...new Set(allSources.map((item) => item.source))];
    return uniqueSources.map((item) => procurementSources.find((source) => source.value === item));
  }, [foodItems, procurementSources]);

  const columns = useMemo(
    () => (isTabletOrMobile ? `repeat(3, 1fr)` : `repeat(${sources.length}, 1fr)`),
    [isTabletOrMobile, sources.length],
  );

  if (R.isEmpty(sources) || R.isEmpty(procurementSources)) return null;

  return (
    <LegendWrapper>
      <LegendContainer columns={columns}>
        {sources.map(({ label, value }) => (
          <div key={value}>
            <RowStyled middle="xs" center="xs" height="100%" flexWrap="nowrap">
              <Chip size="13px" backgroundColor={newColorBySource[value]} />
              <span style={{ marginLeft: "5px" }}>{label}</span>
            </RowStyled>
          </div>
        ))}
      </LegendContainer>
    </LegendWrapper>
  );
};

export default LegendSources;
