import { call, select, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { format } from "date-fns";
import { selectFormValues as selectCommunityFormValues } from "containers/GeneralInfoCommunity/GeneralForm/selectors";
import { loadPartialDiversitySaga } from "containers/GeneralInfoCommunity/GeneralForm/sagas";
import { setAlert } from "containers/App/actions";
import routesPath from "containers/App/Routes/paths";
import messages from "./messages";
import { _loadMyMenus } from "containers/MyMenus/sagas";
import {
  getMenu,
  updateMenu as updateMenuCall,
  postMenu,
  postPartialCopyMenu,
  postCopyMenu,
  postShareMenu,
  getCommunityMenu,
  patchCommunityMenu,
  postCommunityMenu,
  postCommunityPartialPlusMenu,
  postCommunityMenuAsOptimized,
  postOptimizeManualMenu,
} from "api/api";
import {
  loadStarted,
  loadSuccess,
  loadEnded,
  loadMenuRequest,
  updateMenu,
  createMenu,
  copyMenu,
  shareMenu,
  partialCopyMenu,
  createOrUpdateMenuRequest,
  createOrUpdateCommunityMenu,
  copyCommunityMenuAsOptimized,
  copyCommunityMenuAsOptimizedKo,
  optimizeManualMenu,
} from "./actions";
import { loadOptimizedInfoSaga } from "containers/OptimizedMenu/sagas";
import { COMMUNITY, MANUAL, OPTIMIZED } from "containers/MyMenus/labels";
import { SKIP_FOOD_ITEM_PRICE_CHECK, transformForBackend } from "./utils";

export const duplicatePath = (type, menuId, optionClone, customHistoryState) => {
  let path;

  switch (type) {
    case MANUAL:
      if (optionClone === "6" || optionClone === "7") {
        path = routesPath.results;
      } else {
        path = routesPath.duplicateManual;
      }
      break;
    case COMMUNITY:
      path = routesPath.duplicateCommunity;
      break;
    default:
      if (optionClone === "6") {
        path = routesPath.results;
      } else {
        path = routesPath.duplicateOptimized;
      }
  }

  return push(path.replace(":id", menuId), customHistoryState);
};

export function* loadMenuSaga({ payload: { id, callback } }) {
  try {
    yield put(loadStarted());
    const data = yield call(getMenu, id);
    yield put(loadSuccess({ data }));
    yield put(loadEnded());
    if (data && callback) callback(data);
    return data;
  } catch (e) {
    if (callback) callback({ error: true });
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadCommunityMenuSaga({ id, callback }) {
  try {
    yield put(loadStarted());
    const data = yield call(getCommunityMenu, id);
    const nutrients = yield call(loadPartialDiversitySaga, id);
    yield put(loadSuccess({ data: { ...data, nutritional_requirements: nutrients } }));
    yield put(loadEnded());
    if (data && callback) callback(data);
    return data;
  } catch (e) {
    if (callback) callback({ error: true });
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* createMenuSaga({ payload: { body, noAlert } }) {
  try {
    yield put(loadStarted());
    const data = yield call(postMenu, body);
    yield put(loadSuccess({ data }));
    yield put(loadEnded());
    if (!noAlert)
      yield put(
        setAlert({
          type: "success",
          text: messages.menuCreated,
          title: messages.genericSuccess,
        })
      );
    return data;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* createCommunityMenuSaga(body, noAlert) {
  try {
    yield put(loadStarted());
    const data = yield call(postCommunityMenu, body);
    yield put(loadSuccess({ data }));
    yield put(loadEnded());
    if (!noAlert)
      yield put(
        setAlert({
          type: "success",
          text: messages.menuCreated,
          title: messages.genericSuccess,
        })
      );
    return data;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* updateMenuSaga({ payload: { id, body, callback, noAlert = false } }) {
  try {
    yield put(loadStarted());
    const data = yield call(updateMenuCall, id, body);
    yield put(loadSuccess({ data }));
    yield put(loadEnded());
    if (data && callback) callback(data.id);
    if (!noAlert)
      yield put(
        setAlert({
          type: "success",
          text: messages.menuUpdated,
          title: messages.genericSuccess,
        })
      );
    return data;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* updateCommunityMenuSaga({ id, body, callback }, noAlert) {
  try {
    yield put(loadStarted());
    const data = yield call(patchCommunityMenu, id, body);
    yield put(loadSuccess({ data }));
    yield put(loadEnded());
    if (data && callback) callback(data.id);
    if (!noAlert)
      yield put(
        setAlert({
          type: "success",
          text: messages.menuUpdated,
          title: messages.genericSuccess,
        })
      );
    return data;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* copyMenuSaga({ payload: { id, body } }) {
  try {
    const { type, optionClone } = body;
    const optimizedSecondPage = type === OPTIMIZED && optionClone === "8";
    yield put(loadStarted());
    // We don't need optionClone in the body of the request
    delete body["optionClone"];
    const { menu } = yield call(postCopyMenu, id, body);
    yield put(loadSuccess({ data: menu }));
    yield put(loadEnded());
    yield put(duplicatePath(type, menu.id, optionClone, optimizedSecondPage ? { defaultPage: 1 } : undefined));
    // We need to fetch the optimized info data when redirecting to the second step of the menu creation form (for optimized)
    if (optimizedSecondPage) {
      yield call(loadOptimizedInfoSaga, { payload: { id } });
    }
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* optimizeManualMenuSaga({ payload: { id, body } }) {
  try {
    const { type } = body;
    yield put(loadStarted());
    const { menu } = yield call(postOptimizeManualMenu, id, body);
    yield put(loadSuccess({ data: menu }));
    yield put(loadEnded());
    yield put(duplicatePath(type, menu.id));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* partialCopyMenuSaga({ payload: { id, body } }) {
  try {
    const { type, optionClone } = body;
    yield put(loadStarted());
    // We don't need optionClone in the body of the request
    delete body["optionClone"];
    const { menu } = yield call(postPartialCopyMenu, id, body);
    yield put(loadSuccess({ data: menu }));
    yield put(loadEnded());
    yield put(duplicatePath(type, menu.id, optionClone));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* copyCommunityMenuAsOptimizedSaga({ payload: { id, body, options } }) {
  try {
    // 1. Set loading...
    yield put(loadStarted());

    // If user accepted to have food items with missing food price, duplicate menu and change route
    if (options?.acceptUnpricedFood) {
      const response = yield call(postCommunityMenuAsOptimized, id, body, SKIP_FOOD_ITEM_PRICE_CHECK);
      yield put(loadEnded());
      yield put(duplicatePath(OPTIMIZED, response.menu.id));
      return;
    }

    // 2. ...then, start API call
    const { status, menu, reason, items } = yield call(postCommunityMenuAsOptimized, id, body);

    // 3. If status === "KO", we need to inform the user.
    if (status === "KO") {
      yield put(copyCommunityMenuAsOptimizedKo({ status, reason, items }));
      yield put(loadEnded());
      return;
    }

    yield put(loadEnded());
    yield put(duplicatePath(OPTIMIZED, menu.id));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* createOrUpdateMenuSaga({ payload: { menuId, menuType, body, callback } }) {
  const info = { ...transformForBackend(body, { hasBasket: true }), type: menuType };

  try {
    yield put(loadStarted());
    const data = menuId
      ? yield* updateMenuSaga({ payload: { id: menuId, body: { ...info, update_food_basket: true }, noAlert: true } })
      : yield* createMenuSaga({
          payload: { body: info, noAlert: true },
        });
    if (data && callback) callback(data.id);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* createOrUpdateCommunityMenuSaga({ payload: { callback } }) {
  const values = yield select(selectCommunityFormValues);
  const formFoodSources = values.preliminary_information.food_sources;
  const food_sources = Object.keys(formFoodSources).filter((foodSource) => formFoodSources[foodSource]);

  const { id } = values;
  const info = transformForBackend({
    ...values,
    preliminary_information: {
      ...values.preliminary_information,
      food_sources,
    },
    type: COMMUNITY,
  });

  try {
    const data = id
      ? yield* updateCommunityMenuSaga({ id, body: { ...info, update_food_basket: true } }, true)
      : yield* createCommunityMenuSaga({ ...info, name: `menu-${format(new Date(), "P-k:mm")}` }, true);

    // No id? Then we just created menu, so we push nutritional requirements right after.
    if (!id) {
      const nutrient_restrictions = Object.keys(values.nutritional_requirements).map((key) => ({
        ...values.nutritional_requirements[key],
      }));

      const body = {
        menu_general_parameter: {},
        nutrient_restrictions,
        sourcing_constraints: [],
      };
      yield call(postCommunityPartialPlusMenu, data.id, body);
    }

    if (data && callback) callback(data.id);
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* shareMenuSaga({ payload: { id, body, cb } }) {
  try {
    yield put(loadStarted());
    yield call(postShareMenu, id, body);
    yield* _loadMyMenus();
    yield put(loadEnded());
    yield put(
      setAlert({
        type: "success",
        text: messages.menuShared,
        title: messages.genericSuccess,
      })
    );
    if (cb) cb();
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* menuSaga() {
  yield takeLatest(loadMenuRequest, loadMenuSaga);
  yield takeLatest(updateMenu, updateMenuSaga);
  yield takeLatest(createMenu, createMenuSaga);
  yield takeLatest(createOrUpdateMenuRequest, createOrUpdateMenuSaga);
  yield takeLatest(copyMenu, copyMenuSaga);
  yield takeLatest(partialCopyMenu, partialCopyMenuSaga);
  yield takeLatest(shareMenu, shareMenuSaga);
  yield takeLatest(createOrUpdateCommunityMenu, createOrUpdateCommunityMenuSaga);
  yield takeLatest(copyCommunityMenuAsOptimized, copyCommunityMenuAsOptimizedSaga);
  yield takeLatest(optimizeManualMenu, optimizeManualMenuSaga);
}
