import { useMemo, useCallback } from "react";
import { useController } from "react-hook-form";
import { colors, Icon } from "@wfp/ui";
import { iconCaretDown } from "@wfp/icons";

import MenuList from "./MenuList";
import { UISelect } from "./styles";

const SelectHookForm = ({
  control,
  name,
  label,
  helperText,
  options = [],
  wfpStyle,
  className = "",
  customOnChange,
  virtualized = false,
  ...rest
}) => {
  const { field } = useController({ name, control });

  const selectedValue = useMemo(() => options.find((el) => el.value === field.value) ?? "", [field.value, options]);

  const components = useMemo(
    () =>
      wfpStyle
        ? {
            DropdownIndicator: () => <Icon fill={colors["brand-01"].hex} icon={iconCaretDown} description="" />,
            IndicatorSeparator: () => null,
          }
        : virtualized
        ? { MenuList }
        : {},
    [virtualized, wfpStyle],
  );

  const handleChange = useCallback(
    (el) => {
      if (customOnChange) {
        customOnChange(el, field);
      } else {
        field.onChange(el.value);
      }
    },
    [customOnChange, field],
  );

  return (
    <div className="wfp--form-item">
      {label && (
        <label htmlFor={name} className="wfp--label">
          {label}
        </label>
      )}
      {helperText && <div className="wfp--form__helper-text">{helperText}</div>}
      <UISelect
        {...rest}
        id={name}
        onChange={handleChange}
        wfpStyle={wfpStyle}
        components={components}
        className={`wfp--react-select-container ${className}`}
        classNamePrefix="wfp--react-select"
        value={selectedValue}
        options={options}
      />
    </div>
  );
};

export default SelectHookForm;
