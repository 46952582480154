import React from "react";

export const ChartView = () => (
  <svg width="58px" height="20px" viewBox="0 0 58 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="#5A6872"
        d="M4,0 L54,0 C56.209139,-4.05812251e-16 58,1.790861 58,4 L58,16 C58,18.209139 56.209139,20 54,20 L4,20 C1.790861,20 2.705415e-16,18.209139 0,16 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M54,18 C55.1045695,18 56,17.1045695 56,16 L56,4 C56,2.8954305 55.1045695,2 54,2 L29,2 L29,18 L54,18 Z M50,4.92307692 L50,15.0769231 C50,15.5867244 49.5522847,16 49,16 L38,16 C37.4477153,16 37,15.5867244 37,15.0769231 L37,4.92307692 C37,4.41327562 37.4477153,4 38,4 L49,4 C49.5522847,4 50,4.41327562 50,4.92307692 Z M49,4.92307692 L38,4.92307692 L38,6.76923077 L49,6.76923077 L49,4.92307692 Z M49,15.0769231 L49,13.2307692 L38,13.2307692 L38,15.0769231 L49,15.0769231 Z M49,12.3076923 L49,10.4615385 L38,10.4615385 L38,12.3076923 L49,12.3076923 Z M49,9.53846154 L49,7.69230769 L38,7.69230769 L38,9.53846154 L49,9.53846154 Z M18.8740679,15.0181373 L18.8740679,5.69509804 L15.3943662,5.69509804 L15.3943662,15.0181373 L13.6545153,15.0181373 L13.6545153,9.08529412 L10.1748136,9.08529412 L10.1748136,15.0181373 L8.86992543,15.0181373 L8.86992543,4 L8,4 L8,15.0181373 C8,15.4862257 8.38947888,15.8656863 8.86992543,15.8656863 L20.1789561,15.8656863 L20.1789561,15.0181373 L18.8740679,15.0181373 Z M12.7845899,15.0181373 L11.044739,15.0181373 L11.044739,9.93284314 L12.7845899,9.93284314 L12.7845899,15.0181373 Z M18.0041425,15.0181373 L16.2642916,15.0181373 L16.2642916,6.54264706 L18.0041425,6.54264706 L18.0041425,15.0181373 Z"
      />
    </g>
  </svg>
);
