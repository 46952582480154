import { useCallback, useMemo, useEffect } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text, InlineLoading } from "components";
import { AsyncSelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import {
  loadSummaryPricesRequest,
  loadFilteredFCTFoodItemsRequest,
  loadFCTFoodItemsRequest,
} from "containers/FoodBasket/actions";
import { FIELDS } from "containers/FoodBasket/constants";
import messages from "containers/FoodBasket/messages";
import NutritionalValues from "containers/FoodBasket/NutritionalValues";
import {
  selectLoadingSummaryPrices,
  selectSummary,
  selectFoodItems,
  selectLoadingFoodItems,
  selectFCTFoodItems,
  selectLoadingFCTFoodItems,
} from "containers/FoodBasket/selectors";
import { getSummaryPricesFilters } from "containers/FoodBasket/utils";
import { useResponsiveHook } from "hooks";
// import Warning from "./Warning";
import { ChooseFromGlobalBasketContainer } from "./styles";

const ChooseFromGlobalBasket = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { control, getValues } = useFormContext();
  const foodItem = useWatch({ control, name: `${FIELDS.PERSONAL_ITEM}.${FIELDS.ITEM}` });
  const { isTabletOrMobile } = useResponsiveHook();
  const foodItems = useSelector(selectFoodItems);
  const loadingFoodItems = useSelector(selectLoadingFoodItems);
  const fctFoodItems = useSelector(selectFCTFoodItems);
  const loadingFCTFoodItems = useSelector(selectLoadingFCTFoodItems);
  const summary = useSelector(selectSummary);
  const loadingSummaryPrices = useSelector(selectLoadingSummaryPrices);

  useEffect(() => {
    dispatch(loadFCTFoodItemsRequest());
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const foodItemsOptions = useMemo(() => [...foodItems, ...fctFoodItems], [fctFoodItems, foodItems, foodItem.value]);

  const nutritionalValues = useMemo(() => R.pathOr([], ["nutritional_values"], summary), [summary]);

  // Handle nutritional values in order to show them in the corresponding grid
  const data = useMemo(
    () => nutritionalValues.map((el) => ({ ...el, label: el.nutrient_display })),
    [nutritionalValues],
  );

  const handleFoodItemChange = useCallback(
    async (el, field) => {
      // Do not change if new value is same as old one
      if (el.value === field.value) return;
      await field.onChange(el.value);

      const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
      dispatch(loadSummaryPricesRequest({ itemId: el.value, filters }));
    },
    [dispatch, getValues],
  );

  const renderNutritionalValues = useMemo(() => {
    if (loadingSummaryPrices) return <InlineLoading />;
    if (!summary || R.isEmpty(foodItem)) return null;

    const itemsPerRow = isTabletOrMobile ? 2 : 4;

    return (
      <NutritionalValues title={intl.formatMessage(messages.nutritionalValues)} data={data} itemsPerRow={itemsPerRow} />
    );
  }, [data, foodItem, intl, isTabletOrMobile, loadingSummaryPrices, summary]);

  const loadFCTFoodItemsOptions = useCallback(
    (text, callback) => {
      dispatch(loadFilteredFCTFoodItemsRequest({ text, callback }));
    },
    [dispatch],
  );

  const styles = useMemo(
    () => ({
      // Selected option
      singleValue: (styles, { data }) => ({
        ...styles,
        color: data.from_fct ? colors["categorical-corporate-08"].hex : colors["text-01"].hex,
      }),
      // All available options
      option: (styles, { data }) => ({
        ...styles,
        color: data.from_fct ? colors["categorical-corporate-08"].hex : colors["text-01"].hex,
      }),
    }),
    [],
  );

  return (
    <ColStyled xs={4}>
      <ChooseFromGlobalBasketContainer>
        <Text
          bold
          fontSize="16px"
          defaultColor={colors["text-02"].hex}
          value={intl.formatMessage(messages.chooseFromGlobalBasket)}
        />
        <RowStyled margin="1.5rem 0 0 0">
          <AsyncSelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.ITEM}`}
            isLoading={loadingFCTFoodItems || loadingFoodItems}
            defaultOptions={foodItemsOptions}
            label={intl.formatMessage(messages.foodItem)}
            loadOptions={loadFCTFoodItemsOptions}
            customOnChange={handleFoodItemChange}
            styles={styles}
          />
        </RowStyled>

        {/* TODO: Don't know how to check if this warning should be displayed or not. Ask Raul about it. */}
        {/* <RowStyled margin="0.5rem 0 0 0">
          <ColStyled xs padding="0">
            <Warning />
          </ColStyled>
        </RowStyled> */}

        {renderNutritionalValues}
      </ChooseFromGlobalBasketContainer>
    </ColStyled>
  );
};

export default ChooseFromGlobalBasket;
