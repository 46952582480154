import styled from "styled-components";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { RowStyled } from "components/utils";
import { defaultColumnNumber } from "./constants";

// https://www.youtube.com/watch?v=KrPz_wmBsAE
// Edited Codepen: https://codepen.io/gguidotti/pen/qBVeoYm
export const ItemsContainer = styled.div`
  columns: ${defaultColumnNumber};
  gap: 1em;
  padding: ${({ padding = "15px 15px 40px" }) => padding};
  border: 1px solid ${colors["ui-04"].hex};

  & > * {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
`;

export const TitleContainer = styled(RowStyled)`
  background: #edf0f3;
  padding: 0.75rem;
`;

export const TitleAndCount = styled(Text)`
  display: flex;
  flex-wrap: nowrap;
`;

export const Group = styled.span`
  margin-right: 5px;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FoodItemContainer = styled(RowStyled)`
  background: #fff;
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid #edf0f3;
  cursor: pointer;
`;

export const FoodItemLabel = styled.span`
  font-size: 16px;
  color: ${({ color }) => color};
`;
