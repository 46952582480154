import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { INITIAL_VALUES } from "./constants";
import { selectMenus } from "./selectors";

export const useInitialValues = () => INITIAL_VALUES;

// Define form default values and reset its slices when specific data changes
export const useAsyncForm = () => {
  const menus = useSelector(selectMenus);

  const formValues = useInitialValues();
  const useFormApi = useForm({ defaultValues: formValues });

  // Change form default values every time our menus list changes
  useEffect(() => {
    useFormApi.reset({ ...useFormApi.getValues() });
  }, [menus, useFormApi]);

  return useMemo(() => ({ useFormApi }), [useFormApi]);
};
