import { useEffect, useMemo, useRef } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFormContext } from "react-hook-form";

import { selectProfile, selectPriceSources } from "containers/App/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import selectMenu, { selectMenuId } from "containers/Menu/selectors";
import { useCheckUserPermissions } from "hooks";
import { getMenuMode, getMenuType, getPermissionToBeChecked } from "utils/utils";
import { loadGeneralInfo, loadFoodBasket } from "./actions";
import { useListenForChanges } from "./hooks";
import MenuFieldsAndFoodBasket from "./MenuFieldsAndFoodBasket";
import PriceDataOriginsSection from "./PriceDataOriginsSection";
import TargetGroupAndDuration from "./TargetGroupAndDuration";

const GeneralForm = ({ menuId }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { getValues } = useFormContext();

  const firstMount = useRef(true);
  const menuType = useMemo(() => getMenuType(pathname), [pathname]);
  const menuMode = useMemo(() => getMenuMode(pathname), [pathname]);

  useListenForChanges(menuType, firstMount);

  const menu = useSelector(selectMenu());
  const idMenu = useSelector(selectMenuId);
  const profile = useSelector(selectProfile);
  const priceSources = useSelector(selectPriceSources);

  const permissionToBeChecked = useMemo(() => getPermissionToBeChecked(menuMode, menuType), [menuMode, menuType]);

  const { redirectUser, userPermissions } = useCheckUserPermissions(permissionToBeChecked);

  useEffect(() => {
    // Fetch only if we checked for user permissions.
    if (!R.isEmpty(userPermissions) && redirectUser === false) {
      dispatch(loadGeneralInfo({ id: menuId || idMenu }));
    }
  }, [dispatch, idMenu, menuId, userPermissions, redirectUser]);

  useDeepCompareEffect(() => {
    // Fetch only if we checked for user permissions.
    if (!R.isEmpty(userPermissions) && redirectUser === false) {
      if (menuId || idMenu) {
        if (!R.isNil(menu)) {
          firstMount.current = false;
          dispatch(loadFoodBasket({ body: { ...menu, type: menuType } }));
        }
      } else {
        // Check that both "profile" and "priceSources" are not empty, which means that we correctly initialized all data.
        if (!R.isEmpty(priceSources) && !R.isEmpty(profile)) {
          firstMount.current = false;
          dispatch(
            loadFoodBasket({
              body: {
                ...R.pick([FIELDS.PRELIMINARY_INFORMATION, FIELDS.MENU_PRICING_LIST, FIELDS.MENU_NAME], getValues()),
                type: menuType,
              },
            }),
          );
        }
      }
    }
  }, [dispatch, idMenu, getValues, menu, menuId, menuType, priceSources, profile, redirectUser, userPermissions]);

  if (redirectUser) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <TargetGroupAndDuration />
      <MenuFieldsAndFoodBasket />
      <PriceDataOriginsSection />
    </>
  );
};

export default GeneralForm;
