import * as R from "ramda";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { CheckboxHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useResponsiveHook } from "hooks";

const PriceDataOrigins = ({ priceSources }) => {
  const { isTabletOrMobile } = useResponsiveHook();
  const { control } = useFormContext();

  const menuPricingList = useWatch({ control, name: FIELDS.MENU_PRICING_LIST });

  return (
    <>
      {!R.isNil(menuPricingList) && (
        <RowStyled margin="0 0 30px">
          {priceSources.map((priceSource, index) => (
            <ColStyled key={priceSource.value} xs={!index ? 2 : isTabletOrMobile ? 4 : 3}>
              <Text marginBottom="5px" fontSize="16px" bold value={priceSource.label} wrap />

              {priceSource.checkboxes.map((origin, j) => (
                <CheckboxHookForm
                  key={`${FIELDS.MENU_PRICING_LIST}.${priceSource.value}.${origin.value}`}
                  control={control}
                  name={`${FIELDS.MENU_PRICING_LIST}.${priceSource.value}.${origin.value}`}
                  labelText={origin.label}
                  id={`${FIELDS.MENU_PRICING_LIST}.${priceSource.value}.${origin.value}`}
                />
              ))}
            </ColStyled>
          ))}
        </RowStyled>
      )}
    </>
  );
};

export default PriceDataOrigins;
