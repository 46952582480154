import Title from "./Title";
import FoodItem from "./FoodItem";

const FoodGroup = ({ title, items, openModal }) => (
  <div>
    <Title value={title} itemsCount={items.length} />
    {items.map((item) => (
      <FoodItem key={item.value} item={item} openModal={openModal} />
    ))}
  </div>
);

export default FoodGroup;
