import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import { Title } from "./styles";

const RenameMenu = ({ setEditMode }) => {
  const { control, setValue } = useFormContext();

  const draftMenuName = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.MENU_NAME}` });

  const rename = useCallback(() => {
    setValue(FIELDS.MENU_NAME, draftMenuName);
    setEditMode(false);
  }, [draftMenuName, setEditMode, setValue]);

  const handleChange = useCallback(
    (e) => setValue(`${FIELDS.DRAFT}.${FIELDS.MENU_NAME}`, e.currentTarget.value),
    [setValue],
  );

  const disableRenameButton = useMemo(() => !draftMenuName, [draftMenuName]);

  return (
    <RowStyled middle="xs" center="xs" margin="0 5px">
      <ColStyled xs={10}>
        <Title defaultValue={draftMenuName} onChange={handleChange} />
      </ColStyled>
      <ColStyled xs={2} className="center-xs">
        <Button disabled={disableRenameButton} kind="secondary" onClick={rename}>
          <FormattedMessage {...messages.rename} />
        </Button>
      </ColStyled>
    </RowStyled>
  );
};

export default RenameMenu;
