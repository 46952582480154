import React from "react";

import FirstSection from "./FirstSection";

const LandingPage = () => {
  return (
    <div>
      <FirstSection />
    </div>
  );
};

export default LandingPage;
