import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { MultiSelectHookForm } from "components/Form";
import { selectTargetBeneficiaries } from "containers/GeneralInfo/GeneralForm/selectors";
import { GridArea } from "./styles";
import { FIELDS } from "containers/MyMenus/constants";

const Target = () => {
  const { control } = useFormContext();
  const targetBeneficiaries = useSelector(selectTargetBeneficiaries);

  return (
    <GridArea area="1 / 4 / 2 / 5">
      <MultiSelectHookForm control={control} name={FIELDS.TARGET} options={targetBeneficiaries} />
    </GridArea>
  );
};

export default Target;
