import styled from "styled-components";
import { Module, ModuleHeader, colors } from "@wfp/ui";
import { RowStyled } from "components/utils";
import ReactPlayer from "react-player";
import { Text } from "components";

export const Container = styled.div`
  background-color: rgba(44, 52, 57, 0.5);
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
`;

export const Content = styled.div`
  background: white;
  float: right;
  height: 100%;
  width: ${({ width }) => width || "35vw"};
  min-width: 380px;
  overflow: auto;
  padding: ${({ padding }) => padding || "0px 30px 20px"};
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ withBorder }) => withBorder && `border-left: 10px solid ${colors["brand-01"].hex}`};
`;

export const HelpButton = styled.div`
  top: 15%;
  right: 0%;
  position: ${({ position }) => position || "fixed"};
  height: 40px;
  width: 40px;
  background-color: ${colors["brand-01"].hex};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${colors["ui-01"].hex} !important;
  }
`;

export const HelpModule = styled(Module)`
  padding: 0;
  .wfp--module__inner {
    box-shadow: none;
  }
  .wfp--module__content {
    padding: 1.2rem 1.2rem;
  }
`;

export const HelpModuleHeader = styled(ModuleHeader)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  .wfp--module__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .wfp--tabs {
    width: fit-content;
  }
  .wfp--tabs__nav-item > a {
    padding-bottom: 1.2rem !important;
    padding-top: 1.2rem !important;
  }
  .wfp--tabs__nav__bar {
    z-index: 0;
  }
`;

export const Header = styled(RowStyled)`
  margin: 0;
  position: sticky;
  top: 0;
  padding: 10px 0;
  background: white;
  z-index: 1;
  svg {
    margin-top: 10px;
    cursor: pointer;
  }
`;

export const ContainerVideo = styled(RowStyled)`
  position: relative;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  background: ${colors["ui-03"].hex};
`;

export const ContainerDocument = styled(RowStyled)`
  margin: 10px 0 0;
  align-items: center;
`;

export const ReactPlayerWrapper = styled(ReactPlayer)`
  height: auto !important;
  aspect-ratio: 16/9;
`;

export const VideoPlaceholder = styled.div`
  margin: 0 10px 0 0;
  height: 90px;
  width: 160px;
  flex-shrink: 0;
  background: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${colors["ui-02"].hex};
  }
`;

export const TextWrapper = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
