import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { Text, View } from "@react-pdf/renderer";

import messages from "containers/Menu/messages";
import { targeted, seasonality } from "containers/Menu/utils";
import generalStyles from "containers/PDF/styles";

const Header = ({ preliminaryInfo }) => {
  const intl = useIntl();
  const { header, description, row } = generalStyles;
  const basicSettings = R.pathOr("", ["preliminary_information"], preliminaryInfo);
  const seasonalityMonths = useMemo(() => seasonality(basicSettings, false, intl), [basicSettings, intl]);
  const target = useMemo(() => targeted(basicSettings), [basicSettings]);
  const country = useMemo(() => R.pathOr("", ["country_name"], basicSettings), [basicSettings]);

  const regionsNames = useMemo(() => {
    const { regions } = basicSettings;
    if (R.isEmpty(regions) || !regions) return intl.formatMessage(messages.allRegions);
    return intl.formatMessage(messages.regions, { regions: regions.join(", ") });
  }, [basicSettings, intl]);

  const menuDate = useMemo(() => {
    return intl.formatDate(new Date(), { day: "numeric", month: "long", year: "numeric" });
  }, [intl]);

  return (
    <View style={header}>
      <Text>{R.pathOr("", ["name"], preliminaryInfo)}</Text>
      <View style={row}>
        <Text style={description}>{seasonalityMonths}</Text>
        <Text style={description}>{` | ${target} | ${country}, `}</Text>
        <Text style={description}>{regionsNames}</Text>
      </View>
      <Text style={description}>
        {intl.formatMessage(messages.on, { name: R.pathOr("", ["owner_name"], preliminaryInfo), date: menuDate })}
      </Text>
    </View>
  );
};

export default Header;
