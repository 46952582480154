import React, { useCallback, useRef } from "react";
import * as R from "ramda";
import { Accordion, Text } from "components";
import { ColStyled, RowStyled } from "components/utils";

const FaqsPage = ({ tutorials }) => {
  const $accordionRef = useRef({ current: {} });
  const onChange = useCallback(() => $accordionRef.current.scrollIntoView(), []);
  const formatFaq = useCallback((tutorial) => {
    return R.pathOr([], ["faq"], tutorial).map((el) => ({
      title: <Text fontSize="16px" bold wrap value={el.question} />,
      titleCustomPadding: 0,
      content: <Text fontSize="14px" wrap marginLeft="10px" marginBottom="5px" marginTop="-5px" value={el.answer} />,
    }));
  }, []);
  return (
    <ColStyled padding="0">
      {Object.values(tutorials || {}).map(
        (tutorial, index) =>
          R.pathOr([], ["faq"], tutorial).length > 0 && (
            <div data-test-id="tutorial-item-container" key={index} style={{ marginBottom: "1rem" }}>
              <Text fontSize="18px" bold value={tutorial.title} marginBottom="0.5rem" />
              <RowStyled between="xs">
                <Accordion
                  onChange={onChange}
                  padding="0 0 10px"
                  fontSize="16px"
                  fields={formatFaq(tutorial)}
                  firstExpanded={index === 0}
                  allowMultipleExpanded
                  ref={$accordionRef}
                />
              </RowStyled>
            </div>
          )
      )}
    </ColStyled>
  );
};

export default FaqsPage;
