import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { Text } from "components";
import { colors } from "@wfp/ui";
import { Box } from "./styles";
import { loaderWrapperStyle } from "components/utils";

export default function BoxNumber({ loading, value, text }) {
  return (
    <Box padding="10px 5px">
      {loading ? (
        <ThreeDots color={colors["brand-01"].hex} height={30} width={30} wrapperStyle={loaderWrapperStyle} />
      ) : (
        <Text fontSize="21.5px" bold value={value} />
      )}
      <Text wrap value={text} />
    </Box>
  );
}
