import { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { isInclusivelyBeforeDay } from "react-dates";
import { useFormContext } from "react-hook-form";
import moment from "moment";

import { Text } from "components";
import { DateRangeHookForm } from "components/Form";
import { ColStyled } from "components/utils";
import { GridArea, FiltersWrapper } from "./styles";
import { FIELDS, INITIAL_VALUES } from "containers/MyMenus/constants";
import messages from "containers/MyMenus/messages";

const DateRange = () => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState(INITIAL_VALUES[FIELDS.DATE][FIELDS.DATE_FROM]);
  const [endDate, setEndDate] = useState(INITIAL_VALUES[FIELDS.DATE][FIELDS.DATE_TO]);
  const { control } = useFormContext();

  const onDatesChange = useCallback(({ startDate, endDate, onChangeField }) => {
    onChangeField({ [FIELDS.DATE_FROM]: startDate, [FIELDS.DATE_TO]: endDate });
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  return (
    <GridArea area="2 / 3 / 3 / 5" alignSelf="end">
      <ColStyled xs={6} margin="0 0 0.5rem 0.75rem" padding="0">
        <Text bold value={intl.formatMessage(messages.dateRangeTitle)} />
      </ColStyled>
      <FiltersWrapper>
        <DateRangeHookForm
          control={control}
          name={FIELDS.DATE}
          labelText={intl.formatMessage(messages.dateRangeLabel)}
          startDate={startDate}
          startDateId="startDateId"
          endDate={endDate}
          endDateId="endDateId"
          onDatesChange={onDatesChange}
          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        />
      </FiltersWrapper>
    </GridArea>
  );
};

export default DateRange;
