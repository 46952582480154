import styled from "styled-components";
import { colors } from "@wfp/ui";
import { mediaTablet } from "components/utils";
import { SecondaryNavigationLarger } from "components/UIKitStyled";

export const UISecondaryNavigation = styled(SecondaryNavigationLarger)`
  margin: -20px -30px 0;
  padding: ${({ padding }) => padding || "0 30px"};
  border-bottom: 1px solid ${colors["ui-04"].hex};
  ${mediaTablet} {
    padding: ${({ padding }) => padding || "0 40px"};
  }
`;
