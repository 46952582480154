import { useIntl } from "react-intl";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { useMonths } from "utils/utils";
import { TooltipContainer } from "./styles";
import messages from "containers/FoodBasket/messages";

const CustomTooltip = ({ dataKey, unitLabel, currencyLabel, active, payload }) => {
  const intl = useIntl();
  const data = payload[1];
  const months = useMonths(intl);

  return (
    active &&
    payload && (
      <TooltipContainer>
        <Text transform="capitalize" bold value={months[data.payload.month - 1].label} />
        <Text bold value={intl.formatMessage(messages.averageFor, { value: unitLabel.replace(" ", "") })} />
        <Text bold value={`${currencyLabel} ${data.payload[dataKey]}`} defaultColor={colors["brand-01"].hex} />
      </TooltipContainer>
    )
  );
};
export default CustomTooltip;
