import styled, { css } from "styled-components";
import { colors } from "@wfp/ui";

import { RowStyled } from "components/utils";

export const SchedulerContainer = styled(RowStyled)`
  margin: 30px 0;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.4rem;
`;

export const DayContainer = styled(RowStyled)`
  display: flex;
  flex: 1;
  padding: 10px;

  & + & {
    border-left: 1px solid ${colors["ui-04"].hex};
  }
`;

export const SmallButton = styled.div`
  border: 2px solid ${({ color }) => color};
  background-color: white;
  color: ${({ color }) => color};
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  max-width: 70px;
  font-weight: 600;
  padding: 3px 5px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `};
  ${({ selected, color }) =>
    selected &&
    css`
      color: white;
      background-color: ${color};
    `};

  :hover {
    ${({ selected, color }) =>
      !selected &&
      css`
        color: white;
        background-color: ${color};
      `};
  }
`;
