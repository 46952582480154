import { useIntl } from "react-intl";
import { Text, View, Image } from "@react-pdf/renderer";

import messages from "containers/Menu/messages";
import generalStyles from "containers/PDF/styles";
import styles from "./styles";

const SourcingChannels = ({ imageUrl }) => {
  const intl = useIntl();
  const { fontSize12 } = generalStyles;
  const { section } = styles;

  return (
    <View style={section}>
      <Text style={[fontSize12, { marginTop: 3 }]}>{intl.formatMessage(messages.averageSourcing)}</Text>
      {imageUrl && <Image style={{ width: "100%", marginTop: 5, objectFit: "contain" }} src={imageUrl} />}
    </View>
  );
};

export default SourcingChannels;
