import { colors } from "@wfp/ui";
import styled from "styled-components";

export const NutritionalValuesContainer = styled.div`
  grid-area: graph;
  border: 1px solid ${colors["ui-04"].hex};
  border-radius: 0.5rem;
`;

export const GraphForPDF = styled.div`
  background: white;
  width: 638px;
  height: 388px;
  z-index: -1000;
`;
