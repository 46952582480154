import React, { useCallback } from "react";
import { injectIntl } from "react-intl";
import { ModalStyled } from "components/UIKitStyled";
import messages from "components/Button/messages";
import FieldsPriceInfo from "./FieldsPriceInfo";
import { useForm } from "react-final-form";
import { useResponsiveHook } from "hooks";

function ModalAddEditPrice({ open, setModal, currencies, values, intl }) {
  const form = useForm();
  const { isTabletOrMobile } = useResponsiveHook();

  const closeModalAndReset = useCallback(() => {
    // Substitute values.foods with values.draftFoods, the initial ones. Added timeout to hide flickering of values while closing modal.
    setTimeout(() => form.change("foods", values.draftFoods), 100);
    setModal(false);
  }, [form, values.draftFoods, setModal]);

  const onModalSubmit = useCallback(() => {
    // Substitute values.draftFoods with values.foods, the new ones. This way, if we reopen the modal we have the correct starting foods in both places.
    setTimeout(() => form.change("draftFoods", values.foods), 100);
    setModal(false);
  }, [form, values.foods, setModal]);

  return (
    <ModalStyled
      modalHeading={intl.formatMessage(messages.addEditPrices)}
      onRequestSubmit={onModalSubmit}
      onRequestClose={closeModalAndReset}
      onSecondarySubmit={closeModalAndReset}
      open={open}
      maxWidth={isTabletOrMobile ? "92%" : "84%"}
      values={values}
      primaryButtonText={intl.formatMessage(messages.save)}
      secondaryButtonText={intl.formatMessage(messages.cancel)}
    >
      {Object.keys(values.foods).map((label) => (
        <FieldsPriceInfo
          key={values.foods[label].display_name}
          foodItem={values.foods[label]}
          currencies={currencies}
        />
      ))}
    </ModalStyled>
  );
}

export default injectIntl(ModalAddEditPrice);
