import { useMemo, useCallback } from "react";
import * as R from "ramda";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { SelectHookForm, NumberInputHookForm, TextInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { FIELDS } from "containers/Results/constants";
import messages from "containers/Results/messages";
import { ModalContentContainer } from "../styles";
import { orderByTotalPricePerGram } from "./utils";

const SelectExistingRecipe = ({ availableRecipes }) => {
  const { control, setValue } = useFormContext();
  const selectedRecipeId = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.SELECTED_RECIPE}` });

  const selectedRecipe = useMemo(
    () => availableRecipes.find(({ value }) => value === selectedRecipeId),
    [availableRecipes, selectedRecipeId],
  );

  const handleRecipeIdChange = useCallback(
    async (el, field) => {
      // Do not change if new value is same as old one
      if (el.value === field.value) return;

      const selectedRecipe = availableRecipes.find(({ value }) => value === el.value);

      const ingredients = Object.keys(selectedRecipe.recipe_basket).reduce((acc, key) => {
        const currentItem = selectedRecipe.recipe_basket[key];

        return {
          ...acc,
          [`_${key}`]: {
            quantity: currentItem.quantity,
            ingredient: R.head(orderByTotalPricePerGram(currentItem.basket)).display_name,
          },
        };
      }, {});

      setValue(`${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}.${FIELDS.INGREDIENTS}`, ingredients);
      await field.onChange(el.value);
    },
    [availableRecipes, setValue],
  );

  return (
    <ModalContentContainer>
      <RowStyled>
        <ColStyled xs={6}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.DRAFT}.${FIELDS.SELECTED_RECIPE}`}
            options={availableRecipes}
            label={<FormattedMessage {...messages.recipe} />}
            customOnChange={handleRecipeIdChange}
          />
        </ColStyled>
        <ColStyled xs={6}>
          <TextInputHookForm
            control={control}
            name={`${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}.${FIELDS.DESCRIPTION}`}
            labelText={<FormattedMessage {...messages.description} />}
          />
        </ColStyled>
      </RowStyled>
      {!R.isNil(selectedRecipeId) && (
        <ModalContentContainer>
          <Text bold marginBottom="10px" value={<FormattedMessage {...messages.ingredients} />} />
          {Object.entries(selectedRecipe.recipe_basket).map(([key, outerBasket]) => (
            <RowStyled key={`ingredient-${key}`} margin="0 0 10px">
              <ColStyled xs={6}>
                <SelectHookForm
                  control={control}
                  name={`${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}.${FIELDS.INGREDIENTS}._${key}.${FIELDS.INGREDIENT}`}
                  label={<FormattedMessage {...messages.itemAndSource} />}
                  options={outerBasket.basket}
                />
              </ColStyled>
              <ColStyled xs={6}>
                <NumberInputHookForm
                  control={control}
                  name={`${FIELDS.DRAFT}.${FIELDS.EXISTING_RECIPE}.${FIELDS.INGREDIENTS}._${key}.${FIELDS.QUANTITY}`}
                  labelText={<FormattedMessage {...messages.portionSize} />}
                  step={1}
                />
              </ColStyled>
            </RowStyled>
          ))}
        </ModalContentContainer>
      )}
    </ModalContentContainer>
  );
};

export default SelectExistingRecipe;
