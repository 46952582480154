import { HEIGHT_CHART } from "./constants";
import { BarChart, Container } from "./styles";
import CartesianGrid from "./CartesianGrid";
import Nutrient from "./Nutrient";

const NutritionalGraph = ({ nutrients, name }) => {
  return (
    <div>
      <Container height={HEIGHT_CHART}>
        <CartesianGrid />
        <BarChart>
          {nutrients.map((nutrient, i) => (
            <Nutrient key={nutrient.name} name={`${name}.${i}`} nutrient={nutrient} />
          ))}
        </BarChart>
      </Container>
    </div>
  );
};

export default NutritionalGraph;
