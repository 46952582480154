export const FIELDS = {
  NUTRIENTS: "nutrients",
  SOURCES: "sources",
  PRIORITY_ITEMS: "priority_items",
  LABEL: "label",
  CHECKED: "checked",
  NUTRIENTS_LEVEL: "community_nutrients_level",
  PERCENTAGE: "pct",
  VALUE: "value",
  REGIONS: "regions",
  VAM_MAPPINGS: "vam_mappings",
  ITEM: "item",
};

export const DEFAULT_NUTRIENT_PERCENTAGE = 30;
