import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import { RowStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import { FIELDS } from "containers/Results/constants";
import { validation } from "./validation";

const Footer = ({ close, save }) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const dayComposition = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_COMPOSITION}` });
  const dayRecipes = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.DAY_RECIPES}` });

  const validate = useMemo(() => validation(dayComposition, dayRecipes), [dayComposition, dayRecipes]);

  return (
    <RowStyled end="xs" padding="20px 10px">
      <Button
        widthAuto
        marginInlineEnd="5px"
        kind="secondary"
        onClick={close}
        children={intl.formatMessage(buttonMessages.cancel)}
      />
      <Button widthAuto disabled={validate} onClick={save} children={intl.formatMessage(buttonMessages.save)} />
    </RowStyled>
  );
};

export default Footer;
