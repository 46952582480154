import { useCallback } from "react";
import { useController } from "react-hook-form";
import { RadioButton } from "@wfp/ui";

const RadioButtonHookForm = ({ control, id, name, value, customOnChange, labelText, checked, ...rest }) => {
  const { field } = useController({ name, control });

  const handleChange = useCallback(
    (el) => {
      if (customOnChange) {
        customOnChange(el, field);
      } else {
        field.onChange(el.target.value);
      }
    },
    [customOnChange, field]
  );

  return (
    <RadioButton
      {...rest}
      name={name}
      value={value}
      id={id}
      checked={checked ? checked : field.value === value}
      onChange={handleChange}
      labelText={labelText}
    />
  );
};

export default RadioButtonHookForm;
