import React, { useCallback, useState } from "react";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import { useSelector } from "react-redux";
import { ModalStyled } from "components/UIKitStyled";
import { useForm } from "react-final-form";
import { withModalHandling } from "hocs";
import { withRouter } from "react-router-dom";
import FoodSource from "./FoodSource";
import Footer from "./Footer";
import messages from "components/Button/messages";
import { FIELDS } from "../../utils";
import { selectFoodSources } from "containers/App/selectors";

function ModalFoodSources({ open, label, setStatusModal, intl }) {
  const foodSources = useSelector(selectFoodSources);
  const form = useForm();
  const [initial] = useState(form.getState().values.preliminary_information.food_sources);

  const submitModal = useCallback(() => {
    setStatusModal(false);
  }, [setStatusModal]);

  const resetAndClose = useCallback(() => {
    form.change(`${FIELDS.PRELIMINARY_INFO}.${FIELDS.FOOD_SOURCES}`, initial);
    setStatusModal(false);
  }, [form, initial, setStatusModal]);

  return (
    <ModalStyled
      modalHeading={label}
      primaryButtonText={intl.formatMessage(messages.save)}
      onRequestSubmit={submitModal}
      onRequestClose={resetAndClose}
      open={open}
      width="100%"
      inPortal={false}
      height={`${window.innerHeight - 30}px`}
      modalFooter={(props) => <Footer {...props} />}
    >
      {foodSources.map((foodSource) => (
        <FoodSource key={`field-${foodSource.value}`} foodSource={foodSource} />
      ))}
    </ModalStyled>
  );
}

export default compose(withModalHandling, withRouter, injectIntl)(ModalFoodSources);
