import React from "react";

export const GridView = () => (
  <svg height="20" viewBox="0 0 58 20" width="58" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#5a6872"
      fillRule="evenodd"
      d="m4 0h50c2.209139 0 4 1.790861 4 4v12c0 2.209139-1.790861 4-4 4h-50c-2.209139 0-4-1.790861-4-4v-12c0-2.209139 1.790861-4 4-4zm0 2c-1.1045695 0-2 .8954305-2 2v12c0 1.1045695.8954305 2 2 2h25v-16zm15.8740679 13.0181373h1.3048882v.847549h-11.30903067c-.48044655 0-.86992543-.3794606-.86992543-.847549v-11.0181373h.86992543v11.0181373h1.30488817v-5.93284318h3.4797017v5.93284318h1.7398509v-9.32303926h3.4797017zm-6.089478 0v-5.08529416h-1.7398509v5.08529416zm5.2195526 0v-8.47549024h-1.7398509v8.47549024zm30.9958575-10.09506038c0-.5098013-.4477153-.92307692-1-.92307692h-11c-.5522847 0-1 .41327562-1 .92307692v10.15384618c0 .5098013.4477153.9230769 1 .9230769h11c.5522847 0 1-.4132756 1-.9230769zm-1 0v1.84615385h-11v-1.84615385zm0 10.15384618h-11v-1.8461539h11zm0-2.7692308h-11v-1.8461538h11zm0-2.76923076h-11v-1.84615385h11z"
    />
  </svg>
);
