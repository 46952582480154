import React, { useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { iconAddGlyph } from "@wfp/icons";
import { Button, Unlock } from "components";
import messages from "components/Button/messages";
import { RowStyled, ColStyled } from "components/utils";
import Modals from "containers/OptionsAndModals/Modals";
import { authorizedFileDownload } from "api/api";
import PDF from "containers/PDF";
import { selectCurrency, selectUsername } from "containers/App/selectors";
import ModalAddEditPrice from "./ModalAddEditPrice";
import { resetDuplicationData } from "containers/Menu/actions";
import selectMenu, { selectRegions } from "containers/Menu/selectors";
import { PERMISSIONS } from "containers/Admin/constants";
import { useHtmlHook } from "hooks";
import { ACTIONS } from "containers/OptionsAndModals/constants";

function ActionButtons({ menuName, menuId, enableButtons, enableAddEditButton, values, handleSubmit }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isRTL } = useHtmlHook();

  const [menuAndAction, setMenuAndAction] = useState();
  const [modal, setModal] = useState(false);
  const [exporting, setExporting] = useState(false);

  const currencyInfo = useSelector(selectCurrency);
  const regions = useSelector(selectRegions);
  const menu = useSelector(selectMenu());
  const username = useSelector(selectUsername);

  const createdByMe = useMemo(() => R.pathOr("", ["owner_details", "username"], menu) === username, [menu, username]);

  const currencies = useMemo(() => {
    if (!currencyInfo) return;
    const { to_currency, from_currency } = currencyInfo;
    return [
      { value: to_currency, label: to_currency },
      { value: from_currency, label: from_currency },
    ];
  }, [currencyInfo]);

  const onClickShare = useCallback(() => {
    setMenuAndAction({ id: menuId, action: ACTIONS.SHARE, name: menuName });
  }, [menuId, menuName]);

  const onClickExport = useCallback(() => {
    const cb = () => {
      setExporting(false);
    };

    setExporting(true);
    authorizedFileDownload({
      uri: `/api/v1/menus/${menuId}/export/`,
      fileName: `${menuName}_${menuId}`,
      cb,
    });
  }, [menuId, menuName]);

  const onClickClone = useCallback(() => {
    setMenuAndAction({
      id: menuId,
      action: ACTIONS.CLONE,
      name: menuName,
      regions,
    });
  }, [menuId, menuName, setMenuAndAction, regions]);

  const toggleModal = useCallback(() => setModal(!modal), [modal]);

  const onCloseModal = useCallback(() => {
    dispatch(resetDuplicationData());
  }, [dispatch]);

  const buttonStyles = useMemo(() => {
    return isRTL ? { marginLeft: "10px" } : { marginRight: "10px" };
  }, [isRTL]);

  return (
    <RowStyled middle="xs" margin="10px 5px 5px">
      <ColStyled xs className="end-xs">
        <Unlock permission={PERMISSIONS.CHANGE_COMMUNITY_MENU}>
          <Button
            widthAuto
            disabled={!enableAddEditButton}
            icon={iconAddGlyph}
            iconReverse
            {...buttonStyles}
            kind="secondary"
            onClick={toggleModal}
            children={intl.formatMessage(messages.addEditPrices)}
          />
        </Unlock>
        {createdByMe && (
          <Button
            widthAuto
            kind="secondary"
            {...buttonStyles}
            onClick={onClickShare}
            children={intl.formatMessage(messages.share)}
          />
        )}
        <Unlock permission={PERMISSIONS.CHANGE_COMMUNITY_MENU}>
          <Button
            widthAuto
            {...buttonStyles}
            kind="secondary"
            onClick={handleSubmit}
            children={intl.formatMessage(messages.save)}
          />
        </Unlock>
        <Button
          widthAuto
          disabled={enableButtons}
          {...buttonStyles}
          kind="secondary"
          onClick={onClickClone}
          children={intl.formatMessage(messages.duplicate)}
        />
        <Button
          widthAuto
          disabled={enableButtons}
          kind="secondary"
          {...buttonStyles}
          onClick={onClickExport}
          children={exporting ? intl.formatMessage(messages.loading) : intl.formatMessage(messages.exportExcel)}
        />
        <PDF page="results" disabled={enableButtons} />
      </ColStyled>

      <Modals menuAndAction={menuAndAction} setMenuAndAction={setMenuAndAction} onClose={onCloseModal} />

      {values.foods && Object.keys(values.foods).length > 0 && (
        <ModalAddEditPrice open={modal} setModal={setModal} menuId={menuId} currencies={currencies} values={values} />
      )}
    </RowStyled>
  );
}

export default ActionButtons;
