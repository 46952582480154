import compareLoader from "./Compare";
import resultsLoader from "./Results";

export const RESULTS = "results";
export const COMPARE = "compare";

export const loadMapping = {
  [RESULTS]: resultsLoader,
  [COMPARE]: compareLoader,
};

export const mappingName = {
  results: (name) => `${name}.pdf`,
  compare: (_, intl) => intl.formatMessage({ id: "pdf.compareMenus", defaultMessage: "menu_comparison.pdf" }),
};

export const downloadBlob = (blob, name = "file.txt") => {
  const blobUrl = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  document.body.removeChild(link);
};
