import { defineMessages } from "react-intl";

export default defineMessages({
  foodBasket: {
    id: "page.foodBasket",
    defaultMessage: "Food basket",
  },
  foodPricesLower: {
    id: "page.foodPricesLower",
    defaultMessage: "food prices",
  },
  menus: {
    id: "page.menus",
    defaultMessage: "Menus",
  },
  admin: {
    id: "page.admin",
    defaultMessage: "Admin",
  },
  djangoAdmin: {
    id: "page.djangoAdmin",
    defaultMessage: "Global",
  },
  users: {
    id: "page.users",
    defaultMessage: "Users",
  },
  countries: {
    id: "page.countries",
    defaultMessage: "Countries",
  },
  pages: {
    id: "page.pages",
    defaultMessage: "Pages",
  },
  pending: {
    id: "inactiveUser.pending",
    defaultMessage: "Dear {name}, your registration is pending approval.",
  },
  ableToLog: {
    id: "inactiveUser.ableToLog",
    defaultMessage: "You will be able to log in once your request has been approved.",
  },
  moreInfo: {
    id: "inactiveUser.moreInfo",
    defaultMessage: "For more information, please contact your WFP focal point.",
  },
  wait: {
    id: "inactiveUser.wait",
    defaultMessage: "Wait a second",
  },
  processing: {
    id: "inactiveUser.processing",
    defaultMessage: "We are processing your request.",
  },
  notFound: {
    id: "page.notFound",
    defaultMessage: "Sorry, we couldn't find that page",
  },
  acceptCookie: {
    id: "cookie.acceptCookie",
    defaultMessage: "Accept",
  },
  ariaAcceptCookie: {
    id: "cookie.ariaAcceptCookie",
    defaultMessage: "Accept cookies",
  },
  declineCookie: {
    id: "cookie.declineCookie",
    defaultMessage: "Decline",
  },
  ariaDeclineCookie: {
    id: "cookie.ariaDeclineCookie",
    defaultMessage: "Decline cookies",
  },
  cookieMessage: {
    id: "cookie.cookieMessage",
    defaultMessage: "This website uses cookies to enhance the user experience.",
  },
  uncaughtError: {
    id: "error.uncaughtError",
    defaultMessage: "An error occurred. Please try reloading the website by hitting the button below.",
  },
});
