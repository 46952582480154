import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { colors, Icon } from "@wfp/ui";
import { iconDelete } from "@wfp/icons";
import { useFormContext, useFieldArray } from "react-hook-form";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { SelectHookForm, NumberInputHookForm, CheckboxHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import {
  selectRegions,
  selectWeightChoices,
  selectFoodSources,
  selectOrigins,
  selectAdditionalCostChoices,
  selectRoles,
} from "containers/App/selectors";
import { ROLES } from "containers/Admin/constants";
import { FIELDS, ADDITIONAL_COSTS_INITIAL_VALUES, years } from "containers/FoodBasket/constants";
import messages from "containers/FoodBasket/messages";
import { useInitialValues } from "containers/FoodBasket/useInitialValues";
import generalFormMessages from "containers/GeneralInfo/GeneralForm/messages";
import { useCurrencies } from "hooks";
import { useMonths } from "utils/utils";

const PriceFields = ({ showProposedCheckbox }) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const initialValues = useInitialValues();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${FIELDS.PERSONAL_ITEM}.${FIELDS.ADDITIONAL_COSTS}`,
  });
  const regions = useSelector(selectRegions);
  const units = useSelector(selectWeightChoices);
  const procurementSources = useSelector(selectFoodSources);
  const origins = useSelector(selectOrigins);
  const additionalCosts = useSelector(selectAdditionalCostChoices);
  const roles = useSelector(selectRoles);
  const currencies = useCurrencies();
  const months = useMonths(intl);

  const isGlobalAdmin = useMemo(() => roles.includes(ROLES.GLOBAL_ADMIN), [roles]);
  const isCountryAdmin = useMemo(() => roles.includes(ROLES.COUNTRY_ADMIN), [roles]);

  const addAdditionalCost = useCallback(() => {
    append(ADDITIONAL_COSTS_INITIAL_VALUES);
  }, [append]);

  return (
    <>
      <RowStyled>
        <ColStyled xs={3}>
          <NumberInputHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.PRICE}`}
            labelText={intl.formatMessage(messages.foodPrice)}
            defaultValue={initialValues[FIELDS.PERSONAL_ITEM][FIELDS.PRICE]}
          />
        </ColStyled>
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.CURRENCY}`}
            options={currencies}
            label={intl.formatMessage(messages.currency)}
          />
        </ColStyled>
        <ColStyled xs={3}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.UNIT}`}
            options={units}
            label={intl.formatMessage(messages.unit)}
          />
        </ColStyled>
      </RowStyled>

      <RowStyled margin="1.5rem 0 0 0.5rem">
        <Button
          widthAuto
          onClick={addAdditionalCost}
          children={intl.formatMessage(buttonMessages.addAdditionalPrice)}
        />
      </RowStyled>

      {fields.map((field, i) => (
        <RowStyled key={field.id} margin="1.5rem 0 0 0">
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.ADDITIONAL_COSTS}.${i}.${FIELDS.TYPE}`}
              options={additionalCosts}
              label={intl.formatMessage(messages.type)}
            />
          </ColStyled>
          <ColStyled xs={3}>
            <NumberInputHookForm
              control={control}
              name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.ADDITIONAL_COSTS}.${i}.${FIELDS.PRICE}`}
              labelText={intl.formatMessage(messages.price)}
            />
          </ColStyled>
          <ColStyled xs={2}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.ADDITIONAL_COSTS}.${i}.${FIELDS.UNIT}`}
              options={[{ value: "%", label: "%" }, ...currencies]}
              label={intl.formatMessage(messages.currency)}
            />
          </ColStyled>
          <ColStyled xs={1}>
            <Icon
              className="pointer paddingTopSmall"
              description={intl.formatMessage(buttonMessages.remove)}
              icon={iconDelete}
              width="23"
              height="23"
              fill={colors["brand-01"].hex}
              onClick={() => remove(i)}
            />
          </ColStyled>
        </RowStyled>
      ))}

      <RowStyled margin="1.5rem 0 0 0">
        <ColStyled xs={6}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.REGION}`}
            options={regions}
            label={intl.formatMessage(messages.region)}
            placeholder={intl.formatMessage(generalFormMessages.placeholderCountry)}
          />
        </ColStyled>
        <ColStyled xs={6}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.PROCUREMENT_SOURCES}`}
            options={procurementSources}
            label={intl.formatMessage(messages.procurementSource)}
          />
        </ColStyled>
      </RowStyled>

      <RowStyled margin="1.5rem 0 0 0">
        <ColStyled xs={6}>
          <SelectHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.ORIGIN}`}
            options={origins}
            label={intl.formatMessage(messages.priceCollectedHow)}
          />
        </ColStyled>
        <ColStyled xs={6}>
          <label className="wfp--label" style={{ display: "block" }}>
            {intl.formatMessage(messages.priceCollectedWhen)}
          </label>
          <RowStyled>
            <ColStyled xs={6} padding="0 0.25rem 0 0">
              <SelectHookForm control={control} name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.MONTH}`} options={months} />
            </ColStyled>
            <ColStyled xs={6} padding="0 0 0 0.25rem">
              <SelectHookForm control={control} name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.YEAR}`} options={years} />
            </ColStyled>
          </RowStyled>
        </ColStyled>
      </RowStyled>

      {showProposedCheckbox && (isGlobalAdmin || isCountryAdmin) && (
        <RowStyled margin="1.5rem 0 0 0.5rem">
          <CheckboxHookForm
            control={control}
            name={`${FIELDS.PERSONAL_ITEM}.${FIELDS.PROPOSED}`}
            labelText={intl.formatMessage(messages.proposedFoodItem)}
            id={`${FIELDS.PERSONAL_ITEM}.${FIELDS.PROPOSED}`}
          />
        </RowStyled>
      )}
    </>
  );
};

export default PriceFields;
