import * as R from "ramda";

import { FIELDS } from "containers/GeneralInfo/constants";

// Returns "true" if button will be disabled
export const validation = (source) => {
  if (!source[FIELDS.PRICE]) {
    return true;
  }

  if (R.isEmpty(source[FIELDS.SOURCE])) {
    return true;
  }

  return false;
};
