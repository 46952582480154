import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { colors, Icon, Tag } from "@wfp/ui";
import { iconEdit, iconDelete } from "@wfp/icons";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { getIcon } from "components/Icons";
import { joinStrings } from "utils/utils";
import messages from "../messages";
import { RuleContainer, RuleNameContainer } from "./styles";
import { IconStyled } from "../styles";
import { FOOD_RESTRICTION_TYPES} from "../../constants";

const ItemRule = ({
  food_item,
  food_item_name,
  majorLabel,
  id,
  repetition,
  amount_range,
  default_amount_range,
  exclude_from_group_restriction,
  restriction_type,
  matching_with,
  unmatched_with,
  editRule,
  deleteRule,
}) => {
  const intl = useIntl();

  const repetitionMenu = useMemo(() => {
    if (!repetition || (restriction_type !== FOOD_RESTRICTION_TYPES.INCLUDE)) return "";
    return intl.formatMessage(messages.rangeMeals, { value: `${repetition[0]}-${repetition[1]}` });
  }, [intl, repetition, restriction_type]);

  const portionSize = useMemo(() => {
    if (!amount_range || R.equals(amount_range, default_amount_range)) return "";
    return intl.formatMessage(messages.rangePortionSize, { minPortion: amount_range[0], maxPortion: amount_range[1] });
  }, [amount_range, default_amount_range, intl]);

  const unmatchedWith = useMemo(() => {
    if (!unmatched_with || R.isEmpty(unmatched_with)) return "";
    return intl.formatMessage(messages.unmatchedWith, { items: unmatched_with.join(", ") });
  }, [intl, unmatched_with]);

  const recipeWith = useMemo(() => {
    if (!matching_with || R.isEmpty(matching_with)) return "";
    return intl.formatMessage(messages.recipeWith, { items: matching_with.join(", ") });
  }, [intl, matching_with]);

  const sentence = useMemo(() => {
    const constraints = [repetitionMenu, unmatchedWith, recipeWith, portionSize].filter(Boolean);
    return joinStrings(constraints, constraints.pop(), intl);
  }, [intl, portionSize, recipeWith, repetitionMenu, unmatchedWith]);

  let isIncluded = restriction_type === FOOD_RESTRICTION_TYPES.INCLUDE;
  let isExcluded = restriction_type === FOOD_RESTRICTION_TYPES.EXCLUDE;
  let isDefault = restriction_type === FOOD_RESTRICTION_TYPES.DEFAULT;

  return (
    <RuleContainer middle="xs" padding="16px 8px">
      <ColStyled xs={4}>
        <RuleNameContainer>
          {majorLabel && <IconStyled marginInlineEnd="0">{getIcon(majorLabel) && getIcon(majorLabel)()}</IconStyled>}
          <div>
            <Text bold fontSize="16px" value={majorLabel} />
              {isIncluded && exclude_from_group_restriction && <Tag type="info" children={intl.formatMessage(messages.exception)} />}
              {isIncluded && <Text wrap bold value={food_item_name}/>}
              {isExcluded && <Text wrap bold value={food_item_name} defaultColor={colors["text-02"].hex} decoration="line-through" />}
              {isDefault && <Text bold value={`${food_item_name} (Custom Default Value)`} wrap />}

          </div>
        </RuleNameContainer>
      </ColStyled>
      <ColStyled xs={6}>
        <Text wrap value={!R.isEmpty(sentence) ? `${sentence};` : ""} />
      </ColStyled>
      <ColStyled xs={2} className="end-xs">
        <RowStyled end="xs">
          <div onClick={() => editRule({ item_id: food_item }, food_item_name)}>
            <Icon className="pointer" icon={iconEdit} fill={colors["brand-01"].hex} />
          </div>
          <div onClick={() => deleteRule("item_id", food_item)}>
            <Icon
              className="pointer"
              icon={iconDelete}
              fill={colors["categorical-corporate-07"].hex}
              style={{ marginLeft: "20px" }}
            />
          </div>
        </RowStyled>
      </ColStyled>
    </RuleContainer>
  );
};

export default ItemRule;
