import messages from "./messages";
import routesPath from "containers/App/Routes/paths";
import { Text } from "components";
import FoodItem from "./VamFoodPrices/FoodItem";
import { COMMUNITY, MANUAL, OPTIMIZED } from "containers/MyMenus/labels";

const calculatePixelCol = (size, isTabletOrMobile) => ((window.innerWidth - (isTabletOrMobile ? 0 : 62)) / 7) * size;

export const intlTabs = (intl) => [
  {
    label: intl.formatMessage(messages.countriesFirstTab),
    href: routesPath.adminCountriesNutrients,
    secondaryTabs: [
      {
        label: intl.formatMessage(messages.nutrientsFirstSecondaryTab),
        href: routesPath.adminCountriesOptimizedNutrients,
      },
      {
        label: intl.formatMessage(messages.nutrientsSecondSecondaryTab),
        href: routesPath.adminCountriesCommunityNutrients,
      },
      {
        label: intl.formatMessage(messages.nutrientsThirdSecondaryTab),
        href: routesPath.adminCountriesCommunityNutrientsLevel,
      },
    ],
  },
  {
    label: intl.formatMessage(messages.countriesSecondTab),
    href: routesPath.adminCountriesSources,
    secondaryTabs: [
      {
        label: intl.formatMessage(messages.sourcesFirstSecondaryTab),
        href: routesPath.adminCountriesOptimizedSources,
      },
      {
        label: intl.formatMessage(messages.sourcesSecondSecondaryTab),
        href: routesPath.adminCountriesCommunitySources,
      },
    ],
  },
  {
    label: intl.formatMessage(messages.countriesThirdTab),
    href: routesPath.adminCountriesPriorityItems,
    secondaryTabs: [],
  },
  {
    label: intl.formatMessage(messages.countriesFourthTab),
    href: routesPath.adminCountriesVam,
    secondaryTabs: [],
  },
  {
    label: intl.formatMessage(messages.countriesFifthTab),
    href: routesPath.adminCountriesPriceApproval,
    secondaryTabs: [],
  },
  {
    label: intl.formatMessage(messages.countriesSixthTab),
    href: routesPath.adminCountriesFoodItems,
    secondaryTabs: [],
  },
  {
    label: intl.formatMessage(messages.countriesSeventhTab),
    href: routesPath.adminCountriesRecipes,
    secondaryTabs: [],
    acceptsIdParam: true,
  },
];

export const getMenuType = (pathname) => {
  if (pathname.includes("optimized")) {
    return OPTIMIZED;
  } else if (pathname.includes("manual")) {
    return MANUAL;
  } else {
    return COMMUNITY;
  }
};

export const getNutrientsDescription = (intl, menuType) => {
  if (menuType === OPTIMIZED) {
    return intl.formatMessage(messages.optimizedNutrientsDescription);
  } else {
    return intl.formatMessage(messages.communityNutrientsDescription);
  }
};

export const getSourcesDescription = (intl, menuType) => {
  if (menuType === OPTIMIZED) {
    return intl.formatMessage(messages.optimizedSourcesDescription);
  } else {
    return intl.formatMessage(messages.communitySourcesDescription);
  }
};

export const getPropertyName = (menuType) => {
  if (menuType === OPTIMIZED) {
    return "optimized_nutrients";
  } else {
    return "community_nutrients";
  }
};

export const reworkPriorityItems = (priorityItems, originalPriorityItems) => {
  return priorityItems.map((item) => {
    let resultItem = {};

    // If one of these items came from BE, make use of original id
    const alreadyExistingItem = originalPriorityItems.find((origItem) => origItem.item_id === item.value);

    if (alreadyExistingItem) {
      resultItem = { id: alreadyExistingItem.id };
    }

    return {
      ...resultItem,
      item_id: item.value,
      regions: item.regions.map(({ value, label, ...rest }) => ({ id: value, region: label, ...rest })),
    };
  });
};

export const getColumns = (intl, isTabletOrMobile) => {
  return [
    {
      Header: intl.formatMessage(messages.description),
      accessor: "vam_commodity",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { value } }) => <Text fontSize="16px" value={value.description} />,
    },
    {
      Header: intl.formatMessage(messages.foodItem),
      disableSortBy: true,
      accessor: "item",
      width: calculatePixelCol(1.6, isTabletOrMobile),
      Cell: ({ cell: { row } }) => <FoodItem index={row.index} />,
    },
  ];
};
