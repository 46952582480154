import { forwardRef } from "react";
import { colors } from "@wfp/ui";
import { iconChevronDown } from "@wfp/icons";
import {
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { Text } from "components";
import { AccordionStyled, AccordionItemButtonStyled, IconStyled } from "./styles";

const NewAccordion = forwardRef(
  (
    {
      allowMultipleExpanded,
      fields,
      withNoArrow,
      withBorderBottom = true,
      preExpanded,
      firstExpanded,
      className,
      onChange,
      customPadding = undefined,
    },
    ref
  ) => (
    <AccordionStyled
      key={String(preExpanded || firstExpanded)}
      className={className}
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded
      preExpanded={preExpanded || (firstExpanded ? ["accordion-0"] : [])}
      onChange={onChange}
      fields={fields}
      padding={customPadding}
    >
      {fields.map((el, index) => (
        <div ref={ref} key={`accordion-${el.uuid || ""}-${index}`}>
          <AccordionItem uuid={`accordion-${(el.uuid && el.uuid.replace(/\s/g, "")) || index}`}>
            <AccordionItemHeading>
              <AccordionItemButtonStyled withBorderBottom={withBorderBottom} padding={el.titleCustomPadding}>
                <Text bold fontSize={el.titleFontSize || "18px"} value={el.title} />
                {!withNoArrow && (
                  <AccordionItemState>
                    {({ expanded }) => (
                      <IconStyled
                        description={expanded ? "shrink" : "expand"}
                        expanded={String(expanded)}
                        icon={iconChevronDown}
                        fill={colors["brand-01"].hex}
                        width="15"
                        height="15"
                      />
                    )}
                  </AccordionItemState>
                )}
              </AccordionItemButtonStyled>
            </AccordionItemHeading>
            <AccordionItemPanel>{el.content}</AccordionItemPanel>
          </AccordionItem>
        </div>
      ))}
    </AccordionStyled>
  )
);

export default NewAccordion;
