import { useIntl } from "react-intl";
import { View, Text, Image } from "@react-pdf/renderer";

import messages from "containers/Menu/nutMessages";
import { renameVitamins } from "containers/Menu/utils";
import SpacedInRow from "containers/PDF/SpacedInRow";
import generalStyles from "containers/PDF/styles";
import styles from "./styles";

const NutritionalValues = ({ imageUrl, nutrients = [], reqNotMet }) => {
  const intl = useIntl();
  const { row, fontSize12, column, fontSize9 } = generalStyles;
  const { containerList, tag, section } = styles;

  return (
    <View style={[section, { padding: "10 15", maxHeight: 310, justifyContent: "space-between" }]}>
      <Text style={[fontSize12, { marginTop: 3 }]}>{intl.formatMessage(messages.averageValues)}</Text>
      {reqNotMet && <Text style={tag}>{intl.formatMessage(messages.notMet)}</Text>}
      <View style={[row, { marginTop: 5 }]}>
        {imageUrl && <Image style={[column, { objectFit: "contain", width: "75%" }]} src={imageUrl} />}
        <View style={containerList}>
          {nutrients.map(({ label, percentage }) => (
            <SpacedInRow
              key={`nutrient-${label}`}
              heightRow={17}
              textTransform="capitalize"
              fontSizeStyle={fontSize9}
              label={renameVitamins(label)}
              value={`${percentage}%`}
            />
          ))}
        </View>
      </View>
    </View>
  );
};

export default NutritionalValues;
