import styled, { css } from "styled-components";
import { StepNavigationItem as NavigationItem, FormWizard } from "@wfp/ui";
import { colors } from "@wfp/ui";

export const FormWizardHorizontal = styled(FormWizard)`
  flex-direction: column;
  background-color: var(--background-color);
  .wfp--step-navigation__nav {
    flex-direction: row;
    width: 670px;
    margin: 0 auto;
  }
`;

export const StepNavigationItem = styled(NavigationItem)`
  flex-direction: column;
  flex: 1;
  cursor: default;
  text-align: center;
  &.wfp--step-navigation__nav-item--locked .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["ui-05"].hex};
  }
  &.wfp--step-navigation__nav-item--selected .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["text-01"].hex};
  }
  &.wfp--step-navigation__nav-item--complete .wfp--step-navigation__nav-item__text {
    font-size: 16px;
    color: ${colors["text-01"].hex};
    text-decoration: none;
  }
  .wfp--step-navigation__nav-item__indicator {
    margin: 0 0 15px;
    span {
      font-weight: bold;
    }
    svg {
      width: 18px;
      height: 18px;
    }
    &::after {
      ${({ isRTL }) =>
        isRTL
          ? css`
              right: calc(-50% - 30px);
            `
          : css`
              left: calc(-50% - 30px);
            `}
      top: -10px;
      transform: rotate(90deg);
      height: 60px;
    }
  }
`;
