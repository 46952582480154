import * as R from "ramda";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { colors, Loading } from "@wfp/ui";
import { FormProvider } from "react-hook-form";

import { Layout, NewModule, NewMenuWizard } from "components";
import routesPath from "containers/App/Routes/paths";
import { DRAFT, INFEASIBLE, OPTIMIZED, RUNNING, RUNNING_SUBOPTIMAL } from "containers/MyMenus/labels";
import { FIELDS } from "containers/GeneralInfo/constants";
import Header from "containers/GeneralInfo/Header";
import GeneralForm from "containers/GeneralInfo/GeneralForm";
import { resetGeneralForm } from "containers/GeneralInfo/GeneralForm/actions";
import { useMenuGeneralParameterValidations, useUserProvidedNumbers } from "containers/GeneralInfo/GeneralForm/hooks";
import { selectLoadingBasket } from "containers/GeneralInfo/GeneralForm/selectors";
import WarningLowNumber, { showLowNumberModal } from "containers/GeneralInfo/WarningLowNumber";
import { useAsyncForm } from "containers/GeneralInfo/useInitialValues";
import { createOrUpdateMenuRequest, loadMenuRequest, resetMenu } from "containers/Menu/actions";
import { selectMenuId, selectMenuLoading, selectMenuOptimizationType } from "containers/Menu/selectors";
import { withAuth, withCountryKey } from "hocs";
import {
  loadOptimizedInfoRequest,
  savePartialAndOptimizeRequest,
  optimizeMenuWithoutSourcingRequest,
  resetOptimizedMenu,
} from "./actions";
import { WIZARD_LABELS } from "./constants";
import OptimizedModal from "./OptimizedModal";
import Rules from "./Rules";
import { resetMenuStructure } from "./Rules/FoodRules/actions";
import { selectFoodRulesLoading } from "./Rules/FoodRules/selectors";
import { selectLoading } from "./selectors";
import { reworkMenuGeneralParameter } from "./Rules/FoodRules/AdvancedSettings/utils";

const OptimizedMenu = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const defaultPage = R.pathOr(undefined, ["location", "state", "defaultPage"], history);
  
  const [currentPage, setCurrentPage] = useState(defaultPage || 0);

  const [showModalWarning, setModalWarning] = useState();
  const [showModalOptimized, setModalOptimized] = useState();
  const nextCallback = useRef();
  const intervalRef = useRef();

  const loading = useSelector(selectLoading);
  const loadingFoodBasket = useSelector(selectLoadingBasket);
  const loadingMenu = useSelector(selectMenuLoading);
  const menuId = useSelector(selectMenuId);
  const foodRulesLoading = useSelector(selectFoodRulesLoading);
  const optimizationType = useSelector(selectMenuOptimizationType);

  const currentMenuId = useMemo(() => id ?? menuId, [id, menuId]);

  const { useFormApi } = useAsyncForm(currentMenuId);

  const { groupsCount, itemsCount, customItemsCount } = useUserProvidedNumbers(useFormApi.control);
  const { errors: advancedSettingsErrors } = useMenuGeneralParameterValidations(useFormApi.control);

  const passPage = useCallback((page) => setCurrentPage(page), []);

  const closeModal = useCallback(() => setModalOptimized(false), []);
  const closeWarningModal = useCallback(() => setModalWarning(false), []);

  // Runs when clicking "Next" in "Step 1".
  const clickOnContinue = useCallback(() => {
    const callbackOnContinue = (id) => {
      if (nextCallback.current) {
        closeWarningModal();
        nextCallback.current();
        dispatch(loadOptimizedInfoRequest({ id }));
      }
    };

    const values = useFormApi.getValues();
    const body = R.pick([FIELDS.PRELIMINARY_INFORMATION, FIELDS.MENU_PRICING_LIST, FIELDS.NAME, FIELDS.BASKET], values);
    dispatch(
      createOrUpdateMenuRequest({ menuId: currentMenuId, menuType: OPTIMIZED, body, callback: callbackOnContinue })
    );
  }, [closeWarningModal, currentMenuId, dispatch, useFormApi]);

  const actionsOnNext = useMemo(
    () => ({
      [WIZARD_LABELS[0].label]: (cb) => {
        nextCallback.current = cb;
        if (showLowNumberModal(groupsCount, itemsCount + customItemsCount)) return setModalWarning(true);
        return clickOnContinue();
      },
    }),
    [clickOnContinue, customItemsCount, groupsCount, itemsCount]
  );

  // useEffect cleanup
  useEffect(() => {
    const currentIntervalRef = intervalRef.current;
    return () => clearInterval(currentIntervalRef);
  }, []);

  // Redux cleanup
  useEffect(() => {
    return () => {
      dispatch(resetMenu());
      dispatch(resetOptimizedMenu());
      dispatch(resetGeneralForm());
      dispatch(resetMenuStructure());
    };
  }, [dispatch]);

  const startPing = useCallback(
    () =>
      setInterval(() => {
        const callbackToStartPing = (data) => {
          const optimization = R.pathOr({}, ["optimization"], data);
          if (
            optimization.optimisation_end_timestamp &&
            optimization.type === INFEASIBLE &&
            !optimization.without_sourcing
          ) {
            clearInterval(intervalRef.current);
            dispatch(optimizeMenuWithoutSourcingRequest({ id: currentMenuId }));
            intervalRef.current = startPing();
          } else if (optimization.optimisation_end_timestamp || data.error) {
            clearInterval(intervalRef.current);
          }
        };
        dispatch(loadMenuRequest({ id: currentMenuId, callback: callbackToStartPing }));
      }, 2000),
    [dispatch, currentMenuId]
  );

  const advancedSettingsValidation = useMemo(() => {
    return !(loadingMenu || loadingFoodBasket) && Object.keys(advancedSettingsErrors).length !== 0 && currentPage === 1;
  }, [advancedSettingsErrors, currentPage, loadingMenu, loadingFoodBasket]);

  // Running when user hits "Submit" in last step.
  const onSubmit = useCallback(
    (values) => {
      const callback = (optimizationType) => {
        if ([DRAFT, RUNNING, RUNNING_SUBOPTIMAL, INFEASIBLE].includes(optimizationType)) {
          intervalRef.current = startPing();
          return setModalOptimized(true);
        }
        history.push(routesPath.results.replace(":id", currentMenuId));
      };

      const body = {
        menu_general_parameter: reworkMenuGeneralParameter(values[FIELDS.MENU_GENERAL_PARAMETER]),
        nutrient_restrictions: [...values[FIELDS.MICRONUTRIENTS], ...values[FIELDS.MACRONUTRIENTS]],
        sourcing_constraints: values[FIELDS.SOURCING_CONSTRAINTS],
      };

      dispatch(savePartialAndOptimizeRequest({ id: currentMenuId, body, cb: callback }));
    },
    [currentMenuId, dispatch, history, startPing]
  );

  const content = useMemo(
    () => (
      <NewMenuWizard
        hasValidationErrors={advancedSettingsValidation}
        labels={WIZARD_LABELS}
        showLoadingText={loadingMenu}
        disableSubmit={loadingMenu || loadingFoodBasket}
        actionsOnNext={actionsOnNext}
        passPage={passPage}
        handleSubmit={useFormApi.handleSubmit}
        onSubmit={onSubmit}
        defaultPage={defaultPage}
      >
        <NewMenuWizard.Page>
          <GeneralForm menuId={currentMenuId} />
        </NewMenuWizard.Page>
        <NewMenuWizard.Page>
          <Rules />
          {showModalOptimized && (
            <OptimizedModal
              closeModal={closeModal}
              showModal={showModalOptimized}
              internalStatus={optimizationType}
              menuId={currentMenuId}
            />
          )}
        </NewMenuWizard.Page>
      </NewMenuWizard>
    ),
    [
      actionsOnNext,
      closeModal,
      currentMenuId,
      loadingFoodBasket,
      loadingMenu,
      onSubmit,
      optimizationType,
      passPage,
      showModalOptimized,
      advancedSettingsValidation,
      useFormApi.handleSubmit,
      defaultPage,
    ]
  );

  return (
    <FormProvider {...useFormApi}>
      <Layout backgroundColor={colors["ui-02"].hex}>
        <Loading active={loading || foodRulesLoading} />
        <Header />
        <NewModule body={content} />
        <WarningLowNumber
          visible={showModalWarning}
          groupsCount={groupsCount}
          itemsCount={itemsCount + customItemsCount}
          clickOnContinue={clickOnContinue}
          closeModal={closeWarningModal}
        />
      </Layout>
    </FormProvider>
  );
};

export default compose(withAuth, withCountryKey)(OptimizedMenu);
