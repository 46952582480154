import { defineMessages } from "react-intl";

export default defineMessages({
  menuComposition: {
    id: "results.menuComposition",
    defaultMessage: "Menu composition",
  },
  foodGroups: {
    id: "results.foodGroups",
    defaultMessage: "Food groups",
  },
  totalMealWeight: {
    id: "results.totalMealWeight",
    defaultMessage: "Total meal weight",
  },
  foodItems: {
    id: "results.foodItems",
    defaultMessage: "Food items",
  },
  averageCostPerMeal: {
    id: "results.averageCostPerMeal",
    defaultMessage: "Avg. cost per meal",
  },
  averageCost: {
    id: "results.averageCost",
    defaultMessage: "Avg. cost",
  },
  mealScore: {
    id: "results.mealScore",
    defaultMessage: "Meal score",
  },
  menuAverageScore: {
    id: "results.menuAverageScore",
    defaultMessage: "Menu average score: {score}",
  },
  sourcingChannels: {
    id: "results.sourcingChannels",
    defaultMessage: "Sourcing channels",
  },
  addNewFoodItem: {
    id: "results.addNewFoodItem",
    defaultMessage: "Add a new food item",
  },
  addRecipe: {
    id: "results.addRecipe",
    defaultMessage: "Add recipe",
  },
  createRecipe: {
    id: "results.createRecipe",
    defaultMessage: "Create recipe",
  },
  recipe: {
    id: "results.recipe",
    defaultMessage: "Recipe",
  },
  existingRecipe: {
    id: "results.existingRecipe",
    defaultMessage: "Select existing recipe",
  },
  existingOrNewRecipe: {
    id: "results.existingOrNewRecipe",
    defaultMessage: "Select whether you want to use an existing recipe or create a new one",
  },
  name: {
    id: "results.name",
    defaultMessage: "Name",
  },
  description: {
    id: "results.description",
    defaultMessage: "Description",
  },
  addIngredient: {
    id: "results.addIngredient",
    defaultMessage: "Add ingredient",
  },
  ingredients: {
    id: "results.ingredients",
    defaultMessage: "Ingredients:",
  },
  dayAverageNutritionalValues: {
    id: "results.dayAverageNutritionalValues",
    defaultMessage: "DAY {day} - Average nutritional values",
  },
  excludingAdditional: {
    id: "results.excludingAdditional",
    defaultMessage: "{price} excluding additional costs",
  },
  viewFullPrice: {
    id: "results.viewFullPrice",
    defaultMessage: "See full price breakdown",
  },
  allMeals: {
    id: "results.allMeals",
    defaultMessage: "All meals",
  },
  addNewItemInAll: {
    id: "results.addNewItemInAll",
    defaultMessage: "Add a new food item to multiple meals",
  },
  selectMeal: {
    id: "results.selectMeal",
    defaultMessage: "Select meal",
  },
  placeholderSelect: {
    id: "results.placeholderSelect",
    defaultMessage: "Select one",
  },
  itemAndSource: {
    id: "results.itemAndSource",
    defaultMessage: "Food item and source",
  },
  portionSize: {
    id: "results.portionSize",
    defaultMessage: "Grams",
  },
  menuHistory: {
    id: "results.menuHistory",
    defaultMessage: "Menu history",
  },
  communityMenuComposition: {
    id: "results.communityMenuComposition",
    defaultMessage: "Composition",
  },
  communityMenuNutrition: {
    id: "results.communityMenuNutrition",
    defaultMessage: "Nutrition",
  },
  currentMenu: {
    id: "results.currentMenu",
    defaultMessage: "Current menu",
  },
  addFoodItem: {
    id: "results.addFoodItem",
    defaultMessage: "Add food item",
  },
  filter: {
    id: "results.filter",
    defaultMessage: "Filter",
  },
  foodGroup: {
    id: "results.foodGroup",
    defaultMessage: "Food group",
  },
  foodGroupDefaultOption: {
    id: "results.foodGroupDefaultOption",
    defaultMessage: "All",
  },
  foodItem: {
    id: "results.foodItem",
    defaultMessage: "Food item",
  },
  foodItemPlaceholder: {
    id: "results.foodItemPlaceholder",
    defaultMessage: "Filter food item...",
  },
  sort: {
    id: "results.sort",
    defaultMessage: "Sort",
  },
  nutrient: {
    id: "results.nutrient",
    defaultMessage: "Nutrient",
  },
  nutrientDefaultOption: {
    id: "results.nutrientDefaultOption",
    defaultMessage: "None",
  },
  menuCompInfo: {
    id: "results.menuCompInfo",
    defaultMessage:
      "You can add food items to the whole menu or each week. To edit the composition of each meal, navigate by clicking on the day.",
  },
  totalWeight: {
    id: "results.totalWeight",
    defaultMessage: "Total meal weight: {number} g",
  },
  dayPerMeal: {
    id: "results.dayPerMeal",
    defaultMessage: "Day {day} meal",
  },
  mainCostsDrivers: {
    id: "results.mainCostsDrivers",
    defaultMessage: "Main costs drivers",
  },
  mainCostsDriversShort: {
    id: "results.mainCostsDriversShort",
    defaultMessage: "Main costs",
  },
});
