import { useState, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Modal, InputGroup, RadioButton } from "@wfp/ui";

import { authorizedFileDownload } from "api/api";
import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { selectLanguage, selectRateFromUSD, selectLocalCurrency } from "containers/App/selectors";
import compareMenusSelector from "containers/Compare/selectors";
import selectMenu from "containers/Menu/selectors";
import { ACTIONS, DOWNLOADS } from "containers/OptionsAndModals/constants";
import selectPDF from "containers/PDF/selectors";
import { RESULTS, loadMapping, downloadBlob, mappingName } from "containers/PDF/utils";
import modalMessages from "../messages";
import { downloadOptions } from "../utils";

const Download = ({ menuAndAction, closeModal }) => {
  const intl = useIntl();

  const preliminaryInfo = useSelector(selectMenu());
  const compareMenus = useSelector(compareMenusSelector());
  const pdf = useSelector(selectPDF());
  const conversionRate = useSelector(selectRateFromUSD);
  const localCurrency = useSelector(selectLocalCurrency);
  const language = useSelector(selectLanguage);

  const [selectedOption, setSelectedOption] = useState();
  const [generating, setGenerating] = useState();

  const initPdfProcess = useCallback(async () => {
    setGenerating(true);

    const blob = await loadMapping[RESULTS]({
      preliminaryInfo,
      pdf,
      compareMenus,
      language,
      conversionRate,
      localCurrency,
      intl,
    });
    downloadBlob(blob, mappingName[RESULTS](R.pathOr("", ["name"], preliminaryInfo), intl));

    setGenerating(false);
  }, [compareMenus, conversionRate, intl, language, localCurrency, pdf, preliminaryInfo]);

  const changeOptions = useCallback((e) => setSelectedOption(e.target.value), []);

  const download = useCallback(() => {
    const { id: menuId, name: menuName } = menuAndAction;

    if (selectedOption === DOWNLOADS.EXCEL) {
      authorizedFileDownload({
        uri: `/api/v1/menus/${menuId}/export/`,
        fileName: `${menuName}_${menuId}`,
        cb: closeModal,
      });
    } else {
      initPdfProcess();
    }
  }, [closeModal, initPdfProcess, menuAndAction, selectedOption]);

  const modalFooter = useCallback(
    () => (
      <Button
        widthAuto
        disabled={!selectedOption}
        onClick={download}
        children={
          generating ? intl.formatMessage(buttonMessages.generatingFile) : intl.formatMessage(buttonMessages.download)
        }
      />
    ),
    [download, generating, intl, selectedOption],
  );

  return (
    <Modal
      open={menuAndAction && !!menuAndAction.id && menuAndAction.action === ACTIONS.DOWNLOAD}
      onRequestClose={closeModal}
      modalHeading={intl.formatMessage(modalMessages.downloadTitle)}
      modalFooter={modalFooter}
    >
      <InputGroup controlled={false} name="radio" vertical>
        {downloadOptions.map((option) => (
          <RadioButton key={option.id} {...option} onChange={changeOptions} />
        ))}
      </InputGroup>
    </Modal>
  );
};

export default Download;
