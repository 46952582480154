import React, { useCallback, useEffect } from "react";
import * as R from "ramda";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { RowStyled, ColStyled } from "components/utils";
import { Text, Button } from "components";
import { setSelectedCountry } from "containers/App/actions";
import { Container } from "./styles";
import messages from "./messages";

function Confirmation({ onClickContinue, setCountry, profile, intl }) {
  const dispatch = useDispatch();

  useEffect(() => {
    setCountry({
      label: R.pathOr("", ["country_name"], profile),
      value: R.pathOr("", ["country"], profile),
    });
  }, [profile, setCountry]);

  const onClickSecondary = useCallback(() => {
    dispatch(setSelectedCountry(false));
  }, [dispatch]);

  return (
    <Container>
      <RowStyled center="xs">
        <ColStyled xs={6}>
          <Text
            wrap
            fontSize="28px"
            value={intl.formatMessage(messages.confirmTitle, { country: R.pathOr("", ["country_name"], profile) })}
          />
          <Text wrap fontSize="16px" marginTop="10px" value={intl.formatMessage(messages.confirmDescription)} />
        </ColStyled>
      </RowStyled>
      <RowStyled center="xs">
        <ColStyled xs={4}>
          <Button onClick={onClickContinue} marginTop="20px" children={intl.formatMessage(messages.currentCountry)} />
          <Button
            kind="secondary"
            onClick={onClickSecondary}
            marginTop="20px"
            children={intl.formatMessage(messages.anotherCountry)}
          />
        </ColStyled>
      </RowStyled>
    </Container>
  );
}

export default injectIntl(Confirmation);
