import styled from "styled-components";
import { RadioButton, colors } from "@wfp/ui";

const CustomRadioButton = ({ ...props }) => <RadioButton {...props} />;

export const ValidatedRadioButton = styled(CustomRadioButton)`
  &.wfp--radio-button-invalid {
    padding: 0 0 5px 0;
    border-bottom: 1px solid ${colors["support-01"].hex};
  }
`;
