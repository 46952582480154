import { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { Document, Page, pdf } from "@react-pdf/renderer";

import styles from "containers/PDF/styles";
import messages from "locales";
import { getBase64 } from "utils/utils";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import Pagination from "../Pagination";

const getNutritionalValuesImage = () => {
  const el = document.getElementById("nutritional-values");
  return el && getBase64(el);
};

const getSourcingChannelsImage = () => {
  const el = document.getElementById("sourcing-channels");
  return el && getBase64(el);
};

export default async (props) => {
  const [imageUrlNutrients, imageUrlSources] = await Promise.all([
    getNutritionalValuesImage(),
    getSourcingChannelsImage(),
  ]);

  return await pdf(
    <IntlProvider
      locale={props.language}
      defaultLocale={"en"}
      messages={messages[props.language]}
      textComponent={Fragment}
    >
      <Document>
        <Page size="A4" style={styles.page}>
          <PageOne imageUrlNutrients={imageUrlNutrients} imageUrlSources={imageUrlSources} {...props} />
          <PageTwo {...props} />
          <Pagination />
        </Page>
      </Document>
    </IntlProvider>,
  ).toBlob();
};
