import React from "react";
import { injectIntl } from "react-intl";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "components";
import messages from "components/Button/messages";

function Footer({ onRequestSubmit, intl }) {
  const onSubmit = () => {
    onRequestSubmit();
  };

  return (
    <Row bottom="xs" end="xs">
      <Col xs>
        <Button maxwWidth onClick={onSubmit} children={intl.formatMessage(messages.save)} />
      </Col>
    </Row>
  );
}

export default injectIntl(Footer);
