import { useEffect, useMemo, useCallback } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import {
  selectFoodSources,
  selectWeightChoices,
  selectRateFromUSD,
  selectRateFromLocal,
} from "containers/App/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import { columns } from "./Columns";
import { DECIMAL_DIGITS } from "../../constants";
import CustomTable from "./CustomTable";
import Footer from "./Footer";
import { normalizePrice } from "../../PageOne/AddPersonalFoodItem/utils";

const PriceSources = ({ initialItem, item, hasAvailableSources, setOpenAddPriceSource, setStep }) => {
  const intl = useIntl();
  const procurementSources = useSelector(selectFoodSources);
  const units = useSelector(selectWeightChoices);
  const conversionRateFromUsd = useSelector(selectRateFromUSD);
  const conversionRateFromLocal = useSelector(selectRateFromLocal);

  const { setValue, getValues } = useFormContext();

  const formUnit = useMemo(() => {
    const values = getValues();
    return values[FIELDS.FILTERS][FIELDS.UNIT];
  }, [getValues]);

  const formCurrency = useMemo(() => {
    const values = getValues();
    return values[FIELDS.FILTERS][FIELDS.CURRENCY];
  }, [getValues]);

  const unit = useMemo(() => units.find((unit) => unit.value === formUnit), [formUnit, units]);
  const gramUnit = useMemo(() => units.find((unit) => unit.value === "1 g"), [units]);

  // Given filters set in Step One, update "user_provided_price" accordingly (the price shown).
  useEffect(() => {
    const values = getValues();
    const draftItem = values[FIELDS.DRAFT][FIELDS.ITEM];

    // Since we have "user_price_per_gram", we know we are starting from 1g per USD.
    // Add "user_provided_price" only if we are dealing with custom price.
    Object.entries(draftItem[FIELDS.PRICES]).forEach(([sourceKey, sourceData]) => {
      if (R.isNil(sourceData.usd_price_per_gram) || !R.isNil(sourceData.user_price_per_gram)) {
        const normalizedPrice = normalizePrice({
          from: gramUnit,
          to: unit,
          fromCurrency: "USD",
          toCurrency: formCurrency,
          price: sourceData.user_price_per_gram,
          conversionRateFromUsd,
          conversionRateFromLocal,
        });
        setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}.${FIELDS.PRICES}.${sourceKey}`, {
          ...sourceData,
          user_provided_price: normalizedPrice,
        });
      }
    });
  }, [conversionRateFromLocal, conversionRateFromUsd, formCurrency, getValues, gramUnit, setValue, unit]);

  const pricingInformations = useMemo(
    () => Object.entries(item.prices).map(([key, value]) => ({ ...value, source: key })),
    [item.prices],
  );

  const removeSource = useCallback(
    (source) => {
      const values = getValues();
      const draftItem = values[FIELDS.DRAFT][FIELDS.ITEM];
      delete draftItem[FIELDS.PRICES][source];
      setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}.${FIELDS.PRICES}`, draftItem[FIELDS.PRICES]);
    },
    [getValues, setValue],
  );

  const columnsToShow = useMemo(
    () => columns(intl, unit.conversion_rate, formCurrency, procurementSources, conversionRateFromUsd, removeSource),
    [intl, unit.conversion_rate, formCurrency, procurementSources, conversionRateFromUsd, removeSource],
  );

  const addPriceSource = useCallback(() => setOpenAddPriceSource(true), [setOpenAddPriceSource]);

  const close = useCallback(() => {
    setStep(0);
    setValue(`${FIELDS.DRAFT}.${FIELDS.ITEM}`, undefined);
  }, [setStep, setValue]);

  const save = useCallback(() => {
    const values = getValues();
    const draftItem = values[FIELDS.DRAFT][FIELDS.ITEM];

    // If no sources are left, remove the whole food item straight away.
    if (R.isEmpty(draftItem[FIELDS.PRICES])) {
      const newBasket = R.dissoc(item.id, values[FIELDS.BASKET]);
      setValue(FIELDS.BASKET, newBasket);
      close();
      return;
    }

    // Normalize "Your average price" prices.
    const normalizedPrices = Object.entries(draftItem[FIELDS.PRICES]).reduce((acc, [sourceName, sourceData]) => {
      // If not a "custom" price, do nothing.
      if (R.isNil(sourceData.user_provided_price)) return { ...acc, [sourceName]: sourceData };

      const normalizedPrice = normalizePrice({
        from: unit,
        to: gramUnit,
        fromCurrency: formCurrency,
        toCurrency: "USD",
        price: sourceData.user_provided_price,
        conversionRateFromUsd,
        conversionRateFromLocal,
        decimals: DECIMAL_DIGITS,
      });
      return { ...acc, [sourceName]: { ...sourceData, user_price_per_gram: normalizedPrice } };
    }, {});
    setValue(`${FIELDS.BASKET}.${item.id}`, { ...draftItem, prices: normalizedPrices });
    close();
  }, [
    close,
    conversionRateFromLocal,
    conversionRateFromUsd,
    formCurrency,
    getValues,
    gramUnit,
    item.id,
    setValue,
    unit,
  ]);

  const tableProps = {
    data: pricingInformations,
    columns: columnsToShow,
    page: 1,
    pageSize: 10,
    totalItems: pricingInformations.length,
    pageSizeOptions: [10, 30, 40],
    theme: "light",
    showAddButton: hasAvailableSources,
    add: addPriceSource,
  };

  return (
    <>
      <CustomTable {...tableProps} />
      <Footer initialItem={initialItem} close={close} save={save} />
    </>
  );
};

export default PriceSources;
