import React from "react";
import { Switch, Route } from "react-router-dom";
import routesPath from "containers/App/Routes/paths";
import Nutrients from "./Nutrients";

const NutrientsRouter = () => {
  return (
    <Switch>
      <Route path={routesPath.adminCountriesOptimizedNutrients} component={Nutrients} />
      <Route path={routesPath.adminCountriesCommunityNutrients} component={Nutrients} />
    </Switch>
  );
};

export default NutrientsRouter;
