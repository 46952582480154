import fr from "./fr.json";
import en from "./en.json";
import es from "./es.json";
import esPr from "./es-PR.json";
import pt from "./pt.json";
import ru from "./ru.json";
import ar from "./ar.json";
import khm from "./khm.json";

export default {
  fr,
  en,
  es,
  "es-PR": esPr,
  pt,
  ru,
  ar,
  khm,
};
