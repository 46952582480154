import React, { useEffect, useState } from "react";
import { FormattedMessage, defineMessage } from "react-intl";
import { setMonth, isSameDay, isSameYear } from "date-fns";
import { toPng } from "html-to-image";
import localForage from "localforage";
import messages from "containers/Menu/messages";
import { PERMISSIONS } from "containers/Admin/constants";
import { MANUAL, OPTIMIZED, COMMUNITY } from "containers/MyMenus/labels";
import { initialState as appInitialState } from "containers/App/reducer";

export const fixDecimals = (value) => {
  if (value < 10) return value.toFixed(3);
  if (value < 100) return value.toFixed(2);
  return value.toFixed(1);
};

export const formatDate = (value, intl) => {
  if (isSameDay(new Date(), new Date(value))) {
    return <FormattedMessage {...messages.today} />;
  }
  if (isSameYear(new Date(), new Date(value))) {
    return intl.formatDate(new Date(value), { month: "short", day: "numeric" }).replace(".", "");
  }
  return intl.formatDate(new Date(value), { month: "2-digit", day: "2-digit", year: "numeric" });
};

export const capitalize = (text) => {
  return text ? text.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase()) : "";
};

export const useRenderForcingRef = (initialValue) => {
  const [reference, registerReference] = useState(initialValue);
  registerReference.current = reference;
  return registerReference;
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (e) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      handler(e);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const getBase64 = async (node) => {
  try {
    return await toPng(node);
  } catch (e) {
    console.error("oops, something went wrong!", e);
  }
};

const joinWith = (arr, delimiter) => arr.filter(Boolean).join(delimiter);
export const joinStrings = (arr, last, intl) =>
  joinWith(
    [joinWith(arr, ", "), last],
    intl ? intl.formatMessage(defineMessage({ id: "label.and", defaultMessage: " and " })) : " and "
  );

export const useMonths = (intl, formatMonth = "long") => {
  return new Array(12).fill().map((_, index) => ({
    value: index + 1,
    label: intl.formatDate(setMonth(new Date(), index), { month: formatMonth }),
  }));
};

export const getPastYears = (startYear, numberOfYears) =>
  Array.from({ length: numberOfYears + 1 }, (_, i) => startYear - i).map((year) => ({
    value: year,
    label: year,
  }));

export const configLocalForage = () => {
  localForage.config({
    driver: localForage.INDEXEDDB,
    name: "plus_school",
    version: 1.0,
  });
};

export const readFromLocalForage = async (key) => {
  try {
    return await localForage.getItem(key);
  } catch (e) {
    console.log("oops, something went wrong!", e);
  }
};

export const writeToLocalForage = async ({ key, value }) => {
  try {
    return await localForage.setItem(key, value);
  } catch (e) {
    console.log("oops, something went wrong!", e);
  }
};

export const getMenuMode = (pathname) => {
  if (pathname.includes("edit")) {
    return "edit";
  } else if (pathname.includes("create")) {
    return "create";
  } else {
    return "duplicate";
  }
};

export const getMenuType = (pathname) => (pathname.includes(OPTIMIZED) ? OPTIMIZED : MANUAL);

export const getPermissionToBeChecked = (mode, menuType) => {
  // Create - Manual
  if (mode === "create" && menuType === MANUAL) {
    return PERMISSIONS.ADD_MANUAL_MENU;
  }
  // Create - Optimized
  if (mode === "create" && menuType === OPTIMIZED) {
    return PERMISSIONS.ADD_OPTIMIZED_MENU;
  }
  // Create - Community
  if (mode === "create" && menuType === COMMUNITY) {
    return PERMISSIONS.ADD_COMMUNITY_MENU;
  }
  // Edit - Manual
  if (mode === "edit" && menuType === MANUAL) {
    return PERMISSIONS.CHANGE_MANUAL_MENU;
  }
  // Edit - Optimized
  if (mode === "edit" && menuType === OPTIMIZED) {
    return PERMISSIONS.CHANGE_OPTIMIZED_MENU;
  }
  // Edit - Community
  if (mode === "edit" && menuType === COMMUNITY) {
    return PERMISSIONS.CHANGE_COMMUNITY_MENU;
  }

  // Since we do not have permissions in place for duplicate page, we return the "Create" permission instead
  // Duplicate - Manual
  if (mode === "duplicate" && menuType === MANUAL) {
    return PERMISSIONS.ADD_MANUAL_MENU;
  }
  // Duplicate - Optimized
  if (mode === "duplicate" && menuType === OPTIMIZED) {
    return PERMISSIONS.ADD_OPTIMIZED_MENU;
  }
  // Duplicate - Community
  if (mode === "duplicate" && menuType === COMMUNITY) {
    return PERMISSIONS.ADD_COMMUNITY_MENU;
  }
};

export const hydrateFromLocalStorage = () => {
  const language = localStorage.getItem("language");
  if (!language) {
    return {
      app: {
        ...appInitialState,
      },
    };
  }

  return {
    app: {
      ...appInitialState,
      account: {
        profile: {
          language,
        },
      },
    },
  };
};

export const reworkOrdering = (orderingArray) => {
  const orderingRule = orderingArray[0];
  return orderingRule.desc ? `-${orderingRule.id}` : orderingRule.id;
};

export const updateHtmlElement = (language) => {
  document.documentElement.lang = language === "ar" ? "ar" : "en";
  document.documentElement.dir = language === "ar" ? "rtl" : "ltr";
};

export const defaultFontStyle = {
  fontFamily: '"Open Sans", sans-serif',
};
