import styled from "styled-components";
import { Icon } from "@wfp/ui";
import { colors } from "@wfp/ui";
import { mediaTablet } from "components/utils";
import { Row } from "react-flexbox-grid";

export const ContainerGroups = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-height: ${({ moreHeight }) => moreHeight || "845px"};
  width: 100%;
  ${mediaTablet} {
    .col-xs-1 {
      flex-basis: 12.333333%;
      max-width: 12.333333%;
    }
    .col-xs-2 {
      flex-basis: 36.666667%;
      max-width: 23.666667%;
    }
    .col-xs-3 {
      flex-basis: 35%;
      max-width: 35%;
    }
    .col-xs-9 {
      flex-basis: 65%;
      max-width: 65%;
    }
    .col-xs-11 {
      flex-basis: 85.666667%;
      max-width: 85.666667%;
    }
  }
`;

export const ContainerHeader = styled(Row)`
  margin: 0;
  padding: 30px 0 10px;
  position: sticky;
  top: 0;
  background: ${colors["ui-01"].hex};
`;

export const IconStyled = styled(Icon)`
  position: absolute;
  top: 15px;
  right: -10px;
  fill: ${colors["brand-01"].hex};
  cursor: pointer;
`;
