import {IntlShape} from 'react-intl';
import { intlPriceStatusOptions } from "containers/Admin/constants";
import messages from "containers/Admin/Countries/messages";

export const PRICE_STATUS = {
  PROPOSED: "proposed",
  OFFICIAL: "official",
  REJECTED: "rejected",
};

export const allPriceApprovalFields = (intl: IntlShape, sources: {label: string, value: string}[]) => [
  {
    name: "search",
    type: "text",
    label: intl.formatMessage(messages.foodItem),
  },
  {
    name: "creator",
    type: "text",
    label: intl.formatMessage(messages.user),
  },
  {
    name: "price_status",
    type: "select",
    label: "Price status",
    options: intlPriceStatusOptions(intl),
    defaultValue: intlPriceStatusOptions(intl)[1].value,
  },
  {
    name: "source",
    type: "select",
    label: intl.formatMessage(messages.source),
    options: sources,
    defaultValue: sources[0].value,
  },
];
