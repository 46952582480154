import React, { useCallback, useMemo, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { Icon, colors, Module, ModuleHeader, ModuleBody, Loading } from "@wfp/ui";
import { iconDelete, iconChevronLeft } from "@wfp/icons";

import messages from "../../messages";
import errorMessages from "utils/messages";
import { Text, Button } from "components";
import routesPath from "containers/App/Routes/paths";
import { RowStyled, ColStyled } from "components/utils";
import buttonMessages from "components/Button/messages";
import {
  selectAdminRecipes,
  selectLoadingCountriesSlice,
  selectFoodItemOptions,
  selectAdminRecipesLoading,
} from "containers/Admin/selectors";
import {
  TextInputHookForm,
  ValidatedTextInputHookForm,
  ValidatedSelectHookForm,
  ValidatedNumberInputHookForm,
} from "components/Form";
import { FIELDS, ITEMS_INITIAL_VALUES } from "./constants";
import { RecipeContainerStyled } from "./styles";
import { createSingleAdminRecipe, loadSingleAdminRecipe, updateSingleAdminRecipe } from "containers/Admin/actions";

const toFormValues = (recipe) => {
  if (recipe) {
    const author = recipe.author.first_name + " " + recipe.author.last_name;
    const ingredients = recipe.ingredients.map((item) => ({
      [FIELDS.INGREDIENT_ID]: item.id,
      [FIELDS.FOOD_ITEM]: item.food_item.id,
      [FIELDS.QUANTITY]: Number(item.quantity),
    }));
    return { id: recipe.id, description: recipe.description, name: recipe.name, author, ingredients };
  }
  return { name: "", description: "", author: "", ingredients: [ITEMS_INITIAL_VALUES] };
};

const RecipePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { id } = useParams();

  const recipes = useSelector(selectAdminRecipes, shallowEqual);
  const foodItemOptions = useSelector(selectFoodItemOptions, shallowEqual);
  const loading = useSelector(selectLoadingCountriesSlice);
  const isAdminRecipeLoading = useSelector(selectAdminRecipesLoading);

  const selectedRecipe = useMemo(
    () => (id !== "create" ? recipes.find((recipe) => recipe.id === Number(id)) : undefined),
    [recipes, id]
  );

  const showFormFields = useMemo(() => selectedRecipe !== undefined || id === "create", [selectedRecipe, id]);

  const methods = useForm({ defaultValues: toFormValues(selectedRecipe) });
  const { control, handleSubmit, reset, getValues } = methods;

  const {
    fields: ingredients,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${FIELDS.INGREDIENTS}`,
  });

  useEffect(() => {
    if (!selectedRecipe && id && id !== "create") {
      // If in edit mode and there is no selectedRecipe, load the selectedRecipe from the backend
      dispatch(loadSingleAdminRecipe({ id }));
    } else if (selectedRecipe && selectedRecipe.id !== getValues()["id"]) {
      reset({ ...getValues(), ...toFormValues(selectedRecipe) });
    }
  }, [dispatch, getValues, reset, selectedRecipe, id]);

  const handleBackButtonClick = useCallback(() => {
    history.push(routesPath.adminCountriesRecipes);
  }, [history]);

  const addAdditionalItem = useCallback(() => {
    append(ITEMS_INITIAL_VALUES);
  }, [append]);

  const onSubmit = (values) => {
    if (selectedRecipe && id !== "create") {
      // reshape the values, since the backend accepts id instead of ingredient_id.
      // We initially used ingredient_id because react-hook-form's useFieldArray automatically assigns
      // an id to every element, and we don't want to tamper with that as it might cause unexpected bugs
      values.ingredients.forEach((ingredient) => {
        ingredient["id"] = ingredient["ingredient_id"];
        delete ingredient["ingredient_id"];
      });
      dispatch(updateSingleAdminRecipe({ recipe: values, prevPath: routesPath.adminCountriesRecipes }));
    } else {
      dispatch(createSingleAdminRecipe({ recipe: values, prevPath: routesPath.adminCountriesRecipes }));
    }
  };

  return (
    <>
      <Loading active={loading} />
      <ColStyled xs style={{ padding: "20px 8px" }} data-test-id="recipe-page-component">
        <Module>
          <ModuleHeader>
            <RowStyled middle="xs">
              <Button
                id="back-button"
                widthAuto
                marginRight="10px"
                kind="ghost"
                children={<Icon fill={colors["brand-01"].hex} icon={iconChevronLeft} height="20px" width="20px" />}
                onClick={handleBackButtonClick}
              />
              <Text
                bold
                fontSize="16px"
                value={selectedRecipe ? `${selectedRecipe.name}` : intl.formatMessage(messages.newRecipe)}
              />
            </RowStyled>
          </ModuleHeader>
          <ModuleBody>
            {showFormFields && (
              <RecipeContainerStyled>
                <RowStyled middle="xs" className="recipe-flat-row">
                  <ColStyled xs>
                    <ValidatedTextInputHookForm
                      labelText={intl.formatMessage(messages.recipeName)}
                      control={control}
                      name={FIELDS.NAME}
                      rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
                    />
                    <TextInputHookForm
                      labelText={intl.formatMessage(messages.description)}
                      control={control}
                      name={FIELDS.DESCRIPTION}
                    />
                    <TextInputHookForm
                      labelText={intl.formatMessage(messages.authorName)}
                      control={control}
                      name={FIELDS.AUTHOR}
                      disabled
                    />
                  </ColStyled>
                </RowStyled>
                <RowStyled margin="1.5rem 0 0 0.5rem">
                  <Button
                    id="add-additional-item"
                    widthAuto
                    kind="secondary"
                    onClick={addAdditionalItem}
                    children={intl.formatMessage(messages.addAdditionalItem)}
                  />
                </RowStyled>
                {ingredients.map((ingredient, index) => (
                  <RowStyled key={ingredient.id} margin="1.5rem 0 0 0" className="recipe-ingredient-row">
                    {/** This is here just so that the id for each entry in values.ingredients is included,
                     * to avoid re-creating new RecipeIngredients objects on the backend unnecessarily
                     */}
                    {selectedRecipe && (
                      <div className="hidden-id-field">
                        <Controller
                          name={`${FIELDS.INGREDIENTS}.${index}.${FIELDS.INGREDIENT_ID}`}
                          control={control}
                          render={() => null}
                        />
                      </div>
                    )}
                    <ColStyled xs={7}>
                      <ValidatedSelectHookForm
                        control={control}
                        name={`${FIELDS.INGREDIENTS}.${index}.${FIELDS.FOOD_ITEM}`}
                        options={foodItemOptions}
                        virtualized
                        label={intl.formatMessage(messages.foodItem)}
                        rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
                        isDisabled={isAdminRecipeLoading}
                      />
                    </ColStyled>
                    <ColStyled xs={2}>
                      <ValidatedNumberInputHookForm
                        allowZero
                        allowEmpty
                        control={control}
                        name={`${FIELDS.INGREDIENTS}.${index}.${FIELDS.QUANTITY}`}
                        labelText={intl.formatMessage(messages.quantity)}
                        rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
                      />
                    </ColStyled>
                    {ingredients.length > 1 && (
                      <ColStyled xs={1}>
                        <Icon
                          className="pointer paddingTopSmall"
                          description={intl.formatMessage(buttonMessages.remove)}
                          icon={iconDelete}
                          width="23"
                          height="23"
                          fill={colors["brand-01"].hex}
                          onClick={() => remove(index)}
                        />
                      </ColStyled>
                    )}
                  </RowStyled>
                ))}
                <RowStyled end="xs">
                  <Button
                    id="submit-button"
                    widthAuto
                    marginRight="5px"
                    kind="secondary"
                    onClick={handleSubmit(onSubmit)}
                    children={intl.formatMessage(buttonMessages.save)}
                  />
                </RowStyled>
              </RecipeContainerStyled>
            )}
          </ModuleBody>
        </Module>
      </ColStyled>
    </>
  );
};

export default RecipePage;
