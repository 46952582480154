import { select, call, all, put, takeLatest } from "redux-saga/effects";
import * as R from "ramda";

import {
  getPartialDiversity,
  getMenuSources,
  getFoodBasketBySource,
  postPartialPlusMenu,
  postMenuCalculation,
  postMenuWithoutSourcing,
  getCommunityPartialDiversity,
} from "api/api";
import { setAlert } from "containers/App/actions";
import { loadMenuSaga } from "containers/Menu/sagas";
import { selectMenuId } from "containers/Menu/selectors";
import {
  loadStarted,
  loadEnded,
  loadOptimizedInfoRequest,
  loadPlusMenuSuccess,
  loadAvailableSourcesSuccess,
  loadFoodBasketSourcesSuccess,
  loadNutrientsEnded,
  setInitValues,
  saveNutritionalAdvancedRequest,
  savePartialAndOptimizeRequest,
  optimizeMenuWithoutSourcingRequest,
} from "./actions";
import { defaultGeneralParameter } from "./constants";
import { initNutrients, saveNutritionalAdvancedSaga } from "./Rules/sagas";
import { selectSourcingConstraints, selectMenuGeneralParameter } from "./selectors";

export function* loadPartialPlusMenuSaga(id) {
  try {
    const partial = yield call(getPartialDiversity, id);
    yield put(loadPlusMenuSuccess({ data: partial }));
    return partial;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadPartialCommunityPlusMenuSaga(id) {
  try {
    const partial = yield call(getCommunityPartialDiversity, id);
    yield put(loadPlusMenuSuccess({ data: partial }));
    return partial;
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* initSourcing(id) {
  try {
    const sourcing = yield select(selectSourcingConstraints);
    const { available_sources } = yield call(getMenuSources, id);
    yield put(loadAvailableSourcesSuccess({ sources: available_sources }));
    return {
      sourcing_constraints: sourcing.map((el) => ({
        ...el,
        groups: R.pathOr(
          [],
          ["groups"],
          available_sources.find((source) => source.value === el.source)
        ),
      })),
    };
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* initMenuGeneralParameter() {
  try {
    const menuGeneralParameter = R.clone(yield select(selectMenuGeneralParameter));
    Object.keys(defaultGeneralParameter).forEach(
      (key) => (menuGeneralParameter[key] = R.pathOr(defaultGeneralParameter[key], [key], menuGeneralParameter))
    );
    return { menu_general_parameter: menuGeneralParameter };
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadFoodBasketBySourcing(id) {
  try {
    const foodBasket = yield call(getFoodBasketBySource, id);
    yield put(loadFoodBasketSourcesSuccess({ foodBasket }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadOptimizedInfoSaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    const menuId = yield select(selectMenuId);
    if (!menuId) yield call(loadMenuSaga, { payload: { id } });
    const partial = yield call(loadPartialPlusMenuSaga, id);
    const init = yield all([
      initSourcing(id),
      initMenuGeneralParameter(),
      loadFoodBasketBySourcing(id),
      initNutrients(id),
    ]);
    yield put(setInitValues({ values: { ...partial, ...R.mergeAll(init) } }));
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(loadNutrientsEnded());
    yield put(setAlert(e));
  }
}

export function* savePartialPlusMenuSaga({ payload: { id, body, callback } }) {
  try {
    yield call(postPartialPlusMenu, id, body);
    callback();
    yield call(loadOptimizedInfoSaga, { payload: { id } });
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* savePartialAndOptimizeSaga({ payload: { id, body, cb } }) {
  try {
    yield put(loadStarted());

    // 1. Save partial menu.
    yield call(postPartialPlusMenu, id, body);

    // 2. Start optimized process BE side.
    yield call(postMenuCalculation, id);

    // 3. Load menu content so we get everything updated, including the optimization status.
    const menu = yield call(loadMenuSaga, { payload: { id } });

    // 4. Pass optimization type to given callback.
    if (cb) cb(menu.optimization.type);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* optimizeMenuWithoutSourcingSaga({ payload: { id } }) {
  try {
    yield put(loadStarted());
    yield call(postMenuWithoutSourcing, id);
    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export default function* optimizedMenuSaga() {
  yield takeLatest(loadOptimizedInfoRequest, loadOptimizedInfoSaga);
  yield takeLatest(saveNutritionalAdvancedRequest, saveNutritionalAdvancedSaga);
  yield takeLatest(savePartialAndOptimizeRequest, savePartialAndOptimizeSaga);
  yield takeLatest(optimizeMenuWithoutSourcingRequest, optimizeMenuWithoutSourcingSaga);
}
