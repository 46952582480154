import { useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { colors, Icon } from "@wfp/ui";
import {
  iconWfpHumMonitoringPos,
  iconEdit,
  iconCopy,
  iconDownload,
  iconWfpHumFoodSecurityClusterPos,
} from "@wfp/icons";

import { Text } from "components";
import { Share as IconShare } from "components/Icons";
import { RowStyled, ColStyled } from "components/utils";
import { selectUsername } from "containers/App/selectors";
import selectMenu, { selectRegions, selectMenuParents, selectMenuChildren } from "containers/Menu/selectors";
import { ACTIONS } from "containers/OptionsAndModals/constants";
import Modals from "containers/OptionsAndModals/Modals";
import { useBoolean } from "hooks";
import MenuHistoryModal from "./MenuHistoryModal";
import { ContainerText, ContainerActions } from "./styles";
import { TYPES } from "utils/constants";
import { optimizeManualMenu } from "../../Menu/actions";

const Actions = ({ menuId, menuName, setEditMode }) => {
  const menu = useSelector(selectMenu());
  const menuParents = useSelector(selectMenuParents);
  const menuChildren = useSelector(selectMenuChildren);
  const username = useSelector(selectUsername);
  const menuRegions = useSelector(selectRegions);
  const dispatch = useDispatch();

  const hasParentsOrChildren = useMemo(() => {
    if (R.isNil(menu)) return false;
    return Object.keys(menuChildren).length + menuParents.length > 0;
  }, [menu, menuChildren, menuParents.length]);

  const createdByMe = useMemo(() => R.pathOr("", ["owner_details", "username"], menu) === username, [menu, username]);

  const [menuAndAction, setMenuAndAction] = useState();
  const [showHistory, { toggle: toggleShowHistory, setFalse: hideHistory }] = useBoolean();

  const onClickEdit = useCallback(() => setEditMode(true), [setEditMode]);

  const onClickHistory = useCallback(() => toggleShowHistory(), [toggleShowHistory]);

  const onClickShare = useCallback(() => {
    setMenuAndAction({ id: menuId, action: ACTIONS.SHARE, name: menuName });
  }, [menuId, menuName]);

  const onClickClone = useCallback(() => {
    setMenuAndAction({
      id: menuId,
      action: ACTIONS.CLONE,
      name: menuName,
      regions: menuRegions,
    });
  }, [menuId, menuName, menuRegions, setMenuAndAction]);

  const onClickDownload = useCallback(() => {
    setMenuAndAction({ id: menuId, action: ACTIONS.DOWNLOAD, name: menuName });
  }, [menuId, menuName, setMenuAndAction]);

  const onClickOptimizeManual = useCallback(() => {
    dispatch(optimizeManualMenu({ id: menuId, body: {} }));
  }, [menuId, dispatch]);

  return (
    <RowStyled middle="xs" margin="5px">
      <ColStyled xs className="start-xs">
        <ContainerText>
          <Text wrap bold fontSize="28px" value={menuName || ""} />
          <Icon
            className="marginLeftSmall"
            onClick={onClickEdit}
            icon={iconEdit}
            description="edit"
            fill={colors["brand-01"].hex}
            width="20"
            height="20"
          />
        </ContainerText>
      </ColStyled>
      <ColStyled xs className="end-xs">
        <ContainerActions>
          {["Develop", "local"].indexOf(process.env.ENV) > -1 && menu && menu.type === TYPES.MANUAL && (
            <Icon
              icon={iconWfpHumFoodSecurityClusterPos}
              description="menu-optimize-manual"
              className="pointer"
              onClick={onClickOptimizeManual}
              fill={colors["brand-01"].hex}
              width="28"
              height="28"
            />
          )}

          {hasParentsOrChildren && (
            <Icon
              icon={iconWfpHumMonitoringPos}
              description="menu-history"
              className="pointer"
              onClick={onClickHistory}
              fill={colors["brand-01"].hex}
              width="28"
              height="28"
            />
          )}
          {createdByMe && (
            <IconShare
              description="share"
              className="pointer"
              fill={colors["brand-01"].hex}
              size="28px"
              onClick={onClickShare}
            />
          )}
          <Icon
            icon={iconCopy}
            description="copy"
            className="pointer"
            onClick={onClickClone}
            fill={colors["brand-01"].hex}
            width="28"
            height="28"
          />
          <Icon
            icon={iconDownload}
            description="download"
            className="pointer"
            onClick={onClickDownload}
            fill={colors["brand-01"].hex}
            width="28"
            height="28"
          />
        </ContainerActions>
      </ColStyled>

      <Modals menuAndAction={menuAndAction} setMenuAndAction={setMenuAndAction} />
      {hasParentsOrChildren && (
        <MenuHistoryModal
          menuId={menuId}
          menuParents={menuParents}
          menuChildren={menuChildren}
          open={showHistory}
          closeModal={hideHistory}
        />
      )}
    </RowStyled>
  );
};

export default Actions;
