import { useMemo, useEffect, useCallback, useState } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useIntl } from "react-intl";
import { Loading, colors } from "@wfp/ui";
import { FormProvider } from "react-hook-form";

import { Layout, SecondHeader, Button, Unlock } from "components";
import buttonMessages from "components/Button/messages";
import { checkPermission } from "components/Unlock/utils";
import { PERMISSIONS } from "containers/Admin/constants";
import appMessages from "containers/App/messages";
import { selectPermissions } from "containers/App/selectors";
import { withAuth, withCountryKey } from "hocs";
import { useBoolean } from "hooks";
import { loadFoodBasket, searchFoodItemsRequest, resetSummaryPrices, resetFoodBasket } from "./actions";
import { FIELDS } from "./constants";
import { selectLoading } from "./selectors";
import AddPersonalFoodItem from "./AddPersonalFoodItem";
import FoodItemsList from "./FoodItemsList";
import SearchAndFilter from "./SearchAndFilter";
import { useAsyncForm, useInitialValues } from "./useInitialValues";
import { getSummaryPricesFilters } from "./utils";

const FoodBasket = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const userPermissions = useSelector(selectPermissions);
  const [redirectUser, setRedirectUser] = useState();

  const { useFormApi } = useAsyncForm();
  const initialValues = useInitialValues();

  const [visibleAddPersonalFoodItem, setVisibleAddPersonalFoodItem] = useBoolean();

  // If we have no permission to view the page, we redirect user to not found page
  useEffect(() => {
    if (userPermissions.length !== 0) {
      setRedirectUser(!checkPermission(userPermissions, PERMISSIONS.VIEW_FOOD_PRICES));
    }
  }, [userPermissions]);

  useEffect(() => {
    // If we don't have yet user permissions, do not fetch
    if (userPermissions.length !== 0 && redirectUser === false) {
      const filters = getSummaryPricesFilters(useFormApi.getValues()[FIELDS.FILTERS]);
      dispatch(loadFoodBasket());
      dispatch(searchFoodItemsRequest({ body: filters }));
    }
  }, [dispatch, redirectUser, useFormApi, userPermissions.length]);

  useEffect(() => {
    return () => {
      dispatch(resetFoodBasket());
    };
  }, [dispatch]);

  // "Add a personal food item" button
  const handleButtonClick = useCallback(() => {
    // If section is open, close it and empty personal food item data
    if (visibleAddPersonalFoodItem) {
      const initialPersonalItem = initialValues[FIELDS.PERSONAL_ITEM];

      setVisibleAddPersonalFoodItem.setFalse();
      useFormApi.reset({ ...useFormApi.getValues(), [FIELDS.PERSONAL_ITEM]: initialPersonalItem });
      dispatch(resetSummaryPrices());
      return;
    }

    setVisibleAddPersonalFoodItem.setTrue();
  }, [initialValues, dispatch, setVisibleAddPersonalFoodItem, useFormApi, visibleAddPersonalFoodItem]);

  // "Close" button inside footer
  const closePersonalFoodItemSection = useCallback(() => {
    const initialPersonalItem = initialValues[FIELDS.PERSONAL_ITEM];

    setVisibleAddPersonalFoodItem.setFalse();
    useFormApi.reset({ ...useFormApi.getValues(), [FIELDS.PERSONAL_ITEM]: initialPersonalItem });
  }, [initialValues, setVisibleAddPersonalFoodItem, useFormApi]);

  const Buttons = useMemo(
    () => (
      <Unlock permission={PERMISSIONS.ADD_FOOD_PRICES}>
        <Button
          widthAuto
          children={intl.formatMessage(buttonMessages.addPersonalFoodItem)}
          onClick={handleButtonClick}
        />
        {/* TODO: Uncomment once we know about bulk uploading implementation details. */}
        {/* <Button
          widthAuto
          marginLeft="10px"
          children={intl.formatMessage(buttonMessages.bulkAddPersonalFoodItems)}
          onClick={() => {}}
        /> */}
      </Unlock>
    ),
    [handleButtonClick, intl],
  );

  if (redirectUser) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Layout backgroundColor={colors["ui-02"].hex}>
      <SecondHeader title={intl.formatMessage(appMessages.foodBasket)} xsButton={6} button={Buttons} />
      <Loading active={loading} />

      <FormProvider {...useFormApi}>
        {visibleAddPersonalFoodItem ? <AddPersonalFoodItem close={closePersonalFoodItemSection} /> : null}
        <SearchAndFilter />
        <FoodItemsList />
      </FormProvider>
    </Layout>
  );
};

export default compose(withAuth, withCountryKey)(FoodBasket);
