import { FIELDS } from "../constants";
import { COMMUNITY, OPTIMIZED } from "containers/MyMenus/labels";

// Community menu: 5 <= nutrients <= 8
// Optimized/Manual menu: 5 <= nutrients <= no upper limit
export const validation = (menuType, values) => {
  const nutrients = values[FIELDS.NUTRIENTS];
  if (!nutrients) return true;

  const numOfCheckedNutrients = nutrients.filter((nutrient) => nutrient.checked).length;

  if (menuType === OPTIMIZED) {
    if (numOfCheckedNutrients < 5) return true;
  }

  if (menuType === COMMUNITY) {
    if (numOfCheckedNutrients < 5 || numOfCheckedNutrients > 8) return true;
  }

  return false;
};
