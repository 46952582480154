import * as R from "ramda";
import { colors } from "@wfp/ui";

import { Text } from "components";
import { useResponsiveHook } from "hooks";
import { targetOptions, targetColor } from "../constants";
import { Grid, Line, LabelWrapper } from "./styles";

const CartesianGrid = ({ referenceLine, inModal }) => {
  const { isTabletOrMobile } = useResponsiveHook();
  const ticks = R.reverse(targetOptions).filter((_, i) => (isTabletOrMobile || inModal ? i % 2 === 0 : true));

  return (
    <Grid>
      {ticks.map(({ label, value }, i) => (
        <Line
          key={`line-${i}`}
          selectedTarget={referenceLine?.value === value ? targetColor : ""}
          isTabletOrMobile={isTabletOrMobile}
          inModal={inModal}
        >
          <LabelWrapper>
            <Text
              bold
              defaultColor={referenceLine?.value === value ? targetColor : colors["ui-05"].hex}
              fontSize="16px"
              value={label}
            />
          </LabelWrapper>
        </Line>
      ))}
    </Grid>
  );
};

export default CartesianGrid;
