import { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useTable, usePagination, useSortBy } from "react-table";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import foodBasketMessages from "containers/FoodBasket/messages";
import Pagination from "./Pagination";
import { Styles, NoResults } from "./styles";

const Table = ({
  manual,
  pointer,
  columns,
  data,
  _page,
  pageSize: controlledPageSize,
  totalItems,
  loadData,
  onClickRow,
  pageSizeOptions = [5, 10, 15],
  controlledPageCount = -1,
  initialState = { pageIndex: 0, pageSize: controlledPageSize },
  selectedClass,
  hasPagination = true,
  paginationClassName = "",
  noPageRange,
  noPagesText,
  overflowId,
  theme = "",
  showAddButton,
  add,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...initialState, disableMultiSort: true },
      manualPagination: !hasPagination,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    manual && loadData(pageIndex + 1, pageSize);
  }, [loadData, manual, pageIndex, pageSize]);

  const onChange = useCallback(
    (e) => {
      if (e.pageSize !== pageSize) {
        setPageSize(e.pageSize);
        !manual && gotoPage(0);
      } else {
        if (manual) {
          loadData(e.page, e.pageSize);
        } else {
          gotoPage(e.page - 1);
        }
      }
    },
    [gotoPage, loadData, manual, pageSize, setPageSize],
  );

  const paginationProps = hasPagination
    ? {
        page: _page,
        onChange,
        pageSize,
        totalItems,
        noPageRange,
        noPagesText,
        pageSizeOptions,
        className: paginationClassName,
      }
    : {};

  return (
    <>
      <Styles theme={theme}>
        <div className="tableWrap">
          <table {...getTableProps()}>
            {/* Header */}
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div>{column.render("Header")}</div>
                      <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* Body */}
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                overflow: cell.column.id === overflowId ? "visible" : "hidden",
                                cursor: pointer ? "pointer" : "auto",
                              },
                            })}
                            className={`td ${selectedClass && selectedClass(row)}`}
                            onClick={() => onClickRow && onClickRow(row, cell)}
                          >
                            <div>{cell.render("Cell")}</div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <NoResults colSpan={columns.length} theme={theme}>
                    <FormattedMessage {...foodBasketMessages.noResults} />
                  </NoResults>
                </tr>
              )}

              {showAddButton && (
                <tr>
                  <td colSpan={columns.length}>
                    <Button widthAuto small children={<FormattedMessage {...buttonMessages.add} />} onClick={add} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Styles>
      {hasPagination && <Pagination {...paginationProps} />}
    </>
  );
};

export default Table;
