import React from "react";
import { useIntl } from "react-intl";
import { iconClose } from "@wfp/icons";
import { Basket } from "components/Icons";
import { Text } from "components";
import { useResponsiveHook } from "hooks";
import { ContainerHeader, IconStyled } from "./styles";
import messages from "./messages";

function Header({ sourceCount, groupsCount, itemsCount, changeStatus }) {
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();

  return (
    <ContainerHeader>
      <IconStyled onClick={changeStatus} description="close" width="13" height="13" icon={iconClose} />
      <Basket />
      <Text
        bold
        wrap
        marginLeft="5px"
        marginTop="-5px"
        fontSize={isTabletOrMobile ? "25px" : "28px"}
        value={
          sourceCount
            ? intl.formatMessage(messages.modalWithSources, {
                numGroups: groupsCount,
                numItems: itemsCount,
                numSources: sourceCount,
              })
            : intl.formatMessage(messages.modalTitle, { numGroups: groupsCount, numItems: itemsCount })
        }
      />
    </ContainerHeader>
  );
}

export default Header;
