import React from "react";
import { RowStyled, ColStyled } from "components/utils";
import { Field } from "react-final-form";
import { Checkbox } from "components";
import { FIELDS } from "../../utils";

export default function FoodSource({ foodSource }) {
  return (
    <Field
      key={foodSource.value}
      name={`${FIELDS.PRELIMINARY_INFO}.${FIELDS.FOOD_SOURCES}.${foodSource.value}`}
      type="checkbox"
    >
      {({ input }) => (
        <RowStyled bottom="xs">
          <ColStyled xs={10} lg={9} margin="20px 0 0">
            <Checkbox
              id={foodSource.value}
              labelText={foodSource.label}
              checked={input.checked}
              onChange={input.onChange}
            />
          </ColStyled>
        </RowStyled>
      )}
    </Field>
  );
}
