import { useCallback, useMemo, useState } from "react";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { Button } from "components";
import buttonMessages from "components/Button/messages";
import { shareMenu } from "containers/Menu/actions";

const ShareButton = ({ closeModal, values, id }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isSharing, setIsSharing] = useState(false);

  const emails = useMemo(() => Object.values(values), [values]);

  const onShare = useCallback(() => {
    setIsSharing(true);
    dispatch(shareMenu({ id, body: { share_with: emails.map((el) => el.value) }, cb: closeModal }));
  }, [closeModal, dispatch, emails, id]);

  return (
    <Button
      widthAuto
      disabled={R.isEmpty(emails) || emails.some((el) => !el.valid) || isSharing}
      onClick={onShare}
      children={isSharing ? intl.formatMessage(buttonMessages.sharing) : intl.formatMessage(buttonMessages.share)}
    />
  );
};

export default ShareButton;
