import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled } from "components/utils";
import { FIELDS } from "containers/GeneralInfo/constants";
import { validation } from "./validation";

const Footer = ({ initialItem, close, save }) => {
  const { control } = useFormContext();
  const draftItem = useWatch({ control, name: `${FIELDS.DRAFT}.${FIELDS.ITEM}` });

  const validate = useMemo(() => validation(initialItem, draftItem), [draftItem, initialItem]);

  return (
    <RowStyled end="xs" flex={1} padding="0.8rem 0">
      <Button widthAuto kind="secondary" onClick={close} children={<FormattedMessage {...messages.cancel} />} />
      <Button
        widthAuto
        marginLeft="10px"
        disabled={validate}
        onClick={save}
        children={<FormattedMessage {...messages.save} />}
      />
    </RowStyled>
  );
};

export default Footer;
