import { useCallback, useEffect, useState, useMemo } from "react";
import * as R from "ramda";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useLocation, Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "@wfp/ui";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Row } from "react-flexbox-grid";

import { Button, Line } from "components";
import { RowStyled, ColStyled } from "components/utils";
import messages from "components/Button/messages";
import { checkPermission } from "components/Unlock/utils";
import { selectPermissions, selectFoodSources } from "containers/App/selectors";
import selectMenu, { selectMenuId, selectMenuLoading } from "containers/Menu/selectors";
import { COMMUNITY } from "containers/MyMenus/labels";
import { setTutorial } from "containers/Tutorials/actions";
import { selectTutorials } from "containers/Tutorials/selectors";
import { TUTORIAL_IDS } from "containers/Tutorials/utils";
import { useResponsiveHook } from "hooks";
import { getMenuMode, getPermissionToBeChecked } from "utils/utils";
import { loadCommunityGeneralInfo, loadFoodBasket, updateFormState } from "./actions";
import FoodBasket from "./FoodBasket";
import MenuFields from "./MenuFields";
import PricesFields from "./PricesFields";
import { selectFormValues, selectFoodBasket, selectLoading } from "./selectors";
import { useInitialValues, useValidateGeneralForm } from "../useMenuHook";
import { changedSpecificFields, hasTrainingMaterials, reworkInitialMenu } from "./utils";
import validation from "./validation";

const GeneralForm = ({ onSubmit, menuId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isTabletOrMobile } = useResponsiveHook();
  const { pathname } = useLocation();
  const basket = useSelector(selectFoodBasket);
  const menu = useSelector(selectMenu());
  const loading = useSelector(selectLoading);
  const generalForm = useSelector(selectFormValues);
  const initialValues = useInitialValues();
  const validate = useValidateGeneralForm();
  const idMenu = useSelector(selectMenuId);
  const userPermissions = useSelector(selectPermissions);
  const tutorials = useSelector(selectTutorials);
  const loadingMenu = useSelector(selectMenuLoading);
  const foodSources = useSelector(selectFoodSources);
  const [initialize, setInitialize] = useState();
  const [redirectUser, setRedirectUser] = useState();

  const menuMode = useMemo(() => getMenuMode(pathname), [pathname]);

  const updateForm = useCallback(
    (state) => {
      if (changedSpecificFields(state.values, generalForm)) {
        dispatch(loadFoodBasket({ body: { ...state.values, type: COMMUNITY } }));
      }

      // https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373
      setTimeout(() => dispatch(updateFormState({ formState: state })), 0);
    },
    [dispatch, generalForm],
  );

  // If we have no permission to view the page, we redirect user to not found page. Since this is shared between manual and optimmized, logic isn't as straightforward as in other pages
  useEffect(() => {
    if (userPermissions.length !== 0) {
      const permissionToBeChecked = getPermissionToBeChecked(menuMode, COMMUNITY);
      setRedirectUser(!checkPermission(userPermissions, permissionToBeChecked));
    }
  }, [userPermissions, menuMode]);

  useEffect(() => {
    // If we don't have yet user permissions, do not fetch
    if (userPermissions.length !== 0 && redirectUser === false) {
      dispatch(loadCommunityGeneralInfo({ id: menuId || idMenu }));
    }
  }, [dispatch, idMenu, menuId, userPermissions.length, redirectUser]);

  useDeepCompareEffect(() => {
    // If we don't have yet user permissions, do not fetch
    if (userPermissions.length !== 0 && redirectUser === false) {
      if (menuId || idMenu) {
        if (!R.isEmpty(initialValues) && menu && foodSources) {
          setInitialize(reworkInitialMenu(foodSources, menu));
          dispatch(loadFoodBasket({ body: { ...menu, type: COMMUNITY } }));
        }
      } else {
        setInitialize(initialValues);
        if (!R.isEmpty(initialValues)) {
          dispatch(loadFoodBasket({ body: { ...initialValues, type: COMMUNITY } }));
        }
      }
    }
  }, [dispatch, idMenu, initialValues, menu, menuId, userPermissions.length, redirectUser, foodSources]);

  const hasMaterials = useMemo(() => hasTrainingMaterials(tutorials), [tutorials]);

  const onTrainingMaterialsButtonClick = useCallback(() => {
    setTimeout(() => dispatch(setTutorial({ kind: TUTORIAL_IDS.COMMUNITY_TRAINING })), 0);
  }, [dispatch]);

  if (redirectUser) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <Loading active={loading} withOverlay />
      {initialize && (
        <Form
          initialValues={initialize}
          mutators={arrayMutators}
          onSubmit={() => {}}
          validate={validation}
          render={({ values }) => (
            <>
              <FormSpy subscription={{ values: true }} onChange={updateForm} />
              <RowStyled bottom="xs">
                <ColStyled xs={isTabletOrMobile ? 8 : 9}>
                  <MenuFields />
                </ColStyled>
                <ColStyled xs={isTabletOrMobile ? 4 : 3}>
                  <RowStyled center="xs">
                    {hasMaterials && (
                      <Button
                        widthAuto
                        kind="tertiary"
                        marginBottom="10px"
                        children={intl.formatMessage(messages.trainingMaterials)}
                        onClick={onTrainingMaterialsButtonClick}
                      />
                    )}
                    <FoodBasket {...basket} />
                  </RowStyled>
                </ColStyled>
              </RowStyled>
              <PricesFields />
              {onSubmit && (
                <>
                  <Line margin="15px -30px" />
                  <Row end="xs">
                    <Button type="submit" onClick={onSubmit} disabled={validate || loadingMenu} className="col-xs-2">
                      {loadingMenu ? (
                        <FormattedMessage {...messages.loading} />
                      ) : (
                        <FormattedMessage {...messages.submit} />
                      )}
                    </Button>
                  </Row>
                </>
              )}
            </>
          )}
        />
      )}
    </>
  );
};

export default GeneralForm;
