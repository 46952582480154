import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "warningOrientation.title",
    defaultMessage: "Attention!",
  },
  firstLabel: {
    id: "warningOrientation.firstLabel",
    defaultMessage: "Your browser seems to be too small to use this application",
  },
  secondLabel: {
    id: "warningOrientation.secondLabel",
    defaultMessage: "Try to enlarge the window if possible or change the orientation of the device to horizontal",
  },
  useAnotherDevice: {
    id: "warningOrientation.useAnotherDevice",
    defaultMessage: "Try to enlarge the window if possible or use another device",
  },
});
