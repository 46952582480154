import React, { useRef, useMemo, useCallback, useState } from "react";
import * as R from "ramda";
import { colors } from "@wfp/ui";
import { Text } from "components";
import useSize from "@react-hook/size";
import CartesianGrid from "./CartesianGrid";
import Bar from "./Bar";
import {
  HEIGHT_CHART,
  TABLET_HEIGHT_CHART,
  MODAL_SIZE,
  H_PADDING,
  SELECTED_BAR_WIDTH,
  BAR_WIDTH,
  SPACE_GROUP,
} from "./constants";
import { BarChart, Container } from "./styles";
import { useResponsiveHook } from "hooks";


type NutritionalGraphProps = {
  id: any,
  menus: any,
  customProps: any,
  inPdf: any,
  noReferenceLine: any,
  targetByRestriction: any,
  noEllipsis: any,
  inTwoRows: any,
  multipleRows: any,
  inModal: any,
  containerStyle: any,
};

const NutritionalGraph = ({
  id,
  menus,
  customProps,
  inPdf,
  noReferenceLine,
  targetByRestriction,
  noEllipsis,
  inTwoRows,
  multipleRows,
  inModal,
  containerStyle = {},
}: NutritionalGraphProps) => {
  const { isTabletOrMobile } = useResponsiveHook();
  const $container = useRef();
  const [referenceLine] = useState(noReferenceLine ? {} : { value: 30, label: "30%" });
  const [width, height] = useSize($container);
  const [selectedCategory, setSelectedCategory] = useState("macros");
  const [showTooltip, setTooltip] = useState();
  const barWidth = R.pathOr(false, ["barWidth"], customProps);
  const target = R.pathOr("", ["target"], customProps);

  const { totalWidth, barSpacing } = useMemo(() => {
    if (!menus) return 2 * H_PADDING;
    const bars = R.flatten(menus);
    const barsSum = bars.reduce(
      (acc, item) => (acc = acc + (barWidth || (selectedCategory === item.category ? SELECTED_BAR_WIDTH : BAR_WIDTH))),
      0,
    );
    return {
      totalWidth: 2 * H_PADDING + barsSum + SPACE_GROUP * (menus.length - 1),
      barSpacing: 2 * (bars.length - menus.length),
    };
  }, [barWidth, menus, selectedCategory]);

  const getPixel = useCallback(
    (withToCalculate) => (width - barSpacing) * (withToCalculate / totalWidth),
    [barSpacing, totalWidth, width],
  );

  const getPixelFromPercentage = useCallback((heightToCalculate) => height * (heightToCalculate / 100), [height]);

  const barChartArray = useMemo(() => (menus || []).map((el) => (Array.isArray(el) ? el : [el])), [menus]);

  return (
    <div id={id} style={containerStyle}>
      <Container
        ref={$container}
        height={isTabletOrMobile && !inModal ? TABLET_HEIGHT_CHART : inModal ? MODAL_SIZE : HEIGHT_CHART}
      >
        <CartesianGrid referenceLine={referenceLine} inModal={inModal} />
        <BarChart>
          {barChartArray.map((el, groupIndex) =>
            el.map((bar, index) => (
              <Bar
                key={`bar-${index}-${groupIndex}`}
                bar={bar}
                index={index}
                showTooltip={showTooltip}
                groupIndex={groupIndex}
                targetByRestriction={targetByRestriction}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setTooltip={setTooltip}
                totalWidth={totalWidth}
                getPixel={getPixel}
                getPixelFromPercentage={getPixelFromPercentage}
                {...customProps}
              />
            )),
          )}
        </BarChart>
        {target && <Text bold defaultColor={colors["ui-05"].hex} textAlign="center" value={target} />}
      </Container>
    </div>
  );
};

export default NutritionalGraph;
