import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectSelectedNutrientsLevel,
  selectAllNutrientsLevel,
  selectSelectedNutrients,
} from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";

export const useInitialValues = () => {
  const selectedNutrientsLevel = useSelector(selectSelectedNutrientsLevel);
  const allNutrientsLevel = useSelector(selectAllNutrientsLevel);
  const selectedNutrients = useSelector(selectSelectedNutrients);
  const countries = useSelector(selectCountries);

  const isMissingData = useMemo(() => {
    return !selectedNutrientsLevel || !allNutrientsLevel || !selectedNutrients || !countries;
  }, [selectedNutrientsLevel, allNutrientsLevel, selectedNutrients, countries]);

  return useMemo(() => {
    if (isMissingData) return {};

    // We look for nutrients that are selected in "Nutrients" - "Community menu" tab since they are the ones that need to be shown along with related percentage
    let communityNutrientsLevel = [];
    allNutrientsLevel.forEach((nutrient) => {
      const selectedNutrientIndex = selectedNutrients.indexOf(nutrient.value);

      if (selectedNutrientIndex !== -1) {
        const nutrientLevelPreference = selectedNutrientsLevel.find(
          (nutrientLevel) => nutrientLevel.nutrient === selectedNutrients[selectedNutrientIndex],
        );

        communityNutrientsLevel.push({
          ...nutrient,
          pct: nutrientLevelPreference ? Number(nutrientLevelPreference.pct) : 30,
        });
      }
    });

    return { community_nutrients_level: communityNutrientsLevel };
  }, [isMissingData, allNutrientsLevel, selectedNutrients, selectedNutrientsLevel]);
};
