export const railStyle = {
  backgroundColor: "#8E9BA4",
  height: 4,
  borderRadius: 0,
};

export const trackStyle = {
  backgroundColor: "#3077BC",
  height: 4,
  borderRadius: 0,
};

export const handleStyle = {
  borderColor: "#3077BC",
  height: 24,
  width: 24,
  marginTop: -10,
  backgroundColor: "#3077BC",
};
