import { useState, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Col } from "react-flexbox-grid";

import { Module } from "components";
import { NutritionalGraph, NutritionalGrid } from "components/Charts";
import { ChartView, GridView } from "components/Icons";
import messages from "containers/Menu/nutMessages";
import { GraphForPDF } from "./styles";

const NutritionalValues = ({ menus }) => {
  const properties = { marginBar: menus.length === 2 ? 30 : 2, barWidth: 20 };
  const [isChartMode, setChartMode] = useState(true);

  const onClickView = useCallback(() => {
    setChartMode(!isChartMode);
  }, [isChartMode]);

  const refactoredMenu = useMemo(() => {
    if (!menus) return [];
    return menus.map((el) => el.fulfilled);
  }, [menus]);

  const renderValue = useCallback((item) => `${item.percentage}%`, []);
  return (
    <>
      {menus && (
        <Module
          title={<FormattedMessage {...messages.values} />}
          elementsOnRight={
            <Col xs={2} className="end-xs">
              <div onClick={onClickView}>{isChartMode ? <ChartView /> : <GridView />}</div>
            </Col>
          }
        >
          {isChartMode ? (
            <NutritionalGraph menus={refactoredMenu} />
          ) : (
            <Module.ContentWithBorder menus={refactoredMenu}>
              {(menu) => <NutritionalGrid data={menu} numberColumns={3} renderValue={renderValue} />}
            </Module.ContentWithBorder>
          )}
          <GraphForPDF>
            <NutritionalGraph
              id="nutritional-values"
              menus={refactoredMenu}
              customProps={properties}
              targetByRestriction={true}
              inPdf
              noEllipsis
            />
          </GraphForPDF>
        </Module>
      )}
    </>
  );
};

export default NutritionalValues;
