import styled from "styled-components";

export const RecipeContainerStyled = styled.div`
  .recipe-flat-row {
    .wfp--form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }
    .wfp--form-requirement {
      max-height: none;
      margin: 0 0 0 3px;
    }
    .wfp--label {
      min-width: 15rem;
      margin: 0;
    }
  }

  .recipe-ingredient-row {
    .wfp--form-item {
      align-items: inherit !important;
    }
  }

  .wfp--modal-container {
    overflow: visible !important;
  }
`;
