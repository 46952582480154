import { defineMessages } from "react-intl";

export default defineMessages({
  personal: {
    id: "foodBasket.personal",
    defaultMessage: "Personal",
  },
  official: {
    id: "foodBasket.official",
    defaultMessage: "Official",
  },
  addPersonalFoodItem: {
    id: "foodBasket.addPersonalFoodItem",
    defaultMessage: "Add a personal food item",
  },
  addPersonalPrice: {
    id: "foodBasket.addPersonalPrice",
    defaultMessage: "Add a personal price",
  },
  chooseFromGlobalBasket: {
    id: "foodBasket.chooseFromGlobalBasket",
    defaultMessage: "Choose from global basket",
  },
  nutritionalValues: {
    id: "foodBasket.nutritionalValues",
    defaultMessage: "Nutritional values",
  },
  warning: {
    id: "foodBasket.warning",
    defaultMessage: "The food item already exists in your Country’s official food items",
  },
  addToPersonalFoodBasket: {
    id: "foodBasket.addToPersonalFoodBasket",
    defaultMessage: "Add to your personal food basket",
  },
  optionAll: {
    id: "filters.optionAll",
    defaultMessage: "All",
  },
  optionOne: {
    id: "filters.optionOne",
    defaultMessage: "Last year",
  },
  option: {
    id: "filters.option",
    defaultMessage: "Last {number}year",
  },
  regions: {
    id: "filters.regions",
    defaultMessage: "Regions",
  },
  allRegions: {
    id: "filters.allRegions",
    defaultMessage: "-- All --",
  },
  dataSource: {
    id: "filters.dataSource",
    defaultMessage: "Data source",
  },
  priceDataOrigins: {
    id: "filters.priceDataOrigins",
    defaultMessage: "Price data origins",
  },
  year: {
    id: "filters.year",
    defaultMessage: "Year",
  },
  procurementSource: {
    id: "filters.procurementSource",
    defaultMessage: "Procurement source",
  },
  foodItem: {
    id: "fields.foodItem",
    defaultMessage: "Food item",
  },
  foodPrice: {
    id: "fields.foodPrice",
    defaultMessage: "Food price",
  },
  currency: {
    id: "fields.currency",
    defaultMessage: "Currency",
  },
  unit: {
    id: "fields.unit",
    defaultMessage: "Unit",
  },
  selectUnit: {
    id: "fields.selectUnit",
    defaultMessage: "Select unit",
  },
  type: {
    id: "fields.type",
    defaultMessage: "Type",
  },
  selectType: {
    id: "fields.selectType",
    defaultMessages: "Select type",
  },
  region: {
    id: "fields.region",
    defaultMessage: "Region",
  },
  price: {
    id: "fields.price",
    defaultMessage: "Price",
  },
  priceCollectedHow: {
    id: "fields.priceCollectedHow",
    defaultMessage: "How was the price collected?",
  },
  priceCollectedWhen: {
    id: "fields.priceCollectedWhen",
    defaultMessage: "When was the price collected?",
  },
  proposedFoodItem: {
    id: "fields.proposedFoodItem",
    defaultMessage: "Mark this item as proposed",
  },
  showing: {
    id: "foodBasket.showing",
    defaultMessage: "Showing:",
  },
  includeAdditional: {
    id: "foodBasket.includeAdditional",
    defaultMessage: "Include additional costs",
  },
  columnSource: {
    id: "foodBasket.columnSource",
    defaultMessage: "Source",
  },
  columnProcurementSource: {
    id: "foodBasket.columnProcurementSource",
    defaultMessage: "Procurement source",
  },
  columnDate: {
    id: "foodBasket.columnDate",
    defaultMessage: "Date",
  },
  columnRegion: {
    id: "foodBasket.columnRegion",
    defaultMessage: "Region",
  },
  columnPurchaseCost: {
    id: "foodBasket.columnPurchaseCost",
    defaultMessage: "Purchase cost",
  },
  columnAdditionalCosts: {
    id: "foodBasket.columnAdditionalCosts",
    defaultMessage: "Additional costs",
  },
  columnTotalPrice: {
    id: "foodBasket.columnTotalPrice",
    defaultMessage: "Total price",
  },
  columnPricePerKg: {
    id: "foodBasket.columnPricePerKg",
    defaultMessage: "Price (USD per 1Kg)",
  },
  noResults: {
    id: "foodBasket.noResults",
    defaultMessage: "No results",
  },
  tabPrices: {
    id: "foodBasket.tabPrices",
    defaultMessage: "Prices",
  },
  tabNutritionalValues: {
    id: "foodBasket.tabNutritionalValues",
    defaultMessage: "Nutritional values",
  },
  genericSuccess: {
    id: "foodBasket.genericSuccess",
    defaultMessage: "Success",
  },
  createdSuccess: {
    id: "foodBasket.createdSuccess",
    defaultMessage: "Food price successfully created",
  },
  updatedSuccess: {
    id: "foodBasket.updatedSuccess",
    defaultMessage: "Food price successfully updated",
  },
  deletedSuccess: {
    id: "foodBasket.deletedSuccess",
    defaultMessage: "Food price successfully deleted",
  },
  averageFor: {
    id: "foodBasket.averageFor",
    defaultMessage: "Average price for {value}",
  },
  nutritionalInfo: {
    id: "foodBasket.nutritionalInfo",
    defaultMessage: "Nutritional value per 100 G (Nutritional database: {itemSource})",
  },
  options: {
    id: "icon.options",
    defaultMessages: "options",
  },
  nutrientRangeEqualBounds: {
    id: "foodBasket.nutrientRangeEqualBounds",
    defaultMessage: "{nutrient}: {lower, select, 0 {Very Low} 25 {Low} 50 {High} 75 {Very High} other {Max}}",
  },
  nutrientRangeDifferentBounds: {
    id: "foodBasket.nutrientRangeDifferentBounds",
    defaultMessage:
      "{nutrient}: from {lower, select, 0 {Very Low} 25 {Low} 50 {High} 75 {Very High} other {Max}} to {upper, select, 0 {Very Low} 25 {Low} 50 {High} 75 {Very High} other {Max}}",
  },
  staple: {
    id: "foodBasket.staple",
    defaultMessage: "Staple",
  },
});
