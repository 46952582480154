export const LANGUAGES = [
  { language_code: "en", language: "en", beta: false, valueToShow: "EN" },
  { language_code: "fr", language: "fr", beta: true, valueToShow: "FR" },
  { language_code: "es", language: "es", beta: true, description: "general", valueToShow: "ES" },
  // This valueToShow does not match the language because the es-DO code is not supported from the backend
  { language_code: "es_pr", language: "es-PR", beta: true, description: "dominicanRepublic", valueToShow: "ES-DO" },
  { language_code: "pt", language: "pt", beta: true, valueToShow: "PT" },
  { language_code: "ru", language: "ru", beta: true, valueToShow: "RU" },
  { language_code: "ar", language: "ar", beta: true, valueToShow: "AR" },
  { language_code: "khm", language: "khm", beta: true, valueToShow: "KHM" },
];
