import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { SelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { selectWeightChoices } from "containers/App/selectors";
import { FIELDS } from "containers/GeneralInfo/constants";
import { useCurrencies } from "hooks";

const Filters = () => {
  const { control } = useFormContext();

  const units = useSelector(selectWeightChoices);
  const currencies = useCurrencies();

  const handleFilterChange = useCallback(async (el, field) => {
    // Do not change if new value is same as old one
    if (el.value === field.value) return;
    await field.onChange(el.value);
  }, []);

  return (
    <RowStyled end="xs" margin="0 0 10px">
      <ColStyled xs={4} padding="0">
        <RowStyled>
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.FILTERS}.${FIELDS.UNIT}`}
              options={units}
              customOnChange={handleFilterChange}
            />
          </ColStyled>
          <ColStyled xs={6}>
            <SelectHookForm
              control={control}
              name={`${FIELDS.FILTERS}.${FIELDS.CURRENCY}`}
              options={currencies}
              customOnChange={handleFilterChange}
            />
          </ColStyled>
        </RowStyled>
      </ColStyled>
    </RowStyled>
  );
};

export default Filters;
