import { colors } from "@wfp/ui";
import styled from "styled-components";

import { RowStyled, ColStyled } from "components/utils";

export const TitleContainer = styled(RowStyled)`
  background-color: ${colors["ui-03"].hex};
  border: 1px solid ${colors["ui-04"].hex};
  padding: 10px;
`;

export const ItemsListContainer = styled(ColStyled)`
  padding: 0;
  border: 1px solid ${colors["ui-04"].hex};
`;

export const GraphAndStatsContainer = styled(ColStyled)`
  padding: 0;
  border: 1px solid ${colors["ui-04"].hex};
  border-left: none;
`;

export const GraphAndStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 61px);
`;

export const InfoBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
  border: 1px solid ${colors["ui-04"].hex};
  margin-left: -1px;
  margin-bottom: -1px;
`;

export const SourcingBlock = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  border: 1px solid ${colors["ui-04"].hex};
  margin-left: -1px;
  margin-right: -1px;
  border-bottom: 0;
`;

export const DayCompositionContainer = styled.div`
  margin-top: 30px;
`;
