import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { Button, Text } from "components";
import buttonMessages from "components/Button/messages";
import { TextAreaHookForm } from "components/Form";
import { updateMenu } from "containers/Menu/actions";
import menuMessages from "containers/Menu/messages";
import { selectMenuNotes } from "containers/Menu/selectors";
import { FIELDS } from "containers/Results/constants";
import { ColStyled } from "components/utils";
import { TitleContainer } from "./styles";

const Notes = ({ menuId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { control } = useFormContext();

  const notes = useWatch({ control, name: FIELDS.NOTES });
  const reduxNotes = useSelector(selectMenuNotes);

  const save = useCallback(() => {
    dispatch(updateMenu({ id: menuId, body: { notes } }));
  }, [dispatch, menuId, notes]);

  const validation = useMemo(() => notes === reduxNotes, [notes, reduxNotes]);

  return (
    <ColStyled xs={12} padding="0" margin="40px 0 0">
      <TitleContainer>
        <Text bold fontSize="16px" regularWrap value={intl.formatMessage(menuMessages.notes)} />
        <Button widthAuto disabled={validation} children={intl.formatMessage(buttonMessages.save)} onClick={save} />
      </TitleContainer>

      <TextAreaHookForm control={control} name={FIELDS.NOTES} rows={8} />
    </ColStyled>
  );
};

export default Notes;
