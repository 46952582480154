import React, { useMemo, Fragment, memo } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ModuleHeader, ModuleBody } from "@wfp/ui";

import { RowStyled } from "components/utils";
import { selectDaysInWeek, selectWeekCount } from "containers/Menu/selectors";
import { NewModule } from "components";
import DayBox from "./DayBox";
import GroupRow from "./GroupRow";

import { joinStrings } from "utils/utils";
import messages from "../messages";
import { FIELDS } from "../FoodRestriction/constants";

import { DayBoxInnerDiv, GroupRowInnerDiv } from "./styles";

const NUMBER_OF_PLACEHOLDER_DAYS = 5;

const buildRepetitionLabel = (first, second, flag, key, intl) => {
  return flag
    ? intl.formatMessage(messages.rangePortionsPer, { first, second, key })
    : first
    ? intl.formatMessage(messages.atLeastPortionsPer, { first, key })
    : "";
};

const buildDailyRepetitionLabel = (group, intl) => {
  if (!group.daily_repetition) return "";
  return buildRepetitionLabel(
    group[FIELDS.DAILY_REPETITION][0],
    group[FIELDS.DAILY_REPETITION][1],
    group[FIELDS.ENABLED_DAILY_MAX],
    intl.formatMessage(messages.day),
    intl
  );
};

const buildWeeklyRepetitionLabel = (group, intl) => {
  if (!group.weekly_repetition) return "";
  return buildRepetitionLabel(
    group[FIELDS.WEEKLY_REPETITION][0],
    group[FIELDS.WEEKLY_REPETITION][1],
    group[FIELDS.ENABLED_WEEKLY_MAX],
    intl.formatMessage(messages.week),
    intl
  );
};

const useGroups = (food_group_restrictions, intl) => {
  const groups = useMemo(() => {
    const filteredList = (food_group_restrictions || []).filter((item) => !item[FIELDS.EXCLUDE]);

    const list = filteredList.map((group) => {
      const label = [buildDailyRepetitionLabel(group, intl), buildWeeklyRepetitionLabel(group, intl)].filter(Boolean);
      return {
        name: group.minor_group ? group.minor_label : group.major_label,
        inclusionLabel: joinStrings(label, label.pop(), intl),
      };
    });

    return list;
  }, [food_group_restrictions, intl]);

  return groups;
};

const useDaysItemsObject = (food_item_restrictions, groups, daysArray, weekCount) => {
  const daysItemsObject = useMemo(() => {
    const filteredList = (food_item_restrictions || []).filter((item) => item[FIELDS.INCLUDE]);

    const initialDays = {};
    const days = daysArray?.reduce(
      (obj, item) => ({
        ...obj,
        [item]: { week: Math.ceil((item || 0) / ((daysArray.length || 1) / (weekCount || 1))), items: [] },
      }),
      initialDays
    );

    filteredList.forEach((item) => {
      let includedDays = [];
      let excludedDays = [];

      item[FIELDS.INCLUDED_DAYS].forEach((day) => {
        const tempItem = { name: item[FIELDS.ITEM_NAME], type: "include" };
        if (R.pathOr(undefined, ["items"], days[day]) && !days[day]["items"].includes(tempItem)) {
          days[day]["items"].push(tempItem);
          includedDays.push(day);
        }
      });

      item[FIELDS.EXCLUDED_DAYS].forEach((day) => {
        const tempItem = { name: item[FIELDS.ITEM_NAME], type: "exclude" };
        if (R.pathOr(undefined, ["items"], days[day]) && !days[day]["items"].includes(tempItem)) {
          days[day]["items"].push(tempItem);
          excludedDays.push(day);
        }
      });

      // For the included days we also have to take care of the matching and unmatching items
      includedDays.forEach((day) => {
        item[FIELDS.MATCH_WITH].forEach((item) => {
          const tempItem = { name: item, type: "include" };
          if (R.pathOr(undefined, ["items"], days[day]) && !days[day]["items"].includes(tempItem))
            days[day]["items"].push(tempItem);
        });
        item[FIELDS.UNMATCH_WITH].forEach((item) => {
          const tempItem = { name: item, type: "exclude" };
          if (R.pathOr(undefined, ["items"], days[day]) && !days[day]["items"].includes(tempItem))
            days[day]["items"].push(tempItem);
        });
      });
    });

    // Compute maximum length for each week
    let maxLengths = new Array(weekCount).fill(groups.length > 0 ? 0 : NUMBER_OF_PLACEHOLDER_DAYS);
    Object.values(days).forEach((dayObject) => {
      const weekIndex = dayObject["week"] - 1;
      maxLengths[weekIndex] = Math.max(maxLengths[weekIndex], dayObject["items"].length);
    });

    // Pad each day's items array up to the maximum length of its week
    Object.values(days).forEach((dayObject) => {
      const weekIndex = dayObject["week"] - 1;
      const dayValues = dayObject["items"];
      while (dayValues.length < maxLengths[weekIndex]) {
        dayValues.push("");
      }
    });

    return days;
  }, [food_item_restrictions, groups, daysArray, weekCount]);

  return daysItemsObject;
};

const RulesSummary = ({ menu: { food_item_restrictions, food_group_restrictions } }) => {
  const intl = useIntl();

  const daysInWeek = useSelector(selectDaysInWeek);
  const weekCount = useSelector(selectWeekCount);

  const days = useMemo(() => new Array(weekCount * daysInWeek).fill().map((_, i) => i + 1), [daysInWeek, weekCount]);

  const groups = useGroups(food_group_restrictions, intl);
  const daysItemsObject = useDaysItemsObject(food_item_restrictions, groups, days, weekCount);

  const header = useMemo(
    () => (
      <ModuleHeader>
        <FormattedMessage {...messages.rulesSummary} />
      </ModuleHeader>
    ),
    []
  );

  const body = useMemo(() => {
    return (
      <ModuleBody>
        {R.splitEvery(daysInWeek, days).map((days, index) => {
          return (
            <Fragment key={`week-${index}`}>
              <RowStyled>
                {days.map((day) => {
                  return (
                    <DayBoxInnerDiv key={`day-${day}-${index}`}>
                      <DayBox
                        week={index + 1}
                        weekDay={day - index * daysInWeek}
                        dayItems={daysItemsObject[day]["items"]}
                      />
                    </DayBoxInnerDiv>
                  );
                })}
              </RowStyled>

              {groups.map((group, groupIndex) => (
                <GroupRowInnerDiv key={`row-${index}-${groupIndex}`}>
                  <GroupRow group={group} />
                </GroupRowInnerDiv>
              ))}
            </Fragment>
          );
        })}
      </ModuleBody>
    );
  }, [days, daysInWeek, daysItemsObject, groups]);

  return (
    <NewModule
      padding="20px 8px 18px"
      contentBackground="white"
      withBorder
      header={header}
      headerPadding="10px 8px"
      body={body}
    />
  );
};

export default memo(RulesSummary);
