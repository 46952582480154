import { format } from "date-fns";

export const getPriceSourcesCheckboxes = (priceSources) => {
  return priceSources.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: item.checkboxes.reduce((checks, el) => ({ ...checks, [el.value]: true }), {}),
    }),
    {},
  );
};

export const getDefaultMenuName = (countryName) => {
  const date = format(new Date(), "P - k:mm");
  return `Menu - ${countryName} ${date}`;
};
