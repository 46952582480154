import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadMajorGroupsSuccess,
  loadFoodItemsStarted,
  loadFoodItemsSuccess,
  loadFoodItemsEnded,
  loadSummaryPricesStarted,
  loadSummaryPricesSuccess,
  loadSummaryPricesEnded,
  createOrUpdateFoodPriceStarted,
  createOrUpdateFoodPriceEnded,
  deleteFoodPriceStarted,
  deleteFoodPriceEnded,
  loadNutrientsSuccess,
  loadFCTFoodItemsSuccess,
  loadFCTFoodItemsStarted,
  loadFCTFoodItemsEnded,
  resetSummaryPrices,
  resetFoodBasket,
} from "./actions";

export const initialState = {
  loading: false,
  nutrients: [],
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadMajorGroupsSuccess.type]: (state, action) => {
    state.majorGroups = action.payload.majorGroups;
  },
  [loadFoodItemsStarted.type]: (state) => {
    state.loadingFoodItems = true;
  },
  [loadFoodItemsSuccess.type]: (state, action) => {
    state.loadingFoodItems = false;
    state.items = action.payload.foodItems;
  },
  [loadFoodItemsEnded.type]: (state) => {
    state.loadingFoodItems = false;
  },
  [loadSummaryPricesStarted.type]: (state) => {
    state.loadingSummaryPrices = true;
  },
  [loadSummaryPricesSuccess.type]: (state, action) => {
    state.loadingSummaryPrices = false;
    state.summary = action.payload.summaryPrices;
  },
  [loadSummaryPricesEnded.type]: (state) => {
    state.loadingSummaryPrices = false;
  },
  [resetSummaryPrices.type]: (state) => {
    state.summary = null;
  },
  [createOrUpdateFoodPriceStarted.type]: (state) => {
    state.loadingFoodPrice = true;
  },
  [deleteFoodPriceStarted.type]: (state) => {
    state.loadingFoodPrice = true;
  },
  [createOrUpdateFoodPriceEnded.type]: (state) => {
    state.loadingFoodPrice = false;
  },
  [deleteFoodPriceEnded.type]: (state) => {
    state.loadingFoodPrice = false;
  },
  [loadNutrientsSuccess.type]: (state, action) => {
    state.nutrients = action.payload.nutrients;
  },
  [loadFCTFoodItemsStarted.type]: (state) => {
    state.loadingFCTFoodItems = true;
  },
  [loadFCTFoodItemsSuccess.type]: (state, action) => {
    state.fctItems = action.payload.foodItems;
  },
  [loadFCTFoodItemsEnded.type]: (state) => {
    state.loadingFCTFoodItems = false;
  },
  [resetFoodBasket.type]: () => initialState,
});
