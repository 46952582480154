export const normalizePrice = ({
  from,
  to,
  fromCurrency,
  toCurrency,
  price,
  conversionRateFromUsd,
  conversionRateFromLocal,
  decimals = 3,
}) => {
  // Normalize price to USD given the selected quantity.
  const pricePerQuantity = (price * to.conversion_rate) / from.conversion_rate;

  let normalizedPrice;
  if (fromCurrency === "USD" && toCurrency !== "USD") {
    // Going from "USD" to local currency.
    normalizedPrice = pricePerQuantity * conversionRateFromUsd;
  } else if (fromCurrency === "USD" && toCurrency === "USD") {
    // Going from "USD" to "USD": nothing to do.
    normalizedPrice = pricePerQuantity;
  } else {
    // Going from local currency to "USD".
    normalizedPrice = pricePerQuantity * conversionRateFromLocal;
  }
  return normalizedPrice.toFixed(decimals);
};
