import React from "react";
import { useArabicCheck } from "hooks";
import { defaultFontStyle } from "utils/utils";
import { TextStyled } from "./styles";

type TextProps = {
  value: string,
  bold?: boolean
  className?: boolean
  defaultColor?: string,
  disableColor?: boolean,
  disable?: boolean,
  fontSize?: string,
  fontStyle?: string,
  marginLeft?: string,
  marginRight?: string,
  marginTop?: string,
  marginBottom?: string,
  marginInlineStart?: string,
  marginInlineEnd?: string,
  superBold?: string,
  textAlign?: string,
  decoration?: string,
  width?: string,
  wrap?: string,
  transform?: string,
  noEllipsis?: string,
  withPointer?: string,
  noFontWeight?: string,
  maxWidth?: string,
  flex?: string,
  lineHeight?: string,
  regularWrap?: string,
}

export default function Text({
  value,
  bold,
  className,
  defaultColor,
  disableColor,
  disable,
  fontSize,
  fontStyle,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  marginInlineStart,
  marginInlineEnd,
  superBold,
  textAlign,
  decoration,
  width,
  wrap,
  transform,
  noEllipsis,
  withPointer,
  noFontWeight,
  maxWidth,
  flex,
  lineHeight,
  regularWrap,
}: TextProps): JSX.Element {
  const isArabic = useArabicCheck(value);

  return (
    <TextStyled
      bold={bold}
      width={width}
      flex={flex}
      maxWidth={maxWidth}
      fontStyle={fontStyle}
      noFontWeight={noFontWeight}
      className={className}
      superBold={superBold}
      disable={disable}
      fontSize={fontSize}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginInlineStart={marginInlineStart}
      marginInlineEnd={marginInlineEnd}
      defaultColor={defaultColor}
      disableColor={disableColor}
      textAlign={textAlign}
      decoration={decoration}
      noEllipsis={noEllipsis}
      withPointer={withPointer}
      transform={transform}
      wrap={String(wrap || "")}
      lineHeight={lineHeight}
      regularWrap={regularWrap}
      style={!isArabic ? defaultFontStyle : {}}
    >
      {value}
    </TextStyled>
  );
}
