import { createReducer } from "@reduxjs/toolkit";

import { loadStarted, loadEnded, loadCountriesSuccess } from "./actions";

export const initialState = {};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadCountriesSuccess.type]: (state, action) => {
    state.countries = action.payload.countries.map((el) => ({ value: el.iso_code, label: el.country, iso3: el.iso3 }));
  },
});
