import { createReducer } from "@reduxjs/toolkit";

import {
  loadStarted,
  loadEnded,
  loadBasketStarted,
  loadBasketEnded,
  updateFormState,
  loadFoodBasketSuccess,
  loadTargetBeneficiariesSuccess,
  saveNutritionalReq,
} from "./actions";

export const initialState = {
  form: {},
};

export default createReducer(initialState, {
  [loadStarted.type]: (state) => {
    state.loading = true;
  },
  [loadEnded.type]: (state) => {
    state.loading = false;
  },
  [loadBasketStarted.type]: (state) => {
    state.loadingBasket = true;
  },
  [loadBasketEnded.type]: (state) => {
    state.loadingBasket = false;
  },
  [loadFoodBasketSuccess.type]: (state, action) => {
    state.foodBasket = action.payload.foodBasket;
  },
  [loadTargetBeneficiariesSuccess.type]: (state, action) => {
    state.targetBeneficiaries = action.payload.targetBeneficiaries;
  },
  [saveNutritionalReq.type]: (state, action) => {
    state.nutritionalRequirements = action.payload.nutritionalRequirements;
  },
  [updateFormState.type]: (state, action) => {
    state.form = action.payload.formState;
  },
});
