import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components";
import messages from "components/Button/messages";
import { RowStyled } from "components/utils";
import { FIELDS } from "./constants";
import { validation } from "./validation";

const Footer = ({ approve, reject, index }) => {
  const intl = useIntl();
  const { control } = useFormContext();
  const foodPrice = useWatch({ control, name: `${FIELDS.FOOD_PRICES}.${index}` });

  const validate = useMemo(() => validation(foodPrice), [foodPrice]);

  return (
    <RowStyled margin="1.5rem 0 0 0.5rem">
      <Button
        widthAuto
        disabled={validate}
        children={intl.formatMessage(messages.approve)}
        marginRight="10px"
        onClick={approve}
      />
      <Button widthAuto disabled={validate} children={intl.formatMessage(messages.reject)} onClick={reject} />
    </RowStyled>
  );
};

export default Footer;
