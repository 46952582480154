import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { history } from "utils/history";
import { hydrateFromLocalStorage } from "utils/utils";

import rootSaga from "./sagas";
import rootReducer from "./reducers";

const preloadedState = hydrateFromLocalStorage();

const sagaMiddleware = createSagaMiddleware();

const middleware = [routerMiddleware(history), sagaMiddleware];

export default configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState,
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);
