import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Link as WFPLink } from "@wfp/ui";
import { FormattedMessage } from "react-intl";

const Link = ({ path, message, mainNavigationRef, targetBlank }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (targetBlank) {
      mainNavigationRef.current.onChangeSub("close");
      window.open(path, "_blank");
      return;
    } else {
      dispatch(push(path));
    }
  }, [dispatch, path, mainNavigationRef, targetBlank]);

  return (
    <WFPLink onClick={onClick} inline>
      <FormattedMessage {...message} />
    </WFPLink>
  );
};

export default Link;
