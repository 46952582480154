export const FIELDS = {
  USD_PRICE: "usd_price_per_gram",
  USD_ADD_COST: "usd_additional_cost_per_gram",
  PRICE: "price",
  PRICE_WITHOUT_ADD: "price_without_additional",
  QUANTITY: "quantity",
  MAJOR_GROUP: "major_group",
  DISPLAY_NAME: "display_name",
  MENU_COMPOSITION: "menu_composition",
  NOTES: "notes",
  MEAL_NAMES: "meal_names",
  DAY_COMPOSITION: "day_composition",
  DAY_MEAL_NAME: "day_meal_name",
  DELETED: "deleted",
  WEEK: "week",
  ITEM: "item",
  NEW_ITEM: "new_item",
  NEW_RECIPE: "new_recipe",
  AVAILABLE_RECIPES: "available_recipes",
  EXISTING_RECIPE: "existing_recipe",
  SELECTED_RECIPE: "selected_recipe",
  NAME: "name",
  DESCRIPTION: "description",
  INGREDIENTS: "ingredients",
  INGREDIENT: "ingredient",
  ID: "id",
  INCLUDED_RECIPES: "included_recipes",
  DAY_RECIPES: "day_recipes",
  DELETED_RECIPES: "deleted_recipes",
  DRAFT: "draft",
};

export const NEW_ITEM_INITIAL_VALUES = {
  [FIELDS.WEEK]: "all",
  [FIELDS.ITEM]: "",
  [FIELDS.QUANTITY]: "",
};

export const NEW_RECIPE_INITIAL_VALUES = {
  [FIELDS.NAME]: "",
  [FIELDS.DESCRIPTION]: "",
  [FIELDS.INGREDIENTS]: [],
};

export const NEW_INGREDIENT = {
  [FIELDS.ITEM]: [],
  [FIELDS.QUANTITY]: "",
};

export const INITIAL_VALUES = {
  [FIELDS.MENU_COMPOSITION]: {},
  [FIELDS.MEAL_NAMES]: {},
  [FIELDS.DELETED]: [],
  [FIELDS.AVAILABLE_RECIPES]: [],
  [FIELDS.INCLUDED_RECIPES]: {},
  [FIELDS.DELETED_RECIPES]: [],

  [FIELDS.DRAFT]: {
    [FIELDS.DAY_MEAL_NAME]: "",
    [FIELDS.DAY_COMPOSITION]: [],
    [FIELDS.NEW_ITEM]: NEW_ITEM_INITIAL_VALUES,
    [FIELDS.DAY_RECIPES]: [],
    [FIELDS.NEW_RECIPE]: NEW_RECIPE_INITIAL_VALUES,
    [FIELDS.EXISTING_RECIPE]: {
      [FIELDS.DESCRIPTION]: "",
      [FIELDS.INGREDIENTS]: [],
    },
    [FIELDS.SELECTED_RECIPE]: null,
    [FIELDS.DELETED_RECIPES]: [],
    [FIELDS.DELETED]: [],
  },
};
