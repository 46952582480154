import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  section: {
    flex: 1,
    overflow: "hidden",
    fontSize: 30,
    flexDirection: "column",
    flexWrap: "wrap",
    border: 1,
    marginTop: 15,
    borderColor: colors["ui-04"].hex,
    borderRadius: 2,
    padding: "10 14",
    height: 359,
  },
});
