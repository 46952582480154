import React from "react";
import { colors } from "@wfp/ui";

export const Milks = ({ size, disable }) => (
  <svg
    width={size || "15px"}
    height={size || "23px"}
    viewBox="0 0 15 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-221.000000, -662.000000)" fill={disable ? colors["ui-05"].hex : colors["brand-01"].hex}>
        <g transform="translate(221.000000, 662.000000)">
          <g>
            <polygon points="12.2571429 3.70411985 9.42857143 7.92509363 9.25714286 23 15 23 15 7.4082397" />
            <polygon points="10.8857143 3.27340824 8.31428571 7.23595506 0.428571429 7.32209738 3 3.44569288" />
            <polygon points="8.14285714 8.26966292 8.05714286 23 0.257142857 23 0 8.35580524" />
            <path d="M10.9714286,0 L4.71428571,0 C4.11428571,0 3.6,0.516853933 3.6,1.20599251 L3.6,2.3258427 L12.0857143,2.3258427 L12.0857143,1.20599251 C12.0857143,0.516853933 11.5714286,0 10.9714286,0 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
