import React, { useMemo } from "react";
import { Text } from "components";
import { Icon } from "@wfp/ui";
import { colors } from "@wfp/ui";
import { iconCaretDown } from "@wfp/icons";
import { UISelect } from "./styles";

export default function Select({ label, options, defaultValue, wfpStyle, className, customOnChange, ...rest }) {
  const selectedValue = useMemo(
    () => (options ? options.find((el) => el.value === defaultValue) : ""),
    [defaultValue, options],
  );

  const components = useMemo(
    () =>
      wfpStyle
        ? {
            DropdownIndicator: () => <Icon fill={colors["brand-01"].hex} icon={iconCaretDown} description="" />,
            IndicatorSeparator: () => null,
          }
        : {},
    [wfpStyle],
  );

  return (
    <>
      {label && <Text bold value={label} />}
      <UISelect
        {...rest}
        onChange={customOnChange || rest.onChange}
        wfpStyle={wfpStyle}
        components={components}
        className={`wfp--react-select-container ${className || ""}`}
        classNamePrefix="wfp--react-select"
        defaultValue={selectedValue || defaultValue}
        options={options || []}
      />
    </>
  );
}
