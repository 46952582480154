import styled from "styled-components";
import { ColStyled, mediaTablet } from "components/utils";

export const Container = styled(ColStyled)`
  height: 100%;
  overflow: auto;
  ${mediaTablet} {
    padding: 20px 10px 0;
  }
`;

export const ContainerList = styled.div`
  height: ${({ heightToRemove }) => `calc(75% - ${heightToRemove}px)`};
  margin-bottom: 10px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
