import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import * as R from "ramda";
import { useDispatch } from "react-redux";
import { colors, Blockquote } from "@wfp/ui";
import { iconCloseGlyph } from "@wfp/icons";

import { Module, Button, Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import routesPath from "containers/App/Routes/paths";
import buttonMessages from "components/Button/messages";
import { useResponsiveHook } from "hooks";
import { saveComparisonList } from "./actions";
import { IconClose } from "./styles";
import messages from "./messages";

const Warning = ({ target }) => {
  const { isTabletOrMobile } = useResponsiveHook();

  return (
    <ColStyled xs={isTabletOrMobile ? 7 : 8}>
      <Blockquote kind="info">
        <p>
          <FormattedMessage {...messages.compareWarningTitle} />
        </p>
        <p>
          <FormattedMessage {...messages.compareWarningSubtitle} values={{ target }} />
        </p>
      </Blockquote>
    </ColStyled>
  );
};

const ComparisonPreview = ({ comparisonList, clickOnClearList }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isTabletOrMobile } = useResponsiveHook();

  const target = useMemo(
    () => R.pathOr("", ["preliminary_information", "target_group_details", "name"], comparisonList[0]),
    [comparisonList],
  );

  const removeFromComparisonList = useCallback(
    (id) => {
      if (comparisonList.length === 1) return clickOnClearList();
      dispatch(saveComparisonList(comparisonList.filter((el) => el.id !== id)));
    },
    [clickOnClearList, comparisonList, dispatch],
  );

  const onClickCompare = useCallback(() => {
    const ids = comparisonList.map((el) => el.id).join(",");
    history.push(routesPath.compare.replace(":ids", ids));
  }, [comparisonList, history]);

  const boxChildren = useCallback(
    ({ id, name }) => (
      <>
        <RowStyled center="xs" middle="xs">
          <Text value={name} bold />
        </RowStyled>
        <IconClose
          icon={iconCloseGlyph}
          onClick={() => removeFromComparisonList(id)}
          fill={colors["ui-05"].hex}
          width="17px"
          height="17px"
        />
      </>
    ),
    [removeFromComparisonList],
  );

  return (
    <Module useSticky noTitle>
      <RowStyled middle="xs" between="xs">
        <Warning target={target} />
        <ColStyled xs={isTabletOrMobile ? 5 : 4}>
          <RowStyled end="xs">
            <Button
              fontWeight="400"
              fontSize="16px"
              withUnderline
              widthAuto
              kind="ghost"
              marginRight="10px"
              onClick={clickOnClearList}
              children={<FormattedMessage {...buttonMessages.clearCompare} />}
            />
            <Button
              widthAuto
              disabled={comparisonList.length < 2}
              onClick={onClickCompare}
              children={<FormattedMessage {...buttonMessages.compare} />}
            />
          </RowStyled>
        </ColStyled>
      </RowStyled>
      <Module.ContentWithBorder
        menus={comparisonList}
        withPaddingTop
        children={boxChildren}
        placeholder={comparisonList.length === 1 && <FormattedMessage {...messages.comparePlaceholder} />}
      >
        {boxChildren}
      </Module.ContentWithBorder>
    </Module>
  );
};

export default ComparisonPreview;
