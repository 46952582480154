import { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";

import { ROLES } from "containers/Admin/constants";
import { selectFoodSources, selectAccountId, selectWeightChoices, selectRoles } from "containers/App/selectors";
import { FIELDS } from "containers/FoodBasket/constants";
import CustomTable from "containers/FoodBasket/FoodItemsList/FoodGroup/CustomTable";
import { selectSummary } from "containers/FoodBasket/selectors";
import { useMonths } from "utils/utils";
import { columns } from "./Columns";

const PriceSources = ({ onEdit, onRequestClose }) => {
  const intl = useIntl();
  const summary = useSelector(selectSummary);
  const procurementSources = useSelector(selectFoodSources);
  const accountId = useSelector(selectAccountId);
  const units = useSelector(selectWeightChoices);
  const roles = useSelector(selectRoles);

  const months = useMonths(intl, "short");

  const { control } = useFormContext();
  const formUnit = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.UNIT}` });
  const formCurrency = useWatch({ control, name: `${FIELDS.FILTERS}.${FIELDS.CURRENCY}` });

  const pricingInformations = R.pathOr([], ["pricing_informations"], summary);

  const isAdmin = useMemo(() => roles.includes(ROLES.GLOBAL_ADMIN), [roles]);

  const unit = useMemo(() => units.find((unit) => unit.value === formUnit), [formUnit, units]);

  const columnsToShow = useMemo(
    () =>
      columns(
        intl,
        unit.conversion_rate,
        formCurrency,
        procurementSources,
        months,
        accountId,
        isAdmin,
        onEdit,
        onRequestClose,
      ),
    [intl, unit.conversion_rate, formCurrency, procurementSources, months, accountId, isAdmin, onEdit, onRequestClose],
  );

  const tableProps = {
    data: pricingInformations,
    columns: columnsToShow,
    page: 1,
    pageSize: 10,
    totalItems: pricingInformations.length,
    pageSizeOptions: [10, 30, 40],
    theme: "light",
  };

  return <CustomTable {...tableProps} />;
};

export default PriceSources;
