import { useMemo } from "react";
import { useIntl } from "react-intl";
import { InputGroup } from "@wfp/ui";
import { useFormContext, useWatch } from "react-hook-form";

import { Text } from "components";
import { ValidatedRadioButtonHookForm, ValidatedNumberInputHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import { KEYS, MIN, MAX } from "../constants";
import messages from "../messages";
import errorMessages from "utils/messages";

const MinOrMax = ({ label, keyLimit, keyPercentage, xsMinMax, xsField, xsPerc }) => {
  const intl = useIntl();

  const { control } = useFormContext();
  const limit = useWatch({ control, name: keyLimit });

  const minValue = useMemo(() => (limit === MIN && keyLimit.includes(KEYS.FOOD_GROUPS) ? 1 : 0), [keyLimit, limit]);

  const options = useMemo(
    () => [
      { label: intl.formatMessage(messages.min), value: MIN, showErrorMessage: false },
      { label: intl.formatMessage(messages.max), value: MAX, showErrorMessage: true },
    ],
    [intl]
  );

  return (
    <RowStyled middle="xs">
      <ColStyled xs={12} padding="0" margin="0 0 5px">
        <Text bold value={label} wrap />
      </ColStyled>

      <ColStyled xs={xsMinMax} padding="0">
        <InputGroup vertical>
          {options.map(({ label, value, showErrorMessage }) => (
            <ValidatedRadioButtonHookForm
              key={`${keyLimit}-${value}`}
              id={`${keyLimit}-${value}`}
              control={control}
              name={keyLimit}
              value={value}
              labelText={label}
              rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
              showErrorMessage={showErrorMessage}
            />
          ))}
        </InputGroup>
      </ColStyled>

      <ColStyled xs={xsField}>
        <ValidatedNumberInputHookForm
          control={control}
          name={keyPercentage}
          min={minValue}
          max={100}
          step={1}
          rules={{ required: { value: true, message: intl.formatMessage(errorMessages.fieldRequired) } }}
        />
      </ColStyled>

      <ColStyled xs={xsPerc}>
        <Text value="%" bold />
      </ColStyled>
    </RowStyled>
  );
};

export default MinOrMax;
