import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { colors, Tooltip, Icon } from "@wfp/ui";
import { iconEdit, iconDelete, iconOverflowMenu } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Unlock, Button } from "components";
import buttonMessages from "components/Button/messages";
import { checkPermission } from "components/Unlock/utils";
import { ColStyled } from "components/utils";
import { PERMISSIONS, ROLES } from "containers/Admin/constants";
import { selectPermissions, selectRoles } from "containers/App/selectors";
import { deleteFoodPriceRequest } from "containers/FoodBasket/actions";
import { FIELDS } from "containers/FoodBasket/constants";
import foodBasketMessages from "containers/FoodBasket/messages";
import { selectSummary } from "containers/FoodBasket/selectors";
import { getSummaryPricesFilters } from "containers/FoodBasket/utils";

const Actions = ({ price, onEdit, onRequestClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { getValues } = useFormContext();
  const summary = useSelector(selectSummary);
  const userPermissions = useSelector(selectPermissions);
  const roles = useSelector(selectRoles);

  const pricingInformations = R.pathOr([], ["pricing_informations"], summary);

  const onEditPrice = useCallback(() => onEdit(price), [onEdit, price]);

  const onDelete = useCallback(() => {
    const filters = getSummaryPricesFilters(getValues()[FIELDS.FILTERS]);
    // When dealing with last price, close the modal after removal.
    const cb = pricingInformations.length === 1 ? onRequestClose : null;
    dispatch(deleteFoodPriceRequest({ itemId: price.item_id, id: price.id, filters, cb }));
  }, [dispatch, getValues, onRequestClose, price.id, price.item_id, pricingInformations.length]);

  const hasChangeOrDeletePermission = useMemo(() => {
    return (
      checkPermission(userPermissions, PERMISSIONS.CHANGE_FOOD_PRICES) ||
      checkPermission(userPermissions, PERMISSIONS.DELETE_FOOD_PRICES) ||
      roles.includes(ROLES.GLOBAL_ADMIN)
    );
  }, [roles, userPermissions]);

  if (!hasChangeOrDeletePermission) return null;

  return (
    <Tooltip
      trigger="click"
      placement="bottom"
      content={
        <>
          <Unlock permission={PERMISSIONS.CHANGE_FOOD_PRICES}>
            <ColStyled>
              <Button
                kind="ghost"
                onClick={onEditPrice}
                icon={iconEdit}
                iconDescription={intl.formatMessage(buttonMessages.edit)}
              />
            </ColStyled>
          </Unlock>
          <Unlock permission={PERMISSIONS.DELETE_FOOD_PRICES}>
            <ColStyled>
              <Button
                kind="ghost"
                onClick={onDelete}
                icon={iconDelete}
                iconDescription={intl.formatMessage(buttonMessages.delete)}
              />
            </ColStyled>
          </Unlock>
        </>
      }
    >
      <span>
        <Icon
          className="pointer"
          description={intl.formatMessage(foodBasketMessages.options)}
          icon={iconOverflowMenu}
          fill={colors["ui-05"].hex}
        />
      </span>
    </Tooltip>
  );
};

export default Actions;
