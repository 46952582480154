import styled, { css } from "styled-components";

interface TextStyledProps {
  bold: any,
  className: any,
  defaultColor: any,
  disableColor: any,
  disable: any,
  fontSize: any,
  fontStyle: any,
  marginLeft: any,
  marginRight: any,
  marginTop: any,
  marginBottom: any,
  marginInlineStart: any,
  marginInlineEnd: any,
  superBold: any,
  textAlign: any,
  decoration: any,
  width: any,
  wrap: any,
  transform: any,
  noEllipsis: any,
  withPointer: any,
  noFontWeight: any,
  maxWidth: any,
  flex: any,
  lineHeight: any,
  regularWrap: any,
  children: any,
  style: any,
};

export const TextStyled = styled.p<TextStyledProps>`
  color: ${({ disable, disableColor, defaultColor }) => (disable ? disableColor : defaultColor)};
  font-weight: ${({ bold, superBold }) => (bold ? 600 : superBold ? 800 : "")};
  ${({ noFontWeight }) =>
    noFontWeight &&
    css`
      font-weight: 100;
    `};
  ${({ fontStyle }) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};
  white-space: ${({ wrap, regularWrap }) => (regularWrap ? "normal" : wrap ? "none" : "nowrap")};
  width: ${({ width }) => width || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || "auto"};
  overflow: hidden;
  text-overflow: ${({ noEllipsis }) => (noEllipsis ? "unset" : "ellipsis")};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
  margin-right: ${({ marginRight }) => marginRight || 0};
  ${({ marginInlineStart }) =>
    marginInlineStart &&
    css`
      margin-inline-start: ${marginInlineStart};
    `};
  ${({ marginInlineEnd }) =>
    marginInlineEnd &&
    css`
      margin-inline-end: ${marginInlineEnd};
    `};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ decoration }) => decoration};
  text-transform: ${({ transform }) => transform};
  cursor: ${({ withPointer }) => (withPointer ? "pointer" : null)};
  line-height: ${({ lineHeight }) => lineHeight || "1.5"};
  > span {
    font-weight: bold;
  }
`;
