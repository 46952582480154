import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "@wfp/ui";

export default StyleSheet.create({
  headerInBox: {
    borderBottom: 1,
    borderRight: 1,
    borderColor: colors["ui-04"].hex,
    fontSize: 9,
    fontFamily: "OpenSans",
    fontWeight: "bold",
    padding: "10 13",
  },
  groupBox: {
    fontSize: 9,
    fontFamily: "OpenSans",
    fontWeight: "bold",
  },
  itemBox: {
    fontSize: 9,
    fontFamily: "OpenSans",
    marginTop: 5,
  },
});
