import { defineMessages } from "react-intl";

export default defineMessages({
  min: {
    id: "label.min",
    defaultMessage: "Min",
  },
  max: {
    id: "label.max",
    defaultMessage: "Max",
  },
  advancedSetting: {
    id: "advancedSetting.title",
    defaultMessage: "Advanced settings",
  },
  numberOfFoodGroups: {
    id: "diversity.numberOfFoodGroups",
    defaultMessage: "Number of food groups",
  },
  minFoodGroupsInMeal: {
    id: "diversity.minFoodGroupsInMeal",
    defaultMessage: "Set the minimum number of food groups that must be present in each meal",
  },
  minFoodGroupsInMenu: {
    id: "diversity.minFoodGroupsInMenu",
    defaultMessage: "Set the minimum number of food groups that must be present in the whole menu",
  },
  numberOfFoodItems: {
    id: "diversity.numberOfFoodItems",
    defaultMessage: "Number of food items",
  },
  minFoodItemsInMeal: {
    id: "diversity.minFoodItemsInMeal",
    defaultMessage: "Set the minimum number of food items that must be present in each meal",
  },
  maxFoodItemsInMeal: {
    id: "diversity.maxFoodItemsInMeal",
    defaultMessage: "Set the maximum number of food items that must be present in each meal",
  },
  minFoodItemsInMenu: {
    id: "diversity.minFoodItemsInMenu",
    defaultMessage: "Set the minimum number of food items that must be present in the whole menu",
  },
  maxWeightTitle: {
    id: "diversity.maxWeightTitle",
    defaultMessage: "Maximum weight per meal",
  },
  maxWeightInfo: {
    id: "diversity.maxWeightInfo",
    defaultMessage: "Define the maximum weight of the meals.",
  },
  maxWeightCheckbox: {
    id: "diversity.maxWeightCheckbox",
    defaultMessage: "Set the maximum weight of the meal in grams",
  },
  maxPerMeal: {
    id: "diversity.maxPerMeal",
    defaultMessage: "maximum {value} per meal",
  },
  minPerMeal: {
    id: "diversity.minPerMeal",
    defaultMessage: "minimum {value} per meal",
  },
  minPerMenu: {
    id: "diversity.minPerMenu",
    defaultMessage: "minimum {value} per menu",
  },
  perMeal: {
    id: "diversity.perMeal",
    defaultMessage: "{value} per meal",
  },
});
