import React, { useEffect, useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Form, FormSpy } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Row } from "react-flexbox-grid";
import { Layout, Text, Line, Button } from "components";
import { ColStyled } from "components/utils";
import { ModuleWhite } from "components/UIKitStyled";
import buttonMessages from "components/Button/messages";
import { colors, Loading } from "@wfp/ui";
import {
  updateFormState,
  updateSources,
  loadSelectedSources,
  loadAllSources,
  setMenuType,
} from "containers/Admin/actions";
import { loadCountries } from "containers/SelectCountry/actions";
import {
  selectLoadingCountriesSlice,
  selectAllSources,
  selectSelectedSources,
  selectMenuType,
} from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";
import { useInitialValues } from "./useInitialValues";
import { validation } from "./utils";
import SubsetWarning from "./SubsetWarning";
import Source from "./Source";
import { FIELDS } from "../constants";
import countriesMessages from "../messages";
import { getMenuType, getSourcesDescription } from "../utils";

const Sources = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const selectedSources = useSelector(selectSelectedSources);
  const allSources = useSelector(selectAllSources);
  const countries = useSelector(selectCountries);
  const loadingSources = useSelector(selectLoadingCountriesSlice);
  const menuType = useSelector(selectMenuType);
  const initialValues = useInitialValues();

  const [initialize, setInitialize] = useState();

  // Store menu type in redux, then use it both here and in useInitialValues hook
  useEffect(() => {
    const menuType = getMenuType(location.pathname);
    dispatch(setMenuType({ menuType }));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!countries) {
      dispatch(loadCountries());
    }
  }, [dispatch, countries]);

  // Do not run API calls if countries are missing or sources are already in place
  useEffect(() => {
    if (!countries) return;

    if (!selectedSources) {
      dispatch(loadSelectedSources());
    }
  }, [dispatch, countries, selectedSources]);

  useEffect(() => {
    if (!allSources) {
      dispatch(loadAllSources());
    }
  }, [dispatch, allSources]);

  // We initialize data only when both selected sources and whole sources list are available
  useDeepCompareEffect(() => {
    if (selectedSources && allSources) {
      setInitialize(initialValues);
    }
  }, [initialValues, selectedSources, allSources]);

  const description = useMemo(() => getSourcesDescription(intl, menuType), [intl, menuType]);

  const updateForm = useCallback(
    (state) => {
      dispatch(updateFormState({ formState: state }));
    },
    [dispatch],
  );

  const onSubmit = useCallback(() => {
    dispatch(updateSources());
  }, [dispatch]);

  return (
    <Layout withBreadcrumbs={false} backgroundColor={colors["ui-02"].hex} marginTop="0">
      <ModuleWhite margin="15px 0" paddingContent="10px 30px 20px">
        <Text bold fontSize="16px" value={intl.formatMessage(countriesMessages.sourcesTitle)} />
        <SubsetWarning />
        <Line margin="10px -30px" />
        {!R.isEmpty(initialize) && (
          <Form
            initialValues={initialize}
            mutators={arrayMutators}
            onSubmit={() => {}}
            render={({ values }) => {
              const disableSubmit = validation(values);

              return (
                <>
                  <FormSpy subscription={{ values: true }} onChange={updateForm} />
                  <Loading active={loadingSources} />

                  <ColStyled xs>
                    <Text bold fontSize="20px" marginBottom="20px" value={description} />

                    <FieldArray name={FIELDS.SOURCES}>
                      {({ fields }) => fields.map((name) => <Source key={`field-${name}`} name={name} />)}
                    </FieldArray>
                  </ColStyled>

                  <Line margin="15px -30px" />
                  <Row end="xs">
                    <Button type="submit" onClick={onSubmit} disabled={disableSubmit} className="col-xs-2">
                      <FormattedMessage {...buttonMessages.submit} />
                    </Button>
                  </Row>
                </>
              );
            }}
          />
        )}
      </ModuleWhite>
    </Layout>
  );
};

export default Sources;
