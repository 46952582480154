import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectPriorityItems } from "containers/Admin/selectors";
import { selectCountries } from "containers/SelectCountry/selectors";

export const useInitialValues = () => {
  const priorityItems = useSelector(selectPriorityItems);
  const countries = useSelector(selectCountries);

  const isMissingData = useMemo(() => {
    return !priorityItems || !countries;
  }, [priorityItems, countries]);

  return useMemo(() => {
    if (isMissingData) return {};

    const reworkedPriorityItems = priorityItems.map((el) => ({
      value: el.item_id,
      label: el.item_display_name,
      regions: el.regions.map(({ id, region, ...rest }) => ({ value: id, label: region, ...rest })),
    }));

    return { priority_items: reworkedPriorityItems };
  }, [isMissingData, priorityItems]);
};
