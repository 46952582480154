import { useSelector } from "react-redux";
import { selectPermissions } from "containers/App/selectors";
import { checkPermission } from "./utils";

// Given a required `permission`, render `children` if satisfied.
const Unlock = ({ children, permission }) => {
  const userPermissions = useSelector(selectPermissions);
  const hasPermission = checkPermission(userPermissions, permission);

  return hasPermission ? children : null;
};

export default Unlock;
