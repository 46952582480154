import * as R from "ramda";
import { useIntl } from "react-intl";
import { Blockquote } from "@wfp/ui";

import { Text, BulletList } from "components";
import { joinStrings } from "utils/utils";
import { POINTS } from "./constants";
import messages from "./messages";

const NutritionalNotMet = ({ nutrients, kind = "error" }) => {
  const intl = useIntl();
  const nutrientsCopy = R.clone(nutrients);

  return (
    <Blockquote kind={kind}>
      <Text wrap value={intl.formatMessage(messages.warningTitle)} />
      <Text marginTop="10px" value={joinStrings(nutrientsCopy, nutrientsCopy.pop())} bold />
      <Text value={intl.formatMessage(messages.warningInfo)} marginTop="10px" />
      <BulletList list={POINTS} />
    </Blockquote>
  );
};

export default NutritionalNotMet;
