import React, { useCallback } from "react";
import { injectIntl } from "react-intl";
import { useForm } from "react-final-form";
import { ColStyled } from "components/utils";
import { Link } from "@wfp/ui";
import messages from "./messages";
import { PREFIX } from "./utils";

function AddNewEmail({ values, intl }) {
  const form = useForm();
  const addNewEmail = useCallback(() => {
    const index = Object.keys(values)[Object.keys(values).length - 1].replace(PREFIX, "");
    form.change(`${PREFIX}${Number(index) + 1}`, { value: "" });
  }, [form, values]);

  return (
    <ColStyled padding="20px 10px" className="pointer">
      <Link onClick={addNewEmail} children={intl.formatMessage(messages.shareAddEmail)} />
    </ColStyled>
  );
}

export default injectIntl(AddNewEmail);
