import styled from "styled-components";
import { Slider } from "@wfp/ui";

const CheckboxProps = ({ color, ...props }) => <Slider {...props} />;

export const SliderStyled = styled(CheckboxProps)`
  .wfp--slider__range-before {
    background-color: ${({ color }) => color};
  }

  input[type="range"]::-webkit-slider-thumb {
    background-color: ${({ color }) => `${color} !important`};
  }

  input[type="range"]::-moz-range-thumb {
    background-color: ${({ color }) => `${color} !important`};
  }
`;
