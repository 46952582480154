import * as R from "ramda";
import { setMonth } from "date-fns";

import { COMMUNITY } from "containers/MyMenus/labels";
import { capitalize } from "utils/utils";
import messages from "./messages";

export const SKIP_FOOD_ITEM_PRICE_CHECK = "?skip_fpi_check=1";

export const daysAndWeeks = ({ days_in_week, week_count }, intl) => {
  if (!days_in_week || !week_count) return;
  return intl.formatMessage(messages.daysAndWeeks, { days_in_week, week_count });
};

export const targeted = ({ target_group_details }) => {
  if (!target_group_details || !target_group_details.name) return;
  return target_group_details.name.includes("(")
    ? target_group_details.name.split("(")[1].replace(")", "")
    : target_group_details.name;
};

export const seasonality = ({ start_month, end_month }, formatMonth, intl) => {
  if (!start_month || !end_month) return;
  if (start_month === 1 && end_month === 12) return intl.formatMessage(messages.yearly);
  const nameSpaceMonth = formatMonth ? "long" : "short";
  const startMonth = intl.formatDate(setMonth(new Date(), start_month - 1), { month: nameSpaceMonth }).replace(".", "");
  const endMonth = intl.formatDate(setMonth(new Date(), end_month - 1), { month: nameSpaceMonth }).replace(".", "");
  return capitalize(`${startMonth} - ${endMonth}`);
};

export const regional = ({ regions }, intl) => {
  if (!regions) return "";
  return regions.length ? intl.formatMessage(messages.regional) : intl.formatMessage(messages.allRegions);
};

export const renameVitamins = (nutrient) => {
  if (!nutrient.startsWith("Vit")) return nutrient.toUpperCase();
  return `Vit ${nutrient.split(" ")[1]}`.toUpperCase();
};

export const transformForBackend = (data, { hasBasket = false } = {}) => {
  let { regions, sourcing_channels } = data.preliminary_information;

  // When duplicating/editing from a community menu, we may have {value: 1, label: "Label"} instead of an array. We check that here
  regions = Array.isArray(regions) ? regions : [regions];

  sourcing_channels = sourcing_channels.map((channel) => channel.value);

  const newRegions = regions.map(({ value, label, ...rest }) => ({ id: value, region: label, ...rest }));

  const menu_prices = data.type === COMMUNITY || !hasBasket ? {} : handleBasketData(data.basket);

  return {
    ...R.omit(["basket"], data),
    preliminary_information: {
      ...data.preliminary_information,
      regions: newRegions,
      sourcing_channels,
    },
    menu_prices,
  };
};

// Handle "basket" data in order to send BE the bare minimum data needed.
const handleBasketData = (basket) => {
  return Object.entries(basket).reduce((acc, [itemId, item]) => {
    // Custom price provided by user or source added by the user.
    const hasCustomData = Object.values(item.prices).some(
      (price) => !R.isNil(price.user_price_per_gram) || price.added_by_user,
    );

    // TODO: Once BE handles new food items, add back this check and make sure to send these data ad well.
    // Food item added by the user.
    // const itemAddedByUser = item.added_by_user;
    // return hasCustomData || itemAddedByUser ? { ...acc, [itemId]: item.prices } : acc;

    return hasCustomData ? { ...acc, [itemId]: item.prices } : acc;
  }, {});
};
