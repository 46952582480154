import React from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { NumberInputHookForm } from "components/Form";

export default function LabelAndField({ text, fieldName, className, label, invalid, invalidText }) {
  const { control } = useFormContext();

  return (
    <RowStyled middle="xs" className={className || ""} margin="0 0 25px">
      <ColStyled xs={6} padding="0">
        <Text marginTop="20px" value={text} wrap />
      </ColStyled>
      <ColStyled xs={2}>
        <NumberInputHookForm
          control={control}
          name={fieldName}
          labelText={label}
          min={1}
          step={1}
          invalid={invalid}
          invalidText={invalidText}
        />
      </ColStyled>
    </RowStyled>
  );
}
