import { select, put, call, all } from "redux-saga/effects";
import * as R from "ramda";

import {
  getBeneficiariesNutrition,
  getNutrientionalAdvanced,
  getRecommendedNutritionalRequirements,
  patchNutritionalAdvanced,
} from "api/api";
import { setAlert } from "containers/App/actions";
import { selectCountryCode } from "containers/App/selectors";
import { selectTargetGroup } from "containers/Menu/selectors";
import { selectNutrientRestrictions } from "../selectors";

export function* loadBeneficiariesNutrition(targetId, menuType) {
  try {
    const targetGroup = yield select(selectTargetGroup);
    const target = targetId || R.pathOr(1, ["id"], targetGroup);
    return yield call(getBeneficiariesNutrition, target, menuType);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadNutritionalValuesAdvanced(id) {
  try {
    return yield call(getNutrientionalAdvanced, id);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* loadRecommendedNutritionalRequirements() {
  try {
    const countryCode = yield select(selectCountryCode);
    return yield call(getRecommendedNutritionalRequirements, countryCode);
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* initNutrients(id) {
  try {
    const nutrientRestrictions = yield select(selectNutrientRestrictions);

    const [{ nutritional_requirements }, nutritionAdvanced, { results: recommendedNutritionalRequirements }] =
      yield all([
        loadBeneficiariesNutrition(),
        loadNutritionalValuesAdvanced(id),
        loadRecommendedNutritionalRequirements(),
      ]);

    // If we have at least a recommended value we proceed
    const hasRecommendedValues = recommendedNutritionalRequirements.some((item) => item.percentage !== null);
    const { nutrient_max_limits } = nutritionAdvanced;
    let recommendedMacronutrients = [];
    let recommendedMicronutrients = [];
    const recommendedOrdered = R.sortBy((key) => key.nutrient_order, recommendedNutritionalRequirements);
    if (hasRecommendedValues) {
      recommendedOrdered.forEach(({ nutrient }) => {
        const item = R.find(R.propEq("nutrient", nutrient), recommendedNutritionalRequirements);

        // Can't receive some data from BE, I need to use the nutritional_requirements array to perform few checks
        const otherItem = R.find(R.propEq("nutrient", nutrient), nutritional_requirements);

        const itemMaxLimit = R.find(R.propEq("nutrient", nutrient), nutrient_max_limits);
        const itemInPlus = R.find(R.propEq("nutrient", nutrient), nutrientRestrictions);
        const element = {
          label: item.nutrient_label,
          limit: otherItem?.value_range?.[1] || 9999,
          lowerBound: otherItem?.value_range?.[0],
          nutrient: item.nutrient,
          unit: otherItem?.unit,
          infeasibleAbove: R.pathOr(101, ["infeasible_above"], itemMaxLimit),
          criticAbove: R.pathOr(101, ["critic_above"], itemMaxLimit),
          id: R.path(["id"], itemInPlus),
          percentage: R.pathOr(30, ["percentage"], item),
        };
        item.type === "micronutrient"
          ? recommendedMicronutrients.push(element)
          : recommendedMacronutrients.push(element);
      });
    }

    let macronutrients = [];
    let micronutrients = [];
    const nutrientsOrdered = R.sortBy((key) => key.nutrient_order, nutritional_requirements);
    nutrientsOrdered.forEach(({ nutrient }) => {
      const item = R.find(R.propEq("nutrient", nutrient), nutritional_requirements);
      const itemMaxLimit = R.find(R.propEq("nutrient", nutrient), nutrient_max_limits);
      const itemInPlus = R.find(R.propEq("nutrient", nutrient), nutrientRestrictions);
      const element = {
        label: item.nutrient_display,
        limit: item.value_range[1] || 9999,
        lowerBound: item.value_range[0],
        nutrient: item.nutrient,
        unit: item.unit,
        infeasibleAbove: R.pathOr(101, ["infeasible_above"], itemMaxLimit),
        criticAbove: R.pathOr(101, ["critic_above"], itemMaxLimit),
        id: R.path(["id"], itemInPlus),
        percentage: R.pathOr(30, ["percentage"], itemInPlus),
      };
      item.nutrient_is_micro ? micronutrients.push(element) : macronutrients.push(element);
    });

    return {
      macronutrients,
      micronutrients,
      advNutritionalRequirements: {
        ...nutritionAdvanced,
        nutrient_max_limits: nutrient_max_limits.reduce(
          (acc, item) => ({ ...acc, [`key_${item.nutrient}`]: item }),
          {}
        ),
      },
      recommended_nutritional_requirements: hasRecommendedValues
        ? { macronutrients: recommendedMacronutrients, micronutrients: recommendedMicronutrients }
        : null,
    };
  } catch (e) {
    yield put(setAlert(e));
  }
}

export function* saveNutritionalAdvancedSaga({ payload: { id, body, cb } }) {
  try {
    yield call(patchNutritionalAdvanced, id, body);
    if (cb) cb();
  } catch (e) {
    yield put(setAlert(e));
  }
}
