import { useMemo, useCallback } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { InputGroup } from "@wfp/ui";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";

import { NewAccordion } from "components";
import { RadioButtonHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import messages from "containers/OptimizedMenu/Rules/messages";
import { selectInfeasibleNutrients, selectCriticNutrients } from "containers/OptimizedMenu/selectors";
import { FIELDS, NUTRITION_LEVELS, options, nutrientsSet } from "../constants";
import Graph from "./Graph";
import NutrientsList from "./NutrientsList";
import NutritionalNotMet from "./NutritionalNotMet";

const NutritionRules = () => {
  const intl = useIntl();
  const { control, getValues, setValue } = useFormContext();
  const infeasibleNutrients = useSelector(selectInfeasibleNutrients);
  const criticNutrients = useSelector(selectCriticNutrients);

  const nutritionLevel = useWatch({ control, name: `${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.NUTRITION_LEVEL}` });
  const recommended = useWatch({ control, name: FIELDS.RECOMMENDED });
  const { fields: macronutrients, replace: replaceMacronutrients } = useFieldArray({
    control,
    name: FIELDS.MACRONUTRIENTS,
  });
  const { fields: micronutrients, replace: replaceMicronutrients } = useFieldArray({
    control,
    name: FIELDS.MICRONUTRIENTS,
  });

  const handleNutritionLevelChange = useCallback(
    (el, field) => {
      const values = getValues();
      const newNutritionLevel = el.target.value;

      if (newNutritionLevel === NUTRITION_LEVELS.RECOMMENDED) {
        replaceMacronutrients(values[FIELDS.RECOMMENDED][FIELDS.MACRONUTRIENTS] || []);
        replaceMicronutrients(values[FIELDS.RECOMMENDED][FIELDS.MICRONUTRIENTS] || []);
        field.onChange(newNutritionLevel);
        return;
      }

      if (nutrientsSet[newNutritionLevel]) {
        const macronutrients = (values[FIELDS.MACRONUTRIENTS] || []).map((el) => ({
          ...el,
          percentage: nutrientsSet[newNutritionLevel].macronutrients,
        }));
        const micronutrients = (values[FIELDS.MICRONUTRIENTS] || []).map((el) => ({
          ...el,
          percentage: nutrientsSet[newNutritionLevel].micronutrients,
        }));
        replaceMacronutrients(macronutrients);
        replaceMicronutrients(micronutrients);
      }

      field.onChange(newNutritionLevel);
    },
    [getValues, replaceMacronutrients, replaceMicronutrients]
  );

  const showWarningInfeasible = useMemo(
    () => infeasibleNutrients?.[nutritionLevel] && !R.isEmpty(infeasibleNutrients[nutritionLevel]),
    [infeasibleNutrients, nutritionLevel]
  );

  const showWarningCritic = useMemo(
    () => criticNutrients?.[nutritionLevel] && !R.isEmpty(criticNutrients[nutritionLevel]),
    [criticNutrients, nutritionLevel]
  );

  const isCustomSelected = useMemo(() => nutritionLevel === NUTRITION_LEVELS.CUSTOM, [nutritionLevel]);

  const NutrientsAndWeight = useMemo(() => {
    if (R.isEmpty(macronutrients) || R.isEmpty(micronutrients)) return null;

    return (
      <>
        <NutrientsList type="macronutrient" nutrients={macronutrients} disable={!isCustomSelected} />
        <NutrientsList type="micronutrient" nutrients={micronutrients} disable={!isCustomSelected} />
      </>
    );
  }, [isCustomSelected, macronutrients, micronutrients]);

  return (
    <>
      <RowStyled padding="10px">
        <ColStyled xl={5} xs={4}>
          <InputGroup
            labelText={intl.formatMessage(messages.nutrientTargetsTitle)}
            helperText={intl.formatMessage(messages.nutrientTargetsInfo)}
            vertical
          >
            {options(intl, recommended).map(({ label, value }) => (
              <RadioButtonHookForm
                key={`nutrition-rules-${value}`}
                id={`nutrition-rules-${value}`}
                control={control}
                name={`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.NUTRITION_LEVEL}`}
                value={value}
                labelText={label}
                customOnChange={handleNutritionLevelChange}
              />
            ))}
          </InputGroup>
        </ColStyled>

        <Graph />
      </RowStyled>
      <RowStyled>
        {showWarningInfeasible && <NutritionalNotMet nutrients={infeasibleNutrients[nutritionLevel]} />}
        {showWarningCritic && <NutritionalNotMet nutrients={criticNutrients[nutritionLevel]} kind="warning" />}
      </RowStyled>

      <RowStyled>
        <ColStyled xs>
          <NewAccordion
            firstExpanded={isCustomSelected}
            withBorderBottom={false}
            customPadding="0px 20px 0px 20px"
            fields={[
              {
                title: intl.formatMessage(messages.adjustRequirement),
                titleFontSize: "0.875rem",
                content: NutrientsAndWeight,
              },
            ]}
            onChange={([expanded]) =>
              expanded
                ? setValue(`${FIELDS.MENU_GENERAL_PARAMETER}.${FIELDS.NUTRITION_LEVEL}`, NUTRITION_LEVELS.CUSTOM)
                : null
            }
          />
        </ColStyled>
      </RowStyled>
    </>
  );
};

export default NutritionRules;
