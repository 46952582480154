import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { iconFilter } from "@wfp/icons";
import { useFormContext } from "react-hook-form";

import { Button } from "components";
import { SelectHookForm } from "components/Form";
import { RowStyled, ColStyled } from "components/utils";
import messages from "components/Button/messages";
import { FIELDS } from "containers/MyMenus/constants";
import { intlOrderByOptions } from "../constants";

const OrderByAndFilter = ({ visibleFilters, setVisibleFilters, closeFiltersAndFetchMenus, closeFilters }) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const orderByOptions = useMemo(() => intlOrderByOptions(intl), [intl]);

  const handleOrderChange = useCallback(
    async (el, field) => {
      // Do not change if new value is same as old one
      if (el.value === field.value) return;

      await field.onChange(el.value);
      closeFiltersAndFetchMenus();
    },
    [closeFiltersAndFetchMenus],
  );

  const onFilterClick = useCallback(() => {
    if (visibleFilters) {
      closeFilters();
    } else {
      setVisibleFilters(true);
    }
  }, [closeFilters, setVisibleFilters, visibleFilters]);

  return (
    <RowStyled end="xs">
      <ColStyled xs={3}>
        <SelectHookForm
          control={control}
          name={FIELDS.ORDERING}
          options={orderByOptions}
          customOnChange={handleOrderChange}
        />
      </ColStyled>
      <Button
        widthAuto
        icon={iconFilter}
        marginLeft="0.5rem"
        kind={visibleFilters ? "primary" : "secondary"}
        onClick={onFilterClick}
        children={intl.formatMessage(messages.filter)}
      />
    </RowStyled>
  );
};

export default OrderByAndFilter;
