import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { Text, Select } from "components";
import { RowStyled, ColStyled } from "components/utils";
import { saveInResultsPdf } from "containers/PDF/actions";
import messages from "containers/Results/messages";
import { options } from "./constants";

const MainCostsList = ({ mainCosts, mainCostType, setMainCostType, inModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Save this information in redux to display it in the PDF.
  useEffect(() => {
    if (!inModal) dispatch(saveInResultsPdf({ costsDrivers: mainCosts.slice(0, 3) }));
  }, [dispatch, inModal, mainCosts]);

  return (
    <>
      {inModal && (
        <Text bold fontSize="28px" marginBottom="20px" value={intl.formatMessage(messages.mainCostsDrivers)} />
      )}

      <RowStyled center="xs">
        <ColStyled xs={inModal ? 6 : 9} padding="0" margin={`0 0 ${inModal ? "20px" : "5px"}`}>
          <Select wfpStyle defaultValue={mainCostType} options={options(intl)} onChange={setMainCostType} />
        </ColStyled>
      </RowStyled>

      {mainCosts.map(
        ({ label, value }, index) =>
          (inModal || (!inModal && index < 3)) && (
            <RowStyled key={`row-cost-${index}`} width="100%" between="xs" margin="10px 0 0 0">
              <ColStyled xs={9} padding="0" margin="0">
                <Text value={label} />
              </ColStyled>
              <ColStyled className={inModal && "end-xs"} xs={3} padding="0">
                <Text value={value} />
              </ColStyled>
            </RowStyled>
          )
      )}
    </>
  );
};

export default MainCostsList;
