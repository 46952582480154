import { useMemo } from "react";
import { RowStyled } from "components/utils";

import { Text } from "components";
import { Chip } from "./styles";
import { useHtmlHook } from "hooks";

const ChipWithLabel = ({ value, backgroundColor = "tomato", fontSize = "14px", marginLeft = "0", size = "13px" }) => {
  const { isRTL } = useHtmlHook();

  const margin = useMemo(() => (isRTL ? `0 ${marginLeft} 0 0` : `0 0 0 ${marginLeft}`), [isRTL, marginLeft]);

  return (
    <RowStyled middle="xs" center="xs" margin={margin}>
      <Chip size={size} backgroundColor={backgroundColor} />
      <Text marginLeft="5px" fontSize={fontSize} value={value} />
    </RowStyled>
  );
};

export default ChipWithLabel;
