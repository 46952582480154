import * as R from "ramda";
import { Text, ChipWithLabel } from "components";
import { newColorBySource } from "components/utils";
import foodBasketMessages from "containers/FoodBasket/messages";
import messages from "containers/GeneralInfo/GeneralForm/messages";
import Actions from "../Actions";
import CustomAveragePrice from "./CustomAveragePrice";

export const columns = (intl, unitRate, currencyLabel, procurementSources, conversionRate, removeSource) => [
  {
    Header: intl.formatMessage(foodBasketMessages.columnProcurementSource),
    accessor: "source",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      const { label: labelA } = procurementSources.find((source) => source.value === a);
      const { label: labelB } = procurementSources.find((source) => source.value === b);

      if (labelA > labelB) return 1;
      if (labelA < labelB) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const { value, label } = procurementSources.find((source) => source.value === row.original.source);
      return <ChipWithLabel backgroundColor={newColorBySource[value]} value={label} />;
    },
  },
  {
    Header: intl.formatMessage(messages.columnAveragePrice),
    accessor: "price",
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];

      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },
    Cell: ({ cell: { row } }) => {
      const usdPricePerGram = row.original.usd_total_cost_per_gram ?? row.original.user_price_per_gram;
      const currencyMultiplier = currencyLabel === "USD" ? 1 : conversionRate;
      const usdTotalPrice = usdPricePerGram * unitRate;

      const price = usdTotalPrice * currencyMultiplier;
      return <Text fontSize="14px" value={price.toFixed(3)} />;
    },
  },
  {
    Header: intl.formatMessage(messages.columnYourAveragePrice),
    accessor: "your_price",
    disableSortBy: true,
    Cell: ({ cell: { row } }) =>
      !row.original.added_by_user || !R.isNil(row.original.user_price_per_gram) ? (
        <CustomAveragePrice source={row.original.source} />
      ) : null,
  },
  {
    Header: "",
    accessor: "delete",
    disableSortBy: true,
    Cell: ({ cell: { row } }) => <Actions source={row.original.source} removeSource={removeSource} />,
  },
];
