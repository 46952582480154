import { call, select, all, put, takeLatest, debounce } from "redux-saga/effects";

import { setAlert } from "containers/App/actions";
import {
  getAllMajorGroup,
  searchFoodItems,
  getSummaryPrice,
  patchFoodPrice,
  postFoodPrice,
  deleteFoodPrice,
  getAllFoodSimple,
} from "api/api";
import { loadRegionsSaga, loadConstantsSaga, loadCurrencySaga, loadPricesSourcesSaga } from "containers/App/sagas";
import { selectConstants, selectCountryCode, selectNutrientChoices, selectRegions } from "containers/App/selectors";
import {
  loadStarted,
  loadEnded,
  loadMajorGroupsSuccess,
  loadFoodBasket,
  loadFoodItemsStarted,
  loadFoodItemsSuccess,
  loadFoodItemsEnded,
  loadSummaryPricesRequest,
  loadSummaryPricesStarted,
  loadSummaryPricesSuccess,
  loadSummaryPricesEnded,
  createOrUpdateFoodPriceRequest,
  createOrUpdateFoodPriceStarted,
  createOrUpdateFoodPriceEnded,
  deleteFoodPriceRequest,
  deleteFoodPriceStarted,
  deleteFoodPriceEnded,
  loadNutrientsSuccess,
  searchFoodItemsRequest,
  loadFilteredFCTFoodItemsRequest,
  loadFCTFoodItemsRequest,
  loadFCTFoodItemsStarted,
  loadFCTFoodItemsSuccess,
  loadFCTFoodItemsEnded,
} from "./actions";
import messages from "./messages";
import { selectFoodItems } from "./selectors";
import { getReworkedFilters, reworkAllFoodItems } from "./utils";

export function* loadMajorGroupsSaga() {
  try {
    const groups = yield call(getAllMajorGroup);
    const foodGroups = groups.map((el) => ({ ...el, value: encodeURIComponent(el.value) }));
    yield put(loadMajorGroupsSuccess({ majorGroups: foodGroups }));
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadFoodBasketSaga() {
  try {
    yield put(loadStarted());
    const countryCode = yield select(selectCountryCode);

    yield all([
      loadRegionsSaga(),
      loadPricesSourcesSaga(),
      loadConstantsSaga(),
      countryCode && loadCurrencySaga(countryCode),
      loadMajorGroupsSaga(),
    ]);

    const { community_nutrients, optimized_nutrients } = yield select(selectConstants);
    // We show community and optimized nutrients set for current country. We need to leverage the nutrients constant in order to show them properly
    const nutrients = [...new Set([...community_nutrients, ...optimized_nutrients])].slice(0, 16);
    const nutrientChoices = yield select(selectNutrientChoices);
    let reworkedNutrients = nutrientChoices
      .filter((nutrient) => nutrients.includes(nutrient.name))
      .map((nutrient) => ({
        ...nutrient,
        percentage: [0, 100],
      }));
    yield put(loadNutrientsSuccess({ nutrients: reworkedNutrients }));

    yield put(loadEnded());
  } catch (e) {
    yield put(loadEnded());
    yield put(setAlert(e));
  }
}

export function* loadSummaryPricesSaga({ payload: { itemId, filters } }) {
  try {
    yield put(loadSummaryPricesStarted());
    const data = yield call(getSummaryPrice, itemId, filters);
    data.pricing_informations = data.pricing_informations.map((information) => ({
      ...information,
      additional_costs: information.additional_costs.map((el) =>
        el.percentage ? { unit: "%", value: el.percentage, ...el } : { unit: el.price_currency, value: el.price, ...el }
      ),
    }));
    yield put(loadSummaryPricesSuccess({ summaryPrices: { ...data, id: itemId } }));
    yield put(loadSummaryPricesEnded());
  } catch (e) {
    yield put(loadSummaryPricesEnded());
    yield put(setAlert(e));
  }
}

export function* _searchFoodItems(body) {
  try {
    const filters = getReworkedFilters(body);
    const { results } = yield call(searchFoodItems, filters);
    const reworkedResults = results.map(({ item_id, item_name, is_priority, ...rest }) => ({
      label: item_name,
      value: item_id,
      is_priority,
      ...rest,
    }));
    yield put(loadFoodItemsSuccess({ foodItems: reworkedResults }));
  } catch (e) {
    yield put(loadFoodItemsEnded());
    yield put(setAlert(e));
  }
}

export function* createOrUpdateFoodPriceSaga({ payload: { id, data, filters, cb } }) {
  try {
    yield put(createOrUpdateFoodPriceStarted());

    const regions = yield select(selectRegions);
    const { value, label, ...region } = regions.find((region) => region.value === data.region);
    const reworkedData = { ...data, region: { id: value, region: label, ...region } };

    const summary = id ? yield call(patchFoodPrice, id, reworkedData) : yield call(postFoodPrice, reworkedData);

    if (cb) cb();

    // Reload food items list and price sources
    yield* _searchFoodItems(filters);
    yield call(loadSummaryPricesSaga, { payload: { itemId: summary.item, filters } });

    const message = id ? messages.updatedSuccess : messages.createdSuccess;
    yield put(createOrUpdateFoodPriceEnded());

    yield put(
      setAlert({
        type: "success",
        title: messages.genericSuccess,
        text: message,
      })
    );
  } catch (e) {
    yield put(createOrUpdateFoodPriceEnded());
    yield put(setAlert(e));
  }
}

export function* deleteFoodPriceSaga({ payload: { itemId, id, filters, cb = null } }) {
  try {
    yield put(deleteFoodPriceStarted());
    yield call(deleteFoodPrice, id);

    // Reload food items list and price sources
    yield* _searchFoodItems(filters);
    yield call(loadSummaryPricesSaga, { payload: { itemId, filters } });

    yield put(deleteFoodPriceEnded());
    yield put(
      setAlert({
        type: "success",
        title: messages.genericSuccess,
        text: messages.deletedSuccess,
      })
    );
    if (cb) cb();
  } catch (e) {
    yield put(deleteFoodPriceEnded());
    yield put(setAlert(e));
  }
}

export function* searchFoodItemsSaga({ payload: { body } = {} }) {
  try {
    yield put(loadFoodItemsStarted());
    yield* _searchFoodItems(body);
    yield put(loadFoodItemsEnded());
  } catch (e) {
    yield put(loadFoodItemsEnded());
    yield put(setAlert(e));
  }
}

export function* loadFilteredFCTFoodItemsSaga({ payload: { text, callback, filterFn } }) {
  try {
    const foodItems = yield select(selectFoodItems);
    const fctFoodItems = yield call(getAllFoodSimple, { search: text });
    const reworkedFCTFoodItems = fctFoodItems.map(({ id, display_name, ...rest }) => ({
      label: display_name,
      value: id,
      from_fct: true,
      ...rest,
    }));

    const allFoodItems = reworkAllFoodItems({ foodItems, fctFoodItems: reworkedFCTFoodItems, text });
    callback(filterFn ? filterFn(allFoodItems) : allFoodItems);
  } catch (e) {
    callback([]);
  }
}

export function* loadFCTFoodItemsSaga() {
  try {
    yield put(loadFCTFoodItemsStarted());
    const fctFoodItems = yield call(getAllFoodSimple);
    const reworkedFCTFoodItems = fctFoodItems.map(({ id, display_name, ...rest }) => ({
      label: display_name,
      value: id,
      from_fct: true,
      ...rest,
    }));
    yield put(loadFCTFoodItemsSuccess({ foodItems: reworkedFCTFoodItems }));
    yield put(loadFCTFoodItemsEnded());
  } catch (e) {
    yield put(loadFCTFoodItemsEnded());
  }
}

export default function* foodBasketSaga() {
  yield takeLatest(loadFoodBasket, loadFoodBasketSaga);
  yield takeLatest(searchFoodItemsRequest, searchFoodItemsSaga);
  yield takeLatest(loadSummaryPricesRequest, loadSummaryPricesSaga);
  yield takeLatest(createOrUpdateFoodPriceRequest, createOrUpdateFoodPriceSaga);
  yield takeLatest(deleteFoodPriceRequest, deleteFoodPriceSaga);
  yield takeLatest(loadFCTFoodItemsRequest, loadFCTFoodItemsSaga);
  yield debounce(500, loadFilteredFCTFoodItemsRequest, loadFilteredFCTFoodItemsSaga);
}
