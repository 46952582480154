import { useIntl } from "react-intl";
import { View, Text } from "@react-pdf/renderer";
import messages from "containers/Compare/messages";
import ListItems from "./ListItems";
import compareStyles from "../styles";

const PageThree = ({ diversity }) => {
  const intl = useIntl();
  const { body, boxContent, boxHeader } = compareStyles;

  return (
    <View style={[body, { marginTop: 10 }]}>
      <Text style={[boxHeader, { borderTop: 0 }]}>{intl.formatMessage(messages.menuStructure)}</Text>
      <View style={boxContent}>
        {diversity.map(({ items }, index) => (
          <ListItems key={`structure-${index}`} items={items} index={index} menuNumbers={diversity.length} />
        ))}
      </View>
    </View>
  );
};

export default PageThree;
