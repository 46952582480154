import { createSelector } from "@reduxjs/toolkit";
import * as R from "ramda";

import { initialState } from "./reducer";

const selectCompareMenus = () => (state) => R.pathOr(initialState, ["compareMenus"], state);

export default () => createSelector(selectCompareMenus(), (compareState) => compareState);

export const selectLoading = createSelector(selectCompareMenus(), (compareState) =>
  R.pathOr(false, ["loading"], compareState),
);

export const selectNutritions = createSelector(selectCompareMenus(), (compareState) =>
  R.pathOr([], ["nutritions"], compareState),
);

export const selectInfo = createSelector(selectCompareMenus(), (compareState) => R.pathOr([], ["info"], compareState));

export const selectDiversity = createSelector(selectCompareMenus(), (compareState) =>
  R.pathOr([], ["diversity"], compareState),
);

export const selectSourcing = createSelector(selectCompareMenus(), (compareState) =>
  R.pathOr([], ["sourcing", "menus"], compareState),
);
