import { Text } from "@react-pdf/renderer";

import SpacedInRow from "containers/PDF/SpacedInRow";
import generalStyles from "containers/PDF/styles";
import styles from "./styles";

const Recipe = ({ recipe }) => {
  const { baseTag, recipeTag } = styles;
  const { fontSize9 } = generalStyles;

  return (
    <>
      <Text style={[baseTag, recipeTag]}>{recipe.name}</Text>
      {Object.values(recipe.ingredients).map(({ display_name, quantity }) => (
        <SpacedInRow
          key={`row-recipe-${display_name}`}
          label={display_name.split(";")[0]}
          value={`${quantity.toFixed()} g`}
          fontSizeStyle={fontSize9}
          heightRow={15}
        />
      ))}
    </>
  );
};

export default Recipe;
