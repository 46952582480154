import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectLoadingFilters } from "containers/MyMenus/selectors";
import { Text } from "components";
import { RowStyled } from "components/utils";
import { Title } from "./styles";
import messages from "containers/MyMenus/messages";

const SectionTitles = () => {
  const intl = useIntl();
  const loadingFilters = useSelector(selectLoadingFilters);

  if (loadingFilters) return null;

  return (
    <RowStyled margin="2.5rem 0 0.75rem 0" padding="0 1.25rem">
      <Title>
        <Text bold value={intl.formatMessage(messages.modeTitle)} />
      </Title>
      <Title>
        <Text bold value={intl.formatMessage(messages.statusTitle)} />
      </Title>
      <Title>
        <Text bold value={intl.formatMessage(messages.regionTitle)} />
      </Title>
      <Title>
        <Text bold value={intl.formatMessage(messages.targetTitle)} />
      </Title>
    </RowStyled>
  );
};

export default SectionTitles;
